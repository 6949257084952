import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const api = process.env.REACT_APP_SERVER_API;

export const fetchData = createAsyncThunk(
  "organizer/fetchData",
  async ({ getData, token, state }) => {
    const res = await axios.get(`${api}/${getData}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (
      state === "event_summary" ||
      state === "order_summary" ||
      state === "stripe" ||
      state === "paypal" ||
      state === "event_reviews" ||
      state === "scanner_setting"
    ) {
      return { data: res.data.data, state };
    } else if (state === "payout_reports") {
      const sortData = [...res.data.data].sort(
        (a, b) => b.event.id - a.event.id
      );
      return { data: sortData, state };
    } else {
      const sortData = [...res.data.data].sort((a, b) => b.id - a.id);
      return { data: sortData, state };
    }
  }
);

const initialState = {
  events: [],
  venues: [],
  scanners: [],
  sales: [],
  event_categories: [],
  active_scanners: [],
  venue_types: [],
  amenities: [],
  audiences: [],
  countries: [],
  languages: [],
  orders: [],
  event_reviews: [],
  payout_request: [],
  payout_reports: [],
  order_summary: {},
  event_summary: {},
  scanner_setting: {},
  paypal: {},
  stripe: {},
  loading: false,
  error: false,
};
const OrganizerSlice = createSlice({
  name: "organizer",
  initialState,
  reducers: {
    deleteSinglev: (state, action) => {
      const { id, state: dataState } = action.payload;
      state[dataState] = state[dataState].filter((state) => state.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        const { data, state: dataState } = action.payload;
        state[dataState] = data;
        state.loading = false;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export const { deleteSinglev, addPayoutData } = OrganizerSlice.actions;
export default OrganizerSlice.reducer;
