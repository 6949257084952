import React from "react";
import "./dashboardheader.scss";
import { Link } from "react-router-dom";
export default function DashboardHeader({
  icon: Icon1Component,
  icon2: Icon2Component,
  text1,
  text2,
  link,
}) {
  return (
    <>
      <div className="DashboardHeaderSection">
        <div className="DashboardHeaderTitleBox">
          {Icon1Component && <Icon1Component className="DashboardHeaderIcon" />}
          <span className="DashboardHeaderTitle">{text1}</span>
        </div>

        <div className="MangeEventBox">
          {Icon2Component && <Icon2Component className="ManageEventIcon" />}
          <Link to={link} className="ManageEventsTitle">
            {text2}
          </Link>
        </div>
      </div>
    </>
  );
}
