import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FaSyncAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SelectBox from "../../../../global/SelectBox";
import { Radio } from "antd";
import { clearCart, fetchTickets } from "../../../../../store/slice/Cart";
import {
  formatCVC,
  formatCreditCardNumber,
  formatDate,
  formatExpirationDate,
  isDateValid,
} from "../../../../../utils/CustomFunctions";
import { country, states } from "../../../../global/Values";

const CheckOut = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const api = process.env.REACT_APP_SERVER_API;
  const server = process.env.REACT_APP_SERVER;
  const { slug } = useParams();
  const { countries } = useSelector((state) => state.AllData);
  const { user } = useSelector((state) => state.authUser);
  const { total_price, total_item, fees, tax, loading } = useSelector(
    (state) => state.CartDetails
  );
  const [errors, setErrors] = useState({});
  const [isPanding, setPanding] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [checkOut, setCheckOut] = useState({});
  // for billing information
  const [add_billing_information, setAdd_billing_information] = useState({
    first_name: "",
    last_name: "",
    email: "",
    country: "",
    city: "",
    postal_code: "",
    street: "",
    state: "",
  });
  // for payment information
  const [payment, setPayment] = useState({
    card_name: "",
    card_number: "4242 4242 4242 4242",
    month: "",
    year: "",
    expiry: "",
    cvc: "",
    billing_id: "",
  });

  const first_nameputRef = useRef(null);
  const last_nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const street_addressInputRef = useRef(null);
  const cityInputRef = useRef(null);
  const postal_codeInputRef = useRef(null);
  const stateInputRef = useRef(null);
  const countryInputRef = useRef(null);

  // validate fields billing information
  const validateFields = () => {
    let newErrors = {};

    if (!add_billing_information.first_name.trim())
      newErrors.first_name = "This value should not be null.";
    if (!add_billing_information.last_name.trim())
      newErrors.last_name = "This value should not be null.";
    if (!add_billing_information.email.trim())
      newErrors.email = "This value should not be null.";
    if (!add_billing_information.country)
      newErrors.country = "This value should not be null.";
    if (!add_billing_information.state)
      newErrors.state = "This value should not be null.";
    if (!add_billing_information.city.trim())
      newErrors.city = "This value should not be null.";
    if (!add_billing_information.postal_code.trim())
      newErrors.postal_code = "This value should not be null.";
    if (!add_billing_information.street.trim())
      newErrors.street = "This value should not be null.";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  // Scroll to the corresponding input field error
  const scrollToFirstError = (errors) => {
    // Find the first error field
    const firstErrorField = Object.keys(errors)[0];
    // Scroll to the corresponding input field
    if (firstErrorField === "first_name" && first_nameputRef.current) {
      first_nameputRef.current.focus();
    } else if (firstErrorField === "last_name" && last_nameInputRef.current) {
      last_nameInputRef.current.focus();
    } else if (firstErrorField === "email" && emailInputRef.current) {
      emailInputRef.current.focus();
    } else if (firstErrorField === "country" && countryInputRef.current) {
      countryInputRef.current.focus();
    } else if (firstErrorField === "street" && street_addressInputRef.current) {
      street_addressInputRef.current.focus();
    } else if (firstErrorField === "city" && cityInputRef.current) {
      cityInputRef.current.focus();
    } else if (
      firstErrorField === "postal_code" &&
      postal_codeInputRef.current
    ) {
      postal_codeInputRef.current.focus();
    } else if (firstErrorField === "state" && stateInputRef.current) {
      stateInputRef.current.focus();
    }
  };

  // for alidate fields payment information
  const validateFields2 = () => {
    let newErrors = {};
    const cardNumberWithoutSpaces = payment.card_number.replace(/\s/g, "");
    if (!/^[A-Za-z ]+$/.test(payment.card_name))
      newErrors.card_name = "Please Enter valid name.";

    if (!/^\d{16}$/.test(cardNumberWithoutSpaces))
      newErrors.card_number = "Please Enter valid card number (16 digits).";

    const isValid = isDateValid(payment.expiry);
    if (!isValid) newErrors.expiry = "Please enter a valid expiration date";

    if (!/^\d{3}$/.test(payment.cvc))
      newErrors.cvc = "Please Enter cvc (3 digits).";

    if (!payment.card_name.trim())
      newErrors.card_name = "This value should not be null.";
    if (!payment.card_number.trim())
      newErrors.card_number = "This value should not be null.";
    if (!payment.expiry.trim())
      newErrors.expiry = "This value should not be null.";
    if (!payment.cvc.trim()) newErrors.cvc = "This value should not be null.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };
  // for billing information
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdd_billing_information({
      ...add_billing_information,
      [name]: value,
    });
  };
  // for handle change slect box
  const handleSelectBox = (name, value) => {
    setAdd_billing_information({
      ...add_billing_information,
      [name]: value,
    });
  };
  // for payment information
  const handleChangePayment = ({ target }) => {
    let value = target.value;
    switch (target.name) {
      case "card_name":
        setPayment({ ...payment, card_name: value });
        break;
      case "card_number":
        value = formatCreditCardNumber(value);
        setPayment({ ...payment, card_number: value });
        break;
      case "expiry":
        value = formatExpirationDate(value);
        const [month, year] = value.split("/");
        setPayment({ ...payment, expiry: value, month, year });
        break;
      case "cvc":
        value = formatCVC(value);
        setPayment({ ...payment, cvc: value });
        break;
      default:
        break;
    }
  };
  // for add billing information
  const submitBillingInformation = async (e) => {
    e.preventDefault();
    // Validate form fields
    const isValid = validateFields();
    // Scroll to first error if any
    if (!isValid) {
      // Use a callback to ensure state update is complete
      setErrors((errors) => {
        scrollToFirstError(errors);
        return errors;
      });
      return;
    }

    try {
      setPanding(true);
      const { data } = await axios.post(
        `${api}/add_billing_information`,
        add_billing_information
      );
      if (data.status === 201) {
        setIsPayment(true);
        setPayment({ ...payment, billing_id: data.data.id });
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setPanding(false);
    }
  };

  // for submit payment information
  const submitPaymentInformation = async (e) => {
    e.preventDefault();
    if (!validateFields2()) return;
    try {
      setPanding(true);
      const { data } = await axios.post(`${api}/payment`, payment);
      if (data.status === 201) {
        toast.success(data.message);
        // navigate
        navigate(`/en/dashboard/payment-success`, {
          state: { data: data.data },
        });
        dispatch(clearCart());
        if (user?.token) {
          dispatch(
            fetchTickets({
              getData: "attendee/tickets",
              state: "myWallet",
              token: user.token,
            })
          );
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setPanding(false);
    }
  };

  useEffect(() => {
    setPayment({
      ...payment,
      user_id: user?.id || null,
      checkout_reference: data.checkout_reference,
      reference: data.checkout_reference,
      total_tickets: slug ? checkOut?.total_item : total_item,
      price: slug ? checkOut?.total_price : total_price,
      payment_type: "stripe",
    });
    setAdd_billing_information({
      ...add_billing_information,
      user_id: user?.id || null,
      checkout_reference: data.checkout_reference,
      reference: data.checkout_reference,
      ...(user?.token && {
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        email: user.email || "",
        country: user.country || "",
        city: user.city || "",
        postal_code: user.postal_code || "",
        street: user.street || "",
        state: user.state || "",
      }),
    });
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isPayment]);

  useEffect(() => {
    if (slug && data && data.cart) {
      let { total_item, total_price } = data.cart.reduce(
        (accumulator, currentValue) => {
          let { ticket_quantity, total_price, event } = currentValue;
          if (event?.tickets?.[0]?.ticket_free === 0) {
            accumulator.total_price += total_price;
          }
          accumulator.total_item += parseInt(ticket_quantity);
          return accumulator;
        },
        { total_price: 0, total_item: 0 }
      );
      setCheckOut({
        total_item,
        total_price: parseFloat(total_price).toFixed(2),
      });
    }
  }, [data, slug]);

  return (
    <section className="section-checkout bg-white padding-y mb-4 mt-4">
      <div className="container cart">
        <div className="row">
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <FaSyncAlt className="spinIcon" />
            </div>
          ) : (
            data?.cart?.length > 0 && (
              <>
                <main className="col-lg-8 order-1 order-lg-0 mt-4 mt-lg-0">
                  <div className="card">
                    <div className="card-header">
                      <h6 className="b mb-0">Order summary</h6>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-hover shopping-cart-wrap">
                          <thead className="text-muted">
                            <tr>
                              <th style={{ width: "55%" }} scope="col">
                                Event / Ticket
                              </th>
                              <th
                                style={{ width: "15%" }}
                                scope="col"
                                width="100"
                              >
                                Price
                              </th>
                              <th
                                style={{ width: "15%" }}
                                scope="col"
                                width="100"
                              >
                                Quantity
                              </th>
                              <th
                                style={{ width: "15%" }}
                                scope="col"
                                className="text-right"
                                width="100"
                              >
                                Subtotal
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.cart?.map((event, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <figure className="media h-100">
                                      <div className="img-wrap my-auto">
                                        {event?.image ||
                                        event?.event?.main_event_image ? (
                                          <img
                                            src={`${server}/event/image/${
                                              slug
                                                ? event?.event?.main_event_image
                                                : event?.image
                                            }`}
                                            className="img-thumbnail img-50-50 img-sm img-lazy-load b-loaded"
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src="/./images/noPhoto.png"
                                            className="img-thumbnail img-50-50 img-sm"
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      <figcaption className="media-body my-auto ">
                                        <Link
                                          to={`/en/event/${
                                            slug
                                              ? event?.event?.slug
                                              : event.slug
                                          }`}
                                        >
                                          <h6 className="title mb-2">
                                            {slug
                                              ? event?.event?.name
                                              : event?.event_name}
                                          </h6>
                                        </Link>
                                        <dl className="dlist-inline small">
                                          <dt></dt>
                                          <dd className="mr-0">
                                            {slug
                                              ? event?.event?.tickets?.[0]
                                                  ?.ticket_name
                                              : event?.ticket_name}
                                          </dd>
                                        </dl>
                                        <dl className="dlist-inline small">
                                          <dt className="me-1">When</dt>
                                          <dd>
                                            {slug
                                              ? formatDate(
                                                  new Date(
                                                    event?.event?.start_On
                                                  )
                                                ).fullDate
                                              : event?.start_On}
                                          </dd>
                                        </dl>
                                        <dl className="dlist-inline small">
                                          <dt>Where</dt>{" "}
                                          <dd>
                                            {slug ? (
                                              event?.event
                                                ?.event_date_online === 1 ? (
                                                " Online"
                                              ) : (
                                                <>
                                                  {event?.event?.venue?.city}:{" "}
                                                  {
                                                    event?.event?.venue
                                                      ?.postal_code
                                                  }{" "}
                                                  {
                                                    event?.event?.venue
                                                      ?.street_address
                                                  }
                                                  , {event?.event?.venue?.state}
                                                  ,{" "}
                                                  {countries?.map((curElem) => {
                                                    if (
                                                      curElem.id ==
                                                      event?.event?.venue
                                                        ?.country
                                                    ) {
                                                      return (
                                                        <React.Fragment
                                                          key={curElem.id}
                                                        >
                                                          {curElem.name}
                                                        </React.Fragment>
                                                      );
                                                    }
                                                    return null; // or any other default value if the condition is not met
                                                  })}
                                                </>
                                              )
                                            ) : event?.event_date_online ===
                                              1 ? (
                                              " Online"
                                            ) : (
                                              <>
                                                {event?.venue?.city}:{" "}
                                                {event?.venue?.postal_code}{" "}
                                                {event?.venue?.street_address},{" "}
                                                {event?.venue?.state},{" "}
                                                {countries?.map((curElem) => {
                                                  if (
                                                    curElem.id ==
                                                    event?.venue?.country
                                                  ) {
                                                    return (
                                                      <React.Fragment
                                                        key={curElem.id}
                                                      >
                                                        {curElem.name}
                                                      </React.Fragment>
                                                    );
                                                  }
                                                  return null; // or any other default value if the condition is not met
                                                })}
                                              </>
                                            )}
                                          </dd>
                                        </dl>
                                        <dl className="dlist-inline small">
                                          <dt className="me-1">Organizer</dt>
                                          <dd>
                                            <Link
                                              to={`/en/organizer/${
                                                slug
                                                  ? event?.event?.user.slug
                                                  : event?.organize_slug
                                              }`}
                                              taget="_blank"
                                            >
                                              {slug
                                                ? event?.event?.user
                                                    .organize_name
                                                : event?.organize_name}
                                            </Link>
                                          </dd>
                                        </dl>
                                      </figcaption>
                                    </figure>
                                  </td>
                                  <td>
                                    <div className="">
                                      <span className="">
                                        {event?.event?.tickets?.[0]
                                          ?.ticket_free == 1 ||
                                        event?.ticket_free == 1
                                          ? "Free"
                                          : `${event?.ticket_price}$`}
                                      </span>
                                    </div>
                                  </td>
                                  <td>{event?.ticket_quantity}</td>
                                  <td className="text-center">
                                    <div className="price-wrap">
                                      <var className="price">
                                        {event?.event?.tickets?.[0]
                                          ?.ticket_free == 1 ||
                                        event?.ticket_free === 1
                                          ? "0$"
                                          : `${event?.sub_price}$`}
                                      </var>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {!isPayment ? (
                    <form className="signform">
                      <div className="card mt-4 cart">
                        <div className="card-header">
                          <h6 className="b mb-0">Billing information</h6>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col form-group">
                              <label
                                htmlFor="firstname"
                                className="fw-600 mb-1"
                              >
                                First name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                id="firstname"
                                type="text"
                                name="first_name"
                                className={`form-control`}
                                value={add_billing_information.first_name}
                                onChange={handleChange}
                                ref={first_nameputRef}
                              />
                              {errors.first_name && (
                                <small className="text-danger d-block">
                                  {errors.first_name}
                                </small>
                              )}
                            </div>
                            <div className="col form-group">
                              <label htmlFor="lastname" className="fw-600 mb-1">
                                Last name<span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                id="lastname"
                                type="text"
                                name="last_name"
                                className={`form-control`}
                                value={add_billing_information.last_name}
                                onChange={handleChange}
                                ref={last_nameInputRef}
                              />
                              {errors.last_name && (
                                <small className="text-danger d-block">
                                  {errors.last_name}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col form-group">
                              <label htmlFor="email" className="fw-600 mb-1">
                                Email<span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                id="email"
                                type="email"
                                name="email"
                                className={`form-control `}
                                value={add_billing_information.email}
                                onChange={handleChange}
                                ref={emailInputRef}
                              />
                              {errors.email && (
                                <small className="text-danger d-block">
                                  {errors.email}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col form-group">
                              <label htmlFor="country">
                                Country<span style={{ color: "red" }}>*</span>
                              </label>
                              <SelectBox
                                name="country"
                                options={country}
                                val={add_billing_information.country}
                                change={handleSelectBox}
                                ref={countryInputRef}
                              />
                              {errors.country && (
                                <small className="text-danger d-block">
                                  {errors.country}
                                </small>
                              )}
                            </div>
                            <div className="col form-group">
                              <label htmlFor="state" className="fw-600 mb-1">
                                State<span style={{ color: "red" }}>*</span>
                              </label>
                              <SelectBox
                                name="state"
                                options={states}
                                val={add_billing_information.state}
                                change={handleSelectBox}
                                ref={stateInputRef}
                              />
                              {errors.state && (
                                <small className="text-danger d-block">
                                  {errors.state}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col form-group">
                              <label htmlFor="city " className="fw-600 mb-1">
                                City<span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                id="city"
                                type="text"
                                name="city"
                                className={`form-control `}
                                value={add_billing_information.city}
                                onChange={handleChange}
                                ref={cityInputRef}
                              />
                              {errors.city && (
                                <small className="text-danger d-block">
                                  {errors.city}
                                </small>
                              )}
                            </div>
                            <div className="col form-group">
                              <label
                                htmlFor="postal_code "
                                className="fw-600 mb-1"
                              >
                                Postal code
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                id="postal_code"
                                type="text"
                                name="postal_code"
                                className={`form-control `}
                                value={add_billing_information.postal_code}
                                onChange={handleChange}
                                ref={postal_codeInputRef}
                              />
                              {errors.postal_code && (
                                <small className="text-danger d-block">
                                  {errors.postal_code}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col form-group">
                              <label htmlFor="street " className="fw-600 mb-1">
                                Street<span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                id="street"
                                type="text"
                                name="street"
                                className={`form-control`}
                                value={add_billing_information.street}
                                onChange={handleChange}
                                ref={street_addressInputRef}
                              />
                              {errors.street && (
                                <small className="text-danger d-block">
                                  {errors.street}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4 cart">
                        <div className="card-header">
                          <h6 className="b mb-0">Payment method</h6>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-6 form-group">
                              <div className="custom-control custom-radio custom-control-inline">
                                <Radio
                                  className="ProfileSettingRadioBtn fw-600"
                                  name="reviews"
                                  checked={true}
                                  // onChange={handleChange}
                                  value="true"
                                >
                                  <img
                                    src="/./images/strip.png"
                                    className="img-80-80 mr-3 ml-3"
                                    style={{ width: "80px", height: "" }}
                                    alt=""
                                  />{" "}
                                  Credit/Debit Cards
                                </Radio>
                              </div>
                            </div>
                            {/* <div className="col-6 form-group">
                              <div className="custom-control custom-radio custom-control-inline">
                                <Radio
                                  className="ProfileSettingRadioBtn fw-600"
                                  name="reviews"
                                  checked={false}
                                  value="false"
                                  // onChange={handleChange}
                                >
                                  <img
                                    src="/./images/apple.jpeg"
                                    style={{ width: "80px", height: "" }}
                                    className="img-80-80 mr-3 ml-3"
                                    alt=""
                                  />{" "}
                                  Apple Pay
                                </Radio>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      {/* <button
                id="checkout_submit"
                type="submit"
                className="btn btn-primary btn-block mt-4"
              >
                <i className="fas fa-money-check"></i> Pay now
              </button> */}

                      <button
                        className="btn btn-dark rounded-5 mt-3 w-100"
                        type="button"
                        disabled={isPanding ? "disabled" : ""}
                        onClick={submitBillingInformation}
                      >
                        {isPanding ? (
                          <FaSyncAlt className="spinIcon" />
                        ) : (
                          "Continue"
                        )}
                      </button>
                    </form>
                  ) : (
                    <form className="signform">
                      <div className="card mt-4 cart">
                        <div className="card-header">
                          <h6 className="b mb-0">Payment Information</h6>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col form-group">
                              <label
                                htmlFor="card_name"
                                className="fw-600 mb-1"
                              >
                                Card Name
                              </label>
                              <input
                                type="text"
                                name="card_name"
                                value={payment.card_name}
                                className={`form-control `}
                                onKeyDown={(e) => {
                                  if (
                                    !/^[a-zA-Z]$/.test(e.key) &&
                                    ![
                                      "Backspace",
                                      "Delete",
                                      "ArrowLeft",
                                      "ArrowRight",
                                      "Tab",
                                      " ",
                                    ].includes(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={handleChangePayment}
                              />

                              {errors.card_name && (
                                <small className="text-danger d-block">
                                  {errors.card_name}
                                </small>
                              )}
                            </div>
                            <div className="col form-group">
                              <label
                                htmlFor="card_number"
                                className="fw-600 mb-1"
                              >
                                Card Number
                              </label>
                              <input
                                type="tel"
                                name="card_number"
                                className={`form-control `}
                                pattern="[\d ]{16,22}"
                                maxLength="19"
                                value={payment.card_number}
                                onChange={handleChangePayment}
                              />
                              {errors.card_number && (
                                <small className="text-danger d-block">
                                  {errors.card_number}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col form-group">
                              <label htmlFor="month" className="fw-600 mb-1">
                                Expiry Date
                              </label>
                              <input
                                type="tel"
                                name="expiry"
                                className={`form-control `}
                                placeholder="MM/YY"
                                pattern="\d\d/\d\d"
                                value={payment.expiry}
                                required
                                onChange={handleChangePayment}
                              />

                              {errors.expiry && (
                                <small className="text-danger d-block">
                                  {errors.expiry}
                                </small>
                              )}
                            </div>
                            <div className="col form-group">
                              <label htmlFor="cvc" className="fw-600 mb-1">
                                CVC
                              </label>
                              <input
                                id="cvc"
                                type="tel"
                                name="cvc"
                                pattern="\d{3}"
                                className={`form-control `}
                                value={payment.cvc}
                                onChange={handleChangePayment}
                              />
                              {errors.cvc && (
                                <small className="text-danger d-block">
                                  {errors.cvc}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        className="btn btn-dark rounded-5 mt-3 w-100"
                        type="button"
                        disabled={isPanding ? "disabled" : ""}
                        onClick={submitPaymentInformation}
                      >
                        {isPanding ? (
                          <FaSyncAlt className="spinIcon" />
                        ) : (
                          "Pay now"
                        )}
                      </button>
                    </form>
                  )}
                </main>
                <aside className="col-lg-4 pt-3 pt-lg-0 order-0 order-lg-1">
                  <div className="sticky-top sticky-sidebar ">
                    {/* <div className="checkout-timer-wrapper">
                    <div className="alert alert-warning alert-icon">
                      <i className="fas fa-hourglass-half"></i>{" "}
                      <span className="checkout-timer" data-seconds-left="1799">
                        26:50
                      </span>{" "}
                      left before tickets are released
                    </div>
                  </div> */}
                    <dl className="dlist-align">
                      <dt>Tickets</dt>
                      <dd className="text-right ">
                        {slug ? checkOut?.total_item : total_item}
                      </dd>
                    </dl>

                    <dl className="dlist-align">
                      <dt>Fees</dt>
                      <dd className="text-right ">{fees}%</dd>
                    </dl>
                    <dl className="dlist-align">
                      <dt>Tax</dt>
                      <dd className="text-right ">{tax}%</dd>
                    </dl>
                    <dl className="dlist-align h4">
                      <dt>Total</dt>
                      <dd className=" ">
                        <strong>
                          {slug ? checkOut?.total_price : total_price}$
                        </strong>
                      </dd>
                    </dl>

                    <hr />
                  </div>
                </aside>
              </>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default CheckOut;
