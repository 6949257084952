import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import "./app.css";
import Support from "./components/helpCenter/support/Support";
import HelpCenter from "./pages/HelpCenter";
import Home from "./pages/Home";
import EventDetails from "./pages/events/BrowseEvents/EventDetails";
import Events from "./pages/events/Events";
import Venues from "./pages/venues/Venues";
import VenuesDetails from "./pages/venues/VenuesDetails";

import Signin from "./components/auth/signin/Signin";
import Attendee from "./components/auth/signup/Attendee";
import Organizer from "./components/auth/signup/Organizer";
import BlogDetail from "./components/blog/BlogDetail";
import Blog from "./pages/blogs/Blog";
import Contact from "./pages/contact/Contact";
import PricingAndFees from "./pages/pricing-and-fees/PricingAndFees";
import ResetPassword from "./pages/resetPassword/ResetPassword";

import ChangePassword from "./components/Admin/change-password/ChangePassword";
import Cart from "./components/Admin/dashboard/attendee/cart/Cart";
import MyTickets from "./components/Admin/dashboard/attendee/my-tickets/MyTickets";
import TicketDetail from "./components/Admin/dashboard/attendee/my-tickets/TicketDetail";

import AttendeeMain from "./components/Admin/dashboard/attendee/Attendee";
import Following from "./components/Admin/dashboard/attendee/following/Following";
import MyFavorites from "./components/Admin/dashboard/attendee/my-favorites/MyFavorites";
import AddReviews from "./components/Admin/dashboard/attendee/my-reviews/AddReviews";
import MyReviews from "./components/Admin/dashboard/attendee/my-reviews/MyReviews";
import MyWallet from "./components/Admin/dashboard/attendee/my-wallet/MyWallet";
import Setting from "./components/Admin/dashboard/attendee/settings/setting";
import AttendeeCheckout from "./pages/cart/Checkout";
import NoLoginCart from "./pages/cart/NoLoginCart";

import OrganizerMain from "./components/Admin/dashboard/organizer/Organizer";
import OrganizerDashboard from "./components/Admin/dashboard/organizer/dashboard/Dashboard";
import AddEvent from "./components/Admin/dashboard/organizer/events/AddEvent";
import MyEvent from "./components/Admin/dashboard/organizer/events/MyEvent";
import Stats from "./components/Admin/dashboard/organizer/events/Stats";
import OrganizerManageOrders from "./components/Admin/dashboard/organizer/orders/ManageOrders";
import OrganizerOrderDetails from "./components/Admin/dashboard/organizer/orders/OrderDetails";
import PayoutRequestDetail from "./components/Admin/dashboard/organizer/payouts/ManagePayoutRequestDetail";
import PayoutMethods from "./components/Admin/dashboard/organizer/payouts/PayoutMethods";
import PayoutRequests from "./components/Admin/dashboard/organizer/payouts/PayoutRequests";
import AddPointsOfSale from "./components/Admin/dashboard/organizer/points-of-sale/AddPointsOfSale";
import MyPointsOfSale from "./components/Admin/dashboard/organizer/points-of-sale/MyPointsOfSale";
import { Profile } from "./components/Admin/dashboard/organizer/profile/Profile";
import Reports from "./components/Admin/dashboard/organizer/reports/Reports";
import Reviews from "./components/Admin/dashboard/organizer/reviews/Reviews";
import AddScanner from "./components/Admin/dashboard/organizer/scanners/AddScanner";
import MyScanners from "./components/Admin/dashboard/organizer/scanners/MyScanners";
import ScannerAppSetting from "./components/Admin/dashboard/organizer/scanners/ScannerAppSetting";
import AddVenues from "./components/Admin/dashboard/organizer/venues/AddVenues";
import MyVenues from "./components/Admin/dashboard/organizer/venues/MyVenues";

import AdministratorMain from "./components/Admin/dashboard/administrator/Administrator";
import AddBlogPost from "./components/Admin/dashboard/administrator/blogs/AddBlogPost";
import Posts from "./components/Admin/dashboard/administrator/blogs/Posts";
import AddBolgCategory from "./components/Admin/dashboard/administrator/blogs/category/AddBolgCategory";
import BolgCategories from "./components/Admin/dashboard/administrator/blogs/category/Categories";
import Console from "./components/Admin/dashboard/administrator/console/Console";
import ManageEvents from "./components/Admin/dashboard/administrator/events/ManageEvents";
import AddAudiences from "./components/Admin/dashboard/administrator/events/audiences/AddAudiences";
import Audiences from "./components/Admin/dashboard/administrator/events/audiences/Audiences";
import AddCategory from "./components/Admin/dashboard/administrator/events/categories/AddCategory";
import EventCategories from "./components/Admin/dashboard/administrator/events/categories/Categories";
import AddCountries from "./components/Admin/dashboard/administrator/events/countries/AddCountries";
import Countries from "./components/Admin/dashboard/administrator/events/countries/Countries";
import AddLanguage from "./components/Admin/dashboard/administrator/events/language/AddLanguage";
import Language from "./components/Admin/dashboard/administrator/events/language/Language";
import AddArticalCategory from "./components/Admin/dashboard/administrator/help-center/AddArticalCategory";
import AddArticles from "./components/Admin/dashboard/administrator/help-center/AddArticles";
import AdminArticles from "./components/Admin/dashboard/administrator/help-center/Articles";
import Categories from "./components/Admin/dashboard/administrator/help-center/Categories";
import ManageOrders from "./components/Admin/dashboard/administrator/orders/ManageOrders";
import OrderDetails from "./components/Admin/dashboard/administrator/orders/OrderDetails";
import ManagePages from "./components/Admin/dashboard/administrator/pages/ManagePages";
import ManagePayoutRequestDetail from "./components/Admin/dashboard/administrator/payout-requests/ManagePayoutRequestDetail";
import ManagePayoutRequests from "./components/Admin/dashboard/administrator/payout-requests/ManagePayoutRequests";
import AdminReports from "./components/Admin/dashboard/administrator/reports/Reports";
import AdminReviews from "./components/Admin/dashboard/administrator/reviews/Reviews";
import AdminBlog from "./components/Admin/dashboard/administrator/settings/blog/Blog";
import Checkout from "./components/Admin/dashboard/administrator/settings/checkout/Checkout";
import EventsListPage from "./components/Admin/dashboard/administrator/settings/events-list-page/EventsListPage";
import GoogleMaps from "./components/Admin/dashboard/administrator/settings/google-maps/GoogleMaps";
import GoogleRecaptcha from "./components/Admin/dashboard/administrator/settings/google-recaptcha/GoogleRecaptcha";
import HomepageHero from "./components/Admin/dashboard/administrator/settings/homepage-hero/HomepageHero";
import Layout from "./components/Admin/dashboard/administrator/settings/layout/Layout";
import MailServer from "./components/Admin/dashboard/administrator/settings/mail-server/MailServer";
import Menus from "./components/Admin/dashboard/administrator/settings/menus/Menus";
import Newsletter from "./components/Admin/dashboard/administrator/settings/newsletter/Newsletter";
import Payment from "./components/Admin/dashboard/administrator/settings/payment/Payment";
import SocialLogin from "./components/Admin/dashboard/administrator/settings/social-login/SocialLogin";
import VenuePage from "./components/Admin/dashboard/administrator/settings/venue-page/VenuePage";
import Translation from "./components/Admin/dashboard/administrator/translation/Translation";
import Users from "./components/Admin/dashboard/administrator/users/user";
import { ManageVenues } from "./components/Admin/dashboard/administrator/venues/ManageVenues";
import AddAmenities from "./components/Admin/dashboard/administrator/venues/amenities/AddAmenities";
import ManageAmenities from "./components/Admin/dashboard/administrator/venues/amenities/ManageAmenities";
import AddVenuesTypes from "./components/Admin/dashboard/administrator/venues/venues-types/AddVenuesTypes";
import ManageVenuesTypes from "./components/Admin/dashboard/administrator/venues/venues-types/ManageVenuesTypes";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrganizerEvents from "./pages/OrganizerEvents";
import { fetchAllData } from "./store/slice/AllData";

import AdminDashboard from "./components/Admin/dashboard/administrator/dashboard/Dashboard";
import AdminPayoutMethods from "./components/Admin/dashboard/administrator/payout-methods/PayoutMethods";
import AddPayoutRequests from "./components/Admin/dashboard/organizer/payouts/AddPayoutRequests";
import AddPaypalRest from "./components/Admin/dashboard/organizer/payouts/AddPaypalRest";
import AddStripeCheckout from "./components/Admin/dashboard/organizer/payouts/AddStripeCheckout";
import ErrorPage from "./components/ErrorPage";
import EventCategory from "./components/events/EventCategory";
import Articles from "./components/helpCenter/Articles";
import AdminRoute from "./components/routes/AdminRoute";
import OrganizerRoute from "./components/routes/OrganizerRoute";
import UserRoute from "./components/routes/UserRoute";
import AboutUs from "./pages/AboutUs";
import ConfirmEmail from "./pages/ConfirmEmail";
import PaymentSuccess from "./pages/PaymentSuccess";
import CookiePolicy from "./pages/policy/CookiePolicy";
import PrivacyPolicy from "./pages/policy/PrivacyPolicy";
import TermsOfService from "./pages/policy/TermsOfService";
import AddNewPassword from "./pages/resetPassword/AddNewPassword";
import { CheckEmail } from "./pages/resetPassword/CheckEmail";
import { fetchuUserData } from "./store/slice/Attendee";
import { setLoggedUser } from "./store/slice/User";
import { useCountryCodes } from "./utils/CustomFunctions";

function App() {
  useCountryCodes();
  // const api = process.env.REACT_APP_SERVER_API;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const jsonUser = decodeURIComponent(location?.search?.split("=")[1]);
      const parsedUserData = JSON.parse(jsonUser);
      console.log(parsedUserData);
      const user = {
        ...parsedUserData.user,
        role: parsedUserData.user.roles,
        token: parsedUserData.token,
      };
      console.log(user);
      // parsedUserData?.role?.some((role) => role.name === "organizer") &&
      //   navigate("/en/dashboard/organizer/my-events/add");
      // parsedUserData?.role?.some((role) => role.name === "admin") &&
      //   navigate("/en/dashboard/administrator/");
      if (user.role.length > 0) {
        dispatch(setLoggedUser(user));
        user?.role?.some((role) => role.name === "attendee") &&
          navigate("/en/dashboard/attendee/my-tickets");
      }
    }
  }, [location.search]);

  const server = process.env.REACT_APP_SERVER;
  const { layout_settings } = useSelector((state) => state.AllData);
  const { user } = useSelector((state) => state.authUser);

  useEffect(() => {
    dispatch(
      fetchAllData({
        getData: "events",
        state: "events",
      })
    );
    dispatch(
      fetchAllData({
        getData: "categories",
        state: "categories",
      })
    );
    dispatch(
      fetchAllData({
        getData: "venues",
        state: "venues",
      })
    );
    dispatch(
      fetchAllData({
        getData: "blogs",
        state: "blogs",
      })
    );
    dispatch(
      fetchAllData({
        getData: "blog_categories",
        state: "blog_categories",
      })
    );
    dispatch(
      fetchAllData({
        getData: "layout_settings",
        state: "layout_settings",
      })
    );
  }, []);

  useEffect(() => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = `${
      layout_settings?.favicon
        ? `${server}/layout/favicon/${layout_settings?.favicon}`
        : "/./images/fav.png"
    }`;

    document.head.appendChild(link);

    return () => {
      // Clean up by removing the dynamically added link element
      document.head.removeChild(link);
    };
  }, [layout_settings?.favicon]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (user?.token) {
      dispatch(
        fetchuUserData({
          getData: "attendee/favorites",
          state: "favorites",
          token: user.token,
        })
      );
      dispatch(
        fetchuUserData({
          getData: "attendee/followings",
          state: "followings",
          token: user.token,
        })
      );
    }
  }, [user?.token]);

  // for logout
  // useEffect(() => {
  //   let last_login = "2024-02-06 03:37:56";
  //   let logoutTimer = user?.last_login;

  //   const resetLogoutTimer = () => {
  //     clearTimeout(logoutTimer);
  //     logoutTimer = setTimeout(async () => {
  //       // Perform logout actions here
  //       // setIsLoggedIn(false);
  //       try {
  //         const { data } = await axios.post(`${api}/logout`, null, {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${user.token}`,
  //           },
  //         });

  //         if (data.status === 200) {
  //           toast.success(data.message);
  //           dispatch(setLogout());
  //           navigate("/en/signin");
  //         }
  //       } catch (error) {
  //         toast.error(error?.response?.data?.message);
  //       }
  //     }, 15 * 60 * 1000); // 15 minutes in milliseconds
  //   };

  //   const handleUserActivity = () => {
  //     const last_login = formatISODate2(new Date());
  //     dispatch(setLoggedUser({ ...user, last_login }));
  //   };

  //   // Initial setup
  //   resetLogoutTimer();

  //   // Event listeners for user activity
  //   document.addEventListener("mousemove", handleUserActivity);
  //   document.addEventListener("keypress", handleUserActivity);

  //   // Cleanup
  //   return () => {
  //     clearTimeout(logoutTimer);
  //     document.removeEventListener("mousemove", handleUserActivity);
  //     document.removeEventListener("keypress", handleUserActivity);
  //   };
  // }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/en/events" element={<Events />} />
        <Route path="/en/events/:slug" element={<Events />} />
        <Route path="/en/categories" element={<EventCategory />} />
        <Route path="/en/event/:slug" element={<EventDetails />} />
        {/* for cart */}
        <Route path="/en/guest/cart" element={<NoLoginCart />} />
        <Route path="/en/guest/checkout" element={<AttendeeCheckout />} />
        <Route path="/en/attendee/checkout" element={<AttendeeCheckout />} />
        <Route path="/en/organizer/checkout" element={<AttendeeCheckout />} />
        <Route
          path="/en/attendee/checkout/:slug"
          element={<AttendeeCheckout />}
        />
        <Route
          path="/en/organizer/checkout/:slug"
          element={<AttendeeCheckout />}
        />

        <Route path="/en/venues" element={<Venues />} />
        <Route path="/en/about-us" element={<AboutUs />} />
        <Route path="en/venue/:slug" element={<VenuesDetails />} />
        <Route path="en/help-center" element={<HelpCenter />} />
        <Route path="en/help-center/:slug" element={<Support />} />
        <Route path="en/help-center/article/:slug" element={<Articles />} />
        <Route path="/en/blogs" element={<Blog />} />
        {/* <Route path="/en/blogs/category/:slug" element={<Blog />} /> */}
        <Route path="/en/blog-article/:slug" element={<BlogDetail />} />
        <Route path="en/contact" element={<Contact />} />
        <Route path="/en/signin" element={<Signin />} />
        <Route path="en/signup/attendee" element={<Attendee />} />
        <Route path="en/signup/organizer" element={<Organizer />} />
        <Route path="en/resetting/request" element={<ResetPassword />} />
        <Route path="en/resetting/check-email" element={<CheckEmail />} />
        <Route path="en/resetting/verify/:slug" element={<AddNewPassword />} />
        <Route path="en/page/pricing-and-fees" element={<PricingAndFees />} />
        <Route path="en/confirm_email" element={<ConfirmEmail />} />

        <Route
          path="en/dashboard/payment-success"
          element={<PaymentSuccess />}
        />
        <Route path="/en/page/terms-of-service" element={<TermsOfService />} />
        <Route path="/en/page/cookie-policy" element={<CookiePolicy />} />
        <Route path="/en/page/privacy-policy" element={<PrivacyPolicy />} />

        {/* attendee */}
        <Route
          path="/en/dashboard/attendee/my-reviews/:slug/add"
          element={
            <UserRoute>
              <AddReviews />
            </UserRoute>
          }
        />
        <Route
          path="/en/dashboard/attendee"
          element={
            <UserRoute>
              <AttendeeMain />
            </UserRoute>
          }
        >
          <Route index element={<Navigate to="my-tickets" />} />
          <Route path="my-tickets" element={<MyTickets />} />
          <Route path="my-tickets/:slug" element={<TicketDetail />} />
          <Route path="cart" element={<Cart />} />
          <Route path="my-wallet" element={<MyWallet />} />
          <Route path="my-wallet/:slug" element={<TicketDetail />} />
          <Route path="my-favorites" element={<MyFavorites />} />
          <Route path="my-reviews" element={<MyReviews />} />
          <Route path="following" element={<Following />} />
          <Route path="account/settings" element={<Setting />} />
          <Route path="account/change-password" element={<ChangePassword />} />
        </Route>

        {/* organizer */}
        <Route path="/en/organizer/:slug" element={<OrganizerEvents />} />
        <Route
          path="/en/dashboard/organizer"
          element={
            <OrganizerRoute>
              <OrganizerMain />
            </OrganizerRoute>
          }
        >
          {/* <Route index element={<Navigate to="my-events/add" />} /> */}
          <Route path="" element={<OrganizerDashboard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="cart" element={<Cart />} />
          <Route path="my-tickets" element={<MyTickets />} />
          <Route path="my-tickets/:slug" element={<TicketDetail />} />
          <Route path="my-wallet" element={<MyWallet />} />
          <Route path="my-wallet/:slug" element={<TicketDetail />} />
          <Route path="my-events" element={<MyEvent />} />
          <Route path="my-events/:id" element={<Stats />} />
          <Route path="my-events/add" element={<AddEvent />} />
          <Route path="my-events/:slug/edit" element={<AddEvent />} />
          <Route path="my-venues" element={<MyVenues />} />
          <Route
            path="my-venues/:id/edit"
            element={<AddVenues role="organizer" />}
          />
          <Route
            path="my-venues/add"
            element={<AddVenues role="organizer" />}
          />
          <Route path="my-scanners" element={<MyScanners />} />
          <Route path="my-scanners/:id/edit" element={<AddScanner />} />
          <Route path="my-scanners/add" element={<AddScanner />} />
          <Route path="settings/scanner-app" element={<ScannerAppSetting />} />
          <Route path="my-points-of-sale" element={<MyPointsOfSale />} />
          <Route path="my-points-of-sale/add" element={<AddPointsOfSale />} />
          <Route
            path="my-points-of-sale/:id/edit"
            element={<AddPointsOfSale />}
          />
          <Route path="reviews" element={<Reviews />} />
          <Route path="reviews/:slug" element={<Reviews />} />
          <Route path="my-payout-requests" element={<PayoutRequests />} />
          <Route
            path="my-payout-requests/add/:slug"
            element={<AddPayoutRequests />}
          />
          <Route
            path="my-payout-requests/:slug/details"
            element={<PayoutRequestDetail />}
          />
          <Route
            path="my-payout-requests/event/:slug"
            element={<PayoutRequests />}
          />
          <Route path="settings/payouts" element={<PayoutMethods />} />
          <Route
            path="settings/payouts/add/paypal_rest"
            element={<AddPaypalRest />}
          />
          <Route
            path="settings/payouts/paypal/:slug/edit"
            element={<AddPaypalRest />}
          />
          <Route
            path="settings/payouts/stripe/:slug/edit"
            element={<AddStripeCheckout />}
          />
          <Route
            path="settings/payouts/add/stripe_checkout"
            element={<AddStripeCheckout />}
          />
          <Route path="reports" element={<Reports />} />
          <Route path="account/change-password" element={<ChangePassword />} />
          <Route path="manage-orders" element={<OrganizerManageOrders />} />
          <Route
            path="manage-orders/:slug"
            element={<OrganizerOrderDetails />}
          />
          <Route
            path="manage-orders/event/:slug"
            element={<OrganizerManageOrders />}
          />
        </Route>

        {/* administrator */}
        <Route
          path="/en/dashboard/administrator"
          element={
            <AdminRoute>
              <AdministratorMain />
            </AdminRoute>
          }
        >
          <Route index path="" element={<AdminDashboard />} />
          <Route path="settings/layout" element={<Layout />} />
          <Route path="settings/menus" element={<Menus />} />
          <Route path="settings/homepage-hero" element={<HomepageHero />} />
          <Route path="settings/payment" element={<Payment />} />
          <Route path="settings/checkout" element={<Checkout />} />
          <Route path="settings/mail-server" element={<MailServer />} />
          <Route
            path="settings/google-recaptcha"
            element={<GoogleRecaptcha />}
          />
          <Route path="settings/google-maps" element={<GoogleMaps />} />
          <Route path="settings/social-login" element={<SocialLogin />} />
          <Route
            path="settings/events-list-page"
            element={<EventsListPage />}
          />
          <Route path="settings/venue-page" element={<VenuePage />} />
          <Route path="settings/blog" element={<AdminBlog />} />
          <Route path="settings/newsletter" element={<Newsletter />} />
          <Route path="manage-events" element={<ManageEvents />} />
          <Route path="manage-events/:id" element={<Stats />} />
          <Route path="manage-categories" element={<EventCategories />} />
          <Route path="manage-categories/add" element={<AddCategory />} />
          <Route path="manage-categories/:id/edit" element={<AddCategory />} />
          <Route path="manage-countries" element={<Countries />} />
          <Route path="manage-countries/add" element={<AddCountries />} />
          <Route path="manage-countries/:id/edit" element={<AddCountries />} />
          <Route path="manage-languages" element={<Language />} />
          <Route path="manage-languages/add" element={<AddLanguage />} />
          <Route path="manage-languages/:id/edit" element={<AddLanguage />} />
          <Route path="manage-audiences" element={<Audiences />} />
          <Route path="manage-audiences/add" element={<AddAudiences />} />
          <Route path="manage-audiences/:id/edit" element={<AddAudiences />} />
          <Route path="manage-orders" element={<ManageOrders />} />
          <Route path="manage-orders/:slug" element={<OrderDetails />} />
          <Route path="manage-orders/event/:slug" element={<ManageOrders />} />
          <Route
            path="manage-payout-requests"
            element={<ManagePayoutRequests />}
          />
          <Route
            path="manage-payout-requests/:slug/details"
            element={<ManagePayoutRequestDetail />}
          />
          <Route
            path="manage-payout-methods"
            element={<AdminPayoutMethods />}
          />
          <Route path="reports" element={<AdminReports />} />
          <Route path="manage-users" element={<Users />} />
          <Route path="manage-venues" element={<ManageVenues />} />
          <Route
            path="manage-venues/add"
            element={<AddVenues role="admin" />}
          />
          <Route
            path="manage-venues/:id/edit"
            element={<AddVenues role="admin" />}
          />
          <Route path="manage-venues-types" element={<ManageVenuesTypes />} />
          <Route path="manage-venues-types/add" element={<AddVenuesTypes />} />
          <Route
            path="manage-venues-types/:slug/edit"
            element={<AddVenuesTypes />}
          />
          <Route path="manage-amenities" element={<ManageAmenities />} />
          <Route path="manage-amenities/add" element={<AddAmenities />} />
          <Route
            path="manage-amenities/:slug/edit"
            element={<AddAmenities />}
          />
          <Route path="translation" element={<Translation />} />
          <Route path="console" element={<Console />} />
          <Route path="manage-pages" element={<ManagePages />} />
          <Route path="manage-reviews" element={<AdminReviews />} />
          <Route path="manage-reviews/:slug" element={<AdminReviews />} />
          <Route path="manage-blog-posts" element={<Posts />} />
          <Route path="manage-blog-posts/add" element={<AddBlogPost />} />
          <Route
            path="manage-blog-posts/:slug/edit"
            element={<AddBlogPost />}
          />
          <Route
            path="manage-blog-post-categories"
            element={<BolgCategories />}
          />
          <Route
            path="manage-blog-post-categories/add"
            element={<AddBolgCategory />}
          />
          <Route
            path="manage-blog-post-categories/:slug/edit"
            element={<AddBolgCategory />}
          />
          <Route
            path="manage-help-center/articles"
            element={<AdminArticles />}
          />
          <Route
            path="manage-help-center/articles/add"
            element={<AddArticles />}
          />
          <Route
            path="manage-help-center/articles/:slug/edit"
            element={<AddArticles />}
          />
          <Route
            path="manage-help-center/categories"
            element={<Categories />}
          />
          <Route
            path="manage-help-center/categories/add"
            element={<AddArticalCategory />}
          />
          <Route
            path="manage-help-center/categories/:slug/edit"
            element={<AddArticalCategory />}
          />
          <Route path="account/change-password" element={<ChangePassword />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
