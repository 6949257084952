import { Dropdown, Pagination, Space } from "antd";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaEdit,
  FaEllipsisV,
  FaInfoCircle,
  FaUserCheck,
  FaUserSlash,
  FaUserTimes,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Alert from "../../../../global/alert/Alert";
import {
  useDelete,
  useStatusChange,
} from "../../../../../utils/CustomFunctions";
import { useState } from "react";

export const Table = ({ data }) => {
  // this is alert maessage
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No scanners found",
  };

  //show hide and show Status
  const { loading, handleStatusChange } = useStatusChange(
    "organizer",
    "scanner_change_status",
    "scanners",
    "scanners"
  );
  const { isLoading, handleDelete } = useDelete(
    "organizer",
    "scanners",
    "scanners"
  );

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = data?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <div className="row organizer-event">
        <div className="col-12">
          {!data.length > 0 ? (
            <Alert {...msg} />
          ) : (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter">
                  <thead>
                    <tr>
                      <th style={{ width: "35%" }}>Name / Username</th>
                      <th>Creation date / Last login</th>
                      <th>Events count</th>
                      <th>Status</th>
                      <th className="text-center">
                        <AiTwotoneSetting />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((curElem) => {
                      const { id, name, user_name, created_date, status } =
                        curElem;
                      const items = [
                        {
                          label: (
                            <Link
                              to={`${id}/edit`}
                              className="d-flex align-items-center"
                            >
                              <FaEdit className="me-1" />
                              Edit
                            </Link>
                          ),
                          key: "0",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) => handleStatusChange(e, id)}
                            >
                              {status !== "active" ? (
                                <>
                                  <FaUserCheck className="me-1" /> Enabled
                                </>
                              ) : (
                                <>
                                  <FaUserSlash className="me-1" /> Disable
                                </>
                              )}
                            </Link>
                          ),
                          key: "1",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(e, id, "scanner_delete", "")
                              }
                            >
                              <FaUserTimes className="me-1" />
                              Delete
                            </Link>
                          ),
                          key: "3",
                        },
                      ];
                      return (
                        <tr key={id}>
                          <td>
                            {name} / {user_name}
                          </td>
                          <td>
                            <small>{created_date} EDT</small> <br />
                            N/A
                          </td>
                          <td>{curElem.count}</td>
                          <td className="">
                            {status === "active" ? (
                              <span className="badge badge-success d-inline-flex align-items-center">
                                <FaUserCheck className="me-1" /> Enabled
                              </span>
                            ) : (
                              <span className="badge badge-danger d-inline-flex align-items-center">
                                <FaUserSlash className="me-1" /> Disable
                              </span>
                            )}
                          </td>
                          <td className="text-center">
                            <Dropdown
                              className="table-dropdown"
                              menu={{
                                items,
                              }}
                              trigger={["click"]}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                  <FaEllipsisV />
                                </Space>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {data?.length > 10 && (
        <Pagination
          current={currentPage}
          total={data.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};
