import React from "react";
import { Table } from "./Table";
import { FaSyncAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import Filters from "../../organizer/orders/Filters";

const ManageOrders = () => {
  const { orders, events, organizers, loading } = useSelector(
    (state) => state.admin
  );
  const { filter_data } = useSelector((state) => state.organizerFilters);

  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event payoutRequests ">
        <Filters orders={orders} events={events} organizers={organizers} />
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table orders={filter_data.orders} />
      )}
    </>
  );
};

export default ManageOrders;
