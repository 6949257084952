import React from "react";
import { Table } from "./Table";
import { FaSyncAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import Filters from "./Filters";

const Reports = () => {
  const { payout_reports, events, loading } = useSelector(
    (state) => state.organizer
  );
  const { filter_data } = useSelector((state) => state.organizerFilters);

  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event reports">
        <Filters payout_reports={payout_reports} events={events} />
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table reports={filter_data.payout_reports} />
      )}
    </>
  );
};

export default Reports;
