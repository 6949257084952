import React from "react";
import { Link } from "react-router-dom";

const SearchDropdown = ({ data }) => {
  const server = process.env.REACT_APP_SERVER;

  return (
    <div
      className="dropdown-menu w-100 rounded-0 px-2"
      style={{ maxHeight: "400px", overflow: "auto" }}
    >
      {data?.length > 0 ? (
        data?.map((e, i) => (
          <figure className="dropdown-cart align-items-center" key={i}>
            <div className="aside">
              <div className="img-wrap">
                {e?.main_event_image ? (
                  <img
                    src={`${server}/event/image/${e?.main_event_image}`}
                    className="img-thumbnail"
                    width="80px"
                    height="80px"
                  />
                ) : (
                  <img
                    src="/./images/noPhoto.png"
                    className="img-thumbnail"
                    width="80px"
                    height="80px"
                  />
                )}
              </div>
            </div>
            <figcaption className="text-wrap">
              <h6 className="title m-0">
                <Link to={`/en/event/${e?.slug}`}>{e?.name}</Link>
              </h6>
              <div className="text-black-50 small mb-1">{e?.start_On}</div>
            </figcaption>
          </figure>
        ))
      ) : (
        <li style={{ fontSize: "13px" }}>No results found</li>
      )}
    </div>
  );
};

export default SearchDropdown;
