import { FaCircleInfo } from "react-icons/fa6";
import { Radio } from "antd";
import { useSelector } from "react-redux";
import { FaSyncAlt } from "react-icons/fa";
import { useSignUp } from "../../../../../utils/CustomFunctions";
import { useParams } from "react-router-dom";

const AddPaypalRest = () => {
  const { paypal } = useSelector((state) => state.organizer);
  const { slug } = useParams();
  const initialState = {
    id: paypal?.id || "",
    paypal_client_id: paypal?.paypal_client_id || "",
    paypal_client_Secret: paypal?.paypal_client_Secret || "",
    sand_box: paypal?.sand_box || "yes",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};
    if (!values.paypal_client_id)
      newErrors.paypal_client_id = "This value should not be null.";
    if (!values.paypal_client_Secret)
      newErrors.paypal_client_Secret = "This value should not be null.";
    return newErrors;
  };

  const { values, errors, isPending, handleChange, handleSubmit } = useSignUp(
    initialState,
    validateFields,
    `${slug ? "organizer/edit_paypal" : "organizer/add_paypal"} `,
    "paypal"
  );

  return (
    <>
      <div className="card box">
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form onSubmit={handleSubmit} className="signform mt-3 ">
              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Sandbox
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="sand_box"
                      checked={values.sand_box === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn "
                      name="sand_box"
                      checked={values.sand_box === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="paypal_client_id" className="form-label fw-600">
                  Paypal Client Id<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="paypal_client_id"
                  id="paypal_client_id"
                  className="form-control "
                  value={values.paypal_client_id}
                  onChange={handleChange}
                />
                {errors.paypal_client_id && (
                  <small className="text-danger mt-1 d-block">
                    {errors.paypal_client_id}
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="paypal_client_Secret"
                  className="form-label fw-600"
                >
                  Paypal Client Secret<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="paypal_client_Secret"
                  id="paypal_client_Secret"
                  className="form-control "
                  value={values.paypal_client_Secret}
                  onChange={handleChange}
                />
                {errors.paypal_client_Secret && (
                  <small className="text-danger mt-1 d-block">
                    {errors.paypal_client_Secret}
                  </small>
                )}
              </div>

              <div className="form-group mt-4 mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  disabled={isPending ? "disabled" : ""}
                  type="submit"
                >
                  {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPaypalRest;
