import React from "react";
import { useAddEntity } from "../../../../../../utils/CustomFunctions";
import SelectBox from "../../../../../global/SelectBox";
import { order } from "../../../../../global/Values";
import { FaSyncAlt } from "react-icons/fa";
import { IconPicker } from "react-fa-icon-picker";
import { Tooltip } from "antd";
import { Link, useParams } from "react-router-dom";

const Icon = ({ newEntity, setNewEntity }) => {
  const handleIconChange = (selectedValue) => {
    setNewEntity({ ...newEntity, icon: selectedValue });
  };
  return <IconPicker value={newEntity?.icon} onChange={handleIconChange} />;
};

const AddCategory = () => {
  const { id } = useParams();
  const server = process.env.REACT_APP_SERVER;
  const initialState = {
    name: "",
    icon: "",
    featured: "",
    image: "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    if (!values.name) newErrors.name = "This value should not be null.";
    if (!values.icon) newErrors.icon = "This value should not be null.";
    return newErrors;
  };
  const {
    isPending,
    errors,
    newEntity,
    setNewEntity,
    handleChange,
    handleSelectBox,
    handleIamgesChange,
    handleSubmit,
  } = useAddEntity(
    "admin",
    "event_category",
    "event_categories",
    initialState,
    validateFields,
    "/en/dashboard/administrator/manage-categories"
  );

  return (
    <div className="card">
      <div className="card-body">
        <form className="signform" onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="mb-1">
              <label htmlFor="name" className="form-label fw-600">
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control"
                value={newEntity?.name}
                onChange={handleChange}
              />
            </div>
            {errors.name && (
              <small className="text-danger d-block">{errors.name}</small>
            )}
          </div>
          <div className="mb-4">
            <div className="mb-1">
              <label htmlFor="icon" className="form-label fw-600">
                Icon<span style={{ color: "red" }}>*</span>
              </label>
              <Icon setNewEntity={setNewEntity} newEntity={newEntity} />
            </div>
            {errors.icon && (
              <small className="text-danger d-block">{errors.icon}</small>
            )}
          </div>

          <div className="mb-4">
            <div className="mb-1">
              <label htmlFor="image" className="form-label fw-600">
                Image
              </label>
              <input
                type="file"
                name="image"
                id="image"
                className="form-control"
                // value={newScanner?.image}
                onChange={handleIamgesChange}
              />
            </div>
            {id && newEntity.image && (
              <Tooltip title="Enlarge">
                <Link
                  className="fancybox"
                  to={`${server}/event/category/image/${newEntity?.image}`}
                  target="_blank"
                >
                  <img
                    src={`${server}/event/category/image/${newEntity?.image}`}
                    className="img-thumbnail re_ent_img"
                  />
                </Link>
              </Tooltip>
            )}
          </div>

          <div className="mb-4">
            <div className="mb-1">
              <label htmlFor="featured" className="form-label fw-600">
                Featured
              </label>
              <SelectBox
                name="featured"
                options={order}
                val={newEntity?.featured}
                change={handleSelectBox}
              />
            </div>
          </div>

          <div className="form-group mt-3 mb-3">
            <button
              className="btn btn-dark rounded-5"
              disabled={isPending ? "disabled" : ""}
              type="submit"
            >
              {isPending ? <FaSyncAlt className="spinIcon" /> : "save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCategory;
