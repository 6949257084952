import React, { useEffect, useState } from "react";
import ArticleSidebar from "../sidebar/ArticleSidebar";
import { Layout } from "../LayoutUser/Layout";
import Breadcrumb from "../global/Breadcrumb3";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Contactus from "./help/Contactus";
import { fetchAllData } from "./../../store/slice/AllData";

const Articles = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const { articles } = useSelector((state) => state.AllData);
  const [artical, setArtical] = useState(null);
  useEffect(() => {
    if (slug) {
      const foundArticle = articles.find((article) => article.slug === slug);
      setArtical(foundArticle || null);
    }
  }, [slug, articles]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchAllData({
        getData: "articles",
        state: "articles",
      })
    );
    dispatch(
      fetchAllData({
        getData: "article-categories",
        state: "article_categories",
      })
    );
  }, []);

  return (
    <Layout>
      <Breadcrumb
        page={artical?.name}
        link="help-center"
        link2={artical?.article_category}
      />
      <div className="container blog mt-4">
        <div className="row">
          {artical && (
            <div className="col-lg-9 order-1 order-lg-2 mb-5">
              <div className="card box pe-3 ps-3 pt-3 pb-3">
                <div className="card-body helpc">
                  <header className="section-heading mb-5">
                    <h1 className="title-section">{artical?.name}</h1>
                  </header>
                  <article>
                    <p>
                      <span
                        style={{ fontSize: "12.88px" }}
                        dangerouslySetInnerHTML={{
                          __html: artical?.description,
                        }}
                      ></span>
                      <br />
                    </p>
                  </article>
                  <hr />
                  <div className="row">
                    <div className="col-12 text-center text-lg-left">
                      {(artical?.tags || artical.tags?.length > 0) && (
                        <div className="row">
                          <div className="col">
                            {artical?.tags?.map((tag, index) => {
                              return (
                                <Link
                                  to=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/en/help-center", { state: tag });
                                  }}
                                  className="btn btn-tags btn-sm me-3 mb-3 mt-3"
                                  key={index}
                                >
                                  {tag}
                                </Link>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr />
                  <Contactus />
                </div>
              </div>
            </div>
          )}
          <div className="col-lg-3 order-2 order-lg-1 mb-5">
            <ArticleSidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Articles;
