import React from "react";
import Breadcrumb from "../../components/global/Breadcrumb";
import { Link } from "react-router-dom";
import {
  FaEnvelopeOpenText,
  FaLifeRing,
  FaMapMarker,
  FaSyncAlt,
} from "react-icons/fa";
import "./style.css";
import { Layout } from "../../components/LayoutUser/Layout";
import { useSignUp } from "../../utils/CustomFunctions";

const Contact = () => {
  const initialState = {
    email: "",
    name: "",
    subject: "",
    message: "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    if (!values.email.trim())
      newErrors.email = "This value should not be null.";
    if (!values.name.trim()) newErrors.name = "This value should not be null.";
    if (!values.subject.trim())
      newErrors.subject = "This value should not be null.";
    if (!values.message.trim())
      newErrors.message = "This value should not be null.";
    return newErrors;
  };

  // use custom hook for cahnge password
  const { values, errors, isPending, handleChange, handleSubmit } = useSignUp(
    initialState,
    validateFields,
    `contact`
  );
  return (
    <Layout>
      <Breadcrumb page={"Contact Us"} />
      <div className="container contact mb-4 mt-4 ">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <h1 className="text-center mb-5">FAQ</h1>
                <h4 className="text-center mt-5">
                  <Link to="/en/help-center">
                    <FaLifeRing /> Help center
                  </Link>
                </h4>
                <hr className="mt-5 mb-5"></hr>
                <h1 className="text-center mb-5">Contact information</h1>
                <div className="row">
                  <div className="col-12 col-lg-4 card border-0 card-body text-center shadow-none">
                    <figure className="itemside">
                      <div className="aside">
                        <span className="icon-wrap icon-md round bg-info">
                          <FaEnvelopeOpenText />
                        </span>
                      </div>
                      <figcaption className="text-wrap align-self-center">
                        <h6 className="title">support@feevaa.com</h6>
                      </figcaption>
                    </figure>
                  </div>

                  <div className="col card border-0 card-body text-center shadow-none">
                    <figure className="itemside">
                      <div className="aside">
                        <span className="icon-wrap icon-md round bg-info">
                          <FaMapMarker />
                        </span>
                      </div>
                      <figcaption className="text-wrap align-self-center">
                        <h6 className="title">
                          2730 Peachtree Industrial Blvd Ste 105, Duluth, GA
                          30096
                        </h6>
                      </figcaption>
                    </figure>
                  </div>
                </div>
                <hr className="mt-5 mb-5" />

                <h1 className="text-center mb-5">Send us an email</h1>
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    <form
                      onSubmit={handleSubmit}
                      className="signform contact-form"
                    >
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name<span>*</span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          value={values.name}
                          onChange={handleChange}
                        />
                        {errors.name && (
                          <small className="text-danger d-block mt-1">
                            {errors.name}
                          </small>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email<span>*</span>
                        </label>
                        <input
                          id="email"
                          type="email"
                          name="email"
                          className="form-control"
                          value={values.email}
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <small className="text-danger d-block mt-1">
                            {errors.email}
                          </small>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="subject" className="form-label">
                          Subject<span>*</span>
                        </label>
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          className="form-control"
                          value={values.subject}
                          onChange={handleChange}
                        />
                        {errors.subject && (
                          <small className="text-danger d-block mt-1">
                            {errors.subject}
                          </small>
                        )}
                      </div>

                      <div className="mb-3">
                        <label htmlFor="message" className="form-label">
                          Message<span>*</span>
                        </label>
                        <textarea
                          className="form-control"
                          name="message"
                          id="message"
                          rows="3"
                          value={values.message}
                          onChange={handleChange}
                        ></textarea>

                        {errors.message && (
                          <small className="text-danger d-block mt-1">
                            {errors.message}
                          </small>
                        )}
                      </div>

                      <div className="form-group mt-3 mb-3">
                        <button
                          className="btn btn-dark w-100 rounded-5"
                          disabled={isPending ? "disabled" : ""}
                          type="submit"
                        >
                          {isPending ? (
                            <FaSyncAlt className="spinIcon" />
                          ) : (
                            "send"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
