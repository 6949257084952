import { Dropdown, Menu, Space } from "antd";
import React, { useState } from "react";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaChevronRight,
  FaEllipsisV,
  FaEnvelope,
  FaExclamationCircle,
  FaFileInvoice,
  FaMoneyCheck,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Alert from "../../../../global/alert/Alert";
import { formatDate } from "../../../../../utils/CustomFunctions";

export const Table = ({ data }) => {
  const msg = {
    icon: <FaExclamationCircle className="me-3" />,
    type: "alert-info",
    text: "No tickets found",
  };

  // payment === "pending" && {
  //   label: (
  //     <Link
  //       to=""
  //       className="d-flex align-items-center"
  //       onClick={(e) => e.preventDefault()}
  //     >
  //       <FaEnvelope className="me-1" />
  //       Contact the organizer
  //     </Link>
  //   ),
  //   key: "1",
  // },

  return (
    <div className="row organizer-event">
      <div className="col-12">
        {!data.length > 0 ? (
          <Alert {...msg} />
        ) : (
          <div className="card">
            <div className="table-responsive">
              <table className="table table-hover table-vcenter">
                <thead>
                  <tr>
                    <th className="text-center">Reference</th>
                    <th>Organizer / Event / Date / Ticket</th>
                    <th>Order date </th>
                    <th>Status</th>
                    <th className="text-center">
                      <AiTwotoneSetting />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((ticket, i) => {
                    const {
                      id,
                      reference,
                      date,
                      ticket_quantity,
                      payment,
                      event,
                    } = ticket;
                    const items = [
                      payment === "pending" && {
                        label: (
                          <Link
                            to={`/en/attendee/checkout/${reference}`}
                            className="d-flex align-items-center"
                          >
                            <FaMoneyCheck className="me-1" /> Checkout
                          </Link>
                        ),
                        key: "0",
                      },
                      {
                        label: (
                          <Link
                            to={reference}
                            className="d-flex align-items-center"
                          >
                            <FaFileInvoice className="me-1" />
                            Details
                          </Link>
                        ),
                        key: "2",
                      },
                    ];

                    const eventstartDate = new Date(event?.start_On);
                    const ticketOderDate = new Date(date);
                    const eventDate = formatDate(eventstartDate).fullDate;
                    const ticketDate = formatDate(ticketOderDate).fullDate;
                    return (
                      <tr key={id}>
                        <td className="text-center text-muted text-sm">
                          <Link to={reference}>{reference}</Link>
                        </td>
                        <td className="text-sm">
                          <ul className="p-0" style={{ listStyle: "circle" }}>
                            <li>
                              <Link to={`/en/organizer/${event?.user?.slug}`}>
                                {event?.user?.organize_name}
                              </Link>
                              <FaChevronRight />
                              <Link to={`/en/event/${event?.slug}`}>
                                {event?.name} -{" "}
                                {event?.event_date_online === 1 ? (
                                  "Online"
                                ) : (
                                  <>
                                    {event?.venue?.city}
                                    {event?.venue?.state}
                                  </>
                                )}
                              </Link>
                              <FaChevronRight />
                              {eventDate}
                              <FaChevronRight />
                              {event?.tickets?.[0]?.ticket_name}
                              <br />
                              <small>
                                <strong>{ticket_quantity} ticket</strong>
                              </small>
                            </li>
                          </ul>
                        </td>

                        <td>{ticketDate}</td>

                        <td>
                          <span
                            className={`badge badge-${
                              payment === "pending" ? "warning" : "success"
                            } text-capitalize`}
                          >
                            {payment} payment
                          </span>
                        </td>
                        <td className="text-center">
                          <Dropdown
                            className="table-dropdown"
                            menu={{
                              items,
                            }}
                            trigger={["click"]}
                          >
                            <a onClick={(e) => e.preventDefault()}>
                              <Space>
                                <FaEllipsisV />
                              </Space>
                            </a>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
