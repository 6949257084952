import { FaCircleInfo } from "react-icons/fa6";
import "../layout/style.scss";
import { Radio } from "antd";
import Count from "../../../../../count/Count";
import { useSignUp } from "../../../../../../utils/CustomFunctions";
import { useSelector } from "react-redux";
import { FaSyncAlt } from "react-icons/fa";

const AdminBlog = () => {
  const { blog_setting } = useSelector((state) => state.admin);

  const initialState = {
    number: blog_setting?.number || "",
    app_id: blog_setting?.app_id || "",
    subdomain: blog_setting?.subdomain || "",
    enable_comments: blog_setting?.enable_comments || "facebook",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    if (!values.number) newErrors.number = "This value should not be null.";

    return newErrors;
  };

  const { values, setValues, errors, isPending, handleChange, handleSubmit } =
    useSignUp(
      initialState,
      validateFields,
      `admin/add_blog_setting`,
      "blog_setting"
    );

  return (
    <>
      <div className="card box">
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form onSubmit={handleSubmit} className="signform mt-3 ">
              <div className="mb-3">
                <label htmlFor="Facebook_app_id" className="form-label fw-600">
                  Number of blog posts per page
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Count
                  name="number"
                  value={values.number}
                  state="values"
                  setState={setValues}
                />
                {errors.number && (
                  <small className="text-danger d-block mt-1">
                    {errors.number}
                  </small>
                )}
              </div>
              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Enable comments
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="enable_comments"
                      checked={values.enable_comments === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn "
                      name="enable_comments"
                      checked={values.enable_comments === "facebook"}
                      onChange={handleChange}
                      value="facebook"
                    >
                      Facebook comments
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="enable_comments"
                      checked={values.enable_comments === "disqus"}
                      onChange={handleChange}
                      value="disqus"
                    >
                      Disqus comments
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="Facebook_app_id" className="form-label fw-600">
                  Facebook app id
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    Go to the documentation to get help about getting an app ID
                  </span>
                </div>
                <input
                  type="text"
                  name="app_id"
                  id="Facebook_app_id"
                  className="form-control"
                  value={values.app_id}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="subdomain" className="form-label fw-600">
                  Disqus subdomain
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    Go to the documentation to get help about setting up Disqus
                  </span>
                </div>
                <input
                  type="text"
                  name="subdomain"
                  id="subdomain"
                  className="form-control"
                  value={values.subdomain}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group mt-4 mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  disabled={isPending ? "disabled" : ""}
                  type="submit"
                >
                  {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminBlog;
