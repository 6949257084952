import React, { forwardRef } from "react";
import Select from "react-select";
import styled from "styled-components";

const Wrapper = styled.section`
  .select__control {
    /* border-radius: 2rem; */
    ${"" /* font-size: 1.6rem; */}
  }
  .select__input-container {
    margin: 0;
    padding: 0;
  }
  .select__value-container {
    padding: 0rem 0.5rem;
  }
  .select__indicator {
    ${"" /* padding: 1.3rem; */}
  }

  &.is-invalid {
    border-color: red;
    outline-color: red;
  }
  .css-b62m3t-container:focus-visible {
    outline: 0;
  }
  .select__menu {
    z-index: 99999999;
  }
`;

const SelectBox = forwardRef(({ options, val, name, change }, ref) => {
  const Options = options?.map((curElem) => {
    return { value: curElem.id, label: curElem.name };
  });

  // Options.splice(0, 0, {
  //   value: "",
  //   label: "--Please Select--",
  //   isDisabled: true,
  // });
  // { value: main._id, label: main.name, isFixed: true },

  // console.log(category[name]);
  // is-invalid
  let valu;
  if (name === "year") {
    valu = parseInt(val, 10);
  } else {
    valu = val;
  }
  return (
    <Wrapper>
      <Select
        ref={ref}
        value={Options?.filter(({ value }) => value == valu)}
        // className={` ${errors.name && "is-invalid"}`}
        classNamePrefix="select"
        // defaultValue={Options[0]}
        isDisabled={false}
        isLoading={false}
        isClearable={true}
        isRtl={false}
        isSearchable={true}
        name={name}
        options={Options}
        placeholder="--Please Select--"
        onChange={(e) =>
          e === null ? change(name, "") : change(name, e.value)
        }
      ></Select>
    </Wrapper>
  );
});

export default SelectBox;
