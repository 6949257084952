import React from "react";
import { useSignUp } from "../../../../../../utils/CustomFunctions";
import { Modal } from "antd";
import { FaSyncAlt } from "react-icons/fa";

const MailModel = ({ openModel, setOpenModel }) => {
  const initialState = {
    email: "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    if (!values.email.trim())
      newErrors.email = "This value should not be null.";

    return newErrors;
  };

  const { values, errors, isPending, handleChange, handleSubmit } = useSignUp(
    initialState,
    validateFields,
    `admin/mail_server_test`
  );

  return (
    <Modal
      // title="Vertically centered modal dialog"
      centered
      open={openModel}
      onCancel={() => setOpenModel(false)}
      // className="Model"
      width={"400px"}
    >
      <div className="ModelBox">
        <div className="ModelHeader">
          <span className="fs-4">Mail server test email</span>
        </div>
        <div className="jconfirm-content" id="jconfirm-box27525">
          <div>
            By clicking on send, an email is going to be sent to the chosen
            email address. Make sure to save the configuration to be tested
            before testing.
            <input
              id="mail-test-email-address"
              type="email"
              name="email"
              className="form-control mt-3"
              placeholder="Email address"
              onChange={handleChange}
              value={values.email}
            />
            {errors.email && (
              <small className="text-danger d-block mt-1">{errors.email}</small>
            )}
          </div>
        </div>
      </div>

      <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
        <button
          className="btn btn-dark btn-sm rounded-5 d-inline-flex align-items-center gap-2"
          disabled={isPending ? "disabled" : ""}
          onClick={(e) => {
            handleSubmit(e);
            // setOpenModel(false);
          }}
        >
          {isPending ? <FaSyncAlt className="spinIcon" /> : "Send"}
        </button>
        <button
          className="btn btn-secondary btn-sm rounded-5 d-inline-flex align-items-center gap-2"
          onClick={() => setOpenModel(false)}
        >
          close
        </button>
      </div>
    </Modal>
  );
};

export default MailModel;
