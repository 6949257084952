import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const api = process.env.REACT_APP_SERVER_API;

export const adminFetchData = createAsyncThunk(
  "admin/adminFetchData",
  async ({ endPoint, token, state }, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${api}/${endPoint}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (
        state === "blog_setting" ||
        state === "event_summary" ||
        state === "event_setting" ||
        state === "google_maps_settings" ||
        state === "google_repatcha_settings" ||
        state === "home_page_setting" ||
        state === "layout_settings" ||
        state === "news_litter" ||
        state === "order_summary" ||
        state === "payment_fees" ||
        state === "paypal" ||
        state === "social_login_settings" ||
        state === "stripe" ||
        state === "time_left" ||
        state === "user_summary" ||
        state === "venue_page_settings"
      ) {
        return { data: res.data.data, state };
      } else if (state === "reports") {
        const sortData = [...res.data.data].sort(
          (a, b) => b.event.id - a.event.id
        );
        return { data: sortData, state };
      } else {
        const sortData = [...res.data.data].sort((a, b) => b.id - a.id);
        return { data: sortData, state };
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  events: [],
  event_categories: [],
  orders: [],
  venues: [],
  amenities: [],
  venue_types: [],
  audiences: [],
  countries: [],
  languages: [],
  reports: [],
  payout_requests: [],
  users: [],
  blog_categories: [],
  blogs: [],
  news_litter: {},
  mail_server: {},
  blog_setting: {},
  venue_page_settings: {},
  event_setting: {},
  social_login_settings: {},
  google_maps_settings: {},
  google_repatcha_settings: {},
  time_left: {},
  payment_fees: {},
  home_page_setting: {},
  layout_settings: {},
  organizers: [],
  reviews: [],
  article_categories: [],
  articles: [],
  error: {},
  user_summary: {},
  order_summary: {},
  event_summary: {},
  stripe: {},
  paypal: {},
  loading: false,
  iserror: false,
};
const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    deleteSingle: (state, action) => {
      const { id, state: dataState } = action.payload;
      state[dataState] = state[dataState].filter((state) => state.id !== id);
    },
    addData: (state, action) => {
      const { state: dataState, data } = action.payload;
      state[dataState] = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminFetchData.pending, (state, action) => {
        state.loading = true;
        state.iserror = false;
      })
      .addCase(adminFetchData.fulfilled, (state, action) => {
        const { data, state: dataState } = action.payload;
        state[dataState] = data;
        state.loading = false;
        state.iserror = false;
        state.error = {};
      })
      .addCase(adminFetchData.rejected, (state, action) => {
        state.error = action.payload;
        state.iserror = true;
        state.loading = false;
      });
  },
});

export const { deleteSingle, addData } = AdminSlice.actions;
export default AdminSlice.reducer;
