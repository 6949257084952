import React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import Alert from "./../../../../global/alert/Alert";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Following = () => {
  const { followings, loading } = useSelector((state) => state.attendee);
  const server = process.env.REACT_APP_SERVER;
  const msg = {
    icon: <FaExclamationCircle className="me-3" />,
    type: "alert-info",
    text: "You are not following any organizer",
  };

  return (
    <>
      {followings.length > 0 ? (
        <div className="card box">
          <div className="card-body">
            <div className="row">
              {followings.map((curElem, i) => (
                <div className="col-12 col-sm-6 col-lg-6 mb-3" key={i}>
                  <div className="card">
                    <figure className="d-flex gap-2">
                      <div className="aside">
                        <div className="img-wrap img-sm d-flex">
                          {curElem?.organizers?.[0]?.organizer_logo ? (
                            <img
                              className="align-self-center"
                              src={`${server}/organizer/logo/${curElem?.organizers?.[0]?.organizer_logo}`}
                              alt=""
                              width="80"
                            />
                          ) : (
                            <img
                              className="align-self-center"
                              src="/./images/noPhoto.png"
                              alt="FeeVaa Events"
                              width="80"
                            />
                          )}
                        </div>
                      </div>
                      <figcaption className="p-2 align-self-center">
                        <h6 className="tit le">
                          {curElem?.organizers?.[0]?.organize_name}
                        </h6>
                        <Link
                          to={`/en/organizer/${curElem?.organizers?.[0]?.slug}`}
                        >
                          More details
                        </Link>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Alert {...msg} />
      )}
    </>
  );
};

export default Following;
