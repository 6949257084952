import { FaCircleInfo } from "react-icons/fa6";
import "../layout/style.scss";
import { Radio } from "antd";
import { useSelector } from "react-redux";
import { useSignUp } from "../../../../../../utils/CustomFunctions";
import { FaSyncAlt } from "react-icons/fa";

const GoogleRecaptcha = () => {
  const { google_repatcha_settings } = useSelector((state) => state.admin);

  const initialState = {
    google_repatcha: google_repatcha_settings?.google_repatcha || "yes",
    site_key: google_repatcha_settings?.site_key || "",
    secret_key: google_repatcha_settings?.secret_key || "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};
    return newErrors;
  };

  const { values, isPending, handleChange, handleSubmit } = useSignUp(
    initialState,
    validateFields,
    `admin/add_google_repatcha_settings`,
    "google_repatcha_settings"
  );
  return (
    <>
      <div className="card box">
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form onSubmit={handleSubmit} className="signform mt-3 ">
              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Enable Google Repatcha
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="google_repatcha"
                      checked={values.google_repatcha === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn "
                      name="google_repatcha"
                      checked={values.google_repatcha === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="site_key" className="form-label fw-600">
                  Site key
                </label>
                <input
                  type="text"
                  name="site_key"
                  id="site_key"
                  className="form-control"
                  value={values.site_key}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="secret_key" className="form-label fw-600">
                  Secret key
                </label>
                <input
                  type="text"
                  name="secret_key"
                  id="secret_key"
                  className="form-control"
                  value={values.secret_key}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mt-4 mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  disabled={isPending ? "disabled" : ""}
                  type="submit"
                >
                  {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleRecaptcha;
