import { useDispatch, useSelector } from "react-redux";
import ReviewCard from "./ReviewCard";
import "./style.css";
import { FaExclamationCircle, FaSyncAlt } from "react-icons/fa";
import Alert from "../../../../global/alert/Alert";
import { fetchuUserData } from "../../../../../store/slice/Attendee";
import { useEffect } from "react";

const MyReviews = () => {
  const { myRviews, loading } = useSelector((state) => state.attendee);
  const { user } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();

  const msg = {
    icon: <FaExclamationCircle className="me-3" />,
    type: "alert-info",
    text: "No reviews found",
  };

  useEffect(() => {
    dispatch(
      fetchuUserData({
        getData: "attendee/user_event_reviews",
        state: "myRviews",
        token: user.token,
      })
    );
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : myRviews?.length > 0 ? (
        <div className="card review">
          <div className="card-body">
            {myRviews?.map((review, index) => (
              <ReviewCard review={review} key={index} />
            ))}
          </div>
        </div>
      ) : (
        <Alert {...msg} />
      )}
    </>
  );
};

export default MyReviews;
