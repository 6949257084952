import React from "react";
import TopBar from "./topbar/TopBar";
import Menu from "./menu/Menu";

const Header = () => {
  return (
    <>
      <TopBar />
      <Menu />
    </>
  );
};

export default Header;
