import React, { useState } from "react";
import { FaCircleInfo } from "react-icons/fa6";
import { Radio } from "antd";
import "./scannerappsetting.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { FaSyncAlt } from "react-icons/fa";
import { useSelector } from "react-redux";

const ScannerAppSetting = () => {
  const api = process.env.REACT_APP_SERVER_API;
  const [isPending, setPending] = useState(false);
  const { scanner_setting } = useSelector((state) => state.organizer);
  const { user } = useSelector((state) => state.authUser);

  const [values, setValues] = useState({
    date: scanner_setting?.date || "yes",
    check: scanner_setting?.check || "yes",
  });

  // handle changes feilds
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  // register attendee
  const handleSubmit = async () => {
    try {
      setPending(true);
      const { data } = await axios.post(
        `${api}/organizer/add_scanner_setting`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      data.status == 200 && toast.success(data.message);
      setPending(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setPending(false);
    }
  };

  return (
    <>
      <div className="ScannerAppSettingSection">
        <div className="ScannerAppSettingOptionsBox">
          <span className="ScannerAppSettingOptionTitle">
            Show Event date stats on the scanner app{" "}
            <span style={{ color: "red" }}>*</span>
          </span>

          <div className="ScannerAppSettingOptionDescBox">
            <FaCircleInfo className="ScannerAppSettingOptionDescIcon" />
            <span className="ScannerAppSettingOptionDesc">
              The event date stats (sales and attendance) will be visible on the
              scanner app
            </span>
          </div>

          <div className="ScannerAppSettingRadioBtns">
            <Radio
              className="ProfileSettingRadioBtn"
              name="date"
              checked={values.date === "yes"}
              onChange={handleChange}
              value="yes"
            >
              Yes
            </Radio>
            <Radio
              className="ProfileSettingRadioBtn"
              name="date"
              checked={values.date === "no"}
              onChange={handleChange}
              value="no"
            >
              No
            </Radio>
          </div>
        </div>
        <div className="ScannerAppSettingOptionsBox">
          <span className="ScannerAppSettingOptionTitle">
            Allow tap to check in on the scanner app{" "}
            <span style={{ color: "red" }}>*</span>
          </span>

          <div className="ScannerAppSettingOptionDescBox">
            <FaCircleInfo className="ScannerAppSettingOptionDescIcon" />
            <span className="ScannerAppSettingOptionDesc">
              Besides the qr code scanning feature, the scanner account will be
              able to check in the attendees using a list and a button
            </span>
          </div>

          <div className="ScannerAppSettingRadioBtns">
            <Radio
              className="ProfileSettingRadioBtn"
              name="check"
              checked={values.check === "yes"}
              onChange={handleChange}
              value="yes"
            >
              Yes
            </Radio>
            <Radio
              className="ProfileSettingRadioBtn"
              name="check"
              checked={values.check === "no"}
              onChange={handleChange}
              value="no"
            >
              No
            </Radio>
          </div>
        </div>

        <button
          className="btn btn-dark rounded-5"
          disabled={isPending ? "disabled" : ""}
          type="button"
          onClick={handleSubmit}
        >
          {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
        </button>
      </div>
    </>
  );
};

export default ScannerAppSetting;
