import React, { useEffect, useState } from "react";
import "./featurescategories.scss";
import Card from "../../Card/Card";
import { FaInfoCircle, FaSyncAlt } from "react-icons/fa";
import Alert from "../../global/alert/Alert";
import { useSelector } from "react-redux";

export default function FeaturedCategories() {
  const { categories, home_page_setting, loading } = useSelector(
    (state) => state.AllData
  );
  const [categorie, setCategorie] = useState([]);
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No categories found",
  };

  useEffect(() => {
    const cat = categories.filter((category) => category.featured !== null);
    const sortedcategories = [...cat].sort((a, b) => a.featured - b.featured);
    const limitedData = sortedcategories.slice(
      0,
      home_page_setting?.number_category
        ? home_page_setting?.number_category
        : 20
    );
    setCategorie(limitedData);
  }, [categories, home_page_setting]);

  return (
    <>
      <div className="container-fluid FeaturedCategoriesSection pt-4">
        <div className="container pt-5 pb-4">
          <h4 className="FeaturedCategoriesTitle text-muted text-center mb-5">
            FEATURED CATEGORIES
          </h4>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <FaSyncAlt className="spinIcon" />
            </div>
          ) : (
            <div className="row">
              {categorie?.length > 0 ? (
                <Card data={categorie} />
              ) : (
                <Alert {...msg} />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
