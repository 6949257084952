import { FaRegCalendar, FaRss } from "react-icons/fa";
import "./style.css";
import { useSelector } from "react-redux";

const Top = () => {
  const { event_setting } = useSelector((state) => state.AllData);
  const { filter_events } = useSelector((state) => state.Filters);
  return (
    <section className="topbar mt-4 bg-gray">
      <div className="row pt-4 pb-4 ps-3 pe-3">
        <div className="col-sm-12 col-lg-6 mb-3 mb-lg-0 text-center text-lg-start">
          <span className="text-muted">
            {filter_events.length} event(s) found
          </span>
        </div>
        <div className="col-sm-12 col-lg-6 text-center text-lg-end ">
          {/* {event_setting?.calendar === "yes" && (
            <a href="#" className="me-2">
              <FaRegCalendar
                className="calendar"
                title="Show events calendar"
              />
            </a>
          )} */}
          {event_setting?.rss === "yes" && (
            <a href="#" target="_blank">
              <FaRss className="rss" title="Events RSS feed" />
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

export default Top;
