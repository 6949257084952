import React, { useEffect } from "react";
import PayoutMethodsCard from "../payoutMethodsCards/payoutMethodsCard";
import { AiFillSetting } from "react-icons/ai";
import "./payoutmethod.scss";
import paypaImg from "../../../../../assets/images/paypal.svg";
import stripeImg from "../../../../../assets/images/stripe.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../../../store/slice/Organizer";

const PayoutMethods = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);
  const { paypal, stripe } = useSelector((state) => state.organizer);

  useEffect(() => {
    dispatch(
      fetchData({
        getData: "organizer/paypal",
        token: user.token,
        state: "paypal",
      })
    );
    dispatch(
      fetchData({
        getData: "organizer/stripe",
        token: user.token,
        state: "stripe",
      })
    );
  }, []);

  return (
    <div className="OrganizerPayoutMethodsSection">
      <div className="OrganizerPayoutMethodsTitleBox">
        <span className="OrganizerPayoutMethodsTitle">Payout methods</span>
      </div>
      <div className="OrganizerPayoutMethodsHeader">
        <div className="OrganizerPayoutMethodsHeaderWrapper">
          <span className="OrganizerPayoutMethodsHeaderText">
            Payout Method
          </span>
          <span className="OrganizerPayoutMethodsHeaderText">Status</span>
        </div>

        <AiFillSetting className="OrganizerPayoutMethodsHeaderText" />
      </div>
      <div className="OrganizerPayoutMethodsContainer">
        <PayoutMethodsCard
          name="paypal"
          img={paypaImg}
          url="paypal_rest"
          data={paypal}
          delurl="delete_paypal"
          editurl="edit_paypal"
        />
        <PayoutMethodsCard
          name="stripe"
          img={stripeImg}
          url="stripe_checkout"
          data={stripe}
          delurl="delete_stripe"
          editurl="edit_stripe"
        />
      </div>
    </div>
  );
};

export default PayoutMethods;
