import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import "./adminsidebar.scss";
import { Link, useLocation } from "react-router-dom";
import {
  FaFolderOpen,
  FaRegHeart,
  FaRegStar,
  FaShoppingCart,
  FaTicketAlt,
  FaUserCog,
} from "react-icons/fa";
import { BsFillWalletFill } from "react-icons/bs";

const items = [
  {
    icon: <FaTicketAlt />,
    key: "1",
    label: "My Tickets",
    type: "link",
    path: "my-tickets",
  },
  {
    icon: <FaShoppingCart />,
    key: "2",
    label: "Cart",
    type: "link",
    path: "cart",
  },
  {
    icon: <BsFillWalletFill />,
    key: "2.1",
    label: "My Wallet",
    type: "link",
    path: "my-wallet",
  },
  {
    icon: <FaRegHeart />,
    key: "3",
    label: "My Favorites",
    type: "link",
    path: "my-favorites",
  },
  {
    icon: <FaRegStar />,
    key: "4",
    label: "My Reviews",
    type: "link",
    path: "my-reviews",
  },
  {
    icon: <FaFolderOpen />,
    key: "5",
    label: "Following",
    type: "link",
    path: "following",
  },
  {
    icon: <FaUserCog />,
    key: "sub6",
    label: "Account",
    children: [
      {
        label: "Settings",
        key: "6",
        path: "account/settings",
      },
      {
        label: "Change Password",
        key: "7",
        path: "account/change-password",
      },
    ],
  },
];

const AttendeeSidebar = () => {
  const { pathname } = useLocation();
  // Function to find the selected key
  const findSelectedKey = (items, pathname) => {
    for (const item of items) {
      if (item.children) {
        const childItem = item.children.find(
          (child) => child.path === pathname
        );
        if (childItem) {
          return [item.key, childItem.key];
        }
      } else if (item.path === pathname) {
        return [item.key];
      }
    }
    return [];
  };
  // State to store the open keys and selected keys
  const [openKeys, setOpenKeys] = useState(() => {
    // Initialize with the value from localStorage or default to an empty array
    const storedOpenKeys = localStorage.getItem("openKeys");
    return storedOpenKeys ? JSON.parse(storedOpenKeys) : [];
  });

  const [selectedKeys, setSelectedKeys] = useState(() => {
    const storedSelectedKeys = localStorage.getItem("selectedKeys");
    return storedSelectedKeys ? JSON.parse(storedSelectedKeys) : [];
  });
  // Update the open keys and selected keys when pathname changes
  useEffect(() => {
    const newSelectedKeys = findSelectedKey(items, pathname);
    setOpenKeys(newSelectedKeys.slice(0, -1)); // Exclude the last key from openKeys
    setSelectedKeys(newSelectedKeys);
    // Save the open keys and selected keys to localStorage
    localStorage.setItem(
      "openKeys",
      JSON.stringify(newSelectedKeys.slice(0, -1))
    );
    localStorage.setItem("selectedKeys", JSON.stringify(newSelectedKeys));
  }, [pathname, items]);

  return (
    <div>
      <div className="Sidebar">
        <Menu
          mode="inline"
          onOpenChange={(newOpenKeys) => {
            setOpenKeys(newOpenKeys);
            // Save the open keys to localStorage
            localStorage.setItem("openKeys", JSON.stringify(newOpenKeys));
          }}
          selectedKeys={selectedKeys}
        >
          {items.map((item) =>
            item.children ? (
              <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
                {item.children.map((childItem) => (
                  <Menu.Item key={childItem.key}>
                    <Link to={childItem.path}>{childItem.label}</Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ) : (
              <Menu.Item key={item.key} icon={item.icon}>
                <Link to={item.path}>{item.label}</Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </div>
    </div>
  );
};
export default AttendeeSidebar;
