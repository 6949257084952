import { Dropdown, Modal, Pagination, Space } from "antd";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaEllipsisV,
  FaFileAlt,
  FaInfoCircle,
  FaNetworkWired,
  FaRegIdCard,
  FaRegUser,
  FaRegWindowClose,
  FaTrashRestoreAlt,
  FaUserAltSlash,
  FaUserCheck,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Alert from "../../../../global/alert/Alert";
import { IoCloseSharp } from "react-icons/io5";
import {
  formatDate,
  useDelete,
  useStatusChange,
} from "../../../../../utils/CustomFunctions";
import { MdDelete } from "react-icons/md";
import { useState } from "react";

const Table = ({ users }) => {
  const server = process.env.REACT_APP_SERVER;
  const [openModel, setOpenModel] = useState(false);
  const [modelUser, setModelUser] = useState({});
  // this is alert maessage
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No users found",
  };

  // for state change
  const { loading, handleStatusChange } = useStatusChange(
    "admin",
    "user_change_status",
    "users",
    "users",
    "user_summary"
  );
  // for soft delete and parmanently delete
  const { isLoading, handleDelete } = useDelete(
    "admin",
    "users",
    "users",
    "user_summary"
  );

  const handleModelEvent = (e, id) => {
    e.preventDefault();
    setModelUser(users.find((user) => user?.id === id));
    setOpenModel(true);
  };

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = users?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={openModel}
        onCancel={() => setOpenModel(false)}
        // className="Event Model"
      >
        {openModel && (
          <>
            <div className="ModelBox">
              <div className="ModelHeader">
                <span className="ModelBoxBuyTicketText fs-3">
                  User information
                </span>
              </div>
              <div className="ModelContent">
                <div className="jconfirm-content" id="jconfirm-box19401">
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table className="table table-borderless table-striped table-hover table-sm">
                            <thead>
                              <tr>
                                <th colSpan="2" className="">
                                  <FaFileAlt className="me-1" />
                                  Account information
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td width="30%">Roless</td>
                                <td className="text-capitalize">
                                  {modelUser?.roles?.[0]?.name}
                                </td>
                              </tr>
                              <tr>
                                <td width="30%">Status</td>
                                <td>
                                  <td className="">
                                    <span
                                      className={`badge badge-${
                                        modelUser?.status === "active"
                                          ? "success"
                                          : "danger"
                                      } d-inline-flex align-items-center`}
                                    >
                                      {modelUser?.status === "active" ? (
                                        <>
                                          <FaUserCheck className="me-1" />{" "}
                                          Enabled
                                        </>
                                      ) : (
                                        <>
                                          <FaUserAltSlash className="me-1" />
                                          Disabled
                                        </>
                                      )}
                                    </span>
                                  </td>
                                </td>
                              </tr>
                              <tr>
                                <td>First name</td>
                                <td>{modelUser?.first_name}</td>
                              </tr>
                              <tr>
                                <td>Last name</td>
                                <td>{modelUser?.last_name}</td>
                              </tr>
                              <tr>
                                <td>Email</td>
                                <td>{modelUser?.email}</td>
                              </tr>
                              <tr>
                                <td>Username</td>
                                <td>{modelUser?.user_name}</td>
                              </tr>
                              <tr>
                                <td>Registration date</td>
                                <td>
                                  {
                                    formatDate(new Date(modelUser?.created_at))
                                      .fullDate
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Update date</td>
                                <td>
                                  {
                                    formatDate(new Date(modelUser?.updated_at))
                                      .fullDate
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {/* <div className="col-lg-6">
                        <div className="table-responsive">
                          <table className="table table-borderless table-striped table-hover table-sm">
                            <thead>
                              <tr>
                                <th
                                  colSpan="2"
                                  className="d-flex align-items-center"
                                >
                                  <FaRegCalendarPlus className="me-1" />
                                  Organizer information
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td width="30%">Name</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
              <button
                className="btn btn-outline-dark rounded-5 d-inline-flex align-items-center gap-2"
                onClick={() => setOpenModel(false)}
              >
                <FaRegWindowClose className="ModelBoxTicketPurchaseTitleIcon" />
                close
              </button>
            </div>
          </>
        )}
      </Modal>
      <div className="row organizer-event">
        <div className="col-12">
          {!users || !users.length > 0 ? (
            <Alert {...msg} />
          ) : (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter">
                  <thead>
                    <tr>
                      <th>
                        <FaNetworkWired />
                      </th>
                      <th>Name</th>
                      <th>Username / Email</th>
                      <th>Registration date</th>
                      <th>Status</th>
                      <th className="text-center">
                        <AiTwotoneSetting />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((user) => {
                      const items = [
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) => handleModelEvent(e, user.id)}
                            >
                              <FaFileAlt className="me-1" />
                              More information
                            </Link>
                          ),
                          key: "0",
                        },
                        user?.roles.some(
                          (role) => role.name === "organizer"
                        ) && {
                          label: (
                            <Link
                              to={`/en/organizer/${user.slug}`}
                              className="d-flex align-items-center"
                            >
                              <FaRegIdCard className="me-1" />
                              Organizer profile
                            </Link>
                          ),
                          key: "2",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) => handleStatusChange(e, user.id)}
                            >
                              {user.status === "active" ? (
                                <>
                                  <FaUserAltSlash className="me-1" />
                                  Disable
                                </>
                              ) : (
                                <>
                                  <FaUserCheck className="me-1" /> Enable
                                </>
                              )}
                            </Link>
                          ),
                          key: "4",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(e, user.id, "user_delete", "")
                              }
                            >
                              {user?.deleted_at !== null ? (
                                <>
                                  <FaTrashRestoreAlt className="me-1" /> Restore
                                </>
                              ) : (
                                <>
                                  <IoCloseSharp className="me-1" />
                                  Delete
                                </>
                              )}
                            </Link>
                          ),
                          key: "5",
                        },
                        user.deleted_at && {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(
                                  e,
                                  user.id,
                                  "user_delete_permanent",
                                  "delete_permanent"
                                )
                              }
                            >
                              <MdDelete className="me-1" />
                              Delete permanently
                            </Link>
                          ),
                          key: "6",
                        },
                      ];
                      return (
                        <tr key={user.id}>
                          <td className="text-capitalize">
                            {user?.roles?.[0]?.name}
                          </td>
                          <td>
                            <span className="avatar me-1">
                              <FaRegUser />
                            </span>
                            {user?.first_name} {user?.last_name}
                          </td>
                          <td>
                            {user?.user_name} <br /> {user?.email}
                          </td>
                          <td>
                            {formatDate(new Date(user?.created_at)).fullDate}
                          </td>
                          <td className="">
                            <span
                              className={`badge badge-${
                                user?.status === "active" ? "success" : "danger"
                              } d-inline-flex align-items-center`}
                            >
                              {user?.status === "active" ? (
                                <>
                                  <FaUserCheck className="me-1" /> Enabled
                                </>
                              ) : (
                                <>
                                  <FaUserAltSlash className="me-1" />
                                  Disabled
                                </>
                              )}
                            </span>
                            {user?.deleted_at && (
                              <>
                                <br />
                                <br />
                                <span className="badge badge-danger d-inline-flex align-items-center">
                                  <IoCloseSharp className="me-1" />
                                  Deleted
                                </span>
                              </>
                            )}
                          </td>

                          <td className="text-center">
                            <Dropdown
                              menu={{
                                items,
                              }}
                              trigger={["click"]}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Space className="justify-content-center">
                                  <FaEllipsisV />
                                </Space>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {users?.length > 10 && (
        <Pagination
          current={currentPage}
          total={users.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Table;
