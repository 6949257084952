import { DatePicker, Space, Tooltip } from "antd";
import { formatDate, useSignUp } from "../../../../../utils/CustomFunctions";
import SelectBox from "../../../../global/SelectBox";
import "./style.css";
import {
  FaSyncAlt,
  FaUserAltSlash,
  FaUserCheck,
  FaUserPlus,
} from "react-icons/fa";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { country } from "../../../../global/Values";

const Setting = () => {
  const server = process.env.REACT_APP_SERVER;
  const { user } = useSelector((state) => state.authUser);
  const initialState = {
    ...user,
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    user_name: user?.user_name || "",
    email: user?.email || "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    if (!values.first_name.trim())
      newErrors.first_name = "This value should not be null.";
    if (!values.last_name.trim())
      newErrors.last_name = "This value should not be null.";
    if (!values.user_name.trim())
      newErrors.user_name = "This value should not be null.";
    if (!values.email.trim())
      newErrors.email = "This value should not be null.";

    return newErrors;
  };

  const {
    values,
    errors,
    isPending,
    handleChange,
    handleSelectBox,
    singleImage,
    handleDateChange,
    handleSubmit,
  } = useSignUp(initialState, validateFields, `profile_update`);

  return (
    <div className="card setting">
      <div className="card-body">
        <div className="mb-3 bg-light p-3 d-none d-xl-block">
          <span>Account status</span>{" "}
          <span
            className={`badge badge-${
              values?.status === "active" ? "info" : "danger"
            } d-inline-flex align-items-center`}
          >
            {values?.status === "active" ? (
              <>
                <FaUserCheck className="me-1" /> Enabled
              </>
            ) : (
              <>
                <FaUserAltSlash className="me-1" />
                Disabled
              </>
            )}
          </span>
          <span className="ms-3">Member since</span>{" "}
          <span className="badge badge-info ms-3">
            <FaUserPlus /> {formatDate(new Date(values?.created_at)).fullDate}
          </span>
        </div>

        <form className="signform " onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="first_name" className="form-label">
              First Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              className={`form-control ${errors.first_name && "is-invalid"}`}
              value={values?.first_name || ""}
              onChange={handleChange}
            />
            {errors.first_name && (
              <small className="text-danger d-block mt-1">
                {errors.first_name}
              </small>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="last_name" className="form-label">
              Last Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              className={`form-control ${errors.last_name && "is-invalid"}`}
              value={values?.last_name || ""}
              onChange={handleChange}
            />
            {errors.last_name && (
              <small className="text-danger d-block mt-1">
                {errors.last_name}
              </small>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="user_name" className="form-label">
              Username<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="user_name"
              id="user_name"
              className={`form-control ${errors.user_name && "is-invalid"}`}
              value={values?.user_name}
              readOnly
            />
            {errors.user_name && (
              <small className="text-danger d-block mt-1">
                {errors.user_name}
              </small>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="form-label">
              Email<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className={`form-control ${errors.email && "is-invalid"}`}
              value={values?.email || ""}
              readOnly
            />
            {errors.email && (
              <small className="text-danger mt-1">{errors.email}</small>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="phone_number" className="form-label">
              Phone Number
            </label>
            <input
              type="text"
              name="phone_number"
              id="phone_number"
              className="form-control"
              value={values?.phone_number || ""}
              onChange={handleChange}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="birth_date" className="form-label">
              Birth Date
            </label>
            <Space direction="vertical" size={12} className="d-block">
              <DatePicker
                id="birth_date"
                format="YYYY-MM-DD"
                className="form-control"
                value={values?.birth_date ? dayjs(values?.birth_date) : null}
                onChange={(_, dateString) =>
                  handleDateChange("birth_date", dateString)
                }
              />
            </Space>
          </div>

          <div className="mb-4">
            <label htmlFor="profile_picture" className="form-label">
              Profile picture
            </label>
            <input
              type="file"
              name="image"
              id="profile_picture"
              className="form-control"
              onChange={singleImage}
            />
            {values.image && (
              <div className="mt-2">
                <Tooltip title="Enlarge">
                  <Link
                    className="fancybox"
                    to={`${server}/user/profile/${values.image}`}
                    target="_blank"
                  >
                    <img
                      src={`${server}/user/profile/${values.image}`}
                      alt=""
                      width="20%"
                    />
                  </Link>
                </Tooltip>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="street_address" className="form-label">
              Street address
            </label>
            <input
              type="text"
              name="street_address"
              id="street_address"
              className="form-control"
              onChange={handleChange}
              value={values?.street_address || ""}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="street_address_2" className="form-label fw-600">
              Street address 2
            </label>
            <input
              type="text"
              name="street_address_2"
              id="street_address_2"
              className="form-control"
              onChange={handleChange}
              value={values?.street_address_2 || ""}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="city" className="form-label fw-600">
              City
            </label>
            <input
              type="text"
              name="city"
              id="city"
              className="form-control"
              onChange={handleChange}
              value={values?.city || ""}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="postal_code" className="form-label fw-600">
              Zip/ Postal code
            </label>
            <input
              type="text"
              name="postal_code"
              id="postal_code"
              className="form-control"
              onChange={handleChange}
              value={values?.postal_code || ""}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="state" className="form-label fw-600">
              State
            </label>
            <input
              type="text"
              name="state"
              id="state"
              className="form-control"
              onChange={handleChange}
              value={values?.state || ""}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="last_name" className="form-label fw-600">
              Country
            </label>
            <SelectBox
              name="country"
              options={country}
              val={values?.country}
              change={handleSelectBox}
            />
          </div>

          <div className="form-group mt-3 mb-3">
            <button
              className="btn btn-dark rounded-5"
              type="submit"
              disabled={isPending ? "disabled" : ""}
            >
              {isPending ? <FaSyncAlt className="spinIcon" /> : "SEND"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Setting;
