import { Wrapper } from "./Wrapper";
import { Checkbox, Slider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SelectBox from "./../global/SelectBox";
import { addVenueFilters } from "../../store/slice/venueFilters";

const VenueSidebar = () => {
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.AllData);
  const {
    filters: {
      keyword,
      venue_type,
      country,
      maxSeatedGuests,
      minSeatedGuests,
      maxStandingGuests,
      minStandingGuests,
      minMaxSeatedGuests,
      minMaxStandingGuests,
    },
    all_venues,
  } = useSelector((state) => state.venueFilters);

  //   for filters
  const add_filter2 = (name, value) => {
    dispatch(addVenueFilters({ name, value }));
  };
  const add_filter = (e) => {
    const { name, value } = e.target;
    dispatch(addVenueFilters({ name, value }));
  };
  // GET UNOQUE DATA OF EACH FILD
  const getUniqueData = (data) => {
    let newVal = data.map((curElem) => curElem.type.name);
    newVal = newVal.flat();
    return [...new Set(newVal)];
  };
  // we need to have the individual data of each in an array format
  const allVenueType = getUniqueData(all_venues);

  return (
    <Wrapper>
      <div
        className="accordion accordion-flush mb-4"
        id="accordionFlushExample"
      >
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed fw-600"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="true"
              aria-controls="flush-collapseOne"
            >
              Keyword
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingOne"
            data-bs-parent=""
          >
            <div className="accordion-body signform">
              <input
                type="text"
                name="keyword"
                value={keyword}
                className="form-control"
                onChange={add_filter}
              />
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              className="accordion-button collapsed fw-600"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="true"
              aria-controls="flush-collapseThree"
            >
              Country
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingThree"
            data-bs-parent=""
          >
            <div className="accordion-body location-tab">
              <SelectBox
                name="country"
                options={countries}
                val={country}
                change={add_filter2}
              />
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button collapsed fw-600"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="true"
              aria-controls="flush-collapseTwo"
            >
              Venue type
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingTwo"
            data-bs-parent=""
          >
            <div className="accordion-body">
              <Checkbox.Group
                style={{
                  width: "100%",
                }}
                onChange={(e) => add_filter2("venue_type", e)}
              >
                <ul>
                  {allVenueType?.map((venue, index) => (
                    <li key={index} className="fw-600">
                      <Checkbox value={venue}>{venue}</Checkbox>
                    </li>
                  ))}
                </ul>
              </Checkbox.Group>
            </div>
          </div>
        </div>

        <div className="accordion-item ">
          <h2 className="accordion-header" id="flush-headingFour">
            <button
              className="accordion-button collapsed fw-600"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour"
              aria-expanded="true"
              aria-controls="flush-collapseFour"
            >
              Seated guests
            </button>
          </h2>
          <div
            id="flush-collapseFour"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingFour"
            data-bs-parent=""
          >
            <div className="accordion-body">
              <Slider
                className="mb-4"
                range={{
                  draggableTrack: true,
                }}
                defaultValue={[minMaxSeatedGuests[0], minMaxSeatedGuests[1]]}
                min={minSeatedGuests}
                max={maxSeatedGuests}
                onAfterChange={(value) =>
                  add_filter2("minMaxSeatedGuests", value)
                }
              />
              <div className="row ranger-slider-inputs mb-2 signform">
                <div className="col-12 col-sm-6">
                  <label htmlFor="pricemin" className="form-label fw-600 ">
                    Min
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input
                      id="pricemin"
                      name="pricemin"
                      type="text"
                      className="form-control range-slider-min-input"
                      value={minMaxSeatedGuests[0]}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <label htmlFor="pricemax" className="form-label fw-600">
                    Max
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input
                      id="pricemax"
                      name="pricemax"
                      type="text"
                      className="form-control range-slider-max-input"
                      value={minMaxSeatedGuests[1]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item ">
          <h2 className="accordion-header" id="flush-headingFive">
            <button
              className="accordion-button collapsed fw-600"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFive"
              aria-expanded="true"
              aria-controls="flush-collapseFive"
            >
              Standing guests
            </button>
          </h2>
          <div
            id="flush-collapseFive"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingFive"
            data-bs-parent=""
          >
            <div className="accordion-body">
              <Slider
                className="mb-4"
                range={{
                  draggableTrack: true,
                }}
                defaultValue={[
                  minMaxStandingGuests[0],
                  minMaxStandingGuests[1],
                ]}
                min={minStandingGuests}
                max={maxStandingGuests}
                onAfterChange={(value) =>
                  add_filter2("minMaxStandingGuests", value)
                }
              />
              <div className="row ranger-slider-inputs mb-2 signform">
                <div className="col-12 col-sm-6">
                  <label htmlFor="pricemin" className="form-label fw-600 ">
                    Min
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input
                      id="pricemin"
                      name="pricemin"
                      type="text"
                      className="form-control range-slider-min-input"
                      value={minMaxStandingGuests[0]}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <label htmlFor="pricemax" className="form-label fw-600">
                    Max
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input
                      id="pricemax"
                      name="pricemax"
                      type="text"
                      className="form-control range-slider-max-input"
                      value={minMaxStandingGuests[1]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="subscribe-tab bg-dark">
          <div className="figure">
            <div className="fig">
              <FaMailBulk className="icon" />
            </div>
            <div className="figcaption">
              <h5 className="text-white m-0">Subscribe to our newsletter</h5>
            </div>
          </div>
          <div className="form-row mt-3">
            <div className="form-group">
              <input
                type="text"
                className="form-control rounded-0"
                placeholder="Email address"
              />
            </div>
            <div className="form-group">
              <button className="btn btn-dark w-100 rounded-0">
                SUBSCRIBE
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </Wrapper>
  );
};

export default VenueSidebar;
