import React, { useEffect, useState } from "react";
import Top from "./Top/Top";
import { Card } from "./Card/Card";
import { useDispatch, useSelector } from "react-redux";
import { FaInfoCircle, FaSyncAlt } from "react-icons/fa";
import Alert from "./../global/alert/Alert";
import EventSidebar from "./../sidebar/EventSidebar";
import { clearAllFilters, loadFilterEvents } from "../../store/slice/Filters";
import EventCalendar from "./calendar/EventCalendar";
import { useParams } from "react-router-dom";
import { Pagination } from "antd";

const Content = () => {
  const col = "col-sm-6 col-md-4";
  const dispatch = useDispatch();
  const { slug } = useParams();

  const { event_setting } = useSelector((state) => state.AllData);

  const { events, loading } = useSelector((state) => state.AllData);
  const { filter_events } = useSelector((state) => state.Filters);
  const [event, setEvent] = useState([]);

  useEffect(() => {
    slug
      ? setEvent(events.filter((event) => event?.user?.slug === slug))
      : setEvent(events);
  }, [slug]);

  useEffect(() => {
    dispatch(loadFilterEvents(event));
  }, [dispatch, events]);

  useEffect(() => {
    dispatch(clearAllFilters());
  }, []);

  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No events found",
  };

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = event_setting?.number || 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastEvent = currentPage * itemsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - itemsPerPage;
  const currentEvents = filter_events?.slice(
    indexOfFirstEvent,
    indexOfLastEvent
  );

  return (
    <>
      <div className="container">
        {/* <EventCalendar /> */}
        <Top />
        <div className="row mt-4">
          <div className="col-lg-9 order-1 order-lg-2">
            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <FaSyncAlt className="spinIcon" />
              </div>
            ) : (
              <div className="row border-0">
                {filter_events?.length > 0 ? (
                  currentEvents?.map((curElem, index) => {
                    return (
                      <Card
                        col={col}
                        data={curElem}
                        key={index}
                        clas="event-card"
                      />
                    );
                  })
                ) : (
                  <Alert {...msg} />
                )}
              </div>
            )}

            {/* pagination */}
            {filter_events?.length > itemsPerPage && (
              <Pagination
                current={currentPage}
                total={filter_events.length}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
              />
            )}
          </div>
          <div className="col-lg-3 order-2 order-lg-1">
            <EventSidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
