export const countries = [
  {
    id: "afghanistan",
    name: "Afghanistan",
  },
  {
    id: "albania",
    name: "Albania",
  },
  {
    id: "algeria",
    name: "Algeria",
  },
  {
    id: "andorra",
    name: "Andorra",
  },
  {
    id: "angola",
    name: "Angola",
  },
  {
    id: "antigua and barbuda",
    name: "Antigua and Barbuda",
  },
  {
    id: "argentina",
    name: "Argentina",
  },
  {
    id: "armenia",
    name: "Armenia",
  },
  {
    id: "australia",
    name: "Australia",
  },
  {
    id: "austria",
    name: "Austria",
  },
  {
    id: "azerbaijan",
    name: "Azerbaijan",
  },
  {
    id: "bahamas",
    name: "Bahamas",
  },
  {
    id: "bahrain",
    name: "Bahrain",
  },
  {
    id: "bangladesh",
    name: "Bangladesh",
  },
  {
    id: "barbados",
    name: "Barbados",
  },
  {
    id: "belarus",
    name: "Belarus",
  },
  {
    id: "belgium",
    name: "Belgium",
  },
  {
    id: "belize",
    name: "Belize",
  },
  {
    id: "benin",
    name: "Benin",
  },
  {
    id: "bhutan",
    name: "Bhutan",
  },
  {
    id: "bolivia",
    name: "Bolivia",
  },
  {
    id: "bosnia and herzegovina",
    name: "Bosnia and Herzegovina",
  },
  {
    id: "botswana",
    name: "Botswana",
  },
  {
    id: "brazil",
    name: "Brazil",
  },
  {
    id: "brunei",
    name: "Brunei",
  },
  {
    id: "bulgaria",
    name: "Bulgaria",
  },
  {
    id: "burkinafaso",
    name: "Burkina Faso",
  },
  {
    id: "burundi",
    name: "Burundi",
  },
  {
    id: "caboverde",
    name: "Cabo Verde",
  },
  {
    id: "cambodia",
    name: "Cambodia",
  },
  {
    id: "cameroon",
    name: "Cameroon",
  },
  {
    id: "canada",
    name: "Canada",
  },
  {
    id: "central african republic",
    name: "Central African Republic",
  },
  {
    id: "chad",
    name: "Chad",
  },
  {
    id: "chile",
    name: "Chile",
  },
  {
    id: "china",
    name: "China",
  },
  {
    id: "colombia",
    name: "Colombia",
  },
  {
    id: "comoros",
    name: "Comoros",
  },
  {
    id: "congo",
    name: "Congo",
  },
  {
    id: "costarica",
    name: "Costa Rica",
  },
  {
    id: "Côte d'Ivoire",
    name: "Côte d'Ivoire",
  },
  {
    id: "croatia",
    name: "Croatia",
  },
  {
    id: "cuba",
    name: "Cuba",
  },
  {
    id: "cyprus",
    name: "Cyprus",
  },
  {
    id: "czech republic",
    name: "Czech Republic",
  },
  {
    id: "democratic republic of congo",
    name: "Democratic Republic of the Congo",
  },
  {
    id: "denmark",
    name: "Denmark",
  },
  {
    id: "djibouti",
    name: "Djibouti",
  },
  {
    id: "dominica",
    name: "Dominica",
  },
  {
    id: "dominican republic",
    name: "Dominican Republic",
  },
  {
    id: "east timor",
    name: "East Timor",
  },
  {
    id: "ecuador",
    name: "Ecuador",
  },
  {
    id: "egypt",
    name: "Egypt",
  },
  {
    id: "el salvador",
    name: "El Salvador",
  },
  {
    id: "equatorial guinea",
    name: "Equatorial Guinea",
  },
  {
    id: "eritrea",
    name: "Eritrea",
  },
  {
    id: "estonia",
    name: "Estonia",
  },
  {
    id: "eswatini",
    name: "Eswatini",
  },
  {
    id: "ethiopia",
    name: "Ethiopia",
  },
  {
    id: "fiji",
    name: "Fiji",
  },
  {
    id: "finland",
    name: "Finland",
  },
  {
    id: "france",
    name: "France",
  },
  {
    id: "gabon",
    name: "Gabon",
  },
  {
    id: "gambia",
    name: "Gambia",
  },
  {
    id: "georgia",
    name: "Georgia",
  },
  {
    id: "germany",
    name: "Germany",
  },
  {
    id: "ghana",
    name: "Ghana",
  },
  {
    id: "greece",
    name: "Greece",
  },
  {
    id: "grenada",
    name: "Grenada",
  },
  {
    id: "guatemala",
    name: "Guatemala",
  },
  {
    id: "guinea",
    name: "Guinea",
  },
  {
    id: "Guinea-Bissau",
    name: "Guinea-Bissau",
  },
  {
    id: "guyana",
    name: "Guyana",
  },
  {
    id: "haiti",
    name: "Haiti",
  },
  {
    id: "honduras",
    name: "Honduras",
  },
  {
    id: "hungary",
    name: "Hungary",
  },
  {
    id: "iceland",
    name: "Iceland",
  },
  {
    id: "india",
    name: "India",
  },
  {
    id: "indonesia",
    name: "Indonesia",
  },
  {
    id: "iran",
    name: "Iran",
  },
  {
    id: "iraq",
    name: "Iraq",
  },
  {
    id: "ireland",
    name: "Ireland",
  },
  {
    id: "israel",
    name: "Israel",
  },
  {
    id: "italy",
    name: "Italy",
  },
  {
    id: "jamaica",
    name: "Jamaica",
  },
  {
    id: "japan",
    name: "Japan",
  },
  {
    id: "jordan",
    name: "Jordan",
  },
  {
    id: "kazakhstan",
    name: "Kazakhstan",
  },
  {
    id: "kenya",
    name: "Kenya",
  },
  {
    id: "kiribati",
    name: "Kiribati",
  },
  {
    id: "korea",
    name: "Korea",
  },
  {
    id: "kosovo",
    name: "Kosovo",
  },
  {
    id: "kuwait",
    name: "Kuwait",
  },
  {
    id: "kyrgyzstan",
    name: "Kyrgyzstan",
  },
  {
    id: "laos",
    name: "Laos",
  },
  {
    id: "latvia",
    name: "Latvia",
  },
  {
    id: "lebanon",
    name: "Lebanon",
  },
  {
    id: "lesotho",
    name: "Lesotho",
  },
  {
    id: "liberia",
    name: "Liberia",
  },
  {
    id: "libya",
    name: "Libya",
  },
  {
    id: "liechtenstein",
    name: "Liechtenstein",
  },
  {
    id: "lithuania",
    name: "Lithuania",
  },
  {
    id: "luxembourg",
    name: "Luxembourg",
  },
  {
    id: "madagascar",
    name: "Madagascar",
  },
  {
    id: "malawi",
    name: "Malawi",
  },
  {
    id: "malaysia",
    name: "Malaysia",
  },
  {
    id: "maldives",
    name: "Maldives",
  },
  {
    id: "mali",
    name: "Mali",
  },
  {
    id: "malta",
    name: "Malta",
  },
  {
    id: "marshall islands",
    name: "Marshall Islands",
  },
  {
    id: "mauritania",
    name: "Mauritania",
  },
  {
    id: "mauritius",
    name: "Mauritius",
  },
  {
    id: "mexico",
    name: "Mexico",
  },
  {
    id: "micronesia",
    name: "Micronesia",
  },
  {
    id: "moldova",
    name: "Moldova",
  },
  {
    id: "monaco",
    name: "Monaco",
  },
  {
    id: "mongolia",
    name: "Mongolia",
  },
  {
    id: "montenegro",
    name: "Montenegro",
  },
  {
    id: "morocco",
    name: "Morocco",
  },
  {
    id: "mozambique",
    name: "Mozambique",
  },
  {
    id: "myanmar",
    name: "Myanmar",
  },
  {
    id: "namibia",
    name: "Namibia",
  },
  {
    id: "nauru",
    name: "Nauru",
  },
  {
    id: "nepal",
    name: "Nepal",
  },
  {
    id: "netherlands",
    name: "Netherlands",
  },
  {
    id: "new zealand",
    name: "New Zealand",
  },
  {
    id: "nicaragua",
    name: "Nicaragua",
  },
  {
    id: "niger",
    name: "Niger",
  },
  {
    id: "nigeria",
    name: "Nigeria",
  },
  {
    id: "north macedonia",
    name: "North Macedonia",
  },
  {
    id: "norway",
    name: "Norway",
  },
  {
    id: "oman",
    name: "Oman",
  },
  {
    id: "pakistan",
    name: "Pakistan",
  },
  {
    id: "palau",
    name: "Palau",
  },
  {
    id: "palestine",
    name: "Palestine",
  },
  {
    id: "panama",
    name: "Panama",
  },
  {
    id: "papua new guinea",
    name: "Papua New Guinea",
  },
  {
    id: "paraguay",
    name: "Paraguay",
  },
  {
    id: "peru",
    name: "Peru",
  },
  {
    id: "philippines",
    name: "Philippines",
  },
  {
    id: "poland",
    name: "Poland",
  },
  {
    id: "portugal",
    name: "Portugal",
  },
  {
    id: "qatar",
    name: "Qatar",
  },
  {
    id: "romania",
    name: "Romania",
  },
  {
    id: "russia",
    name: "Russia",
  },
  {
    id: "rwanda",
    name: "Rwanda",
  },
  {
    id: "saint kitts and nevis",
    name: "Saint Kitts and Nevis",
  },
  {
    id: "saint lucia",
    name: "Saint Lucia",
  },
  {
    id: "saint vincent and the grenadines",
    name: "Saint Vincent and the Grenadines",
  },
  {
    id: "samoa",
    name: "Samoa",
  },
  {
    id: "sanmarino",
    name: "San Marino",
  },
  {
    id: "sao tome and principe",
    name: "Sao Tome and Principe",
  },
  {
    id: "saudi arabia",
    name: "Saudi Arabia",
  },
  {
    id: "senegal",
    name: "Senegal",
  },
  {
    id: "serbia",
    name: "Serbia",
  },
  {
    id: "seychelles",
    name: "Seychelles",
  },
  {
    id: "sierra leone",
    name: "Sierra Leone",
  },
  {
    id: "singapore",
    name: "Singapore",
  },
  {
    id: "slovakia",
    name: "Slovakia",
  },
  {
    id: "slovenia",
    name: "Slovenia",
  },
  {
    id: "solomon islands",
    name: "Solomon Islands",
  },
  {
    id: "somalia",
    name: "Somalia",
  },
  {
    id: "southa frica",
    name: "South Africa",
  },
  {
    id: "southkorea",
    name: "South Korea",
  },
  {
    id: "south sudan",
    name: "South Sudan",
  },
  {
    id: "spain",
    name: "Spain",
  },
  {
    id: "sri lanka",
    name: "Sri Lanka",
  },
  {
    id: "sudan",
    name: "Sudan",
  },
  {
    id: "suriname",
    name: "Suriname",
  },
  {
    id: "sweden",
    name: "Sweden",
  },
  {
    id: "switzerland",
    name: "Switzerland",
  },
  {
    id: "syria",
    name: "Syria",
  },
  {
    id: "taiwan",
    name: "Taiwan",
  },
  {
    id: "tajikistan",
    name: "Tajikistan",
  },
  {
    id: "tanzania",
    name: "Tanzania",
  },
  {
    id: "thailand",
    name: "Thailand",
  },
  {
    id: "togo",
    name: "Togo",
  },
  {
    id: "tonga",
    name: "Tonga",
  },
  {
    id: "trinidad and tobago",
    name: "Trinidad and Tobago",
  },
  {
    id: "tunisia",
    name: "Tunisia",
  },
  {
    id: "turkey",
    name: "Turkey",
  },
  {
    id: "turkmenistan",
    name: "Turkmenistan",
  },
  {
    id: "tuvalu",
    name: "Tuvalu",
  },
  {
    id: "uganda",
    name: "Uganda",
  },
  {
    id: "ukraine",
    name: "Ukraine",
  },
  {
    id: "united arab emirates",
    name: "United Arab Emirates",
  },
  {
    id: "united kingdom",
    name: "United Kingdom",
  },
  {
    id: "united states",
    name: "United States",
  },
  {
    id: "uruguay",
    name: "Uruguay",
  },
  {
    id: "uzbekistan",
    name: "Uzbekistan",
  },
  {
    id: "vanuatu",
    name: "Vanuatu",
  },
  {
    id: "vatican",
    name: "Vatican",
  },
  {
    id: "venezuela",
    name: "Venezuela",
  },
  {
    id: "vietnam",
    name: "Vietnam",
  },
  {
    id: "yemen",
    name: "Yemen",
  },
  {
    id: "zambia",
    name: "Zambia",
  },
  {
    id: "zimbabwe",
    name: "Zimbabwe",
  },
];

export const country = [
  {
    name: "United States",
    id: "🇺🇸",
    flag: "https://flagcdn.com/w320/us.png",
  },
];

export const states = [
  { name: "Alabama", id: "Alabama" },
  { name: "Alaska", id: "Alaska" },
  { name: "Arizona", id: "Arizona" },
  { name: "Arkansas", id: "Arkansas" },
  { name: "California", id: "California" },
  { name: "Colorado", id: "Colorado" },
  { name: "Connecticut", id: "Connecticut" },
  { name: "Delaware", id: "Delaware" },
  { name: "Florida", id: "Florida" },
  { name: "Georgia", id: "Georgia" },
  { name: "Hawaii", id: "Hawaii" },
  { name: "Idaho", id: "Idaho" },
  { name: "Illinois", id: "Illinois" },
  { name: "Indiana", id: "Indiana" },
  { name: "Iowa", id: "Iowa" },
  { name: "Kansas", id: "Kansas" },
  { name: "Kentucky", id: "Kentucky" },
  { name: "Louisiana", id: "Louisiana" },
  { name: "Maine", id: "Maine" },
  { name: "Maryland", id: "Maryland" },
  { name: "Massachusetts", id: "Massachusetts" },
  { name: "Michigan", id: "Michigan" },
  { name: "Minnesota", id: "Minnesota" },
  { name: "Mississippi", id: "Mississippi" },
  { name: "Missouri", id: "Missouri" },
  { name: "Montana", id: "Montana" },
  { name: "Nebraska", id: "Nebraska" },
  { name: "Nevada", id: "Nevada" },
  { name: "New Hampshire", id: "New Hampshire" },
  { name: "New Jersey", id: "New Jersey" },
  { name: "New Mexico", id: "New Mexico" },
  { name: "New York", id: "New York" },
  { name: "North Carolina", id: "North Carolina" },
  { name: "North Dakota", id: "North Dakota" },
  { name: "Ohio", id: "Ohio" },
  { name: "Oklahoma", id: "Oklahoma" },
  { name: "Oregon", id: "Oregon" },
  { name: "Pennsylvania", id: "Pennsylvania" },
  { name: "Rhode Island", id: "Rhode Island" },
  { name: "South Carolina", id: "South Carolina" },
  { name: "South Dakota", id: "South Dakota" },
  { name: "Tennessee", id: "Tennessee" },
  { name: "Texas", id: "Texas" },
  { name: "Utah", id: "Utah" },
  { name: "Vermont", id: "Vermont" },
  { name: "Virginia", id: "Virginia" },
  { name: "Washington", id: "Washington" },
  { name: "West Virginia", id: "West Virginia" },
  { name: "Wisconsin", id: "Wisconsin" },
  { name: "Wyoming", id: "Wyoming" },
];

const year = [
  { id: 1900, name: "1900" },
  { id: 1901, name: "1901" },
  { id: 1902, name: "1902" },
  { id: 1903, name: "1903" },
  { id: 1904, name: "1904" },
  { id: 1905, name: "1905" },
  { id: 1906, name: "1906" },
  { id: 1907, name: "1907" },
  { id: 1908, name: "1908" },
  { id: 1909, name: "1909" },
  { id: 1910, name: "1910" },
  { id: 1911, name: "1911" },
  { id: 1912, name: "1912" },
  { id: 1913, name: "1913" },
  { id: 1914, name: "1914" },
  { id: 1915, name: "1915" },
  { id: 1916, name: "1916" },
  { id: 1917, name: "1917" },
  { id: 1918, name: "1918" },
  { id: 1919, name: "1919" },
  { id: 1920, name: "1920" },
  { id: 1921, name: "1921" },
  { id: 1922, name: "1922" },
  { id: 1923, name: "1923" },
  { id: 1924, name: "1924" },
  { id: 1925, name: "1925" },
  { id: 1926, name: "1926" },
  { id: 1927, name: "1927" },
  { id: 1928, name: "1928" },
  { id: 1929, name: "1929" },
  { id: 1930, name: "1930" },
  { id: 1931, name: "1931" },
  { id: 1932, name: "1932" },
  { id: 1933, name: "1933" },
  { id: 1934, name: "1934" },
  { id: 1935, name: "1935" },
  { id: 1936, name: "1936" },
  { id: 1937, name: "1937" },
  { id: 1938, name: "1938" },
  { id: 1939, name: "1939" },
  { id: 1940, name: "1940" },
  { id: 1941, name: "1941" },
  { id: 1942, name: "1942" },
  { id: 1943, name: "1943" },
  { id: 1944, name: "1944" },
  { id: 1945, name: "1945" },
  { id: 1946, name: "1946" },
  { id: 1947, name: "1947" },
  { id: 1948, name: "1948" },
  { id: 1949, name: "1949" },
  { id: 1950, name: "1950" },
  { id: 1951, name: "1951" },
  { id: 1952, name: "1952" },
  { id: 1953, name: "1953" },
  { id: 1954, name: "1954" },
  { id: 1955, name: "1955" },
  { id: 1956, name: "1956" },
  { id: 1957, name: "1957" },
  { id: 1958, name: "1958" },
  { id: 1959, name: "1959" },
  { id: 1960, name: "1960" },
  { id: 1961, name: "1961" },
  { id: 1962, name: "1962" },
  { id: 1963, name: "1963" },
  { id: 1964, name: "1964" },
  { id: 1965, name: "1965" },
  { id: 1966, name: "1966" },
  { id: 1967, name: "1967" },
  { id: 1968, name: "1968" },
  { id: 1969, name: "1969" },
  { id: 1970, name: "1970" },
  { id: 1971, name: "1971" },
  { id: 1972, name: "1972" },
  { id: 1973, name: "1973" },
  { id: 1974, name: "1974" },
  { id: 1975, name: "1975" },
  { id: 1976, name: "1976" },
  { id: 1977, name: "1977" },
  { id: 1978, name: "1978" },
  { id: 1979, name: "1979" },
  { id: 1980, name: "1980" },
  { id: 1981, name: "1981" },
  { id: 1982, name: "1982" },
  { id: 1983, name: "1983" },
  { id: 1984, name: "1984" },
  { id: 1985, name: "1985" },
  { id: 1986, name: "1986" },
  { id: 1987, name: "1987" },
  { id: 1988, name: "1988" },
  { id: 1989, name: "1989" },
  { id: 1990, name: "1990" },
  { id: 1991, name: "1991" },
  { id: 1992, name: "1992" },
  { id: 1993, name: "1993" },
  { id: 1994, name: "1994" },
  { id: 1995, name: "1995" },
  { id: 1996, name: "1996" },
  { id: 1997, name: "1997" },
  { id: 1998, name: "1998" },
  { id: 1999, name: "1999" },
  { id: 2000, name: "2000" },
  { id: 2001, name: "2001" },
  { id: 2002, name: "2002" },
  { id: 2003, name: "2003" },
  { id: 2004, name: "2004" },
  { id: 2005, name: "2005" },
  { id: 2006, name: "2006" },
  { id: 2007, name: "2007" },
  { id: 2008, name: "2008" },
  { id: 2009, name: "2009" },
  { id: 2010, name: "2010" },
  { id: 2011, name: "2011" },
  { id: 2012, name: "2012" },
  { id: 2013, name: "2013" },
  { id: 2014, name: "2014" },
  { id: 2015, name: "2015" },
  { id: 2016, name: "2016" },
  { id: 2017, name: "2017" },
  { id: 2018, name: "2018" },
  { id: 2019, name: "2019" },
  { id: 2020, name: "2020" },
  { id: 2021, name: "2021" },
  { id: 2022, name: "2022" },
  { id: 2023, name: "2023" },
  { id: 2024, name: "2024" },
  { id: 2025, name: "2025" },
  { id: 2026, name: "2026" },
  { id: 2027, name: "2027" },
  { id: 2028, name: "2028" },
  { id: 2029, name: "2029" },
  { id: 2030, name: "2030" },
];
export const years = year.slice().sort((a, b) => b.id - a.id);
export const radio = ["No", "Yes"];
export const currency = [
  {
    id: "usd",
    name: "USD",
  },
  {
    id: "rsd",
    name: "RSD",
  },
];
//
const languagesArray = [
  "(Afan)/Oromoor/Oriya",
  "Arabic",
  "Abkhazian",
  "Afar",
  "Afrikaans",
  "Aymara",
  "Albanian",
  "German",
  "Amharic",
  "English",
  "Armenian",
  "Assamese",
  "Azerbaijani",
  "Bashkir",
  "Bulgarian",
  "Basque",
  "Bengali/Bangla",
  "Bhutani",
  "Byelorussian",
  "Bihari",
  "Burmese",
  "Bislama",
  "Breton",
  "Cambodian",
  "Kannada",
  "Catalan",
  "Kashmiri",
  "Kazakh",
  "Czech",
  "Chinese",
  "Singhalese",
  "Korean",
  "Corsican",
  "Croatian",
  "Kurdish",
  "Moldavian",
  "Danish",
  "Dutch",
  "Scots/Gaelic",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Esperanto",
  "Estonian",
  "Faeroese",
  "Fiji",
  "Finnish",
  "Frisian",
  "French",
  "Welsh",
  "Galician",
  "Georgian",
  "Greenlandic",
  "Greek",
  "Guarani",
  "Gujarati",
  "Hungarian",
  "Hausa",
  "Hebrew",
  "Hindi",
  "Yiddish",
  "Icelandic",
  "Indonesian",
  "Interlingua",
  "Interlingue",
  "Inupiak",
  "Yoruba",
  "Irish",
  "Italian",
  "Japanese",
  "Javanese",
  "Kinyarwanda",
  "Kirghiz",
  "Kirundi",
  "Laothian",
  "Latin",
  "Latvian/Lettish",
  "Lingala",
  "Lithuanian",
  "Macedonian",
  "Malagasy",
  "Malayalam",
  "Malay",
  "Maltese",
  "Maori",
  "Marathi",
  "Mongolian",
  "Nauru",
  "Nepali",
  "Norwegian",
  "Occitan",
  "Urdu",
  "Uzbek",
  "Pashto/Pushto",
  "Persian",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Quechua",
  "Rhaeto-Romance",
  "Romanian",
  "Russian",
  "Serbian",
  "Sanskrit",
  "Samoan",
  "Sangro",
  "Swedish",
  "Serbo-Croatian",
  "Sesotho",
  "Setswana",
  "Shona",
  "Sindhi",
  "Siswati",
  "Somali",
  "Swahili",
  "Sundanese",
  "Tatar",
  "Tamil",
  "Turkish",
  "Tajik",
  "Tagalog",
  "Thai",
  "Tegulu",
  "Tibetan",
  "Tigrinya",
  "Tonga",
  "Tsonga",
  "Turkmen",
  "Twi",
  "Ukrainian",
  "Vietnamese",
  "Volapuk",
  "Wolof",
  "Xhosa",
  "Zulu",
];
export const languages = languagesArray.map((name) => ({
  id: name,
  name,
}));

export const order = [
  {
    id: "0",
    name: "0",
  },
  {
    id: "1",
    name: "1",
  },
  {
    id: "2",
    name: "2",
  },
  {
    id: "3",
    name: "3",
  },
  {
    id: "4",
    name: "4",
  },
  {
    id: "5",
    name: "5",
  },
  {
    id: "6",
    name: "6",
  },
  {
    id: "7",
    name: "7",
  },
  {
    id: "8",
    name: "8",
  },
  {
    id: "9",
    name: "9",
  },
  {
    id: "10",
    name: "10",
  },
  {
    id: "11",
    name: "11",
  },
];
export const audiences = ["Adults", "Children", "Family", "Group", "Youth"];

export const timezones = [
  { id: 1, name: "Pacific/Midway" },
  { id: 2, name: "Pacific/Pago_Pago" },
  { id: 3, name: "Pacific/Honolulu" },
  { id: 4, name: "America/Juneau" },
  { id: 5, name: "America/Los_Angeles" },
  { id: 6, name: "America/Tijuana" },
  { id: 7, name: "America/Denver" },
  { id: 8, name: "America/Phoenix" },
  { id: 9, name: "America/Chihuahua" },
  { id: 10, name: "America/Mazatlan" },
  { id: 11, name: "America/Chicago" },
  { id: 12, name: "America/Regina" },
  { id: 13, name: "America/Mexico_City" },
  { id: 14, name: "America/Monterrey" },
  { id: 15, name: "America/Guatemala" },
  { id: 16, name: "America/New_York" },
  { id: 17, name: "America/Indiana/Indianapolis" },
  { id: 18, name: "America/Bogota" },
  { id: 19, name: "America/Lima" },
  { id: 20, name: "America/Halifax" },
  { id: 21, name: "America/Caracas" },
  { id: 22, name: "America/La_Paz" },
  { id: 23, name: "America/Santiago" },
  { id: 24, name: "America/St_Johns" },
  { id: 25, name: "America/Sao_Paulo" },
  { id: 26, name: "America/Argentina/Buenos_Aires" },
  { id: 27, name: "America/Godthab" },
  { id: 28, name: "America/Montevideo" },
  { id: 29, name: "Atlantic/South_Georgia" },
  { id: 30, name: "Atlantic/Azores" },
  { id: 31, name: "Atlantic/Cape_Verde" },
  { id: 32, name: "Africa/Casablanca" },
  { id: 33, name: "Europe/Dublin" },
  { id: 34, name: "Europe/Lisbon" },
  { id: 35, name: "Europe/London" },
  { id: 36, name: "Africa/Monrovia" },
  { id: 37, name: "Europe/Amsterdam" },
  { id: 38, name: "Europe/Belgrade" },
  { id: 39, name: "Europe/Berlin" },
  { id: 40, name: "Europe/Bratislava" },
  { id: 41, name: "Europe/Brussels" },
  { id: 42, name: "Europe/Budapest" },
  { id: 43, name: "Europe/Copenhagen" },
  { id: 44, name: "Europe/Ljubljana" },
  { id: 45, name: "Europe/Madrid" },
  { id: 46, name: "Europe/Paris" },
  { id: 47, name: "Europe/Prague" },
  { id: 48, name: "Europe/Rome" },
  { id: 49, name: "Europe/Sarajevo" },
  { id: 50, name: "Europe/Skopje" },
  { id: 51, name: "Europe/Stockholm" },
  { id: 52, name: "Europe/Vienna" },
  { id: 53, name: "Europe/Warsaw" },
  { id: 54, name: "Europe/Zagreb" },
  { id: 55, name: "Europe/Athens" },
  { id: 56, name: "Europe/Bucharest" },
  { id: 57, name: "Africa/Cairo" },
  { id: 58, name: "Africa/Harare" },
  { id: 59, name: "Europe/Helsinki" },
  { id: 60, name: "Europe/Istanbul" },
  { id: 61, name: "Asia/Jerusalem" },
  { id: 62, name: "Europe/Kiev" },
  { id: 63, name: "Europe/Minsk" },
  { id: 64, name: "Europe/Riga" },
  { id: 65, name: "Europe/Sofia" },
  { id: 66, name: "Europe/Tallinn" },
  { id: 67, name: "Europe/Vilnius" },
  { id: 68, name: "Asia/Baghdad" },
  { id: 69, name: "Asia/Kuwait" },
  { id: 70, name: "Africa/Nairobi" },
  { id: 71, name: "Asia/Riyadh" },
  { id: 72, name: "Asia/Tehran" },
  { id: 73, name: "Asia/Muscat" },
  { id: 74, name: "Asia/Baku" },
  { id: 75, name: "Asia/Tbilisi" },
  { id: 76, name: "Asia/Yerevan" },
  { id: 77, name: "Asia/Kabul" },
  { id: 78, name: "Asia/Yekaterinburg" },
  { id: 79, name: "Asia/Karachi" },
  { id: 80, name: "Asia/Tashkent" },
  { id: 81, name: "Asia/Kolkata" },
  { id: 82, name: "Asia/Colombo" },
  { id: 83, name: "Asia/Kathmandu" },
  { id: 84, name: "Asia/Almaty" },
  { id: 85, name: "Asia/Dhaka" },
  { id: 86, name: "Asia/Urumqi" },
  { id: 87, name: "Asia/Qyzylorda" },
  { id: 88, name: "Asia/Rangoon" },
  { id: 89, name: "Asia/Bangkok" },
  { id: 90, name: "Asia/Jakarta" },
  { id: 91, name: "Asia/Novosibirsk" },
  { id: 92, name: "Asia/Hong_Kong" },
  { id: 93, name: "Asia/Krasnoyarsk" },
  { id: 94, name: "Asia/Kuala_Lumpur" },
  { id: 95, name: "Asia/Singapore" },
  { id: 96, name: "Asia/Taipei" },
  { id: 97, name: "Asia/Ulaanbaatar" },
  { id: 98, name: "Asia/Urumqi" },
  { id: 99, name: "Asia/Irkutsk" },
  { id: 100, name: "Asia/Seoul" },
  { id: 101, name: "Asia/Tokyo" },
  { id: 102, name: "Asia/Yakutsk" },
  { id: 103, name: "Australia/Adelaide" },
  { id: 104, name: "Australia/Darwin" },
  { id: 105, name: "Australia/Brisbane" },
  { id: 106, name: "Australia/Sydney" },
  { id: 107, name: "Pacific/Guam" },
  { id: 108, name: "Australia/Hobart" },
  { id: 109, name: "Asia/Vladivostok" },
  { id: 110, name: "Asia/Magadan" },
  { id: 111, name: "Pacific/Fiji" },
  { id: 112, name: "Asia/Kamchatka" },
  { id: 113, name: "Pacific/Auckland" },
  { id: 114, name: "Pacific/Tongatapu" },
  { id: 115, name: "Pacific/Fakaofo" },
  { id: 116, name: "Pacific/Apia" },
  { id: 117, name: "Pacific/Kiritimati" },
];

export const page_slug = [
  { id: "terms-of-service", name: "Terms of service" },
  { id: "privacy-policy", name: "Privacy policy" },
  { id: "gdpr-compliance", name: "GDPR compliance" },
  { id: "cookie-policy", name: "Cookie policy" },
  { id: "pricing-and-fees", name: "Pricing and fees" },
  { id: "about-us", name: "About us" },
  { id: "about-us", name: "About us" },
];
