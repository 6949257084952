import { FaCircleInfo, FaPaperPlane } from "react-icons/fa6";
import "../layout/style.scss";
import { Radio, Dropdown, Space } from "antd";
import Count from "../../../../../count/Count";
import { AiTwotoneSetting } from "react-icons/ai";
import { FaEllipsisV, FaEye, FaPlus, FaSyncAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSignUp } from "../../../../../../utils/CustomFunctions";
import { currency } from "../../../../../global/Values";
import SelectBox from "./../../../../../global/SelectBox";
import { useSelector } from "react-redux";

const Payment = () => {
  const items = [
    {
      label: "Edit",
      key: "0",
    },
    {
      label: "Hide",
      key: "1",
    },
    {
      label: "Delete",
      key: "3",
    },
  ];

  const { payment_fees } = useSelector((state) => state.admin);
  const initialState = {
    currency: payment_fees?.currency || "",
    currency_symbol_position: payment_fees?.currency_symbol_position || "left",
    ticket_fee: payment_fees?.ticket_fee || "",
    ticket_fee_percentage: payment_fees?.ticket_fee_percentage || "",
    paypal: payment_fees?.paypal || "yes",
    stripe: payment_fees?.stripe || "yes",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    if (!values.currency) newErrors.currency = "This value should not be null.";
    if (!values.ticket_fee)
      newErrors.ticket_fee = "This value should not be null.";
    if (!values.ticket_fee_percentage)
      newErrors.ticket_fee_percentage = "This value should not be null.";
    return newErrors;
  };

  const {
    values,
    setValues,
    errors,
    isPending,
    handleChange,
    handleSelectBox,
    handleSubmit,
  } = useSignUp(
    initialState,
    validateFields,
    `admin/add_payment_fees`,
    "payment_fees"
  );
  return (
    <>
      <div className="card  paymencard">
        <div className="card-header border-0 d-flex align-items-center justify-content-between">
          <h6 className="fw-600 mb-0 ">Global payment settings</h6>
          <button className="btn btn-dark rounded-5">
            <FaPaperPlane className="me-1" />
            Manage currencies
          </button>
        </div>

        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form className="signform" onSubmit={handleSubmit}>
              <div className="mb-3 d-flex flex-column">
                <label htmlFor="phone_number" className="form-label fw-600">
                  Currency<span style={{ color: "red" }}>*</span>
                </label>
                <SelectBox
                  name="currency"
                  options={currency}
                  val={values?.currency}
                  change={handleSelectBox}
                />
                {errors.currency && (
                  <small className="text-danger d-block mt-1">
                    {errors.currency}
                  </small>
                )}
              </div>
              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Currency symbol position
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      name="currency_symbol_position"
                      checked={values.currency_symbol_position === "left"}
                      onChange={handleChange}
                      value="left"
                    >
                      Left
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      name="currency_symbol_position"
                      checked={values.currency_symbol_position === "right"}
                      value="right"
                      onChange={handleChange}
                    >
                      Right
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="Facebook_app_id" className="form-label fw-600">
                  Ticket tax
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Count
                  icon="true"
                  icp="p"
                  name="ticket_fee"
                  value={values.ticket_fee}
                  state="values"
                  setState={setValues}
                />
                {errors.ticket_fee && (
                  <small className="text-danger d-block mt-1">
                    {errors.ticket_fee}
                  </small>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="Facebook_app_id" className="form-label fw-600">
                  Ticket fee percentage
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Count
                  icon="true"
                  icp="p"
                  name="ticket_fee_percentage"
                  value={values.ticket_fee_percentage}
                  state="values"
                  setState={setValues}
                />
                {errors.ticket_fee_percentage && (
                  <small className="text-danger d-block mt-1">
                    {errors.ticket_fee_percentage}
                  </small>
                )}
              </div>

              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Allow Paypal as a payout method for the organizers to
                    receive their revenue
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      name="paypal"
                      checked={values.paypal === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      name="paypal"
                      checked={values.paypal === "no"}
                      value="no"
                      onChange={handleChange}
                    >
                      No
                    </Radio>
                  </div>
                </div>
              </div>
              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Allow Stripe as a payout method for the organizers to
                    receive their revenue
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      name="stripe"
                      checked={values.stripe === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      name="stripe"
                      checked={values.stripe === "no"}
                      value="no"
                      onChange={handleChange}
                    >
                      No
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="form-group mt-4 mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  disabled={isPending ? "disabled" : ""}
                  type="submit"
                >
                  {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="card  mt-5 paymencard">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h6 className="fw-600 mb-0 ">Payment gateways</h6>
          <Link
            // to="/en/dashboard/administrator/settings/payment/gateways/add"
            to=""
            className="btn btn-dark ml-3 rounded-5"
            data-toggle="tooltip"
            title=""
            data-original-title="Add a new payment gateway"
          >
            <FaPlus />
          </Link>
        </div>
        <div className="card-body p-0 ">
          <div className="row organizer-event">
            <div className="col-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-hover table-vcenter">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Logo</th>
                        <th>Status</th>
                        <th>Order of appearance </th>

                        <th className="text-center">
                          <AiTwotoneSetting />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Credit/Debit Cards</td>
                        <td>
                          <Link
                            className="fancybox"
                            to="/./images/strip.svg"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Enlarge"
                            target="_blank"
                          >
                            <img
                              src="/./images/strip.svg"
                              className=" img-50-50"
                            />
                          </Link>
                        </td>
                        <td className="">
                          <span className="badge badge-success ">
                            <FaEye className="me-1" />
                            Enabled
                          </span>
                        </td>
                        <td>1</td>
                        <td className="text-center">
                          <Dropdown
                            menu={{
                              items,
                            }}
                            trigger={["click"]}
                          >
                            <a onClick={(e) => e.preventDefault()}>
                              <Space className="justify-content-center">
                                <FaEllipsisV />
                              </Space>
                            </a>
                          </Dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
