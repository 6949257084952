import { FaMailBulk } from "react-icons/fa";
import { Wrapper } from "./Wrapper";
import { useDispatch, useSelector } from "react-redux";
import SelectBox from "../global/SelectBox";
import { addBlogFilters } from "../../store/slice/BlogFilters";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const BlogSidebar = () => {
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const { blog_categories } = useSelector((state) => state.AllData);
  const {
    filters: { keyword, category, tags },
    all_blogs,
  } = useSelector((state) => state.blogFilters);
  const [isCategory, setIsCategory] = useState({});

  const add_filter2 = (name, value) => {
    dispatch(addBlogFilters({ name, value }));
  };
  const add_filter = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(addBlogFilters({ name, value }));
  };

  // GET UNOQUE DATA OF EACH FILD
  const getUniqueData = (data) => {
    let newVal = data.map((curElem) => curElem?.tags);
    newVal = newVal.filter((tag) => tag !== null);
    newVal = newVal.flat();
    return ["all", ...new Set(newVal)];
  };
  // we need to have the individual data of each in an array format
  const allBogTags = getUniqueData(all_blogs);

  useEffect(() => {
    state && setIsCategory(state);
  }, [pathname]);

  useEffect(() => {
    ((Object.keys(isCategory).length > 0 && isCategory.name === "category") ||
      isCategory.name === "tags") &&
      dispatch(addBlogFilters(isCategory));

    ((Object.keys(isCategory).length > 0 && isCategory.name === "category") ||
      isCategory.name === "tags") &&
      setIsCategory({});
  });

  return (
    <Wrapper>
      <div
        className="accordion accordion-flush mb-4"
        id="accordionFlushExample"
      >
        {/* search */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed fw-600"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="true"
              aria-controls="flush-collapseOne"
            >
              Keyword
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingOne"
            data-bs-parent=""
          >
            <div className="accordion-body signform">
              <input
                type="text"
                name="keyword"
                value={keyword}
                className="form-control"
                onChange={add_filter}
              />
            </div>
          </div>
        </div>
        {/* category */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button collapsed fw-600"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="true"
              aria-controls="flush-collapseTwo"
            >
              Category
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingTwo"
            data-bs-parent=""
          >
            <div className="accordion-body">
              <SelectBox
                name="category"
                options={blog_categories}
                val={category}
                change={add_filter2}
              />
            </div>
          </div>
        </div>
        {/* tags */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              className="accordion-button collapsed fw-600"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="true"
              aria-controls="flush-collapseThree"
            >
              Tags
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingThree"
            data-bs-parent=""
          >
            <div className="accordion-body location-tab">
              {(allBogTags.tags || allBogTags?.length > 0) && (
                <div
                  className="row"
                  style={{ borderBottom: "1px solid #efefef" }}
                >
                  <div className="col">
                    {allBogTags?.map((tag, index) => {
                      return (
                        <Link
                          to=""
                          className={`btn btn-tags btn-sm me-3 mb-3 mt-3 ${
                            tags === tag && "active"
                          }`}
                          key={index}
                          onClick={(e) => {
                            e.preventDefault();
                            add_filter2("tags", tag);
                          }}
                        >
                          {tag}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <div className="subscribe-tab bg-dark">
          <div className="figure">
            <div className="fig">
              <FaMailBulk className="icon" />
            </div>
            <div className="figcaption">
              <h5 className="text-white m-0">Subscribe to our newsletter</h5>
            </div>
          </div>
          <div className="form-row mt-3">
            <div className="form-group">
              <input
                type="text"
                className="form-control rounded-0"
                placeholder="Email address"
              />
            </div>
            <div className="form-group">
              <button className="btn btn-dark w-100 rounded-0">
                SUBSCRIBE
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </Wrapper>
  );
};

export default BlogSidebar;
