import React, { forwardRef } from "react";
import { FaDollarSign, FaPercentage } from "react-icons/fa";
import "./style.css";

const Count = forwardRef(
  (
    {
      per_attendee = null,
      icon = false,
      icp = null,
      name,
      value,
      state = null,
      setState,
      id = null,
      tickets_left = null,
    },
    ref
  ) => {
    const prvValue = parseFloat(value) || 0;

    // if (eventExists && state !== "newEvent") return setState("");

    const increment = (e) => {
      e.preventDefault();

      if (state === "newEvent") {
        setState((prevEvent) => ({
          ...prevEvent,
          tickets: prevEvent.tickets?.map((ticket) => {
            if (ticket.id === id) {
              return {
                ...ticket,
                [name]: prvValue + 1,
              };
            }
            return ticket;
          }),
        }));
      } else {
        setState((prevEvent) => ({
          ...prevEvent,
          [name]:
            per_attendee &&
            prvValue >= per_attendee &&
            tickets_left &&
            prvValue <= tickets_left
              ? per_attendee
              : tickets_left && prvValue >= tickets_left
              ? tickets_left
              : tickets_left == 0 || per_attendee == 0
              ? 0
              : prvValue + 1,
        }));
      }
    };

    const decrement = (e) => {
      e.preventDefault();

      if (state === "newEvent") {
        setState((prevEvent) => ({
          ...prevEvent,
          tickets: prevEvent.tickets?.map((ticket) => {
            if (ticket.id === id) {
              return {
                ...ticket,
                [name]: prvValue <= 0 ? 0 : prvValue - 1,
              };
            }
            return ticket;
          }),
        }));
      } else {
        setState((prevEvent) => ({
          ...prevEvent,
          [name]: prvValue <= 0 ? 0 : prvValue - 1,
        }));
      }
    };

    const handleCountChange = (e) => {
      const { name, value } = e.target;

      // Directly update the state with the entered value
      if (state === "newEvent") {
        setState((prevEvent) => ({
          ...prevEvent,
          tickets: prevEvent.tickets?.map((ticket) => {
            if (ticket.id === id) {
              return {
                ...ticket,
                [name]: value,
              };
            }
            return ticket;
          }),
        }));
      } else {
        setState((prevEvent) => ({
          ...prevEvent,
          [name]:
            per_attendee && parseFloat(value) > per_attendee
              ? per_attendee
              : value,
        }));
      }
    };

    return (
      <div className="input-group count">
        {icon && (
          <span className="input-group-text">
            {icp ? <FaPercentage /> : <FaDollarSign />}
          </span>
        )}
        <input
          type="number"
          className="form-control"
          onChange={handleCountChange}
          name={name}
          value={value}
          ref={ref}
        />
        <span className="input-group-btn-vertical">
          <button
            className="btn btn-dark bootstrap-touchspin-up"
            type="button"
            onClick={increment}
          >
            +
          </button>
          <button
            className="btn btn-dark bootstrap-touchspin-down"
            type="button"
            onClick={decrement}
          >
            -
          </button>
        </span>
      </div>
    );
  }
);

export default Count;
