import React from "react";
import { FaExclamationCircle, FaSyncAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import Alert from "../../../../global/alert/Alert";
import ReviewCard from "../../../../reviews/ReviewCard";
import Filters from "./Filters";

const Reviews = () => {
  const { event_reviews, events, loading } = useSelector(
    (state) => state.organizer
  );

  const { filter_data } = useSelector((state) => state.organizerFilters);

  const msg = {
    icon: <FaExclamationCircle className="me-3" />,
    type: "alert-info",
    text: "No reviews found",
  };

  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event payoutRequests">
        <Filters event_reviews={event_reviews} events={events} />
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : !filter_data?.event_reviews?.length > 0 ? (
        <Alert {...msg} />
      ) : (
        <div className="card review">
          <div className="card-body">
            {filter_data?.event_reviews?.map((review, index) => {
              return <ReviewCard review={review} key={index} />;
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Reviews;
