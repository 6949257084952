import React, { useEffect, useState } from "react";
import { AiTwotoneSetting } from "react-icons/ai";
import { FaSyncAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../../../../utils/CustomFunctions";

const TicketDetail = () => {
  const server = process.env.REACT_APP_SERVER;
  const { tickets, myWallet, loading } = useSelector(
    (state) => state.CartDetails
  );
  const navigate = useNavigate();
  const { slug } = useParams();
  const url = useLocation();
  const segments = url.pathname.split("/");
  const path = segments[4];

  const [ticket, setTicket] = useState({});
  const date = new Date(ticket?.event?.start_On);
  const { fullDate } = formatDate(date);
  useEffect(() => {
    slug &&
      setTicket(
        (path === "my-tickets" ? tickets : myWallet).find(
          (curElem) => curElem.reference === slug
        )
      );
  }, [slug]);

  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        Object.keys(ticket).length > 0 && (
          <div className="card cart">
            <div className="card-header">
              <h6 className="mb-0">
                {" "}
                <span
                  className={`badge badge-${
                    ticket?.payment === "pending" ? "warning" : "success"
                  } text-capitalize`}
                >
                  {ticket?.payment} payment
                </span>
              </h6>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead className="text-muted">
                    <tr>
                      <th scope="col">Event / Ticket</th>
                      <th scope="col" width="100">
                        Price
                      </th>
                      <th scope="col" width="100">
                        Quantity
                      </th>
                      <th scope="col" width="100">
                        Subtotal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <figure className="media h-100">
                          <div className="img-wrap my-auto">
                            <img
                              src={`${server}/event/image/${ticket?.event?.main_event_image}`}
                              className="img-thumbnail img-sm img-lazy-load b-loaded"
                            />
                          </div>
                          <figcaption className="media-body my-auto ">
                            <Link to={`/en/event/${ticket?.event?.slug}`}>
                              <h6 className="title mb-2">
                                {ticket?.event?.name}
                              </h6>
                            </Link>
                            <dl className="dlist-inline small">
                              <dt></dt>
                              <dd className="mr-0"></dd>
                              {ticket?.event?.tickets?.[0]?.ticket_name}
                            </dl>
                            <dl className="dlist-inline small">
                              <dt className="me-1">When</dt>
                              <dd> {fullDate}</dd>
                            </dl>
                            <dl className="dlist-inline small">
                              <dt>Where</dt>{" "}
                              <dd className="text-capitalize">
                                {ticket?.event?.event_date_online === 1 ? (
                                  " Online"
                                ) : (
                                  <>
                                    {ticket?.event?.venue?.city}:{" "}
                                    {ticket?.event?.venue?.postal_code}{" "}
                                    {ticket?.event?.venue?.street_address},{" "}
                                    {ticket?.event?.venue?.state},{" "}
                                    {ticket?.event?.venue?.country}
                                  </>
                                )}
                              </dd>
                            </dl>
                            <dl className="dlist-inline small">
                              <dt className="me-1">Organizer</dt>
                              <dd>
                                <Link
                                  to={`/en/organizer/${ticket?.event?.user?.slug}`}
                                  taget="_blank"
                                >
                                  {ticket?.event?.user?.organize_name}
                                </Link>
                              </dd>
                            </dl>
                          </figcaption>
                        </figure>
                      </td>
                      <td>
                        <div className="">
                          <span className="">{ticket?.ticket_price}$</span>
                        </div>
                      </td>
                      <td>{ticket.ticket_quantity}</td>
                      <td className="text-center">
                        <div className="price-wrap">
                          <var className="price">{ticket?.sub_price}$</var>
                        </div>
                      </td>
                      <td className="text-center remove">
                        <a
                          href=""
                          data-toggle="tooltip"
                          title="Remove"
                          data-original-title="Remove"
                          // onClick={(e) => {
                          //   e.preventDefault();
                          //   dispatch(removeCartItem(curElem.id));
                          // }}
                        >
                          {/* <AiOutlineMinusSquare /> */}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="5" className="text-end">
                        <div className="price-wrap">
                          <var className="price">Fees: {ticket?.fees}%</var>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5" className="text-end">
                        <div className="price-wrap">
                          <var className="price">
                            Total: {ticket?.total_price}$
                          </var>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5" className="text-end footbtn">
                        {/* <Link to="/en/events" className="btn btn-outline-dark mr-3">
                      <FaTicketAlt /> Buy more tickets
                    </Link> */}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default TicketDetail;
