import React from "react";
import { Link } from "react-router-dom";
import Review from "./Review";
import { formatDate } from "../../utils/CustomFunctions";
import Alert from "../global/alert/Alert";
import { FaExclamationCircle } from "react-icons/fa";

const ReviewCard = ({ review }) => {
  const server = process.env.REACT_APP_SERVER;
  const msg = {
    icon: <FaExclamationCircle className="me-3" />,
    type: "alert-info",
    text: "No reviews found",
  };

  return (
    <>
      {review.reviews.length > 0 && (
        <div className="row mb-4">
          <div className="col">
            <div className="user-review-wrapper mb-5">
              {review?.main_event_image ? (
                <Link to={`/en/event/${review?.slug}`}>
                  <img
                    src={`${server}/event/image/${review?.main_event_image}`}
                    className="img-thumbnail img-100-100 img-sm"
                  />{" "}
                  {review?.name}
                </Link>
              ) : (
                <img
                  src="/./images/noPhoto.png"
                  className="img-thumbnail img-100-100 img-sm"
                />
              )}

              <div className="rating-wrap mt-3">
                <Review rating={review.average_rating} />
              </div>

              {review.reviews.map((review, index) => {
                return (
                  <React.Fragment key={index}>
                    <h6 className="mt-4 font-weight-bold">
                      {review?.headline}
                    </h6>
                    <p
                      className=" text-sm readmore rmjs-1 m-0"
                      data-collapsed-height="200"
                      data-height-margin="20"
                    >
                      {review?.details}
                    </p>
                    <small className="text-muted d-inline-block">
                      {formatDate(new Date(review.date)).fullDate}
                    </small>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewCard;
