import React from "react";

const AverageRating = ({ reviews }) => {
  const calculatePercentage = (count) => {
    if (count > 0) {
      return (count / reviews.length) * 100;
    } else {
      return 0;
    }
  };

  const getStarCount = (reviews, star) => {
    return reviews?.filter((review) => parseInt(review.rating) === star).length;
  };
  return (
    <>
      {Array.from({ length: 5 }, (_, index) => index + 1).map((star) => {
        const rating = calculatePercentage(getStarCount(reviews, star)).toFixed(
          0
        );
        return (
          <div className="row rating-bar" key={star}>
            <div className="col-2">{star} stars</div>
            <div className="col-8 ">
              <div className="progress">
                <div
                  className="progress-bar bg-dark"
                  role="progressbar"
                  style={{
                    width: `${rating}%`,
                  }}
                  aria-valuenow={rating}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="col-2 text-end">{rating}%</div>
          </div>
        );
      })}
    </>
  );
};

export default AverageRating;
