import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaList, FaSyncAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  calculate_Percentage,
  formatDate,
} from "../../../../../utils/CustomFunctions";
import { Progress, Space, Tooltip } from "antd";

const Stats = () => {
  const { id } = useParams();
  const api = process.env.REACT_APP_SERVER_API;
  const { user } = useSelector((state) => state.authUser);
  const [statistics, setStatistics] = useState(null);
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchstate = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${api}/organizer/statistics/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (data.status === 200) {
        setStatistics(data.data);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchstate();
  }, [id]);

  // for check dates
  function validDate(start, end) {
    const currentDate = new Date();
    const isSart = new Date(start) <= currentDate;
    const isEnd = new Date(end) < currentDate;
    return { isSart, isEnd };
  }

  useEffect(() => {
    const ticketSale = validDate(
      statistics?.[0]?.event?.tickets?.[0]?.sale_start,
      statistics?.[0]?.event?.tickets?.[0]?.sale_end
    );
    const event = validDate(
      statistics?.[0]?.event?.start_On,
      statistics?.[0]?.event?.end_On
    );
    setDate({ ticketSale, event });
  }, [id, loading]);

  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <div className="card">
          <div className="card-header">
            <h4>
              <i className="dropdown-icon fas fa-chart-line fa-fw text-muted"></i>{" "}
              {statistics?.[0]?.event?.name}
            </h4>
            <p>
              {statistics?.[0]?.event?.status === "inactive" && (
                <span className="badge badge-warning">
                  Event is not yet published.
                </span>
              )}

              {statistics?.[0]?.event?.status === "active" &&
                (statistics?.[0]?.event?.tickets?.[0]?.enable_sales_ticket ===
                  0 ||
                  statistics?.[0]?.event?.enable_event_date === 0 ||
                  !date?.ticketSale.isSart ||
                  date?.ticketSale.isEnd) && (
                  <span className="badge badge-info">No events on sale</span>
                )}

              {statistics?.[0]?.event?.status === "active" &&
                statistics?.[0]?.event?.tickets?.[0]?.enable_sales_ticket ===
                  1 &&
                statistics?.[0]?.event?.enable_event_date === 1 &&
                date?.ticketSale.isSart &&
                !date?.ticketSale.isEnd &&
                !date?.event.isSart && (
                  <span className="badge badge-success">on sale</span>
                )}

              {statistics?.[0]?.event?.status === "active" &&
                statistics?.[0]?.event?.tickets?.[0]?.enable_sales_ticket ===
                  1 &&
                statistics?.[0]?.event?.enable_event_date === 1 &&
                date?.ticketSale.isSart &&
                !date?.ticketSale.isEnd &&
                date?.event.isSart && (
                  <span className="badge badge-info">
                    Event already started
                  </span>
                )}
            </p>
            <p>
              When:{" "}
              {formatDate(new Date(statistics?.[0]?.event?.start_On)).fullDate}
            </p>
            <p>
              Where: {""}
              {statistics?.[0]?.event?.event_date_online === 1 ? (
                "Online"
              ) : (
                <>
                  {statistics?.[0]?.event?.venue?.city}:{" "}
                  {statistics?.[0]?.event?.venue?.postal_code}{" "}
                  {statistics?.[0]?.event?.venue?.street_address},{" "}
                  {statistics?.[0]?.event?.venue?.state},{" "}
                  {statistics?.[0]?.event?.venue?.country}
                </>
              )}
            </p>
            <p>
              <Tooltip title="Toggle display the event date details">
                <button
                  className="btn btn-dark btn-sm"
                  data-bs-toggle="collapse"
                  data-bs-target="#event-date-details"
                  aria-controls="event-date-details"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <FaList />
                </button>
              </Tooltip>
            </p>
          </div>
          <div className="card-body">
            <div id="event-date-details" className="row mb-5 collapse">
              <div className="col-12">
                <div className="card shadow-0">
                  <div className="card-header">Details</div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-borderless table-striped table-hover table-sm mb-0">
                        <tbody>
                          <tr>
                            <td width="30%">Status</td>
                            <td>
                              {statistics?.[0]?.event?.status ===
                                "inactive" && (
                                <span className="badge badge-warning">
                                  Event is not yet published.
                                </span>
                              )}

                              {statistics?.[0]?.event?.status === "active" &&
                                (statistics?.[0]?.event?.tickets?.[0]
                                  ?.enable_sales_ticket === 0 ||
                                  statistics?.[0]?.event?.enable_event_date ===
                                    0 ||
                                  !date?.ticketSale.isSart ||
                                  date?.ticketSale.isEnd) && (
                                  <span className="badge badge-info">
                                    No events on sale
                                  </span>
                                )}

                              {statistics?.[0]?.event?.status === "active" &&
                                statistics?.[0]?.event?.tickets?.[0]
                                  ?.enable_sales_ticket === 1 &&
                                statistics?.[0]?.event?.enable_event_date ===
                                  1 &&
                                date?.ticketSale.isSart &&
                                !date?.ticketSale.isEnd &&
                                !date?.event.isSart && (
                                  <span className="badge badge-success">
                                    on sale
                                  </span>
                                )}

                              {statistics?.[0]?.event?.status === "active" &&
                                statistics?.[0]?.event?.tickets?.[0]
                                  ?.enable_sales_ticket === 1 &&
                                statistics?.[0]?.event?.enable_event_date ===
                                  1 &&
                                date?.ticketSale.isSart &&
                                !date?.ticketSale.isEnd &&
                                date?.event.isSart && (
                                  <span className="badge badge-info">
                                    Event already started
                                  </span>
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Reference</td>
                            <td>{statistics?.[0]?.event?.reference}</td>
                          </tr>
                          <tr>
                            <td width="50%">Venue</td>
                            <td>
                              {statistics?.[0]?.event?.name} at{" "}
                              {statistics?.[0]?.event?.venue?.city}
                            </td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>
                              {statistics?.[0]?.event?.event_date_online ===
                              1 ? (
                                "Online"
                              ) : (
                                <>
                                  {statistics?.[0]?.event?.venue?.city}{" "}
                                  {statistics?.[0]?.event?.venue?.postal_code}{" "}
                                  {
                                    statistics?.[0]?.event?.venue
                                      ?.street_address
                                  }
                                  , {statistics?.[0]?.event?.venue?.state},{" "}
                                  {statistics?.[0]?.event?.venue?.country}
                                </>
                              )}
                            </td>
                          </tr>
                          {/* <tr>
                            <td>Tickets types</td>
                            <td>1</td>
                          </tr> */}
                          <tr>
                            <td>Start date</td>
                            <td>
                              {
                                formatDate(
                                  new Date(statistics?.[0]?.event?.start_On)
                                ).fullDate
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>End date</td>
                            <td>
                              {
                                formatDate(
                                  new Date(statistics?.[0]?.event?.end_On)
                                ).fullDate
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-5 mt-5 pl-2 pr-2">
              <div className="col-md-3 col-sm-6 col-12 p-1">
                <div className="bg-dark rounded p-4 text-center text-white position-relative counter-box">
                  <i className="fas fa-ticket-alt"></i>
                  <h4>
                    <b className="counter">
                      {statistics?.[0]?.check_out_ticket_quantity}
                    </b>
                  </h4>
                  <h5 className="mb-0">Tickets sold</h5>
                </div>
              </div>

              <div className="col-md-3 col-sm-6 col-12 p-1">
                <div className="bg-dark rounded p-4 text-center text-white position-relative counter-box">
                  <i className="fas fa-star"></i>
                  <h4>
                    <b className="counter">
                      {statistics?.[0]?.event?.reviews.length}
                    </b>
                  </h4>
                  <h5 className="mb-0">Attendees reviews</h5>
                </div>
              </div>
            </div>

            <div className="row mb-5 pl-2 pr-2">
              <div className="col-md-3 col-sm-6 col-12 p-1">
                <p className="text-center mt-2">
                  <small className="text-muted">Total tickets sales</small>
                </p>
                <div className="text-center mb-2">
                  <Space wrap>
                    <Progress
                      type="circle"
                      percent={calculate_Percentage(
                        statistics?.[0]?.check_out_ticket_quantity,
                        statistics?.[0]?.event?.tickets?.[0]?.quantity
                      )}
                      size={130}
                    />
                  </Space>
                </div>

                <p className="text-center mb-1">
                  <small className="text-muted font-weight-bold">
                    {statistics?.[0]?.check_out_ticket_quantity} /{" "}
                    {statistics?.[0]?.event?.tickets?.[0]?.quantity}
                  </small>
                </p>
              </div>
              <div className="col-md-3 col-sm-6 col-12 p-1">
                <p className="text-center mt-2">
                  <small className="text-muted">Total attendees check-in</small>
                </p>
                <div className="text-center mb-2">
                  <Space wrap>
                    <Progress
                      type="circle"
                      percent={statistics?.[0]?.event?.check_out.length}
                      size={130}
                    />
                  </Space>
                </div>
                <p className="text-center">
                  <small className="text-muted font-weight-bold">
                    {statistics?.[0]?.event?.check_out.length}
                  </small>
                </p>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h6 className="mb-0 d-inline">
                      <b>
                        <i className="dropdown-icon fas fa-chart-line fa-fw text-muted"></i>{" "}
                        {statistics?.[0]?.event?.tickets?.[0]?.ticket_name} -{" "}
                        {statistics?.[0]?.event?.status === "inactive" && (
                          <span className="badge badge-warning">
                            Event is not yet published.
                          </span>
                        )}
                        {statistics?.[0]?.event?.status === "active" &&
                          (statistics?.[0]?.event?.tickets?.[0]
                            ?.enable_sales_ticket === 0 ||
                            statistics?.[0]?.event?.enable_event_date === 0 ||
                            !date?.ticketSale.isSart ||
                            date?.ticketSale.isEnd) && (
                            <span className="badge badge-info">
                              No events on sale
                            </span>
                          )}
                        {statistics?.[0]?.event?.status === "active" &&
                          statistics?.[0]?.event?.tickets?.[0]
                            ?.enable_sales_ticket === 1 &&
                          statistics?.[0]?.event?.enable_event_date === 1 &&
                          date?.ticketSale.isSart &&
                          !date?.ticketSale.isEnd &&
                          !date?.event.isSart && (
                            <span className="badge badge-success">on sale</span>
                          )}
                        {statistics?.[0]?.event?.status === "active" &&
                          statistics?.[0]?.event?.tickets?.[0]
                            ?.enable_sales_ticket === 1 &&
                          statistics?.[0]?.event?.enable_event_date === 1 &&
                          date?.ticketSale.isSart &&
                          !date?.ticketSale.isEnd &&
                          date?.event.isSart && (
                            <span className="badge badge-info">
                              Event already started
                            </span>
                          )}{" "}
                        - ${statistics?.[0]?.event?.tickets?.[0]?.Price}
                      </b>
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <div className="bg-dark rounded p-4 text-center text-white position-relative counter-box">
                          <i className="fas fa-shopping-cart"></i>
                          <h4>
                            {
                              statistics?.[0]?.event?.check_out.filter(
                                (check) => check.payment === "pending"
                              ).length
                            }
                          </h4>
                          <h5 className="mb-0">Still in cart</h5>
                        </div>
                      </div>
                      <div className="col p-0">
                        <p className="text-center mt-2">
                          <small className="text-muted">Tickets sales</small>
                        </p>
                        <div className="text-center mb-2">
                          <Space wrap>
                            <Progress
                              type="circle"
                              percent={statistics?.[0]?.event?.check_out.length}
                              size={130}
                            />
                          </Space>
                        </div>
                        <p className="text-center">
                          <small className="text-muted font-weight-bold">
                            {statistics?.[0]?.event?.check_out.length}
                          </small>
                        </p>
                      </div>
                      <div className="col p-0">
                        <p className="text-center mt-2">
                          <small className="text-muted">
                            Attendees check-in
                          </small>
                        </p>
                        <div className="text-center mb-2">
                          <Space wrap>
                            <Progress
                              type="circle"
                              percent={statistics?.[0]?.event?.check_out.length}
                              size={130}
                            />
                          </Space>
                        </div>
                        <p className="text-center">
                          <small className="text-muted font-weight-bold">
                            {statistics?.[0]?.event?.check_out.length}
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Stats;
