import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../../../../utils/CustomFunctions";
import axios from "axios";
import { toast } from "react-toastify";
import { Radio } from "antd";
import { FaFileInvoiceDollar, FaSyncAlt } from "react-icons/fa";

const AddPayoutRequests = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { countries } = useSelector((state) => state.AllData);
  const [isPending, setPending] = useState(false);
  const { user } = useSelector((state) => state.authUser);
  const api = process.env.REACT_APP_SERVER_API;

  const { events, loading } = useSelector((state) => state.organizer);
  const [event, setEvent] = useState({});
  const [payoutRequest, setPayoutRequest] = useState({
    id: slug,
    payout_method: "stripe",
  });

  useEffect(() => {
    slug && setEvent(events.find((e) => e.id == slug));
  }, [slug]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayoutRequest({ ...payoutRequest, [name]: value });
  };
  // for request payout
  const handleAddPayout = async (e) => {
    e.preventDefault();
    try {
      setPending(true);

      const { data } = await axios.post(
        `${api}/organizer/payout_method_request`,
        payoutRequest,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (data.status === 200 || data.status === 201) {
        toast.success(data.message);
        navigate(`/en/dashboard/organizer/my-payout-requests`);
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
    } finally {
      setPending(false);
    }
  };

  return (
    <>
      <div className="card box">
        <div className="card-body">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <b>Event</b>
              <h5 className="mt-1">
                {event?.name} -{" "}
                <span className="text-uppercase">
                  {event?.venue?.city} {event?.venue?.state}
                </span>
              </h5>
            </li>
            <li className="list-group-item">
              <b>Date</b>
              <h5 className="mt-1">
                {formatDate(new Date(event?.start_On)).fullDate}
              </h5>
            </li>
            <li className="list-group-item">
              <b>Venue</b>
              <h5 className="mt-1">
                {event?.venue?.city}: {event?.venue?.postal_code}{" "}
                {event?.venue?.street_address}, {event?.venue?.state},{" "}
                {countries?.map((curElem) => {
                  if (curElem.id == event?.venue?.country) {
                    return (
                      <React.Fragment key={curElem.id}>
                        {curElem.name}
                      </React.Fragment>
                    );
                  }
                  return null; // or any other default value if the condition is not met
                })}
              </h5>
            </li>
            <li className="list-group-item">
              <b className="mr-3">Tickets sold</b>
              <h5 className="mt-1">{event?.totalQuantity}</h5>
            </li>
            <li className="list-group-item  list-group-item-success">
              <b>Net sales</b>
              <h5 className="mt-1">${event?.totalSubPrice}</h5>
            </li>
            <li className="list-group-item mt-3">
              <b className="mr-3">Select a payout method</b>
              <div className="row mt-2">
                <div className="col-3 form-group">
                  <div className="custom-control custom-radio custom-control-inline">
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      name="payout_method"
                      checked={payoutRequest.payout_method === "stripe"}
                      onChange={handleChange}
                      value="stripe"
                    >
                      <img
                        src="/./images/strip.svg"
                        className="img-80-80 mr-3 ml-3"
                        style={{ width: "80px", height: "" }}
                      />
                    </Radio>
                  </div>
                </div>
                <div className="col-3 form-group">
                  <div className="custom-control custom-radio custom-control-inline">
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      name="payout_method"
                      checked={payoutRequest.payout_method === "paypal"}
                      value="paypal"
                      onChange={handleChange}
                    >
                      <img
                        src="/./images/paypal.svg"
                        style={{ width: "80px", height: "" }}
                        className="img-80-80 mr-3 ml-3"
                      />{" "}
                    </Radio>
                  </div>
                </div>
              </div>
            </li>
            <li className="list-group-item list-group-item-action text-center pt-5">
              <button
                className="btn btn-dark rounded-5"
                type="button"
                disabled={isPending ? "disabled" : ""}
                onClick={handleAddPayout}
              >
                {isPending ? (
                  <FaSyncAlt className="spinIcon" />
                ) : (
                  <>
                    <FaFileInvoiceDollar className="me-1" /> Confirm payout
                    request
                  </>
                )}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AddPayoutRequests;
