import { Dropdown, Menu, Pagination, Space, Tooltip } from "antd";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaEdit,
  FaEllipsisV,
  FaEye,
  FaInfoCircle,
  FaTrashRestoreAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Alert from "../../../../global/alert/Alert";
import { FaEyeSlash } from "react-icons/fa6";
import {
  useDelete,
  useStatusChange,
} from "../../../../../utils/CustomFunctions";
import { MdDelete } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { useState } from "react";

export const Table = ({ data, error }) => {
  const server = process.env.REACT_APP_SERVER;

  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No venues found",
  };

  //show hide and show Status
  const { loading, handleStatusChange } = useStatusChange(
    "organizer",
    "venues_change_status",
    "venues_details",
    "venues"
  );
  // for soft delete and parmanently delete
  const { isLoading, handleDelete } = useDelete(
    "organizer",
    "venues_details",
    "venues"
  );

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = data?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <div className="row organizer-event">
        <div className="col-12">
          {!data?.length > 0 ? (
            <Alert {...msg} />
          ) : (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Image</th>
                      <th> Events count</th>
                      <th>Status</th>
                      <th className="text-center">
                        <AiTwotoneSetting />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((curElem) => {
                      const {
                        id,
                        name,
                        events_count,
                        images,
                        status,
                        slug,
                        deleted_at,
                      } = curElem;
                      const items = [
                        {
                          label: (
                            <Link
                              to={`${id}/edit`}
                              className="d-flex align-items-center"
                            >
                              <FaEdit className="me-1" />
                              Edit
                            </Link>
                          ),
                          key: "0",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) => handleStatusChange(e, id)}
                            >
                              {status === "visible" ? (
                                <>
                                  <FaEyeSlash className="me-1" />
                                  Hidden
                                </>
                              ) : (
                                <>
                                  <FaEye className="me-1" /> Visible
                                </>
                              )}
                            </Link>
                          ),
                          key: "1",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(e, id, "venue_delete", "")
                              }
                            >
                              {deleted_at ? (
                                <>
                                  <FaTrashRestoreAlt className="me-1" /> Restore
                                </>
                              ) : (
                                <>
                                  <IoCloseSharp className="me-1" />
                                  Delete
                                </>
                              )}
                            </Link>
                          ),
                          key: "3",
                        },
                        deleted_at && {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(
                                  e,
                                  id,
                                  "venue_delete_permanent",
                                  "delete_permanent"
                                )
                              }
                            >
                              <MdDelete className="me-1" />
                              Delete permanently
                            </Link>
                          ),
                          key: "4",
                        },
                      ];
                      return (
                        <tr key={id}>
                          <td>
                            <Link
                              to={
                                status === "visible"
                                  ? `/en/venue/${slug}`
                                  : "/en/venues"
                              }
                            >
                              {name}
                            </Link>
                          </td>
                          <td>
                            <Tooltip title="Enlarge">
                              {images && images?.length > 0 ? (
                                <Link
                                  className="fancybox"
                                  to={`${server}/venue/image/${images[0]?.name}`}
                                  target="_blank"
                                >
                                  <img
                                    src={`${server}/venue/image/${images[0]?.name}`}
                                    className="img-thumbnail img-50-50"
                                  />
                                </Link>
                              ) : (
                                <img
                                  src="/./images/noPhoto.png"
                                  className="img-thumbnail img-50-50"
                                  alt=""
                                />
                              )}
                            </Tooltip>
                          </td>
                          <td>{events_count}</td>
                          <td className="">
                            <span
                              className={`badge badge-${
                                status === "visible" ? "success" : "danger"
                              } d-inline-flex align-items-center`}
                            >
                              {status === "visible" ? (
                                <>
                                  <FaEye className="me-1" /> Visible
                                </>
                              ) : (
                                <>
                                  <FaEyeSlash className="me-1" />
                                  Hidden
                                </>
                              )}
                            </span>
                            {deleted_at && (
                              <>
                                <br />
                                <br />
                                <span className="badge badge-danger d-inline-flex align-items-center">
                                  <IoCloseSharp className="me-1" />
                                  Deleted
                                </span>
                              </>
                            )}
                          </td>
                          <td className="text-center table-dropdown">
                            <Dropdown
                              className="table-dropdown"
                              menu={{
                                items,
                              }}
                              trigger={["click"]}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                  <FaEllipsisV />
                                </Space>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* pagination */}
      {data?.length > 10 && (
        <Pagination
          current={currentPage}
          total={data.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};
