import React, { useState } from "react";
import Breadcrumb from "./../global/Breadcrumb";
import { BsBuilding } from "react-icons/bs";
import Top from "./Top";
import { Link } from "react-router-dom";
import "./style.css";
import {
  FaDog,
  FaInfoCircle,
  FaSpa,
  FaUmbrellaBeach,
  FaChair,
  FaDesktop,
  FaCity,
  FaSyncAlt,
} from "react-icons/fa";
import { Pagination, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllFilters,
  loadFilterVenue,
  sorted,
} from "../../store/slice/venueFilters";
import { useEffect } from "react";
import Alert from "../global/alert/Alert";
import VenueSidebar from "../sidebar/VenueSidebar";

const Venues = () => {
  const dispatch = useDispatch();
  const { venues, countries, loading } = useSelector((state) => state.AllData);
  const { filter_venues, sorting_value } = useSelector(
    (state) => state.venueFilters
  );
  const server = process.env.REACT_APP_SERVER;
  // console.log(venues);

  const amenityIconMap = {
    Beachfront: FaUmbrellaBeach,
    "Media room": FaDesktop,
    Spa: FaSpa,
    "Pet Friendly": FaDog,
    "Breakout rooms": FaChair,
    Rooftop: FaCity,
  };

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust this based on how many venues you want to show per page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastVenue = currentPage * itemsPerPage;
  const indexOfFirstVenue = indexOfLastVenue - itemsPerPage;
  const currentVenues = filter_venues.slice(
    indexOfFirstVenue,
    indexOfLastVenue
  );

  // for page reload
  useEffect(() => {
    dispatch(loadFilterVenue(venues));
  }, [dispatch, venues]);
  useEffect(() => {
    dispatch(sorted());
  }, [sorting_value]);

  useEffect(() => {
    dispatch(clearAllFilters());
  }, []);

  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No venues found",
  };

  return (
    <>
      <div className="container venues">
        <Top data={filter_venues} />
        <div className="row mt-4">
          <div className="col-lg-9 order-1 order-lg-2 mb-5">
            {loading ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "50vh" }}
              >
                <FaSyncAlt className="spinIcon" />
              </div>
            ) : (
              <>
                {currentVenues.length > 0 ? (
                  <>
                    {currentVenues.map((venue) => {
                      const {
                        id,
                        images,
                        name,
                        slug,
                        type,
                        description,
                        street_address,
                        street_address_2,
                        postal_code,
                        city,
                        state,
                        country,
                        seated_guests_number,
                        standing_guests_number,
                        amenities,
                      } = venue;
                      return (
                        <article className="card mb-3 card-hover" key={id}>
                          <div className="card-body">
                            <div className="row">
                              <aside className="col-md-3">
                                <div className="img-wrap">
                                  <Tooltip title="Enlarge">
                                    <Link
                                      className="fancybox"
                                      to={`${server}/venue/image/${images[0]?.name}`}
                                      target="_blank"
                                    >
                                      {images.length > 0 ? (
                                        <img
                                          src={`${server}/venue/image/${images[0]?.name}`}
                                          className="img-thumbnail img-50-50"
                                          alt="venue-image"
                                        />
                                      ) : (
                                        <img
                                          src="/./images/noPhoto.png"
                                          alt=""
                                        />
                                      )}
                                    </Link>
                                  </Tooltip>
                                </div>
                              </aside>
                              <article className="col-md-6 mt-3 mt-md-0">
                                <h4 className="">
                                  <Link to={`/en/venue/${slug}`}>{name}</Link>
                                </h4>
                                <p className="text-muted">{type?.name}</p>
                                <p
                                  className="text-muted"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      description?.substring(0, 600) + "....",
                                  }}
                                ></p>
                                {/* {name?.substring(0, 30) + "...."} */}
                                <dl className="dlist-align">
                                  <dt>Location</dt>
                                  <dd>
                                    {street_address}, {street_address_2}
                                    {city}, {state} {postal_code},{" "}
                                    {countries?.map((curElem) => {
                                      if (curElem.id == country) {
                                        return (
                                          <React.Fragment key={curElem.id}>
                                            {curElem.name}
                                          </React.Fragment>
                                        );
                                      }
                                      return null; // or any other default value if the condition is not met
                                    })}
                                  </dd>
                                </dl>
                                {(seated_guests_number ||
                                  standing_guests_number) && (
                                  <>
                                    <dl className="dlist-align">
                                      <dt>Guests</dt>
                                      <dd>
                                        Seated: {seated_guests_number} -
                                        Standing: {standing_guests_number}
                                      </dd>
                                    </dl>
                                  </>
                                )}
                              </article>
                              <aside className="col-md-3 border-left mt-3 mt-md-0 d-flex justify-content-center">
                                <div className="action-wrap center-lg-y">
                                  {amenities && amenities?.length > 0 && (
                                    <ul className="fa-ul list-lg list-inline">
                                      {amenities?.map((curElem, index) => {
                                        const IconComponent =
                                          amenityIconMap[curElem];
                                        return (
                                          IconComponent && (
                                            <li
                                              className="list-inline-item text-muted mb-2"
                                              key={index}
                                            >
                                              <Tooltip title={curElem}>
                                                <IconComponent className="fs-5 me-2" />
                                              </Tooltip>
                                            </li>
                                          )
                                        );
                                      })}
                                    </ul>
                                  )}

                                  <p className="text-center">
                                    <Link
                                      to={`/en/venue/${slug}`}
                                      className="btn"
                                    >
                                      <BsBuilding /> More details
                                    </Link>
                                  </p>
                                </div>
                              </aside>
                            </div>
                          </div>
                        </article>
                      );
                    })}
                    {filter_venues?.length > itemsPerPage && (
                      <Pagination
                        current={currentPage}
                        total={filter_venues.length}
                        pageSize={itemsPerPage}
                        onChange={handlePageChange}
                      />
                    )}
                  </>
                ) : (
                  <Alert {...msg} />
                )}
              </>
            )}
          </div>

          <div className="col-lg-3 order-2 order-lg-1 mb-4">
            <VenueSidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default Venues;
