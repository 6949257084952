import "./style.css";
import {
  FaAngleRight,
  FaInfoCircle,
  FaSearch,
  FaSyncAlt,
} from "react-icons/fa";
import Card from "./Card";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Contactus from "./Contactus";
import { useEffect, useState } from "react";
import Alert from "../../global/alert/Alert";

const Help = () => {
  const { state } = useLocation();
  const { articles, article_categories, loading } = useSelector(
    (state) => state.AllData
  );

  const [artical, setArtical] = useState([]);

  useEffect(() => {
    let foundArticle;
    if (state && state !== "all") {
      foundArticle =
        articles.filter((article) => article.tags.includes(state)) || [];
    } else {
      foundArticle = articles;
    }

    setArtical(foundArticle);
  }, [state]);

  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No articles found in this tag ",
  };

  return (
    <>
      <section className="section-help-center help-center-hero-bg padding-y-lg padding-x-md">
        <div className="row">
          <div className="col-sm-6 mx-auto">
            <article className="text-white text-center mb-5">
              <h1 className="display-4">How can we help?</h1>
              <form className="mb-3">
                <div className="input-icon">
                  <FaSearch className="mt-2" />
                  <input
                    name="keyword"
                    className="form-control"
                    placeholder="Search for answers"
                    type="text"
                  />
                </div>
              </form>
              <p>
                Organizers, attendees, this support center is intended to
                quickly reply to your questions, if still don't find answers,
                please contact us, w e will be happy to receive your inquiry
              </p>
            </article>
          </div>
        </div>
      </section>
      <section className="container-fluid help-card">
        <div className="container padding-y-lg">
          {state ? (
            <div className="row">
              {artical.length ? (
                <div className="col-12 mb-3">
                  <article className="box h-100 padding-y-lg">
                    <strong>
                      {artical.length} result(s) found for "{state}"
                    </strong>
                    <hr />
                    {artical.map((art, i) => (
                      <p>
                        <Link to={`/en/help-center/article/${art.slug}`}>
                          {art.article_category.name}
                          <FaAngleRight className="me-2 ms-2" /> {art.name}
                        </Link>
                      </p>
                    ))}
                  </article>
                </div>
              ) : (
                <Alert {...msg} />
              )}
            </div>
          ) : (
            <div className="row">
              {loading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <FaSyncAlt className="spinIcon" />
                </div>
              ) : (
                <Card article_categories={article_categories} />
              )}
            </div>
          )}

          <Contactus />
        </div>
      </section>
    </>
  );
};

export default Help;
