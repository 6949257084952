import React, { useEffect, useState, Fragment } from "react";
import "./browseeventsdetailcard.scss";
import {
  FaAt,
  FaFacebookF,
  FaGooglePlus,
  FaInstagram,
  FaLinkedin,
  FaPhone,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { RiTwitterXFill } from "react-icons/ri";
import { AiFillLinkedin, AiOutlineStar } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Review from "../../../reviews/Review";
import { useSelector } from "react-redux";
import AverageRating from "../../../reviews/AverageRating";
import UserReviews from "../../../reviews/UserReviews";
import User from "../../../reviews/User";
import VideoComponent from "../VideoComponent";
import { Tooltip } from "antd";
import SocialShare from "../../../global/SocialShare";

export default function BrowseEventDetailCard({ event, reviews }) {
  const {
    name,
    description,
    category,
    country,
    slug,
    languages,
    images,
    youtube_video_url,
  } = event;
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authUser);
  const { countries, audiences } = useSelector((state) => state.AllData);
  const server = process.env.REACT_APP_SERVER;
  const [isAddReview, setIsAddReview] = useState(false);
  const [filteredAudiences, setFilteredAudiences] = useState([]);

  useEffect(() => {
    reviews.reviews.filter(
      (review) => parseInt(review.user_id) === user?.id && setIsAddReview(true)
    );
  }, [user, event, reviews]);

  // for Contact & Social media
  const createLink = (value, icon, urlPrefix = "") => {
    return (
      value && (
        <li className="col-md-6">
          <Link
            to={urlPrefix + value}
            className="d-flex align-items-center gap-2"
            target="_blank"
          >
            {icon}
            <span>{value}</span>
          </Link>
        </li>
      )
    );
  };

  useEffect(() => {
    const filtered = audiences.filter((audience) =>
      event?.audiences?.includes(audience.id.toString())
    );
    setFilteredAudiences(filtered);
  }, [audiences, event?.audiences]);

  const addReviews = (e) => {
    e.preventDefault();

    isAddReview
      ? navigate(`/en/dashboard/attendee/my-reviews`)
      : !user?.token
      ? navigate(`/en/signin`, {
          state: `/en/dashboard/attendee/my-reviews/${event.id}/add`,
        })
      : navigate(`/en/dashboard/attendee/my-reviews/${event.id}/add`);
  };

  const redirect = (e, name, value) => {
    e.preventDefault();
    navigate("/en/events", { state: { name, value } });
  };

  return (
    <>
      <div className="BrowseEventDetailsCard">
        <div className="BrowseEventCardHeader">
          <h1 className="BrowseEventCardHeaderText">{name}</h1>
          <div className="ReviewBox">
            <Review rating={reviews.average_rating} />
          </div>
        </div>

        <div className="BrowseEventCardDetails">
          <span className="BrowseEventsCardDetailsTitle fw-bold text-muted ">
            Description
          </span>
          <span
            className="BrowseEventsCardDetailsDesc"
            dangerouslySetInnerHTML={{ __html: description }}
          ></span>

          <div className="BrowseEventsCardAgesWrapper">
            <span className="BrowseEventsCardAgesText">All Ages.</span>
            <span className="BrowseEventsCardAgesText">Free to attend.</span>
          </div>

          <div className="BrowseEventsCardAgesWrapper">
            <span className="BrowseEventsCardAgesText">
              Ongoing every 4th Friday.
            </span>
          </div>
        </div>
        {/* category,country,languages,Audience */}
        <div className="BrowseEventCardCategoryDetails">
          <div className="BrowseEventCardCategoryDetailsBox">
            <span className="BrowseEventCardCategoryText fw-bold text-muted">
              Category
            </span>
            <span className="BrowseEventCardCategoryTextDetailsText">
              {category?.name}
            </span>
          </div>

          {country && (
            <div className="BrowseEventCardCategoryDetailsBox">
              <span className="BrowseEventCardCategoryText fw-bold text-muted">
                Country
              </span>
              <div className="BrowseEventCardCategoryTextDetailsWrapper">
                {countries?.map((curElem) => {
                  if (curElem.id == country) {
                    return (
                      <React.Fragment key={curElem.id}>
                        <img
                          className="Flag"
                          style={{ width: "25px" }}
                          src={curElem?.flag}
                          alt={curElem?.name}
                        />{" "}
                        <span className="BrowseEventCardCategoryTextDetailsText fw-bold text-muted text-capitalize">
                          {curElem.name}
                        </span>
                      </React.Fragment>
                    );
                  }
                  return null; // or any other default value if the condition is not met
                })}
              </div>
            </div>
          )}

          {languages && (
            <div className="BrowseEventCardCategoryDetailsBox">
              <span className="BrowseEventCardCategoryText fw-bold text-muted">
                Languages
              </span>

              <div className="BrowseEventCardCategoryTextDetailsWrapper">
                <span className="BrowseEventCardCategoryTextDetailsText fw-bold text-muted text-capitalize">
                  {languages}
                </span>
              </div>
            </div>
          )}

          {filteredAudiences && filteredAudiences.length > 0 && (
            <div className="BrowseEventCardCategoryDetailsBox">
              <span className="BrowseEventCardCategoryText fw-bold text-muted">
                Audience
              </span>

              <div className="BrowseEventCardCategoryTextDetailsWrapper gap-3">
                {filteredAudiences?.map((audience, index) => {
                  return (
                    <Tooltip title={audience.name} key={index}>
                      <img
                        style={{ width: "27px" }}
                        src={`${server}/event/audience/${audience.icon}`}
                        alt={audience.slug}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        {/* photos */}
        {images && images.length > 0 && (
          <dl className="mt-4">
            <dt className="text-muted">Photos</dt>
            <dd className="mr-0">
              <div className="gallery photos-gallery ">
                {images.map((image, index) => (
                  <Tooltip title="Enlarge" key={index}>
                    <Link
                      to={`${server}/event/gallery/${image.image}`}
                      target="_blank"
                      className="me-1"
                    >
                      <img
                        className="img-thumbnail img-sm img-50-50"
                        src={`${server}/event/gallery/${image.image}`}
                        alt={`Event thumbnail for ${name}`}
                      />
                    </Link>
                  </Tooltip>
                ))}
              </div>
            </dd>
          </dl>
        )}

        {/* video */}
        <VideoComponent youtube_video_url={youtube_video_url} />

        {/* Contact & Social media */}
        <dl className="mt-4">
          <dt className="text-muted mb-1">Contact &amp; Social media</dt>
          <dd className="mr-0">
            <ul className="list-icon row no-gutters">
              {createLink(event.youtube_video_url, <FaYoutube />)}
              {createLink(event.contact_email, <FaAt />)}
              {createLink(event.phone_number, <FaPhone />, "tel:")}
              {createLink(event.facebook, <FaFacebookF />)}
              {createLink(event.twitter, <FaTwitter />)}
              {createLink(event.google_plus, <FaGooglePlus />)}
              {createLink(event.instagram, <FaInstagram />)}
              {createLink(event.linkedIn, <FaLinkedin />)}
            </ul>
          </dd>
        </dl>
        {/* share button */}
        <dl className="mt-4">
          <dt className="text-muted ">Share</dt>
          <dd className="mr-0">
            <SocialShare align="justify-content-start" dynamicPart={slug} />
          </dd>
        </dl>

        {/* for tags */}
        {(event.tags || event.tags?.length > 0) && (
          <div className="row" style={{ borderBottom: "1px solid #efefef" }}>
            <div className="col">
              {event?.tags?.map((tag, index) => {
                return (
                  <Link
                    to=""
                    onClick={(e) => redirect(e, "keyword", tag)}
                    className="btn btn-tags btn-sm me-3 mb-3 mt-3"
                    key={index}
                  >
                    {tag}
                  </Link>
                );
              })}
            </div>
          </div>
        )}

        {/* reviews */}
        {event?.review === 1 && (
          <div className="BrowseEventsCardReviewsSection">
            <div className="BrowseEventsSocialIconReviewsHeader">
              <div className="BrowseEventsCardReviewDetails">
                <div className="BrowseEventsCardReviewsBox">
                  <div className="ReviewCount">
                    <span className="BrowseEventsCardReviewDetailsReviewsCountText">
                      {reviews.reviews.length}
                    </span>
                    <span className="BrowseEventsCardReviewDetailsReviewsCountText">
                      Reviews
                    </span>
                  </div>

                  <div className="ReviewBox">
                    <Review rating={reviews.average_rating} />
                  </div>
                </div>

                <a
                  href=""
                  onClick={addReviews}
                  className="d-flex align-items-center btn btn-outline-dark rounded-5"
                >
                  <AiOutlineStar className="BrowseEventIcon" />
                  {isAddReview ? "My Reviews" : "ADD YOUR REVIEW"}
                </a>
              </div>
            </div>

            <div className="BrowseEventsCardReviewDetailsReviewsLoaderWrapper">
              <AverageRating reviews={reviews.reviews} />
            </div>
            {/* for rating */}
            {reviews.reviews.length > 0 && (
              <div id="reviews" className="row mt-4">
                <div className="col-12">
                  {reviews?.reviews?.map((review, index) => {
                    return (
                      <Fragment key={index}>
                        <User review={review} />
                        <UserReviews review={review} />
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
