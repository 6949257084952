import React, { useEffect } from "react";
import html2pdf from "html2pdf.js";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../utils/CustomFunctions";
import { countries } from "./../../../global/Values";

const PaymentDetailsPDF2 = ({ data }) => {
  useEffect(() => {
    const element = document.getElementById("contentToConvert");

    html2pdf()
      .from(element)
      .outputPdf()
      .then(function (pdf) {
        var base64 = btoa(pdf);
        const blob = base64ToBlob(base64, "application/pdf");
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
        function base64ToBlob(base64, type = "application/octet-stream") {
          const binStr = atob(base64);
          const len = binStr.length;
          const arr = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
          }
          return new Blob([arr], { type: type });
        }
      });
  }, [data]);

  return (
    <>
      <div className="d-none">
        <div
          className="container"
          id="contentToConvert"
          style={{ padding: "6rem 4rem 0px 4rem" }}
        >
          <div className="row border" style={{ paddingTop: "5rem" }}>
            <div className="col-6 p-0 border-end">
              <h5
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "25px 0 25px 20px",
                  fontSize: "17px",
                }}
              >
                Order - {data?.reference}
              </h5>

              <div className="p-3">
                <h4 className="title">{data?.event?.name}</h4>
                <b className="text-muted">Start date</b>
                <br />
                <b>
                  {data?.event?.start_On &&
                    formatDate(new Date(data?.event?.start_On)).fullDate}
                </b>
                <br />
                <br />
                <b className="text-muted">End date</b>
                <br />
                <b>
                  {data?.event?.end_On &&
                    formatDate(new Date(data?.event?.end_On)).fullDate}
                </b>
                <br />
                <br />
                <b className="text-muted">Venue / Location</b>
                <br />
                <b>
                  {data?.event?.event_date_online == 1 ? (
                    "Online"
                  ) : (
                    <>
                      {data?.event?.venue?.street_address}{" "}
                      {data?.event?.venue?.postal_code}{" "}
                      {data?.event?.venue?.city}, {data?.event?.venue?.state},{" "}
                      {countries?.map((curElem) => {
                        if (curElem.id == data?.event?.venue?.country) {
                          return (
                            <React.Fragment key={curElem.id}>
                              {curElem.name}
                            </React.Fragment>
                          );
                        }
                        return null; // or any other default value if the condition is not met
                      })}
                    </>
                  )}
                </b>
                <br />
                <br />
                <b className="text-muted">Organizer</b>
                <br />
                <b>{data?.event?.user?.organize_name}</b>
              </div>
            </div>
            <div className="col-6 p-0">
              <h5
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "25px 0 25px 20px",
                  fontSize: "17px",
                }}
              >
                Ticket - {data?.event?.reference}
              </h5>
              <div className="p-3">
                <h4 className="title">
                  {data?.billing?.first_name} {data?.billing?.last_name}
                </h4>

                {data?.event?.tickets && data?.event?.tickets?.length > 0 && (
                  <>
                    <b className="text-muted">Ticket type</b>
                    <br />
                    <b>{data?.event?.tickets?.[0]?.ticket_name}</b>
                  </>
                )}

                <br />
                <br />
                <b className="text-muted">Ticket price</b>
                <br />
                <b>
                  {data?.event?.tickets?.[0]?.ticket_free === 1
                    ? "Free"
                    : `${data?.total_price}$`}
                </b>
                <br />
                <br />
                <b className="text-muted">Total Ticket</b>
                <br />
                <b>{data?.ticket_quantity}</b>
                <br />
                <br />
                <b className="text-muted">Payment method</b>
                <br />
                <b>Credit/Debit Cards</b>
                <br />
                <br />
                <b className="text-muted">Order date</b>
                <br />
                <b>{data?.date && formatDate(new Date(data?.date)).fullDate}</b>
              </div>
            </div>

            <hr />
            <div className="col-12">
              <div style={{ textAlign: "center" }}>
                <img
                  alt="Barcode Generator TEC-IT"
                  src="/./images/qr.png"
                  width="15%"
                />
              </div>
            </div>
          </div>
          <div className="row mb-5" style={{ marginTop: "15rem" }}>
            <div className="col text-center">
              <div style={{ textAlign: "center" }}>
                <img
                  alt="Barcode Generator TEC-IT"
                  src="/./images/barcode.png"
                  width="30%"
                />
              </div>

              <Link to="https://feevaa.com/en/signin" target="_blank">
                <h5>https://feevaa.com</h5>
              </Link>
              <h5>feevaa - Event Management And Ticket Sales Platform</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDetailsPDF2;
