import React from "react";
import { Link } from "react-router-dom";
import { Radio, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrganizerFilters,
  clearAllFilters,
  loadFilterOrganizer,
  sorted,
  sorting,
} from "../../../../../store/slice/OrganizerFilters";
import { useEffect } from "react";
import SelectBox from "../../../../global/SelectBox";
import { FaPlus, FaSearchMinus, FaSearchengin } from "react-icons/fa";

const Filters = ({ events, event_categories, venues, btn = null }) => {
  const dispatch = useDispatch();
  const {
    filters: { event_id, category, venue, event_status },
    sorting_value,
    filter_data,
  } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    dispatch(loadFilterOrganizer({ data: events, key: "events" }));
  }, [dispatch, events]);

  useEffect(() => {
    dispatch(clearAllFilters());
  }, []);
  useEffect(() => {
    dispatch(sorted("events"));
  }, [sorting_value]);

  // Search Filter
  const add_filter2 = (name, value) => {
    dispatch(addOrganizerFilters({ name, value }));
  };
  const add_filter = (e) => {
    const { name, value } = e.target;
    dispatch(addOrganizerFilters({ name, value }));
  };
  const add_filter3 = (name, value) => {
    dispatch(sorting({ name, value }));
  };

  // Sorting select Box options
  const options = [
    { id: "date-desc", name: "Creation date (desc)" },
    { id: "date-asc", name: "Creation date (asc)" },
  ];
  return (
    <div className="row">
      <div className="col-sm-12 col-lg-2 text-center text-lg-start mb-3 mb-lg-0 align-self-center">
        <span className="center-lg-y text-muted">
          {filter_data.events.length} result(s) found
        </span>
      </div>
      <div
        className={`col-sm-12 col-lg-9 text-center text-lg-end d-flex align-items-center ${
          btn && btn === "yes"
            ? "justify-content-between"
            : "justify-content-end"
        } `}
      >
        {/* <SortSelect /> */}
        <form className="text-start d-flex align-items-center">
          <label htmlFor="sort" className="">
            Sort by{" "}
          </label>
          <SelectBox
            name="sorting_value"
            options={options}
            val={sorting_value}
            change={add_filter3}
          />
        </form>
        {btn && btn === "yes" && (
          <div>
            <Link
              to="add"
              className="btn btn-dark ms-3"
              style={{ fontSize: "12px" }}
            >
              <FaPlus /> Add a new event
            </Link>
            <Link
              to="/en/dashboard/organizer/my-venues/add"
              className="btn btn-dark ms-3"
              style={{ fontSize: "12px" }}
            >
              <FaPlus /> Add a new venue
            </Link>
          </div>
        )}
      </div>
      <div className="col-sm-12 col-lg-1 text-center text-lg-end align-self-center">
        <div className="col-sm-12 col-lg-1 text-center text-lg-right">
          <Tooltip title="Toggle display the search filters">
            <button
              type="button"
              className="btn btn-dark ms-2"
              data-bs-toggle="collapse"
              data-bs-target="#search-filters"
              aria-controls="search-filters"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <FaSearchengin />
            </button>
          </Tooltip>
        </div>
      </div>
      <div
        className="col-sm-12 col-lg-12 mb-3 mb-lg-0 collapse show"
        id="search-filters"
      >
        <hr />
        <form action="">
          <div className="row">
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="last_name" className="form-label fw-600">
                Events
              </label>
              <SelectBox
                name="event_id"
                options={events}
                val={event_id}
                change={add_filter2}
              />
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="last_name" className="form-label fw-600">
                Category
              </label>
              <SelectBox
                name="category"
                options={event_categories}
                val={category}
                change={add_filter2}
              />
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="last_name" className="form-label fw-600">
                Venue
              </label>
              <SelectBox
                name="venue"
                options={venues}
                val={venue}
                change={add_filter2}
              />
            </div>
            {/* <div className="col-12 col-sm-4 mb-4">
              <ul>
                <Radio.Group>
                  <li>
                    <Radio value={2}>All</Radio>
                  </li>
                  <li>
                    <Radio value={1}>Elapsed only</Radio>
                  </li>
                  <li>
                    <Radio value={3}>Not elapsed only</Radio>
                  </li>
                </Radio.Group>
              </ul>
            </div> */}
            <div className="col-12 col-sm-4 mb-4 ProfileSettingRadioBtns">
              <ul>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="event_status"
                    checked={event_status === "all"}
                    value="all"
                    onChange={add_filter}
                  >
                    All
                  </Radio>
                </li>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="event_status"
                    checked={event_status === "active"}
                    value="active"
                    onChange={add_filter}
                  >
                    Published only
                  </Radio>
                </li>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="event_status"
                    checked={event_status === "inactive"}
                    value="inactive"
                    onChange={add_filter}
                  >
                    Draft only
                  </Radio>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <Tooltip title="Reset">
                <button
                  type="button"
                  className="btn btn-dark ms-2"
                  onClick={() => dispatch(clearAllFilters())}
                >
                  <FaSearchMinus />
                </button>
              </Tooltip>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filters;
