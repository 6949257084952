import React, { useEffect, useState } from "react";
import { FaInfo, FaSyncAlt, FaTicketAlt } from "react-icons/fa";
import { AiOutlineMinusSquare, AiTwotoneSetting } from "react-icons/ai";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../../../../global/alert/Alert";
import { useDispatch, useSelector } from "react-redux";
import {
  cartTotal,
  clearCart,
  decreaseQty,
  increaseQty,
  removeCartItem,
} from "../../../../../store/slice/Cart";
import ManageCartQuantity from "../../../../count/ManageCartQuantity";
import { toast } from "react-toastify";
import axios from "axios";
import { Tooltip } from "antd";

const Cart = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authUser);
  const api = process.env.REACT_APP_SERVER_API;
  const { cart, total_price, total_item, fees, tax } = useSelector(
    (state) => state.CartDetails
  );
  const { countries } = useSelector((state) => state.AllData);
  const dispatch = useDispatch();
  const server = process.env.REACT_APP_SERVER;
  const [isPanding, setPanding] = useState(false);
  // for alert message
  const msg = {
    icon: <FaInfo className="me-3" />,
    type: "alert-warning",
    text: "Your tickets are not reserved until checkout, the quantity you intend to buy might not be available if you do not proceed to checkout right away",
  };
  const msg2 = {
    icon: <FaInfo className="me-3" />,
    type: "alert-info",
    text: "Your cart is empty.",
  };

  // for add to cart
  const add_check_out = async (e) => {
    e.preventDefault();
    try {
      setPanding(true);
      const { data } = await axios.post(`${api}/add_check_out`, cart);
      if (data.status === 201) {
        if (user && Object.keys(user).length > 0) {
          user.role.some((role) => role.name === "organizer") &&
            navigate("/en/organizer/checkout", {
              state: { checkout: data.data },
            });
          user.role.some((role) => role.name === "attendee") &&
            navigate("/en/attendee/checkout", {
              state: { checkout: data.data },
            });
        } else {
          navigate("/en/guest/checkout", {
            state: { checkout: data.data },
          });
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setPanding(false);
    }
  };

  useEffect(() => {
    dispatch(cartTotal());
  }, [dispatch, cart]);

  return (
    <>
      <Alert {...msg} />
      {cart?.length > 0 ? (
        <div className="card cart">
          <div className="card-header">
            <h6 className="mb-0">{total_item} tickets found in your cart</h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead className="text-muted">
                  <tr>
                    <th scope="col">Event / Ticket</th>
                    <th scope="col" width="100">
                      Price
                    </th>
                    <th scope="col" width="100">
                      Quantity
                    </th>
                    <th scope="col" width="100">
                      Subtotal
                    </th>
                    <th className="text-center">
                      <AiTwotoneSetting />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cart?.map((cartItem, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <figure className="media h-100">
                            <div className="img-wrap my-auto">
                              {cartItem?.image ? (
                                <img
                                  src={`${server}/event/image/${cartItem?.image}`}
                                  className="img-thumbnail img-50-50 img-sm img-lazy-load b-loaded"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src="/./images/noPhoto.png"
                                  className="img-thumbnail img-50-50 img-sm"
                                  alt=""
                                />
                              )}
                            </div>
                            <figcaption className="media-body my-auto">
                              <Link to={`/en/event/${cartItem?.slug}`}>
                                <h6 className="title mb-2">
                                  {cartItem?.event_name}
                                </h6>
                              </Link>
                              <dl className="dlist-inline small">
                                <dt></dt>
                                <dd className="mr-0">
                                  {cartItem?.ticket_name}
                                </dd>
                              </dl>
                              <dl className="dlist-inline small">
                                <dt className="me-1">When</dt>
                                <dd> {cartItem?.start_On}</dd>
                              </dl>
                              <dl className="dlist-inline small">
                                <dt>Where</dt>{" "}
                                <dd>
                                  {cartItem?.event_date_online === 1 ? (
                                    " Online"
                                  ) : (
                                    <>
                                      {cartItem?.venue?.city}:{" "}
                                      {cartItem?.venue?.postal_code}{" "}
                                      {cartItem?.venue?.street_address},{" "}
                                      {cartItem?.venue?.state},{" "}
                                      {countries?.map((curElem) => {
                                        if (
                                          curElem.id == cartItem?.venue?.country
                                        ) {
                                          return (
                                            <React.Fragment key={curElem.id}>
                                              {curElem.name}
                                            </React.Fragment>
                                          );
                                        }
                                        return null; // or any other default value if the condition is not met
                                      })}
                                    </>
                                  )}
                                </dd>
                              </dl>
                              <dl className="dlist-inline small">
                                <dt className="me-1">Organizer</dt>
                                <dd>
                                  <Link
                                    to={`/en/organizer/${cartItem?.organize_slug}`}
                                  >
                                    {cartItem?.organize_name}
                                  </Link>
                                </dd>
                              </dl>
                            </figcaption>
                          </figure>
                        </td>
                        <td>
                          <div className="">
                            <span className="">
                              {cartItem?.ticket_free === 1
                                ? "Free"
                                : `${cartItem?.ticket_price}$`}
                            </span>
                          </div>
                        </td>
                        <td>
                          {/* <Count /> */}
                          <ManageCartQuantity
                            quantity={cartItem?.ticket_quantity}
                            setIncreaseQty={() =>
                              dispatch(increaseQty(cartItem?.id))
                            }
                            setDecreaseQty={() =>
                              dispatch(decreaseQty(cartItem?.id))
                            }
                          />
                        </td>
                        <td className="text-center">
                          <div className="price-wrap">
                            <var className="price">
                              {cartItem?.ticket_free === 1
                                ? "0$"
                                : `${cartItem?.sub_price}$`}
                            </var>
                          </div>
                        </td>
                        <td className="text-center remove">
                          <Tooltip title="Remove">
                            <Link
                              to=""
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(removeCartItem(cartItem?.id));
                              }}
                            >
                              <AiOutlineMinusSquare />
                            </Link>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="5" className="text-end">
                      <div className="price-wrap">
                        <var className="price">Fees: {fees}%</var>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5" className="text-end">
                      <div className="price-wrap">
                        <var className="price">Tax: {tax}%</var>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5" className="text-end">
                      <div className="price-wrap">
                        <var className="price">Total:{total_price}$</var>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="5" className="text-end footbtn">
                      <Link
                        to="/en/events"
                        className="btn btn-outline-dark mr-3"
                      >
                        <FaTicketAlt /> Buy more tickets
                      </Link>

                      <button
                        className="btn btn-outline-dark rounded-5"
                        type="button"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          dispatch(clearCart());
                        }}
                      >
                        <AiOutlineMinusSquare /> Empty cart
                      </button>
                      <button
                        className="btn btn-dark rounded-5"
                        type="button"
                        disabled={isPanding ? "disabled" : ""}
                        onClick={add_check_out}
                      >
                        {isPanding ? (
                          <FaSyncAlt className="spinIcon" />
                        ) : (
                          "Checkout"
                        )}
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <Alert {...msg2} />
      )}
    </>
  );
};

export default Cart;
