import React, { useEffect, useState } from "react";
import { FaFileAlt, FaSyncAlt, FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { formatDate } from "../../../../../utils/CustomFunctions";
import { Tooltip } from "antd";
import Model from "./Model";

const OrderDetails = () => {
  const server = process.env.REACT_APP_SERVER;
  const { slug } = useParams();
  const { orders, loading } = useSelector((state) => state.admin);
  const [order, setOrder] = useState({});
  const [openModel, setOpenModel] = useState(false);

  useEffect(() => {
    slug && setOrder(orders.find((order) => order.reference === slug));
  }, [slug]);

  return (
    <>
      <Model openModel={openModel} setOpenModel={setOpenModel} order={order} />

      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        order &&
        Object.keys(order).length > 0 && (
          <div className="card cart">
            <div className="card-header">
              <h6 className="mb-0 d-inline-flex align-items-center gap-2">
                <span className="d-inline-flex align-items-center">
                  {order?.payment === "pending" ? (
                    <span className="badge badge-warning ">
                      Awaiting payment
                    </span>
                  ) : order?.payment === "completed" ? (
                    <span className="badge badge-success ">
                      Payment completed
                    </span>
                  ) : (
                    <span className="badge badge-danger ">Canceled</span>
                  )}
                </span>{" "}
                Order #{order?.reference} placed on{" "}
                {formatDate(new Date(order?.date)).fullDate}
              </h6>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h6 className="mb-0 d-inline-flex align-items-center gap-2">
                        Attendee / Point of sale
                      </h6>
                    </div>
                    <div className="card-body p-4">
                      <span className="avatar me-2">
                        <FaUser />
                      </span>
                      Georgia House
                      <Tooltip
                        title="User information"
                        className="btn wbtn btn-sm rounded-5"
                      >
                        <button
                          className="ms-3"
                          onClick={() => setOpenModel(true)}
                        >
                          <FaFileAlt />
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead className="text-muted">
                        <tr>
                          <th scope="col">Event / Ticket</th>
                          <th scope="col" width="100">
                            Price
                          </th>
                          <th scope="col" width="100">
                            Quantity
                          </th>
                          <th scope="col" width="100">
                            Subtotal
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <figure className="media h-100">
                              <div className="img-wrap my-auto">
                                <img
                                  src={`${server}/event/image/${order?.event?.main_event_image}`}
                                  className="img-thumbnail img-sm img-lazy-load b-loaded"
                                />
                              </div>
                              <figcaption className="media-body my-auto ">
                                <Link to={`/en/event/${order?.event?.slug}`}>
                                  <h6 className="title mb-2">
                                    {order?.event?.name}
                                  </h6>
                                </Link>
                                <dl className="dlist-inline small">
                                  <dt></dt>
                                  <dd className="mr-0"></dd>
                                  {order?.event?.tickets?.[0]?.ticket_name}
                                </dl>
                                <dl className="dlist-inline small">
                                  <dt className="me-1">When</dt>
                                  <dd>
                                    {
                                      formatDate(
                                        new Date(order?.event?.start_On)
                                      ).fullDate
                                    }
                                  </dd>
                                </dl>
                                <dl className="dlist-inline small">
                                  <dt>Where</dt>{" "}
                                  <dd className="text-capitalize">
                                    {order?.event?.event_date_online === 1 ? (
                                      " Online"
                                    ) : (
                                      <>
                                        {order?.event?.venue?.city}:{" "}
                                        {order?.event?.venue?.postal_code}{" "}
                                        {order?.event?.venue?.street_address},{" "}
                                        {order?.event?.venue?.state},{" "}
                                        {order?.event?.venue?.country}
                                      </>
                                    )}
                                  </dd>
                                </dl>
                                <dl className="dlist-inline small">
                                  <dt className="me-1">Organizer</dt>
                                  <dd>
                                    <Link
                                      to={`/en/organizer/${order?.event?.user?.slug}`}
                                      taget="_blank"
                                    >
                                      {order?.event?.user?.organize_name}
                                    </Link>
                                  </dd>
                                </dl>
                              </figcaption>
                            </figure>
                          </td>
                          <td>
                            <div className="">
                              <span className="">${order?.ticket_price}</span>
                            </div>
                          </td>
                          <td>{order.ticket_quantity}</td>
                          <td className="text-center">
                            <div className="price-wrap">
                              <var className="price">${order?.sub_price}</var>
                            </div>
                          </td>
                        </tr>
                        <tr></tr>
                      </tbody>
                      {/* <tfoot>
                        <tr>
                          <td colSpan="5" className="text-end footbtn pt-4">
                            <Link
                              to="/en/events"
                              className="btn btn-outline-dark mr-3"
                            >
                              <FaWindowClose />
                              Cancel
                            </Link>
                            <Link
                              to="/en/events"
                              className="btn btn-outline-dark mr-3"
                              onClick={(e) =>
                                handleDelete(
                                  e,
                                  order?.reference,
                                  "order_delete",
                                  ""
                                )
                              }
                            >
                              {order.deleted_at !== null ? (
                                <>
                                  <FaTrashRestoreAlt /> Restore
                                </>
                              ) : (
                                <>
                                  <IoCloseSharp />
                                  Delete
                                </>
                              )}
                            </Link>

                            {order.deleted_at && (
                              <Link
                                to=""
                                className="btn btn-outline-dark mr-3"
                                onClick={(e) =>
                                  handleDelete(
                                    e,
                                    order?.id,
                                    "order_delete_permanent",
                                    "delete_permanent"
                                  )
                                }
                              >
                                <MdDelete className="me-1" />
                                Delete permanently
                              </Link>
                            )}
                          </td>
                        </tr>
                      </tfoot> */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default OrderDetails;
