import React, { useState, useEffect } from "react";

const VideoComponent = ({ youtube_video_url }) => {
  const [error, setError] = useState(null);
  const [videoId, setVideoId] = useState(null);
  useEffect(() => {
    const extractVideoId = (link) => {
      try {
        if (link === null || typeof link !== "string") {
          throw new Error(
            "Invalid YouTube link. Please provide a valid string."
          );
        }

        const regex = /[?&]v=([^?&]+)/;
        const match = link.match(regex);

        if (match) {
          setVideoId(match[1]);
        } else {
          throw new Error("No valid YouTube video ID found");
        }
      } catch (error) {
        setError(error.message);
        return null;
      }
    };

    // Call extractVideoId only if youtube_video_url has changed
    if (youtube_video_url) {
      extractVideoId(youtube_video_url);
    }
  }, [youtube_video_url]); // Only re-run the effect if youtube_video_url changes
  return (
    <div>
      {error ? (
        <>
          <dl className="mt-4">
            <dt className="text-muted mb-1">Video</dt>
            <dd>
              <img
                src="https://helpdeskgeek.com/wp-content/pictures/2021/09/youtube-error.jpeg"
                className="w-100"
                alt=""
              />
            </dd>
          </dl>
        </>
      ) : videoId ? (
        <dl className="mt-4">
          <dt className="text-muted mb-1">Video</dt>
          <dd>
            <iframe
              className="w-100 border-0"
              height="400"
              src={`https://www.youtube.com/embed/${videoId}`}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            ></iframe>
          </dd>
        </dl>
      ) : (
        ""
      )}
    </div>
  );
};

export default VideoComponent;
