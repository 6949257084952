import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Table } from "./Table";

const ManagePages = () => {
  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event MyScanners">
        <div className="row">
          <div className="col-sm-12 col-lg-6 text-center text-lg-start mb-3 mb-lg-0 align-self-center">
            <span className="center-lg-y text-muted">1 result(s) found</span>
          </div>

          <div className="col-sm-12 col-lg-6 text-center text-lg-end ">
            <Link
              to="#search-filters"
              className="btn btn-dark has-tooltip"
              data-toggle="collapse"
              title=""
              aria-expanded="false"
              aria-controls="search-filters"
              data-original-title="Toggle display the search filters"
            >
              <FaPlus />
            </Link>
          </div>
        </div>
      </div>
      <Table />
    </>
  );
};

export default ManagePages;
