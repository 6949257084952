import React from "react";
import { Layout } from "../../components/LayoutUser/Layout";
import Breadcrumb from "../../components/global/Breadcrumb";
import TermsService from "../../components/TermsService";

const TermsOfService = () => {
  return (
    <Layout>
      <Breadcrumb page={"Terms of service"} />
      <TermsService />
    </Layout>
  );
};

export default TermsOfService;
