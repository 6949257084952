import React, { useEffect, useState } from "react";
import "./eventdetail.scss";
import { Layout } from "../../../components/LayoutUser/Layout";
import BlurryHeader from "../../../components/events/singleEvent/BlurryHeader/BlurryHeader";
import BrowseEventDetailCard from "../../../components/events/singleEvent/BrowseEventCard/BrowseEventDetailCard";
import BrowseEventSidebar from "../../../components/events/singleEvent/BrowseEventSidebar/BrowseEventSidebar";
import { useLocation, useParams } from "react-router-dom";
import { FaSyncAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import Breadcrumb from "../../../components/global/Breadcrumb2";
import { fetchAllData } from "../../../store/slice/AllData";
import { useDispatch } from "react-redux";

export default function EventDetails() {
  const { slug } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const api = process.env.REACT_APP_SERVER_API;
  const [isPanding, setPanding] = useState(false);
  const [singleData, setSingleData] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [totalTickets, setTotalTickets] = useState({});

  const fetchSngleData = async () => {
    try {
      setPanding(true);
      const { data } = await axios.get(`${api}/event/${slug}`);
      if (data.status === 200) {
        setSingleData(data.data);
        try {
          setPanding(true);
          const res = await axios.get(`${api}/event_reviews/${data.data.id}`);
          if (res.data.status === 200) {
            setReviews(res.data.data);
          }
        } catch (error) {
          // console.log("rws", error.response.data.message);
          toast.error(error.response.data.message);
        }
        // fetch all tickets qty
        try {
          setPanding(true);
          const resp = await axios.get(`${api}/total_tickets/${data.data.id}`);
          if (resp.data.status === 200) {
            setTotalTickets(resp.data.data);
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    } catch (error) {
      console.log("evnts", error.response.data.message);
      toast.error(error.response.data.message);
    } finally {
      setPanding(false);
    }
  };
  useEffect(() => {
    fetchSngleData();
  }, [slug]);
  useEffect(() => {
    dispatch(
      fetchAllData({
        getData: "blog_setting",
        state: "blog_setting",
      })
    );
  }, []);

  return (
    <Layout>
      <Breadcrumb page={singleData?.name} link="events" />
      {isPanding ? (
        <div
          className="d-flex align-items-center justify-content-center "
          style={{ height: "50vh" }}
        >
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <div className="EventsDetailSection mb-5">
          {singleData && (
            <BlurryHeader img={singleData.main_event_image} page="event" />
          )}
          <div className="EventDetailsContainer">
            {singleData && reviews && (
              <BrowseEventDetailCard event={singleData} reviews={reviews} />
            )}
            {singleData && (
              <BrowseEventSidebar
                event={singleData}
                totalTickets={totalTickets}
              />
            )}
          </div>
        </div>
      )}
    </Layout>
  );
}
