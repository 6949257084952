import { Modal } from "antd";
import React from "react";
import { FaRegWindowClose, FaUserAltSlash, FaUserCheck } from "react-icons/fa";
import { formatDate } from "../../../../../utils/CustomFunctions";

const Model = ({ openModel, setOpenModel, order }) => {
  return (
    <Modal
      // title="Vertically centered modal dialog"
      centered
      open={openModel}
      onCancel={() => setOpenModel(false)}
      className="Model"
    >
      <div className="ModelBox">
        <div className="ModelHeader">
          <span className="ModelBoxBuyTicketText">User information</span>
        </div>

        {order && Object.keys(order).length > 0 && (
          <div className="jconfirm-content" id="jconfirm-box71368">
            <div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-hover table-sm">
                      <thead>
                        <tr>
                          <th colSpan="2">
                            <i className="fas fa-file-alt fa-fw text-muted"></i>{" "}
                            Account information
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="30%">Role</td>
                          <td>
                            {order?.attendee?.roles?.some(
                              (role) => role.name === "organizer"
                            )
                              ? "Organizer"
                              : "Attendee"}
                          </td>
                        </tr>
                        {/* <tr>
                          <td width="30%">Status</td>
                          <td>
                            {order?.billing_reference?.status === "active" ? (
                              <span className="badge badge-success d-inline-flex align-items-center">
                                <FaUserCheck className="me-1" />
                                Enabled
                              </span>
                            ) : (
                              <span className="badge badge-success d-inline-flex align-items-center">
                                <FaUserAltSlash className="me-1" />
                                Disabled
                              </span>
                            )}
                          </td>
                        </tr> */}
                        <tr>
                          <td width="30%">First name</td>
                          <td>{order?.billing_reference?.first_name}</td>
                        </tr>
                        <tr>
                          <td width="30%">Last name</td>
                          <td>{order?.billing_reference?.last_name}</td>
                        </tr>
                        <tr>
                          <td width="30%">Email</td>
                          <td>{order?.billing_reference?.email}</td>
                        </tr>
                        {/* <tr>
                          <td width="30%">Username</td>
                          <td>{order?.billing_reference?.user_name}</td>
                        </tr> */}
                        <tr>
                          <td width="30%">Registration date</td>
                          <td>
                            {
                              formatDate(
                                new Date(order?.billing_reference?.created_at)
                              ).fullDate
                            }
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">Update date</td>
                          <td>
                            {
                              formatDate(
                                new Date(order?.billing_reference?.updated_at)
                              ).fullDate
                            }
                          </td>
                        </tr>
                        {/* <tr>
                        <td width="30%">Last login</td>
                        <td>Thu 30 Nov 2023, 1:41 PM EST</td>
                      </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-hover table-sm">
                      <thead>
                        <tr>
                          <th colSpan="2">
                            <i className="far fa-user fa-fw text-muted"></i>{" "}
                            Attendee information
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="30%">Cart elements</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td width="30%">Bought tickets</td>
                          <td>{order?.ticket_quantity}</td>
                        </tr>
                        <tr>
                          <td width="30%">Reviews</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td width="30%">Favorites</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td width="30%">Following</td>
                          <td>0</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
        <button
          className="btn btn-outline-dark rounded-5 d-inline-flex align-items-center gap-2"
          onClick={() => setOpenModel(false)}
        >
          <FaRegWindowClose className="ModelBoxTicketPurchaseTitleIcon" />
          close
        </button>
      </div>
    </Modal>
  );
};

export default Model;
