import React, { useEffect, useState } from "react";
import Breadcrumb from "../global/Breadcrumb2";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  FaBookReader,
  FaLinkedin,
  FaRegClock,
  FaRegUser,
  FaSitemap,
  FaSyncAlt,
} from "react-icons/fa";
import { BiLogoFacebook, BiLogoTwitter } from "react-icons/bi";
import { Layout } from "../LayoutUser/Layout";
import { useDispatch, useSelector } from "react-redux";
import ReactTimeAgo from "react-time-ago";
import SocialShare from "../global/SocialShare";
import { toast } from "react-toastify";
import axios from "axios";
import { fetchAllData } from "./../../store/slice/AllData";
import SelectBox from "../global/SelectBox";
import {
  loadFilterBlogComments,
  sortedb,
  sorting,
} from "../../store/slice/BlogFilters";

const BlogDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [errors, setErrors] = useState({});
  const [isPending, setPending] = useState(false);
  const { user } = useSelector((state) => state.authUser);
  const api = process.env.REACT_APP_SERVER_API;
  const server = process.env.REACT_APP_SERVER;
  const { blogs, blog_setting } = useSelector((state) => state.AllData);
  const [blog, setBlog] = useState({});
  const { sorting_value, filter_blog_comments } = useSelector(
    (state) => state.blogFilters
  );
  const [comment, setComment] = useState({
    comment: "",
    blog_id: "",
  });
  const created_at_timestamp = new Date(blog?.created_at).getTime();

  useEffect(() => {
    if (slug) {
      const blog = blogs.find((blog) => blog.slug === slug);
      setBlog(blog);
      setComment({ ...comment, blog_id: blog?.id });
    }
  }, [slug, blogs]);

  const redirect = (e, name, value) => {
    e.preventDefault();
    navigate("/en/blogs", { state: { name, value } });
  };

  // validate fields
  const validateFields = () => {
    let newErrors = {};
    if (!comment?.comment.trim())
      newErrors.comment = "This value should not be null.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    if (!user?.token) {
      navigate(`/en/signin`, {
        state: `/en/blog-article/${slug}`,
      });
    }
    e.preventDefault();
    if (!validateFields()) return;
    try {
      setPending(true);

      const { data } = await axios.post(
        `${api}/attendee/add_comment`,
        comment,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (data.status === 200 || data.status === 201) {
        setComment({ ...comment, comment: "" });
        toast.success(data.message);
        dispatch(
          fetchAllData({
            getData: "blogs",
            state: "blogs",
          })
        );
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
    } finally {
      setPending(false);
    }
  };

  // Search Filter
  const add_filter3 = (name, value) => {
    dispatch(sorting({ name, value }));
  };

  useEffect(() => {
    dispatch(loadFilterBlogComments(blog?.comments));
  }, [dispatch, blog?.comments]);

  useEffect(() => {
    dispatch(sortedb());
  }, [sorting_value]);

  // Sorting select Box options
  const options = [
    { id: "date-desc", name: "Newest" },
    { id: "date-asc", name: "Oldest" },
  ];

  return (
    <>
      <Layout>
        <Breadcrumb page={blog?.name} link="blogs" />
        {Object.keys(blog).length > 0 && (
          <div className="container blog-detail mb-4">
            <div className="row mt-4">
              <div className="col-lg-10 offset-lg-1">
                <div className="card box">
                  {blog?.image ? (
                    <img
                      className="card-img-top img-lazy-load b-loaded img-fluid"
                      src={`${server}/blog/${blog?.image}`}
                      style={{ height: "600px" }}
                      alt={blog?.image}
                    ></img>
                  ) : (
                    <img
                      className="card-img-top img-lazy-load b-loaded img-fluid"
                      src="/./images/no.png"
                      style={{ height: "500px" }}
                      alt="tes"
                    ></img>
                  )}
                  <div className="card-body">
                    <header className="section-heading mb-1">
                      <h1 className="title-section">{blog?.name}</h1>
                    </header>
                    <p className="mb-4">
                      <small className="text-muted me-3">
                        <Link
                          to=""
                          onClick={(e) =>
                            redirect(e, "category", blog?.blog_category_id)
                          }
                          className="text-dark text-capitalize"
                        >
                          <FaSitemap className="me-1" /> {blog?.category?.name}
                        </Link>
                      </small>
                      <small className="text-muted me-3">
                        <FaRegClock className="me-1" />{" "}
                        <ReactTimeAgo
                          date={created_at_timestamp}
                          locale="en-US"
                        />
                      </small>
                      <small className="text-muted">
                        <FaBookReader className="me-1" />
                        {blog?.time} min read
                      </small>
                    </p>
                    <article>
                      <p>
                        <span
                          className="BrowseEventsCardDetailsDesc"
                          dangerouslySetInnerHTML={{
                            __html: blog?.description,
                          }}
                        ></span>
                      </p>
                    </article>
                    <hr />
                    <div className="row share">
                      <div className="col-12 col-lg-6 text-center text-lg-start">
                        {blog?.tags?.length > 0 &&
                          blog?.tags?.map((tag, i) => (
                            <Link
                              key={i}
                              to=""
                              onClick={(e) => redirect(e, "tags", tag)}
                              className="btn btn-tags btn-sm mr-3 mb-3 mt-3"
                            >
                              {tag}
                            </Link>
                          ))}
                      </div>
                      {/* <div className="col-12 col-lg-6 text-center text-lg-end sharer mt-3 jssocials"> */}
                      <SocialShare align="justify-content-end" />
                      {/* <Link
                          to="/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=https%3A%2F%2Ffeevaa.com%2Fen%2Fblog-article%2Fvalentine-rhodes&display=popup&ref=plugin&src=share_button"
                          target="_blank"
                        >
                          <span className="badge bg-primary">
                            <BiLogoFacebook /> Share 0
                          </span>
                        </Link>
                        <Link
                          to="/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=https%3A%2F%2Ffeevaa.com%2Fen%2Fblog-article%2Fvalentine-rhodes&display=popup&ref=plugin&src=share_button"
                          target="_blank"
                        >
                          <span className="badge bg-secondary">
                            <BiLogoTwitter /> Tweet
                          </span>
                        </Link>
                        <Link
                          to="/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=https%3A%2F%2Ffeevaa.com%2Fen%2Fblog-article%2Fvalentine-rhodes&display=popup&ref=plugin&src=share_button"
                          target="_blank"
                        >
                          <span className="badge bg-info text-dark">
                            <FaLinkedin /> Share
                          </span>
                        </Link> */}
                      {/* </div> */}
                    </div>

                    {/*  */}
                    {blog_setting && blog_setting.enable_comments !== "no" && (
                      <div className="mt-5">
                        <div className="comment d-flex align-item-center justify-content-between pe-3 ps-3 pb-3">
                          <div>
                            <span>{blog?.comments?.length || 0} comments</span>
                          </div>

                          <div>
                            <div className="d-flex align-items-center gap-2">
                              <label htmlFor="" className="pe-2">
                                Sort by
                              </label>
                              <SelectBox
                                name="sorting_value"
                                options={options}
                                val={sorting_value}
                                change={add_filter3}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="comment">
                          {filter_blog_comments?.map((comment, i) => (
                            <React.Fragment key={i}>
                              <div className="mt-3 mb-2">
                                <span className="avatar">
                                  <FaRegUser />
                                </span>
                                <h6 className="ms-2 d-inline-block mb-0">
                                  {comment?.user?.first_name}{" "}
                                  {comment?.user?.last_name}
                                </h6>
                              </div>
                              <p className="mt-2">{comment.comment}</p>
                            </React.Fragment>
                          ))}
                        </div>

                        <div className="comment-form mt-3 mb-5 clearfix">
                          <form onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-2 col-md-1">
                                <div className="avatar">
                                  <img src="/./images/avatar.jpg" alt="" />
                                </div>
                              </div>
                              <div className="col-10 col-md-11">
                                <div className="mb-3">
                                  <textarea
                                    className="form-control"
                                    rows="3"
                                    placeholder="Add a comment"
                                    onChange={(e) =>
                                      setComment({
                                        ...comment,
                                        comment: e.target.value,
                                      })
                                    }
                                    value={comment?.comment}
                                  ></textarea>
                                  {errors.comment && (
                                    <small className="text-danger mt-1">
                                      {errors.comment}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>

                            <button
                              className="btn btn-dark rounded-5 btn-sm"
                              disabled={isPending ? "disabled" : ""}
                              type="submit"
                            >
                              {isPending ? (
                                <FaSyncAlt className="spinIcon" />
                              ) : (
                                "post"
                              )}
                            </button>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default BlogDetail;
