import React from "react";
import { Link } from "react-router-dom";
import Review from "../../../../reviews/Review";
import {
  formatDate,
  useDelete,
  useStatusChange,
} from "../../../../../utils/CustomFunctions";
import {
  FaEllipsisV,
  FaEye,
  FaEyeSlash,
  FaTrashRestoreAlt,
} from "react-icons/fa";
import { Dropdown, Space } from "antd";
import { MdDelete } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";

const ReviewCard = ({ review }) => {
  const server = process.env.REACT_APP_SERVER;
  // for state change
  const { loading, handleStatusChange } = useStatusChange(
    "admin",
    "review_change_status",
    "reviews",
    "reviews"
  );
  // for soft delete and parmanently delete
  const { isLoading, handleDelete } = useDelete("admin", "reviews", "reviews");

  return (
    <div className="row mb-4">
      <div className="col">
        <div className="user-review-wrapper mb-5">
          <Link to={`/en/event/${review?.slug}`}>
            <img
              src={`${server}/event/image/${review?.main_event_image}`}
              className="img-thumbnail img-100-100 "
            />
            {review?.name}
          </Link>
          <div className="rating-wrap mt-3">
            <Review rating={review.average_rating} />
          </div>

          {review?.reviews?.map((review, index) => {
            const items = [
              {
                label: (
                  <Link
                    to=""
                    className="d-flex align-items-center"
                    onClick={(e) => handleStatusChange(e, review.id)}
                  >
                    {review.status === "active" ? (
                      <>
                        <FaEyeSlash className="me-1" />
                        Hide
                      </>
                    ) : (
                      <>
                        <FaEye className="me-1" /> Show
                      </>
                    )}
                  </Link>
                ),
                key: "0",
              },
              {
                label: (
                  <Link
                    to=""
                    className="d-flex align-items-center"
                    onClick={(e) =>
                      handleDelete(e, review.id, "review_delete", "")
                    }
                  >
                    {review.deleted_at !== null ? (
                      <>
                        <FaTrashRestoreAlt className="me-1" /> Restore
                      </>
                    ) : (
                      <>
                        <IoCloseSharp className="me-1" />
                        Delete
                      </>
                    )}
                  </Link>
                ),
                key: "1",
              },
              review.deleted_at && {
                label: (
                  <Link
                    to=""
                    className="d-flex align-items-center"
                    onClick={(e) =>
                      handleDelete(e, review.id, "review_delete_permanent", "")
                    }
                  >
                    <MdDelete className="me-1" />
                    Delete permanently
                  </Link>
                ),
                key: "2",
              },
            ];
            return (
              <React.Fragment key={index}>
                <h6 className="mt-4 font-weight-bold">{review?.headline}</h6>
                <p
                  className=" text-sm readmore rmjs-1 m-0"
                  data-collapsed-height="200"
                  data-height-margin="20"
                >
                  {review?.details}
                </p>
                <small className="text-muted d-inline-block">
                  {formatDate(new Date(review.date)).fullDate}
                </small>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div>
                    {review.status === "active" ? (
                      <span className="badge badge-success d-inline-flex align-items-center">
                        <FaEye className="me-1" /> Visible
                      </span>
                    ) : (
                      <span className="badge badge-danger d-inline-flex align-items-center">
                        <FaEyeSlash className="me-1" /> Hidden
                      </span>
                    )}{" "}
                    {review.deleted_at && (
                      <>
                        <span className="badge badge-danger d-inline-flex align-items-center">
                          <IoCloseSharp className="me-1" />
                          Deleted
                        </span>
                      </>
                    )}
                  </div>

                  <div className="item-action">
                    <Dropdown
                      menu={{
                        items,
                      }}
                      trigger={["click"]}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <FaEllipsisV /> Actions
                        </Space>
                      </a>
                    </Dropdown>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
