import styled from "styled-components";

export const Wrapper = styled.section`
  .accordion {
    box-shadow: 0 4px 15px hsla(0, 0%, 60%, 0.3);
    border: 0;
  }
  .accordion-item {
    background-color: var(--bs-white);
  }
  .accordion-item .accordion-header .accordion-button {
    background-color: #f9f9f9;
    font-size: 0.805rem;
  }
  .accordion-button:focus {
    z-index: 3;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
  .accordion-button::after {
    width: 0.8rem;
    height: 0.8rem;
    background-size: auto;
  }

  /* .accordion-body input:focus-visible {
  outline-color: red;
  outline-style: none;
  outline-width: 1px;
} */
  .location-tab li {
    margin-bottom: 1rem;
  }
  .date-tab li {
    margin-bottom: 0.5rem;
  }
  li > input[type="checkbox"],
  li > input[type="radio"] {
    transform: scale(1.3);
    margin-right: 10px;
    background-color: #dee2e6;
    border: 1px solid #adb5bd;
  }
  /* li > label {
    font-weight: 600;
  } */
  .search-btn {
    padding: 24px;
  }
  .search-btn button {
    border-radius: 50px;
    padding: 10px 0;
    font-weight: 600;
  }
  .subscribe-tab {
    padding: 10px 15px;
    border-radius: 3px;
  }
  .subscribe-tab .figure {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
  }
  .subscribe-tab .figure .fig {
    background-color: var(--bs-white);
    border-radius: 50%;
    padding: 10px 15px;
  }
  .subscribe-tab .figure .fig .icon {
    font-size: 20px;
  }
  .form-row .form-control {
    font-size: 0.805rem;
    padding: 0.625rem 0.75rem;
  }
  .form-row .form-control:focus {
    outline: 0;
    border: none;
    box-shadow: none;
  }

  .btn-group {
    width: 100%;
    box-shadow: 0 2px 8px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 50px;
  }
  .btn-group .btn {
    border: 0;
    padding: 12px 0;
    color: white;
    transform: translateY(0px);
    transition: all 0.15s ease;
  }

  .btn-group .btn:hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }
  .btn-group .btn:first-child {
    border-radius: 50px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .btn-group .btn:last-child {
    border-radius: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .btn-group .oneChild {
    border-radius: 50px !important;
  }
  .btn-group .btn.active {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  .ant-picker {
    border: 0;
    padding: 0;
  }
  .ant-picker-input input {
    display: none;
  }
  .ant-picker-suffix,
  .ant-picker-clear {
    display: none;
  }
  .btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`;
