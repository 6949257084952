import React from "react";
import { Layout } from "../../components/LayoutUser/Layout";
import Breadcrumb from "../../components/global/Breadcrumb";

const CookiePolicy = () => {
  return (
    <Layout>
      <Breadcrumb page={"Cookie policy"} />
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-sm-12">
            <div className="card box">
              <div className="card-body">
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                  }}
                >
                  <strong style={{ background: "transparent" }}>
                    <span
                      style={{ background: "transparent", fontSize: "26px" }}
                    >
                      <span
                        data-custom-className="title"
                        style={{
                          background: "transparent",
                          fontFamily: "Arial",
                        }}
                      >
                        COOKIE POLICY
                      </span>
                    </span>
                  </strong>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <strong style={{ background: "transparent" }}>
                      <span
                        style={{ background: "transparent", fontSize: "15px" }}
                      >
                        <span
                          data-custom-className="subtitle"
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontFamily: "Arial",
                            fontSize: "14px",
                            background: "transparent",
                          }}
                        >
                          Last updated&nbsp;
                          <bdt
                            className="question"
                            style={{ background: "transparent" }}
                          >
                            February 18, 2023
                          </bdt>
                        </span>
                      </span>
                    </strong>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        This Cookie Policy explains how The Ojunta Group LLC
                        Also doing business as&nbsp;
                        <bdt
                          className="question"
                          style={{ background: "transparent" }}
                        >
                          feevaa&nbsp;
                        </bdt>
                        ("
                        <strong style={{ background: "transparent" }}>
                          feevaa
                        </strong>
                        ," "
                        <strong style={{ background: "transparent" }}>
                          we
                        </strong>
                        ," "
                        <strong style={{ background: "transparent" }}>
                          us
                        </strong>
                        ," and "
                        <strong style={{ background: "transparent" }}>
                          our
                        </strong>
                        ") uses cookies and similar technologies to recognize
                        you when you visit our website at&nbsp;
                        <bdt
                          className="question"
                          style={{ background: "transparent" }}
                        >
                          <a
                            href="https://www.feevaa.com/"
                            target="_blank"
                            data-custom-className="link"
                            style={{
                              backgroundImage: "initial",
                              backgroundPosition: "initial",
                              backgroundSize: "initial",
                              backgroundRepeat: "initial",
                              backgroundAttachment: "initial",
                              backgroundOrigin: "initial",
                              backgroundClip: "initial",
                              color: "rgb(48, 48, 241)",
                              wordBreak: "break-word",
                            }}
                          >
                            https://www.feevaa.com
                          </a>
                        </bdt>
                        &nbsp;("
                        <strong style={{ background: "transparent" }}>
                          Website
                        </strong>
                        "). It explains what these technologies are and why we
                        use them, as well as your rights to control our use of
                        them.
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        In some cases we may use cookies to collect personal
                        information, or that becomes personal information if we
                        combine it with other information.
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(0,0,0)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <strong style={{ background: "transparent" }}>
                        <span
                          data-custom-className="heading_1"
                          style={{
                            fontFamily: "Arial",
                            fontSize: "19px",
                            background: "transparent",
                          }}
                        >
                          What are cookies?
                        </span>
                      </strong>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        Cookies are small data files that are placed on your
                        computer or mobile device when you visit a website.
                        Cookies are widely used by website owners in order to
                        make their websites work, or to work more efficiently,
                        as well as to provide reporting information.
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        Cookies set by the website owner (in this case,&nbsp;
                        <bdt
                          className="question"
                          style={{ background: "transparent" }}
                        >
                          feevaa.com&nbsp;
                        </bdt>
                        ) are called "first-party cookies." Cookies set by
                        parties other than the website owner are called
                        "third-party cookies." Third-party cookies enable
                        third-party features or functionality to be provided on
                        or through the website (e.g., advertising, interactive
                        content, and analytics). The parties that set these
                        third-party cookies can recognize your computer both
                        when it visits the website in question and also when it
                        visits certain other websites.
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(0,0,0)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <strong style={{ background: "transparent" }}>
                        <span
                          data-custom-className="heading_1"
                          style={{
                            fontFamily: "Arial",
                            fontSize: "19px",
                            background: "transparent",
                          }}
                        >
                          Why do we use cookies?
                        </span>
                      </strong>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        We use first-
                        <bdt
                          className="block-component"
                          style={{ background: "transparent" }}
                        ></bdt>
                        &nbsp;and third-
                        <bdt
                          className="statement-end-if-in-editor"
                          style={{ background: "transparent" }}
                        ></bdt>
                        party cookies for several reasons. Some cookies are
                        required for technical reasons in order for our Website
                        to operate, and we refer to these as "essential" or
                        "strictly necessary" cookies. Other cookies also enable
                        us to track and target the interests of our users to
                        enhance the experience on our Online Properties.&nbsp;
                        <bdt
                          className="block-component"
                          style={{ background: "transparent" }}
                        ></bdt>
                        Third parties serve cookies through our Website for
                        advertising, analytics, and other purposes.&nbsp;
                        <bdt
                          className="statement-end-if-in-editor"
                          style={{ background: "transparent" }}
                        ></bdt>
                        This is described in more detail below.
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <span style={{ background: "transparent" }}>
                        <span style={{ background: "transparent" }}>
                          <span
                            id="control"
                            style={{
                              color: "rgb(0,0,0)",
                              background: "transparent",
                            }}
                          >
                            <strong style={{ background: "transparent" }}>
                              <span
                                data-custom-className="heading_1"
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "19px",
                                  background: "transparent",
                                }}
                              >
                                How can I control cookies?
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        You have the right to decide whether to accept or reject
                        cookies. You can exercise your cookie rights by setting
                        your preferences in the Cookie Consent Manager. The
                        Cookie Consent Manager allows you to select which
                        categories of cookies you accept or reject. Essential
                        cookies cannot be rejected as they are strictly
                        necessary to provide you with services.
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        The Cookie Consent Manager can be found in the
                        notification banner and on our website. If you choose to
                        reject cookies, you may still use our website though
                        your access to some functionality and areas of our
                        website may be restricted. You may also set or amend
                        your web browser controls to accept or refuse
                        cookies.&nbsp;
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        The specific types of first- and third-party cookies
                        served through our Website and the purposes they perform
                        are described in the table below (please note that the
                        specific&nbsp;
                      </span>
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        cookies served may vary depending on the specific Online
                        Properties you visit):
                      </span>
                    </span>
                  </span>
                  <span
                    style={{ background: "transparent", fontSize: "15px" }}
                  ></span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    data-custom-className="heading_2"
                    style={{
                      fontFamily: " Arial",
                      fontSize: "17px",
                      background: "transparent",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <strong
                        style={{
                          fontSize: "17px",
                          background: "transparent",
                        }}
                      >
                        <u style={{ background: "transparent" }}>
                          <br style={{ background: "transparent" }} />
                          Performance and functionality cookies:
                        </u>
                      </strong>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                  }}
                >
                  <p
                    style={{
                      lineHeight: "1.5",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        background: "transparent",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        These cookies are used to enhance the performance and
                        functionality of our Website but are non-essential to
                        their use. However, without these cookies, certain
                        functionality (like videos) may become unavailable.
                      </span>
                    </span>
                  </p>
                  <div style={{ background: "transparent" }}>
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        <section
                          data-custom-className="body_text"
                          style={{
                            width: "778px",
                            border: "1px solid rgb(230, 230, 230)",
                            margin: "0px 0px 10px",
                            borderRadius: "3px",
                            color: "rgb(0,0,0)",
                            background: "transparent",
                          }}
                        >
                          <div
                            style={{
                              borderBottom: "1px solid rgb(230, 230, 230)",
                              padding: "8px 13px",
                              background: "transparent",
                            }}
                          >
                            <table style={{ background: "transparent" }}>
                              <tbody style={{ background: "transparent" }}>
                                <tr
                                  style={{
                                    borderBottom:
                                      "1px solid rgb(230, 230, 230)",
                                    margin: "0px 0px 8px",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    fontSize: "12px",
                                    fontFamily: "Roboto, Arial",
                                  }}
                                >
                                  <td
                                    style={{
                                      color: "rgb(25, 36, 60)",
                                      background: "transparent",
                                      verticalAlign: "top",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                      textAlign: "right",
                                      minWidth: "80px",
                                    }}
                                  >
                                    Name:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      background: "transparent",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(139, 147, 167)",
                                        background: "transparent",
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      PHPSESSID
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    background: "transparent",
                                    fontSize: "12px",
                                    fontFamily: "Roboto, Arial",
                                    lineHeight: "1.67",
                                    margin: "0px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <td
                                    style={{
                                      color: "rgb(25, 36, 60)",
                                      background: "transparent",
                                      verticalAlign: "top",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                      textAlign: "right",
                                      minWidth: "80px",
                                    }}
                                  >
                                    Purpose:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      background: "transparent",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(139, 147, 167)",
                                        background: "transparent",
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      Cookie generated by applications based on
                                      the PHP language. This is a general
                                      purpose identifier used to maintain user
                                      session variables. It is normally a random
                                      generated number, how it is used can be
                                      specific to the site, but a good example
                                      is maintaining a logged-in status for a
                                      user between pages.
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    borderBottom:
                                      "1px solid rgb(230, 230, 230)",
                                    margin: "0px 0px 8px",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    fontSize: "12px",
                                    fontFamily: "Roboto, Arial",
                                  }}
                                >
                                  <td
                                    style={{
                                      color: "rgb(25, 36, 60)",
                                      background: "transparent",
                                      verticalAlign: "top",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                      textAlign: "right",
                                      minWidth: "80px",
                                    }}
                                  >
                                    Provider:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      background: "transparent",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(139, 147, 167)",
                                        background: "transparent",
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      www.feevaa.com
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    borderBottom:
                                      "1px solid rgb(230, 230, 230)",
                                    margin: "0px 0px 8px",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    fontSize: "12px",
                                    fontFamily: "Roboto, Arial",
                                  }}
                                >
                                  <td
                                    style={{
                                      color: "rgb(25, 36, 60)",
                                      background: "transparent",
                                      verticalAlign: "top",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                      textAlign: "right",
                                      minWidth: "80px",
                                    }}
                                  >
                                    Service:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      background: "transparent",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(139, 147, 167)",
                                        background: "transparent",
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      PHP.net&nbsp;
                                      <a
                                        href="https://www.php.net/privacy.php"
                                        target="_blank"
                                        style={{
                                          backgroundImage: "initial",
                                          backgroundPosition: "initial",
                                          backgroundSize: "initial",
                                          backgroundRepeat: "initial",
                                          backgroundAttachment: "initial",
                                          backgroundOrigin: "initial",
                                          backgroundClip: "initial",
                                          color: "rgb(26, 152, 235)",
                                        }}
                                      >
                                        View Service Privacy Policy
                                      </a>
                                      &nbsp;&nbsp;
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    borderBottom:
                                      "1px solid rgb(230, 230, 230)",
                                    margin: "0px 0px 8px",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    fontSize: "12px",
                                    fontFamily: "Roboto, Arial",
                                  }}
                                >
                                  <td
                                    style={{
                                      color: "rgb(25, 36, 60)",
                                      background: "transparent",
                                      verticalAlign: "top",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                      textAlign: "right",
                                      minWidth: "80px",
                                    }}
                                  >
                                    Country:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      background: "transparent",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(139, 147, 167)",
                                        background: "transparent",
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      United States
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    borderBottom:
                                      "1px solid rgb(230, 230, 230)",
                                    margin: "0px 0px 8px",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    fontSize: "12px",
                                    fontFamily: "Roboto, Arial",
                                  }}
                                >
                                  <td
                                    style={{
                                      color: "rgb(25, 36, 60)",
                                      background: "transparent",
                                      verticalAlign: "top",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                      textAlign: "right",
                                      minWidth: "80px",
                                    }}
                                  >
                                    Type:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      background: "transparent",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(139, 147, 167)",
                                        background: "transparent",
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      http_cookie
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    borderBottom:
                                      "1px solid rgb(230, 230, 230)",
                                    margin: "0px 0px 8px",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    fontSize: "12px",
                                    fontFamily: "Roboto, Arial",
                                  }}
                                >
                                  <td
                                    style={{
                                      color: "rgb(25, 36, 60)",
                                      background: "transparent",
                                      verticalAlign: "top",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                      textAlign: "right",
                                      minWidth: "80px",
                                    }}
                                  >
                                    Expires in:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      background: "transparent",
                                      fontSize: "14px",
                                      fontFamily: " Arial",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(139, 147, 167)",
                                        background: "transparent",
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      session
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </section>
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                  }}
                >
                  <div
                    style={{
                      background: "transparent",
                      lineHeight: "1.5",
                    }}
                  >
                    <span
                      data-custom-className="heading_2"
                      style={{
                        fontFamily: " Arial",
                        fontSize: "17px",
                        background: "transparent",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                          background: "transparent",
                        }}
                      >
                        <strong
                          style={{
                            fontSize: "17px",
                            background: "transparent",
                          }}
                        >
                          <u style={{ background: "transparent" }}>
                            <br style={{ background: "transparent" }} />
                            Analytics and customization cookies:
                          </u>
                        </strong>
                      </span>
                    </span>
                  </div>
                  <p
                    style={{
                      lineHeight: "1.5",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent",
                        color: "rgb(127, 127, 127)",
                      }}
                    >
                      <span
                        style={{
                          color: "rgb(89, 89, 89)",
                          background: "transparent",
                        }}
                      >
                        <span
                          data-custom-className="body_text"
                          style={{
                            fontFamily: "Arial",
                            fontSize: "14px",
                            background: "transparent",
                          }}
                        >
                          These cookies collect information that is used either
                          in aggregate form to help us understand how our
                          Website is being used or how effective our marketing
                          campaigns are, or to help us customize our Website for
                          you.
                        </span>
                      </span>
                    </span>
                  </p>
                  <div style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent",
                        fontSize: "14px",
                        fontFamily: " Arial",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <section
                        data-custom-className="body_text"
                        style={{
                          width: "778px",
                          border: "1px solid rgb(230, 230, 230)",
                          margin: "0px 0px 10px",
                          borderRadius: "3px",
                          color: "rgb(0,0,0)",
                          background: "transparent",
                        }}
                      >
                        <div
                          style={{
                            borderBottom: "1px solid rgb(230, 230, 230)",
                            padding: "8px 13px",
                            background: "transparent",
                          }}
                        >
                          <table style={{ background: "transparent" }}>
                            <tbody style={{ background: "transparent" }}>
                              <tr
                                style={{
                                  borderBottom: "1px solid rgb(230, 230, 230)",
                                  margin: "0px 0px 8px",
                                  background: "transparent",
                                  verticalAlign: "top",
                                  fontSize: "12px",
                                  fontFamily: "Roboto, Arial",
                                }}
                              >
                                <td
                                  style={{
                                    color: "rgb(25, 36, 60)",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                    textAlign: "right",
                                    minWidth: "80px",
                                  }}
                                >
                                  Name:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    background: "transparent",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(139, 147, 167)",
                                      background: "transparent",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    gen_204
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  background: "transparent",
                                  fontSize: "12px",
                                  fontFamily: "Roboto, Arial",
                                  lineHeight: "1.67",
                                  margin: "0px",
                                  verticalAlign: "top",
                                }}
                              >
                                <td
                                  style={{
                                    color: "rgb(25, 36, 60)",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                    textAlign: "right",
                                    minWidth: "80px",
                                  }}
                                >
                                  Purpose:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    background: "transparent",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(139, 147, 167)",
                                      background: "transparent",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    __________
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  borderBottom: "1px solid rgb(230, 230, 230)",
                                  margin: "0px 0px 8px",
                                  background: "transparent",
                                  verticalAlign: "top",
                                  fontSize: "12px",
                                  fontFamily: "Roboto, Arial",
                                }}
                              >
                                <td
                                  style={{
                                    color: "rgb(25, 36, 60)",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                    textAlign: "right",
                                    minWidth: "80px",
                                  }}
                                >
                                  Provider:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    background: "transparent",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(139, 147, 167)",
                                      background: "transparent",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    www.feevaa.com
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  borderBottom: "1px solid rgb(230, 230, 230)",
                                  margin: "0px 0px 8px",
                                  background: "transparent",
                                  verticalAlign: "top",
                                  fontSize: "12px",
                                  fontFamily: "Roboto, Arial",
                                }}
                              >
                                <td
                                  style={{
                                    color: "rgb(25, 36, 60)",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                    textAlign: "right",
                                    minWidth: "80px",
                                  }}
                                >
                                  Service:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    background: "transparent",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(139, 147, 167)",
                                      background: "transparent",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    __________ &nbsp;
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  borderBottom: "1px solid rgb(230, 230, 230)",
                                  margin: "0px 0px 8px",
                                  background: "transparent",
                                  verticalAlign: "top",
                                  fontSize: "12px",
                                  fontFamily: "Roboto, Arial",
                                }}
                              >
                                <td
                                  style={{
                                    color: "rgb(25, 36, 60)",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                    textAlign: "right",
                                    minWidth: "80px",
                                  }}
                                >
                                  Country:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    background: "transparent",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(139, 147, 167)",
                                      background: "transparent",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    United States
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  borderBottom: "1px solid rgb(230, 230, 230)",
                                  margin: "0px 0px 8px",
                                  background: "transparent",
                                  verticalAlign: "top",
                                  fontSize: "12px",
                                  fontFamily: "Roboto, Arial",
                                }}
                              >
                                <td
                                  style={{
                                    color: "rgb(25, 36, 60)",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                    textAlign: "right",
                                    minWidth: "80px",
                                  }}
                                >
                                  Type:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    background: "transparent",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(139, 147, 167)",
                                      background: "transparent",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    pixel_tracker
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  borderBottom: "1px solid rgb(230, 230, 230)",
                                  margin: "0px 0px 8px",
                                  background: "transparent",
                                  verticalAlign: "top",
                                  fontSize: "12px",
                                  fontFamily: "Roboto, Arial",
                                }}
                              >
                                <td
                                  style={{
                                    color: "rgb(25, 36, 60)",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                    textAlign: "right",
                                    minWidth: "80px",
                                  }}
                                >
                                  Expires in:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    background: "transparent",
                                    fontSize: "14px",
                                    fontFamily: " Arial",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(139, 147, 167)",
                                      background: "transparent",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    session
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </section>
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(0,0,0)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <strong style={{ background: "transparent" }}>
                        <span
                          data-custom-className="heading_1"
                          style={{
                            fontFamily: "Arial",
                            fontSize: "19px",
                            background: "transparent",
                          }}
                        >
                          How can I control cookies on my browser?
                        </span>
                      </strong>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      background: "transparent",
                      fontSize: "14px",
                      fontFamily: " Arial",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    As the means by which you can refuse cookies through your
                    web browser controls vary from browser to browser, you
                    should visit your browser's help menu for more information.
                    The following is information about how to manage cookies on
                    the most popular browsers:
                  </span>
                </div>
                <ul
                  style={{
                    background: "transparent",
                    fontSize: "medium",
                    fontFamily: "Roboto, Arial",
                    color: "rgb(0, 0, 0)",
                    listTtyleType: "square",
                  }}
                >
                  <li
                    style={{
                      background: "transparent",
                      lineHeight: "1.5",
                    }}
                  >
                    <a
                      href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{
                        backgroundImage: "initial",
                        backgroundPosition: "initial",
                        backgroundSize: "initial",
                        backgroundRepeat: "initial",
                        backgroundAttachment: "initial",
                        backgroundOrigin: "initial",
                        backgroundClip: "initial",
                      }}
                    >
                      <span
                        data-custom-className="link"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                          fontFamily: " Arial",
                          color: "rgb(48, 48, 241)",
                          wordBreak: "break-word",
                        }}
                      >
                        Chrome
                      </span>
                    </a>
                  </li>
                  <li
                    style={{
                      background: "transparent",
                      lineHeight: "1.5",
                    }}
                  >
                    <a
                      href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{
                        backgroundImage: "initial",
                        backgroundPosition: "initial",
                        backgroundSize: "initial",
                        backgroundRepeat: "initial",
                        backgroundAttachment: "initial",
                        backgroundOrigin: "initial",
                        backgroundClip: "initial",
                      }}
                    >
                      <span
                        data-custom-className="link"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                          fontFamily: " Arial",
                          color: "rgb(48, 48, 241)",
                          wordBreak: "break-word",
                        }}
                      >
                        Internet Explorer
                      </span>
                    </a>
                  </li>
                  <li
                    style={{
                      background: "transparent",
                      lineHeight: "1.5",
                    }}
                  >
                    <a
                      href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&amp;redirectlocale=en-US"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{
                        backgroundImage: "initial",
                        backgroundPosition: "initial",
                        backgroundSize: "initial",
                        backgroundRepeat: "initial",
                        backgroundAttachment: "initial",
                        backgroundOrigin: "initial",
                        backgroundClip: "initial",
                      }}
                    >
                      <span
                        data-custom-className="link"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                          fontFamily: " Arial",
                          color: "rgb(48, 48, 241)",
                          wordBreak: "break-word",
                        }}
                      >
                        Firefox
                      </span>
                    </a>
                  </li>
                  <li
                    style={{
                      background: "transparent",
                      lineHeight: "1.5",
                    }}
                  >
                    <a
                      href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{
                        backgroundImage: "initial",
                        backgroundPosition: "initial",
                        backgroundSize: "initial",
                        backgroundRepeat: "initial",
                        backgroundAttachment: "initial",
                        backgroundOrigin: "initial",
                        backgroundClip: "initial",
                      }}
                    >
                      <span
                        data-custom-className="link"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                          fontFamily: " Arial",
                          color: "rgb(48, 48, 241)",
                          wordBreak: "break-word",
                        }}
                      >
                        Safari
                      </span>
                    </a>
                  </li>
                  <li
                    style={{
                      background: "transparent",
                      lineHeight: "1.5",
                    }}
                  >
                    <a
                      href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{
                        backgroundImage: "initial",
                        backgroundPosition: "initial",
                        backgroundSize: "initial",
                        backgroundRepeat: "initial",
                        backgroundAttachment: "initial",
                        backgroundOrigin: "initial",
                        backgroundClip: "initial",
                      }}
                    >
                      <span
                        data-custom-className="link"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                          fontFamily: " Arial",
                          color: "rgb(48, 48, 241)",
                          wordBreak: "break-word",
                        }}
                      >
                        Edge
                      </span>
                    </a>
                  </li>
                  <li
                    style={{
                      background: "transparent",
                      lineHeight: "1.5",
                    }}
                  >
                    <a
                      href="https://help.opera.com/en/latest/web-preferences/"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{
                        backgroundImage: "initial",
                        backgroundPosition: "initial",
                        backgroundSize: "initial",
                        backgroundRepeat: "initial",
                        backgroundAttachment: "initial",
                        backgroundOrigin: "initial",
                        backgroundClip: "initial",
                      }}
                    >
                      <span
                        data-custom-className="link"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                          fontFamily: " Arial",
                          color: "rgb(48, 48, 241)",
                          wordBreak: "break-word",
                        }}
                      >
                        Opera
                      </span>
                    </a>
                  </li>
                </ul>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      background: "transparent",
                      fontSize: "14px",
                      fontFamily: " Arial",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    In addition, most advertising networks offer you a way to
                    opt out of targeted advertising. If you would like to find
                    out more information, please visit:
                  </span>
                </div>
                <ul
                  style={{
                    background: "transparent",
                    fontSize: "medium",
                    fontFamily: "Roboto, Arial",
                    color: "rgb(0, 0, 0)",
                    listTtyleType: "square",
                  }}
                >
                  <li
                    style={{
                      background: "transparent",
                      lineHeight: "1.5",
                    }}
                  >
                    <a
                      href="http://www.aboutads.info/choices/"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{
                        backgroundImage: "initial",
                        backgroundPosition: "initial",
                        backgroundSize: "initial",
                        backgroundRepeat: "initial",
                        backgroundAttachment: "initial",
                        backgroundOrigin: "initial",
                        backgroundClip: "initial",
                      }}
                    >
                      <span
                        data-custom-className="link"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                          fontFamily: " Arial",
                          color: "rgb(48, 48, 241)",
                          wordBreak: "break-word",
                        }}
                      >
                        Digital Advertising Alliance
                      </span>
                    </a>
                  </li>
                  <li
                    style={{
                      background: "transparent",
                      lineHeight: "1.5",
                    }}
                  >
                    <a
                      href="https://youradchoices.ca/"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{
                        backgroundImage: "initial",
                        backgroundPosition: "initial",
                        backgroundSize: "initial",
                        backgroundRepeat: "initial",
                        backgroundAttachment: "initial",
                        backgroundOrigin: "initial",
                        backgroundClip: "initial",
                      }}
                    >
                      <span
                        data-custom-className="link"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                          fontFamily: " Arial",
                          color: "rgb(48, 48, 241)",
                          wordBreak: "break-word",
                        }}
                      >
                        Digital Advertising Alliance of Canada
                      </span>
                    </a>
                  </li>
                  <li
                    style={{
                      background: "transparent",
                      lineHeight: "1.5",
                    }}
                  >
                    <a
                      href="http://www.youronlinechoices.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{
                        backgroundImage: "initial",
                        backgroundPosition: "initial",
                        backgroundSize: "initial",
                        backgroundRepeat: "initial",
                        backgroundAttachment: "initial",
                        backgroundOrigin: "initial",
                        backgroundClip: "initial",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                          fontFamily: " Arial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          data-custom-className="link"
                          style={{
                            color: "rgb(48, 48, 241)",
                            background: "transparent",
                            wordBreak: "break-word",
                          }}
                        >
                          European Interactive Digital Advertising Alliance
                        </span>
                      </span>
                    </a>
                  </li>
                </ul>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(0,0,0)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <strong style={{ background: "transparent" }}>
                        <span
                          data-custom-className="heading_1"
                          style={{
                            fontFamily: "Arial",
                            fontSize: "19px",
                            background: "transparent",
                          }}
                        >
                          What about other tracking technologies, like web
                          beacons?
                        </span>
                      </strong>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        Cookies are not the only way&nbsp;
                      </span>
                      <span style={{ background: "transparent" }}>
                        <span
                          data-custom-className="body_text"
                          style={{
                            fontFamily: "Arial",
                            fontSize: "14px",
                            background: "transparent",
                          }}
                        >
                          to recognize or track visitors to a website. We may
                          use other, similar technologies from time to time,
                          like web beacons (sometimes called "tracking pixels"
                          or "clear gifs"). These are tiny graphics files that
                          contain a unique identifier that enables us to
                          recognize when someone has visited our Website
                          <bdt
                            className="block-component"
                            style={{ background: "transparent" }}
                          ></bdt>
                          &nbsp;or opened an email including them
                          <bdt
                            className="statement-end-if-in-editor"
                            style={{ background: "transparent" }}
                          ></bdt>
                          . This allows us, for example, to monitor&nbsp;
                        </span>
                        <span style={{ background: "transparent" }}>
                          <span style={{ background: "transparent" }}>
                            <span
                              data-custom-className="body_text"
                              style={{
                                fontFamily: "Arial",
                                fontSize: "14px",
                                background: "transparent",
                              }}
                            >
                              the traffic patterns of users from one page within
                              a website to another, to deliver or communicate
                              with cookies, to understand whether you have come
                              to the website from an online advertisement
                              displayed on a third-party website, to improve
                              site performance, and to measure the success of
                              email marketing campaigns. In many instances,
                              these technologies are reliant on cookies to
                              function properly, and so declining cookies will
                              impair their functioning.
                            </span>
                            <bdt
                              className="block-component"
                              style={{ background: "transparent" }}
                            ></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <span style={{ background: "transparent" }}>
                        <span style={{ background: "transparent" }}>
                          <span
                            style={{
                              color: "rgb(0,0,0)",
                              background: "transparent",
                            }}
                          >
                            <strong style={{ background: "transparent" }}>
                              <span
                                data-custom-className="heading_1"
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "19px",
                                  background: "transparent",
                                }}
                              >
                                Do you use Flash cookies or Local Shared
                                Objects?
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span
                      data-custom-className="body_text"
                      style={{
                        fontFamily: "Arial",
                        fontSize: "14px",
                        background: "transparent",
                      }}
                    >
                      Websites may also use so-called "Flash Cookies" (also
                      known as Local Shared Objects or "LSOs") to, among other
                      things, collect and store information about your use of
                      our services, fraud prevention, and for other site
                      operations.
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span
                      data-custom-className="body_text"
                      style={{
                        fontFamily: "Arial",
                        fontSize: "14px",
                        background: "transparent",
                      }}
                    >
                      If you do not want Flash Cookies stored on your computer,
                      you can adjust the settings of your Flash player to block
                      Flash Cookies storage using the tools contained in
                      the&nbsp;
                    </span>
                  </span>
                  <span
                    data-custom-className="body_text"
                    style={{
                      background: "transparent",
                      fontSize: "14px",
                      fontFamily: " Arial",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(48, 48, 241)",
                        background: "transparent",
                      }}
                    >
                      <a
                        data-custom-className="link"
                        href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                        target="_BLANK"
                        style={{
                          backgroundImage: "initial",
                          backgroundPosition: "initial",
                          backgroundSize: "initial",
                          backgroundRepeat: "initial",
                          backgroundAttachment: "initial",
                          backgroundOrigin: "initial",
                          backgroundClip: "initial",
                          color: "rgb(48, 48, 241)",
                          wordBreak: "break-word",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            background: "transparent",
                            wordBreak: "break-word",
                          }}
                        >
                          Website Storage Settings Panel
                        </span>
                      </a>
                    </span>
                    <span
                      style={{
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      . You can also control Flash Cookies by going to the&nbsp;
                    </span>
                    <span
                      style={{
                        color: "rgb(48, 48, 241)",
                        background: "transparent",
                      }}
                    >
                      <a
                        data-custom-className="link"
                        href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
                        target="_BLANK"
                        style={{
                          backgroundImage: "initial",
                          backgroundPosition: "initial",
                          backgroundSize: "initial",
                          backgroundRepeat: "initial",
                          backgroundAttachment: "initial",
                          backgroundOrigin: "initial",
                          backgroundClip: "initial",
                          color: "rgb(48, 48, 241)",
                          wordBreak: "break-word",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            background: "transparent",
                            wordBreak: "break-word",
                          }}
                        >
                          Global Storage Settings Panel
                        </span>
                      </a>
                    </span>
                  </span>
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span
                      data-custom-className="body_text"
                      style={{
                        fontFamily: "Arial",
                        fontSize: "14px",
                        background: "transparent",
                      }}
                    >
                      &nbsp;and&nbsp;
                    </span>
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        following the instructions (which may include
                        instructions that explain, for example, how to delete
                        existing Flash Cookies (referred to "information" on the
                        Macromedia site), how to prevent Flash LSOs from being
                        placed on your computer without your being asked, and
                        (for Flash Player 8 and later) how to block Flash
                        Cookies that are not being delivered by the operator of
                        the page you are on at the time).
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        Please note that setting the Flash Player to restrict or
                        limit acceptance of Flash Cookies may reduce or impede
                        the functionality of some Flash applications, including,
                        potentially, Flash applications used in connection with
                        our services or online content.
                      </span>
                    </span>
                  </span>
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <bdt
                        className="statement-end-if-in-editor"
                        style={{ background: "transparent" }}
                      ></bdt>
                      <bdt
                        className="block-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <span style={{ background: "transparent" }}>
                        <span style={{ background: "transparent" }}>
                          <span style={{ background: "transparent" }}>
                            <span style={{ background: "transparent" }}>
                              <span
                                style={{
                                  background: "transparent",
                                  color: "rgb(0, 0, 0)",
                                }}
                              >
                                <strong style={{ background: "transparent" }}>
                                  <span
                                    data-custom-className="heading_1"
                                    style={{
                                      fontFamily: "Arial",
                                      fontSize: "19px",
                                      background: "transparent",
                                    }}
                                  >
                                    Do you serve targeted advertising?
                                  </span>
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span
                      data-custom-className="body_text"
                      style={{
                        fontFamily: "Arial",
                        fontSize: "14px",
                        background: "transparent",
                      }}
                    >
                      Third parties may serve cookies on your computer or mobile
                      device to serve advertising through our Website. These
                      companies may use information about your visits to this
                      and other websites in order to provide relevant
                      advertisements about goods and services that you may be
                      interested in. They may also employ technology that is
                      used to measure the effectiveness of advertisements. They
                      can accomplish this by using cookies or web beacons to
                      collect information about your visits to this and other
                      sites in order to provide relevant advertisements about
                      goods and services of potential interest to you. The
                      information collected through this process does not enable
                      us or them to identify your name, contact details, or
                      other details that directly identify you unless you choose
                      to provide these.
                    </span>
                  </span>
                  <span
                    style={{
                      background: "transparent",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <bdt
                        className="statement-end-if-in-editor"
                        style={{ background: "transparent" }}
                      ></bdt>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent",
                        color: "rgb(0, 0, 0)",
                      }}
                    >
                      <strong style={{ background: "transparent" }}>
                        <span
                          data-custom-className="heading_1"
                          style={{
                            fontFamily: "Arial",
                            fontSize: "19px",
                            background: "transparent",
                          }}
                        >
                          How often will you update this Cookie Policy?
                        </span>
                      </strong>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        We may update&nbsp;
                      </span>
                      <span style={{ background: "transparent" }}>
                        <span
                          data-custom-className="body_text"
                          style={{
                            fontFamily: "Arial",
                            fontSize: "14px",
                            background: "transparent",
                          }}
                        >
                          this Cookie Policy from time to time in order to
                          reflect, for example, changes to the cookies we use or
                          for other operational, legal, or regulatory reasons.
                          Please therefore revisit this Cookie Policy regularly
                          to stay informed about our use of cookies and related
                          technologies.
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span style={{ background: "transparent" }}>
                        <span
                          data-custom-className="body_text"
                          style={{
                            fontFamily: "Arial",
                            fontSize: "14px",
                            background: "transparent",
                          }}
                        >
                          The date at the top of this Cookie Policy indicates
                          when it was last updated.
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        style={{
                          background: "transparent",
                          color: "rgb(0, 0, 0)",
                        }}
                      >
                        <strong style={{ background: "transparent" }}>
                          <span
                            data-custom-className="heading_1"
                            style={{
                              fontFamily: "Arial",
                              fontSize: "19px",
                              background: "transparent",
                            }}
                          >
                            Where can I get further information?
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span style={{ background: "transparent" }}>
                        <span
                          data-custom-className="body_text"
                          style={{
                            fontFamily: "Arial",
                            fontSize: "14px",
                            background: "transparent",
                          }}
                        >
                          If you have any questions about our use of cookies or
                          other technologies, please email us at&nbsp;
                          <bdt
                            className="question"
                            style={{ background: "transparent" }}
                          >
                            info@feevaa.com
                          </bdt>
                          &nbsp;or by post to:
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      background: "transparent",
                      fontSize: "14px",
                      fontFamily: " Arial",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <br style={{ background: "transparent" }} />
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        feevaa.com
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <bdt
                      className="question"
                      style={{ background: "transparent" }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontFamily: "Arial",
                          fontSize: "14px",
                          background: "transparent",
                        }}
                      >
                        2730 Peachtree Industrial Blvd, Ste 105 Duluth, GA
                        30097, USA
                      </span>
                    </bdt>
                    <span
                      data-custom-className="body_text"
                      style={{
                        fontFamily: "Arial",
                        fontSize: "14px",
                        background: "transparent",
                      }}
                    >
                      <bdt
                        className="block-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      background: "transparent",
                      fontSize: "14px",
                      fontFamily: " Arial",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <bdt
                        className="question"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                        }}
                      >
                        Duluth
                      </bdt>
                      <bdt
                        className="block-component"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                        }}
                      ></bdt>
                      <bdt
                        className="block-component"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                        }}
                      ></bdt>
                      ,&nbsp;
                      <bdt
                        className="question"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                        }}
                      >
                        GA
                      </bdt>
                      <bdt
                        className="statement-end-if-in-editor"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                        }}
                      ></bdt>
                      <bdt
                        className="block-component"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                        }}
                      ></bdt>
                      <bdt
                        className="block-component"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                        }}
                      ></bdt>
                      &nbsp;
                      <bdt
                        className="question"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                        }}
                      >
                        30097
                      </bdt>
                      <bdt
                        className="statement-end-if-in-editor"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                        }}
                      ></bdt>
                      <bdt
                        className="block-component"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                        }}
                      ></bdt>
                      <bdt
                        className="block-component"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                        }}
                      ></bdt>
                      <bdt
                        className="block-component"
                        style={{
                          background: "transparent",
                          fontSize: "14px",
                        }}
                      ></bdt>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Roboto, Arial",
                    fontSize: "medium",
                    background: "transparent",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      background: "transparent",
                      fontSize: "14px",
                      fontFamily: " Arial",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    United States
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CookiePolicy;
