import React, { useEffect, useState } from "react";
import Slider from "./../components/Slider/Slider";
import Venue from "./../components/home/Venue/Venue";
import FeaturedCategories from "./../components/home/FeaturedCategories/FeaturedCategories";
import FeaturedEvents from "../components/home/FeaturedEvents/FeaturedEvents";
import { Layout } from "../components/LayoutUser/Layout";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllData } from "../store/slice/AllData";
import FeaturedBlogs from "../components/home/FeaturedBlogs";
import CustomHero from "../components/home/custom-hero/CustomHero";

const Home = () => {
  const dispatch = useDispatch();
  const { events, organizers, home_page_setting } = useSelector(
    (state) => state.AllData
  );
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    dispatch(
      fetchAllData({
        getData: "organizers",
        state: "organizers",
      })
    );
    dispatch(
      fetchAllData({
        getData: "blogs",
        state: "blogs",
      })
    );
    dispatch(
      fetchAllData({
        getData: "events",
        state: "events",
      })
    );
    dispatch(
      fetchAllData({
        getData: "categories",
        state: "categories",
      })
    );
    dispatch(
      fetchAllData({
        getData: "home_page_setting",
        state: "home_page_setting",
      })
    );
  }, []);

  useEffect(() => {
    let sliderData = [];
    if (home_page_setting?.home_page === "event") {
      sliderData = events?.filter((e) =>
        home_page_setting?.events?.includes(String(e.id))
      );
    }
    if (home_page_setting?.home_page === "organizers") {
      sliderData = organizers?.filter((e) =>
        home_page_setting?.organizers?.includes(String(e.id))
      );
    }
    setSliderData(sliderData);
  }, [events, home_page_setting]);

  return (
    <Layout>
      {home_page_setting?.home_page !== "custom" && sliderData?.length > 0 && (
        <Slider slidesData={sliderData} />
      )}
      {home_page_setting?.home_page === "custom" && <CustomHero />}
      {home_page_setting?.search === "yes" && <Venue />}
      <FeaturedEvents />
      <FeaturedCategories />
      <FeaturedBlogs />
    </Layout>
  );
};

export default Home;
