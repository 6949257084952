import React, { useState } from "react";
import { WrapperAddInput } from "./WrapperAddInput";
import { BiX } from "react-icons/bi";

const AddInputTags = ({ state, setSate, name }) => {
  const [input, setInput] = useState("");
  const tags = state[name] ?? [];
  const addTags = (e) => {
    e.preventDefault();
    const isTagExist = state[name].includes(input);
    !isTagExist &&
      input.trim() &&
      setSate({ ...state, [name]: [...state[name], input] });
    setInput("");
  };

  const removeTag = (index) => {
    // e.preventDefault();
    const remaingTags = state[name]
      ? state[name].filter((_, ind) => ind !== index)
      : [];
    setSate({ ...state, [name]: remaingTags });
  };

  return (
    <WrapperAddInput>
      <ul className="wrap">
        {tags.length > 0 &&
          tags?.map((curElem, index) => {
            return (
              <li key={index} className="tags">
                {curElem}
                <span className="removeTag" onClick={() => removeTag(index)}>
                  <BiX />
                </span>
              </li>
            );
          })}
        <li className="inputLi">
          <form onSubmit={addTags}>
            <input
              type="text"
              name=""
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="form-control"
            />
          </form>
        </li>
      </ul>
    </WrapperAddInput>
  );
};

export default AddInputTags;
