import React from "react";
import { FaSyncAlt } from "react-icons/fa";
import Table from "./Table";
import { useSelector } from "react-redux";
import Filters from "../../organizer/events/Filters";

const ManageEvents = () => {
  const { events, event_categories, venues, loading } = useSelector(
    (state) => state.admin
  );
  const { filter_data } = useSelector((state) => state.organizerFilters);
  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event">
        <Filters
          events={events}
          event_categories={event_categories}
          venues={venues}
          btn="no"
        />
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table events={filter_data.events} />
      )}
    </>
  );
};

export default ManageEvents;
