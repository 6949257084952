import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { FaBookReader, FaRegClock, FaSitemap } from "react-icons/fa";
import ReactTimeAgo from "react-time-ago";
import { addBlogFilters } from "../../store/slice/BlogFilters";
import { useDispatch } from "react-redux";

const Card = ({ blogs }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const server = process.env.REACT_APP_SERVER;
  const { name, slug, image, category, time, created_at } = blogs;
  const created_at_timestamp = new Date(created_at).getTime();

  const func = (e, name, value) => {
    e.preventDefault();
    pathname === "/en/blogs"
      ? dispatch(addBlogFilters({ name, value }))
      : navigate("/en/blogs", {
          state: { name, value },
        });
  };
  return (
    <div className="col-lg-4 col-md-6">
      <div className="card mb-4 shadow-none blog-post-card">
        <Link to={`/en/blog-article/${slug}`}>
          <img
            className="card-img-top img-lazy-load b-loaded"
            src={`${server}/blog/${image}`}
            alt="tes"
            height="200px"
          />
        </Link>
        <div className="card-body">
          <p className="card-text">
            <small className="text-muted me-3">
              <Link
                to=""
                onClick={(e) => func(e, "category", category.id)}
                className="text-dark text-capitalize"
              >
                <FaSitemap className="me-1" /> {category?.name}
              </Link>
            </small>
            <small className="text-muted me-3">
              <FaRegClock className="me-1" />{" "}
              <ReactTimeAgo date={created_at_timestamp} locale="en-US" />
            </small>
            {time && (
              <small className="text-muted">
                <FaBookReader className="me-1" /> {time} min read
              </small>
            )}
          </p>
          <h5 className="card-title">
            <Link to={`/en/blog-article/${slug}`} className="text-dark">
              {name}
            </Link>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Card;
