import axios from "axios";
import { useState } from "react";
import { FaLock, FaRegIdCard, FaSyncAlt, FaUser } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Layout } from "../../LayoutUser/Layout";
import Privacypolicy from "./../../Privacypolicy";
import TermsService from "./../../TermsService";

const Organizer = () => {
  const navigate = useNavigate();
  const api = process.env.REACT_APP_SERVER_API;
  const [isPanding, setPanding] = useState(false);
  const [organizer, setOrganizer] = useState({
    organize_name: "",
    first_name: "",
    last_name: "",
    user_name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const [errors, setErrors] = useState({});

  // validate fields
  const validateFields = () => {
    let newErrors = {};

    if (!organizer.organize_name.trim())
      newErrors.organize_name = "Please Enter Organizer Name";
    if (!organizer.first_name.trim())
      newErrors.first_name = "Please Enter First Name";
    if (!organizer.last_name.trim())
      newErrors.last_name = "Please Enter Last Name";
    if (!organizer.user_name.trim())
      newErrors.user_name = "Please Enter Username";
    if (!organizer.email.trim()) newErrors.email = "Please Enter Email";
    if (!organizer.password) newErrors.password = "Please Enter Password";
    if (organizer.password && organizer.password.length < 6)
      newErrors.password = "The password must be at least 6 characters";
    if (organizer.password !== organizer.password_confirmation)
      newErrors.password_confirmation = "Passwords do not match";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  // handle changes feilds
  const handleOrganizer = (e) => {
    const { name, value } = e.target;
    setOrganizer({ ...organizer, [name]: value });
  };

  // register organizer
  const RegisterOrganizer = async (e) => {
    e.preventDefault();

    if (!validateFields()) return;

    try {
      setPanding(true);
      const { data } = await axios.post(`${api}/organizer/register`, {
        ...organizer,
      });
      data.status == 200 &&
        navigate("/en/confirm_email", { state: "ConfirmEmail" });
      // toast.success(data.message);
      setPanding(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setPanding(false);
    }
  };

  return (
    <Layout>
      <div className="container signin-section mb-4">
        <div className="row">
          <div className="col-12 mb-4">
            <h1 className="sign-title text-center">
              Take your events to the next level
            </h1>
          </div>
          <div className="col-xl-5 offset-xl-1 d-none d-xl-block">
            <img
              src="/./images/register-organizer.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-xl-4 col-12">
            <form action="" className="signform" onSubmit={RegisterOrganizer}>
              <div className="mb-4">
                <div className="input-group mb-1">
                  <span className="input-group-text">
                    <FaRegIdCard />
                  </span>
                  <input
                    type="text"
                    name="organize_name"
                    className={`form-control ${
                      errors.organize_name && "is-invalid"
                    }`}
                    autoComplete="organize_name"
                    placeholder="Organize Name"
                    value={organizer.organize_name}
                    onChange={handleOrganizer}
                  />
                </div>
                {errors.organize_name && (
                  <small className="text-danger d-block">
                    {errors.organize_name}
                  </small>
                )}
              </div>
              <div className="mb-4">
                <div className="input-group mb-1">
                  <span className="input-group-text">
                    <FaUser />
                  </span>
                  <input
                    type="text"
                    name="first_name"
                    className={`form-control ${
                      errors.first_name && "is-invalid"
                    }`}
                    autoComplete="first_name"
                    placeholder="First Name"
                    value={organizer.first_name}
                    onChange={handleOrganizer}
                  />
                </div>
                {errors.first_name && (
                  <small className="text-danger d-block">
                    {errors.first_name}
                  </small>
                )}
              </div>
              <div className="mb-4">
                <div className="input-group mb-1">
                  <span className="input-group-text">
                    <FaUser />
                  </span>
                  <input
                    type="text"
                    name="last_name"
                    className={`form-control ${
                      errors.last_name && "is-invalid"
                    }`}
                    autoComplete="last_name"
                    placeholder="Last Name"
                    value={organizer.last_name}
                    onChange={handleOrganizer}
                  />
                </div>
                {errors.last_name && (
                  <small className="text-danger d-block">
                    {errors.last_name}
                  </small>
                )}
              </div>
              <div className="mb-4">
                <div className="input-group mb-1">
                  <span className="input-group-text">
                    <FaUser />
                  </span>
                  <input
                    type="text"
                    name="user_name"
                    className={`form-control ${
                      errors.user_name && "is-invalid"
                    }`}
                    autoComplete="username"
                    placeholder="Username"
                    value={organizer.user_name}
                    onChange={handleOrganizer}
                  />
                </div>
                {errors.user_name && (
                  <small className="text-danger d-block">
                    {errors.user_name}
                  </small>
                )}
              </div>
              <div className="mb-4">
                <div className="input-group mb-1">
                  <span className="input-group-text">
                    <HiOutlineMail />
                  </span>
                  <input
                    type="email"
                    name="email"
                    className={`form-control ${errors.email && "is-invalid"}`}
                    autoComplete="email"
                    placeholder="Email"
                    value={organizer.email}
                    onChange={handleOrganizer}
                  />
                </div>
                {errors.email && (
                  <small className="text-danger">{errors.email}</small>
                )}
              </div>
              <div className="mb-4">
                <div className="input-group mb-1">
                  <span className="input-group-text">
                    <FaLock />
                  </span>
                  <input
                    type="password"
                    name="password"
                    className={`form-control ${
                      errors.password && "is-invalid"
                    }`}
                    autoComplete="current-password"
                    placeholder="Password"
                    value={organizer.password}
                    onChange={handleOrganizer}
                  />
                </div>
                {errors.password && (
                  <small className="text-danger d-block">
                    {errors.password}
                  </small>
                )}
              </div>
              <div className="mb-4">
                <div className="input-group mb-1">
                  <span className="input-group-text">
                    <FaLock />
                  </span>
                  <input
                    type="password"
                    name="password_confirmation"
                    className={`form-control ${
                      errors.password_confirmation && "is-invalid"
                    }`}
                    autoComplete="current-password"
                    placeholder="Repeat Password"
                    value={organizer.password_confirmation}
                    onChange={handleOrganizer}
                  />
                </div>
                {errors.password_confirmation && (
                  <small className="text-danger d-block">
                    {errors.password_confirmation}
                  </small>
                )}
              </div>

              <div className="mb-3 text-center text-sm">
                <span className="text-muted">
                  By clicking the Sign Up button, I agree to
                </span>
                <Link
                  to="/en/page/terms-of-service"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  {" "}
                  Terms of service{" "}
                </Link>
                <span className="text-muted"> and </span>
                <Link
                  to="/en/page/privacy-policy"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop2"
                >
                  {" "}
                  Privacy policy{" "}
                </Link>
              </div>
              <div className="form-group mt-3 mb-3">
                <button
                  className="btn btn-dark w-100 rounded-5"
                  disabled={isPanding ? "disabled" : ""}
                  type="submit"
                >
                  {isPanding ? (
                    <FaSyncAlt className="spinIcon" />
                  ) : (
                    "Create Account"
                  )}
                </button>
              </div>
              <p className="text-center">
                Already have an account?<Link to="/en/signin"> Sign in</Link>
              </p>
            </form>
          </div>
        </div>
      </div>

      {/* models */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                TERMS OF SERVICE
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <TermsService />
            </div>
            {/* <div className="modal-footer">
            <button type="button" className="btn btn-sm" data-bs-dismiss="modal">
              Close
            </button>
          </div> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                PRIVACY NOTICE
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Privacypolicy />
            </div>
            {/* <div className="modal-footer">
            <button type="button" className="btn btn-sm" data-bs-dismiss="modal">
              Close
            </button>
          </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Organizer;
