import React from "react";
import { Dropdown, Space, Divider, Button, theme } from "antd";
import { FaCaretDown, FaCartArrowDown, FaShoppingCart } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Alert from "../global/alert/Alert";
const { useToken } = theme;
const items = [];

const CartMenu = () => {
  const { token } = useToken();
  const server = process.env.REACT_APP_SERVER;
  const { cart, total_item } = useSelector((state) => state.CartDetails);
  const { user } = useSelector((state) => state.authUser);
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: "none",
  };
  const msg = {
    icon: <FaShoppingCart className="me-3" />,
    type: "alert-info",
    text: "Your cart is empty.",
  };

  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="bottomRight"
      arrow={{
        pointAtRight: true,
      }}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          {React.cloneElement(menu, {
            style: menuStyle,
          })}
          <Space
            style={{
              padding: 8,
            }}
          >
            <div>
              {cart.length > 0 ? (
                <>
                  <div>
                    {cart?.map((cart, i) => (
                      <React.Fragment key={i}>
                        <figure
                          className="dropdown-cart badge-notification badge-notification-right"
                          data-badge={cart?.ticket_quantity}
                        >
                          <div className="aside">
                            <div className="img-wrap img-sm">
                              {cart?.image ? (
                                <img
                                  src={`${server}/event/image/${cart?.image}`}
                                  className="img-thumbnail img-50-50"
                                />
                              ) : (
                                <img
                                  src="/./images/noPhoto.png"
                                  className="img-thumbnail img-50-50"
                                />
                              )}
                            </div>
                          </div>
                          <figcaption className="text-wrap">
                            <h6 className="title mb-2">
                              <Link to={`/en/event/${cart?.slug}`}>
                                {cart?.event_name}
                              </Link>
                            </h6>
                            <div className="text-black-50 small mb-1">
                              {cart?.start_On}
                            </div>
                            <div className="text-black-50 small">
                              {cart?.event_date_online === 1 ? (
                                " Online"
                              ) : (
                                <>
                                  {cart?.venue?.city}:{" "}
                                  {cart?.venue?.postal_code}{" "}
                                  {cart?.venue?.street_address},{" "}
                                  {cart?.venue?.state}, {cart?.venue?.country}
                                </>
                              )}
                            </div>
                          </figcaption>
                        </figure>
                        <div className="dropdown-divider-cart mt-4 mb-4"></div>
                      </React.Fragment>
                    ))}
                  </div>
                  <Link
                    to={
                      user && Object.keys(user).length > 0
                        ? "/en/dashboard/attendee/cart"
                        : "/en/guest/cart"
                    }
                    className="btn btn-outline-dark btn-block d-flex align-items-center gap-1 justify-content-center"
                  >
                    <FaCartArrowDown />
                    View cart
                  </Link>
                </>
              ) : (
                <Alert {...msg} />
              )}
            </div>
          </Space>
        </div>
      )}
    >
      <a
        onClick={(e) => e.preventDefault()}
        className="icontext badge-notification badge-notification-left"
        data-badge={total_item}
      >
        <Space>
          <div className="icon-wrap icon-xs bg-dark round text-white">
            <FaShoppingCart />
          </div>
          <span className="d-lg-block d-none">
            Cart
            <FaCaretDown />
          </span>
        </Space>
      </a>
    </Dropdown>
  );
};
export default CartMenu;
