import React from "react";
import { useSelector } from "react-redux";

const CustomHero = () => {
  const server = process.env.REACT_APP_SERVER;
  const { home_page_setting } = useSelector((state) => state.AllData);

  return (
    <section className="section-intro bg-dark text-white text-center">
      <div
        className="homepage-custom-hero-background d-flex flex-column img-lazy-load b-loaded"
        style={{
          backgroundImage: `url(
            ${server}/home/${home_page_setting?.image}
          )`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "550px",
        }}
      >
        <div className="container my-auto">
          <div className="row">
            <div className="col-lg-8 col-sm-12 text-center mx-auto">
              <h2 className="display-4 mt-3 mb-3 text-capitalize">
                {home_page_setting?.title}
              </h2>
              <h3 className="mb-5 font-weight-lighter">
                {home_page_setting?.paragraph}
              </h3>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-9 col-md-8 col-sm-12 mx-auto">
              <div className="card">
                <div className="card-body p30">
                  <form action="/en/events">
                    <div className="form-row text-left">
                      <div className="col-lg-3 col-sm-12">
                        <label
                          for="hero-search-category"
                          className="form-label text-dark"
                        >
                          Looking for
                        </label>
                        <div
                          className="select2-container select2"
                          id="s2id_hero-search-category"
                        >
                          <a
                            href="javascript:void(0)"
                            className="select2-choice"
                            tabindex="-1"
                          >
                            {" "}
                            <span className="select2-chosen" id="select2-chosen-1">
                              Anything
                            </span>
                            <abbr className="select2-search-choice-close"></abbr>{" "}
                            <span className="select2-arrow" role="presentation">
                              <b role="presentation"></b>
                            </span>
                          </a>
                          <label for="s2id_autogen1" className="select2-offscreen">
                            Looking for
                          </label>
                          <input
                            className="select2-focusser select2-offscreen"
                            type="text"
                            aria-haspopup="true"
                            role="button"
                            aria-labelledby="select2-chosen-1"
                            id="s2id_autogen1"
                          />
                          <div className="select2-drop select2-display-none select2-with-searchbox">
                            {" "}
                            <div className="select2-search">
                              {" "}
                              <label
                                for="s2id_autogen1_search"
                                className="select2-offscreen"
                              >
                                Looking for
                              </label>{" "}
                              <input
                                type="text"
                                autocomplete="off"
                                autocorrect="off"
                                autocapitalize="off"
                                spellcheck="false"
                                className="select2-input"
                                role="combobox"
                                aria-expanded="true"
                                aria-autocomplete="list"
                                aria-owns="select2-results-1"
                                id="s2id_autogen1_search"
                                placeholder=""
                              />{" "}
                            </div>{" "}
                            <ul
                              className="select2-results"
                              role="listbox"
                              id="select2-results-1"
                            >
                              {" "}
                            </ul>
                          </div>
                        </div>
                        <select
                          id="hero-search-category"
                          className="select2 select2-offscreen"
                          name="category"
                          data-sort-options="1"
                          tabindex="-1"
                          title="Looking for"
                        >
                          <option value="">Anything</option>
                          <option value="cinema">Cinema</option>
                          <option value="concert-music">Concert / Music</option>
                          <option value="conference">Conference</option>
                          <option value="exposition">Exposition</option>
                          <option value="festival-spectacle">
                            Festival / Spectacle
                          </option>
                          <option value="game-competition">
                            Game / Competition
                          </option>
                          <option value="healthmedical">Health/Medical</option>
                          <option value="museum-monument-update">
                            Museum / Monument update
                          </option>
                          <option value="night-lifeclub-events">
                            Night life/Club Events
                          </option>
                          <option value="parking-services">
                            Parking / Services
                          </option>
                          <option value="recreational-park-attraction">
                            Recreational park / Attraction
                          </option>
                          <option value="religious">Religious</option>
                          <option value="restaurantsfooddrinks">
                            Restaurants/Food/Drinks
                          </option>
                          <option value="sport-fitness-1">
                            Sport / Fitness
                          </option>
                          <option value="trip-camp">Trip / Camp</option>
                          <option value="workshop-training">
                            Workshop / Training
                          </option>
                        </select>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <label
                          for="hero-search-country"
                          className="form-label text-dark"
                        >
                          In
                        </label>
                        <div
                          className="select2-container select2"
                          id="s2id_hero-search-country"
                        >
                          <a
                            href="javascript:void(0)"
                            className="select2-choice"
                            tabindex="-1"
                          >
                            {" "}
                            <span className="select2-chosen" id="select2-chosen-2">
                              Anywhere
                            </span>
                            <abbr className="select2-search-choice-close"></abbr>{" "}
                            <span className="select2-arrow" role="presentation">
                              <b role="presentation"></b>
                            </span>
                          </a>
                          <label for="s2id_autogen2" className="select2-offscreen">
                            In
                          </label>
                          <input
                            className="select2-focusser select2-offscreen"
                            type="text"
                            aria-haspopup="true"
                            role="button"
                            aria-labelledby="select2-chosen-2"
                            id="s2id_autogen2"
                          />
                          <div className="select2-drop select2-display-none select2-with-searchbox" />{" "}
                          <div className="select2-search">
                            {" "}
                            <label
                              for="s2id_autogen2_search"
                              className="select2-offscreen"
                            >
                              In
                            </label>{" "}
                            <input
                              type="text"
                              autocomplete="off"
                              autocorrect="off"
                              autocapitalize="off"
                              spellcheck="false"
                              className="select2-input"
                              role="combobox"
                              aria-expanded="true"
                              aria-autocomplete="list"
                              aria-owns="select2-results-2"
                              id="s2id_autogen2_search"
                              placeholder=""
                            />{" "}
                          </div>{" "}
                          <ul
                            className="select2-results"
                            role="listbox"
                            id="select2-results-2"
                          >
                            {" "}
                          </ul>
                        </div>
                      </div>
                      <select
                        id="hero-search-country"
                        className="select2 select2-offscreen"
                        name="country"
                        data-sort-options="1"
                        tabindex="-1"
                        title="In"
                      >
                        <option value="">Anywhere</option>
                        <option value="austria">Austria</option>
                        <option value="egypt">Egypt</option>
                        <option value="equatorial-guinea">
                          Equatorial Guinea
                        </option>
                        <option value="ethiopia">Ethiopia</option>
                        <option value="afghanistan">Afghanistan</option>
                        <option value="south-africa">South Africa</option>
                        <option value="aland-islands">Aland Islands</option>
                        <option value="albania">Albania</option>
                        <option value="germany">Germany</option>
                        <option value="algeria">Algeria</option>
                        <option value="american-samoa">American Samoa</option>
                        <option value="india">India</option>
                        <option value="andorra">Andorra</option>
                        <option value="angola">Angola</option>
                        <option value="anguilla">Anguilla</option>
                        <option value="antigua-and-barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="antarctica">Antarctica</option>
                        <option value="saudi-arabia">Saudi Arabia</option>
                        <option value="argentina">Argentina</option>
                        <option value="armenia">Armenia</option>
                        <option value="aruba">Aruba</option>
                        <option value="azerbaijan">Azerbaijan</option>
                        <option value="australia">Australia</option>
                        <option value="belgium">Belgium</option>
                        <option value="bosnia-and-herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="bahamas">Bahamas</option>
                        <option value="bahrain">Bahrain</option>
                        <option value="bangladesh">Bangladesh</option>
                        <option value="barbados">Barbados</option>
                        <option value="belarus">Belarus</option>
                        <option value="belize">Belize</option>
                        <option value="benin">Benin</option>
                        <option value="bermuda">Bermuda</option>
                        <option value="bhutan">Bhutan</option>
                        <option value="bolivia">Bolivia</option>
                        <option value="bonaire-saint-eustatius-and-saba">
                          Bonaire, Saint Eustatius and Saba
                        </option>
                        <option value="botswana">Botswana</option>
                        <option value="bouvet-island">Bouvet Island</option>
                        <option value="brazil">Brazil</option>
                        <option value="british-virgin-islands">
                          British Virgin Islands
                        </option>
                        <option value="british-indian-ocean-territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="brunei">Brunei</option>
                        <option value="bulgaria">Bulgaria</option>
                        <option value="burkina-faso">Burkina Faso</option>
                        <option value="burundi">Burundi</option>
                        <option value="cape-verde">Cape Verde</option>
                        <option value="cameroon">Cameroon</option>
                        <option value="cambodia">Cambodia</option>
                        <option value="canada">Canada</option>
                        <option value="qatar">Qatar</option>
                        <option value="cayman-islands">Cayman Islands</option>
                        <option value="kazakhstan">Kazakhstan</option>
                        <option value="central-african-republic">
                          Central African Republic
                        </option>
                        <option value="chad">Chad</option>
                        <option value="chile">Chile</option>
                        <option value="china">China</option>
                        <option value="cyprus">Cyprus</option>
                        <option value="christmas-island">
                          Christmas Island
                        </option>
                        <option value="singapore">Singapore</option>
                        <option value="cocos-islands">Cocos Islands</option>
                        <option value="colombia">Colombia</option>
                        <option value="comoros">Comoros</option>
                        <option value="cook-islands">Cook Islands</option>
                        <option value="north-korea">North Korea</option>
                        <option value="south-korea">South Korea</option>
                        <option value="ivory-coast">Ivory Coast</option>
                        <option value="costa-rica">Costa Rica</option>
                        <option value="croatia">Croatia</option>
                        <option value="cuba">Cuba</option>
                        <option value="curacao">Curacao</option>
                        <option value="czech-republic">Czech Republic</option>
                        <option value="denmark">Denmark</option>
                        <option value="democratic-republic-of-the-congo">
                          Democratic Republic of the Congo
                        </option>
                        <option value="djibouti">Djibouti</option>
                        <option value="dominica">Dominica</option>
                        <option value="dominican-republic">
                          Dominican Republic
                        </option>
                        <option value="east-timor">East Timor</option>
                        <option value="ecuador">Ecuador</option>
                        <option value="el-salvador">El Salvador</option>
                        <option value="united-arab-emirates">
                          United Arab Emirates
                        </option>
                        <option value="eritrea">Eritrea</option>
                        <option value="slovakia">Slovakia</option>
                        <option value="slovenia">Slovenia</option>
                        <option value="spain">Spain</option>
                        <option value="estonia">Estonia</option>
                        <option value="united-states">United States</option>
                        <option value="faroe-islands">Faroe Islands</option>
                        <option value="falkland-islands">
                          Falkland Islands
                        </option>
                        <option value="fiji">Fiji</option>
                        <option value="philippines">Philippines</option>
                        <option value="finland">Finland</option>
                        <option value="france">France</option>
                        <option value="french-polynesia">
                          French Polynesia
                        </option>
                        <option value="french-guiana">French Guiana</option>
                        <option value="french-southern-territories">
                          French Southern Territories
                        </option>
                        <option value="gabon">Gabon</option>
                        <option value="gambia">Gambia</option>
                        <option value="ghana">Ghana</option>
                        <option value="georgia">Georgia</option>
                        <option value="south-georgia-and-the-south-sandwich-islands">
                          South Georgia and the South Sandwich Islands
                        </option>
                        <option value="gibraltar">Gibraltar</option>
                        <option value="greenland">Greenland</option>
                        <option value="greece">Greece</option>
                        <option value="grenada">Grenada</option>
                        <option value="guadeloupe">Guadeloupe</option>
                        <option value="guam">Guam</option>
                        <option value="guatemala">Guatemala</option>
                        <option value="guyana">Guyana</option>
                        <option value="guernsey">Guernsey</option>
                        <option value="guinea">Guinea</option>
                        <option value="guinea-bissau">Guinea-Bissau</option>
                        <option value="haiti">Haiti</option>
                        <option value="heard-island-and-mcdonald-islands">
                          Heard Island and McDonald Islands
                        </option>
                        <option value="saint-helena">Saint Helena</option>
                        <option value="saint-lucia">Saint Lucia</option>
                        <option value="honduras">Honduras</option>
                        <option value="hong-kong">Hong Kong</option>
                        <option value="hungary">Hungary</option>
                        <option value="yemen">Yemen</option>
                        <option value="iceland">Iceland</option>
                        <option value="isle-of-man">Isle of Man</option>
                        <option value="marshall-islands">
                          Marshall Islands
                        </option>
                        <option value="united-states-minor-outlying-islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="turks-and-caicos-islands">
                          Turks and Caicos Islands
                        </option>
                        <option value="u-s-virgin-islands">
                          U.S. Virgin Islands
                        </option>
                        <option value="norfolk-island">Norfolk Island</option>
                        <option value="northern-mariana-islands">
                          Northern Mariana Islands
                        </option>
                        <option value="solomon-islands">Solomon Islands</option>
                        <option value="indonesia">Indonesia</option>
                        <option value="iran">Iran</option>
                        <option value="iraq">Iraq</option>
                        <option value="ireland">Ireland</option>
                        <option value="italy">Italy</option>
                        <option value="jamaica">Jamaica</option>
                        <option value="japan">Japan</option>
                        <option value="jersey">Jersey</option>
                        <option value="jordan">Jordan</option>
                        <option value="kenya">Kenya</option>
                        <option value="kyrgyzstan">Kyrgyzstan</option>
                        <option value="kiribati">Kiribati</option>
                        <option value="kosovo">Kosovo</option>
                        <option value="kuwait">Kuwait</option>
                        <option value="moldova">Moldova</option>
                        <option value="turkey">Turkey</option>
                        <option value="lebanon">Lebanon</option>
                        <option value="libya">Libya</option>
                        <option value="laos">Laos</option>
                        <option value="latvia">Latvia</option>
                        <option value="liechtenstein">Liechtenstein</option>
                        <option value="seychelles">Seychelles</option>
                        <option value="lesotho">Lesotho</option>
                        <option value="liberia">Liberia</option>
                        <option value="lithuania">Lithuania</option>
                        <option value="luxembourg">Luxembourg</option>
                        <option value="monaco">Monaco</option>
                        <option value="mexico">Mexico</option>
                        <option value="macao">Macao</option>
                        <option value="macedonia">Macedonia</option>
                        <option value="madagascar">Madagascar</option>
                        <option value="malaysia">Malaysia</option>
                        <option value="malawi">Malawi</option>
                        <option value="maldives">Maldives</option>
                        <option value="mali">Mali</option>
                        <option value="malta">Malta</option>
                        <option value="morocco">Morocco</option>
                        <option value="martinique">Martinique</option>
                        <option value="mauritius">Mauritius</option>
                        <option value="mauritania">Mauritania</option>
                        <option value="mayotte">Mayotte</option>
                        <option value="myanmar">Myanmar</option>
                        <option value="micronesia">Micronesia</option>
                        <option value="mozambique">Mozambique</option>
                        <option value="mongolia">Mongolia</option>
                        <option value="montenegro">Montenegro</option>
                        <option value="montserrat">Montserrat</option>
                        <option value="niger">Niger</option>
                        <option value="namibia">Namibia</option>
                        <option value="nauru">Nauru</option>
                        <option value="nepal">Nepal</option>
                        <option value="netherlands">Netherlands</option>
                        <option value="new-caledonia">New Caledonia</option>
                        <option value="new-zealand">New Zealand</option>
                        <option value="nicaragua">Nicaragua</option>
                        <option value="nigeria">Nigeria</option>
                        <option value="niue">Niue</option>
                        <option value="norway">Norway</option>
                        <option value="oman">Oman</option>
                        <option value="uganda">Uganda</option>
                        <option value="uzbekistan">Uzbekistan</option>
                        <option value="pakistan">Pakistan</option>
                        <option value="palestinian-territory">
                          Palestinian Territory
                        </option>
                        <option value="palau">Palau</option>
                        <option value="panama">Panama</option>
                        <option value="papua-new-guinea">
                          Papua New Guinea
                        </option>
                        <option value="paraguay">Paraguay</option>
                        <option value="peru">Peru</option>
                        <option value="pitcairn">Pitcairn</option>
                        <option value="poland">Poland</option>
                        <option value="puerto-rico">Puerto Rico</option>
                        <option value="portugal">Portugal</option>
                        <option value="russia">Russia</option>
                        <option value="united-kingdom">United Kingdom</option>
                        <option value="republic-of-the-congo">
                          Republic of the Congo
                        </option>
                        <option value="reunion">Reunion</option>
                        <option value="romania">Romania</option>
                        <option value="rwanda">Rwanda</option>
                        <option value="syria">Syria</option>
                        <option value="serbia">Serbia</option>
                        <option value="saint-barthelemy">
                          Saint Barthelemy
                        </option>
                        <option value="saint-kitts-and-nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="saint-martin">Saint Martin</option>
                        <option value="saint-pierre-and-miquelon">
                          Saint Pierre and Miquelon
                        </option>
                        <option value="sao-tome-and-principe">
                          Sao Tome and Principe
                        </option>
                        <option value="saint-vincent-and-the-grenadines">
                          Saint Vincent and the Grenadines
                        </option>
                        <option value="south-sudan">South Sudan</option>
                        <option value="western-sahara">Western Sahara</option>
                        <option value="san-marino">San Marino</option>
                        <option value="zambia">Zambia</option>
                        <option value="samoa">Samoa</option>
                        <option value="sint-maarten">Sint Maarten</option>
                        <option value="sweden">Sweden</option>
                        <option value="switzerland">Switzerland</option>
                        <option value="senegal">Senegal</option>
                        <option value="sierra-leone">Sierra Leone</option>
                        <option value="somalia">Somalia</option>
                        <option value="sudan">Sudan</option>
                        <option value="sri-lanka">Sri Lanka</option>
                        <option value="swaziland">Swaziland</option>
                        <option value="suriname">Suriname</option>
                        <option value="svalbard-and-jan-mayen">
                          Svalbard and Jan Mayen
                        </option>
                        <option value="tajikistan">Tajikistan</option>
                        <option value="thailand">Thailand</option>
                        <option value="taiwan">Taiwan</option>
                        <option value="tanzania">Tanzania</option>
                        <option value="togo">Togo</option>
                        <option value="tokelau">Tokelau</option>
                        <option value="tonga">Tonga</option>
                        <option value="trinidad-and-tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="tunisia">Tunisia</option>
                        <option value="turkmenistan">Turkmenistan</option>
                        <option value="tuvalu">Tuvalu</option>
                        <option value="ukraine">Ukraine</option>
                        <option value="uruguay">Uruguay</option>
                        <option value="vanuatu">Vanuatu</option>
                        <option value="vatican">Vatican</option>
                        <option value="venezuela">Venezuela</option>
                        <option value="vietnam">Vietnam</option>
                        <option value="wallis-and-futuna">
                          Wallis and Futuna
                        </option>
                        <option value="zimbabwe">Zimbabwe</option>
                      </select>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <label
                        for="hero-search-stardate"
                        className="form-label text-dark"
                      >
                        When
                      </label>
                      <div
                        className="select2-container select2"
                        id="s2id_hero-search-stardate"
                      >
                        <a
                          href="javascript:void(0)"
                          className="select2-choice"
                          tabindex="-1"
                        >
                          {" "}
                          <span className="select2-chosen" id="select2-chosen-3">
                            Anytime
                          </span>
                          <abbr className="select2-search-choice-close"></abbr>{" "}
                          <span className="select2-arrow" role="presentation">
                            <b role="presentation"></b>
                          </span>
                        </a>
                        <label for="s2id_autogen3" className="select2-offscreen">
                          When
                        </label>
                        <input
                          className="select2-focusser select2-offscreen"
                          type="text"
                          aria-haspopup="true"
                          role="button"
                          aria-labelledby="select2-chosen-3"
                          id="s2id_autogen3"
                        />
                        <div className="select2-drop select2-display-none select2-with-searchbox">
                          {" "}
                          <div className="select2-search">
                            {" "}
                            <label
                              for="s2id_autogen3_search"
                              className="select2-offscreen"
                            >
                              When
                            </label>{" "}
                            <input
                              type="text"
                              autocomplete="off"
                              autocorrect="off"
                              autocapitalize="off"
                              spellcheck="false"
                              className="select2-input"
                              role="combobox"
                              aria-expanded="true"
                              aria-autocomplete="list"
                              aria-owns="select2-results-3"
                              id="s2id_autogen3_search"
                              placeholder=""
                            />{" "}
                          </div>{" "}
                          <ul
                            className="select2-results"
                            role="listbox"
                            id="select2-results-3"
                          >
                            {" "}
                          </ul>
                        </div>
                      </div>
                      <select
                        id="hero-search-stardate"
                        className="select2 select2-offscreen"
                        name="startdate"
                        tabindex="-1"
                        title="When"
                      >
                        <option value="">Anytime</option>
                        <option value="today">Today</option>
                        <option value="tomorrow">Tomorrow</option>
                        <option value="thisweekend">This weekend</option>
                        <option value="thisweek">This week</option>
                        <option value="nextweek">Next week</option>
                        <option value="thismonth">This month</option>
                        <option value="nextmonth">Next month</option>
                      </select>
                    </div>

                    <div className="col-lg-3 col-sm-12 my-auto">
                      <button className="btn btn-light btn-block" type="submit">
                        <i className="fas fa-search"></i> Search
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default CustomHero;
