import React, { useEffect, useRef, useState } from "react";
import { Checkbox, Radio } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import { Tab, Tabs } from "react-bootstrap";
import { FaCircleInfo } from "react-icons/fa6";
import { BiSolidPlusSquare } from "react-icons/bi";
import Count from "../../../../count/Count";
import SelectBox from "../../../../global/SelectBox";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaSyncAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchData } from "../../../../../store/slice/Organizer";
import { fetchAllData } from "./../../../../../store/slice/AllData";
import { adminFetchData } from "../../../../../store/slice/Admin";
import { states } from "../../../../global/Values";

export default function AddVenues({ role }) {
  const api = process.env.REACT_APP_SERVER_API;
  const server = process.env.REACT_APP_SERVER;
  const { id } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authUser);
  const { venues } = useSelector((state) => state[role]);
  const { venue_types, amenities, countries } = useSelector(
    (state) => state.organizer
  );
  const [errors, setErrors] = useState({});
  const [addImageCard, setAddImageCard] = useState([]);
  const [isPanding, setPanding] = useState(false);
  const [activeData, setActiveData] = useState({
    venue_types: [],
    amenities: [],
    countries: [],
  });
  const [venue, setVenue] = useState({
    venue_type_id: "",
    name: "",
    description: "",
    amenities: [],
    seated_guests_number: "",
    standing_guests_number: "",
    neighborhoods: "",
    pricing: "",
    availability: "",
    food_beverage: "",
    venue_page: 1,
    contact_email: "",
    street_address: "",
    street_address_2: "",
    city: "",
    postal_code: "",
    state: "",
    country: "",
    event_page: 1,
    images: [],
  });

  const venue_type_idputRef = useRef(null);
  const nameInputRef = useRef(null);
  const descriptionInputRef = useRef(null);
  const street_addressInputRef = useRef(null);
  const cityInputRef = useRef(null);
  const postal_codeInputRef = useRef(null);
  const stateInputRef = useRef(null);
  const countryInputRef = useRef(null);

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  // Editor options
  const modules = {
    toolbar: toolbarOptions,
  };

  // for handle Editor on change
  const handleEditor = (html) => {
    const isEmptyEditor = html.trim() === "<p><br></p>";
    setVenue({ ...venue, description: isEmptyEditor ? "" : html });
  };

  // fon handle on change
  const handleVenue = (e) => {
    const { name, value } = e.target;
    setVenue({ ...venue, [name]: value });
  };
  // for handle change select box
  const handleSelectbBox = (name, value) => {
    setVenue({ ...venue, [name]: value });
  };
  // for handleCheckboxChange
  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    const isSelected = e.target.checked;
    // venue.amenities = [...venue.amenities, value];

    setVenue((prevVenue) => ({
      ...prevVenue,
      amenities: !isSelected
        ? prevVenue.amenities.filter((option) => option !== value)
        : [...prevVenue.amenities, value],
    }));
  };

  // add more images cards
  const handleAddImageCard = (e) => {
    e.preventDefault();
    const newImages = [
      ...addImageCard,
      { id: Math.random().toString(36).substr(2, 9) },
    ];
    setAddImageCard(newImages);
  };

  // for remove more images card
  const handleRemoveImageCard = (e, ele, index) => {
    e.preventDefault();
    const updatedImages = venue.images.filter((_, i) => i !== index);

    const imagesCard = addImageCard.filter((ele1, i) => ele1.id !== ele.id);
    setAddImageCard(imagesCard);

    setVenue({
      ...venue,
      image_id: id ? venue.image_id.filter((ele1, i) => ele1 !== ele.id) : "",
      images: updatedImages,
    });
  };

  // for add venue images
  const handleAddImage = (e) => {
    const file = e.target.files[0];
    setVenue({
      ...venue,
      images: [...venue.images, file],
    });
  };

  const validateFields = () => {
    let newErrors = {};

    if (!venue.name.trim()) newErrors.name = "This value should not be null.";
    if (!venue.description)
      newErrors.description = "This value should not be null.";
    if (!venue.venue_type_id)
      newErrors.venue_type_id = "This value should not be null.";
    if (!venue.street_address)
      newErrors.street_address = "This value should not be null.";
    if (!venue.city) newErrors.city = "This value should not be null.";
    if (!venue.postal_code)
      newErrors.postal_code = "This value should not be null.";
    if (!venue.state) newErrors.state = "This value should not be null.";
    // if (!venue.postal_code)
    //   newErrors.postal_code = "This value should not be null.";
    if (!venue.country) newErrors.country = "This value should not be null.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };
  // Scroll to the corresponding input field error
  const scrollToFirstError = (errors) => {
    // Find the first error field
    const firstErrorField = Object.keys(errors)[0];
    // Scroll to the corresponding input field
    if (firstErrorField === "name" && nameInputRef.current) {
      nameInputRef.current.focus();
    } else if (
      firstErrorField === "venue_type_id" &&
      venue_type_idputRef.current
    ) {
      venue_type_idputRef.current.focus();
    } else if (
      firstErrorField === "street_address" &&
      street_addressInputRef.current
    ) {
      street_addressInputRef.current.focus();
    } else if (firstErrorField === "city" && cityInputRef.current) {
      cityInputRef.current.focus();
    } else if (
      firstErrorField === "postal_code" &&
      postal_codeInputRef.current
    ) {
      postal_codeInputRef.current.focus();
    } else if (firstErrorField === "state" && stateInputRef.current) {
      stateInputRef.current.focus();
    } else if (firstErrorField === "country" && countryInputRef.current) {
      countryInputRef.current.focus();
    } else if (
      errors.description &&
      descriptionInputRef.current &&
      descriptionInputRef.current.editor
    ) {
      let editor = descriptionInputRef.current.editor;
      let errorText = errors.description.trim(); // Get the error message
      let content = editor.getText(); // Get the editor content

      // Find the position of the first character of the error message within the content
      let errorIndex = content.indexOf(errorText);
      // If the error message is found, scroll to its position; otherwise, scroll to the end of the content as fallback
      let errorPosition = errorIndex !== -1 ? errorIndex : editor.getLength();
      editor.setSelection(errorPosition);
    }
  };

  // for adding new venue
  const addNewVenue = async (e) => {
    e.preventDefault();

    // Validate form fields
    const isValid = validateFields();
    // Scroll to first error if any
    if (!isValid) {
      // Use a callback to ensure state update is complete
      setErrors((errors) => {
        scrollToFirstError(errors);
        return errors;
      });
      return;
    }

    const endPoint = id ? "edit_venue" : "add_venue";
    try {
      setPanding(true);
      const { data } = await axios.post(`${api}/organizer/${endPoint}`, venue, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (data.status === id ? 200 : 201) {
        toast.success(data.message);
        setPanding(false);
        if (role === "admin") {
          navigate("/en/dashboard/administrator/manage-venues");
          dispatch(
            adminFetchData({
              endPoint: "admin/venues",
              state: "venues",
              token: user.token,
            })
          );
        } else {
          state && state === "addEvent"
            ? navigate("/en/dashboard/organizer/my-events/add", {
                state: "addEvent",
              })
            : navigate("/en/dashboard/organizer/my-events/add");
          dispatch(
            fetchData({
              getData: "organizer/venues_details",
              token: user.token,
              state: "venues",
            })
          );
        }
        dispatch(
          fetchAllData({
            getData: "venues",
            state: "venues",
          })
        );
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setPanding(false);
    }
  };

  // for filtering aqctive data
  useEffect(() => {
    let venue_type = venue_types.filter((venue) => venue.status === "visible");
    let country = countries.filter((countrie) => countrie.status === "visible");
    country = country.map((country) => ({
      id: country.code,
      name: country.name,
    }));
    let amenity = amenities.filter((amenity) => amenity.status === "visible");

    setActiveData({
      venue_types: venue_type,
      countries: country,
      amenities: amenity,
    });
  }, [venue_types, amenities, countries]);

  // for get single venue for edit or update
  useEffect(() => {
    const venueEdit = venues.find((ele) => ele.id == id);
    if (id) {
      setAddImageCard(venueEdit?.images);
      const imageIds = venueEdit?.images?.map((image) => image.id);
      setVenue({
        ...venueEdit,
        image_id: imageIds,
        amenities: venueEdit?.amenities || [],
      });
    }
  }, [id]);

  useEffect(() => {
    dispatch(
      fetchData({
        getData: "admin/venue_types",
        token: user.token,
        state: "venue_types",
      })
    );
    dispatch(
      fetchData({
        getData: "admin/amenities",
        token: user.token,
        state: "amenities",
      })
    );
  }, []);

  return (
    <>
      <div className="card box">
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form
              className="signform"
              onSubmit={addNewVenue}
              encType="multipart/form-data"
            >
              <div className="ProfileSettingOptionsContainer">
                <div className="mb-5">
                  <div>
                    <div className="name-section mb-3">
                      <label htmlFor="name" className="form-label fw-600">
                        Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        onChange={handleVenue}
                        value={venue.name}
                        ref={nameInputRef}
                      />
                      {errors.name && (
                        <small className="text-danger d-block mt-1">
                          {errors.name}
                        </small>
                      )}
                    </div>
                    <div className="editor-section mt-3">
                      <label className="form-label fw-600">
                        Description<span style={{ color: "red" }}>*</span>
                      </label>
                      <ReactQuill
                        value={venue.description}
                        onChange={handleEditor}
                        modules={modules}
                        style={{ height: "300px", clear: "both" }}
                        className=""
                        ref={descriptionInputRef}
                      />
                    </div>
                  </div>
                </div>
                {errors.description && (
                  <small className="text-danger d-block mt-1">
                    {errors.description}
                  </small>
                )}
                <div className="mb-4 mt-3">
                  <label htmlFor="Type" className="form-label fw-600">
                    Type<span style={{ color: "red" }}>*</span>
                  </label>
                  <SelectBox
                    name="venue_type_id"
                    options={activeData.venue_types}
                    val={venue.venue_type_id}
                    change={handleSelectbBox}
                    ref={venue_type_idputRef}
                  />
                  {errors.venue_type_id && (
                    <small className="text-danger d-block mt-1">
                      {errors.venue_type_id}
                    </small>
                  )}
                </div>
                <div className="mb-4">
                  <label htmlFor="last_name" className="form-label fw-600">
                    Amenities
                  </label>

                  <div className="ProfileSettingRadioBtns">
                    {activeData.amenities?.map((curElem, index) => (
                      <Checkbox
                        key={index}
                        className="Checkbox fw-600"
                        value={curElem.slug}
                        onChange={handleCheckboxChange}
                        checked={
                          venue?.amenities?.includes(curElem?.slug)
                            ? true
                            : false
                        }
                      >
                        {curElem.name}
                      </Checkbox>
                    ))}
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="seated_guests_number"
                    className="form-label fw-600"
                  >
                    Seated guests number
                  </label>
                  <Count
                    name="seated_guests_number"
                    value={venue.seated_guests_number}
                    state="venue"
                    setState={setVenue}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="last_name" className="form-label fw-600">
                    Standing guests number
                  </label>
                  <Count
                    name="standing_guests_number"
                    value={venue.standing_guests_number}
                    state="venue"
                    setState={setVenue}
                  />
                </div>
                <div className="name-section mb-4">
                  <label htmlFor="neighborhoods" className="form-label fw-600">
                    Neighborhoods
                  </label>
                  <input
                    type="text"
                    id="neighborhoods"
                    name="neighborhoods"
                    className="form-control"
                    onChange={handleVenue}
                    value={venue.neighborhoods}
                  />
                </div>
                <div className="name-section mb-4">
                  <label htmlFor="pricing" className="form-label fw-600">
                    Pricing
                  </label>
                  <input
                    type="number"
                    id="pricing"
                    name="pricing"
                    className="form-control"
                    onChange={handleVenue}
                    value={venue.pricing}
                  />
                </div>
                <div className="name-section mb-4">
                  <label htmlFor="availability" className="form-label fw-600">
                    Availibility
                  </label>
                  <input
                    type="text"
                    id="availability"
                    name="availability"
                    className="form-control"
                    onChange={handleVenue}
                    value={venue.availability}
                  />
                </div>
                <div className="name-section mb-4">
                  <label htmlFor="food_beverage" className="form-label fw-600">
                    Food and beverage details
                  </label>
                  <input
                    type="text"
                    id="food_beverage"
                    name="food_beverage"
                    className="form-control"
                    onChange={handleVenue}
                    value={venue.food_beverage}
                  />
                </div>
                <div className="name-section mb-4">
                  <label className="form-label fw-600">
                    Show the quote form on the venue page
                  </label>
                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      value={1}
                      onChange={handleVenue}
                      checked={venue.venue_page === 1}
                      name="venue_page"
                    >
                      Yes
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      value={0}
                      onChange={handleVenue}
                      checked={venue.venue_page === 0}
                      name="venue_page"
                    >
                      No
                    </Radio>
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="form-label fw-600">
                    Contact email
                  </label>
                  <div className="ProfleSettingOptionDescBox">
                    <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                    <span className="ProfleSettingOptionDesc">
                      This email address will be used to receive the quote
                      requests, make sure to mention it if you want to show the
                      quote form
                    </span>
                  </div>
                  <input
                    type="email"
                    id="email"
                    name="contact_email"
                    className="form-control"
                    onChange={handleVenue}
                    value={venue.contact_email}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="street_address" className="form-label fw-600">
                    Street address<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="street_address"
                    id="street_address"
                    className="form-control"
                    onChange={handleVenue}
                    value={venue?.street_address}
                    ref={street_addressInputRef}
                  />
                  {errors.street_address && (
                    <small className="text-danger d-block mt-1">
                      {errors.street_address}
                    </small>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="street_address_2"
                    className="form-label fw-600"
                  >
                    Street address 2
                  </label>
                  <input
                    type="text"
                    name="street_address_2"
                    id="street_address_2"
                    className="form-control"
                    onChange={handleVenue}
                    value={venue?.street_address_2}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="city" className="form-label fw-600">
                    City<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    className="form-control"
                    onChange={handleVenue}
                    value={venue.city}
                    ref={cityInputRef}
                  />
                  {errors.city && (
                    <small className="text-danger d-block mt-1">
                      {errors.city}
                    </small>
                  )}
                </div>
                <div className="mb-4">
                  <label htmlFor="postal_code" className="form-label fw-600">
                    Zip/ Postal code<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="postal_code"
                    id="postal_code"
                    className="form-control"
                    onChange={handleVenue}
                    value={venue.postal_code}
                    ref={postal_codeInputRef}
                  />
                  {errors.postal_code && (
                    <small className="text-danger d-block mt-1">
                      {errors.postal_code}
                    </small>
                  )}
                </div>
                <div className="mb-4">
                  <label htmlFor="state" className="form-label fw-600">
                    State<span style={{ color: "red" }}>*</span>
                  </label>
                  <SelectBox
                    name="state"
                    options={states}
                    val={venue.state}
                    change={handleSelectbBox}
                    ref={stateInputRef}
                  />

                  {errors.state && (
                    <small className="text-danger d-block mt-1">
                      {errors.state}
                    </small>
                  )}
                </div>
                <div className="mb-4">
                  <label htmlFor="last_name" className="form-label fw-600">
                    Country<span style={{ color: "red" }}>*</span>
                  </label>
                  <SelectBox
                    name="country"
                    options={activeData.countries}
                    val={venue.country}
                    change={handleSelectbBox}
                    ref={countryInputRef}
                  />
                  {errors.country && (
                    <small className="text-danger d-block mt-1">
                      {errors.country}
                    </small>
                  )}
                </div>
                <div className="mb-4">
                  <label htmlFor="last_name" className="form-label fw-600">
                    Show the map along with the address on the venue page and
                    event page
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      value={1}
                      onChange={handleVenue}
                      checked={venue.event_page === 1}
                      name="event_page"
                    >
                      Yes
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      value={0}
                      onChange={handleVenue}
                      checked={venue.event_page === 0}
                      name="event_page"
                    >
                      No
                    </Radio>
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="" className="form-label fw-600">
                    Images
                  </label>
                  {addImageCard.map((ele, index) => (
                    <div className="card mb-3" key={ele.id}>
                      <div className="card-body">
                        <div className="mb-3 mt-2">
                          <label
                            htmlFor={`venueImage-${ele.id}`}
                            className="form-label"
                          >
                            Venue image
                          </label>
                          <input
                            type="file"
                            name={`venueImage-${ele.id}`}
                            id={`venueImage-${ele.id}`}
                            className="form-control mb-2"
                            onChange={handleAddImage}
                          />
                          {id && ele.name && (
                            <Link
                              className="fancybox"
                              to={`${server}/venue/image/${ele.name}`}
                              data-toggle="tooltip"
                              title=""
                              data-original-title="Enlarge"
                              target="_blank"
                            >
                              <img
                                src={`${server}/venue/image/${ele.name}`}
                                className="img-thumbnail re_ent_img"
                                alt={ele.name}
                              />
                            </Link>
                          )}
                          <button
                            className="d-flex align-items-center  btn btn-outline-dark btn-sm rounded-5 mt-2 mb-3"
                            onClick={(e) =>
                              handleRemoveImageCard(e, ele, index)
                            }
                          >
                            <BiSolidPlusSquare />
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="mb-2 mt-2">
                    <button
                      onClick={handleAddImageCard}
                      className="d-flex align-items-center btn btn-outline-dark btn-sm rounded-5 mb-3"
                    >
                      <BiSolidPlusSquare />
                      <span>ADD</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="form-group mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  type="submit"
                  disabled={isPanding ? "disabled" : ""}
                >
                  {isPanding ? <FaSyncAlt className="spinIcon" /> : "SAVE"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
