import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const api = process.env.REACT_APP_SERVER_API;

export const fetchTickets = createAsyncThunk(
  "cart/fetchTickets",
  async ({ getData, token, state }) => {
    const res = await axios.get(`${api}/${getData}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: res.data.data, state };
  }
);

const totalPrice = (amount, percentage, tax) => {
  let calculatedPercentage = (parseInt(amount) * percentage) / 100;
  let total = calculatedPercentage + parseInt(amount);
  if (tax) {
    let calculatedTax = (total * tax) / 100;
    return parseFloat((total + calculatedTax).toFixed(2));
  }
};

const initialState = {
  tickets: [],
  myWallet: [],
  cart: [],
  total_price: 0,
  total_item: 0,
  fees: "",
  tax: "",
  payment_settings: {},
  loading: false,
  error: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    add_To_Cart(state, action) {
      let {
        user,
        event,
        ticket_qty,
        start_On,
        fees,
        price,
        tax,
        remaining_ticket,
        tickets_left,
      } = action.payload;
      let {
        id,
        event_id,
        ticket_name,
        discount,
        ticket_free,
        tickets_per_attendee,
      } = event?.tickets?.[0];
      // // for existing products
      let existingItem = state.cart?.find(
        (curItem) => curItem.id === id + event_id
      );

      if (existingItem) {
        let updatedItem = state.cart.map((curElem) => {
          if (curElem.id === id + event_id) {
            let newQty = curElem.ticket_quantity + ticket_qty;
            let sub_price = price * newQty;
            let total_price = totalPrice(sub_price, fees, tax);

            // if (
            //   newQty >= curElem.tickets_per_attendee ||
            //   curElem.remaining_ticket == 0
            // ) {
            //   return curElem;
            // }

            return {
              ...curElem,
              ticket_quantity: newQty,
              sub_price,
              total_price,
              remaining_ticket: tickets_left - newQty,
            };
          } else {
            return curElem;
          }
        });
        state.cart = updatedItem;
        state.fees = fees;
        state.tax = tax;
      } else {
        let amount = price * ticket_qty;
        let total = totalPrice(amount, fees, tax);
        let cartItem = {
          price: price,
          user_id: user?.id || null,
          organize_slug: event?.user?.slug,
          id: id + event_id,
          event_id: parseInt(event_id),
          ticket_id: id,
          sub_price: amount,
          ticket_price: price,
          ticket_quantity: ticket_qty,
          event_name: event.name,
          slug: event.slug,
          start_On,
          image: event.main_event_image,
          organize_name: event.user.organize_name,
          venue: event.venue,
          ticket_name,
          tickets_per_attendee,
          ticket_free,
          fees,
          tax,
          event_date_online: event.event_date_online,
          total_price: total,
          remaining_ticket: remaining_ticket - ticket_qty,
          tickets_left,
          discount,
        };
        if (state.cart.length === 0) {
          cartItem.checkout_reference = new Date().getTime().toString();
        } else {
          cartItem.checkout_reference = state.cart[0].checkout_reference;
        }
        state.cart.push(cartItem);
        state.fees = fees;
        state.tax = tax;
        state.total_price = (
          parseFloat(state.total_price) + parseFloat(total)
        ).toFixed(2);
      }
    },
    increaseQty(state, action) {
      let updatedProduct = state.cart.map((curElem) => {
        if (curElem.id === action.payload) {
          let qty = curElem.ticket_quantity + 1;
          let sub_price = curElem.ticket_price * qty;
          let total_price = totalPrice(sub_price, curElem?.fees, curElem?.tax);

          if (
            qty > curElem.tickets_per_attendee ||
            curElem.remaining_ticket == 0
          ) {
            return curElem;
          }

          return {
            ...curElem,
            ticket_quantity: qty,
            sub_price,
            total_price,
            remaining_ticket: curElem.remaining_ticket - qty,
          };
        } else {
          return curElem;
        }
      });
      state.cart = updatedProduct;
    },
    decreaseQty(state, action) {
      let updatedProduct = state.cart?.map((curElem) => {
        if (curElem.id === action.payload) {
          let qty = curElem.ticket_quantity - 1;
          let sub_price = curElem.ticket_price * qty;
          let remaining_ticket = curElem.remaining_ticket + qty;

          if (qty <= 1) {
            qty = 1;
            sub_price = curElem.ticket_price;
            remaining_ticket = curElem.tickets_left - 1;
          }
          let total_price = totalPrice(sub_price, curElem.fees, curElem.tax);

          return {
            ...curElem,
            ticket_quantity: qty,
            sub_price,
            total_price,
            remaining_ticket,
          };
        } else {
          return curElem;
        }
      });
      state.cart = updatedProduct;
    },
    removeCartItem(state, acttion) {
      let newItems = state.cart.filter(
        (curElem) => curElem.id !== acttion.payload
      );
      state.cart = newItems;
    },
    clearCart(state) {
      state.cart = [];
      state.total_price = 0;
      state.total_item = 0;
    },
    cartTotal(state) {
      let { total_item, total_price } = state.cart.reduce(
        (accumulator, currentValue) => {
          let { ticket_quantity, total_price, ticket_free } = currentValue;
          if (ticket_free === 0) {
            accumulator.total_price += total_price;
          }
          accumulator.total_item += parseInt(ticket_quantity);
          return accumulator;
        },
        { total_price: 0, total_item: 0 }
      );
      state.total_price = parseFloat(total_price).toFixed(2);
      state.total_item = total_item;
    },
    addPaymentSettings(state, action) {
      const { fees, tax } = action.payload;
      state.tax = tax;
      state.fees = fees;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTickets.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchTickets.fulfilled, (state, action) => {
        const { data, state: dataState } = action.payload;
        state[dataState] = data;
        state.loading = false;
      })
      .addCase(fetchTickets.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export const {
  add_To_Cart,
  increaseQty,
  decreaseQty,
  removeCartItem,
  clearCart,
  cartTotal,
  addPaymentSettings,
} = cartSlice.actions;
export default cartSlice.reducer;
