import { FaPlus, FaSearch, FaSyncAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./style.css";
import { Table } from "./Table";
import { Radio, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  addOrganizerFilters,
  clearAllFilters,
  loadFilterOrganizer,
  sorted,
  sorting,
} from "../../../../../store/slice/OrganizerFilters";
import SelectBox from "../../../../global/SelectBox";

const MyScanners = () => {
  const dispatch = useDispatch();
  const { scanners, loading, error } = useSelector((state) => state.organizer);
  const {
    filters: { username, status },
    sorting_value,
    filter_data,
  } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    dispatch(loadFilterOrganizer({ data: scanners, key: "scanners" }));
  }, [dispatch, scanners]);

  useEffect(() => {
    dispatch(clearAllFilters());
  }, []);

  useEffect(() => {
    dispatch(sorted("scanners"));
  }, [sorting_value]);

  const add_filter = (e) => {
    const { name, value } = e.target;
    dispatch(addOrganizerFilters({ name, value }));
  };

  // Search Filter
  const add_filter3 = (name, value) => {
    dispatch(sorting({ name, value }));
  };
  // Sorting select Box options
  const options = [
    { id: "date-desc", name: "Creation date (desc)" },
    { id: "date-asc", name: "Creation date (asc)" },
  ];
  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event MyScanners">
        <div className="row">
          <div className="col-sm-12 col-lg-2 text-center text-lg-start mb-3 mb-lg-0 align-self-center">
            <span className="center-lg-y text-muted">
              {filter_data.scanners.length} result(s) found
            </span>
          </div>
          <div className="col-sm-12 col-lg-4 text-center">
            {/* <SortSelect /> */}
            <ul className="d-flex">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Search
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <div className="signform">
                      <label
                        htmlFor="Username"
                        className="form-label dashboard"
                      >
                        Username
                      </label>
                      <input
                        type="email"
                        name="username"
                        className="form-control"
                        id="Username"
                        value={username}
                        onChange={add_filter}
                      />
                    </div>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Enabled
                </a>
                <ul
                  className="dropdown-menu enabled-menu ProfileSettingRadioBtns"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      name="status"
                      checked={status === "all"}
                      value="all"
                      onChange={add_filter}
                    >
                      All
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      name="status"
                      checked={status === "active"}
                      value="active"
                      onChange={add_filter}
                    >
                      Enabled only
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn fw-600"
                      name="status"
                      checked={status === "inactive"}
                      value="inactive"
                      onChange={add_filter}
                    >
                      Disabled only
                    </Radio>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-lg-6 text-center text-lg-end d-flex align-items-center justify-content-around">
            <form className="text-start d-flex align-items-center">
              <label htmlFor="sort" className="">
                Sort by{" "}
              </label>
              <SelectBox
                name="sorting_value"
                options={options}
                val={sorting_value}
                change={add_filter3}
              />
            </form>
            <Tooltip title="Add a new Scanner">
              <Link
                to="add"
                className="btn btn-dark ms-3"
                style={{ fontSize: "12px" }}
              >
                <FaPlus />
              </Link>
            </Tooltip>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table data={filter_data.scanners} error={error} />
      )}
    </>
  );
};

export default MyScanners;
