import React, { useEffect } from "react";
import Help from "../components/helpCenter/help/Help";
import { Layout } from "../components/LayoutUser/Layout";
import { fetchAllData } from "../store/slice/AllData";
import { useDispatch } from "react-redux";

const HelpCenter = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchAllData({
        getData: "articles",
        state: "articles",
      })
    );
    dispatch(
      fetchAllData({
        getData: "article-categories",
        state: "article_categories",
      })
    );
  }, []);
  return (
    <Layout>
      <Help />
    </Layout>
  );
};

export default HelpCenter;
