import { Dropdown, Pagination, Space } from "antd";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaEdit,
  FaEllipsisV,
  FaEye,
  FaEyeSlash,
  FaInfoCircle,
  FaTrashRestoreAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Alert from "../../../../global/alert/Alert";
import { IconPickerItem } from "react-fa-icon-picker";
import {
  useDelete,
  useStatusChange,
} from "../../../../../utils/CustomFunctions";
import { IoCloseSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { useState } from "react";

export const Table2 = ({ article_categories }) => {
  // for state change
  const { loading, handleStatusChange } = useStatusChange(
    "admin",
    "article_category_change_status",
    "article_categories",
    "article_categories"
  );
  // for soft delete and parmanently delete
  const { isLoading, handleDelete } = useDelete(
    "admin",
    "article_categories",
    "article_categories"
  );
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No help center category found",
  };

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = article_categories?.slice(
    indexOfFirstData,
    indexOfLastData
  );

  return (
    <>
      <div className="row organizer-event">
        <div className="col-12">
          {article_categories?.length > 0 ? (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Articles count</th>
                      <th>Icon</th>
                      <th>Status</th>
                      <th className="text-center">
                        <AiTwotoneSetting />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((category, i) => {
                      const items = [
                        {
                          label: (
                            <Link
                              to={`${category.slug}/edit`}
                              className="d-flex align-items-center"
                            >
                              <FaEdit className="me-1" />
                              Edit
                            </Link>
                          ),
                          key: "0",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleStatusChange(e, category.id)
                              }
                            >
                              {category.status === "visible" ? (
                                <>
                                  <FaEyeSlash className="me-1" />
                                  Hide
                                </>
                              ) : (
                                <>
                                  <FaEye className="me-1" /> Show
                                </>
                              )}
                            </Link>
                          ),
                          key: "1",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(
                                  e,
                                  category.id,
                                  "article_category_delete",
                                  ""
                                )
                              }
                            >
                              {category?.deleted_at !== null ? (
                                <>
                                  <FaTrashRestoreAlt className="me-1" /> Restore
                                </>
                              ) : (
                                <>
                                  <IoCloseSharp className="me-1" />
                                  Delete
                                </>
                              )}
                            </Link>
                          ),
                          key: "2",
                        },
                        category.deleted_at && {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(
                                  e,
                                  category.id,
                                  "article_category_delete_permanent",
                                  "delete_permanent"
                                )
                              }
                            >
                              <MdDelete className="me-1" />
                              Delete permanently
                            </Link>
                          ),
                          key: "3",
                        },
                      ];
                      return (
                        <tr key={i}>
                          <td className="text-capitalize">{category.name}</td>
                          <td>{category.articles_count}</td>
                          <td>
                            {category.icon && (
                              <IconPickerItem
                                icon={category?.icon}
                                size={24}
                                color="#000"
                              />
                            )}
                          </td>
                          <td className="">
                            {category.status === "visible" ? (
                              <span className="badge badge-success d-inline-flex align-items-center">
                                <FaEye className="me-1" /> Visible
                              </span>
                            ) : (
                              <span className="badge badge-danger d-inline-flex align-items-center">
                                <FaEyeSlash className="me-1" /> Hidden
                              </span>
                            )}
                            {category?.deleted_at && (
                              <>
                                <br />
                                <br />
                                <span className="badge badge-danger d-inline-flex align-items-center">
                                  <IoCloseSharp className="me-1" />
                                  Deleted
                                </span>
                              </>
                            )}
                          </td>
                          <td className="text-center">
                            <Dropdown
                              menu={{
                                items,
                              }}
                              trigger={["click"]}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Space className="justify-content-center">
                                  <FaEllipsisV />
                                </Space>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <Alert {...msg} />
          )}
        </div>
      </div>

      {article_categories?.length > 10 && (
        <Pagination
          current={currentPage}
          total={article_categories.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};
