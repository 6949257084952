import { FaSyncAlt } from "react-icons/fa";
import { useSignUp } from "../../utils/CustomFunctions";
import { Layout } from "../../components/LayoutUser/Layout";
import { useParams } from "react-router-dom";

const AddNewPassword = () => {
  const { slug } = useParams();
  const initialState = {
    token: slug || "",
    new_password: "",
    confirm_password: "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};
    if (!values.new_password.trim())
      newErrors.new_password = "This value should not be null.";
    if (values.new_password.trim() && values.new_password.trim().length < 8)
      newErrors.new_password = "The password must be at least 8 characters";
    if (values.new_password.trim() !== values.confirm_password.trim())
      newErrors.confirm_password = "Passwords do not match";
    return newErrors;
  };

  // use custom hook for cahnge password
  const { values, errors, isPending, handleChange, handleSubmit } = useSignUp(
    initialState,
    validateFields,
    `reset-password`
  );

  return (
    <Layout>
      <div className="row mb-5 mt-5">
        <div className="col-12 col-md-6 mx-auto">
          <div className="card setting">
            <div className="card-body">
              <form className="signform" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="new_password" className="form-label">
                    New Password<span>*</span>
                  </label>
                  <input
                    type="password"
                    name="new_password"
                    id="new_password"
                    className="form-control"
                    value={values.new_password}
                    onChange={handleChange}
                  />
                  {errors.new_password && (
                    <small className="text-danger d-block mt-1">
                      {errors.new_password}
                    </small>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="confirm_password" className="form-label">
                    Confirm Password<span>*</span>
                  </label>
                  <input
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    className="form-control"
                    value={values.confirm_password}
                    onChange={handleChange}
                  />
                  {errors.confirm_password && (
                    <small className="text-danger d-block mt-1">
                      {errors.confirm_password}
                    </small>
                  )}
                </div>

                <div className="form-group mt-3 mb-3">
                  <button
                    className="btn btn-dark w-100 rounded-5"
                    disabled={isPending ? "disabled" : ""}
                    type="submit"
                  >
                    {isPending ? (
                      <FaSyncAlt className="spinIcon" />
                    ) : (
                      "Change Password"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddNewPassword;
