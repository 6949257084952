import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filter_events: [],
  all_events: [],
  filters: {
    keyword: "",
    category: "",
    country: "",
    date: {},
    audience: "",
    free_events: 0,
    onlineonly: 0,
    localonly: 0,
    maxPrice: 0,
    minPrice: 0,
    minMaxPrice: [0, 0],
  },
};
const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    loadFilterEvents(state, action) {
      let priceArr = action.payload
        ?.flatMap((curElem) =>
          curElem.tickets?.map((ticket) => parseFloat(ticket.Price))
        )
        ?.filter((price) => price !== null && !isNaN(price));

      let maxPrice = Math.max(...priceArr);
      let minPrice = Math.min(...priceArr);
      state.filter_events = action.payload;
      state.all_events = action.payload;
      state.filters.maxPrice = maxPrice;
      state.filters.minPrice = minPrice;
      state.filters.minMaxPrice = [minPrice, maxPrice];
    },
    addFilters(state, action) {
      const { name, value } = action.payload;
      if (state.filters.audience === value) {
        state.filters[name] = "";
      } else {
        state.filters[name] = value;
      }
    },
    // Filters
    filteredEvents(state) {
      let tempFilterEvent = state.all_events;
      const {
        keyword,
        category,
        country,
        audience,
        minMaxPrice,
        date,
        maxPrice,
        free_events,
        onlineonly,
        localonly,
      } = state.filters;
      if (keyword) {
        tempFilterEvent = tempFilterEvent?.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }

      if (category !== "") {
        tempFilterEvent = tempFilterEvent.filter(
          (curElem) => curElem.category.id === category
        );
      }

      if (country !== "") {
        tempFilterEvent = tempFilterEvent.filter(
          (curElem) => curElem.venue.country === country
        );
      }
      if (onlineonly == 1) {
        tempFilterEvent = tempFilterEvent.filter(
          (curElem) => curElem.event_date_online == onlineonly
        );
      }
      if (localonly == 1) {
        tempFilterEvent = tempFilterEvent.filter(
          (curElem) => curElem.venue.country == "🇺🇸"
        );
      }

      if (audience !== "") {
        tempFilterEvent = tempFilterEvent.filter((curElem) =>
          curElem.audiences.includes(audience.toString())
        );
      }

      if (free_events == 1) {
        tempFilterEvent = tempFilterEvent.filter(
          (curElem) => curElem?.tickets?.[0]?.ticket_free == free_events
        );
      }

      if (maxPrice !== 0) {
        tempFilterEvent = tempFilterEvent.filter((curElem) =>
          curElem.tickets?.some(
            (ticket) =>
              ticket.Price >= minMaxPrice[0] && ticket.Price <= minMaxPrice[1]
          )
        );
      }

      if (date && Object.keys(date).length > 0) {
        tempFilterEvent = tempFilterEvent.filter((curElem) => {
          const start_On = curElem.start_On.split(" ")[0];
          return start_On >= date.start && start_On <= date.end;
        });
      }

      state.filter_events = tempFilterEvent;
    },
    // For Clear All Filters
    clearAllFilters(state) {
      const { maxPrice, minPrice } = state.filters;

      state.filters.keyword = "";
      state.filters.category = "";
      state.filters.country = "";
      state.filters.date = {};
      state.filters.audience = "";
      state.filters.free_events = 0;
      state.filters.onlineonly = 0;
      state.filters.localonly = 0;
      state.filters.minMaxPrice = [minPrice, maxPrice];
      state.filters.maxPrice = maxPrice;
      state.filters.minPrice = minPrice;
    },
  },
});

export const {
  loadFilterEvents,
  setGridView,
  setListView,
  sorting,
  sortingProducts,
  addFilters,
  filteredEvents,
  clearAllFilters,
} = filtersSlice.actions;
export default filtersSlice.reducer;
