import React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { BiCaretDown, BiSolidCompass, BiSolidLandmark } from "react-icons/bi";
import {
  FaCalendarPlus,
  FaChalkboardTeacher,
  FaFilm,
  FaFolderOpen,
  FaHome,
  FaNewspaper,
  FaRocket,
  FaStream,
  FaTheaterMasks,
  FaTicketAlt,
  FaUserTie,
  FaUtensils,
} from "react-icons/fa";
import { AiFillCalendar } from "react-icons/ai";
import { FaCircleQuestion, FaShop } from "react-icons/fa6";
import { BsMusicNoteBeamed } from "react-icons/bs";
import { IoMdFootball } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { IconPickerItem } from "react-fa-icon-picker";
import { addFilters } from "../../../store/slice/Filters";

const Menu = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authUser);
  const { categories } = useSelector((state) => state.AllData);

  const func = (e, name, value) => {
    e.preventDefault();
    pathname === "/en/events"
      ? dispatch(addFilters({ name, value }))
      : navigate("/en/events", {
          state: { name, value },
        });
  };

  const goTo = (e) => {
    e.preventDefault();
    !user?.token
      ? navigate("/en/signin", {
          state: "/en/dashboard/organizer/my-events/add",
        })
      : navigate("/en/dashboard/organizer/my-events/add");
  };

  return (
    <header className="container header">
      <nav className="navbar navbar-expand-lg navbar-light bg-light border-0">
        <div className="container">
          <div className="collapse navbar-collapse" id="main-nav">
            <ul className="navbar-nav nav-fill w-100">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  <FaHome />
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/en/events">
                  <AiFillCalendar />
                  Browse Events
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link ">
                  <FaStream />
                  Explore <BiCaretDown className="downIcon" />
                </a>
                <div className="dropdownMenu">
                  <ul>
                    {categories?.length > 0 &&
                      categories?.map((category, i) => (
                        <li key={i}>
                          <Link
                            className="dropdown-item d-flex align-items-center"
                            to=""
                            onClick={(e) => func(e, "category", category.id)}
                          >
                            {category?.icon && (
                              <IconPickerItem
                                icon={category.icon}
                                size={24}
                                color="#000"
                                className="d-flex"
                              />
                            )}
                            <span>{category.name}</span>
                          </Link>
                        </li>
                      ))}

                    <li>
                      <NavLink className="dropdown-item" to="/en/categories">
                        <FaFolderOpen />
                        All categories
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/en/venues">
                  <BiSolidCompass />
                  Venues
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/en/help-center">
                  <FaCircleQuestion />
                  How It works?
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/en/blogs">
                  <FaNewspaper />
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/en/dashboard/attendee/my-tickets"
                >
                  <FaTicketAlt />
                  My tickets
                </NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="" onClick={goTo}>
                  <FaCalendarPlus />
                  Add my event
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  <FaShop />
                  Feevaa Marketplace
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Menu;
