import { FaMailBulk } from "react-icons/fa";
import { Wrapper } from "./Wrapper";
import { Checkbox, DatePicker, Radio, Slider, Space, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addFilters } from "../../store/slice/Filters";
import SelectBox from "./../global/SelectBox";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { calculateDates } from "../../utils/CustomFunctions";
import dayjs from "dayjs";

const EventSidebar = () => {
  const dispatch = useDispatch();
  const state = useLocation();
  const { countries, categories, audiences, event_setting } = useSelector(
    (state) => state.AllData
  );
  const [filterAudiences, setFilterAudiences] = useState([]);
  const server = process.env.REACT_APP_SERVER;
  const [iskeyword, setIskeyword] = useState({});

  const {
    filters: {
      keyword,
      category,
      country,
      audience,
      maxPrice,
      minMaxPrice,
      minPrice,
    },
  } = useSelector((state) => state.Filters);

  // state
  const [checkbox, setCheckbox] = useState({
    onlineonly: false,
    localonly: false,
    free_events: false,
    date: "",
  });
  // for tdate picker open and close
  const [toggle, setToggle] = useState(false);
  const [isFilter, setIsFilter] = useState({});
  const [isCategory, setIsCategory] = useState({});

  // for date radio buttons
  const handleRadioChange = (e) => {
    const { value } = e.target;
    setCheckbox({ ...checkbox, date: value });
    const dates = calculateDates(value);
    dispatch(addFilters({ name: "date", value: dates }));
  };
  // for date radio buttons
  const handleDateRadioChange = (e) => {
    const { value } = e.target;
    setCheckbox({ ...checkbox, date: value });
    setToggle(!toggle);
  };
  // for date picker radio button
  const handleDateChange = (date, dateString) => {
    dispatch(
      addFilters({
        name: "date",
        value: { start: dateString, end: dateString },
      })
    );
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckbox({ ...checkbox, [name]: checked });
    let free = checked ? 1 : 0;
    dispatch(addFilters({ name, value: free }));
  };
  // Search Filter
  const add_filter2 = (name, value) => {
    dispatch(addFilters({ name, value }));
  };
  const add_filter = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(addFilters({ name, value }));
  };

  useEffect(() => {
    state.state &&
      Object.keys(state.state)?.length == 3 &&
      setIsFilter(state.state);
    state.state &&
      Object.keys(state.state)?.length == 2 &&
      setIsCategory(state.state);
  }, [state.pathname]);

  useEffect(() => {
    if (state.state && Object.keys(state.state)?.length == 2) {
      Object.keys(isCategory).length > 0 &&
        isCategory.name === "category" &&
        dispatch(addFilters(isCategory));
      Object.keys(isCategory).length > 0 &&
        isCategory.name === "category" &&
        setIsCategory({});
    }
  }, [state.state, isCategory]);

  useEffect(() => {
    if (state.state && Object.keys(state.state)?.length === 3) {
      if (Object.keys(isFilter).length > 0) {
        dispatch(addFilters({ name: "category", value: isFilter?.category }));
        dispatch(addFilters({ name: "country", value: isFilter?.country }));

        setCheckbox({ ...checkbox, date: isFilter?.date });
        const dates = calculateDates(isFilter?.date);
        dispatch(addFilters({ name: "date", value: dates }));
      }

      Object.keys(isFilter).length > 0 && setIsFilter({});
    }
  }, [state.state, isFilter]);

  useEffect(() => {
    setFilterAudiences(
      audiences?.filter((audience) => audience.status === "visible")
    );
  }, [audiences]);

  useEffect(() => {
    state.state && setIskeyword(state.state);
  }, [state.pathname]);

  useEffect(() => {
    Object.keys(iskeyword).length > 0 &&
      iskeyword.name === "keyword" &&
      dispatch(addFilters(iskeyword));

    Object.keys(iskeyword).length > 0 &&
      iskeyword.name === "keyword" &&
      setIskeyword({});
  });

  return (
    <Wrapper>
      <div
        className="accordion accordion-flush mb-4"
        id="accordionFlushExample"
      >
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed fw-600"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="true"
              aria-controls="flush-collapseOne"
            >
              Keyword
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingOne"
            data-bs-parent=""
          >
            <div className="accordion-body signform">
              <input
                type="text"
                name="keyword"
                value={keyword}
                className="form-control"
                onChange={add_filter}
              />
            </div>
          </div>
        </div>
        {/* Category */}
        {event_setting?.category === "yes" && (
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed fw-600"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="true"
                aria-controls="flush-collapseTwo"
              >
                Category
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse show"
              aria-labelledby="flush-headingTwo"
              data-bs-parent=""
            >
              <div className="accordion-body">
                <SelectBox
                  name="category"
                  options={categories}
                  val={category}
                  change={add_filter2}
                />
              </div>
            </div>
          </div>
        )}
        {/* location */}
        {event_setting?.location === "yes" && (
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed fw-600"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="true"
                aria-controls="flush-collapseThree"
              >
                Location
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse show"
              aria-labelledby="flush-headingThree"
              data-bs-parent=""
            >
              <div className="accordion-body location-tab">
                <ul>
                  {/* <Checkbox.Group
                  style={{
                    width: "100%",
                  }}
                  onChange={handleCheckboxChange}
                > */}
                  {!checkbox.localonly && (
                    <li>
                      <Checkbox
                        id="check1"
                        className="Checkbox ProfileSettingRadioBtn fw-600"
                        name="onlineonly"
                        checked={checkbox.onlineonly}
                        onChange={handleCheckboxChange}
                      >
                        <label className="form-check-label" htmlFor="check1">
                          Online events only
                        </label>
                      </Checkbox>
                    </li>
                  )}
                  {!checkbox.onlineonly && (
                    <li>
                      <Checkbox
                        id="check2"
                        className="Checkbox ProfileSettingRadioBtn fw-600"
                        name="localonly"
                        checked={checkbox.localonly}
                        onChange={handleCheckboxChange}
                      >
                        <label className="form-check-label" htmlFor="check2">
                          Local events only
                        </label>
                      </Checkbox>
                    </li>
                  )}
                  {/* </Checkbox.Group> */}

                  {!checkbox.onlineonly && checkbox.localonly && (
                    <li>
                      <p className="text-muted ">Events in United States</p>
                    </li>
                  )}
                </ul>
                {!checkbox.onlineonly && !checkbox.localonly && (
                  <div className="mb-4">
                    <label htmlFor="last_name" className="form-label fw-600">
                      Country
                    </label>

                    <SelectBox
                      name="country"
                      options={countries}
                      val={country}
                      change={add_filter2}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* date */}
        {event_setting?.date === "yes" && (
          <div className="accordion-item date-tab">
            <h2 className="accordion-header" id="flush-headingFour">
              <button
                className="accordion-button collapsed fw-600"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="true"
                aria-controls="flush-collapseFour"
              >
                Date
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              className="accordion-collapse collapse show"
              aria-labelledby="flush-headingFour"
              data-bs-parent=""
            >
              <div className="accordion-body">
                <ul>
                  <li>
                    <Radio
                      id="today"
                      className="ProfileSettingRadioBtn fw-600"
                      name="today"
                      checked={checkbox.date === "today"}
                      onChange={handleRadioChange}
                      value="today"
                    >
                      <label className="form-check-label" htmlFor="today">
                        Today
                      </label>
                    </Radio>
                  </li>
                  <li>
                    <Radio
                      id="tomorrow"
                      className="ProfileSettingRadioBtn fw-600"
                      name="tomorrow"
                      checked={checkbox.date === "tomorrow"}
                      value="tomorrow"
                      onChange={handleRadioChange}
                    >
                      <label className="form-check-label" htmlFor="tomorrow">
                        Tomorrow
                      </label>
                    </Radio>
                  </li>
                  <li>
                    <Radio
                      id="thisWeekend"
                      className="ProfileSettingRadioBtn fw-600"
                      name="thisWeekend"
                      checked={checkbox.date === "thisWeekend"}
                      value="thisWeekend"
                      onChange={handleRadioChange}
                    >
                      <label className="form-check-label" htmlFor="thisWeekend">
                        This Weekend
                      </label>
                    </Radio>
                  </li>
                  <li>
                    <Radio
                      id="thisWeek"
                      className="ProfileSettingRadioBtn fw-600"
                      name="thisWeek"
                      checked={checkbox.date === "thisWeek"}
                      value="thisWeek"
                      onChange={handleRadioChange}
                    >
                      <label className="form-check-label" htmlFor="thisWeek">
                        This Week
                      </label>
                    </Radio>
                  </li>
                  <li>
                    <Radio
                      id="nextWeek"
                      className="ProfileSettingRadioBtn fw-600"
                      name="nextWeek"
                      checked={checkbox.date === "nextWeek"}
                      value="nextWeek"
                      onChange={handleRadioChange}
                    >
                      <label className="form-check-label" htmlFor="nextWeek">
                        Next Week
                      </label>
                    </Radio>
                  </li>
                  <li>
                    <Radio
                      id="thisMonth"
                      className="ProfileSettingRadioBtn fw-600"
                      name="thisMonth"
                      checked={checkbox.date === "thisMonth"}
                      value="thisMonth"
                      onChange={handleRadioChange}
                    >
                      <label className="form-check-label" htmlFor="thisMonth">
                        This Month
                      </label>
                    </Radio>
                  </li>
                  <li>
                    <Radio
                      id="nextMonth"
                      className="ProfileSettingRadioBtn fw-600"
                      name="nextMonth"
                      checked={checkbox.date === "nextMonth"}
                      value="nextMonth"
                      onChange={handleRadioChange}
                    >
                      <label className="form-check-label" htmlFor="nextMonth">
                        Next Month
                      </label>
                    </Radio>
                  </li>
                  <li>
                    <Radio
                      id="pickDate"
                      className="ProfileSettingRadioBtn fw-600"
                      name="pickDate"
                      checked={checkbox.date === "pickDate"}
                      value="pickDate"
                      onClick={handleDateRadioChange}
                    >
                      <label className="form-check-label" htmlFor="pickDate">
                        Pick a date
                      </label>
                    </Radio>

                    {checkbox.date === "pickDate" && toggle && (
                      <Space
                        direction="vertical"
                        size={12}
                        onClick={(e) => {
                          // e.stopPropagation();
                          // setToggle(false);
                        }}
                      >
                        <DatePicker
                          id="pickDate"
                          open={toggle}
                          defaultValue={dayjs("2015-06-06", "YYYY-MM-DD")}
                          onChange={(date, dateString) =>
                            handleDateChange(date, dateString)
                          }
                        />
                      </Space>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        {/* Ticket price */}
        {event_setting?.ticket_price === "yes" && (
          <div className="accordion-item ticket-tab">
            <h2 className="accordion-header" id="flush-headingFive">
              <button
                className="accordion-button collapsed fw-600"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="true"
                aria-controls="flush-collapseFive"
              >
                Ticket price
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              className="accordion-collapse collapse show"
              aria-labelledby="flush-headingFive"
              data-bs-parent=""
            >
              <div className="accordion-body">
                <ul>
                  <li>
                    <Checkbox
                      id="ticket-checkbox"
                      className="Checkbox ProfileSettingRadioBtn fw-600"
                      name="free_events"
                      checked={checkbox.free_events}
                      onChange={handleCheckboxChange}
                    >
                      <label
                        className="form-check-label"
                        htmlFor="ticket-checkbox"
                      >
                        Free events only
                      </label>
                    </Checkbox>
                  </li>
                </ul>
                {!checkbox.free_events && (
                  <>
                    <Slider
                      className="mb-4 mt-4"
                      range={{
                        draggableTrack: true,
                      }}
                      defaultValue={[minMaxPrice[0], minMaxPrice[1]]}
                      min={minPrice}
                      max={maxPrice}
                      onAfterChange={(value) =>
                        add_filter2("minMaxPrice", value)
                      }
                    />
                    <div className="row ranger-slider-inputs mb-2 signform">
                      <div className="col-12 col-sm-6">
                        <label
                          htmlFor="pricemin"
                          className="form-label fw-600 "
                        >
                          Min
                        </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                          <input
                            id="pricemin"
                            name="pricemin"
                            type="text"
                            className="form-control range-slider-min-input"
                            value={minMaxPrice[0]}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <label htmlFor="pricemax" className="form-label fw-600">
                          Max
                        </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                          <input
                            id="pricemax"
                            name="pricemax"
                            type="text"
                            className="form-control range-slider-max-input"
                            value={minMaxPrice[1]}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {/* audience */}
        {event_setting?.audience === "yes" && (
          <div className="accordion-item audience-tab">
            <h2 className="accordion-header" id="flush-headingSix">
              <button
                className="accordion-button collapsed fw-600"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSix"
                aria-expanded="true"
                aria-controls="flush-collapseSix"
              >
                Audience
              </button>
            </h2>
            <div
              id="flush-collapseSix"
              className="accordion-collapse collapse show"
              aria-labelledby="flush-headingSix"
              data-bs-parent=""
            >
              <div className="accordion-body">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  {filterAudiences?.map((audienc, i) => (
                    <Tooltip title={audienc.name} key={i}>
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          add_filter2("audience", audienc.id);
                        }}
                        to=""
                        className={`btn ${audience == audienc.id && "active"} ${
                          filterAudiences.length === 1 && "oneChild"
                        }`}
                      >
                        <img
                          style={{ width: "20px" }}
                          src={`${server}/event/audience/${audienc.icon}`}
                          alt={audienc.slug}
                        />
                      </Link>
                    </Tooltip>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <div className="subscribe-tab bg-dark">
          <div className="figure">
            <div className="fig">
              <FaMailBulk className="icon" />
            </div>
            <div className="figcaption">
              <h5 className="text-white m-0">Subscribe to our newsletter</h5>
            </div>
          </div>
          <div className="form-row mt-3">
            <div className="form-group">
              <input
                type="text"
                className="form-control rounded-0"
                placeholder="Email address"
              />
            </div>
            <div className="form-group">
              <button className="btn btn-dark w-100 rounded-0">
                SUBSCRIBE
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </Wrapper>
  );
};

export default EventSidebar;
