import React, { useEffect, useState } from "react";
import { Wrapper } from "./Wrapper";
import { FaAngleRight, FaMailBulk } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const ArticleSidebar = () => {
  const navigate = useNavigate();
  const { articles, article_categories } = useSelector(
    (state) => state.AllData
  );
  const [filters, setFilters] = useState({
    keyword: "",
    tags: "",
  });

  const [artical, setArtical] = useState([]);
  const [uniqueTags, setUniqueTags] = useState([]);

  useEffect(() => {
    const foundArticle =
      (filters.keyword &&
        articles.filter((article) =>
          article.name.toLowerCase().includes(filters.keyword.toLowerCase())
        )) ||
      [];
    setArtical(foundArticle);
  }, [filters]);

  useEffect(() => {
    // Extract unique tags
    const tagsSet = new Set();
    articles.forEach((article) => {
      article.tags.forEach((tag) => {
        tagsSet.add(tag);
      });
    });

    // Convert set to array
    const tagsArray = Array.from(tagsSet);

    // Update state with unique tags
    setUniqueTags(["all", ...tagsArray]);
  }, [articles]);

  return (
    <Wrapper>
      <div
        className="accordion accordion-flush mb-4"
        id="accordionFlushExample"
      >
        {/* search */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed fw-600"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="true"
              aria-controls="flush-collapseOne"
            >
              Search help center
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingOne"
            data-bs-parent=""
          >
            <div className="accordion-body signform">
              <input
                type="text"
                name="keyword"
                value={filters.keyword}
                className="form-control"
                onChange={(e) =>
                  setFilters({ ...filters, keyword: e.target.value })
                }
              />
              <ul className="list list-unstyled mt-3">
                {filters.keyword && (
                  <strong>
                    {artical.length} result(s) found for "{filters.keyword}"
                  </strong>
                )}
                {artical.length > 0 && (
                  <>
                    <hr />
                    {artical.map((article, i) => (
                      <li className="mb-2" key={i}>
                        <span>{article.article_category.name}</span>
                        <FaAngleRight className="me-2 ms-2" />
                        <Link
                          to={`/en/help-center/article/${article.slug}`}
                          className="font-weight-semibold"
                        >
                          {article.name}
                        </Link>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
        {/* category */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button collapsed fw-600"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="true"
              aria-controls="flush-collapseTwo"
            >
              Categories
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingTwo"
            data-bs-parent=""
          >
            <div className="accordion-body">
              <ul className="list list-unstyled">
                {article_categories.length > 0 &&
                  article_categories.map((category, i) => {
                    const foundArticleLength =
                      articles.filter(
                        (article) =>
                          article.article_category.slug === category.slug
                      ).length || [].length;
                    return (
                      <li className="mb-2" key={i}>
                        <Link
                          to={`/en/help-center/${category.slug}`}
                          className="font-weight-semibold"
                        >
                          <FaAngleRight className="me-2" />
                          {category.name}
                          <span className="float-end">
                            <span className="badge bg-dark">
                              {foundArticleLength}
                            </span>
                          </span>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        {/* popular */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingFour">
            <button
              className="accordion-button collapsed fw-600"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour"
              aria-expanded="true"
              aria-controls="flush-collapseFour"
            >
              Popular
            </button>
          </h2>
          <div
            id="flush-collapseFour"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingFour"
            data-bs-parent=""
          >
            <div className="accordion-body">
              {articles.length > 0 &&
                articles.map((article, i) => (
                  <article className="row align-items-center mb-3" key={i}>
                    <div className="col-12">
                      <small className="text-muted">
                        {article.article_category.name}
                      </small>
                      <h6 className="mb-0">
                        <Link
                          to={`/en/help-center/article/${article.slug}`}
                          className="text-1"
                        >
                          {article.name}
                        </Link>
                      </h6>
                    </div>
                  </article>
                ))}
            </div>
          </div>
        </div>
        {/* tags */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              className="accordion-button collapsed fw-600"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="true"
              aria-controls="flush-collapseThree"
            >
              Tags
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingThree"
            data-bs-parent=""
          >
            <div className="accordion-body location-tab">
              {uniqueTags?.length > 0 && (
                <div className="row">
                  <div className="col">
                    {uniqueTags?.map((tag, index) => {
                      return (
                        <Link
                          to=""
                          className={`btn btn-tags btn-sm me-3 mb-3 mt-3 ${
                            filters.tags === tag && "active"
                          }`}
                          key={index}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/en/help-center", { state: tag });
                            setFilters({ ...filters, tags: tag });
                          }}
                        >
                          {tag}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <div className="subscribe-tab bg-dark">
          <div className="figure">
            <div className="fig">
              <FaMailBulk className="icon" />
            </div>
            <div className="figcaption">
              <h5 className="text-white m-0">Subscribe to our newsletter</h5>
            </div>
          </div>
          <div className="form-row mt-3">
            <div className="form-group">
              <input
                type="text"
                className="form-control rounded-0"
                placeholder="Email address"
              />
            </div>
            <div className="form-group">
              <button className="btn btn-dark w-100 rounded-0">
                SUBSCRIBE
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </Wrapper>
  );
};

export default ArticleSidebar;
