import React, { useEffect, useState } from "react";
import {
  FaTerminal,
  FaUserCog,
  FaRegLifeRing,
  FaNewspaper,
  FaStar,
  FaRegFileAlt,
  FaMapMarkerAlt,
  FaFunnelDollar,
  FaUsersCog,
  FaFileInvoiceDollar,
  FaCartArrowDown,
  FaCalendarAlt,
  FaSlidersH,
  FaTachometerAlt,
} from "react-icons/fa";
import { Menu } from "antd";
import "./adminsidebar.scss";
import { Link, useLocation } from "react-router-dom";
function getItem(icon, key, label, children, type, path) {
  return {
    icon,
    key,
    label,
    children,
    type,
    path,
  };
}
// {
//   icon: <FaLanguage />,
//   key: "27.1",
//   label: "Translations",
//   type: "link",
//   path: "/en/dashboard/administrator/translation",
// },
// {
//   label: "Sitemap",
//   key: "151",
//   path: "#/",
// },
const items = [
  {
    icon: <FaTachometerAlt />,
    key: "1",
    label: "Dashboard",
    type: "link",
    path: "/en/dashboard/administrator",
  },
  {
    icon: <FaSlidersH />,
    key: "sub2",
    label: "Settings",
    children: [
      {
        label: "Layout, Parameters and SEO",
        key: "2",
        path: "/en/dashboard/administrator/settings/layout",
      },
      {
        label: "Menus",
        key: "3",
        path: "/en/dashboard/administrator/settings/menus",
      },
      {
        label: "Homepage",
        key: "4",
        path: "/en/dashboard/administrator/settings/homepage-hero",
      },
      {
        label: "Payment and Fees",
        key: "5",
        path: "/en/dashboard/administrator/settings/payment",
      },
      {
        label: "Checkout",
        key: "6",
        path: "/en/dashboard/administrator/settings/checkout",
      },
      {
        label: "Mail Server",
        key: "7",
        path: "/en/dashboard/administrator/settings/mail-server",
      },
      {
        label: "Google Recaptcha",
        key: "8",
        path: "/en/dashboard/administrator/settings/google-recaptcha",
      },
      {
        label: "Google Maps",
        key: "9",
        path: "/en/dashboard/administrator/settings/google-maps",
      },
      {
        label: "Social Login",
        key: "10",
        path: "/en/dashboard/administrator/settings/social-login",
      },
      {
        label: "Event list page",
        key: "11",
        path: "/en/dashboard/administrator/settings/events-list-page",
      },
      {
        label: "Venue page",
        key: "12",
        path: "/en/dashboard/administrator/settings/venue-page",
      },
      {
        label: "Blog settings",
        key: "13",
        path: "/en/dashboard/administrator/settings/blog",
      },
      {
        label: "Newsletter",
        key: "14",
        path: "/en/dashboard/administrator/settings/newsletter",
      },
    ],
  },
  {
    icon: <FaCalendarAlt />,
    key: "sub3",
    label: "Events",
    children: [
      {
        label: "Manage events",
        key: "15",
        path: "/en/dashboard/administrator/manage-events",
      },
      {
        label: "Categories",
        key: "16",
        path: "/en/dashboard/administrator/manage-categories",
      },
      {
        label: "Countries",
        key: "17",
        path: "/en/dashboard/administrator/manage-countries",
      },
      {
        label: "Language",
        key: "18",
        path: "/en/dashboard/administrator/manage-languages",
      },
      {
        label: "Audiences",
        key: "19",
        path: "/en/dashboard/administrator/manage-audiences",
      },
    ],
  },
  {
    icon: <FaCartArrowDown />,
    key: "21",
    label: "Orders",
    type: "link",
    path: "/en/dashboard/administrator/manage-orders",
  },
  {
    icon: <FaFileInvoiceDollar />,
    key: "22",
    label: "Payout Requests",
    type: "link",
    path: "/en/dashboard/administrator/manage-payout-requests",
  },
  {
    icon: <FaFileInvoiceDollar />,
    key: "22.1",
    label: "Payout Methods",
    type: "link",
    path: "/en/dashboard/administrator/manage-payout-methods",
  },
  {
    icon: <FaFunnelDollar />,
    key: "23",
    label: "Reports",
    type: "link",
    path: "/en/dashboard/administrator/reports",
  },
  {
    icon: <FaUsersCog />,
    key: "24",
    label: "Users",
    type: "link",
    path: "/en/dashboard/administrator/manage-users",
  },
  {
    icon: <FaMapMarkerAlt />,
    key: "sub4",
    label: "Venues",
    children: [
      {
        label: "Manage venues",
        key: "25",
        path: "/en/dashboard/administrator/manage-venues",
      },
      {
        label: "Venue types",
        key: "26",
        path: "/en/dashboard/administrator/manage-venues-types",
      },
      {
        label: "Amenities",
        key: "27",
        path: "/en/dashboard/administrator/manage-amenities",
      },
    ],
  },

  {
    icon: <FaTerminal />,
    key: "28",
    label: "Execute Symfony commands",
    type: "link",
    path: "/en/dashboard/administrator/console",
  },
  {
    icon: <FaRegFileAlt />,
    key: "29",
    label: "Pages",
    type: "link",
    path: "/en/dashboard/administrator/manage-pages",
  },
  {
    icon: <FaStar />,
    key: "30",
    label: "Reviews",
    type: "link",
    path: "/en/dashboard/administrator/manage-reviews",
  },
  {
    icon: <FaNewspaper />,
    key: "sub5",
    label: "Blog",
    children: [
      {
        label: "Posts",
        key: "31",
        path: "/en/dashboard/administrator/manage-blog-posts",
      },
      {
        label: "Categories",
        key: "32",
        path: "/en/dashboard/administrator/manage-blog-post-categories",
      },
    ],
  },
  {
    icon: <FaRegLifeRing />,
    key: "sub6",
    label: "Help Center",
    children: [
      {
        label: "Articles",
        key: "33",
        path: "/en/dashboard/administrator/manage-help-center/articles",
      },
      {
        label: "Categories",
        key: "34",
        path: "/en/dashboard/administrator/manage-help-center/categories",
      },
    ],
  },
  {
    icon: <FaUserCog />,
    key: "sub7",
    label: "Account",
    children: [
      {
        label: "Change password",
        key: "35",
        path: "/en/dashboard/administrator/account/change-password",
      },
    ],
  },
];
const AdminSidebar = () => {
  const { pathname } = useLocation();
  // Function to find the selected key
  const findSelectedKey = (items, pathname) => {
    for (const item of items) {
      if (item.children) {
        const childItem = item.children.find(
          (child) => child.path === pathname
        );
        if (childItem) {
          return [item.key, childItem.key];
        }
      } else if (item.path === pathname) {
        return [item.key];
      }
    }
    return [];
  };

  // State to store the open keys and selected keys
  const [openKeys, setOpenKeys] = useState(() => {
    // Initialize with the value from localStorage or default to an empty array
    const storedOpenKeys = localStorage.getItem("openKeys");
    return storedOpenKeys ? JSON.parse(storedOpenKeys) : [];
  });

  const [selectedKeys, setSelectedKeys] = useState(() => {
    const storedSelectedKeys = localStorage.getItem("selectedKeys");
    return storedSelectedKeys ? JSON.parse(storedSelectedKeys) : [];
  });
  // Update the open keys and selected keys when pathname changes
  useEffect(() => {
    const newSelectedKeys = findSelectedKey(items, pathname);
    setOpenKeys(newSelectedKeys.slice(0, -1)); // Exclude the last key from openKeys
    setSelectedKeys(newSelectedKeys);
    // Save the open keys and selected keys to localStorage
    localStorage.setItem(
      "openKeys",
      JSON.stringify(newSelectedKeys.slice(0, -1))
    );
    localStorage.setItem("selectedKeys", JSON.stringify(newSelectedKeys));
  }, [pathname, items]);

  return (
    <div>
      <div className="Sidebar">
        <Menu
          openKeys={openKeys}
          onOpenChange={(newOpenKeys) => {
            setOpenKeys(newOpenKeys);
            // Save the open keys to localStorage
            localStorage.setItem("openKeys", JSON.stringify(newOpenKeys));
          }}
          selectedKeys={selectedKeys}
          mode="inline"
        >
          {items.map((item) =>
            item.children ? (
              <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
                {item.children.map((childItem) => (
                  <Menu.Item key={childItem.key}>
                    <Link to={childItem.path}>{childItem.label}</Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ) : (
              <Menu.Item key={item.key} icon={item.icon}>
                <Link to={item.path}>{item.label}</Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </div>
    </div>
  );
};
export default AdminSidebar;
