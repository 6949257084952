import { Radio } from "antd";
import React from "react";
import Count from "../../../../../count/Count";
import "./homepagehero.scss";
import { useSignUp } from "../../../../../../utils/CustomFunctions";
import { useSelector } from "react-redux";
import { FaSyncAlt } from "react-icons/fa";
import MultiSelectBox from "../../../../../global/MultiSelectBox";
import { FaCircleInfo } from "react-icons/fa6";
import { Link } from "react-router-dom";

const HomepageHero = () => {
  const { home_page_setting, events, organizers } = useSelector(
    (state) => state.admin
  );
  const server = process.env.REACT_APP_SERVER;

  const initialState = {
    home_page: home_page_setting?.home_page || "",

    events: home_page_setting?.events || [],
    organizers: home_page_setting?.organizers || [],
    image: home_page_setting?.image || "",
    title: home_page_setting?.title || "",
    paragraph: home_page_setting?.paragraph || "",

    number_event: home_page_setting?.number_event || "",
    number_category: home_page_setting?.number_category || "",
    number_post: home_page_setting?.number_post || "",
    action: home_page_setting?.ticket_price || "yes",
    search: home_page_setting?.search || "yes",
    home_page_search: home_page_setting?.home_page_search || "yes",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    // if (!values.number) newErrors.number = "This value should not be null.";

    return newErrors;
  };

  const {
    values,
    setValues,
    errors,
    isPending,
    handleChange,
    handleMultiSelectBox,
    singleImage,
    handleSubmit,
  } = useSignUp(
    initialState,
    validateFields,
    `admin/add_home_page_setting`,
    "home_page_setting"
  );

  return (
    <>
      <div className="card box">
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form onSubmit={handleSubmit} className="signform mt-3 ">
              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    What to show in the homepage hero ?
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="home_page"
                      checked={values.home_page === "hide"}
                      onChange={handleChange}
                      value="hide"
                    >
                      Hide slider
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn "
                      name="home_page"
                      checked={values.home_page === "event"}
                      onChange={handleChange}
                      value="event"
                    >
                      Events slider
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn "
                      name="home_page"
                      checked={values.home_page === "organizers"}
                      onChange={handleChange}
                      value="organizers"
                    >
                      Organizers slider
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn "
                      name="home_page"
                      checked={values.home_page === "custom"}
                      onChange={handleChange}
                      value="custom"
                    >
                      Custom hero
                    </Radio>
                  </div>
                </div>
              </div>

              {values.home_page === "event" && (
                <div className="mb-3">
                  <label htmlFor="subtitles" className="form-label fw-600 m-0">
                    Events
                  </label>
                  <MultiSelectBox
                    name="events"
                    options={events}
                    val={values}
                    change={handleMultiSelectBox}
                  />
                </div>
              )}
              {values.home_page === "organizers" && (
                <div className="mb-3">
                  <label htmlFor="subtitles" className="form-label fw-600 m-0">
                    Organizers
                  </label>
                  <div className="ProfleSettingOptionDescBox">
                    <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                    <span className="ProfleSettingOptionDesc">
                      Make sure to select organizers who have added a cover
                      photo
                    </span>
                  </div>
                  <MultiSelectBox
                    name="organizers"
                    options={organizers}
                    val={values}
                    change={handleMultiSelectBox}
                  />
                </div>
              )}
              {values.home_page === "custom" && (
                <div className="mb-3">
                  <div id="custom">
                    <fieldset className="form-group mb-3">
                      <label htmlFor="image" className="form-label fw-600">
                        Custom hero background image
                      </label>{" "}
                      <div className="vich-image">
                        <div className="custom-file">
                          <input
                            type="file"
                            id="image"
                            name="image"
                            className="form-control"
                            onChange={singleImage}
                          />
                        </div>
                        {/* <div className="form-group">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              id="homepage_hero_settings_customBackgroundFile_delete"
                              name="homepage_hero_settings[customBackgroundFile][delete]"
                              className="form-check-input"
                              value="1"
                            />
                            <label
                              className="form-check-label"
                              for="homepage_hero_settings_customBackgroundFile_delete"
                            >
                              Delete
                            </label>
                          </div>
                        </div> */}

                        {values.image && (
                          <Link
                            to={`${server}/home/${values.image}`}
                            target="_blank"
                          >
                            <img
                              src={`${server}/home/${values.image}`}
                              alt=""
                              className="img-thumbnail img-sm mt-1"
                            />
                          </Link>
                        )}
                      </div>
                    </fieldset>
                    <fieldset className="form-group mb-3">
                      {/* <label className="form-label fw-600">
                        Custom hero title and paragraph translation
                      </label> */}

                      <div className="a2lix_translations">
                        {/* <ul
                          className="a2lix_translationsLocales nav nav-tabs"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              href="#homepage_hero_settings_translations_en_a2lix_translations-fields"
                              className="nav-link active"
                              data-toggle="tab"
                              role="tab"
                            >
                              En [Default]{" "}
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              href="#homepage_hero_settings_translations_fr_a2lix_translations-fields"
                              className="nav-link "
                              data-toggle="tab"
                              role="tab"
                            >
                              Fr
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              href="#homepage_hero_settings_translations_es_a2lix_translations-fields"
                              className="nav-link "
                              data-toggle="tab"
                              role="tab"
                            >
                              Es
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              href="#homepage_hero_settings_translations_ar_a2lix_translations-fields"
                              className="nav-link "
                              data-toggle="tab"
                              role="tab"
                            >
                              Ar
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              href="#homepage_hero_settings_translations_de_a2lix_translations-fields"
                              className="nav-link "
                              data-toggle="tab"
                              role="tab"
                            >
                              De
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              href="#homepage_hero_settings_translations_pt_a2lix_translations-fields"
                              className="nav-link "
                              data-toggle="tab"
                              role="tab"
                            >
                              Pt
                            </a>
                          </li>
                        </ul> */}

                        <div className="a2lix_translationsFields tab-content">
                          <div
                            id="homepage_hero_settings_translations_en_a2lix_translations-fields"
                            className="tab-pane show active "
                            role="tabpanel"
                          >
                            <div id="homepage_hero_settings_translations_en">
                              <div className="form-group mb-3">
                                <label
                                  htmlFor="title"
                                  className="form-label fw-600"
                                >
                                  Title
                                </label>
                                <input
                                  type="text"
                                  id="title"
                                  name="title"
                                  className="form-control"
                                  value={values.title}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="paragraph"
                                  className="form-label fw-600"
                                >
                                  Paragraph
                                </label>
                                <input
                                  type="text"
                                  id="paragraph"
                                  name="paragraph"
                                  maxLength="500"
                                  className="form-control"
                                  value={values.paragraph}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>

                          {/* <div
                            id="homepage_hero_settings_translations_fr_a2lix_translations-fields"
                            className="tab-pane  "
                            role="tabpanel"
                          >
                            <div id="homepage_hero_settings_translations_fr">
                              <div className="form-group">
                                <label for="homepage_hero_settings_translations_fr_title">
                                  Titre
                                </label>
                                <input
                                  type="text"
                                  id="homepage_hero_settings_translations_fr_title"
                                  name="homepage_hero_settings[translations][fr][title]"
                                  maxlength="100"
                                  className="form-control"
                                  value={values.api_key}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group">
                                <label for="homepage_hero_settings_translations_fr_paragraph">
                                  Paragraphe
                                </label>
                                <input
                                  type="text"
                                  id="homepage_hero_settings_translations_fr_paragraph"
                                  name="homepage_hero_settings[translations][fr][paragraph]"
                                  maxlength="500"
                                  className="form-control"
                                  value={values.api_key}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            id="homepage_hero_settings_translations_es_a2lix_translations-fields"
                            className="tab-pane  "
                            role="tabpanel"
                          >
                            <div id="homepage_hero_settings_translations_es">
                              <div className="form-group">
                                <label for="homepage_hero_settings_translations_es_title">
                                  Título
                                </label>
                                <input
                                  type="text"
                                  id="homepage_hero_settings_translations_es_title"
                                  name="homepage_hero_settings[translations][es][title]"
                                  maxlength="100"
                                  className="form-control"
                                  value="Eventic"
                                />
                              </div>
                              <div className="form-group">
                                <label for="homepage_hero_settings_translations_es_paragraph">
                                  Párrafo
                                </label>
                                <input
                                  type="text"
                                  id="homepage_hero_settings_translations_es_paragraph"
                                  name="homepage_hero_settings[translations][es][paragraph]"
                                  maxlength="500"
                                  className="form-control"
                                  value="Gestión de eventos en línea y venta de entradas"
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            id="homepage_hero_settings_translations_ar_a2lix_translations-fields"
                            className="tab-pane  "
                            role="tabpanel"
                          >
                            <div id="homepage_hero_settings_translations_ar">
                              <div className="form-group">
                                <label for="homepage_hero_settings_translations_ar_title">
                                  عنوان
                                </label>
                                <input
                                  type="text"
                                  id="homepage_hero_settings_translations_ar_title"
                                  name="homepage_hero_settings[translations][ar][title]"
                                  maxlength="100"
                                  className="form-control"
                                  value="Eventic"
                                />
                              </div>
                              <div className="form-group">
                                <label for="homepage_hero_settings_translations_ar_paragraph">
                                  فقرة
                                </label>
                                <input
                                  type="text"
                                  id="homepage_hero_settings_translations_ar_paragraph"
                                  name="homepage_hero_settings[translations][ar][paragraph]"
                                  maxlength="500"
                                  className="form-control"
                                  value="إدارة الأحداث عبر الإنترنت ومبيعات التذاكر"
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            id="homepage_hero_settings_translations_de_a2lix_translations-fields"
                            className="tab-pane  "
                            role="tabpanel"
                          >
                            <div id="homepage_hero_settings_translations_de">
                              <div className="form-group">
                                <label for="homepage_hero_settings_translations_de_title">
                                  Titel
                                </label>
                                <input
                                  type="text"
                                  id="homepage_hero_settings_translations_de_title"
                                  name="homepage_hero_settings[translations][de][title]"
                                  maxlength="100"
                                  className="form-control"
                                  value="Eventic"
                                />
                              </div>
                              <div className="form-group">
                                <label for="homepage_hero_settings_translations_de_paragraph">
                                  Absatz
                                </label>
                                <input
                                  type="text"
                                  id="homepage_hero_settings_translations_de_paragraph"
                                  name="homepage_hero_settings[translations][de][paragraph]"
                                  maxlength="500"
                                  className="form-control"
                                  value="Online-Eventmanagement und Ticketverkauf"
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            id="homepage_hero_settings_translations_pt_a2lix_translations-fields"
                            className="tab-pane  "
                            role="tabpanel"
                          >
                            <div id="homepage_hero_settings_translations_pt">
                              <div className="form-group">
                                <label for="homepage_hero_settings_translations_pt_title">
                                  Título
                                </label>
                                <input
                                  type="text"
                                  id="homepage_hero_settings_translations_pt_title"
                                  name="homepage_hero_settings[translations][pt][title]"
                                  maxlength="100"
                                  className="form-control"
                                  value="Eventic"
                                />
                              </div>
                              <div className="form-group">
                                <label for="homepage_hero_settings_translations_pt_paragraph">
                                  Parágrafo
                                </label>
                                <input
                                  type="text"
                                  id="homepage_hero_settings_translations_pt_paragraph"
                                  name="homepage_hero_settings[translations][pt][paragraph]"
                                  maxlength="500"
                                  className="form-control"
                                  value="GestÃ£o de eventos online e venda de ingressos"
                                />
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="form-group mb-3">
                      <div id="homepage_hero_settings_show_search_box">
                        <div className="ProfileSettingOptionsContainer">
                          <div className="ProfileSettingOptionsBox">
                            <span className="ProfileSettingOptionTitle">
                              Show the homepage hero seach box
                              <span style={{ color: "red" }}>*</span>
                            </span>
                            <div className="ProfileSettingRadioBtns">
                              <Radio
                                className="ProfileSettingRadioBtn"
                                name="home_page_search"
                                checked={values.home_page_search === "no"}
                                onChange={handleChange}
                                value="no"
                              >
                                No
                              </Radio>
                              <Radio
                                className="ProfileSettingRadioBtn "
                                name="home_page_search"
                                checked={values.home_page_search === "yes"}
                                onChange={handleChange}
                                value="yes"
                              >
                                Yes
                              </Radio>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              )}

              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Show the search box
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="search"
                      checked={values.search === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="search"
                      checked={values.search === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="Facebook_app_id" className="form-label fw-600">
                  Number of events per page
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Count
                  name="number_event"
                  value={values.number_event}
                  state="values"
                  setState={setValues}
                />
                {errors.number_event && (
                  <small className="text-danger d-block mt-1">
                    {errors.number_event}
                  </small>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="Facebook_app_id" className="form-label fw-600">
                  Number of featured categories to show
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Count
                  name="number_category"
                  value={values.number_category}
                  state="values"
                  setState={setValues}
                />
                {errors.number_category && (
                  <small className="text-danger d-block mt-1">
                    {errors.number_category}
                  </small>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="Facebook_app_id" className="form-label fw-600">
                  Number of blog posts to show
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Count
                  name="number_post"
                  value={values.number_post}
                  state="values"
                  setState={setValues}
                />
                {errors.number_post && (
                  <small className="text-danger d-block mt-1">
                    {errors.number_post}
                  </small>
                )}
              </div>
              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Show the call to action block
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="action"
                      checked={values.action === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn "
                      name="action"
                      checked={values.action === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="form-group mt-4 mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  disabled={isPending ? "disabled" : ""}
                  type="submit"
                >
                  {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomepageHero;
