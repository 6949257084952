import { Modal } from "antd";
import React from "react";
import { FaRegWindowClose } from "react-icons/fa";

const Model = ({ openModel, setOpenModel, order }) => {
  return (
    <Modal
      // title="Vertically centered modal dialog"
      centered
      open={openModel}
      onCancel={() => setOpenModel(false)}
      className="Model"
    >
      <div className="ModelBox">
        <div className="ModelHeader">
          <span className="ModelBoxBuyTicketText">User information</span>
        </div>

        {order && Object.keys(order).length > 0 && (
          <div className="jconfirm-content" id="jconfirm-box71368">
            <div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-hover table-sm">
                      <thead>
                        <tr>
                          <th colSpan="2">
                            <i className="fas fa-file-alt fa-fw text-muted"></i>{" "}
                            Account information
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="30%">First name</td>
                          <td>
                            {
                              order?.event?.check_out?.billing_reference
                                ?.first_name
                            }
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">Last name</td>
                          <td>
                            {
                              order?.event?.check_out?.billing_reference
                                ?.last_name
                            }
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">Email</td>
                          <td>
                            {order?.event?.check_out?.billing_reference?.email}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-hover table-sm">
                      <thead>
                        <tr>
                          <th colSpan="2">
                            <i className="far fa-user fa-fw text-muted"></i>{" "}
                            Attendee information
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="30%">Bought tickets</td>
                          <td>{order?.event?.check_out?.ticket_quantity}</td>
                        </tr>
                        {/* <tr>
                          <td width="30%">Reviews</td>
                          <td>2</td>
                        </tr> */}
                        {/* <tr>
                          <td width="30%">Favorites</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td width="30%">Following</td>
                          <td>0</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
        <button
          className="btn btn-outline-dark rounded-5 d-inline-flex align-items-center gap-2"
          onClick={() => setOpenModel(false)}
        >
          <FaRegWindowClose className="ModelBoxTicketPurchaseTitleIcon" />
          close
        </button>
      </div>
    </Modal>
  );
};

export default Model;
