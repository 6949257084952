import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../../Organizer/Dashboard/Header/DashboardHeader";
import {
  FaCalendarDays,
  FaCartArrowDown,
  FaUsers,
  FaTicket,
  FaUsersGear,
} from "react-icons/fa6";
import { AiFillSetting } from "react-icons/ai";
import Card from "../../../../Organizer/Dashboard/Card/Card";
import "./dashboard.scss";
import Breadcrumb from "../../../../global/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { adminFetchData } from "../../../../../store/slice/Admin";
import EventsTable from "../events/Table";
import { FaSyncAlt } from "react-icons/fa";
import UsersTable from "../users/Table";
import { Table } from "../orders/Table";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);
  const {
    events,
    orders,
    users,
    event_summary,
    order_summary,
    user_summary,
    loading,
  } = useSelector((state) => state.admin);

  const [limitedEvents, setlimitedEvents] = useState([]);
  const [limitedOrders, setlimitedOrders] = useState([]);
  const [limitedUsers, setlimitedUsers] = useState([]);

  useEffect(() => {
    dispatch(
      adminFetchData({
        endPoint: "admin/event_summary",
        state: "event_summary",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/order_summary",
        state: "order_summary",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/user_summary",
        state: "user_summary",
        token: user.token,
      })
    );
  }, []);

  useEffect(() => {
    const limitedEvent = events?.slice(0, 4);
    const limitedOrder = orders?.slice(0, 3);
    const limitedUser = users?.slice(0, 3);
    setlimitedEvents(limitedEvent);
    setlimitedOrders(limitedOrder);
    setlimitedUsers(limitedUser);
  }, [events, orders, users]);

  return (
    <>
      <Breadcrumb page={"Dashboard"} />
      <div className="OraganizerDashboardSection mt-2">
        <div className="mb-5">
          <DashboardHeader
            icon={FaCalendarDays}
            icon2={AiFillSetting}
            text1={"Event Summary"}
            text2={"My Events"}
            link={"/en/dashboard/administrator/manage-events"}
          />

          <div className="OrganizerDashboardEventsCardSection mb-4">
            <Card
              count={event_summary?.added}
              text={"Events Added"}
              icon={FaCalendarDays}
            />
            <Card
              count={event_summary?.published}
              text={"Published events"}
              icon={FaCalendarDays}
            />
            <Card
              count={event_summary?.upcoming}
              text={"Upcoming events"}
              icon={FaCalendarDays}
            />
            <Card
              count={event_summary?.dates}
              text={"Events dates"}
              icon={FaCalendarDays}
            />
          </div>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <FaSyncAlt className="spinIcon" />
            </div>
          ) : (
            <EventsTable events={limitedEvents} />
          )}
        </div>

        <div className="mb-5">
          <DashboardHeader
            icon={FaCartArrowDown}
            icon2={AiFillSetting}
            text1={"Orders Summary"}
            text2={"Manage Orders"}
            link={"/en/dashboard/administrator/manage-orders"}
          />

          <div className="OrganizerDashboardEventsCardSection mb-4">
            <Card
              count={order_summary?.placed}
              text={"Order Placed"}
              icon={FaCartArrowDown}
            />
            <Card
              count={order_summary?.paid}
              text={"Paid Orders"}
              icon={FaCartArrowDown}
            />
            <Card
              count={order_summary?.awaiting}
              text={"Awaiting payment"}
              icon={FaCartArrowDown}
            />
            <Card
              count={order_summary?.sold}
              text={"Tickets Sold"}
              icon={FaTicket}
            />
          </div>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <FaSyncAlt className="spinIcon" />
            </div>
          ) : (
            <Table orders={limitedOrders} />
          )}
        </div>

        <div>
          <DashboardHeader
            icon={FaUsersGear}
            icon2={AiFillSetting}
            text1={"Users summary"}
            text2={"Manage Users"}
            link={"/en/dashboard/administrator/manage-users"}
          />

          <div className="OrganizerDashboardEventsCardSection mb-4">
            <Card
              count={user_summary?.attendee}
              text={"Active attendees"}
              icon={FaCartArrowDown}
            />
            <Card
              count={user_summary?.organizer}
              text={"Active organizers"}
              icon={FaCartArrowDown}
            />
            <Card
              count={user_summary?.scanner}
              text={"Scanners"}
              icon={FaCartArrowDown}
            />
            <Card
              count={user_summary?.sale}
              text={"Points of sale"}
              icon={FaTicket}
            />
          </div>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <FaSyncAlt className="spinIcon" />
            </div>
          ) : (
            <UsersTable users={limitedUsers} />
          )}
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
