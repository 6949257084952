import { FaCalendarPlus, FaInfoCircle, FaSearch, FaUser } from "react-icons/fa";
import { BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Alert from "../../global/alert/Alert";
import { IconPickerItem } from "react-fa-icon-picker";

const Card = ({ article_categories }) => {
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No help center category found",
  };

  return (
    <>
      {article_categories?.length > 0 ? (
        article_categories?.map((article_category, i) => (
          <div className="col-md-6 mb-3" key={i}>
            <article className="box h-100 padding-y-lg">
              <figure className="itembox text-center">
                <span className="mt-2 icon-wrap rounded icon-lg bg-dark">
                  {article_category.icon && (
                    <IconPickerItem
                      icon={article_category?.icon}
                      size={24}
                      color="white"
                    />
                  )}
                </span>
                <figcaption className="text-wrap">
                  <h5 className="title mt-3">{article_category.name}</h5>
                </figcaption>
              </figure>

              <hr />
              <hr />

              <Link
                to={`/en/help-center/${article_category.slug}`}
                className="btn btn-outline-dark btn-block"
              >
                See more articles
                <BsChevronRight className="ms-3" />
              </Link>
            </article>
          </div>
        ))
      ) : (
        <Alert {...msg} />
      )}
    </>
  );
};

export default Card;
