import { useDispatch, useSelector } from "react-redux";
import SelectBox from "../global/SelectBox";
import { sorting } from "../../store/slice/BlogFilters";

const Top = ({ data, state }) => {
  const dispatch = useDispatch();
  // Sorting select Box options
  const options = [
    { id: "date-desc", name: "Creation date (desc)" },
    { id: "date-asc", name: "Creation date (asc)" },
  ];
  const { sorting_value } = useSelector((state) => state.blogFilters);

  // Search Filter
  const add_filter3 = (name, value) => {
    dispatch(sorting({ name, value }));
  };

  return (
    <section className="topbar mt-4 mb-4 bg-gray sortedbydate">
      <div className="row pt-3 pb-3 ps-3 pe-3 align-items-center">
        <div className="col-sm-12 col-lg-6 mb-3 mb-lg-0 text-center text-lg-start">
          <span className="text-muted">
            {data.length} {state}(s) found
          </span>
        </div>
        <div className="col-sm-12 col-lg-6 d-flex justify-content-lg-end justify-content-center">
          <form className="text-start d-flex align-items-center">
            <label htmlFor="sort" className="">
              Sort by{" "}
            </label>
            <SelectBox
              name="sorting_value"
              options={options}
              val={sorting_value}
              change={add_filter3}
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Top;
