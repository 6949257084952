import axios from "axios";
import { useEffect, useState } from "react";
import { FaInfoCircle, FaSyncAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchData } from "../../../../../store/slice/Organizer";
import Alert from "../../../../global/alert/Alert";

const AddScanner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const api = process.env.REACT_APP_SERVER_API;
  const { user } = useSelector((state) => state.authUser);
  const { scanners } = useSelector((state) => state.organizer);
  const disaptch = useDispatch();

  const [isPanding, setPanding] = useState(false);
  const [errors, setErrors] = useState({});
  const [newScanner, setNewScanner] = useState({
    name: "",
    user_name: "",
    password: "",
    password_confirmation: "",
  });

  // validate fields
  const validateFields = () => {
    let newErrors = {};

    if (!newScanner.name.trim()) newErrors.name = "Please Enter Name";
    if (!newScanner.user_name.trim())
      newErrors.user_name = "Please Enter Username";

    if (!newScanner.password && !id)
      newErrors.password = "Please Enter Password";
    if (!id && newScanner.password && newScanner.password.length < 6)
      newErrors.password = "The password must be at least 6 characters";
    if (newScanner.password !== newScanner.password_confirmation && !id)
      newErrors.password_confirmation = "Passwords do not match";

    if (id && newScanner.password && newScanner.password.length < 6)
      newErrors.password = "The password must be at least 6 characters";
    if (
      (newScanner.password || newScanner.password_confirmation) &&
      newScanner.password !== newScanner.password_confirmation &&
      id
    )
      newErrors.password_confirmation = "Passwords do not match";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };
  //
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewScanner({ ...newScanner, [name]: value });
  };
  // for adding a new scanner to the  database
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) return;

    try {
      setPanding(true);
      if (!id) {
        const { data } = await axios.post(
          `${api}/organizer/add_scanner`,
          newScanner,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (data.status === 201) {
          toast.success(data.message);
          setNewScanner({
            name: "",
            user_name: "",
            password: "",
            password_confirmation: "",
          });
        }
      } else {
        const { data } = await axios.post(
          `${api}/organizer/update_scanner`,
          {
            id: newScanner.id,
            name: newScanner.name,
            user_name: newScanner.user_name,
            password: newScanner.password,
            password_confirmation: newScanner.password_confirmation,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (data.status === 200) {
          toast.success(data.message);
        }
      }
      navigate("/en/dashboard/organizer/my-scanners");
      disaptch(
        fetchData({
          getData: "organizer/scanners",
          token: user.token,
          state: "scanners",
        })
      );
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setPanding(false);
    }
  };

  // for get single data for edit
  useEffect(() => {
    id && setNewScanner(scanners.find((ele) => ele.id == id));
  }, [id]);

  // this is alert maessage
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "Leave the password empty to keep the old one",
  };
  return (
    <>
      <div className="card setting">
        <div className="card-body">
          {id && <Alert {...msg} />}
          <form action="" className="signform" onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="mb-1">
                <label htmlFor="name" className="form-label fw-600">
                  Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className={`form-control ${errors.name && "is-invalid"}`}
                  value={newScanner?.name}
                  onChange={handleChange}
                />
              </div>
              {errors.name && (
                <small className="text-danger d-block">{errors.name}</small>
              )}
            </div>
            <div className="mb-4">
              <div className="mb-1">
                <label htmlFor="user_name" className="form-label fw-600">
                  Username<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="user_name"
                  id="user_name"
                  className={`form-control ${errors.user_name && "is-invalid"}`}
                  value={newScanner?.user_name}
                  onChange={handleChange}
                />
              </div>
              {errors.user_name && (
                <small className="text-danger d-block">
                  {errors.user_name}
                </small>
              )}
            </div>
            <div className="mb-4">
              <div className="mb-1">
                <label htmlFor="password" className="form-label fw-600">
                  Password<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className={`form-control ${errors.password && "is-invalid"}`}
                  value={newScanner?.password}
                  onChange={handleChange}
                />
              </div>
              {errors.password && (
                <small className="text-danger d-block">{errors.password}</small>
              )}
            </div>
            <div className="mb-4">
              <div className="mb-1">
                <label
                  htmlFor="password_confirmation"
                  className="form-label fw-600"
                >
                  Repeat password<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="password"
                  name="password_confirmation"
                  id="password_confirmation"
                  className={`form-control ${
                    errors.password_confirmation && "is-invalid"
                  }`}
                  value={newScanner?.password_confirmation}
                  onChange={handleChange}
                />
              </div>
              {errors.password_confirmation && (
                <small className="text-danger d-block">
                  {errors.password_confirmation}
                </small>
              )}
            </div>

            <div className="form-group mt-3 mb-3">
              <button
                className="btn btn-dark rounded-5"
                disabled={isPanding ? "disabled" : ""}
                type="submit"
              >
                {isPanding ? <FaSyncAlt className="spinIcon" /> : "save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddScanner;
