import React from "react";
import Breadcrumb from "../../components/global/Breadcrumb";
import { Layout } from "../../components/LayoutUser/Layout";
import Privacypolicy from "../../components/Privacypolicy";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Breadcrumb page={"Privacy policy"} />
      <Privacypolicy />
    </Layout>
  );
};

export default PrivacyPolicy;
