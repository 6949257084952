import React from "react";
import Header from "./../header/Header";
import Footer from "./../footer/Footer";
import { useState } from "react";

export const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};
