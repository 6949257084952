import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import { FaRegStar, FaStar, FaStarHalfAlt, FaSyncAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout } from "../../../../LayoutUser/Layout";
import AverageRating from "../../../../reviews/AverageRating";
import Review from "../../../../reviews/Review";
import { toast } from "react-toastify";
import axios from "axios";

const AddReviews = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const server = process.env.REACT_APP_SERVER;
  const [errors, setErrors] = useState({});
  const [isPending, setPending] = useState(false);
  const { user } = useSelector((state) => state.authUser);
  const api = process.env.REACT_APP_SERVER_API;
  const { events } = useSelector((state) => state.AllData);
  const [event, setEvent] = useState({});

  const [review, setReview] = useState({
    event_id: "",
    rating: "",
    headline: "",
    details: "",
  });

  // validate fields
  const validateFields = () => {
    let newErrors = {};

    if (!review?.rating) newErrors.rating = "This value should not be null.";
    if (!review?.headline.trim())
      newErrors.headline = "This value should not be null.";
    if (!review?.details.trim())
      newErrors.details = "This value should not be null.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReview({ ...review, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;
    try {
      setPending(true);

      const { data } = await axios.post(`${api}/attendee/add_review`, review, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (data.status === 200 || data.status === 201) {
        toast.success(data.message);
        navigate(`/en/event/${event.slug}`);
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
    } finally {
      setPending(false);
    }
  };

  // for get event id
  useEffect(() => {
    const event = events.find((event) => event.id == slug);
    setEvent(event);
    setReview({ rating: "", headline: "", details: "", event_id: event.id });
  }, [slug]);

  return (
    <Layout>
      <section className="section-content my-5 bg-white review add">
        <div className="container">
          <div className="row mb-5 event-scorecard">
            <div className="col-12 col-lg-6">
              <img
                className="img-100-100"
                src={`${server}/event/image/${event?.main_event_image}`}
              />
              <div
                className="ms-1 d-inline-block"
                style={{ verticalAlign: "top" }}
              >
                <h3>
                  <Link to={`/en/event/${event?.slug}`}>{event?.name}</Link>
                </h3>
                {/* <div className="rating-wrap">
                  <ul className=" rating-stars">
                    <li>
                      <FaStar />
                    </li>
                    <li>
                      <FaStarHalfAlt />
                    </li>
                    <li>
                      <FaRegStar />
                    </li>
                  </ul>
                  <div className="label-rating">4.5 out of 5 stars</div>
                </div> */}
                <Review rating={event?.average_rating} />
              </div>
            </div>

            <div className="col-12 col-lg-6">
              {/* <div className="row rating-bar">
                <div className="col-2">5 stars</div>
                <div className="col-9">
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "25%" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="col-1">50%</div>
              </div>
              <div className="row rating-bar">
                <div className="col-2">4 stars</div>
                <div className="col-9">
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "25%" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="col-1">50%</div>
              </div>
              <div className="row rating-bar">
                <div className="col-2">3 stars</div>
                <div className="col-9">
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "25%" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="col-1">50%</div>
              </div>
              <div className="row rating-bar">
                <div className="col-2">2 stars</div>
                <div className="col-9">
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "25%" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="col-1">50%</div>
              </div>
              <div className="row rating-bar">
                <div className="col-2">1 stars</div>
                <div className="col-9">
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "25%" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="col-1">50%</div>
              </div> */}
              <AverageRating reviews={event?.reviews} />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card box">
                <div className="card-body">
                  <form className="signform" onSubmit={handleSubmit}>
                    <div id="review">
                      <div className="mb-4">
                        <label htmlFor="rating" className="form-label fw-600">
                          Your rating (out of 5 stars)
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <div className="ProfileSettingRadioBtns">
                          <Radio
                            className="ProfileSettingRadioBtn fw-600"
                            name="rating"
                            id="rating"
                            checked={review?.rating === "5"}
                            onChange={handleChange}
                            value="5"
                          >
                            5 stars
                          </Radio>
                          <Radio
                            className="ProfileSettingRadioBtn fw-600"
                            name="rating"
                            checked={review?.rating === "4"}
                            value="4"
                            onChange={handleChange}
                          >
                            4 stars
                          </Radio>
                          <Radio
                            className="ProfileSettingRadioBtn fw-600"
                            name="rating"
                            checked={review?.rating === "3"}
                            value="3"
                            onChange={handleChange}
                          >
                            3 stars
                          </Radio>
                          <Radio
                            className="ProfileSettingRadioBtn fw-600"
                            name="rating"
                            checked={review?.rating === "2"}
                            value="2"
                            onChange={handleChange}
                          >
                            2 stars
                          </Radio>
                          <Radio
                            className="ProfileSettingRadioBtn fw-600"
                            name="rating"
                            checked={review?.rating === "1"}
                            value="1"
                            onChange={handleChange}
                          >
                            1 stars
                          </Radio>
                        </div>
                        {errors.rating && (
                          <small className="text-danger mt-1">
                            {errors.rating}
                          </small>
                        )}
                      </div>

                      <div className="mb-4">
                        <label htmlFor="headline" className="form-label fw-600">
                          Your review headline(out of 5 stars)
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="headline"
                          name="headline"
                          className="form-control"
                          value={review?.headline}
                          onChange={handleChange}
                        />
                        {errors.headline && (
                          <small className="text-danger mt-1">
                            {errors.headline}
                          </small>
                        )}
                      </div>
                      <div className="mb-4">
                        <label htmlFor="details" className="form-label fw-600 ">
                          Let the other attendee know more details about your
                          experience(out of 5 stars)
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          id="details"
                          name="details"
                          rows="10"
                          className="form-control "
                          onChange={handleChange}
                          value={review?.details}
                        ></textarea>
                        {errors.details && (
                          <small className="text-danger mt-1">
                            {errors.details}
                          </small>
                        )}
                      </div>
                      <div className="">
                        <button
                          className="btn btn-dark rounded-5"
                          disabled={isPending ? "disabled" : ""}
                          type="submit"
                        >
                          {isPending ? (
                            <FaSyncAlt className="spinIcon" />
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AddReviews;
