import styled from "styled-components";

export const WrapperAddInput = styled.div`
  .wrap {
    background-color: #f1f3f7;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    flex-wrap: wrap;
    color: #8898aa;
    border: 1px solid #f1f3f7;
    border-radius: 0.25rem;
    font-size: 0.805rem;

    .inputLi {
      input {
        border: 0;
      }
      input:focus {
        background-color: #f1f3f7;
        outline: 0;
        border: 0;
        box-shadow: none;
      }
    }
    .tags {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      background-color: #1a1a1d;
      padding: 8px;
      color: white;
      margin-right: 5px;
      span svg {
        color: white !important;
        font-size: 0.805rem;
        cursor: pointer;
      }
    }
  }
`;
