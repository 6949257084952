import React from "react";
import { FaRegUser } from "react-icons/fa";

const User = ({ review }) => {
  return (
    <div className="mt-3 mb-2">
      <span className="avatar">
        <FaRegUser />
      </span>
      <h6 className="ms-2 d-inline-block mb-0">
        {review.user.first_name} {review.user.last_name}
      </h6>
    </div>
  );
};

export default User;
