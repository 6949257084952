import React from "react";
import "./card.scss";
import { Link, useNavigate } from "react-router-dom";

export default function Card({ data }) {
  const server = process.env.REACT_APP_SERVER;
  const navigate = useNavigate();

  const func = (e, name, value) => {
    e.preventDefault();
    navigate("/en/events", {
      state: { name, value },
    });
  };
  return (
    <>
      {data &&
        data?.map((data, indx) => (
          <div className="col-sm-12 col-md-4 col-lg-3 mb-4" key={indx}>
            <Link
              to=""
              onClick={(e) => func(e, "category", data.id)}
              // className="card-hover"
            >
              {/* <div className="card featuredCategoriesCard">
                {data.image ? (
                  <img
                    className="card-img-top"
                    src={`${server}/event/category/image/${data.image}`}
                    alt=""
                  />
                ) : (
                  <img
                    className="card-img-top"
                    src="/./images/no.png"
                    // className="img-thumbnail img-50-50"
                    alt=""
                  />
                )}

                <h4 className="card-text ">{data.name}</h4>
              </div> */}

              <div className="card-banner mb-3">
                <div
                  className="card-body zoom-in img-lazy-load b-loaded"
                  style={{
                    height: "250px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundImage: `url(${
                      data?.image
                        ? `${server}/event/category/image/${data?.image}`
                        : "/images/no.png"
                    })`, // Use backticks for dynamic URL
                  }}
                ></div>
                <div className="text-bottom">
                  <h4 className="title">{data.name}</h4>
                </div>
              </div>
            </Link>
          </div>
        ))}
    </>
  );
}
