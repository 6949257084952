// PrivateRoute.js
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Layout } from "./../LayoutUser/Layout";
import Breadcrumb from "../global/Breadcrumb";
import { FaBan } from "react-icons/fa";
import Alert from "./../global/alert/Alert";

const OrganizerRoute = ({ children }) => {
  const { user } = useSelector((state) => state.authUser);
  const isAuthenticated = Object.keys(user || {}).length === 0;
  const isOrganizer = user?.role?.some((role) => role.name === "organizer");

  const isUser = user?.role?.some((role) => role.name === "attendee");
  const msg = {
    icon: <FaBan className="me-3" />,
    type: "alert-danger",
    text: `Access is denied. You may not have the appropriate permissions to access this resource. You are logged in as: ${
      isUser ? "Attendee" : "Administrator"
    }`,
  };

  if (isAuthenticated) return <Navigate to="/en/signin" replace />;

  return (
    <>
      {isOrganizer ? (
        children
      ) : (
        <Layout>
          <Breadcrumb page="Access denied" />
          <div className="container mt-4" style={{ height: "40vh" }}>
            <Alert {...msg} />
          </div>
        </Layout>
      )}
    </>
  );
};

export default OrganizerRoute;
