import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  all_blogs: [],
  blog_comments: [],
  filter_blogs: [],
  filter_blog_comments: [],
  sorting_value: "",
  filters: {
    keyword: "",
    category: "",
    tags: "",
  },
};

const blogFiltersSlice = createSlice({
  name: "blogFilters",
  initialState,
  reducers: {
    loadFilterBlog(state, action) {
      state.all_blogs = action.payload;
      state.filter_blogs = action.payload;
    },
    loadFilterBlogComments(state, action) {
      state.blog_comments = action.payload;
      state.filter_blog_comments = action.payload;
    },
    addBlogFilters(state, action) {
      const { name, value } = action.payload;
      state.filters[name] = value;
    },
    sorting(state, action) {
      const { name, value } = action.payload;
      state[name] = value;
    },
    sorted(state, action) {
      const { filter_blogs, sorting_value } = state;
      const sorting = (a, b) => {
        let dateA = new Date(a.created_at);
        let dateB = new Date(b.created_at);

        if (sorting_value === "date-asc") {
          return dateA - dateB;
        }
        if (sorting_value === "date-desc" || sorting_value === "") {
          return dateB - dateA;
        }
      };
      const newSortData = filter_blogs.sort(sorting);
      state.filter_blogs = newSortData;
    },
    sortedb(state, action) {
      const { blog_comments, sorting_value } = state;
      const sorting = (a, b) => {
        let dateA = new Date(a.created_at);
        let dateB = new Date(b.created_at);

        if (sorting_value === "date-asc") {
          return dateA - dateB;
        }
        if (sorting_value === "date-desc" || sorting_value === "") {
          return dateB - dateA;
        }
      };
      const newSortData = blog_comments?.sort(sorting);
      state.filter_blog_comments = newSortData;
    },
    // Filters
    filteredBlogs(state) {
      let tempFilterBlogs = state.all_blogs;
      const { keyword, category, tags } = state.filters;

      if (keyword) {
        tempFilterBlogs = tempFilterBlogs.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }

      if (category !== "") {
        tempFilterBlogs = tempFilterBlogs.filter(
          (curElem) => curElem.category.id === category
        );
      }

      if (tags !== "all" && tags !== "") {
        tempFilterBlogs = tempFilterBlogs.filter((curElem) =>
          curElem?.tags?.includes(tags)
        );
      }

      state.filter_blogs = tempFilterBlogs;
    },
    // For Clear All Filters
    clearAllFilters(state) {
      state.filters.keyword = "";
      state.filters.category = "";
      state.filters.tags = "";
    },
  },
});

export const {
  loadFilterBlog,
  addBlogFilters,
  filteredBlogs,
  sorting,
  sorted,
  clearAllFilters,
  sortedb,
  loadFilterBlogComments,
} = blogFiltersSlice.actions;
export default blogFiltersSlice.reducer;
