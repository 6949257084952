import React, { useState } from "react";
import {
  FaCalendarPlus,
  FaCaretUp,
  FaRegUser,
  FaSearch,
  FaSignInAlt,
  FaSignOutAlt,
  FaTachometerAlt,
  FaUserAlt,
  FaUserPlus,
} from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BiCaretDown, BiMenu } from "react-icons/bi";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../../../store/slice/User";
import axios from "axios";
import { toast } from "react-toastify";
import OrganizerSidebar from "../../Admin/dashboard/organizer/organizerSidebar/OrganizerSidebar";
import AttendeeSidebar from "../../Admin/dashboard/attendee/AttendeeSidebar";
import AdminSidebar from "../../Admin/dashboard/administrator/AdminSidebar/AdminSidebar";
import CartMenu from "../CartMenu";
import SearchDropdown from "./SearchDropdown";
import { clearCart } from "../../../store/slice/Cart";

const TopBar = () => {
  const api = process.env.REACT_APP_SERVER_API;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const server = process.env.REACT_APP_SERVER;
  const { user } = useSelector((state) => state.authUser);
  const { events, layout_settings } = useSelector((state) => state.AllData);
  const [searchResults, setSearchResults] = useState([]);
  // logout
  const logout = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(`${api}/logout`, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (data.status === 200) {
        toast.success(data.message);
        dispatch(setLogout());
        dispatch(clearCart());
        navigate("/en/signin");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    if (value.trim()) {
      const searchResults = events?.filter((curElem) =>
        curElem.name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(searchResults);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <section className="container-fluid topSection">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-5 col-lg-3 order-0 order-lg-0 header-logo">
            <div className="brand-wrap">
              <NavLink to="/">
                {layout_settings?.logo ? (
                  <img
                    src={`${server}/layout/logo/${layout_settings?.logo}`}
                    className="logo img-fluid"
                    alt="feevaa"
                  />
                ) : (
                  <img
                    src="/./images/logo.png"
                    className="logo img-fluid"
                    alt="feevaa"
                  />
                )}
              </NavLink>
            </div>
          </div>
          <div className="col-12 col-lg-5 order-2 order-lg-1 mb-3 mt-3 mb-lg-0 mt-lg-0 header-search">
            <div className="SearchBox">
              <input
                type="text"
                name="search_events"
                className="form-control HeaderSeacthInput"
                placeholder="Search for events"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                // value=""
                onChange={handleSearch}
              />
              <FaSearch className="searchIcon" />
              <SearchDropdown data={searchResults} />
            </div>
          </div>
          <div className="col-7 col-lg-4 order-1 order-lg-2 header-action">
            <div className="widgets-wrap d-flex align-items-center justify-content-end gap-1">
              <div className="widget-header ">
                <CartMenu />
              </div>
              {user && Object.keys(user).length > 0 ? (
                <>
                  <div className="widget-header ">
                    {/* hide in lg - mobile */}
                    <div className="signup d-flex align-items-center d-lg-none align-items-center gap-1">
                      <Link
                        to=""
                        onClick={(e) => e.preventDefault()}
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasWithBackdrop"
                        aria-controls="offcanvasWithBackdrop"
                      >
                        {user?.role[0]?.name === "organizer" &&
                        user?.organizer_logo ? (
                          <img
                            className="img-thumbnail"
                            src={`${server}/organizer/logo/${user?.organizer_logo}`}
                            alt=""
                          />
                        ) : user?.role[0]?.name === "attendee" &&
                          user?.image ? (
                          <img
                            className="img-thumbnail"
                            src={`${server}/user/profile/${user.image}`}
                            alt=""
                          />
                        ) : (
                          <div className="icon-wrap bg-dark text-white">
                            <FaRegUser className="signupicon" />
                          </div>
                        )}
                      </Link>
                      <div
                        className="offcanvas offcanvas-start"
                        // tabIndex="-1"
                        id="offcanvasWithBackdrop"
                        aria-labelledby="offcanvasWithBackdropLabel"
                      >
                        <div className="offcanvas-header">
                          <div className="d-flex align-items-center gap-1">
                            {user?.role[0]?.name === "organizer" &&
                            user?.organizer_logo ? (
                              <img
                                className="img-thumbnail"
                                src={`${server}/organizer/logo/${user?.organizer_logo}`}
                                alt=""
                              />
                            ) : user?.role[0]?.name === "attendee" &&
                              user?.image ? (
                              <img
                                className="img-thumbnail"
                                src={`${server}/user/profile/${user.image}`}
                                alt=""
                              />
                            ) : (
                              <div className="icon-wrap bg-dark text-white">
                                <FaRegUser className="signupicon" />
                              </div>
                            )}
                            <span className="">
                              {user?.user_name || user?.organize_name}{" "}
                            </span>
                          </div>

                          <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="offcanvas-body p-0 pb-2">
                          {user?.role[0]?.name === "attendee" ? (
                            <AttendeeSidebar />
                          ) : user?.role[0]?.name === "organizer" ? (
                            <OrganizerSidebar />
                          ) : (
                            <AdminSidebar />
                          )}

                          <ul className="ps-4">
                            <li className="ps-1">
                              <a
                                href=""
                                onClick={logout}
                                className="d-flex align-items-center"
                              >
                                <FaSignOutAlt className="me-2" />
                                Sign out
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* hide in lg down */}
                    <div className="signup d-lg-flex d-none align-items-center gap-1">
                      {user?.role[0]?.name === "organizer" &&
                      user?.organizer_logo ? (
                        <img
                          className="img-thumbnail"
                          src={`${server}/organizer/logo/${user?.organizer_logo}`}
                          alt=""
                        />
                      ) : user?.role[0]?.name === "attendee" && user?.image ? (
                        <img
                          className="img-thumbnail"
                          src={`${server}/user/profile/${user.image}`}
                          alt=""
                        />
                      ) : (
                        <div className="icon-wrap bg-dark text-white">
                          <FaRegUser className="signupicon" />
                        </div>
                      )}
                      <span className="">
                        {user?.user_name || user?.organize_name}{" "}
                      </span>
                      <BiCaretDown className="downIcon" />
                    </div>
                    {/* dropdown */}
                    <div className="signUpDropDown profileLink d-lg-block d-none">
                      <FaCaretUp className="upIcon" />
                      <ul>
                        <li>
                          <Link
                            to={`/en/dashboard/${
                              user?.role[0]?.name === "attendee"
                                ? "attendee/my-tickets"
                                : user?.role[0]?.name === "organizer"
                                ? "organizer"
                                : "administrator"
                            }`}
                            className="d-flex align-items-center"
                          >
                            <FaTachometerAlt className="userIcon me-1" />
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <a
                            href=""
                            onClick={logout}
                            className="d-flex align-items-center"
                          >
                            <FaSignOutAlt className="calendarIcon me-1" />
                            Sign out
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="widget-header">
                    <Link to="/en/signin">
                      <div className="icon-wrap bg-dark text-white d-lg-none d-xl-none">
                        <FaSignInAlt className="signinicon" title="Sign in" />
                      </div>
                      <span className="d-none d-lg-block">Sign in</span>
                    </Link>
                  </div>
                  <div className="widget-header ">
                    <div className="signup d-flex align-items-center gap-1">
                      <div className="icon-wrap bg-dark text-white d-lg-none d-xl-none">
                        <FaUserPlus className="signupicon" title="Sign up" />
                      </div>
                      <span className="d-none d-lg-block">Sign up</span>
                      <BiCaretDown className="downIcon" />
                    </div>
                    {/* dropdown */}
                    <div className="signUpDropDown">
                      {/* <FaCaretUp className="upIcon" /> */}
                      <ul>
                        <li>
                          <FaUserAlt className="userIcon" />
                          <Link to="/en/signup/attendee">Attendee</Link>
                        </li>
                        <li>
                          <FaCalendarPlus className="calendarIcon" />
                          <Link to="/en/signup/organizer">Organizer</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}

              <div className="widget-header d-lg-none align-self-center">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#main-nav"
                  aria-controls="navbarToggleExternalContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <BiMenu className="togglebtn" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBar;
