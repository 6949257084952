import React from "react";
import { Layout } from "../components/LayoutUser/Layout";
import PaymentDetailsPDF from "../components/Admin/dashboard/pdf/PaymentDetailsPDF";

const PaymentSuccess = () => {
  return (
    <Layout>
      <PaymentDetailsPDF />
    </Layout>
  );
};

export default PaymentSuccess;
