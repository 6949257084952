import { Dropdown, Pagination, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaChevronRight,
  FaEllipsisV,
  FaFileAlt,
  FaFileInvoice,
  FaInfoCircle,
  FaRegUser,
  FaTicketAlt,
  FaTrashRestoreAlt,
  FaWindowClose,
} from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import Alert from "../../../../global/alert/Alert";
import { formatDate, useDelete } from "../../../../../utils/CustomFunctions";
import Model from "./Model";
import { MdDelete, MdOutlineMailOutline } from "react-icons/md";
import CancelModel from "./CancelModel";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PaymentDetailsPDF2 from "../../pdf/PaymentDetailsPDF2";

export const Table = ({ orders }) => {
  const server = process.env.REACT_APP_SERVER;
  const api = process.env.REACT_APP_SERVER_API;
  const { user } = useSelector((state) => state.authUser);
  const [printPDFData, setPrintPDFData] = useState(null);
  const [openModel, setOpenModel] = useState(false);
  const [openModel2, setOpenModel2] = useState(false);
  const [modelOrder, setModelOrder] = useState({});

  const [modelCancelOrder, setModelCancelOrder] = useState({
    reference: "",
    reason: "",
  });
  // this is alert maessage
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No orders found",
  };

  const handleModelEvent = (e, id) => {
    e.preventDefault();
    setModelOrder(orders.find((event) => event.id === id));
    setOpenModel(true);
  };

  const handleOderCancel = (e, reference) => {
    e.preventDefault();
    setModelCancelOrder({ ...modelCancelOrder, reference });
    setOpenModel2(true);
  };

  const { isLoading, handleDelete } = useDelete(
    "admin",
    "orders",
    "orders",
    "order_summary"
  );

  const printTckets = async (e, id) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(`${api}/organizer/print_ticket/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (data.status === 200) {
        setPrintPDFData(data.data);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  // order?.payment === "completed" && {
  //   label: (
  //     <Link to="" className="d-flex align-items-center">
  //       <MdOutlineMailOutline className="me-1" />
  //       Resend confirmation email
  //     </Link>
  //   ),
  //   key: "1",
  // },

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = orders?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      {printPDFData && <PaymentDetailsPDF2 data={printPDFData} />}

      <Model
        openModel={openModel}
        setOpenModel={setOpenModel}
        order={modelOrder}
      />
      <CancelModel
        openModel={openModel2}
        setOpenModel={setOpenModel2}
        order={modelCancelOrder}
        setorder={setModelCancelOrder}
      />
      <div className="row organizer-event">
        <div className="col-12">
          {!orders || !orders.length > 0 ? (
            <Alert {...msg} />
          ) : (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter">
                  <thead>
                    <tr>
                      <th className="text-center">Reference</th>
                      <th>Organizer / Event / Date / Ticket</th>
                      <th style={{ width: "13%" }}>Attendee / POS</th>
                      <th>Order date </th>
                      <th>Status</th>
                      <th className="text-center">
                        <AiTwotoneSetting />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((order) => {
                      const items = [
                        order?.payment === "completed" && {
                          label: (
                            <Link
                              to=""
                              onClick={(e) => printTckets(e, order?.id)}
                              className="d-flex align-items-center"
                            >
                              <FaTicketAlt className="me-1" />
                              Print tickets
                            </Link>
                          ),
                          key: "0",
                        },
                        order?.payment === "completed" && {
                          label: (
                            <Link
                              to={`/en/dashboard/administrator/manage-orders/${order?.reference}`}
                              className="d-flex align-items-center"
                            >
                              <FaFileAlt className="me-1" />
                              Payment details
                            </Link>
                          ),
                          key: "2",
                        },
                        {
                          label: (
                            <Link
                              to={`/en/dashboard/administrator/manage-orders/${order?.reference}`}
                              className="d-flex align-items-center"
                            >
                              <FaFileInvoice className="me-1" />
                              Details
                            </Link>
                          ),
                          key: "3",
                        },

                        order?.payment !== "cancel" && {
                          label: (
                            <Link
                              to=""
                              onClick={(e) =>
                                handleOderCancel(e, order.reference)
                              }
                              className="d-flex align-items-center"
                            >
                              <FaWindowClose className="me-1" />
                              Cancel
                            </Link>
                          ),
                          key: "4",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(e, order?.id, "order_delete", "")
                              }
                            >
                              {order.deleted_at !== null ? (
                                <>
                                  <FaTrashRestoreAlt className="me-1" /> Restore
                                </>
                              ) : (
                                <>
                                  <IoCloseSharp className="me-1" />
                                  Delete
                                </>
                              )}
                            </Link>
                          ),
                          key: "5",
                        },
                        order.deleted_at && {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(
                                  e,
                                  order?.id,
                                  "order_delete_permanent",
                                  "delete_permanent"
                                )
                              }
                            >
                              <MdDelete className="me-1" />
                              Delete permanently
                            </Link>
                          ),
                          key: "6",
                        },
                      ];
                      return (
                        <tr key={order.id}>
                          <td className="text-center text-muted text-sm">
                            <Link to={order?.reference}>
                              {order?.reference}
                            </Link>
                          </td>
                          <td className="text-sm">
                            <ul className="p-0" style={{ listStyle: "circle" }}>
                              <li>
                                <Link
                                  to={`/en/organizer/${order?.event?.user?.slug}`}
                                >
                                  {order?.event?.user?.organize_name}
                                </Link>
                                <FaChevronRight />
                                <Link to={`/en/event/${order?.event?.slug}`}>
                                  {order?.event?.name}
                                </Link>
                                <FaChevronRight />
                                {
                                  formatDate(new Date(order?.event?.start_On))
                                    .fullDate
                                }
                                <FaChevronRight />
                                {order?.event?.tickets?.[0]?.ticket_name}
                                <br />
                                <small>
                                  <strong>
                                    {order?.ticket_quantity} ticket
                                  </strong>
                                </small>
                              </li>
                            </ul>
                          </td>
                          <td>
                            <Tooltip title="User information">
                              <Link
                                to=""
                                onClick={(e) => handleModelEvent(e, order.id)}
                              >
                                <span className="avatar me-1">
                                  <FaRegUser />
                                </span>
                                {order?.billing_reference?.first_name}{" "}
                                {order?.billing_reference?.last_name}
                              </Link>
                            </Tooltip>
                          </td>
                          <td>{formatDate(new Date(order?.date)).fullDate}</td>

                          <td>
                            {order?.payment === "pending" ? (
                              <span className="badge badge-warning ">
                                Awaiting payment
                              </span>
                            ) : order?.payment === "completed" ? (
                              <span className="badge badge-success ">Paid</span>
                            ) : (
                              <span className="badge badge-danger">
                                Canceled
                              </span>
                            )}
                            {order.deleted_at && (
                              <>
                                <br />
                                <br />
                                <span className="badge badge-danger d-inline-flex align-items-center">
                                  <IoCloseSharp className="me-1" />
                                  Deleted
                                </span>
                              </>
                            )}
                          </td>
                          <td className="text-center">
                            <Dropdown
                              menu={{
                                items,
                              }}
                              trigger={["click"]}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                  <FaEllipsisV />
                                </Space>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {orders?.length > 10 && (
        <Pagination
          current={currentPage}
          total={orders.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};
