import React, { useEffect } from "react";
import { FaSyncAlt } from "react-icons/fa";
import { Table } from "./Table";
import { useDispatch, useSelector } from "react-redux";
import Filters from "../../organizer/payouts/Filters";
import { adminFetchData } from "../../../../../store/slice/Admin";

const ManagePayoutRequests = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);
  const { payout_requests, events, organizers, loading } = useSelector(
    (state) => state.admin
  );
  const { filter_data } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    dispatch(
      adminFetchData({
        endPoint: "admin/payout_method_requests",
        state: "payout_requests",
        token: user.token,
      })
    );
  }, []);

  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event payoutRequests ">
        <Filters
          payout_request={payout_requests}
          events={events}
          organizers={organizers}
        />
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table payout_request={filter_data.payout_request} />
      )}
    </>
  );
};

export default ManagePayoutRequests;
