import React from "react";

import "./card.scss";

export default function Card({ text, icon: IconComponent, count }) {
  return (
    <>
      <div className="DashboardCard">
        {IconComponent && <IconComponent className="DashboardCardIcon" />}
        <div className="DashboardCardContent">
          <span className="DashboardCartContentDate">{count}</span>
          <span className="DashboardCartContentText">{text}</span>
        </div>
      </div>
    </>
  );
}
