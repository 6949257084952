import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import "./venue.scss";
import SelectBox from "../../global/SelectBox";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Venue() {
  const navigate = useNavigate();
  const { countries, categories } = useSelector((state) => state.AllData);
  const [filter, setFilter] = useState({
    category: "",
    country: "",
    date: "",
  });
  const add_filter = (name, value) => {
    setFilter({ ...filter, [name]: value });
    // dispatch(addFilters({ name, value }));
  };
  const addFilter = (e) => {
    e.preventDefault();
    navigate("/en/events", { state: filter });
  };

  const dates = [
    {
      id: "today",
      name: "Today",
    },
    {
      id: "tomorrow",
      name: "Tomorrow",
    },
    {
      id: "thisWeekend",
      name: "This Weekend",
    },
    {
      id: "thisWeek",
      name: "This Week",
    },
    {
      id: "nextWeek",
      name: "Next Week",
    },
    {
      id: "thisMonth",
      name: "This Month",
    },
    {
      id: "nextMonth",
      name: "Next Month",
    },
  ];

  return (
    <>
      <div className="VenueSection">
        <div className="container row m-auto pt-5">
          <div className="col-lg-4 col-md-6">
            <label htmlFor="category" className="form-label fw-600">
              Looking For
            </label>
            <SelectBox
              name="category"
              options={categories}
              val={filter.category}
              change={add_filter}
            />
          </div>

          <div className="col-lg-4 col-md-6">
            <label htmlFor="country" className="form-label fw-600">
              In
            </label>
            <SelectBox
              name="country"
              options={countries}
              val={filter.country}
              change={add_filter}
            />
          </div>
          {/* date */}
          <div className="col-lg-4 col-md-6">
            <label htmlFor="date" className="form-label fw-600">
              When
            </label>
            <SelectBox
              name="date"
              options={dates}
              val={filter.date}
              change={add_filter}
            />
          </div>
        </div>

        <button className="btn btn-dark rounded-5 mt-5" onClick={addFilter}>
          <FaSearch className="me-1" />
          <span>Search</span>
        </button>
      </div>
    </>
  );
}
