import React from "react";
import { FaPlus } from "react-icons/fa6";
import "./payoutmethodcard.scss";
import { Link } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { useDelete } from "../../../../../utils/CustomFunctions";

export default function PayoutMethodsCard({
  name,
  img,
  url,
  data,
  delurl,
  editurl,
}) {
  const { isLoading, handleDelete } = useDelete("organizer", name, name);

  return (
    <>
      <div className="PayoutMethodCard">
        <div className="PayoutMethodContainer">
          <img className="PayoutMethodImg" src={img} alt="" />
          {data && Object.keys(data)?.length > 0 ? (
            <span className="badge badge-success">Information provided</span>
          ) : (
            <span className="badge badge-warning">
              Information not Provided
            </span>
          )}
        </div>

        {data && Object.keys(data).length > 0 ? (
          <span className="d-flex align-items-center justify-content-center gap-2">
            <Link
              to={`${name}/${data?.id}/edit`}
              className="btn btn-dark rounded-5 d-flex align-items-center gap-1"
            >
              <IoCloseSharp />
              <span>Edit</span>
            </Link>
            <Link
              to=""
              onClick={(e) => handleDelete(e, data?.id, delurl, "")}
              className="btn btn-dark rounded-5 d-flex align-items-center gap-1"
            >
              <IoCloseSharp />
              <span>unSET</span>
            </Link>
          </span>
        ) : (
          <Link
            to={`add/${url}`}
            className="btn btn-dark rounded-5 d-flex align-items-center gap-1"
          >
            <FaPlus />
            <span>SET</span>
          </Link>
        )}
      </div>
    </>
  );
}
