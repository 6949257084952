import React, { useState } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { Layout } from "../../components/LayoutUser/Layout";
import { FaSyncAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const api = process.env.REACT_APP_SERVER_API;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isPending, setPending] = useState(false);

  // validate fields
  const validateFields = () => {
    let newErrors = {};
    if (!email.trim()) newErrors.email = "This value should not be null.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;
    try {
      setPending(true);

      const { data } = await axios.post(`${api}/forget_password`, { email });
      if (data.status === 200) {
        toast.success(data.message);
        navigate("/en/resetting/check-email");
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
    } finally {
      setPending(false);
    }
  };

  return (
    <Layout>
      <div className="container mb-5 mt-5 pt-3 resetPassword">
        <div className="row">
          <div className="col-sm-12">
            <div className="card p-4">
              <div className="card-body">
                <div className="col-12 col-md-6 mx-auto">
                  <h1 className="text-center mb-3">Reset your password</h1>
                  <form onSubmit={handleSubmit} className="signform" novalidate>
                    <div className="mb-3">
                      <div className="input-group ">
                        <span className="input-group-text">
                          <HiOutlineMail />
                        </span>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Username or email address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      {errors.email && (
                        <small className="text-danger mt-1">
                          {errors.email}
                        </small>
                      )}
                    </div>

                    <div className="form-group mt-3 mb-3">
                      <button
                        className="btn btn-dark w-100 rounded-5"
                        disabled={isPending ? "disabled" : ""}
                        type="submit"
                      >
                        {isPending ? (
                          <FaSyncAlt className="spinIcon" />
                        ) : (
                          "Reset password"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
