import React from "react";
import { Layout } from "../components/LayoutUser/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaHome, FaInbox } from "react-icons/fa";

const ConfirmEmail = () => {
  const { state } = useLocation();

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-sm-12">
            <div className="card box pt-3 pb-3">
              <div className="card-body pt-5 pb-5">
                <div className="col-12 col-md-6 mx-auto text-center">
                  {!state || state !== "ConfirmEmail" ? (
                    <>
                      {" "}
                      <p>
                        <FaInbox
                          className="text-success"
                          style={{ fontSize: "8rem" }}
                        />
                      </p>
                      <h5 className="card-title pb-2">
                        Access Denied! Please Sign Up to continue.
                      </h5>
                      <div className="d-flex gap-2 justify-content-center">
                        <Link
                          className="text-bg-dark rounded-3 px-3 py-2"
                          to="/en/signup/attendee"
                        >
                          SignUp as Attendee
                        </Link>
                        <Link
                          className="text-bg-dark rounded-3 px-3 py-2"
                          to="/en/signup/organizer"
                        >
                          Sign Up Organizer
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <p className="text-center">
                        <FaInbox
                          className="text-success"
                          style={{ fontSize: "8rem" }}
                        />
                      </p>
                      <h5 className="card-title text-center pb-2">
                        Verify Email
                      </h5>
                      <p className="card-text text-center">
                        Check your email to verify account.
                      </p>
                      <Link
                        to="/"
                        className="text-bg-dark rounded-3 px-3 py-2 mt-1 d-inline-flex align-items-center justify-content-center gap-1"
                      >
                        <FaHome />
                        Home
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ConfirmEmail;
