import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate, useDelete } from "../../../../../utils/CustomFunctions";
import { IoCloseSharp } from "react-icons/io5";
import {
  FaMoneyCheckAlt,
  FaTrashRestoreAlt,
  FaWindowClose,
} from "react-icons/fa";
import ApproveModel from "./ApproveModel";
import CancelModel from "./CancelModel";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";

const ManagePayoutRequestDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const api = process.env.REACT_APP_SERVER_API;
  const { user } = useSelector((state) => state.authUser);
  const { payout_requests } = useSelector((state) => state.admin);
  const [request, setRequest] = useState({});
  const [openModel2, setOpenModel2] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [modelApprove, setModelApprove] = useState("");
  const [modelCancelRequest, setModelCancelRequest] = useState({
    id: "",
    reason: "",
  });

  useEffect(() => {
    slug &&
      setRequest(
        payout_requests.find((request) => request?.reference === slug)
      );
  }, [slug, payout_requests]);

  const handleOderApprove = (e, id) => {
    e.preventDefault();
    setModelApprove(id);
    setOpenModel2(true);
  };
  const handleOderCancel = (e, id) => {
    e.preventDefault();
    setModelCancelRequest({ ...modelCancelRequest, id });
    setOpenModel(true);
  };

  const { isLoading, handleDelete } = useDelete(
    "admin",
    "payout_method_requests",
    "payout_requests"
  );

  const handleDeletePermanent = async (e, id, deleteEndpoint) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(`${api}/admin/${deleteEndpoint}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (data.status === 200) {
        toast.success(data.message);
        navigate("/en/dashboard/administrator/manage-payout-requests");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <>
      <ApproveModel
        openModel={openModel2}
        setOpenModel={setOpenModel2}
        id={modelApprove}
      />
      <CancelModel
        openModel={openModel}
        setOpenModel={setOpenModel}
        request={modelCancelRequest}
        setrequest={setModelCancelRequest}
      />
      <div className="card box">
        <div className="card-body">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <b>Reference</b>
              <h5 className="mt-1">{request?.reference}</h5>
            </li>
            <li className="list-group-item">
              <b>Requested at</b>
              <h5 className="mt-1">
                {formatDate(new Date(request?.request_date)).fullDate}
              </h5>
            </li>
            {/* <li className="list-group-item">
              <b>Processed at</b>
              <h5 className="mt-1">
                {formatDate(new Date(request?.event?.start_On)).fullDate}
              </h5>
            </li> */}
            <li className="list-group-item">
              <b>Status</b>
              <h5 className="mt-1">
                {request?.status === "pending" && (
                  <span className="badge badge-warning ">Pending</span>
                )}
                {request?.status === "cancel" && (
                  <span className="badge badge-danger ">Canceled</span>
                )}
                {request?.status === "approve" && (
                  <span className="badge badge-success ">Approved</span>
                )}
              </h5>
            </li>

            <li className="list-group-item">
              <b>Organizer</b>
              <h5 className="mt-1">{request?.user?.organize_name}</h5>
            </li>
            <li className="list-group-item">
              <b>Event</b>
              <h5 className="mt-1">
                {request?.event?.name} -{" "}
                <span className="text-uppercase">
                  {request?.event?.venue?.city} {request?.event?.venue?.state}
                </span>
              </h5>
            </li>
            <li className="list-group-item">
              <b>Date</b>
              <h5 className="mt-1">
                {formatDate(new Date(request?.event?.start_On)).fullDate}
              </h5>
            </li>
            <li className="list-group-item">
              <b>Venue</b>
              <h5 className="mt-1">
                {request?.event?.venue?.city}:{" "}
                {request?.event?.venue?.postal_code}{" "}
                {request?.event?.venue?.street_address},{" "}
                {request?.event?.venue?.state}, {request?.event?.venue?.country}
              </h5>
            </li>
            <li className="list-group-item">
              <b className="mr-3">Tickets sold</b>
              {/* <a
                href="/en/dashboard/administrator/manage-events/resemblingruth-2nd-annual-conference/event-dates/784b10e94e/statistics"
                target="_blank"
              >
                <i className="fas fa-chart-line"></i> View detailed statistics
              </a> */}
              <h5 className="mt-1">{request?.ticket_quantity}</h5>
            </li>
            <li className="list-group-item">
              <b>Payout method</b>
              <h5 className="mt-1">
                {request?.payout_method === "stripe" ? (
                  <img
                    src="/./images/strip.svg"
                    className="img-thumbnail img-100-100"
                  />
                ) : (
                  <img
                    src="/./images/paypal.svg"
                    className="img-thumbnail img-100-100"
                  />
                )}
              </h5>
            </li>
            <li className="list-group-item  list-group-item-success">
              <b>Net sales</b>
              <h5 className="mt-1">${request?.net_sales}</h5>
            </li>
          </ul>

          <hr />
          {/* <a
            className="btn btn-primary "
            data-confirmation-text="You are about to delete the payout request and all its related information"
          >
            <i className="fas fa-times fa-fw"></i> Delete{" "}
          </a> */}
          {request?.status === "pending" && !request?.deleted_at && (
            <>
              <button
                type="button"
                className="btn btn-dark rounded-5 me-2"
                onClick={(e) => handleOderApprove(e, request.id)}
              >
                <FaMoneyCheckAlt className="me-1" />
                Approve
              </button>
              <button
                type="button"
                className="btn btn-dark rounded-5 me-2"
                onClick={(e) => handleOderCancel(e, request.id)}
              >
                <FaWindowClose className="me-1" />
                Cancel
              </button>
            </>
          )}

          {request?.deleted_at && (
            <button
              type="button"
              className="btn btn-dark rounded-5 me-2"
              onClick={(e) =>
                handleDeletePermanent(
                  e,
                  request?.id,
                  "payout_method_request_permanent_delete"
                )
              }
            >
              <MdDelete className="me-1" />
              Delete permanently
            </button>
          )}
          <button
            type="button"
            className="btn btn-dark rounded-5"
            onClick={(e) =>
              handleDelete(e, request?.id, "payout_method_request_delete", "")
            }
          >
            {request?.deleted_at !== null ? (
              <>
                <FaTrashRestoreAlt className="me-1" /> Restore
              </>
            ) : (
              <>
                <IoCloseSharp className="me-1" />
                Delete
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default ManagePayoutRequestDetail;
