import React from "react";
import { Layout } from "../components/LayoutUser/Layout";
import Breadcrumb from "../components/global/Breadcrumb";

const AboutUs = () => {
  return (
    <Layout>
      <Breadcrumb page={"About us"} />
      <section className="section-content my-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="card box">
                <div className="card-body">
                  <p style={{ fontSize: "12.88px" }}>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Ipsum voluptate asperiores nisi optio! Accusamus quidem
                    obcaecati at debitis molestias, nihil soluta alias
                    reprehenderit, error veritatis saepe sint, ipsam amet
                    delectus fugit? Consequuntur, impedit perspiciatis quae
                    deserunt fugit adipisci a, quod cum facilis vel iusto dolor
                    omnis distinctio! Eligendi, ab voluptatum.
                  </p>
                  <p style={{ fontSize: "12.88px" }}>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Ipsum voluptate asperiores nisi optio! Accusamus quidem
                    obcaecati at debitis molestias, nihil soluta alias
                    reprehenderit, error veritatis saepe sint, ipsam amet
                    delectus fugit? Consequuntur, impedit perspiciatis quae
                    deserunt fugit adipisci a, quod cum facilis vel iusto dolor
                    omnis distinctio! Eligendi, ab voluptatum.
                  </p>
                  <p style={{ fontSize: "12.88px" }}>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Ipsum voluptate asperiores nisi optio! Accusamus quidem
                    obcaecati at debitis molestias, nihil soluta alias
                    reprehenderit, error veritatis saepe sint, ipsam amet
                    delectus fugit? Consequuntur, impedit perspiciatis quae
                    deserunt fugit adipisci a, quod cum facilis vel iusto dolor
                    omnis distinctio! Eligendi, ab voluptatum.
                  </p>
                  <p style={{ fontSize: "12.88px" }}>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Ipsum voluptate asperiores nisi optio! Accusamus quidem
                    obcaecati at debitis molestias, nihil soluta alias
                    reprehenderit, error veritatis saepe sint, ipsam amet
                    delectus fugit? Consequuntur, impedit perspiciatis quae
                    deserunt fugit adipisci a, quod cum facilis vel iusto dolor
                    omnis distinctio! Eligendi, ab voluptatum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutUs;
