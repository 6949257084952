import React, { useEffect, useState } from "react";
import { FaInfoCircle, FaSearchMinus, FaSearchengin } from "react-icons/fa";
import { Radio, Tooltip } from "antd";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addOrganizerFilters,
  clearAllFilters,
  loadFilterOrganizer,
  sorted,
  sorting,
} from "../../../../../store/slice/OrganizerFilters";
import SelectBox from "../../../../global/SelectBox";
import { formatDate } from "../../../../../utils/CustomFunctions";

const Filters = ({ payout_request, events, organizers = null }) => {
  const { slug } = useParams();
  const isSlug = slug || null;
  const dispatch = useDispatch();
  const [payout, setPayout] = useState([]);

  const {
    filters: {
      reference,
      event_date,
      from_date,
      until_date,
      organizer,
      status,
    },
    sorting_value,
    filter_data,
  } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    isSlug
      ? setPayout(payout_request.filter((r) => r.reference === slug))
      : setPayout(payout_request);
  }, [payout_request, slug]);

  useEffect(() => {
    dispatch(loadFilterOrganizer({ data: payout, key: "payout_request" }));
  }, [dispatch, payout]);

  useEffect(() => {
    dispatch(clearAllFilters());
  }, []);

  useEffect(() => {
    dispatch(sorted("payout_request"));
  }, [sorting_value]);

  const add_filter = (e) => {
    const { name, value } = e.target;
    dispatch(addOrganizerFilters({ name, value }));
  };
  const add_filter2 = (name, value) => {
    dispatch(addOrganizerFilters({ name, value }));
  };
  const add_filter3 = (name, value) => {
    dispatch(sorting({ name, value }));
  };
  // Sorting select Box options
  const options = [
    { id: "date-desc", name: "Creation date (desc)" },
    { id: "date-asc", name: "Creation date (asc)" },
  ];

  const formattedDataList = events.map((event) => ({
    id: event.id + " " + event.start_On,
    name:
      event.name + " (" + formatDate(new Date(event.start_On)).fullDate + ")",
  }));
  const organizerList = organizers?.map((organizer) => ({
    id: organizer.id,
    name: organizer.organize_name,
  }));

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-3 text-center text-lg-start mb-3 mb-lg-0 align-self-center">
        <span className="center-lg-y text-muted">
          {filter_data.payout_request.length} result(s) found
        </span>
      </div>
      <div className="col-sm-12 col-lg-5 mb-3 mb-lg-0">
        <form>
          <div className="row align-items-center">
            <div className="col-3 text-end">
              <label htmlFor="sort" className="">
                Sort by{" "}
              </label>
            </div>
            <div className="col-9">
              <SelectBox
                name="sorting_value"
                options={options}
                val={sorting_value}
                change={add_filter3}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="col-sm-12 col-lg-4 text-center text-lg-end">
        <Tooltip title="Toggle display the search filters">
          <button
            type="button"
            className="btn btn-dark ms-2"
            data-bs-toggle="collapse"
            data-bs-target="#search-filters"
            aria-controls="search-filters"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FaSearchengin />
          </button>
        </Tooltip>
      </div>
      <div
        className="col-sm-12 col-lg-12 mb-3 mb-lg-0 collapse show"
        id="search-filters"
      >
        <hr />
        <form className="signform">
          <div className="row align-items-end">
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="Reference" className="form-label">
                Reference
              </label>
              <input
                type="text"
                className="form-control"
                id="Reference"
                name="reference"
                value={reference}
                onChange={add_filter}
              />
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="event_id" className="form-label fw-600">
                Event date <FaInfoCircle className="ms-1" />
              </label>
              <SelectBox
                name="event_date"
                options={formattedDataList}
                val={event_date}
                change={add_filter2}
              />
            </div>
            {organizers && (
              <div className="col-12 col-sm-4 mb-4">
                <label htmlFor="organizers" className="form-label fw-600">
                  Organizers
                </label>
                <SelectBox
                  name="organizer"
                  options={organizerList}
                  val={organizer}
                  change={add_filter2}
                />
              </div>
            )}
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="From-date" className="form-label">
                From date
              </label>
              <input
                type="date"
                className="form-control"
                id="From-date"
                name="from_date"
                value={from_date}
                onChange={add_filter}
              />
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="Until-date" className="form-label">
                Until date
              </label>
              <input
                type="date"
                className="form-control"
                id="Until-date"
                name="until_date"
                value={until_date}
                onChange={add_filter}
              />
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <ul>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="status"
                    checked={status === "all"}
                    value="all"
                    onChange={add_filter}
                  >
                    All
                  </Radio>
                </li>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="status"
                    checked={status === "approve"}
                    value="approve"
                    onChange={add_filter}
                  >
                    Approved
                  </Radio>
                </li>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="status"
                    checked={status === "pending"}
                    value="pending"
                    onChange={add_filter}
                  >
                    Pending
                  </Radio>
                </li>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="status"
                    checked={status === "cancel"}
                    value="cancel"
                    onChange={add_filter}
                  >
                    Canceled
                  </Radio>
                </li>
              </ul>
            </div>

            <div className="col-12 col-sm-4 mb-4">
              <Tooltip title="Reset">
                <button
                  type="button"
                  className="btn btn-dark ms-2"
                  onClick={() => dispatch(clearAllFilters())}
                >
                  <FaSearchMinus />
                </button>
              </Tooltip>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filters;
