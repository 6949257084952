import React, { useEffect, useState } from "react";
// import { Card } from "../../events/Card/Card";
import { FaInfoCircle, FaSyncAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import Card from "./../blog/Card";
import Alert from "../global/alert/Alert";

const FeaturedBlogs = () => {
  const { blogs, home_page_setting, loading } = useSelector(
    (state) => state.AllData
  );

  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No lastest blog posts found",
  };

  const [blog, setBlog] = useState([]);

  useEffect(() => {
    const sortedblogs = [...blogs].sort((a, b) => b.id - a.id);
    const limitedData = sortedblogs.slice(
      0,
      home_page_setting?.number_post ? home_page_setting?.number_post : 10
    );

    setBlog(limitedData);
  }, [blogs, home_page_setting]);

  return (
    <section className="event-featured-section pt-5 pb-5 mt-3">
      <h4
        className="text-center mb-4 pb-1 text-muted text-uppercase "
        style={{ fontSize: "600" }}
      >
        LASTEST BLOG POSTS
      </h4>

      <div className="container">
        {loading ? (
          <div className="d-flex align-items-center justify-content-center">
            <FaSyncAlt className="spinIcon" />
          </div>
        ) : (
          <div className="row border-0">
            {blog?.length > 0 ? (
              blog?.map((curElem) => {
                return <Card blogs={curElem} key={curElem.id} />;
              })
            ) : (
              <Alert {...msg} />
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default FeaturedBlogs;
