import React from "react";
import styled from "styled-components";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

const Wrapper = styled.section`
  ${"" /* background-color : #f1f3f7; */}
  padding :20px;
  .title {
    color: black;
    font-weight: 600;
  }

  .active {
    color: #6c757d;
    font-size: 0.805rem;
    font-weight: 400;
  }
`;

const Breadcrumb = ({ page }) => {
  return (
    <Wrapper className="container-fluid bg-gray">
      <div className="container d-flex align-items-center justify-content-between">
        <h4 className="title m-0">{page}</h4>
        <ol className="breadcrumb m-0 d-flex align-items-center">
          <li className="breadcrumb-item">
            <Link to="/">
              <FaHome />
            </Link>
          </li>

          <li className="breadcrumb-item active">{page}</li>
        </ol>
      </div>
    </Wrapper>
  );
};

export default Breadcrumb;
