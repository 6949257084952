import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../../../utils/CustomFunctions";

const PayoutRequestDetail = () => {
  const { slug } = useParams();
  const { countries } = useSelector((state) => state.AllData);
  const { payout_request } = useSelector((state) => state.organizer);
  const [request, setRequest] = useState({});

  useEffect(() => {
    slug &&
      setRequest(payout_request.find((request) => request?.reference === slug));
  }, [slug]);

  return (
    <>
      <div className="card box">
        <div className="card-body">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <b>Reference</b>
              <h5 className="mt-1">{request?.reference}</h5>
            </li>
            <li className="list-group-item">
              <b>Requested at</b>
              <h5 className="mt-1">
                {formatDate(new Date(request?.request_date)).fullDate}
              </h5>
            </li>
            {/* <li className="list-group-item">
              <b>Processed at</b>
              <h5 className="mt-1">
                {formatDate(new Date(request?.event?.start_On)).fullDate}
              </h5>
            </li> */}
            <li className="list-group-item">
              <b>Status</b>
              <h5 className="mt-1">
                {request?.status === "pending" && (
                  <span className="badge badge-warning ">Pending</span>
                )}
                {request?.status === "cancel" && (
                  <span className="badge badge-danger ">Canceled</span>
                )}
                {request?.status === "approve" && (
                  <span className="badge badge-success ">Approved</span>
                )}
              </h5>
            </li>
            <li className="list-group-item">
              <b>Note</b>
              <h5 className="mt-1">Automatically canceled before deletion</h5>
            </li>
            <li className="list-group-item">
              <b>Organizer</b>
              <h5 className="mt-1">{request?.user?.organize_name}</h5>
            </li>
            <li className="list-group-item">
              <b>Event</b>
              <h5 className="mt-1">
                {request?.event?.name} -{" "}
                <span className="text-uppercase">
                  {request?.event?.venue?.city} {request?.event?.venue?.state}
                </span>
              </h5>
            </li>
            <li className="list-group-item">
              <b>Date</b>
              <h5 className="mt-1">
                {formatDate(new Date(request?.event?.start_On)).fullDate}
              </h5>
            </li>
            <li className="list-group-item">
              <b>Venue</b>
              <h5 className="mt-1">
                {request?.event?.event_date_online === 1 ? (
                  " Online"
                ) : (
                  <>
                    {request?.event?.venue?.city}:{" "}
                    {request?.event?.venue?.postal_code}{" "}
                    {request?.event?.venue?.street_address},{" "}
                    {request?.event?.venue?.state},{" "}
                    {countries?.map((curElem) => {
                      if (curElem.id == request?.event?.venue?.country) {
                        return (
                          <React.Fragment key={curElem.id}>
                            {curElem.name}
                          </React.Fragment>
                        );
                      }
                      return null; // or any other default value if the condition is not met
                    })}
                  </>
                )}
              </h5>
            </li>
            <li className="list-group-item">
              <b className="mr-3">Tickets sold</b>
              {/* <a
                href="/en/dashboard/administrator/manage-events/resemblingruth-2nd-annual-conference/event-dates/784b10e94e/statistics"
                target="_blank"
              >
                <i className="fas fa-chart-line"></i> View detailed statistics
              </a> */}
              <h5 className="mt-1">{request?.ticket_quantity}</h5>
            </li>
            <li className="list-group-item">
              <b>Payout method</b>
              <h5 className="mt-1">
                {request?.payout_method === "stripe" ? (
                  <img
                    src="/./images/strip.svg"
                    className="img-thumbnail img-100-100"
                  />
                ) : (
                  <img
                    src="/./images/paypal.svg"
                    className="img-thumbnail img-100-100"
                  />
                )}
              </h5>
            </li>

            {/* <li className="list-group-item ">
              <b>Percentage cut</b>
              <h5 className="mt-1">{request?.percentage_cut}$</h5>
            </li> */}
            <li className="list-group-item  list-group-item-success">
              <b>Net sales</b>
              <h5 className="mt-1">${request?.net_sales}</h5>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PayoutRequestDetail;
