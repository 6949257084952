import { FaCircleInfo } from "react-icons/fa6";
import "../layout/style.scss";
import { useSignUp } from "../../../../../../utils/CustomFunctions";
import { useSelector } from "react-redux";
import { FaSyncAlt } from "react-icons/fa";

const GoogleMaps = () => {
  const { google_maps_settings } = useSelector((state) => state.admin);

  const initialState = {
    api_key: google_maps_settings?.api_key || "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};
    return newErrors;
  };

  const { values, errors, isPending, handleChange, handleSubmit } = useSignUp(
    initialState,
    validateFields,
    `admin/add_google_maps_settings`,
    "google_maps_settings"
  );
  return (
    <>
      <div className="card box">
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form onSubmit={handleSubmit} className="signform mt-3 ">
              <div className="mb-3">
                <label
                  htmlFor="google_maps_api_key"
                  className="form-label fw-600"
                >
                  Google Maps Api Key
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    Leave api key empty to disable google maps project wide
                  </span>
                </div>
                <input
                  type="text"
                  name="api_key"
                  id="google_maps_api_key"
                  className="form-control"
                  value={values.api_key}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group mt-4 mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  disabled={isPending ? "disabled" : ""}
                  type="submit"
                >
                  {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleMaps;
