import Venue from "../../components/venues/Venue";
import { Layout } from "../../components/LayoutUser/Layout";
import { useDispatch, useSelector } from "react-redux";
import { filteredVenues } from "../../store/slice/venueFilters";
import { useEffect } from "react";
import Breadcrumb from "../../components/global/Breadcrumb";
import { fetchAllData } from "../../store/slice/AllData";

const Venues = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.venueFilters);
  useEffect(() => {
    dispatch(filteredVenues());
    window.scrollTo(0, 0);
  }, [filters]);

  useEffect(() => {
    dispatch(
      fetchAllData({
        getData: "venue_page_settings",
        state: "venue_page_settings",
      })
    );
    dispatch(
      fetchAllData({
        getData: "venues",
        state: "venues",
      })
    );
  }, []);

  return (
    <Layout>
      <Breadcrumb page={"Venues"} />
      <Venue />
    </Layout>
  );
};

export default Venues;
