import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    setLoggedUser: (state, action) => {
      state.user = action.payload;
    },
    setLogout: (state, action) => {
      state.user = {};
    },
  },
});

export const { setLoggedUser, setLogout } = userSlice.actions;
export default userSlice.reducer;
