import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { setLoggedUser } from "../store/slice/User";
import { deleteSinglev, fetchData } from "../store/slice/Organizer";
import { fetchAllData, setData } from "../store/slice/AllData";
import { addData, adminFetchData, deleteSingle } from "../store/slice/Admin";
import { fetchTickets } from "../store/slice/Cart";
import Payment from "payment";
const api = process.env.REACT_APP_SERVER_API;

// for change staus
export const useStatusChange = (
  role,
  change_status,
  state,
  stateKey,
  statekey2 = null
) => {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();

  const handleStatusChange = async (e, id) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${api}/${role}/${change_status}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (data.status === 200) {
        toast.success(data.message);
        if (role === "admin") {
          dispatch(
            adminFetchData({
              endPoint: `admin/${state}`,
              state: stateKey,
              token: user.token,
            })
          );
          if (statekey2) {
            dispatch(
              adminFetchData({
                endPoint: `admin/${statekey2}`,
                state: statekey2,
                token: user.token,
              })
            );
          }
          if (stateKey === "blogs") {
            dispatch(
              fetchAllData({
                getData: stateKey,
                state: stateKey,
              })
            );
          }
        }
        if (role === "organizer") {
          dispatch(
            fetchData({
              getData: `organizer/${state}`,
              token: user.token,
              state: stateKey,
            })
          );
          dispatch(
            fetchAllData({
              getData: stateKey,
              state: stateKey,
            })
          );
        }
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  return { loading, handleStatusChange };
};
// for delete
export const useDelete = (role, state, stateKey, categoryState = null) => {
  const [isLoading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();

  const handleDelete = async (e, id, deleteEndpoint, del) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${api}/${role}/${deleteEndpoint}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (data.status === 200) {
        toast.success(data.message);
        if (del === "delete_permanent") {
          if (role === "admin") {
            dispatch(deleteSingle({ id, state: stateKey }));
            if (categoryState) {
              dispatch(
                adminFetchData({
                  endPoint: `admin/${categoryState}`,
                  state: `${categoryState}`,
                  token: user.token,
                })
              );
            }
          }

          if (role === "organizer") {
            dispatch(deleteSinglev({ id, state: stateKey }));
            if (categoryState) {
              console.log(categoryState);
              dispatch(
                fetchData({
                  getData: `organizer/${categoryState}`,
                  token: user.token,
                  state: categoryState,
                })
              );
            }
            if (stateKey === "events") {
              dispatch(
                fetchAllData({
                  getData: "events",
                  state: "events",
                })
              );
            }
          }
        } else {
          if (role === "admin") {
            dispatch(
              adminFetchData({
                endPoint: `admin/${state}`,
                state: `${stateKey}`,
                token: user.token,
              })
            );
            dispatch(
              fetchAllData({
                getData: stateKey,
                state: stateKey,
              })
            );
            if (state === "event_categories") {
              dispatch(
                fetchAllData({
                  getData: "categories",
                  state: "categories",
                })
              );
            }
          }

          if (role === "organizer") {
            dispatch(
              fetchData({
                getData: `organizer/${state}`,
                token: user.token,
                state: stateKey,
              })
            );
            if (state !== "paypal" && state !== "stripe") {
              dispatch(
                fetchAllData({
                  getData: stateKey,
                  state: stateKey,
                })
              );
            }
          }
        }
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, handleDelete };
};
// for date and time formatting
export const formatDate = (date) => ({
  month: date.toLocaleString("en-US", { month: "short" }),
  day: date.toLocaleString("en-US", { day: "2-digit" }),
  year: date.toLocaleString("en-US", { year: "numeric" }),
  time: date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }),
  fullDate: date.toLocaleString("en-US", {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }),
});
// format date into yyyy-mm-dd format
export const formatISODate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
// format date into yyyy-mm-dd dd-mm-ss format
export const formatISODate2 = (originalDateString) => {
  const originalDate = new Date(originalDateString);

  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
  const day = originalDate.getDate().toString().padStart(2, "0");

  const hours = originalDate.getHours().toString().padStart(2, "0");
  const minutes = originalDate.getMinutes().toString().padStart(2, "0");
  const seconds = originalDate.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
// for get the dates
export const calculateDates = (type) => {
  const today = new Date();
  let endOfWeek = new Date();

  // const formatISODate = (date) => {
  //   return date.toISOString().slice(0, 10); // YYYY-MM-DD
  // };
  // const formatDate = (date) => {
  //   return date.toLocaleString('en-US', {
  //     year: 'numeric',
  //     month: '2-digit',
  //     day: '2-digit',
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     second: '2-digit',
  //   });
  // };

  switch (type) {
    case "today":
      return { start: formatISODate(today), end: formatISODate(today) };

    case "tomorrow":
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      return { start: formatISODate(tomorrow), end: formatISODate(tomorrow) };

    case "thisWeekend":
      const todayCopy = new Date(today);
      const daysUntilSaturday = 6 - today.getDay(); // Days until Saturday
      const daysUntilSunday = 7 - today.getDay(); // Days until Sunday
      const saturday = new Date(
        todayCopy.setDate(today.getDate() + daysUntilSaturday)
      );
      const sunday = new Date(
        todayCopy.setDate(today.getDate() + daysUntilSunday)
      );
      return { start: formatISODate(saturday), end: formatISODate(sunday) };

    case "thisWeek":
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay());
      endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      return {
        start: formatISODate(startOfWeek),
        end: formatISODate(endOfWeek),
      };

    case "nextWeek":
      const startOfNextWeek = new Date(endOfWeek);
      startOfNextWeek.setDate(endOfWeek.getDate() + 1);
      const endOfNextWeek = new Date(startOfNextWeek);
      endOfNextWeek.setDate(startOfNextWeek.getDate() + 6);
      return {
        start: formatISODate(startOfNextWeek),
        end: formatISODate(endOfNextWeek),
      };

    case "thisMonth":
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      return {
        start: formatISODate(startOfMonth),
        end: formatISODate(endOfMonth),
      };

    case "nextMonth":
      const startOfNextMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );
      const endOfNextMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 2,
        0
      );
      return {
        start: formatISODate(startOfNextMonth),
        end: formatISODate(endOfNextMonth),
      };

    default:
      return null;
  }
};
// for signup
export const useSignUp = (
  initialState,
  validate,
  apiEndpoint,
  state = null
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isPending, setPending] = useState(false);
  const { user } = useSelector((state) => state.authUser);

  const validateFields = () => {
    const newErrors = validate(values);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // for changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  // for handle change select box
  const handleSelectBox = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const handleMultiSelectBox = (name, value) => {
    const selectedValues = value ? value.map((option) => option.value) : [];
    setValues({ ...values, [name]: selectedValues });
  };

  // for handle single image
  const singleImage = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    setValues({ ...values, [name]: file });
  };
  // for handle date input
  const handleDateChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  // for add data
  // console.log(values);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;
    try {
      setPending(true);

      const { data } = await axios.post(
        `${api}/${apiEndpoint}`,
        values,
        user?.token && {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (data.status === 200 || data.status === 201) {
        toast.success(data.message);
        // route && navigate(route);
        if (apiEndpoint === "profile_update") {
          dispatch(setLoggedUser({ ...data.data, token: user.token }));
          setValues({ ...data.data, token: user.token });
        } else if (
          (apiEndpoint === "admin/add_news_litter_setting" ||
            apiEndpoint === "admin/add_email_server_settings" ||
            apiEndpoint === "admin/add_venue_page_settings" ||
            apiEndpoint === "admin/add_event_setting" ||
            apiEndpoint === "admin/add_social_login_settings" ||
            apiEndpoint === "admin/add_google_maps_settings" ||
            apiEndpoint === "admin/add_google_repatcha_settings" ||
            apiEndpoint === "admin/add_time_left" ||
            apiEndpoint === "admin/add_payment_fees" ||
            apiEndpoint === "admin/add_home_page_setting" ||
            apiEndpoint === "admin/add_layout_settings" ||
            apiEndpoint === "admin/add_blog_setting") &&
          state
        ) {
          dispatch(addData({ state, data: data.data }));
          setValues(data.data);
        } else if (state === "paypal" || state === "stripe") {
          navigate("/en/dashboard/organizer/settings/payouts");
          // dispatch(addPayoutData({ state, data: data.data }));
          // setValues(data.data);
        } else {
          setValues(initialState);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
    } finally {
      setPending(false);
    }
  };
  return {
    values,
    setValues,
    errors,
    isPending,
    handleChange,
    handleSelectBox,
    handleMultiSelectBox,
    singleImage,
    handleDateChange,
    handleSubmit,
  };
};
// for add fees in percentage
export const calculatePercentage = (amount, percentage, tax) => {
  const calculatedPercentage = (amount * percentage) / 100;

  const total = calculatedPercentage + amount;
  if (tax) {
    const calculatedTax = (total * tax) / 100;
    return (total + calculatedTax).toFixed(2);
  }
  return calculatedPercentage.toFixed(2);
};
export const calculate_Percentage = (part, whole) => {
  if (part === 0) {
    return "0";
  }
  const calculatedPercentage = (
    (parseFloat(part) / parseFloat(whole)) *
    100
  ).toFixed(2);
  return calculatedPercentage;
};
// fetch countries
export const useCountryCodes = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const country = response.data.map((country) => ({
          name: country.name.common,
          id: country.flag,
          flag: country.flags.png,
        }));

        const lang = response.data.map((country) => country.languages);

        const uniqueLanguagesObject = {};
        lang.forEach((languageObject) => {
          for (const code in languageObject) {
            const name = languageObject[code];
            if (!uniqueLanguagesObject[code]) {
              uniqueLanguagesObject[code] = { [code]: name };
            }
          }
        });

        const languages = Object.entries(uniqueLanguagesObject).map(
          ([id, nameObj]) => ({ id, name: nameObj[id] })
        );

        dispatch(setData({ data: country, state: "countries" }));
        dispatch(setData({ data: languages, state: "languages" }));
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCountryCodes();
  }, []);

  return { loading, error };
};
// for add data to database
export const useAddEntity = (
  role,
  entityType,
  state,
  initialState,
  validate,
  route = null
) => {
  const { id } = useParams();
  const { slug } = useParams();

  const { user } = useSelector((state) => state.authUser);
  const { [state]: entity } = useSelector((state) => state.AllData);
  const { [state]: entities } = useSelector((state) => state[role]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPending, setPending] = useState(false);
  const [errors, setErrors] = useState({});
  const [newEntity, setNewEntity] = useState(initialState);

  const validateFields = () => {
    const newErrors = validate(newEntity);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEntity({ ...newEntity, [name]: value });
  };
  // for handle change select box
  const handleSelectBox = (name, value) => {
    setNewEntity({ ...newEntity, [name]: value });
  };
  // for country select box
  const handleCountrySelectBox = (name, value) => {
    const data = entity?.find((st) => st.id === value);
    setNewEntity({
      ...newEntity,
      name: data?.name,
      code: data?.id,
      ...(data?.flag && { flag: data?.flag }),
    });
  };
  // for handle Editor on change
  const handleEditor = (html) => {
    setNewEntity({ ...newEntity, description: html });
  };
  // for iamges
  const handleIamgesChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    setNewEntity({
      ...newEntity,
      [name]: name === "images" ? [...newEntity.images, file] : file,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) return;

    try {
      setPending(true);
      const endpoint = id || slug ? `edit_${entityType}` : `add_${entityType}`;
      const { data } = await axios.post(
        `${api}/${role}/${endpoint}`,
        newEntity,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (data.status === id ? 200 : 201) {
        toast.success(data.message);
        route && navigate(route);
        if (!id && !slug) {
          setNewEntity(initialState);
        }

        if (role === "admin") {
          dispatch(
            adminFetchData({
              endPoint: `admin/${state}`,
              state: state,
              token: user.token,
            })
          );
          state === "blogs" &&
            dispatch(
              adminFetchData({
                endPoint: `admin/blog_categories`,
                state: "blog_categories",
                token: user.token,
              })
            );

          state === "articles" &&
            dispatch(
              adminFetchData({
                endPoint: `admin/article_categories`,
                state: "article_categories",
                token: user.token,
              })
            );

          if (state === "event_categories") {
            dispatch(
              fetchAllData({
                getData: "categories",
                state: "categories",
              })
            );
          }
          dispatch(
            fetchTickets({
              getData: "payment_fees",
              state: "payment_settings",
              token: "",
            })
          );
        }

        dispatch(
          fetchAllData({
            getData: "blogs",
            state: "blogs",
          })
        );
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    id &&
      setNewEntity(entities?.find((ele) => parseInt(ele.id) === parseInt(id)));
    slug && setNewEntity(entities?.find((ele) => ele.slug === slug));
  }, [id, entities, slug]);

  return {
    isPending,
    errors,
    newEntity,
    setNewEntity,
    handleChange,
    handleSelectBox,
    handleIamgesChange,
    handleCountrySelectBox,
    handleEditor,
    handleSubmit,
  };
};

// for card information for payments

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 3;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

// for vlidate date
export function isDateValid(dateString) {
  // Split the date string into month and year parts
  const [month, year] = dateString.split("/").map((part) => parseInt(part, 10));

  // Get the current date
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear() % 100; // Get the last two digits of the current year
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns zero-based index

  // Check if the year is greater than the current year, or if it's the same year but the month is greater than or equal to the current month
  if (year > currentYear || (year === currentYear && month >= currentMonth)) {
    return true; // The date is not less than the current date
  } else {
    return false; // The date is less than the current date
  }
}
