import React, { useState } from "react";
import Alert from "./../../../../global/alert/Alert";
import {
  FaCheck,
  FaInfo,
  FaLongArrowAltRight,
  FaQuestionCircle,
  FaSyncAlt,
} from "react-icons/fa";
import { Modal } from "antd";

const Console = () => {
  const [openModel, setOpenModel] = useState(false);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const handleModel = (e, id) => {
    e.preventDefault();
    setLoading(true);
    const n = Math.floor(Math.random() * (10 - 5 + 1)) + 5;
    setTimeout(() => {
      setLoading(false);
    }, n + "000");
    setData(id);
    setOpenModel(true);
  };

  const msg = {
    icon: <FaInfo className="me-3" />,
    type: "alert-warning",
    text: "An error might occur if the command execution time reaches your PHP max_execution_time directive",
  };

  return (
    <>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={openModel}
        onCancel={() => setOpenModel(false)}
        width="357px"
      >
        <div className="ModelBox d-flex flex-column align-items-center justify-content-center">
          {loading ? (
            <FaSyncAlt className="spinIcon" />
          ) : (
            <>
              <div className="ModelHeader mb-2">
                <span className="ModelBoxBuyTicketText fs-5 ">
                  Command Executed
                </span>
              </div>
              <div className="jconfirm-content" id="jconfirm-box27525">
                <div>
                  Clear the application cache for the <b>{data}</b> environment!
                  Done
                </div>
              </div>
            </>
          )}
        </div>

        <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
          <button
            className="btn btn-outline-secondary btn-sm rounded-5 d-inline-flex align-items-center gap-2"
            onClick={() => setOpenModel(false)}
          >
            close
          </button>
        </div>
      </Modal>
      <div className="card p-0 organizer-event">
        <div className="card-body">
          <Alert {...msg} />
          <div className="row mt-5">
            <div className="col-md-8">
              <h5 className="mt-2 d-flex align-items-center gap-1">
                <FaLongArrowAltRight />
                Clear the application cache for the production environment
                <FaQuestionCircle title="Execute this command after editing a file in the application source code or after modifying a string in the translations files" />
              </h5>
            </div>
            <div className="col-md-4">
              <button
                type="button"
                className="btn btn-dark btn-block ajax-loading"
                // data-title="Response"
                // data-url="/en/dashboard/administrator/console/execute-command/cache:clear/--env/prod"
                onClick={(e) => handleModel(e, "production")}
              >
                <FaCheck className="me-1" /> Execute command
              </button>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-8">
              <h5 className="mt-2 d-flex align-items-center gap-1">
                <FaLongArrowAltRight />
                Clear the application cache for the development environment
                <FaQuestionCircle title="Execute this command after editing a file in the application source code or after modifying a string in the translations files" />
              </h5>
            </div>
            <div className="col-md-4">
              <button
                type="button"
                className="btn btn-dark btn-block ajax-loading"
                // data-title="Response"
                // data-url="/en/dashboard/administrator/console/execute-command/cache:clear/--env/prod"
                onClick={(e) => handleModel(e, "development")}
              >
                <FaCheck className="me-1" /> Execute command
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Console;
