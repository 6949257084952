import React from "react";
import "./blurryheader.scss";

export default function BlurryHeader({ img, page }) {
  const server = process.env.REACT_APP_SERVER;
  return (
    <div className="BlurryHeaderContainer">
      {img ? (
        <>
          <div
            className="background"
            style={{ backgroundImage: `url(${server}/${page}/image/${img})` }}
          ></div>
          <div className="imageContainer">
            <img
              className="BlurryHeaderImage"
              src={`${server}/${page}/image/${img}`}
              alt=""
            />
          </div>
        </>
      ) : (
        <>
          <div
            className="background"
            style={{ backgroundImage: `url(/./images/no.png)` }}
          ></div>
          <div className="imageContainer">
            <img className="BlurryHeaderImage" src="/./images/no.png" alt="" />
          </div>
        </>
      )}
    </div>
  );
}
