import React from "react";
import Select from "react-select";
import styled from "styled-components";

const Wrapper = styled.section`
  .select__control {
    /* border-radius: 2rem; */
    ${"" /* font-size: 1.6rem; */}
  }
  .select__input-container {
    margin: 0;
    padding: 0;
  }
  .select__value-container {
    padding: 0rem 0.5rem;
  }
  .select__indicator {
    ${"" /* padding: 1.3rem; */}
  }
  .basic-single.css-b62m3t-container {
    ${"" /* margin: 1.5rem 0; */}
  }
  .select__menu {
    z-index: 99999999;
  }
`;

const MultiSelectBox = ({ options, val, name, change }) => {
  const Options = options.map((curElem) => {
    return { value: curElem.id, label: curElem.name || curElem.organize_name };
  });

  // const handleSelectChange = (selectedOptions) => {
  //   const selectedValues = selectedOptions
  //     ? selectedOptions.map((option) => option.value)
  //     : [];
  //   setVal({ ...val, [name]: selectedValues });
  //   console.log(selectedValues);
  //   // Update newEvent state if needed
  //   // setNewEvent({ ...newEvent, [name]: selectedValues });
  // };

  let op;
  if (name !== "subtitles") {
    const opt = val[name] || [];
    op = opt?.map((str) => parseInt(str, 10));
  } else {
    op = val[name];
  }
  return (
    <Wrapper>
      <Select
        value={Options.filter((option) => op?.includes(option.value))}
        className="basic-single"
        classNamePrefix="select"
        // defaultValue={Options[0]}
        isMulti
        isDisabled={false}
        isLoading={false}
        isClearable={true}
        isRtl={false}
        isSearchable={true}
        name={name}
        options={Options}
        placeholder="--Please Select--"
        onChange={(value) => change(name, value)}
      ></Select>
    </Wrapper>
  );
};

export default MultiSelectBox;
