import React, { useEffect } from "react";
import { FaInfoCircle, FaSyncAlt } from "react-icons/fa";
import "./style.css";
import Alert from "./../../../../global/alert/Alert";
import { Table } from "./Table";
import { useDispatch, useSelector } from "react-redux";
import Filters from "./Filters";
import { fetchData } from "../../../../../store/slice/Organizer";

const PayoutRequests = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);

  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "To submit a new payout request for an event date, go to the My events page and click on the Request payout link next to the event",
  };

  const { payout_request, events, loading } = useSelector(
    (state) => state.organizer
  );
  const { filter_data } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    dispatch(
      fetchData({
        getData: "organizer/payout_method_requests",
        token: user.token,
        state: "payout_request",
      })
    );
  }, []);

  return (
    <>
      <Alert {...msg} />
      <div className="box shadow-none bg-gray mb-4 organizer-event payoutRequests ">
        <Filters payout_request={payout_request} events={events} />
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table payout_request={filter_data.payout_request} />
      )}
    </>
  );
};

export default PayoutRequests;
