import React, { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import Alert from "../global/alert/Alert";
import { Card } from "../events/Card/Card";
import { event } from "jquery";

const Tabs = ({ events }) => {
  const col = "col-12";
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No events on sale.",
  };
  const msg2 = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No past events found.",
  };

  const currentDateTime = new Date();
  const onSaleEvents = [];
  const notOnSaleEvents = [];

  events?.forEach((event) => {
    for (const ticket of event?.tickets) {
      const saleStart = new Date(ticket.sale_start);
      const saleEnd = new Date(ticket.sale_end);

      if (saleStart <= currentDateTime && currentDateTime <= saleEnd) {
        onSaleEvents.push(event);
        break; // If any ticket is on sale, consider the whole event on sale
      }
    }
    if (!onSaleEvents?.includes(event)) {
      notOnSaleEvents.push(event);
    }
  });

  return (
    <>
      <div
        className="nav  nav-pills"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <button
          className="nav-link active"
          id="events_on_sale_tab"
          data-bs-toggle="pill"
          data-bs-target="#events_on_sale"
          type="button"
          role="tab"
          aria-controls="events_on_sale"
          aria-selected="true"
        >
          Events on sale ({onSaleEvents?.length})
        </button>
        <button
          className="nav-link"
          id="past_events_tab"
          data-bs-toggle="pill"
          data-bs-target="#past_events"
          type="button"
          role="tab"
          aria-controls="past_events"
          aria-selected="false"
        >
          Past events ({notOnSaleEvents?.length})
        </button>
      </div>
      {/* content */}
      <div className="tab-content w-100 mt-4" id="v-pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="events_on_sale"
          role="tabpanel"
          aria-labelledby="events_on_sale_tab"
        >
          {onSaleEvents?.length > 0 ? (
            onSaleEvents.map((curElem, index) => {
              return (
                <Card
                  col={col}
                  data={curElem}
                  key={index}
                  clas="home-card-event"
                />
              );
            })
          ) : (
            <Alert {...msg} />
          )}
        </div>
        <div
          className="tab-pane fade"
          id="past_events"
          role="tabpanel"
          aria-labelledby="past_events_tab"
        >
          {notOnSaleEvents?.length > 0 ? (
            notOnSaleEvents.map((curElem, index) => {
              return (
                <Card
                  col={col}
                  data={curElem}
                  key={index}
                  clas="home-card-event"
                />
              );
            })
          ) : (
            <Alert {...msg2} />
          )}
        </div>
      </div>
    </>
  );
};

export default Tabs;
