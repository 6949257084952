import React, { useEffect } from "react";
import { Table } from "./Table";
import { FaPlus, FaSyncAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrganizerFilters,
  clearAllFilters,
  loadFilterOrganizer,
  sorted,
  sorting,
} from "../../../../../../store/slice/OrganizerFilters";
import SelectBox from "../../../../../global/SelectBox";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";

const ManageVenuesTypes = () => {
  const dispatch = useDispatch();
  const { venue_types, loading } = useSelector((state) => state.admin);
  const {
    filters: { keyword },
    sorting_value,
    filter_data,
  } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    dispatch(loadFilterOrganizer({ data: venue_types, key: "venue_types" }));
  }, [dispatch, venue_types]);

  useEffect(() => {
    dispatch(clearAllFilters());
  }, []);

  useEffect(() => {
    dispatch(sorted("venue_types"));
  }, [sorting_value]);

  const add_filter = (e) => {
    const { name, value } = e.target;
    dispatch(addOrganizerFilters({ name, value }));
  };

  // Search Filter
  const add_filter3 = (name, value) => {
    dispatch(sorting({ name, value }));
  };
  // Sorting select Box options
  const options = [
    { id: "date-desc", name: "Creation date (desc)" },
    { id: "date-asc", name: "Creation date (asc)" },
  ];

  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event MyScanners">
        <div className="row">
          <div className="col-sm-12 col-lg-2 text-center text-lg-start mb-3 mb-lg-0 align-self-center">
            <span className="center-lg-y text-muted">
              {filter_data.venue_types?.length} result(s) found
            </span>
          </div>
          <div className="col-sm-12 col-lg-4 d-flex align-items-center justify-content-center">
            <ul className="d-flex">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Search
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <div className="signform">
                      <label htmlFor="keyword" className="form-label dashboard">
                        Keyword
                      </label>
                      <input
                        type="text"
                        name="keyword"
                        className="form-control"
                        id="keyword"
                        value={keyword}
                        onChange={add_filter}
                      />
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-lg-6 d-flex align-items-center justify-content-around">
            <form className="text-start d-flex align-items-center">
              <label htmlFor="sort" className="">
                Sort by{" "}
              </label>
              <SelectBox
                name="sorting_value"
                options={options}
                val={sorting_value}
                change={add_filter3}
              />
            </form>
            <Tooltip title="Add a new Venue Type">
              <Link
                to="add"
                className="btn btn-dark ms-3"
                style={{ fontSize: "12px" }}
              >
                <FaPlus />
              </Link>
            </Tooltip>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table venue_types={filter_data?.venue_types} />
      )}
    </>
  );
};

export default ManageVenuesTypes;
