import React, { useEffect, useState } from "react";
import {
  FaExclamationCircle,
  FaSearch,
  FaSearchMinus,
  FaSearchengin,
} from "react-icons/fa";
import { Radio, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addOrganizerFilters,
  clearAllFilters,
  loadFilterOrganizer,
  sorted,
  sorting,
} from "../../../../../store/slice/OrganizerFilters";
import SelectBox from "../../../../global/SelectBox";

const Filters = ({ event_reviews, events }) => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const isSlug = slug || null;

  const [reviews, setReviews] = useState([]);

  const {
    filters: { event_id, keyword, star, status },
    sorting_value,
    filter_data,
  } = useSelector((state) => state.organizerFilters);

  const msg = {
    icon: <FaExclamationCircle className="me-3" />,
    type: "alert-info",
    text: "No reviews found",
  };

  useEffect(() => {
    isSlug
      ? setReviews(event_reviews.filter((r) => r.slug === slug))
      : setReviews(event_reviews);
  }, [event_reviews, slug]);

  useEffect(() => {
    dispatch(loadFilterOrganizer({ data: reviews, key: "event_reviews" }));
  }, [dispatch, reviews]);

  useEffect(() => {
    dispatch(clearAllFilters());
  }, []);

  useEffect(() => {
    dispatch(sorted("event_reviews"));
  }, [sorting_value]);

  const add_filter = (e) => {
    const { name, value } = e.target;
    dispatch(addOrganizerFilters({ name, value }));
  };
  const add_filter2 = (name, value) => {
    dispatch(addOrganizerFilters({ name, value }));
  };
  const add_filter3 = (name, value) => {
    dispatch(sorting({ name, value }));
  };
  // Sorting select Box options
  const options = [
    { id: "date-desc", name: "Creation date (desc)" },
    { id: "date-asc", name: "Creation date (asc)" },
  ];
  const totalReviews = filter_data.event_reviews.reduce((total, event) => {
    return total + event.reviews.length;
  }, 0);

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-5 text-center text-lg-start mb-3 mb-lg-0 align-self-center">
        <span className="center-lg-y text-muted">
          {totalReviews} result(s) found
        </span>
      </div>
      <div className="col-sm-12 col-lg-5 mb-3 mb-lg-0 text-center text-lg-end">
        <form className="text-start d-flex align-items-center">
          <label htmlFor="sort" className="">
            Sort by{" "}
          </label>
          <SelectBox
            name="sorting_value"
            options={options}
            val={sorting_value}
            change={add_filter3}
          />
        </form>
      </div>
      <div className="col-sm-12 col-lg-2 text-center text-lg-end">
        <Tooltip title="Toggle display the search filters">
          <button
            type="button"
            className="btn btn-dark ms-2"
            data-bs-toggle="collapse"
            data-bs-target="#search-filters"
            aria-controls="search-filters"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FaSearchengin />
          </button>
        </Tooltip>
      </div>

      <div
        id="search-filters"
        className="col-sm-12 col-lg-12 mb-3 mb-lg-0 collapse show"
      >
        <hr />
        <form action="" className="signform">
          <div className="row">
            <div className="col-12 col-sm-4 mb-4">
              <div className="">
                <label htmlFor="Keyword" className="form-label">
                  Keyword
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Keyword"
                  name="keyword"
                  value={keyword}
                  onChange={add_filter}
                  placeholder="Search in reviews by title"
                />
              </div>
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="event_id" className="form-label fw-600">
                Event
              </label>
              <SelectBox
                name="event_id"
                options={events}
                val={event_id}
                change={add_filter2}
              />
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <ul>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="status"
                    checked={status === "all"}
                    value="all"
                    onChange={add_filter}
                  >
                    All
                  </Radio>
                </li>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="status"
                    checked={status === "active"}
                    value="active"
                    onChange={add_filter}
                  >
                    Visible only
                  </Radio>
                </li>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="status"
                    checked={status === "inactive"}
                    value="inactive"
                    onChange={add_filter}
                  >
                    Hidden only
                  </Radio>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <ul>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="star"
                    checked={star === "all"}
                    value="all"
                    onChange={add_filter}
                  >
                    All
                  </Radio>
                </li>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="star"
                    checked={star === "5"}
                    value="5"
                    onChange={add_filter}
                  >
                    5 Star
                  </Radio>
                </li>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="star"
                    checked={star === "4"}
                    value="4"
                    onChange={add_filter}
                  >
                    4 Star
                  </Radio>
                </li>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="star"
                    checked={star === "3"}
                    value="3"
                    onChange={add_filter}
                  >
                    3 Star
                  </Radio>
                </li>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="star"
                    checked={star === "2"}
                    value="2"
                    onChange={add_filter}
                  >
                    2 Star
                  </Radio>
                </li>
                <li>
                  <Radio
                    className="ProfileSettingRadioBtn fw-600"
                    name="star"
                    checked={star === "1"}
                    value="1"
                    onChange={add_filter}
                  >
                    1 Star
                  </Radio>
                </li>
              </ul>
            </div>

            <div className="col-12 col-sm-4 mb-4">
              <Tooltip title="Reset">
                <button
                  type="button"
                  className="btn btn-dark ms-2"
                  onClick={() => dispatch(clearAllFilters())}
                >
                  <FaSearchMinus />
                </button>
              </Tooltip>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filters;
