import React, { useEffect } from "react";
import Breadcrumb from "../../components/global/Breadcrumb";
import Content from "../../components/events/Content";
import { Layout } from "../../components/LayoutUser/Layout";
import { useDispatch, useSelector } from "react-redux";
import { filteredEvents } from "../../store/slice/Filters";
import { fetchAllData } from "../../store/slice/AllData";

const Events = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.Filters);

  useEffect(() => {
    dispatch(filteredEvents());
    window.scrollTo(0, 0);
  }, [filters]);

  useEffect(() => {
    dispatch(
      fetchAllData({
        getData: "events",
        state: "events",
      })
    );
    dispatch(
      fetchAllData({
        getData: "event_setting",
        state: "event_setting",
      })
    );
    dispatch(
      fetchAllData({
        getData: "audiences",
        state: "audiences",
      })
    );
  }, []);

  return (
    <Layout>
      <Breadcrumb page={"Events"} />
      <Content />
    </Layout>
  );
};

export default Events;
