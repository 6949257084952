import React from "react";
import "./style.css";
import { Layout } from "../../components/LayoutUser/Layout";
import BlurryHeader from "../../components/events/singleEvent/BlurryHeader/BlurryHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCalendarCheck, FaRegUser, FaSyncAlt } from "react-icons/fa";
import Breadcrumb from "../../components/global/Breadcrumb2";
import axios from "axios";
import { fetchAllData } from "../../store/slice/AllData";
import { toast } from "react-toastify";
import {
  loadFilterVenueComments,
  sortedv,
  sorting,
} from "../../store/slice/venueFilters";
import SelectBox from "../../components/global/SelectBox";

const VenuesDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [venue, setVenue] = useState(null);
  const [errors, setErrors] = useState({});
  const [isPending, setPending] = useState(false);
  const { user } = useSelector((state) => state.authUser);
  const api = process.env.REACT_APP_SERVER_API;
  const { venues, venue_page_settings, countries } = useSelector(
    (state) => state.AllData
  );
  const { sorting_value, filter_venue_comments } = useSelector(
    (state) => state.venueFilters
  );

  const [comment, setComment] = useState({
    comment: "",
    venue_id: "",
  });

  useEffect(() => {
    if (slug) {
      const venue = venues.find((venue) => venue.slug === slug);
      setVenue(venue);
      setComment({ ...comment, venue_id: venue?.id });
    }
  }, [slug, venues]);

  const validateFields = () => {
    let newErrors = {};
    if (!comment?.comment.trim())
      newErrors.comment = "This value should not be null.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    if (!user?.token) {
      navigate(`/en/signin`, {
        state: `/en/venue/${slug}`,
      });
    }
    e.preventDefault();
    if (!validateFields()) return;
    try {
      setPending(true);

      const { data } = await axios.post(
        `${api}/attendee/add_venue_comment`,
        comment,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (data.status === 200 || data.status === 201) {
        setComment({ ...comment, comment: "" });
        toast.success(data.message);
        dispatch(
          fetchAllData({
            getData: "venues",
            state: "venues",
          })
        );
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
    } finally {
      setPending(false);
    }
  };

  // Search Filter
  const add_filter3 = (name, value) => {
    dispatch(sorting({ name, value }));
  };

  useEffect(() => {
    dispatch(loadFilterVenueComments(venue?.venue_comments));
  }, [dispatch, venue?.venue_comments]);

  useEffect(() => {
    dispatch(sortedv());
  }, [sorting_value]);

  // Sorting select Box options
  const options = [
    { id: "date-desc", name: "Newest" },
    { id: "date-asc", name: "Oldest" },
  ];

  useEffect(() => {
    const apiKey = process.env.REACT_APP_MAP_API_KEY;

    if (!venue) {
      return; // Exit early if venue or API key is not available
    }

    const address = `${venue.street_address}, ${venue.city}, ${venue.state} ${venue.postal_code}, ${venue.country}`;

    const encodedAddress = encodeURIComponent(address);
    const mapsUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${encodedAddress}`;

    // const iframe = document.createElement("iframe");
    // iframe.setAttribute("width", "100%");
    // iframe.setAttribute("height", "500");
    // iframe.setAttribute("frameborder", "0");
    // iframe.setAttribute("style", "border:0");
    // iframe.setAttribute("src", mapsUrl);

    const container = document?.getElementById("BrowseVenueMap");

    if (container) {
      const iframe = document.createElement("iframe");
      iframe.setAttribute("width", "100%");
      iframe.setAttribute("height", "500");
      iframe.setAttribute("frameborder", "0");
      iframe.setAttribute("style", "border:0");
      iframe.setAttribute("src", mapsUrl);

      container.innerHTML = "";
      container.appendChild(iframe);

      return () => {
        container.innerHTML = "";
      };
    }
  }, [venue]);

  return (
    <Layout>
      {venue && (
        <>
          <Breadcrumb page={venue?.name} link="venues" />
          <BlurryHeader img={venue.images[0].name} page="venue" />
          <section className="venues-detail-section">
            <div className="container mb-5">
              <div className="row">
                <aside className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-12 col-lg-6">
                          <h1 className="text-center text-lg-start">
                            {venue.name}
                          </h1>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="text-muted text-center text-lg-end">
                            {venue.type.name}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className={`col-12 ${
                            venue?.venue_page === 1 && "col-lg-8"
                          }`}
                        >
                          {(venue.seated_guests_number ||
                            venue.standing_guests_number) && (
                            <dl className="dlist-align dlist-border clearfix mb-0">
                              <dt>Guests</dt>
                              <dd>
                                <p>
                                  Seated: {venue.seated_guests_number} -
                                  Standing:
                                  {venue.standing_guests_number}
                                </p>
                              </dd>
                            </dl>
                          )}

                          {venue?.event_page === 1 && (
                            <dl className="dlist-align dlist-border clearfix mb-0">
                              <dt>Location</dt>
                              <dd>
                                <div id="BrowseVenueMap"></div>

                                <dl className="mt-3">
                                  <dt>Address</dt>
                                  <dd>
                                    {venue.street_address},{" "}
                                    {venue.street_address_2}
                                    {venue.city}, {venue.state}{" "}
                                    {venue.postal_code},
                                    {countries?.map((curElem) => {
                                      if (curElem.id == venue?.country) {
                                        return (
                                          <React.Fragment key={curElem.id}>
                                            {curElem.name}
                                          </React.Fragment>
                                        );
                                      }
                                      return null; // or any other default value if the condition is not met
                                    })}
                                  </dd>
                                </dl>
                                {venue.neighborhoods && (
                                  <dl className="mt-3">
                                    <dt>Neighborhoods</dt>
                                    <dd>{venue.neighborhoods}</dd>
                                  </dl>
                                )}
                              </dd>
                            </dl>
                          )}

                          <dl className="dlist-align dlist-border clearfix mb-0">
                            <dt>The space</dt>
                            <dd>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: venue.description,
                                }}
                              ></p>
                            </dd>
                          </dl>
                          {venue.pricing && (
                            <dl className="dlist-align dlist-border clearfix mb-0">
                              <dt>Pricing</dt>
                              <dd>
                                <p>{venue.pricing}</p>
                              </dd>
                            </dl>
                          )}
                          {venue.pricing && (
                            <dl className="dlist-align dlist-border clearfix mb-0">
                              <dt>Availability</dt>
                              <dd>
                                <p>{venue.availability}</p>
                              </dd>
                            </dl>
                          )}
                          {venue.pricing && (
                            <dl className="dlist-align dlist-border clearfix mb-0">
                              <dt>Food and beverage</dt>
                              <dd>
                                <p>{venue.food_beverage}</p>
                              </dd>
                            </dl>
                          )}
                        </div>
                        {/* add qoute form */}
                        {venue?.venue_page === 1 && (
                          <div className="col-12 col-lg-4 mt-5 mt-lg-0">
                            <div className="card">
                              <article className="card-body">
                                <h4 className="card-title mb-4 mt-1 text-center">
                                  <FaCalendarCheck /> Request a quote
                                </h4>
                                <form className="signform">
                                  <div id="">
                                    <div className="mb-4">
                                      <label
                                        htmlFor="venue_quote_email"
                                        className="form-label fw-600 m-0"
                                      >
                                        Your email
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        type="email"
                                        id="venue_quote_email"
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="mb-4">
                                      <label
                                        htmlFor="venue_quote_phonenumber"
                                        className="form-label fw-600 m-0"
                                      >
                                        Phone number
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        id="venue_quote_phonenumber"
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="mb-4">
                                      <label
                                        htmlFor="venue_quote_guests"
                                        className="form-label fw-600 m-0"
                                      >
                                        Number of guests
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        id="venue_quote_guests"
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="mb-4">
                                      <label
                                        htmlFor="venue_quote_note"
                                        className="form-label fw-600 m-0"
                                      >
                                        Additional note
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <textarea className="form-control"></textarea>
                                    </div>
                                    <div className="mb-4">
                                      <button
                                        id="venue_quote_save"
                                        className="btn btn-dark btn-block"
                                        disabled
                                        type="button"
                                      >
                                        Send
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </article>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* comments */}
                      {venue_page_settings?.enable_comments !== "no" && (
                        <div className="mt-5">
                          <div className="comment d-flex align-item-center justify-content-between pe-3 ps-3 pb-3">
                            <div>
                              <span>
                                {venue?.venue_comments?.length || 0} comments
                              </span>
                            </div>

                            <div>
                              <div className="d-flex align-items-center gap-2">
                                <label htmlFor="" className="pe-2">
                                  Sort by
                                </label>
                                <SelectBox
                                  name="sorting_value"
                                  options={options}
                                  val={sorting_value}
                                  change={add_filter3}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="comment">
                            {filter_venue_comments?.map((comment, i) => (
                              <React.Fragment key={i}>
                                <div className="mt-3 mb-2">
                                  <span className="avatar">
                                    <FaRegUser />
                                  </span>
                                  <h6 className="ms-2 d-inline-block mb-0">
                                    {comment?.user?.first_name}{" "}
                                    {comment?.user?.last_name}
                                  </h6>
                                </div>
                                <p className="mt-2">{comment.comment}</p>
                              </React.Fragment>
                            ))}
                          </div>
                          <div className="comment-form mt-3 mb-5 clearfix">
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-2 col-md-1">
                                  <div className="avatar">
                                    <img src="/./images/avatar.jpg" alt="" />
                                  </div>
                                </div>
                                <div className="col-10 col-md-11">
                                  <div className="mb-3">
                                    <textarea
                                      className="form-control"
                                      rows="3"
                                      placeholder="Add a comment"
                                      onChange={(e) =>
                                        setComment({
                                          ...comment,
                                          comment: e.target.value,
                                        })
                                      }
                                      value={comment?.comment}
                                    ></textarea>
                                    {errors.comment && (
                                      <small className="text-danger mt-1">
                                        {errors.comment}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <button
                                className="btn btn-dark rounded-5 btn-sm"
                                disabled={isPending ? "disabled" : ""}
                                type="submit"
                              >
                                {isPending ? (
                                  <FaSyncAlt className="spinIcon" />
                                ) : (
                                  "post"
                                )}
                              </button>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </section>
        </>
      )}
    </Layout>
  );
};

export default VenuesDetails;
