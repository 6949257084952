import { Dropdown, Pagination, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaEdit,
  FaEllipsisV,
  FaEye,
  FaEyeSlash,
  FaInfoCircle,
  FaTrashRestoreAlt,
} from "react-icons/fa";
import Alert from "../../../../../global/alert/Alert";
import {
  useDelete,
  useStatusChange,
} from "../../../../../../utils/CustomFunctions";
import { Link } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";

const CountrieTable = ({ countries }) => {
  // this is alert maessage
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No audiences found",
  };

  // for state change
  const { loading, handleStatusChange } = useStatusChange(
    "admin",
    "country_change_status",
    "countries",
    "countries"
  );
  // for soft delete and parmanently delete
  const { isLoading, handleDelete } = useDelete(
    "admin",
    "countries",
    "countries"
  );

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = countries?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <div className="row organizer-event">
        <div className="col-12">
          {countries && countries.length > 0 ? (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Events count</th>
                      <th>Flag</th>
                      <th>Status</th>
                      <th className="text-center">
                        <AiTwotoneSetting />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((country) => {
                      const items = [
                        {
                          label: (
                            <Link
                              to={`${country.id}/edit`}
                              className="d-flex align-items-center"
                            >
                              <FaEdit className="me-1" />
                              Edit
                            </Link>
                          ),
                          key: "0",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) => handleStatusChange(e, country.id)}
                            >
                              {country.status === "visible" ? (
                                <>
                                  <FaEyeSlash className="me-1" />
                                  Hidden
                                </>
                              ) : (
                                <>
                                  <FaEye className="me-1" /> Show
                                </>
                              )}
                            </Link>
                          ),
                          key: "1",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(
                                  e,
                                  country.id,
                                  "country_delete",
                                  ""
                                )
                              }
                            >
                              {country.deleted_at !== null ? (
                                <>
                                  <FaTrashRestoreAlt className="me-1" /> Restore
                                </>
                              ) : (
                                <>
                                  <IoCloseSharp className="me-1" />
                                  Delete
                                </>
                              )}
                            </Link>
                          ),
                          key: "3",
                        },
                        country.deleted_at && {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(
                                  e,
                                  country.id,
                                  "country_delete_permanent",
                                  "delete_permanent"
                                )
                              }
                            >
                              <MdDelete className="me-1" />
                              Delete permanently
                            </Link>
                          ),
                          key: "4",
                        },
                      ];
                      return (
                        <tr key={country.id}>
                          <td className="text-capitalize">{country?.name}</td>
                          <td>{country?.event_count}</td>
                          <td>
                            <Tooltip title="Enlarge">
                              {country?.flag ? (
                                <Link
                                  className="fancybox"
                                  to={country?.flag}
                                  target="_blank"
                                >
                                  <img
                                    src={country?.flag}
                                    className="img-thumbnail img-50-50"
                                  />
                                </Link>
                              ) : (
                                <img
                                  src="/./images/noPhoto.png"
                                  className="img-thumbnail img-50-50"
                                  alt=""
                                />
                              )}
                            </Tooltip>
                          </td>
                          <td className="">
                            <span
                              className={`badge badge-${
                                country.status === "visible"
                                  ? "success"
                                  : "danger"
                              } d-inline-flex align-items-center`}
                            >
                              {country.status === "visible" ? (
                                <>
                                  <FaEye className="me-1" /> Visible
                                </>
                              ) : (
                                <>
                                  <FaEyeSlash className="me-1" />
                                  Hidden
                                </>
                              )}
                            </span>

                            {country.deleted_at && (
                              <>
                                <br />
                                <br />
                                <span className="badge badge-danger d-inline-flex align-items-center">
                                  <IoCloseSharp className="me-1" />
                                  Deleted
                                </span>
                              </>
                            )}
                          </td>
                          <td className="text-center">
                            <Dropdown
                              menu={{
                                items,
                              }}
                              trigger={["click"]}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Space className="justify-content-center">
                                  <FaEllipsisV />
                                </Space>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <Alert {...msg} />
          )}
        </div>
      </div>

      {countries?.length > 10 && (
        <Pagination
          current={currentPage}
          total={countries.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};

export default CountrieTable;
