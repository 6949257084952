import React, { useEffect, useState } from "react";
import Top from "./Top";
import { FaInfoCircle, FaSyncAlt } from "react-icons/fa";
import Alert from "../global/alert/Alert";
import Card from "./Card";
import { useDispatch, useSelector } from "react-redux";
import BlogSidebar from "../sidebar/BlogSidebar";
import {
  clearAllFilters,
  loadFilterBlog,
  sorted,
} from "../../store/slice/BlogFilters";
import { Pagination } from "antd";

const Blogs = () => {
  const dispatch = useDispatch();
  const { blogs, blog_setting, loading } = useSelector(
    (state) => state.AllData
  );
  const { filter_blogs, sorting_value } = useSelector(
    (state) => state.blogFilters
  );

  useEffect(() => {
    dispatch(loadFilterBlog(blogs));
  }, [dispatch, blogs]);

  useEffect(() => {
    dispatch(clearAllFilters());
  }, []);

  useEffect(() => {
    dispatch(sorted());
  }, [sorting_value]);

  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No blogs found",
  };

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = blog_setting?.number || 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = filter_blogs?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <div className="container blog">
        <Top data={blogs} state="blogs" />
        <div className="row">
          <div className="col-lg-9 order-1 order-lg-2">
            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <FaSyncAlt className="spinIcon" />
              </div>
            ) : (
              <div className="row">
                {filter_blogs.length > 0 ? (
                  currentData.map((blog) => {
                    return <Card blogs={blog} key={blog.id} />;
                  })
                ) : (
                  <Alert {...msg} />
                )}
              </div>
            )}
            {filter_blogs?.length > itemsPerPage && (
              <Pagination
                current={currentPage}
                total={filter_blogs.length}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
              />
            )}
          </div>
          <div className="col-lg-3 order-2 order-lg-1">
            <BlogSidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
