import { Radio, Tooltip } from "antd";
import React from "react";
import { FaSearch, FaSearchengin, FaSyncAlt } from "react-icons/fa";
import CategoriesTable from "./CategoriesTable";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import Filters from "../Filters";

const Categories = () => {
  const { event_categories, loading } = useSelector((state) => state.admin);
  const { filter_data } = useSelector((state) => state.organizerFilters);

  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event MyScanners">
        <Filters event_categories={event_categories} />
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <>
          <CategoriesTable categories={filter_data.event_categories} />
        </>
      )}
    </>
  );
};

export default Categories;
