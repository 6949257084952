import React, { useEffect } from "react";
import OrganizerSidebar from "./organizerSidebar/OrganizerSidebar";
import { Outlet } from "react-router-dom";
import { Layout } from "../../../LayoutUser/Layout";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../../store/slice/Organizer";
import { fetchTickets } from "../../../../store/slice/Cart";
import {
  filteredOrganizerEvents,
  filteredOrganizerOrders,
  filteredOrganizerPayoutRequests,
  filteredOrganizerReports,
  filteredOrganizerReviews,
  filteredOrganizerSales,
  filteredOrganizerScanners,
  filteredOrganizerVenues,
} from "../../../../store/slice/OrganizerFilters";
import { fetchuUserData } from "../../../../store/slice/Attendee";

const Organizer = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);
  const { cart } = useSelector((state) => state.CartDetails);
  // add venue, event,scanner and sales in arrays depending on(later)
  useEffect(() => {
    dispatch(
      fetchuUserData({
        getData: "attendee/favorites",
        state: "favorites",
        token: user.token,
      })
    );
    dispatch(
      fetchData({
        getData: "organizer/venues_details",
        token: user.token,
        state: "venues",
      })
    );
    dispatch(
      fetchData({
        getData: "organizer/scanners",
        token: user.token,
        state: "scanners",
      })
    );
    dispatch(
      fetchData({
        getData: "organizer/sales",
        token: user.token,
        state: "sales",
      })
    );
    dispatch(
      fetchData({
        getData: "organizer/events",
        token: user.token,
        state: "events",
      })
    );

    dispatch(
      fetchData({
        getData: "organizer/event_categories",
        token: user.token,
        state: "event_categories",
      })
    );

    dispatch(
      fetchData({
        getData: "admin/audiences",
        token: user.token,
        state: "audiences",
      })
    );
    dispatch(
      fetchData({
        getData: "admin/countries",
        token: user.token,
        state: "countries",
      })
    );
    dispatch(
      fetchData({
        getData: "admin/languages",
        token: user.token,
        state: "languages",
      })
    );
    dispatch(
      fetchData({
        getData: "organizer/orders",
        token: user.token,
        state: "orders",
      })
    );
    dispatch(
      fetchData({
        getData: "organizer/event_reviews",
        token: user.token,
        state: "event_reviews",
      })
    );

    dispatch(
      fetchData({
        getData: "organizer/payout_reports",
        token: user.token,
        state: "payout_reports",
      })
    );
    dispatch(
      fetchData({
        getData: "organizer/scanner_setting",
        token: user.token,
        state: "scanner_setting",
      })
    );
    // error && toast.error(error);
  }, []);

  useEffect(() => {
    dispatch(
      fetchTickets({
        getData: "attendee/check_out_cart",
        state: "tickets",
        token: user.token,
      })
    );
  }, [user.token, cart]);

  const { filters } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    dispatch(filteredOrganizerEvents());
    dispatch(filteredOrganizerVenues());
    dispatch(filteredOrganizerScanners());
    dispatch(filteredOrganizerSales());
    dispatch(filteredOrganizerReviews());
    dispatch(filteredOrganizerReports());
    dispatch(filteredOrganizerPayoutRequests());
    dispatch(filteredOrganizerOrders());
    window.scrollTo(0, 0);
  }, [filters]);

  return (
    <Layout>
      <div className="container attendeeSidebar">
        <div className="row">
          <aside className="col-lg-3 pt-3 pt-lg-0 d-lg-block d-none">
            <OrganizerSidebar />
          </aside>
          <div className="col-lg-9 mt-4 mt-lg-0">
            <Outlet />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Organizer;
