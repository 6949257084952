import "../layout/style.scss";
import { Radio } from "antd";
import Count from "../../../../../count/Count";
import { useSelector } from "react-redux";
import { useSignUp } from "../../../../../../utils/CustomFunctions";
import { FaSyncAlt } from "react-icons/fa";

const EventsListPage = () => {
  const radioOption = [
    { option: "Show map button", name: "map" },
    { option: "Show calendar button", name: "calendar" },
    { option: "Show RSS feed button", name: "rss" },
    { option: "Show category filter", name: "category" },
    { option: "Show location filter", name: "location" },
    { option: "Show date filter", name: "date" },
    { option: "Show ticket price filter", name: "ticket_price" },
    { option: "Show audience filter", name: "audience" },
  ];

  const { event_setting } = useSelector((state) => state.admin);

  const initialState = {
    number: event_setting?.number || "",
    map: event_setting?.map || "yes",
    category: event_setting?.category || "yes",
    rss: event_setting?.rss || "yes",
    location: event_setting?.location || "yes",
    ticket_price: event_setting?.ticket_price || "yes",
    audience: event_setting?.audience || "yes",
    date: event_setting?.date || "yes",
    calendar: event_setting?.calendar || "yes",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    if (!values.number) newErrors.number = "This value should not be null.";

    return newErrors;
  };

  const { values, setValues, errors, isPending, handleChange, handleSubmit } =
    useSignUp(
      initialState,
      validateFields,
      `admin/add_event_setting`,
      "event_setting"
    );
  return (
    <>
      <div className="card box">
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form onSubmit={handleSubmit} className="signform mt-3 ">
              <div className="mb-3">
                <label htmlFor="Facebook_app_id" className="form-label fw-600">
                  Number of events per page
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Count
                  name="number"
                  value={values.number}
                  state="values"
                  setState={setValues}
                />
                {errors.number && (
                  <small className="text-danger d-block mt-1">
                    {errors.number}
                  </small>
                )}
              </div>

              {radioOption.map((curElem, index) => {
                return (
                  <div className="ProfileSettingOptionsContainer" key={index}>
                    <div className="ProfileSettingOptionsBox">
                      <span className="ProfileSettingOptionTitle">
                        {curElem.option}
                        <span style={{ color: "red" }}>*</span>
                      </span>

                      <div className="ProfileSettingRadioBtns">
                        <Radio
                          className="ProfileSettingRadioBtn"
                          name={curElem.name}
                          checked={values[curElem.name] === "no"}
                          onChange={handleChange}
                          value="no"
                        >
                          No
                        </Radio>
                        <Radio
                          className="ProfileSettingRadioBtn "
                          name={curElem.name}
                          checked={values[curElem.name] === "yes"}
                          onChange={handleChange}
                          value="yes"
                        >
                          Yes
                        </Radio>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="form-group mt-4 mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  disabled={isPending ? "disabled" : ""}
                  type="submit"
                >
                  {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsListPage;
