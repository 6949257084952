import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import "./adminsidebar.scss";
import { Link, useLocation } from "react-router-dom";
import {
  FaCalendar,
  FaFileInvoiceDollar,
  FaFunnelDollar,
  FaMapMarkerAlt,
  FaPrint,
  FaQrcode,
  FaRegIdCard,
  FaShoppingCart,
  FaStar,
  FaTachometerAlt,
  FaTicketAlt,
  FaUserCog,
} from "react-icons/fa";
import { BsFillWalletFill } from "react-icons/bs";

const items = [
  {
    icon: <FaTachometerAlt />,
    key: "1",
    label: "Dashboard",
    type: "link",
    path: "/en/dashboard/organizer",
  },
  {
    icon: <FaRegIdCard />,
    key: "2",
    label: "My organizer profile",
    type: "link",
    path: "/en/dashboard/organizer/profile",
  },
  {
    icon: <FaShoppingCart />,
    key: "2.0",
    label: "Cart",
    type: "link",
    path: "/en/dashboard/organizer/cart",
  },
  {
    icon: <FaTicketAlt />,
    key: "2.1",
    label: "My Tickets",
    type: "link",
    path: "/en/dashboard/organizer/my-tickets",
  },
  {
    icon: <BsFillWalletFill />,
    key: "2.2",
    label: "My Wallet",
    type: "link",
    path: "/en/dashboard/organizer/my-wallet",
  },
  {
    icon: <FaCalendar />,
    key: "3",
    label: "My Events",
    type: "link",
    path: "/en/dashboard/organizer/my-events",
  },
  {
    icon: <FaMapMarkerAlt />,
    key: "4",
    label: "My Venues",
    type: "link",
    path: "/en/dashboard/organizer/my-venues",
  },

  {
    icon: <FaQrcode />,
    key: "sub3",
    label: "Scanners App",
    children: [
      {
        label: "My Scanners",
        key: "5",
        path: "/en/dashboard/organizer/my-scanners",
      },
      {
        label: "Scanner App Settings",
        key: "6",
        path: "/en/dashboard/organizer/settings/scanner-app",
      },
    ],
  },
  {
    icon: <FaPrint />,
    key: "7",
    label: "My Points of sale",
    type: "link",
    path: "/en/dashboard/organizer/my-points-of-sale",
  },
  {
    icon: <FaStar />,
    key: "8",
    label: "Reviews",
    type: "link",
    path: "/en/dashboard/organizer/reviews",
  },
  {
    icon: <FaFileInvoiceDollar />,
    key: "sub4",
    label: "Payouts",
    children: [
      {
        label: "Payout requests",
        key: "9",
        path: "/en/dashboard/organizer/my-payout-requests",
      },
      {
        label: "Payout methods",
        key: "10",
        path: "/en/dashboard/organizer/settings/payouts",
      },
    ],
  },
  {
    icon: <FaFunnelDollar />,
    key: "11",
    label: "Reports",
    type: "link",
    path: "/en/dashboard/organizer/reports",
  },
  {
    icon: <FaUserCog />,
    key: "sub5",
    label: "Account",
    children: [
      {
        label: "Change Password",
        key: "12",
        path: "/en/dashboard/organizer/account/change-password",
      },
    ],
  },
];

const OrganizerSidebar = () => {
  const { pathname } = useLocation();
  // Function to find the selected key
  const findSelectedKey = (items, pathname) => {
    for (const item of items) {
      if (item.children) {
        const childItem = item.children.find(
          (child) => child.path === pathname
        );
        if (childItem) {
          return [item.key, childItem.key];
        }
      } else if (item.path === pathname) {
        return [item.key];
      }
    }
    return [];
  };

  // State to store the open keys and selected keys
  const [openKeys, setOpenKeys] = useState(() => {
    // Initialize with the value from localStorage or default to an empty array
    const storedOpenKeys = localStorage.getItem("openKeys");
    return storedOpenKeys ? JSON.parse(storedOpenKeys) : [];
  });

  const [selectedKeys, setSelectedKeys] = useState(() => {
    const storedSelectedKeys = localStorage.getItem("selectedKeys");
    return storedSelectedKeys ? JSON.parse(storedSelectedKeys) : [];
  });
  // Update the open keys and selected keys when pathname changes
  useEffect(() => {
    const newSelectedKeys = findSelectedKey(items, pathname);
    setOpenKeys(newSelectedKeys.slice(0, -1)); // Exclude the last key from openKeys
    setSelectedKeys(newSelectedKeys);
    // Save the open keys and selected keys to localStorage
    localStorage.setItem(
      "openKeys",
      JSON.stringify(newSelectedKeys.slice(0, -1))
    );
    localStorage.setItem("selectedKeys", JSON.stringify(newSelectedKeys));
  }, [pathname, items]);

  return (
    <>
      <div>
        <div className="Sidebar">
          <Menu
            // defaultSelectedKeys={selectedKey}
            mode="inline"
            openKeys={openKeys}
            onOpenChange={(newOpenKeys) => {
              setOpenKeys(newOpenKeys);
              // Save the open keys to localStorage
              localStorage.setItem("openKeys", JSON.stringify(newOpenKeys));
            }}
            selectedKeys={selectedKeys}
          >
            {items.map((item) =>
              item.children ? (
                <Menu.SubMenu
                  key={item.key}
                  icon={item.icon}
                  title={item.label}
                  items={item.children} // Use the 'items' prop here
                >
                  {item.children.map((childItem) => (
                    <Menu.Item key={childItem.key}>
                      <Link to={childItem.path}>{childItem.label}</Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <Menu.Item key={item.key} icon={item.icon}>
                  <Link to={item.path}>{item.label}</Link>
                </Menu.Item>
              )
            )}
          </Menu>
        </div>
      </div>
    </>
  );
};

export default OrganizerSidebar;
