import React, { useEffect, useState } from "react";
import { Card } from "../../events/Card/Card";
import { FaInfoCircle, FaSyncAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import Alert from "../../global/alert/Alert";

const FeaturedEvents = () => {
  const col = "col-sm-6 col-lg-4";
  const { events, home_page_setting, loading } = useSelector(
    (state) => state.AllData
  );
  const [event, setEvent] = useState([]);

  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No Upcoming events found",
  };

  useEffect(() => {
    // const sortedEvents = [...events].sort((a, b) => b.id - a.id);
    const limitedData = events.slice(
      0,
      home_page_setting?.number_event ? home_page_setting?.number_event : 6
    );
    setEvent(limitedData);
  }, [events, home_page_setting]);

  return (
    <section className="event-featured-section pt-5 pb-5 mt-3">
      <h4
        className="text-center mb-4 pb-1 text-muted text-uppercase "
        style={{ fontSize: "600" }}
      >
        Upcoming events
      </h4>

      <div className="container">
        {loading ? (
          <div className="d-flex align-items-center justify-content-center">
            <FaSyncAlt className="spinIcon" />
          </div>
        ) : (
          <div className="row border-0">
            {event?.length > 0 ? (
              event?.map((curElem, index) => {
                return (
                  <Card
                    col={col}
                    data={curElem}
                    key={index}
                    clas="home-card-event"
                  />
                );
              })
            ) : (
              <Alert {...msg} />
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default FeaturedEvents;
