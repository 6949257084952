import React from "react";

const Privacypolicy = () => {
  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-sm-12">
          <div className="card box">
            <div className="card-body">
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent",
                }}
              >
                <strong style={{ fontSize: "26px", fontFamily: "Arial" }}>
                  <span style={{ fontSize: "26px", fontFamily: "Arial" }}>
                    <span style={{ fontSize: "26px", fontFamily: "Arial" }}>
                      <span
                        data-custom-className="title"
                        style={{
                          fontSize: "26px",
                          fontFamily: "Arial",
                        }}
                      >
                        PRIVACY NOTICE
                      </span>
                    </span>
                  </span>
                </strong>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent",
                }}
              >
                <br />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    color: "rgb(127, 127, 127)",
                    fontSize: "15px",
                    fontFamily: "Arial",
                    background: "transparent",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "15px",
                        fontFamily: "Arial",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        data-custom-className="subtitle"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Arial",
                          background: "transparent",
                        }}
                      >
                        Last updated{" "}
                        <bdt className="question">February 18, 2023</bdt>
                      </span>
                    </span>
                  </strong>
                </span>
              </div>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent",
                }}
              >
                <br />
              </div>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent",
                }}
              >
                <br />
              </div>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent",
                }}
              >
                <br />
              </div>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    color: "rgb(127, 127, 127)",
                    fontSize: "15px",
                    fontFamily: "Arial",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <span
                      data-custom-className="body_text"
                      style={{
                        fontSize: "14px",
                        fontFamily: "Arial",
                        background: "transparent",
                      }}
                    >
                      This privacy notice for{" "}
                      <bdt className="question">The Ojunta Group LLC</bdt>{" "}
                      (doing business as <bdt className="question">feevaa</bdt>
                      ), describes how and why we might collect, store, use,
                      and/or share ("
                      <bdt className="block-component"></bdt>" "
                      <strong>feevaa</strong>," "<strong>we</strong>," "
                      <strong>us</strong>," or "<strong>our</strong>") your
                      information when you use our services ("
                      <strong>Services</strong>"), such as when you:
                    </span>
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "15px",
                    color: "rgb(127, 127, 127)",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      color: "rgb(89, 89, 89)",
                      fontFamily: "Arial",
                    }}
                  >
                    <bdt className="block-component"></bdt>
                  </span>
                </span>
              </div>

              <ul
                style={{
                  listStyleType: "square",
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent",
                }}
              >
                <li style={{ background: "transparent", lineHeight: 1.5 }}>
                  <span
                    style={{
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                      background: "transparent",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Arial",
                          background: "transparent",
                        }}
                      >
                        Visit our website
                        <bdt className="block-component"></bdt> at&nbsp;
                        <bdt className="question">
                          <a
                            href="https://www.feevaa.com/"
                            target="_blank"
                            data-custom-className="link"
                            style={{
                              color: "rgb(48, 48, 241)",
                              wordBreak: "break-word",
                            }}
                          >
                            https://www.feevaa.com
                          </a>
                        </bdt>
                        <span
                          style={{
                            fontSize: "15px",
                            background: "transparent",
                          }}
                        >
                          <span
                            style={{
                              background: "transparent",
                              fontSize: "14px",
                            }}
                          >
                            <span
                              data-custom-className="body_text"
                              style={{ background: "transparent" }}
                            >
                              <span
                                style={{
                                  fontSize: "15px",
                                  background: "transparent",
                                }}
                              >
                                <span
                                  style={{
                                    background: "transparent",
                                    fontSize: "14px",
                                  }}
                                >
                                  <bdt
                                    className="statement-end-if-in-editor"
                                    style={{
                                      background: "transparent",
                                    }}
                                  >
                                    , or any website of ours that links to this
                                    privacy notice
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent",
                }}
              >
                <bdt
                  className="block-component"
                  style={{ background: "transparent" }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        style={{
                          background: "transparent",
                          color: "rgb(127, 127, 127)",
                        }}
                      >
                        <span
                          data-custom-className="body_text"
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "14px",
                            fontFamily: "Arial",
                            background: "transparent",
                          }}
                        >
                          <bdt
                            className="block-component"
                            style={{ background: "transparent" }}
                          ></bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </bdt>
              </div>

              <ul
                style={{
                  listStyleType: "square",
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent",
                }}
              >
                <li style={{ background: "transparent", lineHeight: 1.5 }}>
                  <span
                    style={{
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                      background: "transparent",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Arial",
                          background: "transparent",
                        }}
                      >
                        Download and use
                        <bdt className="block-component"></bdt> our mobile
                        application (<bdt className="question">FeeVaa</bdt>
                        <span
                          style={{
                            fontSize: "15px",
                            background: "transparent",
                          }}
                        >
                          <span style={{ background: "transparent" }}>
                            <span
                              data-custom-className="body_text"
                              style={{
                                fontSize: "14px",
                                background: "transparent",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "15px",
                                  background: "transparent",
                                }}
                              >
                                <span
                                  style={{
                                    background: "transparent",
                                    fontSize: "14px",
                                  }}
                                >
                                  <bdt className="statement-end-if-in-editor"></bdt>
                                  , or any other application of ours that links
                                  to this privacy notice
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                    color: "rgb(127, 127, 127)",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <bdt className="block-component"></bdt>
                  </span>
                </span>
              </div>

              <ul
                style={{
                  listStyleType: "square",
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent",
                }}
              >
                <li style={{ background: "transparent", lineHeight: 1.5 }}>
                  <span
                    style={{
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                      background: "transparent",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Arial",
                          background: "transparent",
                        }}
                      >
                        Engage with us in other related ways, including any
                        sales, marketing, or events
                        <span
                          style={{
                            fontSize: "15px",
                            background: "transparent",
                          }}
                        >
                          <span style={{ background: "transparent" }}>
                            <span
                              data-custom-className="body_text"
                              style={{
                                fontSize: "14px",
                                background: "transparent",
                              }}
                            >
                              <bdt className="statement-end-if-in-editor"></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                    color: "rgb(127, 127, 127)",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <strong style={{ background: "transparent" }}>
                      Questions or concerns?&nbsp;
                    </strong>
                    Reading this privacy notice will help you understand your
                    privacy rights and choices. If you do not agree with our
                    policies and practices, please do not use our Services. If
                    you still have any questions or concerns, please contact us
                    at&nbsp;
                    <bdt className="question">info@feevaa.com</bdt>.
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <strong style={{ background: "transparent" }}>
                  <span
                    style={{
                      fontSize: "15px",
                      background: "transparent",
                    }}
                  >
                    <span
                      data-custom-className="heading_1"
                      style={{
                        fontSize: "19px",
                        fontFamily: "Arial",
                        background: "transparent",
                      }}
                    >
                      SUMMARY OF KEY POINTS
                    </span>
                  </span>
                </strong>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <strong style={{ background: "transparent" }}>
                      <em style={{ background: "transparent" }}>
                        This summary provides key points from our privacy
                        notice, but you can find out more details about any of
                        these topics by clicking the link following each key
                        point or by using our table of contents below to find
                        the section you are looking for.&nbsp;
                      </em>
                    </strong>
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <strong style={{ background: "transparent" }}>
                      What personal information do we process?
                    </strong>
                    &nbsp;When you visit, use, or navigate our Services, we may
                    process personal information depending on how you interact
                    with&nbsp;
                    <bdt
                      className="block-component"
                      style={{ background: "transparent" }}
                    ></bdt>
                    <bdt
                      className="question"
                      style={{ background: "transparent" }}
                    >
                      feevaa.com&nbsp;
                    </bdt>
                    <bdt
                      className="else-block"
                      style={{ background: "transparent" }}
                    ></bdt>
                    and the Services, the choices you make, and the products and
                    features you use.&nbsp;
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <strong style={{ background: "transparent" }}>
                      Do we process any sensitive personal information?
                    </strong>
                    &nbsp;
                    <bdt
                      className="block-component"
                      style={{ background: "transparent" }}
                    ></bdt>
                    We do not process sensitive personal information.
                    <bdt
                      className="else-block"
                      style={{ background: "transparent" }}
                    ></bdt>
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <strong style={{ background: "transparent" }}>
                      Do we receive any information from third parties?
                    </strong>
                    &nbsp;
                    <bdt
                      className="block-component"
                      style={{ background: "transparent" }}
                    ></bdt>
                    We do not receive any information from third parties.
                    <bdt
                      className="else-block"
                      style={{ background: "transparent" }}
                    ></bdt>
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <strong style={{ background: "transparent" }}>
                      How do we process your information?
                    </strong>
                    &nbsp;We process your information to provide, improve, and
                    administer our Services, communicate with you, for security
                    and fraud prevention, and to comply with law. We may also
                    process your information for other purposes with your
                    consent. We process your information only when we have a
                    valid legal reason to do so.&nbsp;
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <br />
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <strong style={{ background: "transparent" }}>
                      In what situations and with which&nbsp;
                      <bdt
                        className="block-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                      parties do we share personal information?
                    </strong>
                    &nbsp;We may share information in specific situations and
                    with specific&nbsp;
                    <bdt
                      className="block-component"
                      style={{ background: "transparent" }}
                    ></bdt>
                    third parties.&nbsp;
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <strong style={{ background: "transparent" }}>
                      How do we keep your information safe?
                    </strong>
                    &nbsp;We have&nbsp;
                    <bdt
                      className="block-component"
                      style={{ background: "transparent" }}
                    ></bdt>
                    organizational
                    <bdt
                      className="statement-end-if-in-editor"
                      style={{ background: "transparent" }}
                    ></bdt>
                    &nbsp;and technical processes and procedures in place to
                    protect your personal information. However, no electronic
                    transmission over the internet or information storage
                    technology can be guaranteed to be 100% secure, so we cannot
                    promise or guarantee that hackers, cybercriminals, or
                    other&nbsp;
                    <bdt
                      className="block-component"
                      style={{ background: "transparent" }}
                    ></bdt>
                    unauthorized
                    <bdt
                      className="statement-end-if-in-editor"
                      style={{ background: "transparent" }}
                    ></bdt>
                    &nbsp;third parties will not be able to defeat our security
                    and improperly collect, access, steal, or modify your
                    information.&nbsp;
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <strong style={{ background: "transparent" }}>
                      What are your rights?
                    </strong>
                    &nbsp;Depending on where you are located geographically, the
                    applicable privacy law may mean you have certain rights
                    regarding your personal information.&nbsp;
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <strong style={{ background: "transparent" }}>
                      How do you exercise your rights?
                    </strong>
                    &nbsp;The easiest way to exercise your rights is by filling
                    out our data subject request form available&nbsp;
                    <bdt
                      className="block-component"
                      style={{ background: "transparent" }}
                    ></bdt>
                    here:&nbsp;
                    <bdt
                      className="question"
                      style={{ background: "transparent" }}
                    >
                      <a
                        href="https://feevaa.com/en/contact"
                        target="_blank"
                        data-custom-className="link"
                        style={{
                          backgroundImage: "initial !important",
                          backgroundPosition: "initial !important",
                          backgroundSize: "initial !important",
                          backgroundRepeat: "initial !important",
                          backgroundAttachment: "initial !important",
                          backgroundOrigin: "initial !important",
                          backgroundClip: "initial !important",
                          color: "rgb(48, 48, 241) !important",
                          wordBreak: "break-word !important",
                        }}
                      >
                        https://feevaa.com/en/contact
                      </a>
                    </bdt>
                    <bdt
                      className="else-block"
                      style={{ background: "transparent" }}
                    ></bdt>
                    , or by contacting us. We will consider and act upon any
                    request in accordance with applicable data protection laws.
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                id="toc"
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent !important",
                        color: "rgb(0, 0, 0)",
                      }}
                    >
                      <strong style={{ background: "transparent" }}>
                        <span
                          data-custom-className="heading_1"
                          style={{
                            fontSize: "19px",
                            fontFamily: "Arial",
                            background: "transparent",
                          }}
                        >
                          TABLE OF CONTENTS
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </div>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px !important",
                      fontFamily: "Arial !important",
                      wordBreak: "break-word !important",
                    }}
                  >
                    1. WHAT INFORMATION DO WE COLLECT?
                  </span>
                </span>
              </div>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px !important",
                      fontFamily: "Arial !important",
                      wordBreak: "break-word !important",
                    }}
                  >
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                    <a
                      data-custom-className="link"
                      href="https://app.termly.io/document/privacy-policy/d237bcd6-1d78-41b2-9869-2515b1b3953c#infouse"
                      style={{
                        background: "transparent !important",
                        color: "rgb(48, 48, 241) !important",
                        wordBreak: "break-word !important",
                      }}
                    >
                      <bdt
                        className="block-component"
                        style={{
                          background: "transparent !important",
                          color: "rgb(48, 48, 241) !important",
                          wordBreak: "break-word !important",
                        }}
                      ></bdt>
                    </a>
                  </span>
                </span>
              </div>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px !important",
                      fontFamily: "Arial !important",
                      wordBreak: "break-word !important",
                    }}
                  >
                    3.&nbsp;
                    <span
                      style={{
                        fontSize: "15px",
                        background: "transparent !important",
                        color: "rgb(48, 48, 241) !important",
                        wordBreak: "break-word !important",
                      }}
                    >
                      <span
                        style={{
                          color: "rgb(89, 89, 89)",
                          background: "transparent !important",
                          fontSize: "14px !important",
                          wordBreak: "break-word !important",
                        }}
                      >
                        WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                        INFORMATION?
                      </span>
                    </span>
                    <a
                      data-custom-className="link"
                      href="https://app.termly.io/document/privacy-policy/d237bcd6-1d78-41b2-9869-2515b1b3953c#legalbases"
                      style={{
                        background: "transparent !important",
                        color: "rgb(48, 48, 241) !important",
                        wordBreak: "break-word !important",
                      }}
                    >
                      <bdt
                        className="statement-end-if-in-editor"
                        style={{
                          background: "transparent !important",
                          color: "rgb(48, 48, 241) !important",
                          wordBreak: "break-word !important",
                        }}
                      ></bdt>
                    </a>
                  </span>
                </span>
              </div>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <font color="#3030f1" face="Arial">
                      <span
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          wordBreak: "break-word !important",
                        }}
                      >
                        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                        INFORMATION?
                      </span>
                    </font>
                  </span>
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <bdt
                      className="block-component"
                      style={{ background: "transparent" }}
                    ></bdt>
                  </span>
                </span>
              </div>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="link"
                    style={{
                      background: "transparent !important",
                      color: "rgb(48, 48, 241) !important",
                      fontSize: "14px !important",
                      fontFamily: "Arial !important",
                      wordBreak: "break-word !important",
                    }}
                  >
                    5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                    <a href="https://app.termly.io/document/privacy-policy/d237bcd6-1d78-41b2-9869-2515b1b3953c#3pwebsites">
                      <bdt
                        className="statement-end-if-in-editor"
                        style={{
                          background: "transparent !important",
                          wordBreak: "break-word !important",
                        }}
                      ></bdt>
                    </a>
                  </span>
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent !important",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        <span style={{ background: "transparent" }}>
                          <bdt
                            className="block-component"
                            style={{ background: "transparent" }}
                          ></bdt>
                        </span>
                      </span>
                      &nbsp;
                    </span>
                    &nbsp;
                  </span>
                  &nbsp;
                </span>
              </div>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px !important",
                      fontFamily: "Arial !important",
                      wordBreak: "break-word !important",
                    }}
                  >
                    6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </span>
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent !important",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        <span style={{ background: "transparent" }}>
                          <bdt
                            className="statement-end-if-in-editor"
                            style={{ background: "transparent" }}
                          ></bdt>
                        </span>
                      </span>
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        <span style={{ background: "transparent" }}>
                          <span style={{ background: "transparent" }}>
                            <span style={{ background: "transparent" }}>
                              <bdt
                                className="block-component"
                                style={{ background: "transparent" }}
                              ></bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px !important",
                      fontFamily: "Arial !important",
                      wordBreak: "break-word !important",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent !important",
                        wordBreak: "break-word !important",
                      }}
                    >
                      <span
                        style={{
                          background: "transparent !important",
                          wordBreak: "break-word !important",
                        }}
                      >
                        7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                      </span>
                    </span>
                  </span>
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent !important",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        <span style={{ background: "transparent" }}>
                          <span style={{ background: "transparent" }}>
                            <span style={{ background: "transparent" }}>
                              <bdt
                                className="statement-end-if-in-editor"
                                style={{ background: "transparent" }}
                              ></bdt>
                            </span>
                          </span>
                          <bdt
                            className="block-component"
                            style={{ background: "transparent" }}
                          ></bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              {/* 2 */}
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px !important",
                      fontFamily: "Arial !important",
                      wordBreak: "break-word !important",
                    }}
                  >
                    8. HOW LONG DO WE KEEP YOUR INFORMATION?
                  </span>
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent !important",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        <span style={{ background: "transparent" }}>
                          <bdt
                            className="block-component"
                            style={{ background: "transparent" }}
                          ></bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>

              {/* Repeat the above structure for the remaining sections, adjusting the content and styles accordingly */}

              {/* Section 9 */}
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px !important",
                      fontFamily: "Arial !important",
                      wordBreak: "break-word !important",
                    }}
                  >
                    9. HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </span>
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent !important",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        <bdt
                          className="statement-end-if-in-editor"
                          style={{ background: "transparent" }}
                        ></bdt>
                        <bdt
                          className="block-component"
                          style={{ background: "transparent" }}
                        ></bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </div>

              {/* Continue adding sections 10 to 16 */}

              {/* Section 10 */}
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <font color="#3030f1" face="Arial">
                      <span
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          wordBreak: "break-word !important",
                        }}
                      >
                        10. WHAT ARE YOUR PRIVACY RIGHTS?
                      </span>
                    </font>
                  </span>
                </span>
              </div>

              {/* Continue adding sections 11 to 16 */}

              {/* Section 11 */}
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px !important",
                      fontFamily: "Arial !important",
                      wordBreak: "break-word !important",
                    }}
                  >
                    11. CONTROLS FOR DO-NOT-TRACK FEATURES
                  </span>
                </span>
              </div>

              {/* Section 12 */}
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px !important",
                      fontFamily: "Arial !important",
                      wordBreak: "break-word !important",
                    }}
                  >
                    12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </span>
                  <bdt
                    className="block-component"
                    style={{ background: "transparent" }}
                  >
                    <span
                      style={{
                        fontSize: "15px",
                        background: "transparent",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          color: "rgb(89, 89, 89)",
                          fontSize: "14px",
                          fontFamily: "Arial",
                          background: "transparent",
                        }}
                      ></span>
                    </span>
                  </bdt>
                </span>
              </div>

              {/* Continue adding sections 13 to 16 */}

              {/* Section 13 */}
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    color: "rgb(48, 48, 241) !important",
                    fontSize: "15px",
                    fontFamily: "Arial !important",
                    wordBreak: "break-word !important",
                  }}
                >
                  13. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  ></span>
                </span>
              </div>

              {/* Section 14 */}
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px !important",
                      fontFamily: "Arial !important",
                      wordBreak: "break-word !important",
                    }}
                  >
                    14. DO WE MAKE UPDATES TO THIS NOTICE?
                  </span>
                </span>
              </div>

              {/* Section 15 */}
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89)",
                    fontSize: "15px",
                    fontFamily: "Arial !important",
                    wordBreak: "break-word !important",
                  }}
                >
                  15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
              </div>

              {/* Section 16 */}
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    color: "rgb(89, 89, 89)",
                    fontSize: "14px !important",
                    fontFamily: "Arial !important",
                    wordBreak: "break-word !important",
                  }}
                >
                  16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </span>
              </div>

              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>

              <div
                id="infocollect"
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    color: "rgb(127, 127, 127)",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span style={{ background: "transparent" }}>
                        <span
                          id="control"
                          style={{
                            background: "transparent !important",
                            color: "rgb(0, 0, 0)",
                          }}
                        >
                          <strong style={{ background: "transparent" }}>
                            <span
                              data-custom-className="heading_1"
                              style={{
                                fontSize: "19px",
                                fontFamily: "Arial",
                                background: "transparent",
                              }}
                            >
                              1. WHAT INFORMATION DO WE COLLECT?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>

              {/* Section 1 content */}
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>

              {/* Section 2 */}
              <div
                id="personalinfo"
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  data-custom-className="heading_2"
                  style={{
                    background: "transparent !important",
                    fontFamily: "Arial !important",
                    fontSize: "17px !important",
                  }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      background: "transparent !important",
                    }}
                  >
                    <strong
                      style={{
                        background: "transparent !important",
                        fontSize: "17px !important",
                      }}
                    >
                      Personal information you disclose to us
                    </strong>
                  </span>
                </span>
              </div>
              {/*  */}
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent !important",
                }}
              >
                <div style={{ background: "transparent" }}>
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent !important",
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            background: "transparent !important",
                          }}
                        >
                          <span style={{ background: "transparent" }}>
                            <span
                              data-custom-className="body_text"
                              style={{
                                background: "transparent !important",
                                fontSize: "14px",
                              }}
                            >
                              <strong style={{ background: "transparent" }}>
                                <em style={{ background: "transparent" }}>
                                  In Short:
                                </em>
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            background: "transparent !important",
                          }}
                        >
                          <span style={{ background: "transparent" }}>
                            <span
                              data-custom-className="body_text"
                              style={{
                                background: "transparent !important",
                                fontSize: "14px",
                              }}
                            >
                              <strong style={{ background: "transparent" }}>
                                <em style={{ background: "transparent" }}>
                                  &nbsp;
                                </em>
                              </strong>
                              <em style={{ background: "transparent" }}>
                                We collect personal information that you provide
                                to us.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      We collect personal information that you voluntarily
                      provide to us when you&nbsp;
                      <span
                        style={{
                          fontSize: "15px",
                          background: "transparent !important",
                        }}
                      >
                        <bdt
                          className="block-component"
                          style={{
                            background: "transparent !important",
                            fontSize: "14px",
                          }}
                        ></bdt>
                      </span>
                      register on the Services,&nbsp;
                    </span>
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            background: "transparent !important",
                          }}
                        >
                          <bdt
                            className="statement-end-if-in-editor"
                            style={{
                              background: "transparent !important",
                              fontSize: "14px",
                            }}
                          ></bdt>
                        </span>
                      </span>
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        express an interest in obtaining information about us or
                        our products and Services, when you participate in
                        activities on the Services, or otherwise when you
                        contact us.
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                          background: "transparent !important",
                        }}
                      >
                        <bdt
                          className="block-component"
                          style={{
                            background: "transparent !important",
                            fontSize: "14px",
                          }}
                        ></bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <strong style={{ background: "transparent" }}>
                        Personal Information Provided by You.
                      </strong>
                      &nbsp;The personal information that we collect depends on
                      the context of your interactions with us and the Services,
                      the choices you make, and the products and features you
                      use. The personal information we collect may include the
                      following:
                      <span
                        style={{
                          fontSize: "15px",
                          background: "transparent !important",
                        }}
                      >
                        <span
                          data-custom-className="body_text"
                          style={{
                            background: "transparent !important",
                            fontSize: "14px",
                          }}
                        >
                          <bdt
                            className="forloop-component"
                            style={{ background: "transparent" }}
                          ></bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul
                style={{
                  listStyleType: "square",
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent !important",
                }}
              >
                <li
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        names
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <bdt
                        className="forloop-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                    </span>
                  </span>
                </span>
              </div>
              <ul
                style={{
                  listStyleType: "square",
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent !important",
                }}
              >
                <li
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        email addresses
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <bdt
                        className="forloop-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                    </span>
                  </span>
                </span>
              </div>
              <ul
                style={{
                  listStyleType: "square",
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent !important",
                }}
              >
                <li
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        usernames
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <bdt
                        className="forloop-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                    </span>
                  </span>
                </span>
              </div>
              <ul
                style={{
                  listStyleType: "square",
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent !important",
                }}
              >
                <li
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        passwords
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <bdt
                        className="forloop-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                    </span>
                  </span>
                </span>
              </div>
              <ul
                style={{
                  listStyleType: "square",
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent !important",
                }}
              >
                <li
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        organizer name
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                          background: "transparent !important",
                        }}
                      >
                        <span
                          data-custom-className="body_text"
                          style={{
                            background: "transparent !important",
                            fontSize: "14px",
                          }}
                        >
                          <bdt
                            className="forloop-component"
                            style={{ background: "transparent" }}
                          ></bdt>
                        </span>
                        <span
                          data-custom-className="body_text"
                          style={{
                            background: "transparent !important",
                            fontSize: "14px",
                          }}
                        >
                          <bdt
                            className="statement-end-if-in-editor"
                            style={{ background: "transparent" }}
                          ></bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div
                id="sensitiveinfo"
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <strong style={{ background: "transparent" }}>
                      Sensitive Information.
                    </strong>
                    &nbsp;
                    <bdt
                      className="block-component"
                      style={{ background: "transparent" }}
                    ></bdt>
                    We do not process sensitive information.
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <bdt
                      className="else-block"
                      style={{ background: "transparent" }}
                    ></bdt>
                  </span>
                </span>
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <bdt
                        className="block-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                    </span>
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <strong style={{ background: "transparent" }}>
                        Payment Data.
                      </strong>
                      &nbsp;We may collect data necessary to process your
                      payment if you make purchases, such as your payment
                      instrument number, and the security code associated with
                      your payment instrument. All payment data is stored by
                      <bdt
                        className="forloop-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                      <span
                        style={{
                          fontSize: "15px",
                          background: "transparent !important",
                        }}
                      >
                        <span style={{ background: "transparent" }}>
                          <span
                            data-custom-className="body_text"
                            style={{
                              background: "transparent !important",
                              fontSize: "14px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "15px",
                                background: "transparent !important",
                              }}
                            >
                              <span
                                data-custom-className="body_text"
                                style={{
                                  background: "transparent !important",
                                  fontSize: "14px",
                                }}
                              >
                                <bdt
                                  className="block-component"
                                  style={{ background: "transparent" }}
                                ></bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                      &nbsp;
                      <bdt
                        className="question"
                        style={{ background: "transparent" }}
                      >
                        stripe
                      </bdt>
                      <span
                        style={{
                          fontSize: "15px",
                          background: "transparent !important",
                        }}
                      >
                        <span style={{ background: "transparent" }}>
                          <span
                            data-custom-className="body_text"
                            style={{
                              background: "transparent !important",
                              fontSize: "14px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "15px",
                                background: "transparent !important",
                              }}
                            >
                              <span style={{ background: "transparent" }}>
                                <span
                                  data-custom-className="body_text"
                                  style={{
                                    background: "transparent !important",
                                    fontSize: "14px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      background: "transparent !important",
                                    }}
                                  >
                                    <span
                                      data-custom-className="body_text"
                                      style={{
                                        background: "transparent !important",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <bdt
                                        className="block-component"
                                        style={{
                                          background: "transparent",
                                        }}
                                      ></bdt>
                                    </span>
                                  </span>
                                </span>
                                <span
                                  data-custom-className="body_text"
                                  style={{
                                    background: "transparent !important",
                                    fontSize: "14px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      background: "transparent !important",
                                    }}
                                  >
                                    <span
                                      style={{
                                        background: "transparent !important",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <span
                                        data-custom-className="body_text"
                                        style={{
                                          background: "transparent",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "15px",
                                            background:
                                              "transparent !important",
                                          }}
                                        >
                                          <span
                                            style={{
                                              background:
                                                "transparent !important",
                                              fontSize: "14px",
                                            }}
                                          >
                                            <span
                                              data-custom-className="body_text"
                                              style={{
                                                background: "transparent",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  background: "transparent",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                    background:
                                                      "transparent !important",
                                                  }}
                                                >
                                                  <span
                                                    data-custom-className="body_text"
                                                    style={{
                                                      background:
                                                        "transparent !important",
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    <bdt
                                                      className="forloop-component"
                                                      style={{
                                                        background:
                                                          "transparent",
                                                      }}
                                                    ></bdt>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            . You may find their privacy notice link(s) here:
                            <span
                              style={{
                                fontSize: "15px",
                                background: "transparent !important",
                              }}
                            >
                              <span style={{ background: "transparent" }}>
                                <span
                                  data-custom-className="body_text"
                                  style={{
                                    background: "transparent !important",
                                    fontSize: "14px",
                                  }}
                                >
                                  <bdt
                                    className="forloop-component"
                                    style={{
                                      background: "transparent",
                                    }}
                                  ></bdt>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      background: "transparent !important",
                                    }}
                                  >
                                    <span
                                      style={{
                                        background: "transparent",
                                      }}
                                    >
                                      <span
                                        data-custom-className="body_text"
                                        style={{
                                          background: "transparent !important",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "15px",
                                            background:
                                              "transparent !important",
                                          }}
                                        >
                                          <span
                                            data-custom-className="body_text"
                                            style={{
                                              background:
                                                "transparent !important",
                                              fontSize: "14px",
                                            }}
                                          >
                                            <bdt
                                              className="block-component"
                                              style={{
                                                background: "transparent",
                                              }}
                                            ></bdt>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            &nbsp;
                            <bdt
                              className="question"
                              style={{ background: "transparent" }}
                            >
                              <a
                                href="https://stripe.com/privacy"
                                target="_blank"
                                data-custom-className="link"
                                style={{
                                  backgroundImage: "initial !important",
                                  backgroundPosition: "initial !important",
                                  backgroundSize: "initial !important",
                                  backgroundRepeat: "initial !important",
                                  backgroundAttachment: "initial !important",
                                  backgroundOrigin: "initial !important",
                                  backgroundClip: "initial !important",
                                  color: "rgb(48, 48, 241) !important",
                                  wordBreak: "break-word !important",
                                }}
                              >
                                https://stripe.com/privacy
                              </a>
                            </bdt>
                            <span
                              style={{
                                fontSize: "15px",
                                background: "transparent !important",
                              }}
                            >
                              <span style={{ background: "transparent" }}>
                                <span
                                  data-custom-className="body_text"
                                  style={{
                                    background: "transparent !important",
                                    fontSize: "14px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      background: "transparent !important",
                                    }}
                                  >
                                    <span
                                      style={{
                                        background: "transparent",
                                      }}
                                    >
                                      <span
                                        data-custom-className="body_text"
                                        style={{
                                          background: "transparent !important",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "15px",
                                            background:
                                              "transparent !important",
                                          }}
                                        >
                                          <span
                                            style={{
                                              background: "transparent",
                                            }}
                                          >
                                            <span
                                              data-custom-className="body_text"
                                              style={{
                                                background:
                                                  "transparent !important",
                                                fontSize: "14px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "15px",
                                                  background:
                                                    "transparent !important",
                                                }}
                                              >
                                                <span
                                                  data-custom-className="body_text"
                                                  style={{
                                                    background:
                                                      "transparent !important",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  <bdt
                                                    className="block-component"
                                                    style={{
                                                      background: "transparent",
                                                    }}
                                                  ></bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  <bdt
                                    className="forloop-component"
                                    style={{
                                      background: "transparent",
                                    }}
                                  ></bdt>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      background: "transparent !important",
                                    }}
                                  >
                                    <span
                                      data-custom-className="body_text"
                                      style={{
                                        background: "transparent !important",
                                        fontSize: "14px",
                                      }}
                                    >
                                      .
                                      <bdt
                                        className="block-component"
                                        style={{
                                          background: "transparent",
                                        }}
                                      ></bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <bdt
                        className="block-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                    </span>
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <strong style={{ background: "transparent" }}>
                        Social Media Login Data.&nbsp;
                      </strong>
                      We may provide you with the option to register with us
                      using your existing social media account details, like
                      your Facebook, Twitter, or other social media account. If
                      you choose to register in this way, we will collect the
                      information described in the section called&nbsp;
                      <bdt
                        className="block-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                      "
                      <bdt
                        className="statement-end-if-in-editor"
                        style={{ background: "transparent" }}
                      ></bdt>
                      <span
                        style={{
                          fontSize: "15px",
                          background: "transparent !important",
                        }}
                      >
                        <span
                          data-custom-className="body_text"
                          style={{
                            background: "transparent !important",
                            fontSize: "14px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "15px",
                              background: "transparent !important",
                            }}
                          >
                            <span
                              style={{
                                background: "transparent !important",
                                fontSize: "14px",
                              }}
                            >
                              <a
                                data-custom-className="link"
                                href="https://app.termly.io/document/privacy-policy/d237bcd6-1d78-41b2-9869-2515b1b3953c#sociallogins"
                                style={{
                                  backgroundImage: "initial !important",
                                  backgroundPosition: "initial !important",
                                  backgroundSize: "initial !important",
                                  backgroundRepeat: "initial !important",
                                  backgroundAttachment: "initial !important",
                                  backgroundOrigin: "initial !important",
                                  backgroundClip: "initial !important",
                                  color: "rgb(48, 48, 241) !important",
                                  wordBreak: "break-word !important",
                                }}
                              >
                                HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                              </a>
                            </span>
                          </span>
                        </span>
                      </span>
                      <bdt
                        className="block-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                      "
                      <bdt
                        className="statement-end-if-in-editor"
                        style={{ background: "transparent" }}
                      ></bdt>
                      &nbsp;below.
                    </span>
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <bdt
                        className="statement-end-if-in-editor"
                        style={{ background: "transparent" }}
                      ></bdt>
                    </span>
                  </span>
                </span>
                <bdt
                  className="block-component"
                  style={{ background: "transparent" }}
                >
                  <bdt
                    className="block-component"
                    style={{ background: "transparent" }}
                  ></bdt>
                </bdt>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      All personal information that you provide to us must be
                      true, complete, and accurate, and you must notify us of
                      any changes to such personal information.
                    </span>
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <bdt
                        className="block-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                    </span>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                          background: "transparent !important",
                        }}
                      >
                        <span
                          data-custom-className="body_text"
                          style={{
                            background: "transparent !important",
                            fontSize: "14px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "15px",
                              background: "transparent !important",
                            }}
                          >
                            <span
                              data-custom-className="body_text"
                              style={{
                                background: "transparent !important",
                                fontSize: "14px",
                              }}
                            >
                              <bdt
                                className="statement-end-if-in-editor"
                                style={{ background: "transparent" }}
                              >
                                <bdt
                                  className="block-component"
                                  style={{ background: "transparent" }}
                                ></bdt>
                              </bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "15px",
                    background: "transparent",
                  }}
                >
                  <span
                    data-custom-className="body_text"
                    style={{
                      color: "rgb(89, 89, 89)",
                      fontSize: "14px",
                      fontFamily: "Arial",
                      background: "transparent",
                    }}
                  >
                    <bdt
                      className="block-component"
                      style={{ background: "transparent" }}
                    ></bdt>
                  </span>
                </span>
              </div>
              <div
                id="infouse"
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    color: "rgb(127, 127, 127)",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(89, 89, 89)",
                      fontSize: "15px",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span style={{ background: "transparent" }}>
                        <span
                          id="control"
                          style={{
                            background: "transparent !important",
                            color: "rgb(0, 0, 0)",
                          }}
                        >
                          <strong style={{ background: "transparent" }}>
                            <span
                              data-custom-className="heading_1"
                              style={{
                                fontSize: "19px",
                                fontFamily: "Arial",
                                background: "transparent",
                              }}
                            >
                              2. HOW DO WE PROCESS YOUR INFORMATION?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent !important",
                }}
              >
                <div
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <br style={{ background: "transparent" }} />
                </div>
                <div
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      color: "rgb(127, 127, 127)",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent !important",
                        color: "rgb(89, 89, 89)",
                        fontSize: "15px",
                      }}
                    >
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            background: "transparent !important",
                          }}
                        >
                          <span style={{ background: "transparent" }}>
                            <span
                              data-custom-className="body_text"
                              style={{
                                background: "transparent !important",
                                fontSize: "14px",
                              }}
                            >
                              <strong style={{ background: "transparent" }}>
                                <em style={{ background: "transparent" }}>
                                  In Short:&nbsp;
                                </em>
                              </strong>
                              <em style={{ background: "transparent" }}>
                                We process your information to provide, improve,
                                and administer our Services, communicate with
                                you, for security and fraud prevention, and to
                                comply with law. We may also process your
                                information for other purposes with your
                                consent.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <br style={{ background: "transparent" }} />
              </div>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <strong style={{ background: "transparent" }}>
                        We process your personal information for a variety of
                        reasons, depending on how you interact with our
                        Services, including:
                      </strong>
                      <bdt
                        className="block-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                    </span>
                  </span>
                </span>
              </div>
              <ul
                style={{
                  listStyleType: "square",
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  background: "transparent !important",
                }}
              >
                <li
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        <strong style={{ background: "transparent" }}>
                          To facilitate account creation and authentication and
                          otherwise manage user accounts.&nbsp;
                        </strong>
                        We may process your information so you can create and
                        log in to your account, as well as keep your account in
                        working order.
                        <span
                          style={{
                            fontSize: "15px",
                            background: "transparent !important",
                          }}
                        >
                          <span style={{ background: "transparent" }}>
                            <span
                              data-custom-className="body_text"
                              style={{
                                background: "transparent !important",
                                fontSize: "14px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "15px",
                                  background: "transparent !important",
                                }}
                              >
                                <span
                                  style={{
                                    background: "transparent !important",
                                    fontSize: "14px",
                                  }}
                                >
                                  <span
                                    data-custom-className="body_text"
                                    style={{
                                      background: "transparent",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        background: "transparent !important",
                                      }}
                                    >
                                      <span
                                        style={{
                                          background: "transparent !important",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <span
                                          data-custom-className="body_text"
                                          style={{
                                            background: "transparent",
                                          }}
                                        >
                                          <bdt
                                            className="statement-end-if-in-editor"
                                            style={{
                                              background: "transparent",
                                            }}
                                          ></bdt>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Roboto, Arial",
                  fontSize: "medium",
                  lineHeight: 1.5,
                  background: "transparent",
                }}
              >
                <span
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span style={{ background: "transparent" }}>
                    <span
                      data-custom-className="body_text"
                      style={{
                        background: "transparent !important",
                        fontSize: "14px !important",
                        fontFamily: "Arial !important",
                      }}
                    >
                      <bdt
                        className="block-component"
                        style={{ background: "transparent" }}
                      ></bdt>
                    </span>
                  </span>
                </span>
                <div
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        <bdt
                          className="block-component"
                          style={{ background: "transparent" }}
                        ></bdt>
                      </span>
                    </span>
                  </span>
                </div>
                <ul
                  style={{
                    listStyleType: "square",
                    background: "transparent !important",
                  }}
                >
                  <li
                    style={{
                      background: "transparent !important",
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent !important",
                        fontSize: "15px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span style={{ background: "transparent" }}>
                        <span
                          data-custom-className="body_text"
                          style={{
                            background: "transparent !important",
                            fontSize: "14px !important",
                            fontFamily: "Arial !important",
                          }}
                        >
                          <strong style={{ background: "transparent" }}>
                            To deliver and facilitate delivery of services to
                            the user.&nbsp;
                          </strong>
                          We may process your information to provide you with
                          the requested service.
                          <span
                            style={{
                              fontSize: "15px",
                              background: "transparent !important",
                            }}
                          >
                            <span
                              style={{
                                background: "transparent !important",
                                fontSize: "14px",
                              }}
                            >
                              <span
                                data-custom-className="body_text"
                                style={{ background: "transparent" }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    background: "transparent !important",
                                  }}
                                >
                                  <span
                                    style={{
                                      background: "transparent",
                                    }}
                                  >
                                    <span
                                      data-custom-className="body_text"
                                      style={{
                                        background: "transparent !important",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "15px",
                                          background: "transparent !important",
                                        }}
                                      >
                                        <span
                                          style={{
                                            background: "transparent",
                                          }}
                                        >
                                          <span
                                            data-custom-className="body_text"
                                            style={{
                                              background:
                                                "transparent !important",
                                              fontSize: "14px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                background:
                                                  "transparent !important",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  background: "transparent",
                                                }}
                                              >
                                                <span
                                                  data-custom-className="body_text"
                                                  style={{
                                                    background:
                                                      "transparent !important",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "15px",
                                                      background:
                                                        "transparent !important",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      <span
                                                        data-custom-className="body_text"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent !important",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            <span
                                                              data-custom-className="body_text"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              <bdt
                                                                className="statement-end-if-in-editor"
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              ></bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div
                  style={{
                    background: "transparent !important",
                    fontSize: "15px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <span
                    style={{
                      background: "transparent !important",
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span style={{ background: "transparent" }}>
                      <span
                        data-custom-className="body_text"
                        style={{
                          background: "transparent !important",
                          fontSize: "14px !important",
                          fontFamily: "Arial !important",
                        }}
                      >
                        <bdt
                          className="block-component"
                          style={{ background: "transparent" }}
                        ></bdt>
                      </span>
                    </span>
                  </span>
                  <div
                    style={{
                      background: "transparent !important",
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <span
                      style={{
                        background: "transparent !important",
                        fontSize: "15px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span style={{ background: "transparent" }}>
                        <span
                          data-custom-className="body_text"
                          style={{
                            background: "transparent !important",
                            fontSize: "14px !important",
                            fontFamily: "Arial !important",
                          }}
                        >
                          <bdt
                            className="block-component"
                            style={{ background: "transparent" }}
                          ></bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul
                    style={{
                      listStyleType: "square",
                      background: "transparent !important",
                    }}
                  >
                    <li
                      style={{
                        background: "transparent !important",
                        fontSize: "15px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          background: "transparent !important",
                          fontSize: "15px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span style={{ background: "transparent" }}>
                          <span
                            data-custom-className="body_text"
                            style={{
                              background: "transparent !important",
                              fontSize: "14px !important",
                              fontFamily: "Arial !important",
                            }}
                          >
                            <strong style={{ background: "transparent" }}>
                              To respond to user inquiries/offer support to
                              users.&nbsp;
                            </strong>
                            We may process your information to respond to your
                            inquiries and solve any potential issues you might
                            have with the requested service.
                            <bdt
                              className="statement-end-if-in-editor"
                              style={{ background: "transparent" }}
                            ></bdt>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div
                    style={{
                      background: "transparent !important",
                      fontSize: "15px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <bdt
                      className="block-component"
                      style={{ background: "transparent" }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                          background: "transparent",
                        }}
                      ></span>
                    </bdt>
                    <div
                      style={{
                        background: "transparent !important",
                        fontSize: "15px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <bdt
                        className="block-component"
                        style={{ background: "transparent" }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            background: "transparent",
                          }}
                        ></span>
                      </bdt>
                    </div>
                    <ul
                      style={{
                        listStyleType: "square",
                        background: "transparent !important",
                      }}
                    >
                      <li
                        style={{
                          background: "transparent !important",
                          fontSize: "15px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            background: "transparent !important",
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span style={{ background: "transparent" }}>
                            <span
                              data-custom-className="body_text"
                              style={{
                                background: "transparent !important",
                                fontSize: "14px !important",
                                fontFamily: "Arial !important",
                              }}
                            >
                              <strong style={{ background: "transparent" }}>
                                To send administrative information to you.&nbsp;
                              </strong>
                              We may process your information to send you
                              details about our products and services, changes
                              to our terms and policies, and other similar
                              information.
                              <span
                                style={{
                                  fontSize: "15px",
                                  background: "transparent !important",
                                }}
                              >
                                <span style={{ background: "transparent" }}>
                                  <span
                                    data-custom-className="body_text"
                                    style={{
                                      background: "transparent !important",
                                      fontSize: "14px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        background: "transparent !important",
                                      }}
                                    >
                                      <span
                                        style={{
                                          background: "transparent",
                                        }}
                                      >
                                        <span
                                          data-custom-className="body_text"
                                          style={{
                                            background:
                                              "transparent !important",
                                            fontSize: "14px",
                                          }}
                                        >
                                          <bdt
                                            className="statement-end-if-in-editor"
                                            style={{
                                              background: "transparent",
                                            }}
                                          ></bdt>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <div
                      style={{
                        background: "transparent !important",
                        fontSize: "15px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <bdt
                        className="block-component"
                        style={{ background: "transparent" }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            background: "transparent",
                          }}
                        ></span>
                      </bdt>
                      <div
                        style={{
                          background: "transparent !important",
                          fontSize: "15px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <bdt
                          className="block-component"
                          style={{ background: "transparent" }}
                        >
                          <span
                            style={{
                              fontSize: "15px",
                              background: "transparent",
                            }}
                          >
                            <span
                              data-custom-className="body_text"
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "14px",
                                fontFamily: "Arial",
                                background: "transparent",
                              }}
                            ></span>
                          </span>
                        </bdt>
                        <p
                          style={{
                            background: "transparent !important",
                            fontSize: "15px",
                            lineHeight: "1.5",
                          }}
                        >
                          <bdt
                            className="block-component"
                            style={{ background: "transparent" }}
                          >
                            <span style={{ background: "transparent" }}></span>
                          </bdt>
                        </p>
                        <p
                          style={{
                            background: "transparent !important",
                            fontSize: "15px",
                            lineHeight: "1.5",
                          }}
                        >
                          <bdt
                            className="block-component"
                            style={{ background: "transparent" }}
                          >
                            <span style={{ background: "transparent" }}></span>
                          </bdt>
                        </p>
                        <ul
                          style={{
                            listStyleType: "square",
                            background: "transparent !important",
                          }}
                        >
                          <li
                            style={{
                              background: "transparent !important",
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "15px",
                                background: "transparent",
                              }}
                            >
                              <span
                                style={{
                                  background: "transparent !important",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span style={{ background: "transparent" }}>
                                  <span
                                    data-custom-className="body_text"
                                    style={{
                                      background: "transparent !important",
                                      fontSize: "14px !important",
                                      fontFamily: "Arial !important",
                                    }}
                                  >
                                    <strong
                                      style={{
                                        background: "transparent",
                                      }}
                                    >
                                      To enable user-to-user
                                      communications.&nbsp;
                                    </strong>
                                    We may process your information if you
                                    choose to use any of our offerings that
                                    allow for communication with another user.
                                    <span
                                      style={{
                                        background: "transparent",
                                      }}
                                    >
                                      <span
                                        style={{
                                          background: "transparent",
                                        }}
                                      >
                                        <span
                                          data-custom-className="body_text"
                                          style={{
                                            background: "transparent",
                                          }}
                                        >
                                          <span
                                            style={{
                                              background: "transparent",
                                            }}
                                          >
                                            <span
                                              data-custom-className="body_text"
                                              style={{
                                                background: "transparent",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  background: "transparent",
                                                }}
                                              >
                                                <span
                                                  data-custom-className="body_text"
                                                  style={{
                                                    background: "transparent",
                                                  }}
                                                >
                                                  <bdt
                                                    className="statement-end-if-in-editor"
                                                    style={{
                                                      background: "transparent",
                                                    }}
                                                  ></bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </li>
                        </ul>
                        <p
                          style={{
                            background: "transparent !important",
                            fontSize: "15px",
                            lineHeight: "1.5",
                          }}
                        >
                          <bdt
                            className="block-component"
                            style={{ background: "transparent" }}
                          ></bdt>
                        </p>
                        <p
                          style={{
                            background: "transparent !important",
                            fontSize: "15px",
                            lineHeight: "1.5",
                          }}
                        >
                          <bdt
                            className="block-component"
                            style={{ background: "transparent" }}
                          ></bdt>
                        </p>
                        <div
                          style={{
                            background: "transparent !important",
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <bdt
                            className="block-component"
                            style={{ background: "transparent" }}
                          >
                            <span
                              style={{
                                fontSize: "15px",
                                background: "transparent",
                              }}
                            ></span>
                          </bdt>
                          <div
                            style={{
                              background: "transparent !important",
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <bdt
                              className="block-component"
                              style={{ background: "transparent" }}
                            >
                              <span
                                style={{
                                  fontSize: "15px",
                                  background: "transparent",
                                }}
                              ></span>
                            </bdt>
                            <div
                              style={{
                                background: "transparent !important",
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <bdt
                                className="block-component"
                                style={{ background: "transparent" }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    background: "transparent",
                                  }}
                                ></span>
                              </bdt>
                              <div
                                style={{
                                  background: "transparent !important",
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    background: "transparent",
                                  }}
                                >
                                  <span
                                    data-custom-className="body_text"
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "14px",
                                      fontFamily: "Arial",
                                      background: "transparent",
                                    }}
                                  ></span>
                                </span>
                                <div
                                  style={{
                                    background: "transparent !important",
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <bdt
                                    className="block-component"
                                    style={{
                                      background: "transparent",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        background: "transparent",
                                      }}
                                    >
                                      <span
                                        data-custom-className="body_text"
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: "14px",
                                          fontFamily: "Arial",
                                          background: "transparent",
                                        }}
                                      ></span>
                                    </span>
                                  </bdt>
                                  <div
                                    style={{
                                      background: "transparent !important",
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <bdt
                                      className="block-component"
                                      style={{
                                        background: "transparent",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "15px",
                                          background: "transparent",
                                        }}
                                      >
                                        <span
                                          data-custom-className="body_text"
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                            fontSize: "14px",
                                            fontFamily: "Arial",
                                            background: "transparent",
                                          }}
                                        ></span>
                                      </span>
                                    </bdt>
                                    <div
                                      style={{
                                        background: "transparent !important",
                                        fontSize: "15px",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt
                                        className="block-component"
                                        style={{
                                          background: "transparent",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "15px",
                                            background: "transparent",
                                          }}
                                        >
                                          <span
                                            data-custom-className="body_text"
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                              fontSize: "14px",
                                              fontFamily: "Arial",
                                              background: "transparent",
                                            }}
                                          ></span>
                                        </span>
                                      </bdt>
                                      <div
                                        style={{
                                          background: "transparent !important",
                                          fontSize: "15px",
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <bdt
                                          className="block-component"
                                          style={{
                                            background: "transparent",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "15px",
                                              background: "transparent",
                                            }}
                                          >
                                            <span
                                              data-custom-className="body_text"
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                                fontSize: "14px",
                                                fontFamily: "Arial",
                                                background: "transparent",
                                              }}
                                            ></span>
                                          </span>
                                        </bdt>
                                        <div
                                          style={{
                                            background:
                                              "transparent !important",
                                            fontSize: "15px",
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <bdt
                                            className="block-component"
                                            style={{
                                              background: "transparent",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                background: "transparent",
                                              }}
                                            >
                                              <span
                                                data-custom-className="body_text"
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                  fontSize: "14px",
                                                  fontFamily: "Arial",
                                                  background: "transparent",
                                                }}
                                              ></span>
                                            </span>
                                          </bdt>
                                          <div
                                            style={{
                                              background:
                                                "transparent !important",
                                              fontSize: "15px",
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <bdt
                                              className="block-component"
                                              style={{
                                                background: "transparent",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "15px",
                                                  background: "transparent",
                                                }}
                                              >
                                                <span
                                                  data-custom-className="body_text"
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                    fontSize: "14px",
                                                    fontFamily: "Arial",
                                                    background: "transparent",
                                                  }}
                                                ></span>
                                              </span>
                                            </bdt>
                                            <div
                                              style={{
                                                background:
                                                  "transparent !important",
                                                fontSize: "15px",
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <bdt
                                                className="block-component"
                                                style={{
                                                  background: "transparent",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                    background: "transparent",
                                                  }}
                                                >
                                                  <span
                                                    data-custom-className="body_text"
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                      fontSize: "14px",
                                                      fontFamily: "Arial",
                                                      background: "transparent",
                                                    }}
                                                  ></span>
                                                </span>
                                              </bdt>
                                              <div
                                                style={{
                                                  background:
                                                    "transparent !important",
                                                  fontSize: "15px",
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <bdt
                                                  className="block-component"
                                                  style={{
                                                    background: "transparent",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "15px",
                                                      background: "transparent",
                                                    }}
                                                  >
                                                    <span
                                                      data-custom-className="body_text"
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: "14px",
                                                        fontFamily: "Arial",
                                                        background:
                                                          "transparent",
                                                      }}
                                                    ></span>
                                                  </span>
                                                </bdt>
                                                <div
                                                  style={{
                                                    background:
                                                      "transparent !important",
                                                    fontSize: "15px",
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <bdt
                                                    className="block-component"
                                                    style={{
                                                      background: "transparent",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        background:
                                                          "transparent",
                                                      }}
                                                    >
                                                      <span
                                                        data-custom-className="body_text"
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "14px",
                                                          fontFamily: "Arial",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      ></span>
                                                    </span>
                                                  </bdt>
                                                  <div
                                                    style={{
                                                      background:
                                                        "transparent !important",
                                                      fontSize: "15px",
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <bdt
                                                      className="block-component"
                                                      style={{
                                                        background:
                                                          "transparent",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        ></span>
                                                      </span>
                                                    </bdt>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent !important",
                                                            }}
                                                          >
                                                            <strong
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              To save or protect
                                                              an individual's
                                                              vital interest.
                                                            </strong>
                                                            &nbsp;We may process
                                                            your information
                                                            when necessary to
                                                            save or protect an
                                                            individual’s vital
                                                            interest, such as to
                                                            prevent harm.
                                                          </span>
                                                        </span>
                                                        <bdt
                                                          className="statement-end-if-in-editor"
                                                          style={{
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <span
                                                              data-custom-className="body_text"
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "14px",
                                                                fontFamily:
                                                                  "Arial",
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></span>
                                                          </span>
                                                        </bdt>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <br
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      id="legalbases"
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <strong
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="heading_1"
                                                            style={{
                                                              fontSize: "19px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            3. WHAT LEGAL BASES
                                                            DO WE RELY ON TO
                                                            PROCESS YOUR
                                                            INFORMATION?
                                                          </span>
                                                        </span>
                                                      </strong>
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <br
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <em
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <strong
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              In Short:&nbsp;
                                                            </strong>
                                                            We only process your
                                                            personal information
                                                            when we believe it
                                                            is necessary and we
                                                            have a valid legal
                                                            reason (i.e.
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            ,
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            &nbsp;legal basis)
                                                            to do so under
                                                            applicable law, like
                                                            with your consent,
                                                            to comply with laws,
                                                            to provide you with
                                                            services to enter
                                                            into or&nbsp;
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            fulfill
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            &nbsp;our
                                                            contractual
                                                            obligations, to
                                                            protect your rights,
                                                            or to&nbsp;
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            fulfill
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            &nbsp;our legitimate
                                                            business interests.
                                                          </span>
                                                        </span>
                                                      </em>
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></bdt>
                                                        </span>
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></bdt>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <br
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <em
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <strong
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              <u
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                If you are
                                                                located in the
                                                                EU or UK, this
                                                                section applies
                                                                to you.
                                                              </u>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </em>
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <bdt
                                                            className="statement-end-if-in-editor"
                                                            style={{
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></bdt>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <br
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          The General Data
                                                          Protection Regulation
                                                          (GDPR) and UK GDPR
                                                          require us to explain
                                                          the valid legal bases
                                                          we rely on in order to
                                                          process your personal
                                                          information. As such,
                                                          we may rely on the
                                                          following legal bases
                                                          to process your
                                                          personal information:
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <strong
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              Consent.&nbsp;
                                                            </strong>
                                                            We may process your
                                                            information if you
                                                            have given us
                                                            permission (i.e.
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            ,
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            &nbsp;consent) to
                                                            use your personal
                                                            information for a
                                                            specific purpose.
                                                            You can withdraw
                                                            your consent at any
                                                            time.&nbsp;
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent !important",
                                                            }}
                                                          >
                                                            <strong
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              Performance of a
                                                              Contract.
                                                            </strong>
                                                            &nbsp;We may process
                                                            your personal
                                                            information when we
                                                            believe it is
                                                            necessary to&nbsp;
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            ></bdt>
                                                            fulfill
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            ></bdt>
                                                            &nbsp;our
                                                            contractual
                                                            obligations to you,
                                                            including providing
                                                            our Services or at
                                                            your request prior
                                                            to entering into a
                                                            contract with you.
                                                          </span>
                                                        </span>
                                                        <bdt
                                                          className="statement-end-if-in-editor"
                                                          style={{
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <span
                                                              data-custom-className="body_text"
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "14px",
                                                                fontFamily:
                                                                  "Arial",
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></span>
                                                          </span>
                                                        </bdt>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent !important",
                                                            }}
                                                          >
                                                            <strong
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              Legal Obligations.
                                                            </strong>
                                                            &nbsp;We may process
                                                            your information
                                                            where we believe it
                                                            is necessary for
                                                            compliance with our
                                                            legal obligations,
                                                            such as to cooperate
                                                            with a law
                                                            enforcement body or
                                                            regulatory agency,
                                                            exercise or defend
                                                            our legal rights, or
                                                            disclose your
                                                            information as
                                                            evidence in
                                                            litigation in which
                                                            we are involved.
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            ></bdt>
                                                            <br
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            />
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent !important",
                                                            }}
                                                          >
                                                            <strong
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              Vital Interests.
                                                            </strong>
                                                            &nbsp;We may process
                                                            your information
                                                            where we believe it
                                                            is necessary to
                                                            protect your vital
                                                            interests or the
                                                            vital interests of a
                                                            third party, such as
                                                            situations involving
                                                            potential threats to
                                                            the safety of any
                                                            person.
                                                          </span>
                                                        </span>
                                                        <bdt
                                                          className="statement-end-if-in-editor"
                                                          style={{
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <span
                                                              data-custom-className="body_text"
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "14px",
                                                                fontFamily:
                                                                  "Arial",
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></span>
                                                          </span>
                                                        </bdt>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                      <span
                                                        data-custom-className="body_text"
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "14px",
                                                          fontFamily: "Arial",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent !important",
                                                          }}
                                                        >
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize: "14px",
                                                            }}
                                                          ></bdt>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <br
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        data-custom-className="body_text"
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "14px",
                                                          fontFamily: "Arial",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent !important",
                                                          }}
                                                        >
                                                          <strong
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            <u
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              <em
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                If you are
                                                                located in
                                                                Canada, this
                                                                section applies
                                                                to you.
                                                              </em>
                                                            </u>
                                                          </strong>
                                                          <bdt
                                                            className="statement-end-if-in-editor"
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize: "14px",
                                                            }}
                                                          ></bdt>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <br
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        data-custom-className="body_text"
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "14px",
                                                          fontFamily: "Arial",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent !important",
                                                          }}
                                                        >
                                                          We may process your
                                                          information if you
                                                          have given us specific
                                                          permission (i.e.
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize: "14px",
                                                            }}
                                                          ></bdt>
                                                          ,
                                                          <bdt
                                                            className="statement-end-if-in-editor"
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize: "14px",
                                                            }}
                                                          ></bdt>
                                                          &nbsp;express consent)
                                                          to use your personal
                                                          information for a
                                                          specific purpose, or
                                                          in situations where
                                                          your permission can be
                                                          inferred (i.e.
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize: "14px",
                                                            }}
                                                          ></bdt>
                                                          ,
                                                          <bdt
                                                            className="statement-end-if-in-editor"
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize: "14px",
                                                            }}
                                                          ></bdt>
                                                          &nbsp;implied
                                                          consent). You can
                                                          withdraw your consent
                                                          at any time.&nbsp;
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <br
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        data-custom-className="body_text"
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "14px",
                                                          fontFamily: "Arial",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent !important",
                                                          }}
                                                        >
                                                          In some exceptional
                                                          cases, we may be
                                                          legally permitted
                                                          under applicable law
                                                          to process your
                                                          information without
                                                          your consent,
                                                          including, for
                                                          example:
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent !important",
                                                            }}
                                                          >
                                                            If collection is
                                                            clearly in the
                                                            interests of an
                                                            individual and
                                                            consent cannot be
                                                            obtained in a timely
                                                            way
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        data-custom-className="body_text"
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "14px",
                                                          fontFamily: "Arial",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent !important",
                                                          }}
                                                        >
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize: "14px",
                                                            }}
                                                          ></bdt>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent !important",
                                                            }}
                                                          >
                                                            For investigations
                                                            and fraud detection
                                                            and prevention
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            ></bdt>
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent !important",
                                                            }}
                                                          >
                                                            For business
                                                            transactions
                                                            provided certain
                                                            conditions are met
                                                          </span>
                                                        </span>
                                                        <bdt
                                                          className="statement-end-if-in-editor"
                                                          style={{
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <span
                                                              data-custom-className="body_text"
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "14px",
                                                                fontFamily:
                                                                  "Arial",
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></span>
                                                          </span>
                                                        </bdt>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent !important",
                                                            }}
                                                          >
                                                            If it is contained
                                                            in a witness
                                                            statement and the
                                                            collection is
                                                            necessary to assess,
                                                            process, or settle
                                                            an insurance claim
                                                          </span>
                                                        </span>
                                                        <bdt
                                                          className="statement-end-if-in-editor"
                                                          style={{
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <span
                                                              data-custom-className="body_text"
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "14px",
                                                                fontFamily:
                                                                  "Arial",
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></span>
                                                          </span>
                                                        </bdt>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent !important",
                                                            }}
                                                          >
                                                            For identifying
                                                            injured, ill, or
                                                            deceased persons and
                                                            communicating with
                                                            next of kin
                                                          </span>
                                                        </span>
                                                        <bdt
                                                          className="statement-end-if-in-editor"
                                                          style={{
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <span
                                                              data-custom-className="body_text"
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "14px",
                                                                fontFamily:
                                                                  "Arial",
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></span>
                                                          </span>
                                                        </bdt>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        data-custom-className="body_text"
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "14px",
                                                          fontFamily: "Arial",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent !important",
                                                          }}
                                                        >
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize: "14px",
                                                            }}
                                                          ></bdt>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent !important",
                                                            }}
                                                          >
                                                            If we have
                                                            reasonable grounds
                                                            to believe an
                                                            individual has been,
                                                            is, or may be victim
                                                            of financial abuse
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            ></bdt>
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        data-custom-className="body_text"
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "14px",
                                                          fontFamily: "Arial",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent !important",
                                                          }}
                                                        >
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize: "14px",
                                                            }}
                                                          ></bdt>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent !important",
                                                            }}
                                                          >
                                                            If it is reasonable
                                                            to expect collection
                                                            and use with consent
                                                            would compromise the
                                                            availability or the
                                                            accuracy of the
                                                            information and the
                                                            collection is
                                                            reasonable for
                                                            purposes related to
                                                            investigating a
                                                            breach of an
                                                            agreement or a
                                                            contravention of the
                                                            laws of Canada or a
                                                            province
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            ></bdt>
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        data-custom-className="body_text"
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "14px",
                                                          fontFamily: "Arial",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent !important",
                                                          }}
                                                        >
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize: "14px",
                                                            }}
                                                          ></bdt>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent !important",
                                                            }}
                                                          >
                                                            If disclosure is
                                                            required to comply
                                                            with a subpoena,
                                                            warrant, court
                                                            order, or rules of
                                                            the court relating
                                                            to the production of
                                                            records
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            ></bdt>
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <bdt
                                                        className="block-component"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            If it was produced
                                                            by an individual in
                                                            the course of their
                                                            employment,
                                                            business, or
                                                            profession and the
                                                            collection is
                                                            consistent with the
                                                            purposes for which
                                                            the information was
                                                            produced
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></bdt>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            If the collection is
                                                            solely for
                                                            journalistic,
                                                            artistic, or
                                                            literary purposes
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></bdt>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            If the information
                                                            is publicly
                                                            available and is
                                                            specified by the
                                                            regulations
                                                          </span>
                                                          <bdt
                                                            className="statement-end-if-in-editor"
                                                            style={{
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <span
                                                              data-custom-className="body_text"
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "14px",
                                                                fontFamily:
                                                                  "Arial",
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></span>
                                                          </bdt>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <bdt
                                                        className="statement-end-if-in-editor"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                      <bdt
                                                        className="statement-end-if-in-editor"
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></span>
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <br
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      id="whoshare"
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            background:
                                                              "transparent !important",
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              <span
                                                                id="control"
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  color:
                                                                    "rgb(0, 0, 0)",
                                                                }}
                                                              >
                                                                <strong
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="heading_1"
                                                                    style={{
                                                                      fontSize:
                                                                        "19px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    4. WHEN AND
                                                                    WITH WHOM DO
                                                                    WE SHARE
                                                                    YOUR
                                                                    PERSONAL
                                                                    INFORMATION?
                                                                  </span>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <br
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize:
                                                                "14px !important",
                                                              fontFamily:
                                                                "Arial !important",
                                                            }}
                                                          >
                                                            <strong
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              <em
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                In Short:
                                                              </em>
                                                            </strong>
                                                            <em
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              &nbsp;We may share
                                                              information in
                                                              specific
                                                              situations
                                                              described in this
                                                              section and/or
                                                              with the
                                                              following&nbsp;
                                                              <bdt
                                                                className="block-component"
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              ></bdt>
                                                              third parties.
                                                            </em>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize:
                                                                "14px !important",
                                                              fontFamily:
                                                                "Arial !important",
                                                            }}
                                                          >
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <br
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          We&nbsp;
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></bdt>
                                                          may need to share your
                                                          personal information
                                                          in the following
                                                          situations:
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <strong
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              Business
                                                              Transfers.
                                                            </strong>
                                                            &nbsp;We may share
                                                            or transfer your
                                                            information in
                                                            connection with, or
                                                            during negotiations
                                                            of, any merger, sale
                                                            of company assets,
                                                            financing, or
                                                            acquisition of all
                                                            or a portion of our
                                                            business to another
                                                            company.
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent",
                                                            }}
                                                          ></bdt>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul
                                                      style={{
                                                        listStyleType: "square",
                                                        background:
                                                          "transparent !important",
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <strong
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              When we use Google
                                                              Maps Platform
                                                              APIs.
                                                            </strong>
                                                            &nbsp;We may share
                                                            your information
                                                            with certain Google
                                                            Maps Platform APIs
                                                            (e.g.
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            ,
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            &nbsp;Google Maps
                                                            API, Places API). To
                                                            find out more about
                                                            Google’s Privacy
                                                            Policy, please refer
                                                            to this&nbsp;
                                                          </span>
                                                        </span>
                                                        <a
                                                          data-custom-className="link"
                                                          href="https://policies.google.com/privacy"
                                                          rel="noopener noreferrer"
                                                          target="_blank"
                                                          style={{
                                                            backgroundImage:
                                                              "initial !important",
                                                            backgroundPosition:
                                                              "initial !important",
                                                            backgroundSize:
                                                              "initial !important",
                                                            backgroundRepeat:
                                                              "initial !important",
                                                            backgroundAttachment:
                                                              "initial !important",
                                                            backgroundOrigin:
                                                              "initial !important",
                                                            backgroundClip:
                                                              "initial !important",
                                                            color:
                                                              "rgb(48, 48, 241) !important",
                                                            fontSize:
                                                              "14px !important",
                                                            fontFamily:
                                                              "Arial !important",
                                                            wordBreak:
                                                              "break-word !important",
                                                          }}
                                                        >
                                                          link
                                                        </a>
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            .
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            &nbsp;We use certain
                                                            Google Maps Platform
                                                            APIs to retrieve
                                                            certain information
                                                            when you make
                                                            location-specific
                                                            requests. This
                                                            includes:
                                                            <bdt
                                                              className="forloop-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            &nbsp;
                                                            <bdt
                                                              className="question"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              Venue Information
                                                            </bdt>
                                                            ;
                                                            <bdt
                                                              className="forloop-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            &nbsp;and other
                                                            similar information.
                                                            A full list of what
                                                            we use information
                                                            for can be found in
                                                            this section and in
                                                            the previous section
                                                            titled&nbsp;
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            "
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                          </span>
                                                        </span>
                                                        <a
                                                          data-custom-className="link"
                                                          href="https://app.termly.io/document/privacy-policy/d237bcd6-1d78-41b2-9869-2515b1b3953c#infouse"
                                                          style={{
                                                            backgroundImage:
                                                              "initial !important",
                                                            backgroundPosition:
                                                              "initial !important",
                                                            backgroundSize:
                                                              "initial !important",
                                                            backgroundRepeat:
                                                              "initial !important",
                                                            backgroundAttachment:
                                                              "initial !important",
                                                            backgroundOrigin:
                                                              "initial !important",
                                                            backgroundClip:
                                                              "initial !important",
                                                            color:
                                                              "rgb(48, 48, 241) !important",
                                                            fontSize:
                                                              "14px !important",
                                                            fontFamily:
                                                              "Arial !important",
                                                            wordBreak:
                                                              "break-word !important",
                                                          }}
                                                        >
                                                          HOW DO WE PROCESS YOUR
                                                          INFORMATION?
                                                        </a>
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            data-custom-className="body_text"
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            "
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              .
                                                            </bdt>
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            &nbsp;The Google
                                                            Maps Platform APIs
                                                            that we use store
                                                            and access cookies
                                                            and other
                                                            information on your
                                                            devices. If you are
                                                            a user currently in
                                                            the European
                                                            Economic Area (EU
                                                            countries, Iceland,
                                                            Liechtenstein, and
                                                            Norway) or the
                                                            United Kingdom,
                                                            please take a look
                                                            at our Cookie Notice
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                            .
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></bdt>
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        background:
                                                          "transparent !important",
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        <span
                                                          data-custom-className="body_text"
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "14px",
                                                            fontFamily: "Arial",
                                                            background:
                                                              "transparent",
                                                          }}
                                                        ></span>
                                                      </span>
                                                      <div
                                                        style={{
                                                          background:
                                                            "transparent !important",
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <bdt
                                                          className="block-component"
                                                          style={{
                                                            background:
                                                              "transparent",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <span
                                                              data-custom-className="body_text"
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "14px",
                                                                fontFamily:
                                                                  "Arial",
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            ></span>
                                                          </span>
                                                        </bdt>
                                                        <div
                                                          style={{
                                                            background:
                                                              "transparent !important",
                                                            fontSize: "15px",
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <bdt
                                                            className="block-component"
                                                            style={{
                                                              background:
                                                                "transparent",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              <span
                                                                data-custom-className="body_text"
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize:
                                                                    "14px",
                                                                  fontFamily:
                                                                    "Arial",
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              ></span>
                                                            </span>
                                                          </bdt>
                                                          <div
                                                            style={{
                                                              background:
                                                                "transparent !important",
                                                              fontSize: "15px",
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <bdt
                                                              className="block-component"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                ></span>
                                                              </span>
                                                            </bdt>
                                                            <span
                                                              data-custom-className="body_text"
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "14px",
                                                                fontFamily:
                                                                  "Arial",
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <bdt
                                                                  className="block-component"
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "14px",
                                                                  }}
                                                                ></bdt>
                                                              </span>
                                                            </span>
                                                            <bdt
                                                              className="statement-end-if-in-editor"
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                ></span>
                                                              </span>
                                                            </bdt>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <bdt
                                                                className="block-component"
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></span>
                                                                </span>
                                                              </bdt>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <strong
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  id="3pwebsites"
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="heading_1"
                                                                    style={{
                                                                      fontSize:
                                                                        "19px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    5. WHAT IS
                                                                    OUR STANCE
                                                                    ON
                                                                    THIRD-PARTY
                                                                    WEBSITES?
                                                                  </span>
                                                                </span>
                                                              </strong>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <strong
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <em
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      In Short:
                                                                    </em>
                                                                  </strong>
                                                                  <em
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    &nbsp;We are
                                                                    not
                                                                    responsible
                                                                    for the
                                                                    safety of
                                                                    any
                                                                    information
                                                                    that you
                                                                    share with
                                                                    third
                                                                    parties that
                                                                    we may link
                                                                    to or who
                                                                    advertise on
                                                                    our
                                                                    Services,
                                                                    but are not
                                                                    affiliated
                                                                    with, our
                                                                    Services.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  The Services
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  <bdt
                                                                    className="statement-end-if-in-editor"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  &nbsp;may link
                                                                  to third-party
                                                                  websites,
                                                                  online
                                                                  services, or
                                                                  mobile
                                                                  applications
                                                                  and/or contain
                                                                  advertisements
                                                                  from third
                                                                  parties that
                                                                  are not
                                                                  affiliated
                                                                  with us and
                                                                  which may link
                                                                  to other
                                                                  websites,
                                                                  services, or
                                                                  applications.
                                                                  Accordingly,
                                                                  we do not make
                                                                  any guarantee
                                                                  regarding any
                                                                  such third
                                                                  parties, and
                                                                  we will not be
                                                                  liable for any
                                                                  loss or damage
                                                                  caused by the
                                                                  use of such
                                                                  third-party
                                                                  websites,
                                                                  services, or
                                                                  applications.
                                                                  The inclusion
                                                                  of a link
                                                                  towards a
                                                                  third-party
                                                                  website,
                                                                  service, or
                                                                  application
                                                                  does not imply
                                                                  an endorsement
                                                                  by us. We
                                                                  cannot
                                                                  guarantee the
                                                                  safety and
                                                                  privacy of
                                                                  data you
                                                                  provide to any
                                                                  third parties.
                                                                  Any data
                                                                  collected by
                                                                  third parties
                                                                  is not covered
                                                                  by this
                                                                  privacy
                                                                  notice. We are
                                                                  not
                                                                  responsible
                                                                  for the
                                                                  content or
                                                                  privacy and
                                                                  security
                                                                  practices and
                                                                  policies of
                                                                  any third
                                                                  parties,
                                                                  including
                                                                  other
                                                                  websites,
                                                                  services, or
                                                                  applications
                                                                  that may be
                                                                  linked to or
                                                                  from the
                                                                  Services. You
                                                                  should review
                                                                  the policies
                                                                  of such third
                                                                  parties and
                                                                  contact them
                                                                  directly to
                                                                  respond to
                                                                  your
                                                                  questions.
                                                                </span>
                                                              </span>
                                                              <bdt
                                                                className="statement-end-if-in-editor"
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></span>
                                                                </span>
                                                              </bdt>
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="heading_1"
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontFamily:
                                                                      "Arial !important",
                                                                    fontSize:
                                                                      "19px !important",
                                                                    color:
                                                                      "rgb(0, 0, 0) !important",
                                                                  }}
                                                                ></span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              id="cookies"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  color:
                                                                    "rgb(127, 127, 127)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        id="control"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          color:
                                                                            "rgb(0, 0, 0)",
                                                                        }}
                                                                      >
                                                                        <strong
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="heading_1"
                                                                            style={{
                                                                              fontSize:
                                                                                "19px",
                                                                              fontFamily:
                                                                                "Arial",
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            6.
                                                                            DO
                                                                            WE
                                                                            USE
                                                                            COOKIES
                                                                            AND
                                                                            OTHER
                                                                            TRACKING
                                                                            TECHNOLOGIES?
                                                                          </span>
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <strong
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <em
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        In
                                                                        Short:
                                                                      </em>
                                                                    </strong>
                                                                    <em
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      &nbsp;We
                                                                      may use
                                                                      cookies
                                                                      and other
                                                                      tracking
                                                                      technologies
                                                                      to collect
                                                                      and store
                                                                      your
                                                                      information.
                                                                    </em>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    We may use
                                                                    cookies and
                                                                    similar
                                                                    tracking
                                                                    technologies
                                                                    (like web
                                                                    beacons and
                                                                    pixels) to
                                                                    access or
                                                                    store
                                                                    information.
                                                                    Specific
                                                                    information
                                                                    about how we
                                                                    use such
                                                                    technologies
                                                                    and how you
                                                                    can refuse
                                                                    certain
                                                                    cookies is
                                                                    set out in
                                                                    our Cookie
                                                                    Notice
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                      >
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        ></bdt>
                                                                        .
                                                                      </span>
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                                background:
                                                                                  "transparent !important",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  background:
                                                                                    "transparent !important",
                                                                                  fontSize:
                                                                                    "14px",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  data-custom-className="body_text"
                                                                                  style={{
                                                                                    background:
                                                                                      "transparent",
                                                                                  }}
                                                                                >
                                                                                  <bdt
                                                                                    className="statement-end-if-in-editor"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent",
                                                                                    }}
                                                                                  ></bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize:
                                                                                    "15px",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    data-custom-className="body_text"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent",
                                                                                    }}
                                                                                  >
                                                                                    <bdt
                                                                                      className="block-component"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent",
                                                                                      }}
                                                                                    ></bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              id="sociallogins"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  color:
                                                                    "rgb(127, 127, 127)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        id="control"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          color:
                                                                            "rgb(0, 0, 0)",
                                                                        }}
                                                                      >
                                                                        <strong
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="heading_1"
                                                                            style={{
                                                                              fontSize:
                                                                                "19px",
                                                                              fontFamily:
                                                                                "Arial",
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            7.
                                                                            HOW
                                                                            DO
                                                                            WE
                                                                            HANDLE
                                                                            YOUR
                                                                            SOCIAL
                                                                            LOGINS?
                                                                          </span>
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <strong
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <em
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        In
                                                                        Short:&nbsp;
                                                                      </em>
                                                                    </strong>
                                                                    <em
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      If you
                                                                      choose to
                                                                      register
                                                                      or log in
                                                                      to our
                                                                      Services
                                                                      using a
                                                                      social
                                                                      media
                                                                      account,
                                                                      we may
                                                                      have
                                                                      access to
                                                                      certain
                                                                      information
                                                                      about you.
                                                                    </em>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    Our Services
                                                                    offer you
                                                                    the ability
                                                                    to register
                                                                    and log in
                                                                    using your
                                                                    third-party
                                                                    social media
                                                                    account
                                                                    details
                                                                    (like your
                                                                    Facebook or
                                                                    Twitter
                                                                    logins).
                                                                    Where you
                                                                    choose to do
                                                                    this, we
                                                                    will receive
                                                                    certain
                                                                    profile
                                                                    information
                                                                    about you
                                                                    from your
                                                                    social media
                                                                    provider.
                                                                    The profile
                                                                    information
                                                                    we receive
                                                                    may vary
                                                                    depending on
                                                                    the social
                                                                    media
                                                                    provider
                                                                    concerned,
                                                                    but will
                                                                    often
                                                                    include your
                                                                    name, email
                                                                    address,
                                                                    friends
                                                                    list, and
                                                                    profile
                                                                    picture, as
                                                                    well as
                                                                    other
                                                                    information
                                                                    you choose
                                                                    to make
                                                                    public on
                                                                    such a
                                                                    social media
                                                                    platform.
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <bdt
                                                                            className="block-component"
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          ></bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    We will use
                                                                    the
                                                                    information
                                                                    we receive
                                                                    only for the
                                                                    purposes
                                                                    that are
                                                                    described in
                                                                    this privacy
                                                                    notice or
                                                                    that are
                                                                    otherwise
                                                                    made clear
                                                                    to you on
                                                                    the relevant
                                                                    Services.
                                                                    Please note
                                                                    that we do
                                                                    not control,
                                                                    and are not
                                                                    responsible
                                                                    for, other
                                                                    uses of your
                                                                    personal
                                                                    information
                                                                    by your
                                                                    third-party
                                                                    social media
                                                                    provider. We
                                                                    recommend
                                                                    that you
                                                                    review their
                                                                    privacy
                                                                    notice to
                                                                    understand
                                                                    how they
                                                                    collect,
                                                                    use, and
                                                                    share your
                                                                    personal
                                                                    information,
                                                                    and how you
                                                                    can set your
                                                                    privacy
                                                                    preferences
                                                                    on their
                                                                    sites and
                                                                    apps.
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  background:
                                                                                    "transparent !important",
                                                                                  fontSize:
                                                                                    "14px",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                    background:
                                                                                      "transparent !important",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent !important",
                                                                                      fontSize:
                                                                                        "14px",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      data-custom-className="body_text"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent",
                                                                                      }}
                                                                                    >
                                                                                      <bdt
                                                                                        className="statement-end-if-in-editor"
                                                                                        style={{
                                                                                          background:
                                                                                            "transparent",
                                                                                        }}
                                                                                      ></bdt>
                                                                                    </span>
                                                                                    <bdt
                                                                                      className="block-component"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        data-custom-className="body_text"
                                                                                        style={{
                                                                                          background:
                                                                                            "transparent",
                                                                                        }}
                                                                                      >
                                                                                        <bdt
                                                                                          className="block-component"
                                                                                          style={{
                                                                                            background:
                                                                                              "transparent",
                                                                                          }}
                                                                                        ></bdt>
                                                                                      </span>
                                                                                    </bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              id="inforetain"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  color:
                                                                    "rgb(127, 127, 127)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        id="control"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          color:
                                                                            "rgb(0, 0, 0)",
                                                                        }}
                                                                      >
                                                                        <strong
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="heading_1"
                                                                            style={{
                                                                              fontSize:
                                                                                "19px",
                                                                              fontFamily:
                                                                                "Arial",
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            8.
                                                                            HOW
                                                                            LONG
                                                                            DO
                                                                            WE
                                                                            KEEP
                                                                            YOUR
                                                                            INFORMATION?
                                                                          </span>
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <strong
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <em
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        In
                                                                        Short:&nbsp;
                                                                      </em>
                                                                    </strong>
                                                                    <em
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      We keep
                                                                      your
                                                                      information
                                                                      for as
                                                                      long as
                                                                      necessary
                                                                      to&nbsp;
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      fulfill
                                                                      <bdt
                                                                        className="statement-end-if-in-editor"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      &nbsp;the
                                                                      purposes
                                                                      outlined
                                                                      in this
                                                                      privacy
                                                                      notice
                                                                      unless
                                                                      otherwise
                                                                      required
                                                                      by law.
                                                                    </em>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    We will only
                                                                    keep your
                                                                    personal
                                                                    information
                                                                    for as long
                                                                    as it is
                                                                    necessary
                                                                    for the
                                                                    purposes set
                                                                    out in this
                                                                    privacy
                                                                    notice,
                                                                    unless a
                                                                    longer
                                                                    retention
                                                                    period is
                                                                    required or
                                                                    permitted by
                                                                    law (such as
                                                                    tax,
                                                                    accounting,
                                                                    or other
                                                                    legal
                                                                    requirements).
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;No
                                                                    purpose in
                                                                    this notice
                                                                    will require
                                                                    us keeping
                                                                    your
                                                                    personal
                                                                    information
                                                                    for longer
                                                                    than&nbsp;
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <bdt
                                                                            className="block-component"
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          ></bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    the period
                                                                    of time in
                                                                    which users
                                                                    have an
                                                                    account with
                                                                    us
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <bdt
                                                                            className="else-block"
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          ></bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    .
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    When we have
                                                                    no ongoing
                                                                    legitimate
                                                                    business
                                                                    need to
                                                                    process your
                                                                    personal
                                                                    information,
                                                                    we will
                                                                    either
                                                                    delete
                                                                    or&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    anonymize
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;such
                                                                    information,
                                                                    or, if this
                                                                    is not
                                                                    possible
                                                                    (for
                                                                    example,
                                                                    because your
                                                                    personal
                                                                    information
                                                                    has been
                                                                    stored in
                                                                    backup
                                                                    archives),
                                                                    then we will
                                                                    securely
                                                                    store your
                                                                    personal
                                                                    information
                                                                    and isolate
                                                                    it from any
                                                                    further
                                                                    processing
                                                                    until
                                                                    deletion is
                                                                    possible.
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              id="infosafe"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  color:
                                                                    "rgb(127, 127, 127)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        id="control"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          color:
                                                                            "rgb(0, 0, 0)",
                                                                        }}
                                                                      >
                                                                        <strong
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="heading_1"
                                                                            style={{
                                                                              fontSize:
                                                                                "19px",
                                                                              fontFamily:
                                                                                "Arial",
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            9.
                                                                            HOW
                                                                            DO
                                                                            WE
                                                                            KEEP
                                                                            YOUR
                                                                            INFORMATION
                                                                            SAFE?
                                                                          </span>
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <strong
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <em
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        In
                                                                        Short:&nbsp;
                                                                      </em>
                                                                    </strong>
                                                                    <em
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      We aim to
                                                                      protect
                                                                      your
                                                                      personal
                                                                      information
                                                                      through a
                                                                      system
                                                                      of&nbsp;
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      organizational
                                                                      <bdt
                                                                        className="statement-end-if-in-editor"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      &nbsp;and
                                                                      technical
                                                                      security
                                                                      measures.
                                                                    </em>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    We have
                                                                    implemented
                                                                    appropriate
                                                                    and
                                                                    reasonable
                                                                    technical
                                                                    and&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    organizational
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;security
                                                                    measures
                                                                    designed to
                                                                    protect the
                                                                    security of
                                                                    any personal
                                                                    information
                                                                    we process.
                                                                    However,
                                                                    despite our
                                                                    safeguards
                                                                    and efforts
                                                                    to secure
                                                                    your
                                                                    information,
                                                                    no
                                                                    electronic
                                                                    transmission
                                                                    over the
                                                                    Internet or
                                                                    information
                                                                    storage
                                                                    technology
                                                                    can be
                                                                    guaranteed
                                                                    to be 100%
                                                                    secure, so
                                                                    we cannot
                                                                    promise or
                                                                    guarantee
                                                                    that
                                                                    hackers,
                                                                    cybercriminals,
                                                                    or
                                                                    other&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    unauthorized
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;third
                                                                    parties will
                                                                    not be able
                                                                    to defeat
                                                                    our security
                                                                    and
                                                                    improperly
                                                                    collect,
                                                                    access,
                                                                    steal, or
                                                                    modify your
                                                                    information.
                                                                    Although we
                                                                    will do our
                                                                    best to
                                                                    protect your
                                                                    personal
                                                                    information,
                                                                    transmission
                                                                    of personal
                                                                    information
                                                                    to and from
                                                                    our Services
                                                                    is at your
                                                                    own risk.
                                                                    You should
                                                                    only access
                                                                    the Services
                                                                    within a
                                                                    secure
                                                                    environment.
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="statement-end-if-in-editor"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                      >
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        ></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              id="privacyrights"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  color:
                                                                    "rgb(127, 127, 127)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        id="control"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          color:
                                                                            "rgb(0, 0, 0)",
                                                                        }}
                                                                      >
                                                                        <strong
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="heading_1"
                                                                            style={{
                                                                              fontSize:
                                                                                "19px",
                                                                              fontFamily:
                                                                                "Arial",
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            10.
                                                                            WHAT
                                                                            ARE
                                                                            YOUR
                                                                            PRIVACY
                                                                            RIGHTS?
                                                                          </span>
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <strong
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <em
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        In
                                                                        Short:
                                                                      </em>
                                                                    </strong>
                                                                    <em
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      &nbsp;
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px",
                                                                            }}
                                                                          >
                                                                            <em
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              <bdt
                                                                                className="block-component"
                                                                                style={{
                                                                                  background:
                                                                                    "transparent",
                                                                                }}
                                                                              ></bdt>
                                                                            </em>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      In some
                                                                      regions,
                                                                      such
                                                                      as&nbsp;
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      the
                                                                      European
                                                                      Economic
                                                                      Area
                                                                      (EEA),
                                                                      United
                                                                      Kingdom
                                                                      (UK), and
                                                                      Canada
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      , you have
                                                                      rights
                                                                      that allow
                                                                      you
                                                                      greater
                                                                      access to
                                                                      and
                                                                      control
                                                                      over your
                                                                      personal
                                                                      information.
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px",
                                                                            }}
                                                                          >
                                                                            <em
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              <bdt
                                                                                className="statement-end-if-in-editor"
                                                                                style={{
                                                                                  background:
                                                                                    "transparent",
                                                                                }}
                                                                              ></bdt>
                                                                            </em>
                                                                          </span>
                                                                        </span>
                                                                        &nbsp;
                                                                      </span>
                                                                      You may
                                                                      review,
                                                                      change, or
                                                                      terminate
                                                                      your
                                                                      account at
                                                                      any time.
                                                                    </em>
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                          background:
                                                                            "transparent !important",
                                                                        }}
                                                                      >
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        ></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    In some
                                                                    regions
                                                                    (like&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    the EEA, UK,
                                                                    and Canada
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    ), you have
                                                                    certain
                                                                    rights under
                                                                    applicable
                                                                    data
                                                                    protection
                                                                    laws. These
                                                                    may include
                                                                    the right
                                                                    (i) to
                                                                    request
                                                                    access and
                                                                    obtain a
                                                                    copy of your
                                                                    personal
                                                                    information,
                                                                    (ii) to
                                                                    request
                                                                    rectification
                                                                    or erasure;
                                                                    (iii) to
                                                                    restrict the
                                                                    processing
                                                                    of your
                                                                    personal
                                                                    information;
                                                                    and (iv) if
                                                                    applicable,
                                                                    to data
                                                                    portability.
                                                                    In certain
                                                                    circumstances,
                                                                    you may also
                                                                    have the
                                                                    right to
                                                                    object to
                                                                    the
                                                                    processing
                                                                    of your
                                                                    personal
                                                                    information.
                                                                    You can make
                                                                    such a
                                                                    request by
                                                                    contacting
                                                                    us by using
                                                                    the contact
                                                                    details
                                                                    provided in
                                                                    the
                                                                    section&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    "
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <a
                                                                data-custom-className="link"
                                                                href="https://app.termly.io/document/privacy-policy/d237bcd6-1d78-41b2-9869-2515b1b3953c#contact"
                                                                style={{
                                                                  backgroundImage:
                                                                    "initial !important",
                                                                  backgroundPosition:
                                                                    "initial !important",
                                                                  backgroundSize:
                                                                    "initial !important",
                                                                  backgroundRepeat:
                                                                    "initial !important",
                                                                  backgroundAttachment:
                                                                    "initial !important",
                                                                  backgroundOrigin:
                                                                    "initial !important",
                                                                  backgroundClip:
                                                                    "initial !important",
                                                                  color:
                                                                    "rgb(48, 48, 241) !important",
                                                                  fontSize:
                                                                    "14px !important",
                                                                  fontFamily:
                                                                    "Arial !important",
                                                                  wordBreak:
                                                                    "break-word !important",
                                                                }}
                                                              >
                                                                HOW CAN YOU
                                                                CONTACT US ABOUT
                                                                THIS NOTICE?
                                                              </a>
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    "
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;below.
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    We will
                                                                    consider and
                                                                    act upon any
                                                                    request in
                                                                    accordance
                                                                    with
                                                                    applicable
                                                                    data
                                                                    protection
                                                                    laws.
                                                                  </span>
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                                background:
                                                                                  "transparent !important",
                                                                              }}
                                                                            >
                                                                              <bdt
                                                                                className="statement-end-if-in-editor"
                                                                                style={{
                                                                                  background:
                                                                                    "transparent !important",
                                                                                  fontSize:
                                                                                    "14px",
                                                                                }}
                                                                              ></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                &nbsp;
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    If you are
                                                                    located in
                                                                    the EEA or
                                                                    UK and you
                                                                    believe we
                                                                    are
                                                                    unlawfully
                                                                    processing
                                                                    your
                                                                    personal
                                                                    information,
                                                                    you also
                                                                    have the
                                                                    right to
                                                                    complain to
                                                                    your local
                                                                    data
                                                                    protection
                                                                    supervisory
                                                                    authority.
                                                                    You can find
                                                                    their
                                                                    contact
                                                                    details
                                                                    here:&nbsp;
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(48, 48, 241)",
                                                                              background:
                                                                                "transparent !important",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                color:
                                                                                  "rgb(89, 89, 89) !important",
                                                                              }}
                                                                            >
                                                                              <a
                                                                                data-custom-className="link"
                                                                                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                                style={{
                                                                                  backgroundImage:
                                                                                    "initial !important",
                                                                                  backgroundPosition:
                                                                                    "initial !important",
                                                                                  backgroundSize:
                                                                                    "initial !important",
                                                                                  backgroundRepeat:
                                                                                    "initial !important",
                                                                                  backgroundAttachment:
                                                                                    "initial !important",
                                                                                  backgroundOrigin:
                                                                                    "initial !important",
                                                                                  backgroundClip:
                                                                                    "initial !important",
                                                                                  color:
                                                                                    "rgb(48, 48, 241) !important",
                                                                                  wordBreak:
                                                                                    "break-word !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    wordBreak:
                                                                                      "break-word !important",
                                                                                  }}
                                                                                >
                                                                                  https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                                                </span>
                                                                              </a>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    .
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    If you are
                                                                    located in
                                                                    Switzerland,
                                                                    the contact
                                                                    details for
                                                                    the data
                                                                    protection
                                                                    authorities
                                                                    are
                                                                    available
                                                                    here:&nbsp;
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(48, 48, 241)",
                                                                              background:
                                                                                "transparent !important",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                color:
                                                                                  "rgb(89, 89, 89) !important",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize:
                                                                                    "15px",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <a
                                                                                  data-custom-className="link"
                                                                                  href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                  rel="noopener noreferrer"
                                                                                  target="_blank"
                                                                                  style={{
                                                                                    backgroundImage:
                                                                                      "initial !important",
                                                                                    backgroundPosition:
                                                                                      "initial !important",
                                                                                    backgroundSize:
                                                                                      "initial !important",
                                                                                    backgroundRepeat:
                                                                                      "initial !important",
                                                                                    backgroundAttachment:
                                                                                      "initial !important",
                                                                                    backgroundOrigin:
                                                                                      "initial !important",
                                                                                    backgroundClip:
                                                                                      "initial !important",
                                                                                    color:
                                                                                      "rgb(48, 48, 241) !important",
                                                                                    fontSize:
                                                                                      "14px !important",
                                                                                    wordBreak:
                                                                                      "break-word !important",
                                                                                  }}
                                                                                >
                                                                                  https://www.edoeb.admin.ch/edoeb/en/home.html
                                                                                </a>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    .
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              id="withdrawconsent"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <strong
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <u
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        Withdrawing
                                                                        your
                                                                        consent:
                                                                      </u>
                                                                    </strong>
                                                                    &nbsp;If we
                                                                    are relying
                                                                    on your
                                                                    consent to
                                                                    process your
                                                                    personal
                                                                    information,
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;which
                                                                    may be
                                                                    express
                                                                    and/or
                                                                    implied
                                                                    consent
                                                                    depending on
                                                                    the
                                                                    applicable
                                                                    law,
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;you
                                                                    have the
                                                                    right to
                                                                    withdraw
                                                                    your consent
                                                                    at any time.
                                                                    You can
                                                                    withdraw
                                                                    your consent
                                                                    at any time
                                                                    by
                                                                    contacting
                                                                    us by using
                                                                    the contact
                                                                    details
                                                                    provided in
                                                                    the
                                                                    section&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    "
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <a
                                                                data-custom-className="link"
                                                                href="https://app.termly.io/document/privacy-policy/d237bcd6-1d78-41b2-9869-2515b1b3953c#contact"
                                                                style={{
                                                                  backgroundImage:
                                                                    "initial !important",
                                                                  backgroundPosition:
                                                                    "initial !important",
                                                                  backgroundSize:
                                                                    "initial !important",
                                                                  backgroundRepeat:
                                                                    "initial !important",
                                                                  backgroundAttachment:
                                                                    "initial !important",
                                                                  backgroundOrigin:
                                                                    "initial !important",
                                                                  backgroundClip:
                                                                    "initial !important",
                                                                  color:
                                                                    "rgb(48, 48, 241) !important",
                                                                  fontSize:
                                                                    "14px !important",
                                                                  fontFamily:
                                                                    "Arial !important",
                                                                  wordBreak:
                                                                    "break-word !important",
                                                                }}
                                                              >
                                                                HOW CAN YOU
                                                                CONTACT US ABOUT
                                                                THIS NOTICE?
                                                              </a>
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    "
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;below
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;or
                                                                    updating
                                                                    your
                                                                    preferences
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    .
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  However,
                                                                  please note
                                                                  that this will
                                                                  not affect the
                                                                  lawfulness of
                                                                  the processing
                                                                  before its
                                                                  withdrawal
                                                                  nor,
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  &nbsp;when
                                                                  applicable law
                                                                  allows,
                                                                  <bdt
                                                                    className="statement-end-if-in-editor"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  &nbsp;will it
                                                                  affect the
                                                                  processing of
                                                                  your personal
                                                                  information
                                                                  conducted in
                                                                  reliance on
                                                                  lawful
                                                                  processing
                                                                  grounds other
                                                                  than consent.
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <strong
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <u
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      Opting out
                                                                      of
                                                                      marketing
                                                                      and
                                                                      promotional
                                                                      communications:
                                                                    </u>
                                                                  </strong>
                                                                  <strong
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <u
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      &nbsp;
                                                                    </u>
                                                                  </strong>
                                                                  You can
                                                                  unsubscribe
                                                                  from our
                                                                  marketing and
                                                                  promotional
                                                                  communications
                                                                  at any time by
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  &nbsp;replying&nbsp;
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  "STOP" or
                                                                  "UNSUBSCRIBE"
                                                                  <bdt
                                                                    className="statement-end-if-in-editor"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  &nbsp;to the
                                                                  SMS messages
                                                                  that we send,
                                                                  <bdt
                                                                    className="statement-end-if-in-editor"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  &nbsp;or by
                                                                  contacting us
                                                                  using the
                                                                  details
                                                                  provided in
                                                                  the
                                                                  section&nbsp;
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  "
                                                                  <bdt
                                                                    className="statement-end-if-in-editor"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </span>
                                                              <a
                                                                data-custom-className="link"
                                                                href="https://app.termly.io/document/privacy-policy/d237bcd6-1d78-41b2-9869-2515b1b3953c#contact"
                                                                style={{
                                                                  backgroundImage:
                                                                    "initial !important",
                                                                  backgroundPosition:
                                                                    "initial !important",
                                                                  backgroundSize:
                                                                    "initial !important",
                                                                  backgroundRepeat:
                                                                    "initial !important",
                                                                  backgroundAttachment:
                                                                    "initial !important",
                                                                  backgroundOrigin:
                                                                    "initial !important",
                                                                  backgroundClip:
                                                                    "initial !important",
                                                                  color:
                                                                    "rgb(48, 48, 241) !important",
                                                                  fontSize:
                                                                    "14px !important",
                                                                  fontFamily:
                                                                    "Arial !important",
                                                                  wordBreak:
                                                                    "break-word !important",
                                                                }}
                                                              >
                                                                HOW CAN YOU
                                                                CONTACT US ABOUT
                                                                THIS NOTICE?
                                                              </a>
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  "
                                                                  <bdt
                                                                    className="statement-end-if-in-editor"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                  &nbsp;below.
                                                                  You will then
                                                                  be removed
                                                                  from the
                                                                  marketing
                                                                  lists.
                                                                  However, we
                                                                  may still
                                                                  communicate
                                                                  with you — for
                                                                  example, to
                                                                  send you
                                                                  service-related
                                                                  messages that
                                                                  are necessary
                                                                  for the
                                                                  administration
                                                                  and use of
                                                                  your account,
                                                                  to respond to
                                                                  service
                                                                  requests, or
                                                                  for other
                                                                  non-marketing
                                                                  purposes.
                                                                </span>
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="statement-end-if-in-editor"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </span>
                                                              <bdt
                                                                className="block-component"
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></span>
                                                                </span>
                                                              </bdt>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="heading_2"
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontFamily:
                                                                      "Arial !important",
                                                                    fontSize:
                                                                      "17px !important",
                                                                  }}
                                                                >
                                                                  <strong
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    Account
                                                                    Information
                                                                  </strong>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                data-custom-className="body_text"
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize:
                                                                    "14px",
                                                                  fontFamily:
                                                                    "Arial",
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent !important",
                                                                  }}
                                                                >
                                                                  If you would
                                                                  at any time
                                                                  like to review
                                                                  or change the
                                                                  information in
                                                                  your account
                                                                  or terminate
                                                                  your account,
                                                                  you can:
                                                                  <bdt
                                                                    className="forloop-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <ul
                                                              style={{
                                                                listStyleType:
                                                                  "square",
                                                                background:
                                                                  "transparent !important",
                                                              }}
                                                            >
                                                              <li
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  Log in to your
                                                                  account
                                                                  settings and
                                                                  update your
                                                                  user account.
                                                                </span>
                                                              </li>
                                                            </ul>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                data-custom-className="body_text"
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize:
                                                                    "14px",
                                                                  fontFamily:
                                                                    "Arial",
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent !important",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="forloop-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  Upon your
                                                                  request to
                                                                  terminate your
                                                                  account, we
                                                                  will
                                                                  deactivate or
                                                                  delete your
                                                                  account and
                                                                  information
                                                                  from our
                                                                  active
                                                                  databases.
                                                                  However, we
                                                                  may retain
                                                                  some
                                                                  information in
                                                                  our files to
                                                                  prevent fraud,
                                                                  troubleshoot
                                                                  problems,
                                                                  assist with
                                                                  any
                                                                  investigations,
                                                                  enforce our
                                                                  legal terms
                                                                  and/or comply
                                                                  with
                                                                  applicable
                                                                  legal
                                                                  requirements.
                                                                </span>
                                                              </span>
                                                              <bdt
                                                                className="statement-end-if-in-editor"
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></span>
                                                                </span>
                                                              </bdt>
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <strong
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <u
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        Cookies
                                                                        and
                                                                        similar
                                                                        technologies:
                                                                      </u>
                                                                    </strong>
                                                                    &nbsp;Most
                                                                    Web browsers
                                                                    are set to
                                                                    accept
                                                                    cookies by
                                                                    default. If
                                                                    you prefer,
                                                                    you can
                                                                    usually
                                                                    choose to
                                                                    set your
                                                                    browser to
                                                                    remove
                                                                    cookies and
                                                                    to reject
                                                                    cookies. If
                                                                    you choose
                                                                    to remove
                                                                    cookies or
                                                                    reject
                                                                    cookies,
                                                                    this could
                                                                    affect
                                                                    certain
                                                                    features or
                                                                    services of
                                                                    our
                                                                    Services. To
                                                                    opt out of
                                                                    interest-based
                                                                    advertising
                                                                    by
                                                                    advertisers
                                                                    on our
                                                                    Services
                                                                    visit&nbsp;
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(48, 48, 241)",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          color:
                                                                            "rgb(89, 89, 89) !important",
                                                                        }}
                                                                      >
                                                                        <a
                                                                          data-custom-className="link"
                                                                          href="http://www.aboutads.info/choices/"
                                                                          rel="noopener noreferrer"
                                                                          target="_blank"
                                                                          style={{
                                                                            backgroundImage:
                                                                              "initial !important",
                                                                            backgroundPosition:
                                                                              "initial !important",
                                                                            backgroundSize:
                                                                              "initial !important",
                                                                            backgroundRepeat:
                                                                              "initial !important",
                                                                            backgroundAttachment:
                                                                              "initial !important",
                                                                            backgroundOrigin:
                                                                              "initial !important",
                                                                            backgroundClip:
                                                                              "initial !important",
                                                                            color:
                                                                              "rgb(48, 48, 241) !important",
                                                                            wordBreak:
                                                                              "break-word !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                              background:
                                                                                "transparent !important",
                                                                              wordBreak:
                                                                                "break-word !important",
                                                                            }}
                                                                          >
                                                                            http://www.aboutads.info/choices/
                                                                          </span>
                                                                        </a>
                                                                      </span>
                                                                    </span>
                                                                    .
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <bdt
                                                                            className="block-component"
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          ></bdt>
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                              background:
                                                                                "transparent !important",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize:
                                                                                    "15px",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "15px",
                                                                                      background:
                                                                                        "transparent !important",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent !important",
                                                                                        fontSize:
                                                                                          "14px",
                                                                                      }}
                                                                                    >
                                                                                      <bdt
                                                                                        className="statement-end-if-in-editor"
                                                                                        style={{
                                                                                          background:
                                                                                            "transparent",
                                                                                        }}
                                                                                      ></bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <bdt
                                                                className="block-component"
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></span>
                                                                </span>
                                                              </bdt>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                data-custom-className="body_text"
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize:
                                                                    "14px",
                                                                  fontFamily:
                                                                    "Arial",
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent !important",
                                                                  }}
                                                                >
                                                                  If you have
                                                                  questions or
                                                                  comments about
                                                                  your privacy
                                                                  rights, you
                                                                  may email us
                                                                  at&nbsp;
                                                                  <bdt
                                                                    className="question"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px",
                                                                    }}
                                                                  >
                                                                    info@feevaa.com
                                                                  </bdt>
                                                                  .
                                                                </span>
                                                              </span>
                                                              <bdt
                                                                className="statement-end-if-in-editor"
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></span>
                                                                </span>
                                                              </bdt>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              id="DNT"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  color:
                                                                    "rgb(127, 127, 127)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        id="control"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          color:
                                                                            "rgb(0, 0, 0)",
                                                                        }}
                                                                      >
                                                                        <strong
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="heading_1"
                                                                            style={{
                                                                              fontSize:
                                                                                "19px",
                                                                              fontFamily:
                                                                                "Arial",
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            11.
                                                                            CONTROLS
                                                                            FOR
                                                                            DO-NOT-TRACK
                                                                            FEATURES
                                                                          </span>
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    Most web
                                                                    browsers and
                                                                    some mobile
                                                                    operating
                                                                    systems and
                                                                    mobile
                                                                    applications
                                                                    include a
                                                                    Do-Not-Track
                                                                    (
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    "DNT"
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    ) feature or
                                                                    setting you
                                                                    can activate
                                                                    to signal
                                                                    your privacy
                                                                    preference
                                                                    not to have
                                                                    data about
                                                                    your online
                                                                    browsing
                                                                    activities
                                                                    monitored
                                                                    and
                                                                    collected.
                                                                    At this
                                                                    stage no
                                                                    uniform
                                                                    technology
                                                                    standard
                                                                    for&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    recognizing
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;and
                                                                    implementing
                                                                    DNT signals
                                                                    has
                                                                    been&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    finalized
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    . As such,
                                                                    we do not
                                                                    currently
                                                                    respond to
                                                                    DNT browser
                                                                    signals or
                                                                    any other
                                                                    mechanism
                                                                    that
                                                                    automatically
                                                                    communicates
                                                                    your choice
                                                                    not to be
                                                                    tracked
                                                                    online. If a
                                                                    standard for
                                                                    online
                                                                    tracking is
                                                                    adopted that
                                                                    we must
                                                                    follow in
                                                                    the future,
                                                                    we will
                                                                    inform you
                                                                    about that
                                                                    practice in
                                                                    a revised
                                                                    version of
                                                                    this privacy
                                                                    notice.
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              id="caresidents"
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  color:
                                                                    "rgb(127, 127, 127)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        id="control"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          color:
                                                                            "rgb(0, 0, 0)",
                                                                        }}
                                                                      >
                                                                        <strong
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="heading_1"
                                                                            style={{
                                                                              fontSize:
                                                                                "19px",
                                                                              fontFamily:
                                                                                "Arial",
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            12.
                                                                            DO
                                                                            CALIFORNIA
                                                                            RESIDENTS
                                                                            HAVE
                                                                            SPECIFIC
                                                                            PRIVACY
                                                                            RIGHTS?
                                                                          </span>
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <strong
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <em
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        In
                                                                        Short:&nbsp;
                                                                      </em>
                                                                    </strong>
                                                                    <em
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      Yes, if
                                                                      you are a
                                                                      resident
                                                                      of
                                                                      California,
                                                                      you are
                                                                      granted
                                                                      specific
                                                                      rights
                                                                      regarding
                                                                      access to
                                                                      your
                                                                      personal
                                                                      information.
                                                                    </em>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    California
                                                                    Civil Code
                                                                    Section
                                                                    1798.83,
                                                                    also known
                                                                    as the&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    "Shine The
                                                                    Light"
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;law,
                                                                    permits our
                                                                    users who
                                                                    are
                                                                    California
                                                                    residents to
                                                                    request and
                                                                    obtain from
                                                                    us, once a
                                                                    year and
                                                                    free of
                                                                    charge,
                                                                    information
                                                                    about
                                                                    categories
                                                                    of personal
                                                                    information
                                                                    (if any) we
                                                                    disclosed to
                                                                    third
                                                                    parties for
                                                                    direct
                                                                    marketing
                                                                    purposes and
                                                                    the names
                                                                    and
                                                                    addresses of
                                                                    all third
                                                                    parties with
                                                                    which we
                                                                    shared
                                                                    personal
                                                                    information
                                                                    in the
                                                                    immediately
                                                                    preceding
                                                                    calendar
                                                                    year. If you
                                                                    are a
                                                                    California
                                                                    resident and
                                                                    would like
                                                                    to make such
                                                                    a request,
                                                                    please
                                                                    submit your
                                                                    request in
                                                                    writing to
                                                                    us using the
                                                                    contact
                                                                    information
                                                                    provided
                                                                    below.
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    If you are
                                                                    under 18
                                                                    years of
                                                                    age, reside
                                                                    in
                                                                    California,
                                                                    and have a
                                                                    registered
                                                                    account with
                                                                    Services,
                                                                    you have the
                                                                    right to
                                                                    request
                                                                    removal of
                                                                    unwanted
                                                                    data that
                                                                    you publicly
                                                                    post on the
                                                                    Services. To
                                                                    request
                                                                    removal of
                                                                    such data,
                                                                    please
                                                                    contact us
                                                                    using the
                                                                    contact
                                                                    information
                                                                    provided
                                                                    below and
                                                                    include the
                                                                    email
                                                                    address
                                                                    associated
                                                                    with your
                                                                    account and
                                                                    a statement
                                                                    that you
                                                                    reside in
                                                                    California.
                                                                    We will make
                                                                    sure the
                                                                    data is not
                                                                    publicly
                                                                    displayed on
                                                                    the
                                                                    Services,
                                                                    but please
                                                                    be aware
                                                                    that the
                                                                    data may not
                                                                    be
                                                                    completely
                                                                    or
                                                                    comprehensively
                                                                    removed from
                                                                    all our
                                                                    systems
                                                                    (e.g.
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    ,
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;backups,
                                                                    etc.).
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                      >
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        ></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                data-custom-className="heading_2"
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontFamily:
                                                                    "Arial !important",
                                                                  fontSize:
                                                                    "17px !important",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent !important",
                                                                  }}
                                                                >
                                                                  <strong
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "17px",
                                                                    }}
                                                                  >
                                                                    CCPA Privacy
                                                                    Notice
                                                                  </strong>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(127, 127, 127)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      The
                                                                      California
                                                                      Code of
                                                                      Regulations
                                                                      defines
                                                                      a&nbsp;
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      "resident"
                                                                      <bdt
                                                                        className="statement-end-if-in-editor"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      &nbsp;as:
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                lineHeight:
                                                                  "1.5",
                                                                marginLeft:
                                                                  "20px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    (1) every
                                                                    individual
                                                                    who is in
                                                                    the State of
                                                                    California
                                                                    for other
                                                                    than a
                                                                    temporary or
                                                                    transitory
                                                                    purpose and
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                lineHeight:
                                                                  "1.5",
                                                                marginLeft:
                                                                  "20px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    (2) every
                                                                    individual
                                                                    who is
                                                                    domiciled in
                                                                    the State of
                                                                    California
                                                                    who is
                                                                    outside the
                                                                    State of
                                                                    California
                                                                    for a
                                                                    temporary or
                                                                    transitory
                                                                    purpose
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    All other
                                                                    individuals
                                                                    are defined
                                                                    as&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    "non-residents."
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    If this
                                                                    definition
                                                                    of&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    "resident"
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;applies
                                                                    to you, we
                                                                    must adhere
                                                                    to certain
                                                                    rights and
                                                                    obligations
                                                                    regarding
                                                                    your
                                                                    personal
                                                                    information.
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <strong
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      What
                                                                      categories
                                                                      of
                                                                      personal
                                                                      information
                                                                      do we
                                                                      collect?
                                                                    </strong>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    We have
                                                                    collected
                                                                    the
                                                                    following
                                                                    categories
                                                                    of personal
                                                                    information
                                                                    in the past
                                                                    twelve (12)
                                                                    months:
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                      >
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        ></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <table
                                                              style={{
                                                                background:
                                                                  "transparent",
                                                                width: "100%",
                                                              }}
                                                              className="table table-borderless table-responsive"
                                                            >
                                                              <tbody
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <tr
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "256.469px",
                                                                      borderLeft:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px !important",
                                                                            fontFamily:
                                                                              "Arial !important",
                                                                          }}
                                                                        >
                                                                          <strong
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            Category
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "393.078px",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px !important",
                                                                            fontFamily:
                                                                              "Arial !important",
                                                                          }}
                                                                        >
                                                                          <strong
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            Examples
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "110.453px",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px !important",
                                                                            fontFamily:
                                                                              "Arial !important",
                                                                          }}
                                                                        >
                                                                          <strong
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            Collected
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </td>
                                                                </tr>
                                                                <tr
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "256.469px",
                                                                      borderLeft:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            A.
                                                                            Identifiers
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "393.078px",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            Contact
                                                                            details,
                                                                            such
                                                                            as
                                                                            real
                                                                            name,
                                                                            alias,
                                                                            postal
                                                                            address,
                                                                            telephone
                                                                            or
                                                                            mobile
                                                                            contact
                                                                            number,
                                                                            unique
                                                                            personal
                                                                            identifier,
                                                                            online
                                                                            identifier,
                                                                            Internet
                                                                            Protocol
                                                                            address,
                                                                            email
                                                                            address,
                                                                            and
                                                                            account
                                                                            name
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "110.453px",
                                                                      textAlign:
                                                                        "center",
                                                                      verticalAlign:
                                                                        "middle",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            NO
                                                                            <bdt
                                                                              className="statement-end-if-in-editor"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                                <tr
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "256.469px",
                                                                      borderLeft:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            B.
                                                                            Personal
                                                                            information
                                                                            categories
                                                                            listed
                                                                            in
                                                                            the
                                                                            California
                                                                            Customer
                                                                            Records
                                                                            statute
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "393.078px",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            Name,
                                                                            contact
                                                                            information,
                                                                            education,
                                                                            employment,
                                                                            employment
                                                                            history,
                                                                            and
                                                                            financial
                                                                            information
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "110.453px",
                                                                      textAlign:
                                                                        "center",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <font
                                                                        color="#595959"
                                                                        face="Arial"
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        >
                                                                          NO
                                                                        </span>
                                                                      </font>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                                <tr
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "256.469px",
                                                                      borderLeft:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            C.
                                                                            Protected
                                                                            classification
                                                                            characteristics
                                                                            under
                                                                            California
                                                                            or
                                                                            federal
                                                                            law
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "393.078px",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            Gender
                                                                            and
                                                                            date
                                                                            of
                                                                            birth
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "110.453px",
                                                                      textAlign:
                                                                        "center",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            NO
                                                                            <bdt
                                                                              className="statement-end-if-in-editor"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                                <tr
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "256.469px",
                                                                      borderLeft:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            D.
                                                                            Commercial
                                                                            information
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "393.078px",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            Transaction
                                                                            information,
                                                                            purchase
                                                                            history,
                                                                            financial
                                                                            details,
                                                                            and
                                                                            payment
                                                                            information
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "110.453px",
                                                                      textAlign:
                                                                        "center",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            NO
                                                                            <bdt
                                                                              className="statement-end-if-in-editor"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                                <tr
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "256.469px",
                                                                      borderLeft:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            E.
                                                                            Biometric
                                                                            information
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "393.078px",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            Fingerprints
                                                                            and
                                                                            voiceprints
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "110.453px",
                                                                      textAlign:
                                                                        "center",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            NO
                                                                            <bdt
                                                                              className="statement-end-if-in-editor"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                                <tr
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "256.469px",
                                                                      borderLeft:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            F.
                                                                            Internet
                                                                            or
                                                                            other
                                                                            similar
                                                                            network
                                                                            activity
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "393.078px",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            Browsing
                                                                            history,
                                                                            search
                                                                            history,
                                                                            online&nbsp;
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            behavior
                                                                            <bdt
                                                                              className="statement-end-if-in-editor"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            ,
                                                                            interest
                                                                            data,
                                                                            and
                                                                            interactions
                                                                            with
                                                                            our
                                                                            and
                                                                            other
                                                                            websites,
                                                                            applications,
                                                                            systems,
                                                                            and
                                                                            advertisements
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "110.453px",
                                                                      textAlign:
                                                                        "center",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            NO
                                                                            <bdt
                                                                              className="statement-end-if-in-editor"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                                <tr
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "256.469px",
                                                                      borderLeft:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            G.
                                                                            Geolocation
                                                                            data
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "393.078px",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            Device
                                                                            location
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "110.453px",
                                                                      textAlign:
                                                                        "center",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            NO
                                                                            <bdt
                                                                              className="statement-end-if-in-editor"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                                <tr
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "256.469px",
                                                                      borderLeft:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            H.
                                                                            Audio,
                                                                            electronic,
                                                                            visual,
                                                                            thermal,
                                                                            olfactory,
                                                                            or
                                                                            similar
                                                                            information
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "393.078px",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            Images
                                                                            and
                                                                            audio,
                                                                            video
                                                                            or
                                                                            call
                                                                            recordings
                                                                            created
                                                                            in
                                                                            connection
                                                                            with
                                                                            our
                                                                            business
                                                                            activities
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "110.453px",
                                                                      textAlign:
                                                                        "center",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            NO
                                                                            <bdt
                                                                              className="statement-end-if-in-editor"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                                <tr
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "256.469px",
                                                                      borderLeft:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            I.
                                                                            Professional
                                                                            or
                                                                            employment-related
                                                                            information
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "393.078px",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            Business
                                                                            contact
                                                                            details
                                                                            in
                                                                            order
                                                                            to
                                                                            provide
                                                                            you
                                                                            our
                                                                            Services
                                                                            at a
                                                                            business
                                                                            level
                                                                            or
                                                                            job
                                                                            title,
                                                                            work
                                                                            history,
                                                                            and
                                                                            professional
                                                                            qualifications
                                                                            if
                                                                            you
                                                                            apply
                                                                            for
                                                                            a
                                                                            job
                                                                            with
                                                                            us
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "110.453px",
                                                                      textAlign:
                                                                        "center",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            NO
                                                                            <bdt
                                                                              className="statement-end-if-in-editor"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                                <tr
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      borderLeft:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                      width:
                                                                        "256.469px",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            J.
                                                                            Education
                                                                            Information
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "393.078px",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            Student
                                                                            records
                                                                            and
                                                                            directory
                                                                            information
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      width:
                                                                        "110.453px",
                                                                      textAlign:
                                                                        "center",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            NO
                                                                            <bdt
                                                                              className="statement-end-if-in-editor"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                                <tr
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      borderWidth:
                                                                        "1px",
                                                                      borderColor:
                                                                        "black",
                                                                      borderStyle:
                                                                        "solid",
                                                                      width:
                                                                        "256.469px",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            K.
                                                                            Inferences
                                                                            drawn
                                                                            from
                                                                            other
                                                                            personal
                                                                            information
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      borderBottom:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      width:
                                                                        "393.078px",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            Inferences
                                                                            drawn
                                                                            from
                                                                            any
                                                                            of
                                                                            the
                                                                            collected
                                                                            personal
                                                                            information
                                                                            listed
                                                                            above
                                                                            to
                                                                            create
                                                                            a
                                                                            profile
                                                                            or
                                                                            summary
                                                                            about,
                                                                            for
                                                                            example,
                                                                            an
                                                                            individual’s
                                                                            preferences
                                                                            and
                                                                            characteristics
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      textAlign:
                                                                        "center",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderBottom:
                                                                        "1px solid black",
                                                                      borderTop:
                                                                        "1px solid black",
                                                                      width:
                                                                        "110.453px",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "15px",
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px !important",
                                                                              fontFamily:
                                                                                "Arial !important",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            NO
                                                                            <bdt
                                                                              className="statement-end-if-in-editor"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                data-custom-className="body_text"
                                                                                style={{
                                                                                  background:
                                                                                    "transparent",
                                                                                }}
                                                                              ></span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "15px",
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <br
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                                <tr
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      borderLeft:
                                                                        "1px solid black",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderBottom:
                                                                        "1px solid black",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "14px",
                                                                        fontFamily:
                                                                          "Arial",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      L.
                                                                      Sensitive
                                                                      Personal
                                                                      Information
                                                                    </span>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderBottom:
                                                                        "1px solid black",
                                                                      lineHeight: 1.5,
                                                                    }}
                                                                  >
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "14px",
                                                                          fontFamily:
                                                                            "Arial",
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></span>
                                                                    </bdt>
                                                                  </td>
                                                                  <td
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      color:
                                                                        "rgb(89, 89, 89) !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                      borderRight:
                                                                        "1px solid black",
                                                                      borderBottom:
                                                                        "1px solid black",
                                                                      textAlign:
                                                                        "center",
                                                                      lineHeight: 1.5,
                                                                    }}
                                                                  >
                                                                    <br
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    />
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></span>
                                                                    </bdt>
                                                                    NO
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></span>
                                                                    </bdt>
                                                                    <br
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    />
                                                                    <br
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    />
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <bdt
                                                                className="block-component"
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              ></bdt>
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <br
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              style={{
                                                                background:
                                                                  "transparent !important",
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <bdt
                                                                className="block-component"
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                ></span>
                                                              </bdt>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      We may
                                                                      also
                                                                      collect
                                                                      other
                                                                      personal
                                                                      information
                                                                      outside of
                                                                      these
                                                                      categories
                                                                      through
                                                                      instances
                                                                      where you
                                                                      interact
                                                                      with us in
                                                                      person,
                                                                      online, or
                                                                      by phone
                                                                      or mail in
                                                                      the
                                                                      context
                                                                      of:
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                          background:
                                                                            "transparent !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                                background:
                                                                                  "transparent !important",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                data-custom-className="body_text"
                                                                                style={{
                                                                                  background:
                                                                                    "transparent !important",
                                                                                  fontSize:
                                                                                    "14px",
                                                                                }}
                                                                              >
                                                                                <bdt
                                                                                  className="block-component"
                                                                                  style={{
                                                                                    background:
                                                                                      "transparent",
                                                                                  }}
                                                                                ></bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        Receiving
                                                                        help
                                                                        through
                                                                        our
                                                                        customer
                                                                        support
                                                                        channels;
                                                                      </span>
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize:
                                                                                    "15px",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  data-custom-className="body_text"
                                                                                  style={{
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "15px",
                                                                                      background:
                                                                                        "transparent !important",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      data-custom-className="body_text"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent !important",
                                                                                        fontSize:
                                                                                          "14px",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize:
                                                                                            "15px",
                                                                                          background:
                                                                                            "transparent !important",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            background:
                                                                                              "transparent !important",
                                                                                            fontSize:
                                                                                              "14px",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            data-custom-className="body_text"
                                                                                            style={{
                                                                                              background:
                                                                                                "transparent",
                                                                                            }}
                                                                                          >
                                                                                            <bdt
                                                                                              className="statement-end-if-in-editor"
                                                                                              style={{
                                                                                                background:
                                                                                                  "transparent",
                                                                                              }}
                                                                                            ></bdt>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        Participation
                                                                        in
                                                                        customer
                                                                        surveys
                                                                        or
                                                                        contests;
                                                                        and
                                                                      </span>
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize:
                                                                                    "15px",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  data-custom-className="body_text"
                                                                                  style={{
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "15px",
                                                                                      background:
                                                                                        "transparent !important",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      data-custom-className="body_text"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent !important",
                                                                                        fontSize:
                                                                                          "14px",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize:
                                                                                            "15px",
                                                                                          background:
                                                                                            "transparent !important",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            background:
                                                                                              "transparent !important",
                                                                                            fontSize:
                                                                                              "14px",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            data-custom-className="body_text"
                                                                                            style={{
                                                                                              background:
                                                                                                "transparent",
                                                                                            }}
                                                                                          >
                                                                                            <bdt
                                                                                              className="statement-end-if-in-editor"
                                                                                              style={{
                                                                                                background:
                                                                                                  "transparent",
                                                                                              }}
                                                                                            ></bdt>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        Facilitation
                                                                        in the
                                                                        delivery
                                                                        of our
                                                                        Services
                                                                        and to
                                                                        respond
                                                                        to your
                                                                        inquiries.
                                                                      </span>
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize:
                                                                                    "15px",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  data-custom-className="body_text"
                                                                                  style={{
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "15px",
                                                                                      background:
                                                                                        "transparent !important",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      data-custom-className="body_text"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent !important",
                                                                                        fontSize:
                                                                                          "14px",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize:
                                                                                            "15px",
                                                                                          background:
                                                                                            "transparent !important",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            background:
                                                                                              "transparent !important",
                                                                                            fontSize:
                                                                                              "14px",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            data-custom-className="body_text"
                                                                                            style={{
                                                                                              background:
                                                                                                "transparent",
                                                                                            }}
                                                                                          >
                                                                                            <bdt
                                                                                              className="statement-end-if-in-editor"
                                                                                              style={{
                                                                                                background:
                                                                                                  "transparent",
                                                                                              }}
                                                                                            ></bdt>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <strong
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        How do
                                                                        we use
                                                                        and
                                                                        share
                                                                        your
                                                                        personal
                                                                        information?
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                          background:
                                                                            "transparent !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px",
                                                                            }}
                                                                          >
                                                                            More
                                                                            information
                                                                            about
                                                                            our
                                                                            data
                                                                            collection
                                                                            and
                                                                            sharing
                                                                            practices
                                                                            can
                                                                            be
                                                                            found
                                                                            in
                                                                            this
                                                                            privacy
                                                                            notice
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            .
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                          background:
                                                                            "transparent !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                              background:
                                                                                "transparent !important",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <bdt
                                                                                className="block-component"
                                                                                style={{
                                                                                  background:
                                                                                    "transparent",
                                                                                }}
                                                                              ></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      You may
                                                                      contact
                                                                      us&nbsp;
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                          background:
                                                                            "transparent !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        >
                                                                          <bdt
                                                                            className="block-component"
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          ></bdt>
                                                                        </span>
                                                                      </span>
                                                                      by email
                                                                      at&nbsp;
                                                                    </span>
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        <bdt
                                                                          className="question"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          Info@feevaa.com
                                                                        </bdt>
                                                                        ,&nbsp;
                                                                        <bdt
                                                                          className="statement-end-if-in-editor"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        ></bdt>
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        ></bdt>
                                                                      </span>
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        ></bdt>
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          ></span>
                                                                        </bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  or by
                                                                  referring to
                                                                  the contact
                                                                  details at the
                                                                  bottom of this
                                                                  document.
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      If you are
                                                                      using
                                                                      an&nbsp;
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      authorized
                                                                      <bdt
                                                                        className="statement-end-if-in-editor"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      &nbsp;agent
                                                                      to
                                                                      exercise
                                                                      your right
                                                                      to opt out
                                                                      we may
                                                                      deny a
                                                                      request if
                                                                      the&nbsp;
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      authorized
                                                                      <bdt
                                                                        className="statement-end-if-in-editor"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      &nbsp;agent
                                                                      does not
                                                                      submit
                                                                      proof that
                                                                      they have
                                                                      been
                                                                      validly&nbsp;
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      authorized
                                                                      <bdt
                                                                        className="statement-end-if-in-editor"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      &nbsp;to
                                                                      act on
                                                                      your
                                                                      behalf.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <strong
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        Will
                                                                        your
                                                                        information
                                                                        be
                                                                        shared
                                                                        with
                                                                        anyone
                                                                        else?
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      We may
                                                                      disclose
                                                                      your
                                                                      personal
                                                                      information
                                                                      with our
                                                                      service
                                                                      providers
                                                                      pursuant
                                                                      to a
                                                                      written
                                                                      contract
                                                                      between us
                                                                      and each
                                                                      service
                                                                      provider.
                                                                      Each
                                                                      service
                                                                      provider
                                                                      is a
                                                                      for-profit
                                                                      entity
                                                                      that
                                                                      processes
                                                                      the
                                                                      information
                                                                      on our
                                                                      behalf,
                                                                      following
                                                                      the same
                                                                      strict
                                                                      privacy
                                                                      protection
                                                                      obligations
                                                                      mandated
                                                                      by the
                                                                      CCPA.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      We may use
                                                                      your
                                                                      personal
                                                                      information
                                                                      for our
                                                                      own
                                                                      business
                                                                      purposes,
                                                                      such as
                                                                      for
                                                                      undertaking
                                                                      internal
                                                                      research
                                                                      for
                                                                      technological
                                                                      development
                                                                      and
                                                                      demonstration.
                                                                      This is
                                                                      not
                                                                      considered
                                                                      to
                                                                      be&nbsp;
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      "selling"
                                                                      <bdt
                                                                        className="statement-end-if-in-editor"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      &nbsp;of
                                                                      your
                                                                      personal
                                                                      information.
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                          background:
                                                                            "transparent !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        >
                                                                          <bdt
                                                                            className="block-component"
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          ></bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="question"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        The
                                                                        Ojunta
                                                                        Group
                                                                        LLC
                                                                      </bdt>
                                                                    </span>
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      &nbsp;has
                                                                      not
                                                                      disclosed,
                                                                      sold, or
                                                                      shared any
                                                                      personal
                                                                      information
                                                                      to third
                                                                      parties
                                                                      for a
                                                                      business
                                                                      or
                                                                      commercial
                                                                      purpose in
                                                                      the
                                                                      preceding
                                                                      twelve
                                                                      (12)
                                                                      months.&nbsp;
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="question"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              The
                                                                              Ojunta
                                                                              Group
                                                                              LLC
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                        &nbsp;
                                                                      </span>
                                                                      will not
                                                                      sell or
                                                                      share
                                                                      personal
                                                                      information
                                                                      in the
                                                                      future
                                                                      belonging
                                                                      to website
                                                                      visitors,
                                                                      users, and
                                                                      other
                                                                      consumers.
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  background:
                                                                                    "transparent",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  data-custom-className="body_text"
                                                                                  style={{
                                                                                    background:
                                                                                      "transparent",
                                                                                  }}
                                                                                >
                                                                                  <bdt
                                                                                    className="statement-end-if-in-editor"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent",
                                                                                    }}
                                                                                  ></bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          <bdt
                                                                            className="block-component"
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          ></bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <strong
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        Your
                                                                        rights
                                                                        with
                                                                        respect
                                                                        to your
                                                                        personal
                                                                        data
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <u
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        Right to
                                                                        request
                                                                        deletion
                                                                        of the
                                                                        data —
                                                                        Request
                                                                        to
                                                                        delete
                                                                      </u>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      You can
                                                                      ask for
                                                                      the
                                                                      deletion
                                                                      of your
                                                                      personal
                                                                      information.
                                                                      If you ask
                                                                      us to
                                                                      delete
                                                                      your
                                                                      personal
                                                                      information,
                                                                      we will
                                                                      respect
                                                                      your
                                                                      request
                                                                      and delete
                                                                      your
                                                                      personal
                                                                      information,
                                                                      subject to
                                                                      certain
                                                                      exceptions
                                                                      provided
                                                                      by law,
                                                                      such as
                                                                      (but not
                                                                      limited
                                                                      to) the
                                                                      exercise
                                                                      by another
                                                                      consumer
                                                                      of his or
                                                                      her right
                                                                      to free
                                                                      speech,
                                                                      our
                                                                      compliance
                                                                      requirements
                                                                      resulting
                                                                      from a
                                                                      legal
                                                                      obligation,
                                                                      or any
                                                                      processing
                                                                      that may
                                                                      be
                                                                      required
                                                                      to protect
                                                                      against
                                                                      illegal
                                                                      activities.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <u
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        Right to
                                                                        be
                                                                        informed
                                                                        —
                                                                        Request
                                                                        to know
                                                                      </u>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      Depending
                                                                      on the
                                                                      circumstances,
                                                                      you have a
                                                                      right to
                                                                      know:
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                          background:
                                                                            "transparent !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                                background:
                                                                                  "transparent !important",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  background:
                                                                                    "transparent",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  data-custom-className="body_text"
                                                                                  style={{
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "15px",
                                                                                      background:
                                                                                        "transparent !important",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      data-custom-className="body_text"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent !important",
                                                                                        fontSize:
                                                                                          "14px",
                                                                                      }}
                                                                                    >
                                                                                      <bdt
                                                                                        className="block-component"
                                                                                        style={{
                                                                                          background:
                                                                                            "transparent",
                                                                                        }}
                                                                                      ></bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        whether
                                                                        we
                                                                        collect
                                                                        and use
                                                                        your
                                                                        personal
                                                                        information;
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                              background:
                                                                                "transparent !important",
                                                                              color:
                                                                                "rgb(89, 89, 89) !important",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(0, 0, 0)",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    color:
                                                                                      "rgb(89, 89, 89) !important",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    data-custom-className="body_text"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent !important",
                                                                                      fontSize:
                                                                                        "14px",
                                                                                    }}
                                                                                  >
                                                                                    <bdt
                                                                                      className="statement-end-if-in-editor"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent",
                                                                                      }}
                                                                                    ></bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        the
                                                                        categories
                                                                        of
                                                                        personal
                                                                        information
                                                                        that we
                                                                        collect;
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                              background:
                                                                                "transparent !important",
                                                                              color:
                                                                                "rgb(89, 89, 89) !important",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(0, 0, 0)",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    color:
                                                                                      "rgb(89, 89, 89) !important",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    data-custom-className="body_text"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent !important",
                                                                                      fontSize:
                                                                                        "14px",
                                                                                    }}
                                                                                  >
                                                                                    <bdt
                                                                                      className="statement-end-if-in-editor"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent",
                                                                                      }}
                                                                                    ></bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        the
                                                                        purposes
                                                                        for
                                                                        which
                                                                        the
                                                                        collected
                                                                        personal
                                                                        information
                                                                        is used;
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                              background:
                                                                                "transparent !important",
                                                                              color:
                                                                                "rgb(89, 89, 89) !important",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(0, 0, 0)",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    color:
                                                                                      "rgb(89, 89, 89) !important",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    data-custom-className="body_text"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent !important",
                                                                                      fontSize:
                                                                                        "14px",
                                                                                    }}
                                                                                  >
                                                                                    <bdt
                                                                                      className="statement-end-if-in-editor"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent",
                                                                                      }}
                                                                                    ></bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        whether
                                                                        we sell
                                                                        or share
                                                                        personal
                                                                        information
                                                                        to third
                                                                        parties;
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                              background:
                                                                                "transparent !important",
                                                                              color:
                                                                                "rgb(89, 89, 89) !important",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(0, 0, 0)",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    color:
                                                                                      "rgb(89, 89, 89) !important",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    data-custom-className="body_text"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent !important",
                                                                                      fontSize:
                                                                                        "14px",
                                                                                    }}
                                                                                  >
                                                                                    <bdt
                                                                                      className="statement-end-if-in-editor"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent",
                                                                                      }}
                                                                                    ></bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        the
                                                                        categories
                                                                        of
                                                                        personal
                                                                        information
                                                                        that we
                                                                        sold,
                                                                        shared,
                                                                        or
                                                                        disclosed
                                                                        for a
                                                                        business
                                                                        purpose;
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                              background:
                                                                                "transparent !important",
                                                                              color:
                                                                                "rgb(89, 89, 89) !important",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(0, 0, 0)",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    color:
                                                                                      "rgb(89, 89, 89) !important",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    data-custom-className="body_text"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent !important",
                                                                                      fontSize:
                                                                                        "14px",
                                                                                    }}
                                                                                  >
                                                                                    <bdt
                                                                                      className="statement-end-if-in-editor"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent",
                                                                                      }}
                                                                                    ></bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        the
                                                                        categories
                                                                        of third
                                                                        parties
                                                                        to whom
                                                                        the
                                                                        personal
                                                                        information
                                                                        was
                                                                        sold,
                                                                        shared,
                                                                        or
                                                                        disclosed
                                                                        for a
                                                                        business
                                                                        purpose;
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                              background:
                                                                                "transparent !important",
                                                                              color:
                                                                                "rgb(89, 89, 89) !important",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(0, 0, 0)",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    color:
                                                                                      "rgb(89, 89, 89) !important",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    data-custom-className="body_text"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent !important",
                                                                                      fontSize:
                                                                                        "14px",
                                                                                    }}
                                                                                  >
                                                                                    <bdt
                                                                                      className="statement-end-if-in-editor"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent",
                                                                                      }}
                                                                                    ></bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        the
                                                                        business
                                                                        or
                                                                        commercial
                                                                        purpose
                                                                        for
                                                                        collecting,
                                                                        selling,
                                                                        or
                                                                        sharing
                                                                        personal
                                                                        information;
                                                                        and
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                              background:
                                                                                "transparent !important",
                                                                              color:
                                                                                "rgb(89, 89, 89) !important",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(0, 0, 0)",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    color:
                                                                                      "rgb(89, 89, 89) !important",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    data-custom-className="body_text"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent !important",
                                                                                      fontSize:
                                                                                        "14px",
                                                                                    }}
                                                                                  >
                                                                                    <bdt
                                                                                      className="statement-end-if-in-editor"
                                                                                      style={{
                                                                                        background:
                                                                                          "transparent",
                                                                                      }}
                                                                                    ></bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <bdt
                                                                  className="block-component"
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></span>
                                                                </bdt>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    the specific
                                                                    pieces of
                                                                    personal
                                                                    information
                                                                    we collected
                                                                    about you.
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      In
                                                                      accordance
                                                                      with
                                                                      applicable
                                                                      law, we
                                                                      are not
                                                                      obligated
                                                                      to provide
                                                                      or delete
                                                                      consumer
                                                                      information
                                                                      that is
                                                                      de-identified
                                                                      in
                                                                      response
                                                                      to a
                                                                      consumer
                                                                      request or
                                                                      to
                                                                      re-identify
                                                                      individual
                                                                      data to
                                                                      verify a
                                                                      consumer
                                                                      request.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <u
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        Right to
                                                                        Non-Discrimination
                                                                        for the
                                                                        Exercise
                                                                        of a
                                                                        Consumer’s
                                                                        Privacy
                                                                        Rights
                                                                      </u>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      We will
                                                                      not
                                                                      discriminate
                                                                      against
                                                                      you if you
                                                                      exercise
                                                                      your
                                                                      privacy
                                                                      rights.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <u
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    Right to
                                                                    Limit Use
                                                                    and
                                                                    Disclosure
                                                                    of Sensitive
                                                                    Personal
                                                                    Information
                                                                  </span>
                                                                </u>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <bdt
                                                                  className="block-component"
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></span>
                                                                </bdt>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  We do not
                                                                  process
                                                                  consumer's
                                                                  sensitive
                                                                  personal
                                                                  information.
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <bdt
                                                                  className="statement-end-if-in-editor"
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></span>
                                                                </bdt>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <u
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        Verification
                                                                        process
                                                                      </u>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      Upon
                                                                      receiving
                                                                      your
                                                                      request,
                                                                      we will
                                                                      need to
                                                                      verify
                                                                      your
                                                                      identity
                                                                      to
                                                                      determine
                                                                      you are
                                                                      the same
                                                                      person
                                                                      about whom
                                                                      we have
                                                                      the
                                                                      information
                                                                      in our
                                                                      system.
                                                                      These
                                                                      verification
                                                                      efforts
                                                                      require us
                                                                      to ask you
                                                                      to provide
                                                                      information
                                                                      so that we
                                                                      can match
                                                                      it with
                                                                      information
                                                                      you have
                                                                      previously
                                                                      provided
                                                                      us. For
                                                                      instance,
                                                                      depending
                                                                      on the
                                                                      type of
                                                                      request
                                                                      you
                                                                      submit, we
                                                                      may ask
                                                                      you to
                                                                      provide
                                                                      certain
                                                                      information
                                                                      so that we
                                                                      can match
                                                                      the
                                                                      information
                                                                      you
                                                                      provide
                                                                      with the
                                                                      information
                                                                      we already
                                                                      have on
                                                                      file, or
                                                                      we may
                                                                      contact
                                                                      you
                                                                      through a
                                                                      communication
                                                                      method
                                                                      (e.g.
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      ,
                                                                      <bdt
                                                                        className="statement-end-if-in-editor"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      &nbsp;phone
                                                                      or email)
                                                                      that you
                                                                      have
                                                                      previously
                                                                      provided
                                                                      to us. We
                                                                      may also
                                                                      use other
                                                                      verification
                                                                      methods as
                                                                      the
                                                                      circumstances
                                                                      dictate.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      We will
                                                                      only use
                                                                      personal
                                                                      information
                                                                      provided
                                                                      in your
                                                                      request to
                                                                      verify
                                                                      your
                                                                      identity
                                                                      or
                                                                      authority
                                                                      to make
                                                                      the
                                                                      request.
                                                                      To the
                                                                      extent
                                                                      possible,
                                                                      we will
                                                                      avoid
                                                                      requesting
                                                                      additional
                                                                      information
                                                                      from you
                                                                      for the
                                                                      purposes
                                                                      of
                                                                      verification.
                                                                      However,
                                                                      if we
                                                                      cannot
                                                                      verify
                                                                      your
                                                                      identity
                                                                      from the
                                                                      information
                                                                      already
                                                                      maintained
                                                                      by us, we
                                                                      may
                                                                      request
                                                                      that you
                                                                      provide
                                                                      additional
                                                                      information
                                                                      for the
                                                                      purposes
                                                                      of
                                                                      verifying
                                                                      your
                                                                      identity
                                                                      and for
                                                                      security
                                                                      or
                                                                      fraud-prevention
                                                                      purposes.
                                                                      We will
                                                                      delete
                                                                      such
                                                                      additionally
                                                                      provided
                                                                      information
                                                                      as soon as
                                                                      we finish
                                                                      verifying
                                                                      you.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <u
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        Other
                                                                        privacy
                                                                        rights
                                                                      </u>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        You may
                                                                        object
                                                                        to the
                                                                        processing
                                                                        of your
                                                                        personal
                                                                        information.
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(0, 0, 0)",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    color:
                                                                                      "rgb(89, 89, 89) !important",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    data-custom-className="body_text"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent !important",
                                                                                      fontSize:
                                                                                        "14px",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(0, 0, 0)",
                                                                                        background:
                                                                                          "transparent !important",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize:
                                                                                            "15px",
                                                                                          background:
                                                                                            "transparent !important",
                                                                                          color:
                                                                                            "rgb(89, 89, 89) !important",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          data-custom-className="body_text"
                                                                                          style={{
                                                                                            background:
                                                                                              "transparent !important",
                                                                                            fontSize:
                                                                                              "14px",
                                                                                          }}
                                                                                        >
                                                                                          <bdt
                                                                                            className="statement-end-if-in-editor"
                                                                                            style={{
                                                                                              background:
                                                                                                "transparent",
                                                                                            }}
                                                                                          ></bdt>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        You may
                                                                        request
                                                                        correction
                                                                        of your
                                                                        personal
                                                                        data if
                                                                        it is
                                                                        incorrect
                                                                        or no
                                                                        longer
                                                                        relevant,
                                                                        or ask
                                                                        to
                                                                        restrict
                                                                        the
                                                                        processing
                                                                        of the
                                                                        information.
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(0, 0, 0)",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    color:
                                                                                      "rgb(89, 89, 89) !important",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    data-custom-className="body_text"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent !important",
                                                                                      fontSize:
                                                                                        "14px",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(0, 0, 0)",
                                                                                        background:
                                                                                          "transparent !important",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize:
                                                                                            "15px",
                                                                                          background:
                                                                                            "transparent !important",
                                                                                          color:
                                                                                            "rgb(89, 89, 89) !important",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          data-custom-className="body_text"
                                                                                          style={{
                                                                                            background:
                                                                                              "transparent !important",
                                                                                            fontSize:
                                                                                              "14px",
                                                                                          }}
                                                                                        >
                                                                                          <bdt
                                                                                            className="statement-end-if-in-editor"
                                                                                            style={{
                                                                                              background:
                                                                                                "transparent",
                                                                                            }}
                                                                                          ></bdt>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        You can
                                                                        designate
                                                                        an&nbsp;
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        ></bdt>
                                                                        authorized
                                                                        <bdt
                                                                          className="statement-end-if-in-editor"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        ></bdt>
                                                                        &nbsp;agent
                                                                        to make
                                                                        a
                                                                        request
                                                                        under
                                                                        the CCPA
                                                                        on your
                                                                        behalf.
                                                                        We may
                                                                        deny a
                                                                        request
                                                                        from
                                                                        an&nbsp;
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        ></bdt>
                                                                        authorized
                                                                        <bdt
                                                                          className="statement-end-if-in-editor"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        ></bdt>
                                                                        &nbsp;agent
                                                                        that
                                                                        does not
                                                                        submit
                                                                        proof
                                                                        that
                                                                        they
                                                                        have
                                                                        been
                                                                        validly&nbsp;
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        ></bdt>
                                                                        authorized
                                                                        <bdt
                                                                          className="statement-end-if-in-editor"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        ></bdt>
                                                                        &nbsp;to
                                                                        act on
                                                                        your
                                                                        behalf
                                                                        in
                                                                        accordance
                                                                        with the
                                                                        CCPA.
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(0, 0, 0)",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    color:
                                                                                      "rgb(89, 89, 89) !important",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    data-custom-className="body_text"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent !important",
                                                                                      fontSize:
                                                                                        "14px",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(0, 0, 0)",
                                                                                        background:
                                                                                          "transparent !important",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize:
                                                                                            "15px",
                                                                                          background:
                                                                                            "transparent !important",
                                                                                          color:
                                                                                            "rgb(89, 89, 89) !important",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          data-custom-className="body_text"
                                                                                          style={{
                                                                                            background:
                                                                                              "transparent !important",
                                                                                            fontSize:
                                                                                              "14px",
                                                                                          }}
                                                                                        >
                                                                                          <bdt
                                                                                            className="statement-end-if-in-editor"
                                                                                            style={{
                                                                                              background:
                                                                                                "transparent",
                                                                                            }}
                                                                                          ></bdt>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        You may
                                                                        request
                                                                        to opt
                                                                        out from
                                                                        future
                                                                        selling
                                                                        or
                                                                        sharing
                                                                        of your
                                                                        personal
                                                                        information
                                                                        to third
                                                                        parties.
                                                                        Upon
                                                                        receiving
                                                                        an
                                                                        opt-out
                                                                        request,
                                                                        we will
                                                                        act upon
                                                                        the
                                                                        request
                                                                        as soon
                                                                        as
                                                                        feasibly
                                                                        possible,
                                                                        but no
                                                                        later
                                                                        than
                                                                        fifteen
                                                                        (15)
                                                                        days
                                                                        from the
                                                                        date of
                                                                        the
                                                                        request
                                                                        submission.
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent !important",
                                                                                fontSize:
                                                                                  "14px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(0, 0, 0)",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    color:
                                                                                      "rgb(89, 89, 89) !important",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    data-custom-className="body_text"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent !important",
                                                                                      fontSize:
                                                                                        "14px",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(0, 0, 0)",
                                                                                        background:
                                                                                          "transparent !important",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize:
                                                                                            "15px",
                                                                                          background:
                                                                                            "transparent !important",
                                                                                          color:
                                                                                            "rgb(89, 89, 89) !important",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          data-custom-className="body_text"
                                                                                          style={{
                                                                                            background:
                                                                                              "transparent !important",
                                                                                            fontSize:
                                                                                              "14px",
                                                                                          }}
                                                                                        >
                                                                                          <bdt
                                                                                            className="statement-end-if-in-editor"
                                                                                            style={{
                                                                                              background:
                                                                                                "transparent",
                                                                                            }}
                                                                                          ></bdt>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      To
                                                                      exercise
                                                                      these
                                                                      rights,
                                                                      you can
                                                                      contact
                                                                      us&nbsp;
                                                                    </span>
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px !important",
                                                                          fontFamily:
                                                                            "Arial !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            by
                                                                            email
                                                                            at&nbsp;
                                                                            <bdt
                                                                              className="question"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              Info@feevaa.com
                                                                            </bdt>
                                                                            ,&nbsp;
                                                                            <bdt
                                                                              className="statement-end-if-in-editor"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                          </span>
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            ></bdt>
                                                                            <bdt
                                                                              className="block-component"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                data-custom-className="body_text"
                                                                                style={{
                                                                                  background:
                                                                                    "transparent",
                                                                                }}
                                                                              ></span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  or by
                                                                  referring to
                                                                  the contact
                                                                  details at the
                                                                  bottom of this
                                                                  document. If
                                                                  you have a
                                                                  complaint
                                                                  about how we
                                                                  handle your
                                                                  data, we would
                                                                  like to hear
                                                                  from you.
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px !important",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </span>
                                                                <bdt
                                                                  className="block-component"
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></span>
                                                                </bdt>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <br
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  />
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  id="virginia"
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <strong
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="heading_1"
                                                                      style={{
                                                                        fontSize:
                                                                          "19px",
                                                                        fontFamily:
                                                                          "Arial",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      13. DO
                                                                      VIRGINIA
                                                                      RESIDENTS
                                                                      HAVE
                                                                      SPECIFIC
                                                                      PRIVACY
                                                                      RIGHTS?
                                                                    </span>
                                                                  </strong>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <br
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  />
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <em
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <strong
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        data-custom-className="body_text"
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "14px",
                                                                          fontFamily:
                                                                            "Arial",
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        In
                                                                        Short:
                                                                      </span>
                                                                    </strong>
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "14px",
                                                                        fontFamily:
                                                                          "Arial",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      &nbsp;Yes,
                                                                      if you are
                                                                      a resident
                                                                      of
                                                                      Virginia,
                                                                      you may be
                                                                      granted
                                                                      specific
                                                                      rights
                                                                      regarding
                                                                      access to
                                                                      and use of
                                                                      your
                                                                      personal
                                                                      information.
                                                                    </span>
                                                                  </em>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <br
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  />
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <strong
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="heading_2"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                        fontSize:
                                                                          "17px !important",
                                                                      }}
                                                                    >
                                                                      Virginia
                                                                      CDPA
                                                                      Privacy
                                                                      Notice
                                                                    </span>
                                                                  </strong>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <br
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  />
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    Under the
                                                                    Virginia
                                                                    Consumer
                                                                    Data
                                                                    Protection
                                                                    Act (CDPA):
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <br
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  />
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    "Consumer"
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;means
                                                                    a natural
                                                                    person who
                                                                    is a
                                                                    resident of
                                                                    the
                                                                    Commonwealth
                                                                    acting only
                                                                    in an
                                                                    individual
                                                                    or household
                                                                    context. It
                                                                    does not
                                                                    include a
                                                                    natural
                                                                    person
                                                                    acting in a
                                                                    commercial
                                                                    or
                                                                    employment
                                                                    context.
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <br
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  />
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    "Personal
                                                                    data"
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;means
                                                                    any
                                                                    information
                                                                    that is
                                                                    linked or
                                                                    reasonably
                                                                    linkable to
                                                                    an
                                                                    identified
                                                                    or
                                                                    identifiable
                                                                    natural
                                                                    person.&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    "Personal
                                                                    data"
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;does
                                                                    not include
                                                                    de-identified
                                                                    data or
                                                                    publicly
                                                                    available
                                                                    information.
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <br
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  />
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    "Sale of
                                                                    personal
                                                                    data"
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;means
                                                                    the exchange
                                                                    of personal
                                                                    data for
                                                                    monetary
                                                                    consideration.
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <br
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  />
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    If this
                                                                    definition&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    "consumer"
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;applies
                                                                    to you, we
                                                                    must adhere
                                                                    to certain
                                                                    rights and
                                                                    obligations
                                                                    regarding
                                                                    your
                                                                    personal
                                                                    data.
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    The
                                                                    information
                                                                    we collect,
                                                                    use, and
                                                                    disclose
                                                                    about you
                                                                    will vary
                                                                    depending on
                                                                    how you
                                                                    interact
                                                                    with&nbsp;
                                                                    <bdt
                                                                      className="question"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      The Ojunta
                                                                      Group LLC
                                                                    </bdt>
                                                                    &nbsp;and
                                                                    our
                                                                    Services. To
                                                                    find out
                                                                    more, please
                                                                    visit the
                                                                    following
                                                                    links:
                                                                  </span>
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <a
                                                                      data-custom-className="link"
                                                                      href="https://app.termly.io/document/privacy-policy/d237bcd6-1d78-41b2-9869-2515b1b3953c#infocollect"
                                                                      style={{
                                                                        backgroundImage:
                                                                          "initial !important",
                                                                        backgroundPosition:
                                                                          "initial !important",
                                                                        backgroundSize:
                                                                          "initial !important",
                                                                        backgroundRepeat:
                                                                          "initial !important",
                                                                        backgroundAttachment:
                                                                          "initial !important",
                                                                        backgroundOrigin:
                                                                          "initial !important",
                                                                        backgroundClip:
                                                                          "initial !important",
                                                                        color:
                                                                          "rgb(48, 48, 241) !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                        wordBreak:
                                                                          "break-word !important",
                                                                      }}
                                                                    >
                                                                      Personal
                                                                      data we
                                                                      collect
                                                                    </a>
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <a
                                                                      data-custom-className="link"
                                                                      href="https://app.termly.io/document/privacy-policy/d237bcd6-1d78-41b2-9869-2515b1b3953c#infouse"
                                                                      style={{
                                                                        backgroundImage:
                                                                          "initial !important",
                                                                        backgroundPosition:
                                                                          "initial !important",
                                                                        backgroundSize:
                                                                          "initial !important",
                                                                        backgroundRepeat:
                                                                          "initial !important",
                                                                        backgroundAttachment:
                                                                          "initial !important",
                                                                        backgroundOrigin:
                                                                          "initial !important",
                                                                        backgroundClip:
                                                                          "initial !important",
                                                                        color:
                                                                          "rgb(48, 48, 241) !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                        wordBreak:
                                                                          "break-word !important",
                                                                      }}
                                                                    >
                                                                      How we use
                                                                      your
                                                                      personal
                                                                      data
                                                                    </a>
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <a
                                                                      data-custom-className="link"
                                                                      href="https://app.termly.io/document/privacy-policy/d237bcd6-1d78-41b2-9869-2515b1b3953c#whoshare"
                                                                      style={{
                                                                        backgroundImage:
                                                                          "initial !important",
                                                                        backgroundPosition:
                                                                          "initial !important",
                                                                        backgroundSize:
                                                                          "initial !important",
                                                                        backgroundRepeat:
                                                                          "initial !important",
                                                                        backgroundAttachment:
                                                                          "initial !important",
                                                                        backgroundOrigin:
                                                                          "initial !important",
                                                                        backgroundClip:
                                                                          "initial !important",
                                                                        color:
                                                                          "rgb(48, 48, 241) !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                        wordBreak:
                                                                          "break-word !important",
                                                                      }}
                                                                    >
                                                                      When and
                                                                      with whom
                                                                      we share
                                                                      your
                                                                      personal
                                                                      data
                                                                    </a>
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <u
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "14px",
                                                                        fontFamily:
                                                                          "Arial",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      Your
                                                                      rights
                                                                      with
                                                                      respect to
                                                                      your
                                                                      personal
                                                                      data
                                                                    </span>
                                                                  </u>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "14px",
                                                                        fontFamily:
                                                                          "Arial",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      Right to
                                                                      be
                                                                      informed
                                                                      whether or
                                                                      not we are
                                                                      processing
                                                                      your
                                                                      personal
                                                                      data
                                                                    </span>
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "14px",
                                                                        fontFamily:
                                                                          "Arial",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      Right to
                                                                      access
                                                                      your
                                                                      personal
                                                                      data
                                                                    </span>
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "14px",
                                                                        fontFamily:
                                                                          "Arial",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      Right to
                                                                      correct
                                                                      inaccuracies
                                                                      in your
                                                                      personal
                                                                      data
                                                                    </span>
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "14px",
                                                                        fontFamily:
                                                                          "Arial",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      Right to
                                                                      request
                                                                      deletion
                                                                      of your
                                                                      personal
                                                                      data
                                                                    </span>
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "14px",
                                                                        fontFamily:
                                                                          "Arial",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      Right to
                                                                      obtain a
                                                                      copy of
                                                                      the
                                                                      personal
                                                                      data you
                                                                      previously
                                                                      shared
                                                                      with us
                                                                    </span>
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </div>
                                                              <ul
                                                                style={{
                                                                  listStyleType:
                                                                    "square",
                                                                  background:
                                                                    "transparent !important",
                                                                }}
                                                              >
                                                                <li
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "14px",
                                                                        fontFamily:
                                                                          "Arial",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      Right to
                                                                      opt out of
                                                                      the
                                                                      processing
                                                                      of your
                                                                      personal
                                                                      data if it
                                                                      is used
                                                                      for
                                                                      targeted
                                                                      advertising,
                                                                      the sale
                                                                      of
                                                                      personal
                                                                      data, or
                                                                      profiling
                                                                      in
                                                                      furtherance
                                                                      of
                                                                      decisions
                                                                      that
                                                                      produce
                                                                      legal or
                                                                      similarly
                                                                      significant
                                                                      effects (
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      "profiling"
                                                                      <bdt
                                                                        className="statement-end-if-in-editor"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      )
                                                                    </span>
                                                                  </span>
                                                                  <bdt
                                                                    className="statement-end-if-in-editor"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></span>
                                                                  </bdt>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                data-custom-className="body_text"
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  color:
                                                                    "rgb(89, 89, 89) !important",
                                                                  fontSize:
                                                                    "14px !important",
                                                                  fontFamily:
                                                                    "Arial !important",
                                                                  lineHeight:
                                                                    "1.5",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent !important",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px",
                                                                    }}
                                                                  ></bdt>
                                                                  <bdt
                                                                    className="question"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px",
                                                                    }}
                                                                  >
                                                                    The Ojunta
                                                                    Group LLC
                                                                  </bdt>
                                                                  &nbsp;has not
                                                                  sold any
                                                                  personal data
                                                                  to third
                                                                  parties for
                                                                  business or
                                                                  commercial
                                                                  purposes.&nbsp;
                                                                  <bdt
                                                                    className="question"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px",
                                                                    }}
                                                                  >
                                                                    The Ojunta
                                                                    Group LLC
                                                                  </bdt>
                                                                  &nbsp;will not
                                                                  sell personal
                                                                  data in the
                                                                  future
                                                                  belonging to
                                                                  website
                                                                  visitors,
                                                                  users, and
                                                                  other
                                                                  consumers.
                                                                  <bdt
                                                                    className="else-block"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <u
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      color:
                                                                        "rgb(89, 89, 89) !important",
                                                                      fontSize:
                                                                        "15px",
                                                                      fontFamily:
                                                                        "Arial !important",
                                                                    }}
                                                                  >
                                                                    Exercise
                                                                    your rights
                                                                    provided
                                                                    under the
                                                                    Virginia
                                                                    CDPA
                                                                  </span>
                                                                </u>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(89, 89, 89) !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    fontFamily:
                                                                      "Arial !important",
                                                                  }}
                                                                >
                                                                  More
                                                                  information
                                                                  about our data
                                                                  collection and
                                                                  sharing
                                                                  practices can
                                                                  be found in
                                                                  this privacy
                                                                  notice
                                                                  <bdt
                                                                    className="block-component"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px",
                                                                    }}
                                                                  ></bdt>
                                                                  .
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <bdt
                                                                  className="block-component"
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                ></bdt>
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    You may
                                                                    contact us
                                                                    by email
                                                                    at&nbsp;
                                                                    <bdt
                                                                      className="question"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      privacy@feevaa.com
                                                                    </bdt>
                                                                    ,&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    by
                                                                    visiting&nbsp;
                                                                    <bdt
                                                                      className="question"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        href="https://feevaa.com/en/contact"
                                                                        target="_blank"
                                                                        data-custom-className="link"
                                                                        style={{
                                                                          backgroundImage:
                                                                            "initial !important",
                                                                          backgroundPosition:
                                                                            "initial !important",
                                                                          backgroundSize:
                                                                            "initial !important",
                                                                          backgroundRepeat:
                                                                            "initial !important",
                                                                          backgroundAttachment:
                                                                            "initial !important",
                                                                          backgroundOrigin:
                                                                            "initial !important",
                                                                          backgroundClip:
                                                                            "initial !important",
                                                                          color:
                                                                            "rgb(48, 48, 241) !important",
                                                                          wordBreak:
                                                                            "break-word !important",
                                                                        }}
                                                                      >
                                                                        https://feevaa.com/en/contact
                                                                      </a>
                                                                    </bdt>
                                                                    <bdt
                                                                      className="else-block"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    , or by
                                                                    referring to
                                                                    the contact
                                                                    details at
                                                                    the bottom
                                                                    of this
                                                                    document.
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    If you are
                                                                    using
                                                                    an&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    authorized
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;agent
                                                                    to exercise
                                                                    your rights,
                                                                    we may deny
                                                                    a request if
                                                                    the&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    authorized
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;agent
                                                                    does not
                                                                    submit proof
                                                                    that they
                                                                    have been
                                                                    validly&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    authorized
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;to act
                                                                    on your
                                                                    behalf.
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <u
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "14px",
                                                                        fontFamily:
                                                                          "Arial",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      Verification
                                                                      process
                                                                    </span>
                                                                  </span>
                                                                </u>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    We may
                                                                    request that
                                                                    you provide
                                                                    additional
                                                                    information
                                                                    reasonably
                                                                    necessary to
                                                                    verify you
                                                                    and your
                                                                    consumer's
                                                                    request. If
                                                                    you submit
                                                                    the request
                                                                    through
                                                                    an&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    authorized
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    &nbsp;agent,
                                                                    we may need
                                                                    to collect
                                                                    additional
                                                                    information
                                                                    to verify
                                                                    your
                                                                    identity
                                                                    before
                                                                    processing
                                                                    your
                                                                    request.
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    Upon
                                                                    receiving
                                                                    your
                                                                    request, we
                                                                    will respond
                                                                    without
                                                                    undue delay,
                                                                    but in all
                                                                    cases,
                                                                    within
                                                                    forty-five
                                                                    (45) days of
                                                                    receipt. The
                                                                    response
                                                                    period may
                                                                    be extended
                                                                    once by
                                                                    forty-five
                                                                    (45)
                                                                    additional
                                                                    days when
                                                                    reasonably
                                                                    necessary.
                                                                    We will
                                                                    inform you
                                                                    of any such
                                                                    extension
                                                                    within the
                                                                    initial
                                                                    45-day
                                                                    response
                                                                    period,
                                                                    together
                                                                    with the
                                                                    reason for
                                                                    the
                                                                    extension.
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <u
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "14px",
                                                                        fontFamily:
                                                                          "Arial",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      Right to
                                                                      appeal
                                                                    </span>
                                                                  </u>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <br
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  />
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    If we
                                                                    decline to
                                                                    take action
                                                                    regarding
                                                                    your
                                                                    request, we
                                                                    will inform
                                                                    you of our
                                                                    decision and
                                                                    reasoning
                                                                    behind
                                                                    it.&nbsp;
                                                                  </span>
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    If you wish
                                                                    to appeal
                                                                    our
                                                                    decision,
                                                                    please email
                                                                    us at&nbsp;
                                                                    <bdt
                                                                      className="block-component"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    <bdt
                                                                      className="question"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      __________
                                                                    </bdt>
                                                                    <bdt
                                                                      className="statement-end-if-in-editor"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></bdt>
                                                                    . Within
                                                                    sixty (60)
                                                                    days of
                                                                    receipt of
                                                                    an appeal,
                                                                    we will
                                                                    inform you
                                                                    in writing
                                                                    of any
                                                                    action taken
                                                                    or not taken
                                                                    in response
                                                                    to the
                                                                    appeal,
                                                                    including a
                                                                    written
                                                                    explanation
                                                                    of the
                                                                    reasons for
                                                                    the
                                                                    decisions.
                                                                    If your
                                                                    appeal if
                                                                    denied, you
                                                                    may contact
                                                                    the Attorney
                                                                    General
                                                                    to&nbsp;
                                                                  </span>
                                                                </span>
                                                                <a
                                                                  href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
                                                                  rel="noopener noreferrer"
                                                                  target="_blank"
                                                                  style={{
                                                                    backgroundImage:
                                                                      "initial !important",
                                                                    backgroundPosition:
                                                                      "initial !important",
                                                                    backgroundSize:
                                                                      "initial !important",
                                                                    backgroundRepeat:
                                                                      "initial !important",
                                                                    backgroundAttachment:
                                                                      "initial !important",
                                                                    backgroundOrigin:
                                                                      "initial !important",
                                                                    backgroundClip:
                                                                      "initial !important",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="link"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        color:
                                                                          "rgb(48, 48, 241) !important",
                                                                        fontSize:
                                                                          "14px",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                        wordBreak:
                                                                          "break-word !important",
                                                                      }}
                                                                    >
                                                                      submit a
                                                                      complaint
                                                                    </span>
                                                                  </span>
                                                                </a>
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    .
                                                                  </span>
                                                                  <bdt
                                                                    className="statement-end-if-in-editor"
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  ></bdt>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                id="policyupdates"
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(127, 127, 127)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                          }}
                                                                        >
                                                                          <strong
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="heading_1"
                                                                              style={{
                                                                                fontSize:
                                                                                  "19px",
                                                                                fontFamily:
                                                                                  "Arial",
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              14.
                                                                              DO
                                                                              WE
                                                                              MAKE
                                                                              UPDATES
                                                                              TO
                                                                              THIS
                                                                              NOTICE?
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <em
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <strong
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          In
                                                                          Short:&nbsp;
                                                                        </strong>
                                                                        Yes, we
                                                                        will
                                                                        update
                                                                        this
                                                                        notice
                                                                        as
                                                                        necessary
                                                                        to stay
                                                                        compliant
                                                                        with
                                                                        relevant
                                                                        laws.
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      We may
                                                                      update
                                                                      this
                                                                      privacy
                                                                      notice
                                                                      from time
                                                                      to time.
                                                                      The
                                                                      updated
                                                                      version
                                                                      will be
                                                                      indicated
                                                                      by an
                                                                      updated&nbsp;
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      "Revised"
                                                                      <bdt
                                                                        className="statement-end-if-in-editor"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      &nbsp;date
                                                                      and the
                                                                      updated
                                                                      version
                                                                      will be
                                                                      effective
                                                                      as soon as
                                                                      it is
                                                                      accessible.
                                                                      If we make
                                                                      material
                                                                      changes to
                                                                      this
                                                                      privacy
                                                                      notice, we
                                                                      may notify
                                                                      you either
                                                                      by
                                                                      prominently
                                                                      posting a
                                                                      notice of
                                                                      such
                                                                      changes or
                                                                      by
                                                                      directly
                                                                      sending
                                                                      you a
                                                                      notification.
                                                                      We
                                                                      encourage
                                                                      you to
                                                                      review
                                                                      this
                                                                      privacy
                                                                      notice
                                                                      frequently
                                                                      to be
                                                                      informed
                                                                      of how we
                                                                      are
                                                                      protecting
                                                                      your
                                                                      information.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                id="contact"
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(127, 127, 127)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                          }}
                                                                        >
                                                                          <strong
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="heading_1"
                                                                              style={{
                                                                                fontSize:
                                                                                  "19px",
                                                                                fontFamily:
                                                                                  "Arial",
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              15.
                                                                              HOW
                                                                              CAN
                                                                              YOU
                                                                              CONTACT
                                                                              US
                                                                              ABOUT
                                                                              THIS
                                                                              NOTICE?
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      If you
                                                                      have
                                                                      questions
                                                                      or
                                                                      comments
                                                                      about this
                                                                      notice,
                                                                      you
                                                                      may&nbsp;
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                          background:
                                                                            "transparent !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        >
                                                                          <bdt
                                                                            className="block-component"
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          ></bdt>
                                                                          email
                                                                          us
                                                                          at&nbsp;
                                                                          <bdt
                                                                            className="question"
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            privacy@feevaa.com
                                                                          </bdt>
                                                                          <bdt
                                                                            className="statement-end-if-in-editor"
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          ></bdt>
                                                                        </span>
                                                                      </span>
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                          background:
                                                                            "transparent !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px",
                                                                            }}
                                                                          >
                                                                            &nbsp;or
                                                                            by
                                                                            post
                                                                            to:
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                          background:
                                                                            "transparent !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="body_text"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              <bdt
                                                                                className="question"
                                                                                style={{
                                                                                  background:
                                                                                    "transparent",
                                                                                }}
                                                                              >
                                                                                The
                                                                                Ojunta
                                                                                Group
                                                                                LLC
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          data-custom-className="body_text"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <bdt
                                                                            className="block-component"
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          ></bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <bdt
                                                                      className="question"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      2730
                                                                      Peachtree
                                                                      Industrial
                                                                      Blvd, Ste
                                                                      105
                                                                      Duluth, GA
                                                                      30097, USA
                                                                    </bdt>
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                          background:
                                                                            "transparent !important",
                                                                        }}
                                                                      >
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        ></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-className="body_text"
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontFamily:
                                                                        "Arial",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <bdt
                                                                      className="question"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      Duluth
                                                                    </bdt>
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                          background:
                                                                            "transparent !important",
                                                                        }}
                                                                      >
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        ></bdt>
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        ></bdt>
                                                                        ,&nbsp;
                                                                        <bdt
                                                                          className="question"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        >
                                                                          GA
                                                                        </bdt>
                                                                        <bdt
                                                                          className="statement-end-if-in-editor"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        ></bdt>
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        ></bdt>
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        ></bdt>
                                                                        &nbsp;
                                                                        <bdt
                                                                          className="question"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        >
                                                                          30097
                                                                        </bdt>
                                                                        <bdt
                                                                          className="statement-end-if-in-editor"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        ></bdt>
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        ></bdt>
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        ></bdt>
                                                                        <bdt
                                                                          className="block-component"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        ></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(89, 89, 89) !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    fontFamily:
                                                                      "Arial !important",
                                                                  }}
                                                                >
                                                                  <bdt
                                                                    className="question"
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      fontSize:
                                                                        "14px",
                                                                    }}
                                                                  >
                                                                    United
                                                                    States
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                            background:
                                                                              "transparent !important",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize:
                                                                                    "15px",
                                                                                  background:
                                                                                    "transparent !important",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  data-custom-className="body_text"
                                                                                  style={{
                                                                                    background:
                                                                                      "transparent !important",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize:
                                                                                          "15px",
                                                                                        background:
                                                                                          "transparent !important",
                                                                                      }}
                                                                                    >
                                                                                      <bdt
                                                                                        className="statement-end-if-in-editor"
                                                                                        style={{
                                                                                          background:
                                                                                            "transparent !important",
                                                                                          fontSize:
                                                                                            "14px",
                                                                                        }}
                                                                                      ></bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </bdt>
                                                                </span>
                                                                <bdt
                                                                  className="block-component"
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "14px",
                                                                        fontFamily:
                                                                          "Arial",
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    ></span>
                                                                  </span>
                                                                </bdt>
                                                                <bdt
                                                                  className="statement-end-if-in-editor"
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                ></bdt>
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        background:
                                                                          "transparent !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="statement-end-if-in-editor"
                                                                        style={{
                                                                          background:
                                                                            "transparent !important",
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  data-custom-className="body_text"
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "14px",
                                                                    fontFamily:
                                                                      "Arial",
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent !important",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <bdt
                                                                          className="statement-end-if-in-editor"
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                                background:
                                                                                  "transparent !important",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                data-custom-className="body_text"
                                                                                style={{
                                                                                  background:
                                                                                    "transparent !important",
                                                                                  fontSize:
                                                                                    "14px",
                                                                                }}
                                                                              >
                                                                                <bdt
                                                                                  className="block-component"
                                                                                  style={{
                                                                                    background:
                                                                                      "transparent",
                                                                                  }}
                                                                                >
                                                                                  <bdt
                                                                                    className="block-component"
                                                                                    style={{
                                                                                      background:
                                                                                        "transparent",
                                                                                    }}
                                                                                  ></bdt>
                                                                                </bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      background:
                                                                        "transparent !important",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                          background:
                                                                            "transparent !important",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            background:
                                                                              "transparent",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            data-custom-className="body_text"
                                                                            style={{
                                                                              background:
                                                                                "transparent !important",
                                                                              fontSize:
                                                                                "14px",
                                                                            }}
                                                                          >
                                                                            <bdt
                                                                              className="statement-end-if-in-editor"
                                                                              style={{
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              <bdt
                                                                                className="block-component"
                                                                                style={{
                                                                                  background:
                                                                                    "transparent",
                                                                                }}
                                                                              ></bdt>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                id="request"
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    color:
                                                                      "rgb(127, 127, 127)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent !important",
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        background:
                                                                          "transparent",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            background:
                                                                              "transparent !important",
                                                                            color:
                                                                              "rgb(0, 0, 0)",
                                                                          }}
                                                                        >
                                                                          <strong
                                                                            style={{
                                                                              background:
                                                                                "transparent",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-className="heading_1"
                                                                              style={{
                                                                                fontSize:
                                                                                  "19px",
                                                                                fontFamily:
                                                                                  "Arial",
                                                                                background:
                                                                                  "transparent",
                                                                              }}
                                                                            >
                                                                              16.
                                                                              HOW
                                                                              CAN
                                                                              YOU
                                                                              REVIEW,
                                                                              UPDATE,
                                                                              OR
                                                                              DELETE
                                                                              THE
                                                                              DATA
                                                                              WE
                                                                              COLLECT
                                                                              FROM
                                                                              YOU?
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <br
                                                                  style={{
                                                                    background:
                                                                      "transparent",
                                                                  }}
                                                                />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  background:
                                                                    "transparent !important",
                                                                  fontSize:
                                                                    "15px",
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      Based on
                                                                      the
                                                                      applicable
                                                                      laws of
                                                                      your
                                                                      country,
                                                                      you may
                                                                      have the
                                                                      right to
                                                                      request
                                                                      access to
                                                                      the
                                                                      personal
                                                                      information
                                                                      we collect
                                                                      from you,
                                                                      change
                                                                      that
                                                                      information,
                                                                      or delete
                                                                      it.&nbsp;
                                                                      <bdt
                                                                        className="else-block"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                      To request
                                                                      to review,
                                                                      update, or
                                                                      delete
                                                                      your
                                                                      personal
                                                                      information,
                                                                      please&nbsp;
                                                                      <bdt
                                                                        className="block-component"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      ></bdt>
                                                                    </span>
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      visit:{" "}
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <a
                                                                  href="https://feevaa.com/en/contact"
                                                                  target="_blank"
                                                                >
                                                                  https://feevaa.com/en/contact
                                                                </a>
                                                                <span
                                                                  style={{
                                                                    background:
                                                                      "transparent !important",
                                                                    fontSize:
                                                                      "15px",
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      background:
                                                                        "transparent",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-className="body_text"
                                                                      style={{
                                                                        background:
                                                                          "transparent !important",
                                                                        fontSize:
                                                                          "14px !important",
                                                                        fontFamily:
                                                                          "Arial !important",
                                                                      }}
                                                                    >
                                                                      <bdt
                                                                        className="question"
                                                                        style={{
                                                                          background:
                                                                            "transparent",
                                                                        }}
                                                                      >
                                                                        <a
                                                                          href="https://feevaa.com/en/contact"
                                                                          target="_blank"
                                                                          data-custom-className="link"
                                                                          style={{
                                                                            backgroundImage:
                                                                              "initial !important",
                                                                            backgroundPosition:
                                                                              "initial !important",
                                                                            backgroundSize:
                                                                              "initial !important",
                                                                            backgroundRepeat:
                                                                              "initial !important",
                                                                            backgroundAttachment:
                                                                              "initial !important",
                                                                            backgroundOrigin:
                                                                              "initial !important",
                                                                            backgroundClip:
                                                                              "initial !important",
                                                                            color:
                                                                              "rgb(48, 48, 241) !important",
                                                                            wordBreak:
                                                                              "break-word !important",
                                                                          }}
                                                                        ></a>
                                                                        &nbsp;
                                                                        or send
                                                                        us an
                                                                        email to
                                                                        privacy@feevaa.com&nbsp;
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
