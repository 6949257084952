import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const api = process.env.REACT_APP_SERVER_API;

export const fetchAllData = createAsyncThunk(
  "all-data/fetchData",
  async ({ getData, state }) => {
    const res = await axios.get(`${api}/${getData}`);

    if (
      state === "blog_setting" ||
      state === "countries" ||
      state === "event_setting" ||
      state === "home_page_setting" ||
      state === "languages" ||
      state === "layout_settings" ||
      state === "venue_page_settings"
    ) {
      return { data: res.data.data, state };
    } else {
      const sortData = [...res.data.data].sort((a, b) => b.id - a.id);
      return { data: sortData, state };
    }
  }
);

const initialState = {
  events: [],
  blogs: [],
  blog_categories: [],
  categories: [],
  venues: [],
  countries: [],
  languages: [],
  event_setting: {},
  home_page_setting: {},
  layout_settings: {},
  venue_page_settings: {},
  blog_setting: {},
  articles: [],
  article_categories: [],
  organizers: [],
  audiences: [],
  loading: false,
  error: false,
};
const AllDataSlice = createSlice({
  name: "all-data",
  initialState,
  reducers: {
    setData: (state, action) => {
      const { data, state: dataState } = action.payload;
      state[dataState] = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchAllData.fulfilled, (state, action) => {
        const { data, state: dataState } = action.payload;
        state[dataState] = data;
        state.loading = false;
      })
      .addCase(fetchAllData.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export const { setData } = AllDataSlice.actions;
export default AllDataSlice.reducer;
