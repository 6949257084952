import React from "react";
import { Layout } from "../../components/LayoutUser/Layout";
import Cart from "../../components/Admin/dashboard/attendee/cart/Cart";

const NoLoginCart = () => {
  return (
    <Layout>
      <div className="container attendeeSidebar">
        <div className="row">
          <aside className="col-lg-3 pt-3 pt-lg-0">
            {/* <OrganizerSidebar /> */}
          </aside>
          <div className="col-lg-9 mt-4 mt-lg-0">
            <Cart />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NoLoginCart;
