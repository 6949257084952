import { useSignUp } from "../../../utils/CustomFunctions";
import { FaSyncAlt } from "react-icons/fa";

const ChangePassword = () => {
  const initialState = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    if (!values.current_password.trim())
      newErrors.current_password = "This value should not be null.";
    if (!values.new_password.trim())
      newErrors.new_password = "This value should not be null.";
    if (values.new_password.trim() && values.new_password.trim().length < 6)
      newErrors.new_password = "The password must be at least 6 characters";
    if (values.new_password.trim() !== values.confirm_password.trim())
      newErrors.confirm_password = "Passwords do not match";
    return newErrors;
  };

  // use custom hook for cahnge password
  const { values, errors, isPending, handleChange, handleSubmit } = useSignUp(
    initialState,
    validateFields,
    `change_password`
  );

  return (
    <div className="card setting">
      <div className="card-body">
        <form className="signform" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="current_password" className="form-label">
              Current password<span>*</span>
            </label>
            <input
              type="password"
              name="current_password"
              id="current_password"
              className={`form-control ${
                errors.current_password && "is-invalid"
              }`}
              value={values.current_password}
              onChange={handleChange}
            />
            {errors.current_password && (
              <small className="text-danger d-block mt-1">
                {errors.current_password}
              </small>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="new_password" className="form-label">
              New password<span>*</span>
            </label>
            <input
              type="password"
              name="new_password"
              id="new_password"
              className={`form-control ${errors.new_password && "is-invalid"}`}
              value={values.new_password}
              onChange={handleChange}
            />
            {errors.new_password && (
              <small className="text-danger d-block mt-1">
                {errors.new_password}
              </small>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="confirm_password" className="form-label">
              Repeat New password<span>*</span>
            </label>
            <input
              type="password"
              name="confirm_password"
              id="confirm_password"
              className={`form-control ${
                errors.confirm_password && "is-invalid"
              }`}
              value={values.confirm_password}
              onChange={handleChange}
            />
            {errors.confirm_password && (
              <small className="text-danger d-block mt-1">
                {errors.confirm_password}
              </small>
            )}
          </div>

          <div className="form-group mt-3 mb-3">
            <button
              className="btn btn-dark w-100 rounded-5"
              disabled={isPending ? "disabled" : ""}
              type="submit"
            >
              {isPending ? (
                <FaSyncAlt className="spinIcon" />
              ) : (
                "Change Password"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
