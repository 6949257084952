import React from "react";
import Review from "./Review";
import { formatDate } from "../../utils/CustomFunctions";

const UserReviews = ({ review }) => {
  const date = new Date(review.date);
  const { fullDate } = formatDate(date);
  return (
    <div className="mb-5">
      <Review rating={review.rating} />
      <small className="text-muted mt-1">{fullDate}</small>
      <h6 className="mt-2 fw-bold">{review.headline}</h6>
      <p
        className="mt-2 text-sm readmore rmjs-2"
        data-collapsed-height="200"
        data-height-margin="20"
      >
        {review.details}
      </p>
    </div>
  );
};

export default UserReviews;
