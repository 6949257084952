import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import { Tab, Tabs } from "react-bootstrap";
import Count from "../../../../count/Count";
import SelectBox from "../../../../global/SelectBox";
import { FaSyncAlt } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { useAddEntity } from "../../../../../utils/CustomFunctions";
import { useSelector } from "react-redux";
import { FaCircleInfo } from "react-icons/fa6";
import AddInputTags from "../../organizer/AddTags/AddInputTags";

const AddBlogPost = () => {
  const { id } = useParams();
  const { slug } = useParams();
  const server = process.env.REACT_APP_SERVER;
  const [key, setKey] = useState("en");
  const { blog_categories } = useSelector((state) => state.admin);
  const [BolgCategories, setBolgCategories] = useState([]);
  const initialState = {
    name: "",
    description: "",
    tags: [],
    time: "",
    blog_category_id: "",
    image: "",
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  // Editor options
  const modules = {
    toolbar: toolbarOptions,
  };

  const validateFields = (values) => {
    let newErrors = {};

    if (!values.blog_category_id)
      newErrors.blog_category_id = "This value should not be null.";
    if (!values.name.trim()) newErrors.name = "This value should not be null.";
    if (!values.description)
      newErrors.description = "This value should not be null.";
    if (!values?.tags?.length || !values?.tags?.length > 0)
      newErrors.tags = "This value should not be null.";
    if (!values.image) newErrors.image = "This value should not be null.";

    return newErrors;
  };

  const {
    isPending,
    errors,
    newEntity,
    setNewEntity,
    handleChange,
    handleSelectBox,
    handleIamgesChange,
    handleEditor,
    handleSubmit,
  } = useAddEntity(
    "admin",
    "blog",
    "blogs",
    initialState,
    validateFields,
    "/en/dashboard/administrator/manage-blog-posts"
  );

  useEffect(() => {
    setBolgCategories(
      blog_categories?.filter((curElem) => curElem.status === "visible")
    );
  }, [blog_categories]);

  return (
    <>
      <div className="card box">
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <div className="signform">
              <div className="ProfileSettingOptionsContainer">
                <div className="mb-4 mt-3">
                  <label
                    htmlFor="blog_category_id"
                    className="form-label fw-600"
                  >
                    Category<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="OrganizerOptionDescBox">
                    <FaCircleInfo className="OrganizerOptionDescIcon" />
                    <span className="OrganizerOptionDesc">
                      Make sure to select right category to let the users find
                      it quickly
                    </span>
                  </div>
                  <SelectBox
                    name="blog_category_id"
                    options={BolgCategories}
                    val={newEntity.blog_category_id}
                    change={handleSelectBox}
                  />
                  {errors.blog_category_id && (
                    <small className="text-danger d-block mt-1">
                      {errors.blog_category_id}
                    </small>
                  )}
                </div>

                <div className="">
                  <label htmlFor="" className="form-label fw-600">
                    Translation<span style={{ color: "red" }}>*</span>
                  </label>
                  <div>
                    <Tabs
                      id="lang-tabs"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                      className=""
                    >
                      <Tab eventKey="en" title="En (Default)" />
                      <Tab eventKey="fr" title="Fr" />
                      <Tab eventKey="es" title="Es" />
                      <Tab eventKey="ar" title="Ar" />
                      <Tab eventKey="de" title="De" />
                      <Tab eventKey="pt" title="Pt" />
                    </Tabs>
                    <div className="name-section mb-3">
                      <label htmlFor="name" className="form-label fw-600">
                        Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        onChange={handleChange}
                        value={newEntity.name}
                      />
                      {errors.name && (
                        <small className="text-danger d-block mt-1">
                          {errors.name}
                        </small>
                      )}
                    </div>
                    <div className="editor-section ">
                      <label className="form-label fw-600">
                        Description<span style={{ color: "red" }}>*</span>
                      </label>
                      <ReactQuill
                        value={newEntity.description}
                        onChange={handleEditor}
                        modules={modules}
                        // style={{ height: "300px", clear: "both" }}
                      />
                    </div>
                  </div>
                </div>
                {errors.description && (
                  <small className="text-danger d-block mt-1">
                    {errors.description}
                  </small>
                )}

                <div className="mb-4">
                  <div className="mb-1">
                    <label htmlFor="tags" className="form-label fw-600 m-0">
                      Tags<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="OrganizerOptionDescBox">
                      <FaCircleInfo className="OrganizerOptionDescIcon" />
                      <span className="OrganizerOptionDesc">
                        To help attendee find your event quickly, enter some
                        keywords that identify your event (press Enter after
                        each entry)
                      </span>
                    </div>
                    <AddInputTags
                      state={newEntity}
                      setSate={setNewEntity}
                      name="tags"
                    />
                    {errors.tags && (
                      <small className="text-danger d-block mt-1">
                        {errors.tags}
                      </small>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="image" className="form-label fw-600">
                    Main blog post image
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    className="form-control mb-2"
                    onChange={handleIamgesChange}
                  />
                  {(id || slug) && newEntity?.image?.name && (
                    <Link
                      className="fancybox"
                      to={URL.createObjectURL(newEntity?.image)}
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Enlarge"
                      target="_blank"
                    >
                      <img
                        src={URL.createObjectURL(newEntity?.image)}
                        className="img-thumbnail re_ent_img mt-2"
                        alt={newEntity?.image.path}
                      />
                    </Link>
                  )}

                  {(id || slug) &&
                    newEntity.image &&
                    !newEntity?.image?.name && (
                      <Link
                        className="fancybox"
                        to={`${server}/blog/${newEntity.image}`}
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Enlarge"
                        target="_blank"
                      >
                        <img
                          src={`${server}/blog/${newEntity.image}`}
                          className="img-thumbnail re_ent_img"
                          alt={newEntity.image}
                        />
                      </Link>
                    )}

                  {errors.image && (
                    <small className="text-danger d-block mt-1">
                      {errors.image}
                    </small>
                  )}
                </div>

                <div className="mb-4">
                  <label htmlFor="time" className="form-label fw-600">
                    Read time in minutes
                  </label>
                  <Count
                    name="time"
                    value={newEntity.time}
                    state="newEntity"
                    setState={setNewEntity}
                  />
                </div>
                <div className="form-group mb-3">
                  <button
                    className="btn btn-dark rounded-5"
                    type="button"
                    disabled={isPending ? "disabled" : ""}
                    onClick={handleSubmit}
                  >
                    {isPending ? <FaSyncAlt className="spinIcon" /> : "SAVE"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBlogPost;
