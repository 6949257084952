import { Modal } from "antd";
import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import { adminFetchData } from "../../../../../store/slice/Admin";
import { useDispatch, useSelector } from "react-redux";

const CancelModel = ({ openModel, setOpenModel, order, setorder }) => {
  const dispatch = useDispatch();
  const api = process.env.REACT_APP_SERVER_API;
  const { user } = useSelector((state) => state.authUser);

  const handleSubmit = async () => {
    // e.preventDefault();
    try {
      const { data } = await axios.post(`${api}/admin/order_cancel`, order, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (data.status === 200 || data.status === 201) {
        toast.success(data.message);
        setOpenModel(false);
        dispatch(
          adminFetchData({
            endPoint: "admin/orders",
            state: "orders",
            token: user.token,
          })
        );
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={openModel}
        onCancel={() => setOpenModel(false)}
        className="Model"
      >
        <div className="ModelBox">
          <div className="ModelHeader">
            <span className="ModelBoxBuyTicketText">Confirmation required</span>
          </div>
          <div className="jconfirm-content" id="jconfirm-box27525">
            <div>
              You are about to cancel this order (this action cannot be undone)
              <textarea
                id="reason"
                rows="5"
                name="reason"
                value={order.reason}
                onChange={(e) => setorder({ ...order, reason: e.target.value })}
                className="form-control mt-3"
                placeholder="Note"
              ></textarea>
            </div>
          </div>
        </div>

        <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
          <button
            className="btn btn-outline-dark btn-sm rounded-5 d-inline-flex align-items-center gap-2"
            onClick={handleSubmit}
          >
            Confirm
          </button>
          <button
            className="btn btn-outline-secondary btn-sm rounded-5 d-inline-flex align-items-center gap-2"
            onClick={() => setOpenModel(false)}
          >
            close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CancelModel;
