import React from "react";

const Tabs = ({ tab, name, val, setVal }) => {
  return (
    <div
      className="nav nav-pills"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      {tab.map((tab, i) => {
        return (
          <button
            key={i}
            className={`nav-link ${tab.title === val[name] && "active"}`}
            id={`${tab.id}_tab`}
            data-bs-toggle="pill"
            data-bs-target={`#${tab.id}`}
            type="button"
            role="tab"
            aria-controls={`${tab.id}`}
            aria-selected={tab.title === val[name]}
            onClick={() => setVal({ ...val, [name]: tab.title })}
          >
            {tab.title}
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;
