import { FaCircleInfo, FaPaperPlane } from "react-icons/fa6";
import "../layout/style.scss";
import { useState } from "react";
import MailModel from "./MailModel";
import { Radio } from "antd";
import { useSelector } from "react-redux";
import { useSignUp } from "../../../../../../utils/CustomFunctions";
import { FaSyncAlt } from "react-icons/fa";

const MailServer = () => {
  const [openModel, setOpenModel] = useState(false);
  const handleMailOpen = (e) => {
    e.preventDefault();
    setOpenModel(true);
  };

  const { mail_server } = useSelector((state) => state.admin);

  const initialState = {
    transport: mail_server?.transport || "gmail",
    port: mail_server?.port || "",
    host: mail_server?.host || "",
    encryption: mail_server?.encryption || "ssl",
    email: mail_server?.email || "",
    contact_email: mail_server?.contact_email || "",
    password: mail_server?.password || "",
    username: mail_server?.username || "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    if (!values.host.trim()) newErrors.host = "This value should not be null.";
    if (!values.email.trim())
      newErrors.email = "This value should not be null.";
    if (!values.contact_email.trim())
      newErrors.contact_email = "This value should not be null.";

    return newErrors;
  };

  const { values, errors, isPending, handleChange, handleSubmit } = useSignUp(
    initialState,
    validateFields,
    `admin/add_email_server_settings`,
    "mail_server"
  );
  return (
    <>
      <MailModel openModel={openModel} setOpenModel={setOpenModel} />
      <div className="card box">
        <div className="card-header text-end bg-transparent border-0">
          <div className="form-group mt-4 mb-3">
            <button className="btn btn-dark rounded-5" onClick={handleMailOpen}>
              <FaPaperPlane className="me-1" />
              Test the mail server configuration
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form onSubmit={handleSubmit} className="signform ">
              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Transport
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="transport"
                      checked={values.transport === "smtp"}
                      onChange={handleChange}
                      value="smtp"
                    >
                      SMTP
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn "
                      name="transport"
                      checked={values.transport === "gmail"}
                      onChange={handleChange}
                      value="gmail"
                    >
                      Gmail
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn "
                      name="transport"
                      checked={values.transport === "sendmail"}
                      onChange={handleChange}
                      value="sendmail"
                    >
                      Sendmail
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="host" className="form-label fw-600">
                  Host<span style={{ color: "red" }}>*</span>
                </label>

                <input
                  type="text"
                  name="host"
                  id="host"
                  className="form-control"
                  autoComplete="host"
                  value={values.host}
                  onChange={handleChange}
                />
                {errors.host && (
                  <small className="text-danger d-block mt-1">
                    {errors.host}
                  </small>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="port" className="form-label fw-600">
                  Port
                </label>
                <input
                  type="text"
                  name="port"
                  id="port"
                  className="form-control"
                  value={values.port}
                  onChange={handleChange}
                />
              </div>

              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Encryption
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="encryption"
                      checked={values.encryption === "none"}
                      onChange={handleChange}
                      value="none"
                    >
                      None
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="encryption"
                      checked={values.encryption === "ssl"}
                      onChange={handleChange}
                      value="ssl"
                    >
                      SSL
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="encryption"
                      checked={values.encryption === "tls"}
                      onChange={handleChange}
                      value="tls"
                    >
                      TLS
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="username" className="form-label fw-600">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  className="form-control"
                  value={values.username}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label fw-600">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control"
                  value={values.password}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="no_reply_email" className="form-label fw-600">
                  No reply email address<span style={{ color: "red" }}>*</span>
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    This email address will be used as the sender of all the
                    emails sent by the platform, in almost all cases, it is the
                    same as the username above
                  </span>
                </div>
                <input
                  type="email"
                  name="email"
                  id="no_reply_email"
                  className="form-control"
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <small className="text-danger d-block mt-1">
                    {errors.email}
                  </small>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="contact_email" className="form-label fw-600">
                  Contact email<span style={{ color: "red" }}>*</span>
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    This email address will receive the contact form messages
                  </span>
                </div>
                <input
                  type="email"
                  name="contact_email"
                  id="contact_email"
                  className="form-control"
                  value={values.contact_email}
                  onChange={handleChange}
                />
                {errors.contact_email && (
                  <small className="text-danger d-block mt-1">
                    {errors.contact_email}
                  </small>
                )}
              </div>

              <div className="form-group mt-4 mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  disabled={isPending ? "disabled" : ""}
                  type="submit"
                >
                  {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MailServer;
