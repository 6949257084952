import { useSelector } from "react-redux";
import "../layout/style.scss";
import { Radio } from "antd";
import { useSignUp } from "../../../../../../utils/CustomFunctions";
import { FaSyncAlt } from "react-icons/fa";

const SocialLogin = () => {
  const { social_login_settings } = useSelector((state) => state.admin);

  const initialState = {
    facebook_login: social_login_settings?.facebook_login || "yes",
    google_login: social_login_settings?.google_login || "yes",
    facebook_id: social_login_settings?.facebook_id || "",
    facebook_secret: social_login_settings?.facebook_secret || "",
    google_id: social_login_settings?.google_id || "",
    google_secret: social_login_settings?.google_secret || "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};
    return newErrors;
  };

  const { values, isPending, handleChange, handleSubmit } = useSignUp(
    initialState,
    validateFields,
    `admin/add_social_login_settings`,
    "social_login_settings"
  );
  return (
    <>
      <div className="card box">
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form onSubmit={handleSubmit} className="signform mt-3 ">
              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Enable Facebook Social Login
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="facebook_login"
                      checked={values.facebook_login === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="facebook_login"
                      checked={values.facebook_login === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="Facebook_Id" className="form-label fw-600">
                  Facebook Id
                </label>

                <input
                  type="text"
                  name="facebook_id"
                  id="Facebook_Id"
                  className="form-control"
                  value={values.facebook_id}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="Facebook_Secret" className="form-label fw-600">
                  Facebook Secret
                </label>
                <input
                  type="text"
                  name="facebook_secret"
                  id="Facebook_Secret"
                  className="form-control"
                  value={values.facebook_secret}
                  onChange={handleChange}
                />
              </div>

              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Enable Google Social Login
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="google_login"
                      checked={values.google_login === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="google_login"
                      checked={values.google_login === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="google_id" className="form-label fw-600">
                  Google Id
                </label>
                <input
                  type="text"
                  name="google_id"
                  id="google_id"
                  className="form-control"
                  value={values.google_id}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="google_secret" className="form-label fw-600">
                  Google Secret
                </label>
                <input
                  type="text"
                  name="google_secret"
                  id="google_secret"
                  className="form-control"
                  value={values.google_secret}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group mt-4 mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  disabled={isPending ? "disabled" : ""}
                  type="submit"
                >
                  {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialLogin;
