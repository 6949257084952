import { Dropdown, Modal, Pagination, Progress, Space, Tooltip } from "antd";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaAt,
  FaCalendar,
  FaChartLine,
  FaEdit,
  FaEllipsisV,
  FaEye,
  FaEyeSlash,
  FaFileAlt,
  FaFileInvoiceDollar,
  FaImage,
  FaInfoCircle,
  FaRegWindowClose,
  FaStar,
  FaTicketAlt,
  FaUsers,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Alert from "../../../../global/alert/Alert";
import {
  calculate_Percentage,
  formatDate,
  useDelete,
  useStatusChange,
} from "../../../../../utils/CustomFunctions";
import { IoCloseSharp } from "react-icons/io5";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Table = ({ data }) => {
  const { countries, audiences } = useSelector((state) => state.AllData);
  const server = process.env.REACT_APP_SERVER;
  const [openModel, setOpenModel] = useState(false);
  const [openModel2, setOpenModel2] = useState(false);
  const [openModel3, setOpenModel3] = useState(false);
  const [modelEevnt, setModelEevnt] = useState({});
  const [filteredAudience, setfilteredAudience] = useState([]);
  const [request, setRequest] = useState({});
  const { payout_request } = useSelector((state) => state.organizer);
  const { loading, handleStatusChange } = useStatusChange(
    "organizer",
    "event_change_status",
    "events",
    "events"
  );

  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No events found",
  };
  const msg2 = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: " Once the payout request submitted, the event date will be locked and the sales will be suspended for the specific event date. If you wish, you can wait until the start date of the event date before requesting the payout. You can cancel the payout request any time before it is processed",
  };
  const handleModelEvent = (e, id, model) => {
    e.preventDefault();
    setModelEevnt(data?.find((event) => event.id === id));
    model === "model1"
      ? setOpenModel(true)
      : model === "model2"
      ? setOpenModel2(true)
      : setOpenModel3(true);
  };

  // for soft delete and parmanently delete
  const { isLoading, handleDelete } = useDelete(
    "organizer",
    "events",
    "events",
    "event_summary"
  );

  // for check dates
  function validDate(start, end) {
    const currentDate = new Date();
    const isSart = new Date(start) <= currentDate;
    const isEnd = new Date(end) < currentDate;
    return { isSart, isEnd };
  }
  const ticketSale = validDate(
    modelEevnt?.tickets?.[0]?.sale_start,
    modelEevnt?.tickets?.[0]?.sale_end
  );
  const event = validDate(modelEevnt?.start_On, modelEevnt?.end_On);

  useEffect(() => {
    if (openModel) {
      const d =
        payout_request?.find((r) => r.event.id === modelEevnt?.id) || {};
      setRequest(d);
    }
  }, [openModel]);

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastEvent = currentPage * itemsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - itemsPerPage;
  const currentEvents = data?.slice(indexOfFirstEvent, indexOfLastEvent);

  useEffect(() => {
    if (openModel2) {
      // Convert string IDs to integers
      const firstArrayInt = modelEevnt?.audiences?.map((idStr) =>
        parseInt(idStr)
      );
      // Filter second array based on IDs present in the first array
      const filteredArray = audiences.filter((item) =>
        firstArrayInt?.includes(item.id)
      );
      setfilteredAudience(filteredArray);
    }
  }, [modelEevnt]);

  // {
  //   label: (
  //     <Link
  //       to=""
  //       className="d-flex align-items-center"
  //       onClick={(e) => handleModelEvent(e, id, "model1")}
  //     >
  //       <FaFileInvoiceDollar className="me-1" />
  //       Request payout
  //     </Link>
  //   ),
  //   key: "2",
  // },
  return (
    <>
      <Modal
        centered
        open={openModel3}
        onCancel={() => setOpenModel3(false)}
        className="Model"
      >
        <div className="ModelBox">
          <div className="ModelHeader">
            <span className="ModelBoxBuyTicketText">{modelEevnt?.name}</span>
          </div>
          <div className="jconfirm-content" id="jconfirm-box27525">
            <div className="pt-3">
              <b className="float-left">
                {formatDate(new Date(modelEevnt?.start_On)).fullDate}
              </b>
              <Link
                to={`/en/dashboard/organizer/my-events/${modelEevnt?.reference}`}
                className="float-end"
              >
                View stats
              </Link>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>

        <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
          <button
            className="btn btn-outline-secondary btn-sm rounded-5 d-inline-flex align-items-center gap-2"
            onClick={() => setOpenModel3(false)}
          >
            close
          </button>
        </div>
      </Modal>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={openModel}
        onCancel={() => setOpenModel(false)}
        className="Model"
      >
        <div className="ModelBox">
          <div className="ModelHeader">
            <span className="ModelBoxBuyTicketText">{modelEevnt?.name}</span>
          </div>
          <div className="jconfirm-content" id="jconfirm-box27525">
            <Alert {...msg2} />
            <hr />
            <div className="d-flex align-items-center justify-content-between">
              <b>{formatDate(new Date(modelEevnt?.start_On)).fullDate}</b>
              {!modelEevnt.totalSubPrice > 0 && (
                <span className="badge badge-info ">No sales yet</span>
              )}

              {(!request || !Object.keys(request).length > 0) &&
                modelEevnt.totalQuantity > 0 && (
                  <Link
                    to={`/en/dashboard/organizer/my-payout-requests/add/${modelEevnt?.id}`}
                    className="d-flex align-items-center"
                  >
                    <FaFileInvoiceDollar className="me-1" />
                    Request payout
                  </Link>
                )}

              {request &&
                Object.keys(request).length > 0 &&
                modelEevnt.totalQuantity > 0 && (
                  <Link
                    to={`/en/dashboard/organizer/my-payout-requests/event/${request.reference}`}
                    className="d-flex align-items-center"
                  >
                    <FaFileInvoiceDollar className="me-1" />
                    Payout already requested
                  </Link>
                )}
            </div>
          </div>
        </div>

        <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
          <button
            className="btn btn-outline-secondary btn-sm rounded-5 d-inline-flex align-items-center gap-2"
            onClick={() => setOpenModel(false)}
          >
            close
          </button>
        </div>
      </Modal>
      {/*  */}
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={openModel2}
        onCancel={() => setOpenModel2(false)}
        className="Event Model"
      >
        {openModel2 && (
          <>
            <div className="ModelBox">
              <div className="ModelHeader">
                <span className="ModelBoxBuyTicketText">
                  {modelEevnt?.name}
                </span>
              </div>
              <div className="ModelContent">
                <div className="jconfirm-content" id="jconfirm-box19401">
                  <div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="table-responsive">
                          <table className="table table-borderless table-striped table-hover table-sm">
                            <thead>
                              <tr>
                                <th
                                  colSpan="2"
                                  className="d-flex align-items-center"
                                >
                                  <FaFileAlt className="me-1" />
                                  General information
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td width="30%">Status</td>
                                <td>
                                  {modelEevnt?.status === "inactive" && (
                                    <span className="badge badge-warning">
                                      Event is not yet published.
                                    </span>
                                  )}

                                  {modelEevnt?.status === "active" &&
                                    (modelEevnt?.tickets?.[0]
                                      ?.enable_sales_ticket === 0 ||
                                      modelEevnt.enable_event_date === 0 ||
                                      !ticketSale.isSart ||
                                      ticketSale.isEnd) && (
                                      <span className="badge badge-info">
                                        No events on sale
                                      </span>
                                    )}
                                  {modelEevnt?.status === "active" &&
                                    modelEevnt?.tickets?.[0]
                                      ?.enable_sales_ticket === 1 &&
                                    modelEevnt?.enable_event_date === 1 &&
                                    ticketSale.isSart &&
                                    !ticketSale.isEnd && (
                                      <span className="badge badge-success">
                                        on sale
                                      </span>
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td width="30%">Title</td>
                                <td>{modelEevnt?.name}</td>
                              </tr>
                              <tr>
                                <td>Reference</td>
                                <td>{modelEevnt?.reference}</td>
                              </tr>
                              <tr>
                                <td>Creation date</td>
                                <td>
                                  {
                                    formatDate(new Date(modelEevnt?.created_at))
                                      .fullDate
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Update date</td>
                                <td>
                                  {
                                    formatDate(new Date(modelEevnt?.updated_at))
                                      .fullDate
                                  }
                                </td>
                              </tr>
                              {/* <tr>
                                <td>Views</td>
                                <td>27 view(s)</td>
                              </tr>
                              <tr>
                                <td>Added to favorites by</td>
                                <td>0 user(s)</td>
                              </tr> */}
                              {/* <tr>
                                <td>Category</td>
                                <td>{modelEevnt?.category?.name}</td>
                              </tr> */}

                              {modelEevnt?.languages && (
                                <tr>
                                  <td>Languages</td>
                                  <td className="text-capitalize">
                                    {modelEevnt?.languages}
                                  </td>
                                </tr>
                              )}
                              {modelEevnt?.subtitles &&
                                modelEevnt?.subtitles?.length > 0 && (
                                  <tr>
                                    <td>Subtitles</td>
                                    <td className="text-capitalize">
                                      {modelEevnt?.subtitles?.map(
                                        (subtitle, index) => (
                                          <React.Fragment key={index}>
                                            {subtitle},{" "}
                                          </React.Fragment>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                )}
                              {modelEevnt?.audiences &&
                                modelEevnt?.audiences?.length > 0 && (
                                  <tr>
                                    <td>Audiences</td>
                                    <td className="text-capitalize">
                                      {filteredAudience?.map(
                                        (audience, index) => (
                                          <React.Fragment key={index}>
                                            {audience.name},{" "}
                                          </React.Fragment>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                )}
                              {modelEevnt?.country && (
                                <tr>
                                  <td>Country</td>
                                  <td className="text-capitalize">
                                    {countries?.map((curElem) => {
                                      if (curElem.id == modelEevnt?.country) {
                                        return (
                                          <React.Fragment key={curElem.id}>
                                            {curElem.name}
                                          </React.Fragment>
                                        );
                                      }
                                      return null; // or any other default value if the condition is not met
                                    })}
                                  </td>
                                </tr>
                              )}
                              {modelEevnt?.artists &&
                                modelEevnt?.artists?.length > 0 && (
                                  <tr>
                                    <td>Artists</td>
                                    <td>
                                      {modelEevnt?.artists?.map(
                                        (artist, index) => (
                                          <React.Fragment key={index}>
                                            {artist},{" "}
                                          </React.Fragment>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                )}
                              {modelEevnt?.tags?.length > 0 && (
                                <tr>
                                  <td>Tags</td>
                                  <td>
                                    {modelEevnt?.tags?.map((tag, index) => (
                                      <React.Fragment key={index}>
                                        {tag},{" "}
                                      </React.Fragment>
                                    ))}
                                  </td>
                                </tr>
                              )}
                              {modelEevnt?.year && (
                                <tr>
                                  <td>Year</td>
                                  <td>{modelEevnt?.year}</td>
                                </tr>
                              )}
                              {modelEevnt?.youtube_video_url && (
                                <tr>
                                  <td>Youtube video</td>
                                  <td>{modelEevnt?.youtube_video_url}</td>
                                </tr>
                              )}
                              <tr>
                                <td>Publicly show attendees</td>
                                <td>
                                  {modelEevnt?.attendees_show === 1
                                    ? "Yes"
                                    : "No"}
                                </td>
                              </tr>
                              <tr>
                                <td>Allow attendees to leave reviews</td>
                                <td>
                                  {modelEevnt?.review === 1 ? "Yes" : "No"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="table-responsive">
                          <table className="table table-borderless table-striped table-hover table-sm">
                            <thead>
                              <tr>
                                <th
                                  colSpan="2"
                                  className="d-flex align-items-center"
                                >
                                  <FaImage className="me-1" />
                                  Images
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td width="30%">Main image</td>
                                <td>
                                  {modelEevnt?.main_event_image ? (
                                    <Tooltip title="Enlarge">
                                      <Link
                                        className="fancybox"
                                        to={`${server}/event/image/${modelEevnt?.main_event_image}`}
                                        target="_blank"
                                      >
                                        <img
                                          src={`${server}/event/image/${modelEevnt?.main_event_image}`}
                                          className="img-thumbnail img-50-50"
                                          width="50%"
                                        />
                                      </Link>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Enlarge">
                                      <Link
                                        className="fancybox"
                                        to="/./images/noPhoto.png"
                                        target="_blank"
                                      >
                                        <img
                                          src="/./images/noPhoto.png"
                                          className="img-thumbnail img-50-50"
                                          width="50%"
                                        />
                                      </Link>
                                    </Tooltip>
                                  )}
                                </td>
                              </tr>
                              {modelEevnt?.images &&
                                modelEevnt?.images?.length > 0 && (
                                  <tr>
                                    <td>Gallery</td>
                                    <td>
                                      {modelEevnt?.images?.length} image(s)
                                      <div className="gallery photos-gallery d-flex">
                                        {modelEevnt?.images?.map((image) => (
                                          <figure
                                            key={image.id}
                                            style={{ width: "30%" }}
                                          >
                                            <Tooltip title="Enlarge">
                                              <Link
                                                className="fancybox"
                                                to={`${server}/event/gallery/${image?.image}`}
                                                target="_blank"
                                              >
                                                <img
                                                  src={`${server}/event/gallery/${image?.image}`}
                                                  width="100%"
                                                  className="img-thumbnail img-50-50"
                                                  style={{ height: "70px" }}
                                                />
                                              </Link>
                                            </Tooltip>
                                          </figure>
                                        ))}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="table-responsive">
                          <table className="table table-borderless table-sm">
                            <thead>
                              <tr>
                                <th>
                                  <span className="d-flex align-items-center">
                                    <FaCalendar className="me-1" />
                                    Event dates
                                  </span>
                                </th>
                                <th>
                                  <span className="d-flex align-items-center">
                                    <FaTicketAlt className="me-1" />
                                    Tickets
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td width="50%">
                                  <h6>Event date 1</h6>
                                  <div className="table-responsive">
                                    <table className="table table-borderless table-striped table-hover table-sm">
                                      <tbody>
                                        <tr>
                                          <td>Start date</td>
                                          <td>
                                            {
                                              formatDate(
                                                new Date(modelEevnt?.start_On)
                                              ).fullDate
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>End date</td>
                                          <td>
                                            {
                                              formatDate(
                                                new Date(modelEevnt?.end_On)
                                              ).fullDate
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="30%">Status</td>

                                          <td>
                                            {modelEevnt?.status === "active" &&
                                              (modelEevnt?.tickets?.[0]
                                                ?.enable_sales_ticket === 0 ||
                                                modelEevnt.enable_event_date ===
                                                  0 ||
                                                !ticketSale.isSart) && (
                                                <span className="badge badge-info">
                                                  No events on sale
                                                </span>
                                              )}
                                            {modelEevnt?.status === "active" &&
                                              modelEevnt?.tickets?.[0]
                                                ?.enable_sales_ticket === 1 &&
                                              modelEevnt?.enable_event_date ===
                                                1 &&
                                              ticketSale.isSart &&
                                              !ticketSale.isEnd &&
                                              !event.isSart && (
                                                <span className="badge badge-success">
                                                  on sale
                                                </span>
                                              )}

                                            {modelEevnt?.status === "active" &&
                                              modelEevnt?.tickets?.[0]
                                                ?.enable_sales_ticket === 1 &&
                                              modelEevnt?.enable_event_date ===
                                                1 &&
                                              ticketSale.isSart &&
                                              !ticketSale.isEnd &&
                                              event.isSart && (
                                                <span className="badge badge-info">
                                                  Event already started
                                                </span>
                                              )}

                                            {modelEevnt?.status === "active" &&
                                              modelEevnt?.tickets?.[0]
                                                ?.enable_sales_ticket === 1 &&
                                              modelEevnt?.enable_event_date ===
                                                1 &&
                                              ticketSale.isSart &&
                                              ticketSale.isEnd && (
                                                <span className="badge badge-success">
                                                  No ticket on sale
                                                </span>
                                              )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Sales</td>
                                          <td>{modelEevnt?.payments_count}</td>
                                        </tr>

                                        {modelEevnt?.event_date_online === 0 ? (
                                          <>
                                            <tr>
                                              <td width="50%">Venue</td>
                                              <td>{modelEevnt?.venue?.name}</td>
                                            </tr>
                                            <tr>
                                              <td>Address</td>
                                              <td>
                                                {
                                                  modelEevnt?.venue
                                                    ?.street_address
                                                }
                                                ,{" "}
                                                {
                                                  modelEevnt?.venue
                                                    ?.street_address_2
                                                }
                                                {modelEevnt?.venue?.postal_code}{" "}
                                                {modelEevnt?.venue?.city},{" "}
                                                {modelEevnt?.venue?.state},{" "}
                                                {modelEevnt?.venue?.country}
                                              </td>
                                            </tr>
                                          </>
                                        ) : (
                                          <tr>
                                            <td width="50%">Where</td>
                                            <td>Online</td>
                                          </tr>
                                        )}

                                        <tr>
                                          <td>Tickets types</td>
                                          <td>1</td>
                                        </tr>

                                        {/* {modelEevnt?.event_date_online === 1 ? (
                                          <>
                                            <tr>
                                              <td>Scanners</td>
                                              <td>Test scanner</td>
                                            </tr>
                                            <tr>
                                              <td>Point of sales</td>
                                              <td>kevin oj</td>
                                            </tr>
                                          </>
                                        ) : (
                                          <></>
                                        )} */}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                                <td>
                                  <h6>Ticket 1</h6>
                                  <div className="table-responsive">
                                    <table className="table table-borderless table-striped table-hover table-sm">
                                      <tbody>
                                        <tr>
                                          <td>Name</td>
                                          <td>
                                            {
                                              modelEevnt?.tickets[0]
                                                ?.ticket_name
                                            }
                                            {/* <small className="text-muted">
                                              #1d1868044c
                                            </small> */}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="30%">Status</td>
                                          <td>
                                            {modelEevnt?.status === "active" &&
                                              (modelEevnt?.tickets?.[0]
                                                ?.enable_sales_ticket === 0 ||
                                                modelEevnt.enable_event_date ===
                                                  0 ||
                                                !ticketSale.isSart) && (
                                                <span className="badge badge-info">
                                                  No events on sale
                                                </span>
                                              )}

                                            {modelEevnt?.status === "active" &&
                                              modelEevnt?.tickets?.[0]
                                                ?.enable_sales_ticket === 1 &&
                                              modelEevnt?.enable_event_date ===
                                                1 &&
                                              ticketSale.isSart &&
                                              !ticketSale.isEnd &&
                                              !event.isSart && (
                                                <span className="badge badge-success">
                                                  on sale
                                                </span>
                                              )}

                                            {modelEevnt?.status === "active" &&
                                              modelEevnt?.tickets?.[0]
                                                ?.enable_sales_ticket === 1 &&
                                              modelEevnt?.enable_event_date ===
                                                1 &&
                                              ticketSale.isSart &&
                                              !ticketSale.isEnd &&
                                              event.isSart && (
                                                <span className="badge badge-info">
                                                  Event already started
                                                </span>
                                              )}

                                            {modelEevnt?.status === "active" &&
                                              modelEevnt?.tickets?.[0]
                                                ?.enable_sales_ticket === 1 &&
                                              modelEevnt?.enable_event_date ===
                                                1 &&
                                              ticketSale.isSart &&
                                              ticketSale.isEnd && (
                                                <span className="badge badge-success">
                                                  Sale ended
                                                </span>
                                              )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Sales</td>
                                          <td>{modelEevnt?.payments_count}</td>
                                        </tr>
                                        <tr>
                                          <td>Tickets left</td>
                                          <td>
                                            {modelEevnt?.payments_count ===
                                            modelEevnt?.tickets[0]?.quantity
                                              ? "Tickets overbooked"
                                              : Math.abs(
                                                  modelEevnt?.payments_count -
                                                    modelEevnt?.tickets[0]
                                                      ?.quantity
                                                )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Quantity</td>
                                          <td>
                                            {modelEevnt?.tickets[0]?.quantity}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Price</td>
                                          <td>
                                            ${modelEevnt?.tickets[0]?.Price}
                                          </td>
                                        </tr>
                                        {/* <tr>
                                          <td>Promotional price</td>
                                          <td>2$t</td>
                                        </tr> */}
                                        <tr>
                                          <td>Tickets per attendee</td>
                                          <td>
                                            {
                                              modelEevnt?.tickets[0]
                                                ?.tickets_per_attendee
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Sales start date</td>
                                          <td>
                                            {
                                              formatDate(
                                                new Date(
                                                  modelEevnt?.tickets[0]?.sale_start
                                                )
                                              ).fullDate
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Sales end date</td>
                                          <td>
                                            {
                                              formatDate(
                                                new Date(
                                                  modelEevnt?.tickets[0]?.sale_end
                                                )
                                              ).fullDate
                                            }
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="table-responsive">
                          <table className="table table-borderless table-striped table-hover table-sm">
                            <thead>
                              <tr>
                                <th
                                  colSpan="2"
                                  className="d-flex align-items-center"
                                >
                                  <FaAt className="me-1" />
                                  Contact &amp; Social media
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {modelEevnt?.external_link && (
                                <tr>
                                  <td>External link</td>
                                  <td>{modelEevnt?.external_link}</td>
                                </tr>
                              )}
                              {modelEevnt?.contact_email && (
                                <tr>
                                  <td>Email</td>
                                  <td>{modelEevnt?.contact_email}</td>
                                </tr>
                              )}

                              {modelEevnt?.phone_number && (
                                <tr>
                                  <td>Phone number</td>
                                  <td>{modelEevnt?.phone_number}</td>
                                </tr>
                              )}
                              {modelEevnt?.facebook && (
                                <tr>
                                  <td>Facebook</td>
                                  <td>{modelEevnt?.facebook}</td>
                                </tr>
                              )}
                              {modelEevnt?.twitter && (
                                <tr>
                                  <td>Twitter</td>
                                  <td>{modelEevnt?.twitter}</td>
                                </tr>
                              )}
                              {modelEevnt?.google_plus && (
                                <tr>
                                  <td>Google plus</td>
                                  <td>{modelEevnt?.google_plus}</td>
                                </tr>
                              )}
                              {modelEevnt?.instagram && (
                                <tr>
                                  <td>Instagram</td>
                                  <td>{modelEevnt?.instagram}</td>
                                </tr>
                              )}
                              {modelEevnt?.linkedIn && (
                                <tr>
                                  <td>Linkedin</td>
                                  <td>{modelEevnt?.linkedIn}</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="table-responsive">
                          <table className="table table-borderless table-striped table-hover table-sm">
                            <thead>
                              <tr>
                                <th
                                  colSpan="2"
                                  className="d-flex align-items-center"
                                >
                                  <FaStar className="me-1" />
                                  Reviews
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td width="30%">Count</td>
                                <td>{modelEevnt?.reviews.length}</td>
                              </tr>
                              <tr>
                                <td>Rating</td>
                                <td>
                                  <div className="rating-wrap">
                                    <div className="label-rating">
                                      {modelEevnt?.average_rating} out of 5
                                      stars
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
              <button
                className="btn btn-outline-dark rounded-5 d-inline-flex align-items-center gap-2"
                onClick={() => setOpenModel2(false)}
              >
                <FaRegWindowClose className="ModelBoxTicketPurchaseTitleIcon" />
                close
              </button>
            </div>
          </>
        )}
      </Modal>
      {/*  */}
      <div className="row organizer-event">
        <div className="col-12">
          {data?.length > 0 ? (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter">
                  <thead>
                    <tr>
                      <th style={{ width: "35%" }}>Event</th>
                      <th>Sales</th>
                      <th>Status</th>
                      <th>Attendance</th>
                      <th>Action</th>
                      <th className="text-center">
                        <AiTwotoneSetting />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEvents.map((event, i) => {
                      const {
                        id,
                        name,
                        reference,
                        status,
                        main_event_image,
                        slug,
                      } = event;
                      const items = [
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) => handleModelEvent(e, id, "model3")}
                            >
                              <FaChartLine className="me-1" />
                              Statistics
                            </Link>
                          ),
                          key: "1",
                        },
                        {
                          label: (
                            <Link
                              to={`/en/dashboard/organizer/my-events/${slug}/edit`}
                              className="d-flex align-items-center"
                            >
                              <FaEdit className="me-1" />
                              Edit
                            </Link>
                          ),
                          key: "3",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              onClick={(e) => handleModelEvent(e, id, "model2")}
                              className="d-flex align-items-center"
                            >
                              <FaFileAlt className="me-1" />
                              Details
                            </Link>
                          ),
                          key: "4",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) => handleStatusChange(e, id)}
                            >
                              {status === "inactive" ? (
                                <>
                                  <FaEye className="me-1" /> Publish
                                </>
                              ) : (
                                <>
                                  <FaEyeSlash className="me-1" /> Draft
                                </>
                              )}
                            </Link>
                          ),
                          key: "0",
                        },
                        {
                          label: (
                            <Link
                              to={`/en/dashboard/organizer/manage-orders/event/${reference}`}
                              className="d-flex align-items-center"
                            >
                              <FaUsers className="me-1" />
                              Attendees
                            </Link>
                          ),
                          key: "5",
                        },
                        {
                          label: (
                            <Link
                              to={`/en/dashboard/organizer/reviews/${slug}`}
                              className="d-flex align-items-center"
                            >
                              <FaStar className="me-1" />
                              Reviews
                            </Link>
                          ),
                          key: "6",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(
                                  e,
                                  event.id,
                                  "event_delete_permanent",
                                  "delete_permanent"
                                )
                              }
                            >
                              <IoCloseSharp className="me-1" />
                              Delete
                            </Link>
                          ),
                          key: "7",
                        },
                      ];
                      const ticketSale = validDate(
                        event?.tickets?.[0]?.sale_start,
                        event?.tickets?.[0]?.sale_end
                      );

                      return (
                        <tr key={i}>
                          <td>
                            {main_event_image ? (
                              <Tooltip title="Enlarge">
                                <Link
                                  className="fancybox"
                                  to={`${server}/event/image/${main_event_image}`}
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Enlarge"
                                  target="_blank"
                                >
                                  <img
                                    src={`${server}/event/image/${main_event_image}`}
                                    className="img-thumbnail img-50-50"
                                  />
                                </Link>
                              </Tooltip>
                            ) : (
                              <img
                                src="/./images/noPhoto.png"
                                className="img-thumbnail img-50-50"
                              />
                            )}

                            <Link
                              to={`/en/event/${slug}`}
                              data-toggle="tooltip"
                              title=""
                              data-original-title="View event"
                              className="pe-2 ps-2"
                            >
                              {name}
                            </Link>
                            <span className="small text-muted ml-1">
                              {reference}
                            </span>
                          </td>
                          <td>
                            <div className="d-flex justify-content-between">
                              <strong>
                                {" "}
                                {calculate_Percentage(
                                  event?.payments_count,
                                  event?.tickets?.[0]?.quantity
                                )}
                                %
                              </strong>
                              <small className="text-muted">
                                {event?.payments_count} ticket(s) sold
                              </small>
                            </div>
                            <div className="progress progress-xs">
                              <div
                                className="progress-bar bg-yellow"
                                role="progressbar"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{
                                  width: calculate_Percentage(
                                    event?.payments_count,
                                    event?.tickets?.[0]?.quantity
                                  ),
                                }}
                              ></div>
                            </div>
                          </td>

                          <td>
                            {status === "inactive" && (
                              <span className="badge badge-warning">
                                Event is not yet published.
                              </span>
                            )}
                            {status === "active" &&
                              (event?.tickets?.[0]?.enable_sales_ticket === 0 ||
                                event.enable_event_date === 0 ||
                                !ticketSale.isSart ||
                                ticketSale.isEnd) && (
                                <span className="badge badge-info">
                                  No events on sale
                                </span>
                              )}
                            {status === "active" &&
                              event?.tickets?.[0]?.enable_sales_ticket === 1 &&
                              event.enable_event_date === 1 &&
                              ticketSale.isSart &&
                              !ticketSale.isEnd && (
                                <span className="badge badge-success">
                                  on sale
                                </span>
                              )}
                          </td>
                          <td className="text-center">
                            <Space wrap>
                              <Progress
                                type="circle"
                                percent={calculate_Percentage(
                                  event?.payments_count,
                                  event?.tickets?.[0]?.quantity
                                )}
                                size={45}
                              />
                            </Space>
                          </td>
                          <td>
                            <span className="d-flex align-items-center gap-2">
                              <Link
                                to=""
                                className={`d-inline-flex align-items-center badge badge-${
                                  status === "inactive" ? "info" : "success"
                                }`}
                                onClick={(e) => handleStatusChange(e, id)}
                              >
                                {status === "inactive" ? (
                                  <>
                                    <FaEye className="me-1" /> Publish
                                  </>
                                ) : (
                                  <>
                                    <FaEyeSlash className="me-1" /> Draft
                                  </>
                                )}
                              </Link>
                              <Link
                                to=""
                                className="badge badge-info"
                                onClick={(e) =>
                                  handleModelEvent(e, id, "model1")
                                }
                              >
                                Request payout
                              </Link>
                            </span>
                            {event.deleted_at && (
                              <>
                                <br />
                                <br />
                                <span className="badge badge-danger d-inline-flex align-items-center">
                                  <IoCloseSharp className="me-1" />
                                  Deleted
                                </span>
                              </>
                            )}
                          </td>
                          <td className="text-center">
                            <Dropdown
                              className="table-dropdown"
                              menu={{
                                items,
                              }}
                              trigger={["click"]}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                  <FaEllipsisV />
                                </Space>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <Alert {...msg} />
          )}
        </div>
      </div>

      {/* pagination */}
      {data?.length > 10 && (
        <Pagination
          current={currentPage}
          total={data.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Table;
