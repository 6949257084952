import React, { useEffect } from "react";
import "./adminsidebar.scss";
import { Outlet } from "react-router-dom";
import { Layout } from "../../../LayoutUser/Layout";
import { useDispatch, useSelector } from "react-redux";
import { fetchTickets } from "../../../../store/slice/Cart";
import { fetchuUserData } from "../../../../store/slice/Attendee";
import AttendeeSidebar from "./AttendeeSidebar";

const Attendee = () => {
  const { user } = useSelector((state) => state.authUser);
  const { cart } = useSelector((state) => state.CartDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchTickets({
        getData: "attendee/tickets",
        state: "myWallet",
        token: user.token,
      })
    );
    dispatch(
      fetchuUserData({
        getData: "attendee/user_event_reviews",
        state: "myRviews",
        token: user.token,
      })
    );
    dispatch(
      fetchuUserData({
        getData: "attendee/favorites",
        state: "favorites",
        token: user.token,
      })
    );
    dispatch(
      fetchuUserData({
        getData: "attendee/followings",
        state: "followings",
        token: user.token,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      fetchTickets({
        getData: "attendee/check_out_cart",
        state: "tickets",
        token: user.token,
      })
    );
  }, [cart]);

  return (
    <Layout>
      <div className="container attendeeSidebar">
        <div className="row">
          <aside className="col-lg-3 pt-3 pt-lg-0 d-lg-block d-none">
            <AttendeeSidebar />
          </aside>
          <div className="col-lg-9 mt-4 mt-lg-0">
            <Outlet />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Attendee;
