import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import {
  BiCaretDown,
  BiGlobe,
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoTwitter,
  BiLogoYoutube,
} from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { addFilters } from "../../store/slice/Filters";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { categories, loading } = useSelector((state) => state.AllData);

  // Function to shuffle array randomly
  // const shuffleArray = (array) => {
  //   for (let i = array.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [array[i], array[j]] = [array[j], array[i]];
  //   }
  //   return array;
  // };
  // // Shuffle the array and get the first 5 records
  // const randomlySelectedRecords = shuffleArray([...categories]).slice(0, 4);
  const lastCategory = categories.slice(-4);

  const func = (e, name, value) => {
    e.preventDefault();
    pathname === "/en/events"
      ? dispatch(addFilters({ name, value }))
      : navigate("/en/events", {
          state: { name, value },
        });
  };

  return (
    <footer className="container-fluid footer ">
      <div className="container">
        <section className="footer-top mb-5">
          <div className="row">
            <aside className="col-sm-6 col-lg-3">
              <h5 className="title text-dark">Useful Links</h5>
              <ul>
                <li className="mb-1">
                  <Link to="/en/about-us">About us</Link>
                </li>
                <li className="mb-1">
                  <Link to="/en/help-center">Help center</Link>
                </li>
                <li className="mb-1">
                  <Link to="/en/blogS">Blogs</Link>
                </li>
                <li className="mb-1">
                  <Link to="/en/venues">Venues</Link>
                </li>
                <li className="mb-1">
                  <Link to="/en/contact">Contact Us</Link>
                </li>
              </ul>
            </aside>
            <aside className="col-sm-6 col-lg-3">
              <h5 className="title text-dark">My Account</h5>
              <ul>
                <li className="mb-1">
                  <Link to="/en/signup/attendee">Create an account</Link>
                </li>
                <li className="mb-1">
                  <Link to="/en/signup/organizer">Sell tickets online</Link>
                </li>
                <li className="mb-1">
                  <Link to="/en/dashboard/attendee/my-tickets">My tickets</Link>
                </li>
                <li className="mb-1">
                  <Link to="/en/resetting/request">Forgot your password ?</Link>
                </li>
                <li className="mb-1">
                  <Link to="/en/page/pricing-and-fees">Pricing and fees</Link>
                </li>
              </ul>
            </aside>
            <aside className="col-sm-6 col-lg-3">
              <h5 className="title text-dark">Event Categories</h5>
              <ul>
                {lastCategory?.length > 0 &&
                  lastCategory?.map((category, i) => (
                    <li className="mb-1" key={i}>
                      <Link
                        to=""
                        onClick={(e) => func(e, "category", category.id)}
                      >
                        {category.name}
                      </Link>
                    </li>
                  ))}

                <li className="mb-1">
                  <Link to="/en/categories">All categories</Link>
                </li>
              </ul>
            </aside>
            <aside className="col-sm-6 col-lg-3">
              <h5 className="title text-dark">Contact Us</h5>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <Link
                  to="https://www.facebook.com"
                  target="_blanck"
                  className="btn btn-facebook"
                  title="Facebook"
                >
                  <BiLogoFacebook />
                </Link>
                <Link
                  to="https://www.instagram.com"
                  target="_blanck"
                  className="btn btn-instagram"
                  title="Instagram"
                >
                  <BiLogoInstagram />
                </Link>
                <Link
                  to="https://www.youtube.com"
                  target="_blanck"
                  className="btn btn-youtube"
                  title="Youtube"
                >
                  <BiLogoYoutube />
                </Link>
                <Link
                  to="https://www.twitter.com"
                  target="_blanck"
                  className="btn btn-twitter"
                  title="Twitter"
                >
                  <BiLogoTwitter />
                </Link>
              </div>
              <div className="d-flex gap-5 mt-4">
                <h5 className="title  d-flex align-items-center gap-1">
                  <BiGlobe className="glob" /> Language
                </h5>
                <div className="dropdown ">
                  <div className="language">
                    <span className="">English</span>
                    <BiCaretDown className="downIcon" />
                  </div>
                  <div className="languagedropdownMenu">
                    <ul>
                      <li>
                        <Link to="#">Français</Link>
                      </li>
                      <li>
                        <Link to="#">Spanish</Link>
                      </li>
                      <li>
                        <Link to="#">عربي</Link>
                      </li>
                      <li>
                        <Link to="#">Deutsch</Link>
                      </li>
                      <li>
                        <Link to="#">Português</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </section>
        <section className="footer-bottom">
          <div className="row">
            <div className="col-sm-12">
              <p className="text-center text-dark">
                <Link to="/en/page/terms-of-service" className="text-dark">
                  Terms of service
                </Link>
                <span className="text-gray"> | </span>
                <Link to="/en/page/privacy-policy" className="text-dark">
                  Privacy policy
                </Link>
                <span className="text-gray"> | </span>
                <Link to="/en/page/cookie-policy" className="text-dark">
                  Cookie policy
                </Link>
                <span className="text-gray"> | </span>
                <Link to="/en/about-us" className="text-dark">
                  GDPR compliance
                </Link>
              </p>
            </div>
            <div className="col-sm-12">
              <p className="text-center text-dark-50">
                Copyright feevaa.com © 2023
              </p>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
