import React, { useState, useEffect, useRef } from "react";
import { Checkbox, DatePicker, Radio, Select, Space } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import { FaCircleInfo } from "react-icons/fa6";
import Count from "../../../../count/Count";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../store/slice/Organizer";
import SelectBox from "../../../../global/SelectBox";
import MultiSelectBox from "../../../../global/MultiSelectBox";
import { years } from "../../../../global/Values";
import AddInputTags from "../AddTags/AddInputTags";
import { FaMinusSquare, FaPlusSquare, FaSyncAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "axios";
import { fetchAllData } from "../../../../../store/slice/AllData";
import dayjs from "dayjs";

const AddEvent = () => {
  const { slug } = useParams();
  const { state, pathname } = useLocation();

  const [navi, setNavi] = useState(null);

  const checkStatus = useLocation().pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const api = process.env.REACT_APP_SERVER_API;
  const server = process.env.REACT_APP_SERVER;
  const { user } = useSelector((state) => state.authUser);
  const {
    scanners,
    sales,
    event_categories,
    venues,
    events,
    audiences,
    countries,
    languages,
  } = useSelector((state) => state.organizer);

  const statusSplit = checkStatus.split("/");
  var status = statusSplit[statusSplit.length - 1];
  const getLocalFormData = () => {
    let localFormData;
    if (status === "edit" || slug !== undefined) {
      localFormData = localStorage.getItem("formData");
    } else {
      localFormData = localStorage.getItem("formDataAdd");
    }
    if (localFormData === null) {
      return null;
    } else {
      return JSON.parse(localFormData);
    }
  };
  const [errors, setErrors] = useState({});
  const [isPanding, setPanding] = useState(false);
  const [imagesCard, setimagesCard] = useState([]);
  const [activeData, setActiveData] = useState({
    venues: [],
    scanners: [],
    sales: [],
    audiences: [],
    languages: [],
    countries: [],
  });

  const ticket = {
    id: new Date().getTime().toString(),
    ticket_name: "",
    ticket_description: "",
    enable_sales_ticket: 1,
    ticket_free: 0,
    discount: "",
    Price: "",
    tickets_per_attendee: "",
    quantity: "",
    sale_start: "",
    sale_end: "",
  };

  const initialState = {
    event_categories_id: "",
    name: "",
    description: "",
    attendees_show: 1,
    review: 1,
    languages: "",
    subtitles: [],
    audiences: [],
    country: "",
    year: "",
    youtube_video_url: "",
    external_link: "",
    phone_number: "",
    twitter: "",
    instagram: "",
    contact_email: "",
    facebook: "",
    google_plus: "",
    linkedIn: "",
    artists: [],
    tags: [],
    main_event_image: "",
    images: [],

    enable_event_date: 1,
    start_On: "",
    end_On: "",
    event_date_online: 0,
    venue_id: "",
    scanner_id: [],
    point_of_sales_id: [],
    tickets: [ticket],
  };

  const [newEvent, setNewEvent] = useState(getLocalFormData() || initialState);

  const event_categories_idputRef = useRef(null);
  const nameInputRef = useRef(null);
  const descriptionInputRef = useRef(null);
  const main_event_imageInputRef = useRef(null);
  const start_OnInputRef = useRef(null);
  const venue_idInputRef = useRef(null);
  const ticket_nameInputRef = useRef(null);
  const PriceInputRef = useRef(null);
  const quantityInputRef = useRef(null);

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  const modules = {
    toolbar: toolbarOptions,
  };
  // const evTicket = status === "edit" ? newEvent?.tickets : newEvent?.ticket;
  const evTicket = newEvent?.tickets;
  // validate fields
  const validateFields = () => {
    let newErrors = {};

    if (!newEvent.event_categories_id)
      newErrors.event_categories_id = "This value should not be null.";
    if (!newEvent.name.trim())
      newErrors.name = "This value should not be null.";
    if (!newEvent.description)
      newErrors.description = "This value should not be null.";
    if (!newEvent.main_event_image)
      newErrors.main_event_image = "This value should not be null.";
    if (!newEvent.start_On)
      newErrors.start_On = "This value should not be null.";
    if (newEvent?.event_date_online === 0) {
      if (!newEvent.venue_id) {
        newErrors.venue_id = "This value should not be null.";
      }
    }

    evTicket?.map((curElem, i) => {
      if (!curElem.ticket_name.trim())
        newErrors.ticket_name = "This value should not be null.";

      if (curElem.ticket_free === 0) {
        if (!curElem.Price) {
          newErrors.Price = "This value should not be null.";
        }
      }

      if (!curElem.quantity)
        newErrors.quantity = "This value should not be null.";
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };
  // Scroll to the corresponding input field error
  const scrollToFirstError = (errors) => {
    // Find the first error field
    const firstErrorField = Object.keys(errors)[0];
    // Scroll to the corresponding input field
    if (firstErrorField === "name" && nameInputRef.current) {
      nameInputRef.current.focus();
    } else if (
      firstErrorField === "main_event_image" &&
      main_event_imageInputRef.current
    ) {
      main_event_imageInputRef.current.focus();
    } else if (firstErrorField === "start_On" && start_OnInputRef.current) {
      start_OnInputRef.current.focus();
    } else if (
      firstErrorField === "event_categories_id" &&
      event_categories_idputRef.current
    ) {
      event_categories_idputRef.current.focus();
    } else if (
      firstErrorField === "ticket_name" &&
      ticket_nameInputRef.current
    ) {
      ticket_nameInputRef.current.focus();
    } else if (firstErrorField === "Price" && PriceInputRef.current) {
      PriceInputRef.current.focus();
    } else if (firstErrorField === "quantity" && quantityInputRef.current) {
      quantityInputRef.current.focus();
    } else if (firstErrorField === "venue_id" && venue_idInputRef.current) {
      venue_idInputRef.current.focus();
    } else if (
      errors.description &&
      descriptionInputRef.current &&
      descriptionInputRef.current.editor
    ) {
      let editor = descriptionInputRef.current.editor;
      let errorText = errors.description.trim(); // Get the error message
      let content = editor.getText(); // Get the editor content

      // Find the position of the first character of the error message within the content
      let errorIndex = content.indexOf(errorText);
      // If the error message is found, scroll to its position; otherwise, scroll to the end of the content as fallback
      let errorPosition = errorIndex !== -1 ? errorIndex : editor.getLength();
      editor.setSelection(errorPosition);
    }
  };
  // for handle Editor on change
  const handleEditor = (html) => {
    const isEmptyEditor = html.trim() === "<p><br></p>";
    setNewEvent({ ...newEvent, description: isEmptyEditor ? "" : html });
  };
  // for handleCheckboxChange
  const handleCheckboxChange = (e) => {
    const value = e.target.value?.toString();
    const isSelected = e.target.checked;
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      audiences: !isSelected
        ? prevEvent.audiences.filter((option) => option !== value)
        : [...prevEvent.audiences, value],
    }));
  };
  // fon handle on change
  const handleEventChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({ ...newEvent, [name]: value });
  };
  // for handle change slectbox
  const handleSelectEvent = (name, value) => {
    setNewEvent({ ...newEvent, [name]: value });
  };
  // handle multi select box
  const handleMultiSelectBox = (name, value) => {
    const selectedValues = value ? value?.map((option) => option.value) : [];
    setNewEvent({ ...newEvent, [name]: selectedValues });
  };
  // for handle ticket input change
  const handleNewTicketToEvent = (e, ticketId) => {
    const { name, value } = e.target;
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      tickets: prevEvent?.tickets?.map((ticket) => {
        if (ticket.id === ticketId) {
          return {
            ...ticket,
            [name]: value,
            Price: name === "ticket_free" && value === 1 ? 0 : ticket.Price,
          };
        }
        return ticket;
      }),
    }));
  };
  // for images change -new
  const handleIagesChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    setNewEvent({
      ...newEvent,
      [name]: name === "images" ? [...newEvent?.images, file] : file,
    });
  };
  // for set ticket date time change -new
  const handleNewTicketDate = (e, name, ticketId) => {
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      tickets: prevEvent.tickets?.map((ticket) => {
        if (ticket.id === ticketId) {
          return {
            ...ticket,
            [name]: e,
          };
        }
        return ticket;
      }),
    }));
  };
  // for date change
  const handleDateChange = (date, dateString) => {
    setNewEvent((prevEvent) => ({ ...prevEvent, [date]: dateString }));
  };

  // handle images gallery card add -new
  const addImageCard = (e) => {
    e.preventDefault();
    const id = new Date().getTime().toString();
    setimagesCard([...imagesCard, { id }]);
  };
  // handle images gallery card remove - new
  const removeImageCard = (e, id, index) => {
    e.preventDefault();
    const imageCard = imagesCard?.filter((curElem) => curElem.id !== id);
    const images = newEvent.images?.filter((_, indx) => indx !== index);
    setimagesCard(imageCard);
    setNewEvent({
      ...newEvent,
      images,
      image_id:
        status === "edit"
          ? newEvent.image_id.filter((ele1, i) => ele1 !== id)
          : "",
    });
  };
  // for add new ticket card
  const addNewTicketCardToEventCard = (e) => {
    e.preventDefault();
    // const evTicket = status === "edit" ? "tickets" : "ticket";
    const evTicket = "tickets";
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      [evTicket]: [
        ...prevEvent.tickets,
        { ...ticket, id: new Date().getTime().toString() },
      ],
    }));
  };
  // for remove ticket card from event
  const removeTicketCardToEventCard = (e, ticketId) => {
    e.preventDefault();
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      tickets: prevEvent.tickets.filter((ticket) => ticket.id !== ticketId),
    }));
  };

  // for add new enent to database - new
  const addNewEvent = async (e) => {
    e.preventDefault();

    // Validate form fields
    const isValid = validateFields();
    // Scroll to first error if any
    if (!isValid) {
      // Use a callback to ensure state update is complete
      setErrors((errors) => {
        scrollToFirstError(errors);
        return errors;
      });
      return;
    }

    const endPoint = status === "edit" ? "edit_event" : "add_event";
    try {
      setPanding(true);
      const { data } = await axios.post(
        `${api}/organizer/${endPoint}`,
        newEvent,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if ((data.status === status) === "edit" ? 200 : 201) {
        toast.success(data.message);
        navigate("/en/dashboard/organizer/my-events");
        dispatch(
          fetchData({
            getData: "organizer/events",
            token: user.token,
            state: "events",
          })
        );
        dispatch(
          fetchAllData({
            getData: "events",
            state: "events",
          })
        );
        localStorage.clear("formDataAdd");
        localStorage.clear("formData");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setPanding(false);
    }
  };

  useEffect(() => {
    const active_sales = sales.filter((venue) => venue.status === "active");
    const active_scanners = scanners.filter(
      (venue) => venue.status === "active"
    );
    const active_venues = venues.filter((venue) => venue.status === "visible");
    let audience = audiences.filter(
      (audience) => audience.status === "visible"
    );
    // audience = audience.map((audience) => ({
    //   id: audience.slug,
    //   name: audience.name,
    // }));
    let countrie = countries.filter(
      (countrie) => countrie.status === "visible"
    );
    countrie = countrie.map((country) => ({
      id: country.code,
      name: country.name,
    }));
    let language = languages.filter(
      (language) => language.status === "visible"
    );
    language = language.map((language) => ({
      id: language.slug,
      name: language.name,
    }));

    setActiveData({
      scanners: active_scanners,
      venues: active_venues,
      sales: active_sales,
      audiences: audience,
      countries: countrie,
      languages: language,
    });
  }, [scanners, sales, venues, audiences, countries, languages]);

  useEffect(() => {
    getLocalFormData();
    if (status === "edit") {
      const localFormData = localStorage.getItem("formData");
      setNewEvent(JSON.parse(localFormData));
    }
    if (status === "add") {
      const localFormData = localStorage.getItem("formDataAdd");
      localFormData == null
        ? setNewEvent(initialState)
        : setNewEvent(JSON.parse(localFormData));
    }
  }, [status, slug]);

  useEffect(() => {
    if (status === "edit") {
      // localStorage.setItem("formData", JSON.stringify(newEvent));
      localStorage.setItem("formData", JSON.stringify(newEvent));
    } else {
      localStorage.setItem("formDataAdd", JSON.stringify(newEvent));
    }
  }, [newEvent]);

  // for get single venue for edit or update
  useEffect(() => {
    const eventEdit = events?.find((ele) => ele.slug === slug);
    if (status === "edit") {
      setimagesCard(eventEdit?.images);
      const imageIds = eventEdit?.images?.map((image) => image.id);
      setNewEvent({
        ...eventEdit,
        image_id: imageIds,
        audiences: eventEdit?.audiences || [],
      });
      localStorage.setItem(
        "formData",
        JSON.stringify({
          ...eventEdit,
          image_id: imageIds,
          audiences: eventEdit?.audiences || [],
        })
      );
    }
  }, [slug]);

  useEffect(() => {
    state && setNavi(state);
  }, [pathname]);

  useEffect(() => {
    navi && navi === "addEvent" && window.scrollTo(3320, 3320);
    navi && navi === "addEvent" && setNavi(null);
  }, [navi]);

  useEffect(() => {
    if (newEvent.event_date_online === 1) {
      setNewEvent({
        ...newEvent,
        venue_id: "",
        scanner_id: [],
        point_of_sales_id: [],
      });
      console.log(newEvent.event_date_online);
    }
  }, [newEvent.event_date_online]);

  return (
    <>
      {newEvent && Object?.keys(newEvent)?.length > 0 && (
        <div className="card box">
          <div className="card-body">
            <div className="OrganizerProfileSection">
              <div className="signform mt-5 ">
                <div className="ProfileSettingOptionsContainer p-0">
                  <div className="mb-4">
                    <div className="mb-1">
                      <label
                        htmlFor="last_name"
                        className="form-label fw-600 m-0"
                      >
                        Categories<span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          Make sure to select right category to let the users
                          find it quickly
                        </span>
                      </div>
                      <SelectBox
                        name="event_categories_id"
                        options={event_categories}
                        val={newEvent?.event_categories_id}
                        change={handleSelectEvent}
                        ref={event_categories_idputRef}
                      />
                    </div>
                    {errors.event_categories_id && (
                      <small className="text-danger d-block">
                        {errors.event_categories_id}
                      </small>
                    )}
                  </div>

                  <div className="mb-4">
                    <label htmlFor="last_name" className="form-label fw-600">
                      Event details<span style={{ color: "red" }}>*</span>
                    </label>
                    <div>
                      <div className="mb-4">
                        <div className="name-section mb-1">
                          <label
                            htmlFor="name"
                            className="form-label fw-600 m-0"
                          >
                            Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="OrganizerOptionDescBox">
                            <FaCircleInfo className="OrganizerOptionDescIcon" />
                            <span className="OrganizerOptionDesc">
                              Editing the title after the event is saved won't
                              change the event url
                            </span>
                          </div>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className={`form-control ${
                              errors.name && "is-invalid"
                            }`}
                            onChange={handleEventChange}
                            value={newEvent?.name}
                            ref={nameInputRef}
                          />
                        </div>
                        {errors.name && (
                          <small className="text-danger d-block">
                            {errors.name}
                          </small>
                        )}
                      </div>
                      <div className="">
                        <div className="editor-section mb-1">
                          <label
                            htmlFor="description"
                            className="form-label fw-600 m-0"
                          >
                            Description
                          </label>
                          <ReactQuill
                            value={newEvent?.description}
                            onChange={handleEditor}
                            modules={modules}
                            ref={descriptionInputRef}
                          />
                        </div>
                        {errors.description && (
                          <small className="text-danger d-block">
                            {errors.description}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label
                        htmlFor="attendees_show"
                        className="form-label fw-600 m-0"
                      >
                        Attendees<span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          Show the attendees number and list in the event page
                        </span>
                      </div>

                      <div className="ProfileSettingRadioBtns">
                        <Radio
                          className="ProfileSettingRadioBtn fw-600"
                          name="attendees_show"
                          checked={newEvent.attendees_show === 1}
                          onChange={handleEventChange}
                          value={1}
                        >
                          Show
                        </Radio>
                        <Radio
                          className="ProfileSettingRadioBtn fw-600"
                          name="attendees_show"
                          checked={newEvent.attendees_show === 0}
                          value={0}
                          onChange={handleEventChange}
                        >
                          Hide
                        </Radio>
                      </div>
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label
                        htmlFor="attendees_show"
                        className="form-label fw-600 m-0"
                      >
                        Enable reviews<span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="ProfileSettingRadioBtns">
                        <Radio
                          className="ProfileSettingRadioBtn fw-600"
                          name="review"
                          checked={newEvent.review === 1}
                          onChange={handleEventChange}
                          value={1}
                        >
                          Enable
                        </Radio>
                        <Radio
                          className="ProfileSettingRadioBtn fw-600"
                          name="review"
                          checked={newEvent.review === 0}
                          value={0}
                          onChange={handleEventChange}
                        >
                          Disable
                        </Radio>
                      </div>
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label
                        htmlFor="languages"
                        className="form-label fw-600 m-0"
                      >
                        Languages
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          Select the languages that will be spoken in your event
                        </span>
                      </div>
                      <SelectBox
                        name="languages"
                        options={activeData.languages}
                        val={newEvent.languages}
                        change={handleSelectEvent}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-3">
                      <label
                        htmlFor="subtitles"
                        className="form-label fw-600 m-0"
                      >
                        Subtitle
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          If your event is a movie for example, select the
                          available subtitles
                        </span>
                      </div>
                      <MultiSelectBox
                        name="subtitles"
                        options={activeData.languages}
                        val={newEvent}
                        change={handleMultiSelectBox}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label htmlFor="year" className="form-label fw-600 m-0">
                        Year
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          If your event is a movie for example, select the year
                          of release
                        </span>
                      </div>
                      <SelectBox
                        name="year"
                        options={years}
                        val={newEvent.year}
                        change={handleSelectEvent}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label htmlFor="year" className="form-label fw-600 m-0">
                        Audiences
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          Select the audience types that are targeted in your
                          event
                        </span>
                      </div>
                      <div className="ProfileSettingRadioBtns text-capitalize">
                        {activeData.audiences?.map((curElem, index) => (
                          <Checkbox
                            key={index}
                            className="Checkbox fw-600"
                            value={curElem.id}
                            onChange={handleCheckboxChange}
                            checked={
                              newEvent?.audiences?.includes(
                                curElem.id.toString()
                              )
                                ? true
                                : false
                            }
                          >
                            {curElem.name}
                          </Checkbox>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label
                        htmlFor="last_name"
                        className="form-label fw-600 m-0"
                      >
                        Country
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          Select the country that your event represents (ie: A
                          movie's country of production)
                        </span>
                      </div>
                      <SelectBox
                        name="country"
                        options={activeData.countries}
                        val={newEvent.country}
                        change={handleSelectEvent}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label
                        htmlFor="youtube_video_url"
                        className="form-label fw-600 m-0"
                      >
                        Youtube video url
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          If you have an Youtube video that represents your
                          activities as an event organizer, add it in the
                          standard format:
                          https://www.youtube.com/watch?v=FzG4uDgje3M
                        </span>
                      </div>
                      <input
                        type="text"
                        name="youtube_video_url"
                        id="youtube_video_url"
                        className="form-control"
                        onChange={handleEventChange}
                        value={newEvent.youtube_video_url}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label
                        htmlFor="external_link"
                        className="form-label fw-600 m-0"
                      >
                        External link
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          If your event has a dedicated website, enter its url
                          here
                        </span>
                      </div>
                      <input
                        type="text"
                        name="external_link"
                        id="external_link"
                        className="form-control"
                        onChange={handleEventChange}
                        value={newEvent.external_link}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label
                        htmlFor="phone_number"
                        className="form-label fw-600 m-0"
                      >
                        Contact phone number
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          Enter the phone number to be called for inquiries
                        </span>
                      </div>
                      <input
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        className="form-control"
                        onChange={handleEventChange}
                        value={newEvent.phone_number}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label
                        htmlFor="contact_email"
                        className="form-label fw-600 m-0"
                      >
                        Contact email address
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          Enter the email address to be reached for inquiries
                        </span>
                      </div>
                      <input
                        type="text"
                        name="contact_email"
                        id="contact_email"
                        className="form-control"
                        autoComplete="country"
                        onChange={handleEventChange}
                        value={newEvent.contact_email}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label htmlFor="twitter" className="form-label fw-600 ">
                        Twitter
                      </label>
                      <input
                        type="text"
                        name="twitter"
                        id="twitter"
                        className="form-control"
                        onChange={handleEventChange}
                        value={newEvent.twitter}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label htmlFor="instagram" className="form-label fw-600">
                        Instagram
                      </label>
                      <input
                        type="text"
                        name="instagram"
                        id="instagram"
                        className="form-control"
                        onChange={handleEventChange}
                        value={newEvent.instagram}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label htmlFor="facebook" className="form-label fw-600 ">
                        Facebook
                      </label>
                      <input
                        type="text"
                        name="facebook"
                        id="facebook"
                        className="form-control"
                        onChange={handleEventChange}
                        value={newEvent.facebook}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label
                        htmlFor="google_plus"
                        className="form-label fw-600"
                      >
                        Google plus
                      </label>
                      <input
                        type="text"
                        name="google_plus"
                        id="google_plus"
                        className="form-control"
                        onChange={handleEventChange}
                        value={newEvent.google_plus}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="mb-1">
                      <label htmlFor="linkedIn" className="form-label fw-600">
                        LinkedIn
                      </label>
                      <input
                        type="text"
                        name="linkedIn"
                        id="linkedIn"
                        className="form-control"
                        onChange={handleEventChange}
                        value={newEvent.linkedIn}
                      />
                    </div>
                  </div>

                  {/* artista in array  */}
                  <div className="mb-4">
                    <div className="mb-1">
                      <label
                        htmlFor="artists"
                        className="form-label fw-600 m-0"
                      >
                        Artist
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          Enter the list of artists that will perform in your
                          event (press Enter after each entry)
                        </span>
                      </div>
                      <AddInputTags
                        state={newEvent}
                        setSate={setNewEvent}
                        name="artists"
                      />
                    </div>
                  </div>
                  {/* tags in array */}
                  <div className="mb-4">
                    <div className="mb-1">
                      <label htmlFor="tags" className="form-label fw-600 m-0">
                        Tags
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          To help attendee find your event quickly, enter some
                          keywords that identify your event (press Enter after
                          each entry)
                        </span>
                      </div>
                      <AddInputTags
                        state={newEvent}
                        setSate={setNewEvent}
                        name="tags"
                      />
                    </div>
                  </div>
                  {/* images */}
                  <div className="mb-4">
                    <div className="mb-3">
                      <label
                        htmlFor="main_event_image"
                        className="form-label fw-600 m-0"
                      >
                        Main event image<span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          Choose the right image to represent your event (We
                          recommend using at least a 1200x600px (2:1 ratio)
                          image )
                        </span>
                      </div>
                      <input
                        type="file"
                        name="main_event_image"
                        id="main_event_image"
                        className="form-control"
                        onChange={handleIagesChange}
                        ref={main_event_imageInputRef}
                      />
                      {errors.main_event_image && (
                        <small className="text-danger d-block mt-1">
                          {errors.main_event_image}
                        </small>
                      )}

                      {slug && newEvent?.main_event_image?.name && (
                        <Link
                          className="fancybox"
                          to={URL.createObjectURL(newEvent.main_event_image)}
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Enlarge"
                          target="_blank"
                        >
                          <img
                            src={URL.createObjectURL(newEvent.main_event_image)}
                            className="img-thumbnail re_ent_img mt-2"
                            alt={newEvent.main_event_image.path}
                          />
                        </Link>
                      )}

                      {slug &&
                        newEvent?.main_event_image &&
                        !newEvent?.main_event_image?.name && (
                          <Link
                            className="fancybox"
                            to={`${server}/event/image/${newEvent.main_event_image}`}
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Enlarge"
                            target="_blank"
                          >
                            <img
                              src={`${server}/event/image/${newEvent.main_event_image}`}
                              className="img-thumbnail re_ent_img mt-2"
                              alt={newEvent.main_event_image}
                            />
                          </Link>
                        )}
                    </div>

                    <div className="">
                      <label htmlFor="images" className="form-label fw-600 m-0">
                        Images gallery
                      </label>
                      <div className="OrganizerOptionDescBox">
                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                        <span className="OrganizerOptionDesc">
                          Add other images that represent your event to be
                          displayed as a gallery
                        </span>
                      </div>

                      {imagesCard?.map((image, index) => {
                        return (
                          <div className="card mb-3" key={image.id}>
                            <div className="card-body">
                              <div className="mb-1">
                                <label
                                  htmlFor="images"
                                  className="form-label fw-600"
                                >
                                  Gallery image
                                </label>
                                <input
                                  type="file"
                                  name="images"
                                  id="images"
                                  className="form-control mb-2"
                                  onChange={handleIagesChange}
                                />
                              </div>
                              {slug && image?.image && (
                                <Link
                                  className="fancybox"
                                  to={`${server}/event/gallery/${image?.image}`}
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Enlarge"
                                  target="_blank"
                                >
                                  <img
                                    src={`${server}/event/gallery/${image?.image}`}
                                    className="img-thumbnail re_ent_img"
                                    alt={image.name}
                                  />
                                </Link>
                              )}
                              <div className="mt-4">
                                <button
                                  className="d-flex align-items-center  btn btn-outline-dark btn-sm rounded-5"
                                  onClick={(e) =>
                                    removeImageCard(e, image.id, index)
                                  }
                                >
                                  <FaMinusSquare className="me-1" />
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <div className="mt-3">
                        <button
                          className="d-flex align-items-center  btn btn-outline-dark btn-sm rounded-5"
                          onClick={addImageCard}
                        >
                          <FaPlusSquare className="me-1" />
                          Add
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <label className="form-label fw-600">
                      Events dates<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="">
                          <div className="mb-4">
                            <label
                              htmlFor="enable_event_date"
                              className="form-label fw-600 m-0"
                            >
                              Enable event date ?
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="OrganizerOptionDescBox">
                              <FaCircleInfo className="OrganizerOptionDescIcon" />
                              <span className="OrganizerOptionDesc">
                                Enabling ticket discounts for an event date does
                                not affect the tickets individual sale status
                              </span>
                            </div>
                            <div className="ProfileSettingRadioBtns">
                              <Radio
                                className="ProfileSettingRadioBtn fw-600"
                                name="enable_event_date"
                                checked={newEvent.enable_event_date === 1}
                                value={1}
                                onChange={handleEventChange}
                              >
                                Yes
                              </Radio>
                              <Radio
                                className="ProfileSettingRadioBtn fw-600"
                                name="enable_event_date"
                                checked={newEvent.enable_event_date === 0}
                                value={0}
                                onChange={handleEventChange}
                              >
                                No
                              </Radio>
                            </div>
                          </div>

                          <div className="mb-4">
                            <label
                              htmlFor="start_On"
                              className="form-label fw-600"
                            >
                              Starts On <span style={{ color: "red" }}>*</span>
                            </label>
                            <Space
                              direction="vertical"
                              size={12}
                              onClick={(e) => {
                                // e.stopPropagation();
                                // setToggle(false);
                              }}
                              className="d-block"
                            >
                              <DatePicker
                                format="YYYY-MM-DD HH:mm:ss"
                                className="form-control"
                                value={
                                  newEvent?.start_On
                                    ? dayjs(newEvent?.start_On)
                                    : null
                                }
                                showTime={{
                                  defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                                }}
                                onChange={(_, dateString) =>
                                  handleDateChange("start_On", dateString)
                                }
                                ref={start_OnInputRef}
                              />
                            </Space>

                            {errors.start_On && (
                              <small className="text-danger d-block mt-1">
                                {errors.start_On}
                              </small>
                            )}
                          </div>

                          <div className="mb-4">
                            <label
                              htmlFor="end_On"
                              className="form-label fw-600"
                            >
                              Ends On
                            </label>
                            <Space
                              direction="vertical"
                              size={12}
                              onClick={(e) => {
                                // e.stopPropagation();
                                // setToggle(false);
                              }}
                              className="d-block"
                            >
                              <Space
                                direction="vertical"
                                size={12}
                                onClick={(e) => {
                                  // e.stopPropagation();
                                  // setToggle(false);
                                }}
                                className="d-block"
                              >
                                <DatePicker
                                  format="YYYY-MM-DD HH:mm:ss"
                                  className="form-control"
                                  value={
                                    newEvent?.end_On
                                      ? dayjs(newEvent?.end_On)
                                      : null
                                  }
                                  showTime={{
                                    defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                                  }}
                                  onChange={(_, dateString) =>
                                    handleDateChange("end_On", dateString)
                                  }
                                />
                              </Space>
                            </Space>
                          </div>

                          <div className="mb-4">
                            <label
                              htmlFor="enable_event_date"
                              className="form-label fw-600"
                            >
                              Is this event date online ?
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="ProfileSettingRadioBtns">
                              <Radio
                                className="ProfileSettingRadioBtn fw-600"
                                name="event_date_online"
                                checked={newEvent.event_date_online === 1}
                                value={1}
                                onChange={handleEventChange}
                              >
                                Yes
                              </Radio>
                              <Radio
                                className="ProfileSettingRadioBtn fw-600"
                                name="event_date_online"
                                checked={newEvent.event_date_online === 0}
                                value={0}
                                onChange={handleEventChange}
                              >
                                No
                              </Radio>
                            </div>
                          </div>

                          {newEvent?.event_date_online === 0 && (
                            <>
                              <div className="mb-4">
                                <label
                                  htmlFor="last_name"
                                  className="form-label fw-600"
                                >
                                  Venue<span style={{ color: "red" }}>*</span>
                                </label>
                                <SelectBox
                                  name="venue_id"
                                  options={activeData.venues}
                                  val={newEvent.venue_id}
                                  change={handleSelectEvent}
                                  ref={venue_idInputRef}
                                />
                                {errors.venue_id && (
                                  <small className="text-danger d-block mt-1">
                                    {errors.venue_id}
                                  </small>
                                )}
                                <div className="mb-2 mt-3">
                                  <Link
                                    to=""
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate(
                                        "/en/dashboard/organizer/my-venues/add",
                                        {
                                          state: "addEvent",
                                        }
                                      );
                                    }}
                                    className="btn btn-dark rounded-5"
                                    style={{ fontSize: "12px" }}
                                  >
                                    ADD VENUE
                                  </Link>
                                </div>
                              </div>

                              <div className="mb-4">
                                <div className="mb-1">
                                  <label
                                    htmlFor="scanners"
                                    className="form-label fw-600"
                                  >
                                    Scanners
                                  </label>
                                  <MultiSelectBox
                                    name="scanner_id"
                                    options={activeData.scanners}
                                    val={newEvent}
                                    change={handleMultiSelectBox}
                                  />
                                </div>
                              </div>

                              <div className="mb-4">
                                <div className="mb-1">
                                  <label
                                    htmlFor="point_of_sales_id"
                                    className="form-label fw-600"
                                  >
                                    Point of sale
                                  </label>
                                  <MultiSelectBox
                                    name="point_of_sales_id"
                                    options={activeData.sales}
                                    val={newEvent}
                                    change={handleMultiSelectBox}
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          {/* event tickets */}
                          <div className="">
                            <label className="form-label fw-600 ">
                              Event tickets
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            {evTicket?.map((curElem) => {
                              return (
                                <div className="card mb-3" key={curElem.id}>
                                  <div className="card-body">
                                    <div className="mb-4">
                                      <label
                                        htmlFor="enable_sales_ticket"
                                        className="form-label fw-600"
                                      >
                                        Enable sales for this ticket ?
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <div className="ProfileSettingRadioBtns">
                                        <Radio
                                          className="ProfileSettingRadioBtn fw-600"
                                          name="enable_sales_ticket"
                                          checked={
                                            curElem.enable_sales_ticket === 1
                                          }
                                          value={1}
                                          onChange={(e) =>
                                            handleNewTicketToEvent(
                                              e,
                                              curElem.id
                                            )
                                          }
                                        >
                                          Yes
                                        </Radio>
                                        <Radio
                                          className="ProfileSettingRadioBtn fw-600"
                                          name="enable_sales_ticket"
                                          checked={
                                            curElem.enable_sales_ticket === 0
                                          }
                                          value={0}
                                          onChange={(e) =>
                                            handleNewTicketToEvent(
                                              e,
                                              curElem.id
                                            )
                                          }
                                        >
                                          No
                                        </Radio>
                                      </div>
                                    </div>

                                    <div className="mb-4">
                                      <label
                                        htmlFor="ticket_name"
                                        className="form-label fw-600 m-0"
                                      >
                                        Ticket name
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <div className="OrganizerOptionDescBox">
                                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                                        <span className="OrganizerOptionDesc">
                                          Early bird, General admission, VIP...
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        name="ticket_name"
                                        id="ticket_name"
                                        className="form-control"
                                        onChange={(e) =>
                                          handleNewTicketToEvent(e, curElem.id)
                                        }
                                        value={curElem.ticket_name}
                                        ref={ticket_nameInputRef}
                                      />
                                      {errors.ticket_name && (
                                        <small className="text-danger d-block mt-1">
                                          {errors.ticket_name}
                                        </small>
                                      )}
                                    </div>

                                    <div className="mb-4">
                                      <label
                                        htmlFor="ticket_description"
                                        className="form-label fw-600 m-0"
                                      >
                                        Ticket description
                                      </label>
                                      <div className="OrganizerOptionDescBox">
                                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                                        <span className="OrganizerOptionDesc">
                                          Tell your attendees more about this
                                          ticket type
                                        </span>
                                      </div>
                                      <textarea
                                        name="ticket_description"
                                        id="ticket_description"
                                        className="form-control"
                                        onChange={(e) =>
                                          handleNewTicketToEvent(e, curElem.id)
                                        }
                                        value={curElem.ticket_description}
                                      ></textarea>
                                    </div>

                                    <div className="mb-4">
                                      <label
                                        htmlFor="ticket_description"
                                        className="form-label fw-600"
                                      >
                                        Is this ticket free ?
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <div className="ProfileSettingRadioBtns">
                                        <Radio
                                          className="ProfileSettingRadioBtn"
                                          name="ticket_free"
                                          checked={curElem.ticket_free === 0}
                                          value={0}
                                          onChange={(e) =>
                                            handleNewTicketToEvent(
                                              e,
                                              curElem.id
                                            )
                                          }
                                        >
                                          No
                                        </Radio>
                                        <Radio
                                          className="ProfileSettingRadioBtn"
                                          name="ticket_free"
                                          checked={curElem.ticket_free === 1}
                                          value={1}
                                          onChange={(e) =>
                                            handleNewTicketToEvent(
                                              e,
                                              curElem.id
                                            )
                                          }
                                        >
                                          Yes
                                        </Radio>
                                      </div>
                                    </div>

                                    {curElem.ticket_free === 0 && (
                                      <>
                                        <div className="mb-4">
                                          <label
                                            htmlFor="Price"
                                            className="form-label fw-600"
                                          >
                                            Price
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                          <Count
                                            name="Price"
                                            value={curElem.Price}
                                            state="newEvent"
                                            setState={setNewEvent}
                                            icon={true}
                                            id={curElem.id}
                                            ref={PriceInputRef}
                                          />
                                          {errors.Price && (
                                            <small className="text-danger d-block mt-1">
                                              {errors.Price}
                                            </small>
                                          )}
                                        </div>

                                        <div className="mb-4">
                                          <label
                                            htmlFor="discount"
                                            className="form-label fw-600 m-0"
                                          >
                                            Ticket discount price
                                          </label>
                                          <div className="OrganizerOptionDescBox">
                                            <FaCircleInfo className="OrganizerOptionDescIcon" />
                                            <span className="OrganizerOptionDesc">
                                              Set a price lesser than than the
                                              original price to indicate a
                                              promotion (this price will be the
                                              ticket discount price)
                                            </span>
                                          </div>
                                          <Count
                                            name="discount"
                                            icon={true}
                                            value={curElem.discount}
                                            state="newEvent"
                                            setState={setNewEvent}
                                            id={curElem.id}
                                          />
                                        </div>
                                      </>
                                    )}

                                    <div className="mb-4">
                                      <label
                                        htmlFor="quantity"
                                        className="form-label fw-600"
                                      >
                                        Quantity - (Amount of tickets available
                                        to be sold)
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <Count
                                        name="quantity"
                                        value={curElem.quantity}
                                        state="newEvent"
                                        setState={setNewEvent}
                                        id={curElem.id}
                                        ref={quantityInputRef}
                                      />
                                      {errors.quantity && (
                                        <small className="text-danger d-block mt-1">
                                          {errors.quantity}
                                        </small>
                                      )}
                                    </div>
                                    <div className="mb-4">
                                      <label
                                        htmlFor="tickets_per_attendee"
                                        className="form-label fw-600"
                                      >
                                        Tickets per attendee
                                      </label>
                                      <div className="OrganizerOptionDescBox">
                                        <FaCircleInfo className="OrganizerOptionDescIcon" />
                                        <span className="OrganizerOptionDesc">
                                          Set the number of tickets that an
                                          attendee can buy for this ticket type.
                                          Leave Blank if event is free or
                                          unlimited tickets.
                                        </span>
                                      </div>
                                      <Count
                                        name="tickets_per_attendee"
                                        value={curElem.tickets_per_attendee}
                                        state="newEvent"
                                        setState={setNewEvent}
                                        id={curElem.id}
                                      />
                                    </div>

                                    <div className="mb-4">
                                      <label
                                        htmlFor="sale_start"
                                        className="form-label fw-600"
                                      >
                                        Ticket sale starts On
                                      </label>
                                      <Space
                                        direction="vertical"
                                        size={12}
                                        onClick={(e) => {
                                          // e.stopPropagation();
                                          // setToggle(false);
                                        }}
                                        className="d-block"
                                      >
                                        <DatePicker
                                          format="YYYY-MM-DD HH:mm:ss"
                                          className="form-control"
                                          value={
                                            curElem?.sale_start
                                              ? dayjs(curElem?.sale_start)
                                              : null
                                          }
                                          showTime={{
                                            defaultValue: dayjs(
                                              "00:00:00",
                                              "HH:mm:ss"
                                            ),
                                          }}
                                          onChange={(_, dateString) =>
                                            handleNewTicketDate(
                                              dateString,
                                              "sale_start",
                                              curElem.id
                                            )
                                          }
                                        />
                                      </Space>
                                    </div>
                                    <div className="mb-4">
                                      <label
                                        htmlFor="sale_end"
                                        className="form-label fw-600"
                                      >
                                        Ticket sale ends On
                                      </label>
                                      <Space
                                        direction="vertical"
                                        size={12}
                                        onClick={(e) => {
                                          // e.stopPropagation();
                                          // setToggle(false);
                                        }}
                                        className="d-block"
                                      >
                                        <DatePicker
                                          format="YYYY-MM-DD HH:mm:ss"
                                          className="form-control"
                                          value={
                                            curElem.sale_end
                                              ? dayjs(curElem.sale_end)
                                              : null
                                          }
                                          showTime={{
                                            defaultValue: dayjs(
                                              "00:00:00",
                                              "HH:mm:ss"
                                            ),
                                          }}
                                          onChange={(_, dateString) =>
                                            handleNewTicketDate(
                                              dateString,
                                              "sale_end",
                                              curElem.id
                                            )
                                          }
                                        />
                                      </Space>
                                    </div>

                                    <div className="mt-4">
                                      <button
                                        className={` ${
                                          evTicket.length === 1
                                            ? "d-none"
                                            : "d-flex"
                                        } align-items-center  btn btn-outline-dark btn-sm rounded-5`}
                                        onClick={(e) =>
                                          removeTicketCardToEventCard(
                                            e,
                                            curElem.id
                                          )
                                        }
                                      >
                                        <FaMinusSquare className="me-1" />
                                        Remove this ticket
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}

                            {/* <div className="mt-4">
                              <button
                                className="d-flex align-items-center btn btn-outline-dark btn-sm rounded-5"
                                onClick={addNewTicketCardToEventCard}
                              >
                                <FaPlusSquare className="me-1" />
                                ADD A new ticket
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-dark rounded-5"
                      type="button"
                      disabled={isPanding ? "disabled" : ""}
                      onClick={addNewEvent}
                    >
                      {isPanding ? <FaSyncAlt className="spinIcon" /> : "SAVE"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddEvent;
