import React from "react";
import { FaInfo } from "react-icons/fa";

const Alert = ({ icon, type, text }) => {
  return (
    <div className={`alert ${type}  alert-icon`}>
      {icon} {text}
    </div>
  );
};

export default Alert;
