import React from "react";
import { Link } from "react-router-dom";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import UserReviews from "../../../../reviews/UserReviews";

const ReviewCard = ({ review }) => {
  const server = process.env.REACT_APP_SERVER;
  return (
    <div className="row mb-4">
      <div className="col">
        <div className="user-review-wrapper mb-5">
          <Link to={`/en/event/${review.event.slug}`}>
            <img
              src={`${
                review.event.main_event_image
                  ? `${server}/event/image/${review.event.main_event_image}`
                  : "/./images/noPhoto.png"
              } `}
              className="img-thumbnail re_ent_img mb-2"
              alt={`Event thumbnail for ${review?.event?.name}`}
            />
            {review?.event?.name}
          </Link>

          <UserReviews review={review} />
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
