import { FaSyncAlt } from "react-icons/fa";
import "./style.css";
import Table from "./Table";
import Filters from "./Filters";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../../../store/slice/Organizer";
import { useEffect } from "react";

const MyEvent = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);
  const { events, event_categories, venues, loading, error } = useSelector(
    (state) => state.organizer
  );
  const { filter_data } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    dispatch(
      fetchData({
        getData: "organizer/payout_method_requests",
        token: user.token,
        state: "payout_request",
      })
    );
  }, []);

  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event">
        <Filters
          events={events}
          event_categories={event_categories}
          venues={venues}
          btn="yes"
        />
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table data={filter_data.events} />
      )}
    </>
  );
};

export default MyEvent;
