import { Radio, Tooltip } from "antd";
import { Table } from "./Table";
import TopSection from "../TopSection";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminFetchData } from "../../../../../store/slice/Admin";
import { FaPlus, FaSyncAlt } from "react-icons/fa";
import {
  addOrganizerFilters,
  clearAllFilters,
  loadFilterOrganizer,
  sorted,
  sorting,
} from "../../../../../store/slice/OrganizerFilters";
import SelectBox from "../../../../global/SelectBox";
import { Link } from "react-router-dom";

const Articles = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);
  const { articles, loading } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(
      adminFetchData({
        endPoint: "admin/article_categories",
        state: "article_categories",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/articles",
        state: "articles",
        token: user.token,
      })
    );
  }, []);

  const {
    filters: { keyword },
    sorting_value,
    filter_data,
  } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    dispatch(
      loadFilterOrganizer({
        data: articles,
        key: "articles",
      })
    );
  }, [dispatch, articles]);

  useEffect(() => {
    dispatch(clearAllFilters());
  }, []);

  useEffect(() => {
    dispatch(sorted("articles"));
  }, [sorting_value]);

  const add_filter = (e) => {
    const { name, value } = e.target;
    dispatch(addOrganizerFilters({ name, value }));
  };

  // Search Filter
  const add_filter3 = (name, value) => {
    dispatch(sorting({ name, value }));
  };
  // Sorting select Box options
  const options = [
    { id: "date-desc", name: "Creation date (desc)" },
    { id: "date-asc", name: "Creation date (asc)" },
  ];

  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event MyScanners">
        <div className="row">
          <div className="col-sm-12 col-lg-2 text-center text-lg-start mb-3 mb-lg-0 align-self-center">
            <span className="center-lg-y text-muted">
              {filter_data.articles?.length} result(s) found
            </span>
          </div>
          <div className="col-sm-12 col-lg-4 d-flex align-items-center justify-content-center">
            <ul className="d-flex">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Search
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <div className="signform">
                      <label htmlFor="keyword" className="form-label dashboard">
                        Keyword
                      </label>
                      <input
                        type="text"
                        name="keyword"
                        className="form-control"
                        id="keyword"
                        value={keyword}
                        onChange={add_filter}
                      />
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-lg-6 d-flex align-items-center justify-content-around">
            <form className="text-start d-flex align-items-center">
              <label htmlFor="sort" className="">
                Sort by{" "}
              </label>
              <SelectBox
                name="sorting_value"
                options={options}
                val={sorting_value}
                change={add_filter3}
              />
            </form>
            <Tooltip title="Add a new Articale">
              <Link
                to="add"
                className="btn btn-dark ms-3"
                style={{ fontSize: "12px" }}
              >
                <FaPlus />
              </Link>
            </Tooltip>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table articles={filter_data.articles} />
      )}
    </>
  );
};

export default Articles;
