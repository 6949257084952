import React, { useEffect, useState } from "react";
import CheckOut from "../../components/Admin/dashboard/attendee/cart/CheckOut";
import { Layout } from "../../components/LayoutUser/Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addPaymentSettings } from "../../store/slice/Cart";

const Checkout = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { cart, tickets, payment_settings } = useSelector(
    (state) => state.CartDetails
  );
  const [ticket, setTicket] = useState({});
  const { user } = useSelector((state) => state.authUser);

  useEffect(() => {
    dispatch(
      addPaymentSettings({
        fees: payment_settings?.ticket_fee_percentage,
        tax: payment_settings?.ticket_fee,
      })
    );
  }, []);
  useEffect(() => {
    if (slug) {
      const tick = tickets.find((curElem) => curElem.reference === slug);

      setTicket({
        cart: tick ? [tick] : [],
        checkout_reference: tick?.checkout_reference || "",
      });
    }
  }, [slug]);
  useEffect(() => {
    if (state?.checkout) {
      setTicket({
        cart,
        checkout_reference: state?.checkout?.[0]?.checkout_reference,
      });
    }
  }, [state]);

  useEffect(() => {
    if ((!slug && !state?.checkout) || ticket?.cart?.length === 0) {
      if (user && Object.keys(user).length > 0) {
        user.role.some((role) => role.name === "organizer") &&
          navigate("/en/dashboard/organizer/my-tickets");
        user.role.some((role) => role.name === "attendee") &&
          navigate("/en/dashboard/attendee/my-tickets");
      } else {
        navigate("/");
      }
    }
  }, [slug, state, ticket, user, navigate]);

  return (
    <>
      {(slug || state?.checkout) && (
        <Layout>
          <CheckOut data={ticket} />{" "}
        </Layout>
      )}
    </>
  );
};

export default Checkout;
