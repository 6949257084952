import React from "react";
import AdminSidebar from "./AdminSidebar/AdminSidebar";
import { Outlet } from "react-router-dom";
import { Layout } from "../../../LayoutUser/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminFetchData } from "../../../../store/slice/Admin";
import { fetchData } from "../../../../store/slice/Organizer";
import {
  filteredAdminARTICAL,
  filteredAdminARTICALCategory,
  filteredAdminAmenitie,
  filteredAdminBlog,
  filteredAdminBlogCategory,
  filteredAdminCounrty,
  filteredAdminData,
  filteredAdminLanguage,
  filteredAdminPaypal,
  filteredAdminUser,
  filteredAdminaAudience,
  filteredAdminaVenueType,
  filteredOrganizerEvents,
  filteredOrganizerOrders,
  filteredOrganizerPayoutRequests,
  filteredOrganizerReports,
  filteredOrganizerReviews,
  filteredOrganizerVenues,
} from "../../../../store/slice/OrganizerFilters";

const Administrator = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);

  useEffect(() => {
    dispatch(
      adminFetchData({
        endPoint: "admin/event_categories",
        state: "event_categories",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/events",
        state: "events",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/orders",
        state: "orders",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/venues",
        state: "venues",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/amenities",
        state: "amenities",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/venue_types",
        state: "venue_types",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/audiences",
        state: "audiences",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/countries",
        state: "countries",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/languages",
        state: "languages",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/reports",
        state: "reports",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/users",
        state: "users",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/blog_categories",
        state: "blog_categories",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/blogs",
        state: "blogs",
        token: user.token,
      })
    );
    dispatch(
      fetchData({
        getData: "admin/venue_types",
        token: user.token,
        state: "venue_types",
      })
    );
    dispatch(
      fetchData({
        getData: "admin/countries",
        token: user.token,
        state: "countries",
      })
    );
    dispatch(
      fetchData({
        getData: "admin/amenities",
        token: user.token,
        state: "amenities",
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/news_litter",
        state: "news_litter",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/mail_server",
        state: "mail_server",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/blog_setting",
        state: "blog_setting",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/venue_page_settings",
        state: "venue_page_settings",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/event_setting",
        state: "event_setting",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/social_login_settings",
        state: "social_login_settings",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/google_maps_settings",
        state: "google_maps_settings",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/google_repatcha_settings",
        state: "google_repatcha_settings",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/time_left",
        state: "time_left",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/payment_fees",
        state: "payment_fees",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/home_page_setting",
        state: "home_page_setting",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/layout_settings",
        state: "layout_settings",
        token: user.token,
      })
    );
    dispatch(
      adminFetchData({
        endPoint: "admin/organizers",
        state: "organizers",
        token: user.token,
      })
    );
  }, []);

  const { filters } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    dispatch(filteredOrganizerEvents());
    dispatch(filteredOrganizerVenues());
    dispatch(filteredOrganizerReviews());
    dispatch(filteredOrganizerReports());
    dispatch(filteredOrganizerPayoutRequests());
    dispatch(filteredOrganizerOrders());
    dispatch(filteredAdminData());
    dispatch(filteredAdminCounrty());
    dispatch(filteredAdminLanguage());
    dispatch(filteredAdminaAudience());
    dispatch(filteredAdminaVenueType());
    dispatch(filteredAdminAmenitie());
    dispatch(filteredAdminBlog());
    dispatch(filteredAdminBlogCategory());
    dispatch(filteredAdminUser());
    dispatch(filteredAdminARTICALCategory());
    dispatch(filteredAdminARTICAL());
    dispatch(filteredAdminPaypal());
    window.scrollTo(0, 0);
  }, [filters]);

  return (
    <Layout>
      <div className="container attendeeSidebar">
        <div className="row">
          <aside className="col-lg-3 pt-3 pt-lg-0 d-lg-block d-none">
            <AdminSidebar />
          </aside>
          <div className="col-lg-9 mt-4 mt-lg-0">
            <Outlet />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Administrator;
