import React from "react";
import { FaSyncAlt } from "react-icons/fa";
import { useAddEntity } from "../../../../../utils/CustomFunctions";
import AddInputTags from "../../organizer/AddTags/AddInputTags";
import SelectBox from "../../../../global/SelectBox";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";

const AddArticles = () => {
  const { article_categories } = useSelector((state) => state.admin);

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  const modules = {
    toolbar: toolbarOptions,
  };

  const initialState = {
    name: "",
    tags: [],
    description: "",
    article_category_id: "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    if (!values.name) newErrors.name = "This value should not be null.";
    if (!values.article_category_id)
      newErrors.article_category_id = "This value should not be null.";
    if (!values.description)
      newErrors.description = "This value should not be null.";
    if (!values.tags || !values.tags.length > 0)
      newErrors.tags = "This value should not be null. Add atleast one tag.";
    return newErrors;
  };
  const {
    isPending,
    errors,
    newEntity,
    setNewEntity,
    handleChange,
    handleSelectBox,
    handleEditor,
    handleSubmit,
  } = useAddEntity(
    "admin",
    "article",
    "articles",
    initialState,
    validateFields,
    "/en/dashboard/administrator/manage-help-center/articles"
  );

  return (
    <div className="card">
      <div className="card-body">
        <div className="signform">
          <div className="mb-4">
            <div className="mb-1">
              <label htmlFor="name" className="form-label fw-600">
                Category<span style={{ color: "red" }}>*</span>
              </label>
              <SelectBox
                name="article_category_id"
                options={article_categories}
                val={newEntity.article_category_id}
                change={handleSelectBox}
              />
            </div>
            {errors.article_category_id && (
              <small className="text-danger d-block">
                {errors.article_category_id}
              </small>
            )}
          </div>
          <div className="mb-4">
            <div className="mb-1">
              <label htmlFor="name" className="form-label fw-600">
                Title<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control"
                value={newEntity?.name}
                onChange={handleChange}
              />
            </div>
            {errors.name && (
              <small className="text-danger d-block">{errors.name}</small>
            )}
          </div>
          <div className="mb-4">
            <div className="mb-1">
              <label htmlFor="description" className="form-label fw-600">
                Content<span style={{ color: "red" }}>*</span>
              </label>
              <ReactQuill
                value={newEntity?.description}
                onChange={handleEditor}
                modules={modules}
              />
            </div>
            {errors.description && (
              <small className="text-danger d-block">
                {errors.description}
              </small>
            )}
          </div>
          <div className="mb-4">
            <div className="mb-1">
              <label htmlFor="tags" className="form-label fw-600">
                Tags
                <span style={{ color: "red" }}>*</span>
              </label>
              <AddInputTags
                state={newEntity}
                setSate={setNewEntity}
                name="tags"
              />
            </div>
            {errors.tags && (
              <small className="text-danger d-block">{errors.tags}</small>
            )}
          </div>

          <div className="form-group mt-3 mb-3">
            <button
              className="btn btn-dark rounded-5"
              disabled={isPending ? "disabled" : ""}
              type="button"
              onClick={handleSubmit}
            >
              {isPending ? <FaSyncAlt className="spinIcon" /> : "save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddArticles;
