import Breadcrumb from "../../global/Breadcrumb3";
import { FaInfoCircle } from "react-icons/fa";
import "./style.css";
import { Link, useParams } from "react-router-dom";
import { Layout } from "../../LayoutUser/Layout";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { IconPickerItem } from "react-fa-icon-picker";
import Alert from "../../global/alert/Alert";

const Support = () => {
  const { slug } = useParams();
  const { articles, article_categories } = useSelector(
    (state) => state.AllData
  );
  const [articals, setArticals] = useState([]);
  const [articleCategorY, setArticleCategorY] = useState(null);

  useEffect(() => {
    if (slug) {
      const foundArticle =
        articles.filter((article) => article.article_category.slug === slug) ||
        [];
      const foundArticleC = article_categories.find((cat) => cat.slug === slug);
      setArticals(foundArticle);
      setArticleCategorY(foundArticleC || null);
    }
  }, [slug, articles]);

  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: `No help center ${slug} articles found`,
  };
  const msg2 = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No articles found in this category ",
  };

  return (
    <Layout>
      <Breadcrumb
        page={`Support for ${articleCategorY ? articleCategorY?.name : ""}`}
        link="help-center"
      />
      <div className="container support-section">
        <div className="row">
          <div className="col-sm-12">
            {articleCategorY ? (
              <div className="card box">
                <div className="card-body">
                  <article className="h-100">
                    <figure className="itembox text-center">
                      <span className="mt-2 icon-wrap rounded icon-lg bg-dark">
                        {articleCategorY?.icon && (
                          <IconPickerItem
                            icon={articleCategorY?.icon}
                            size={24}
                            color="white"
                          />
                        )}
                      </span>
                      <figcaption className="text-wrap">
                        <h5 className="title">{articleCategorY?.name}</h5>
                      </figcaption>
                    </figure>

                    <hr />
                    {articals?.length > 0 ? (
                      articals.map((artical, i) => (
                        <p key={i}>
                          <Link to={`/en/help-center/article/${artical.slug}`}>
                            {artical.name}
                          </Link>
                        </p>
                      ))
                    ) : (
                      <Alert {...msg} />
                    )}
                  </article>
                </div>
              </div>
            ) : (
              <Alert {...msg2} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Support;
