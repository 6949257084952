import { Pagination, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { FaExclamationCircle, FaSyncAlt } from "react-icons/fa";
import { adminFetchData } from "../../../../../store/slice/Admin";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../../../global/alert/Alert";
import ReviewCard from "./ReviewCard";
import Filters from "../../organizer/reviews/Filters";

const AdminReviews = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);
  const { reviews, events, loading } = useSelector((state) => state.admin);
  const { filter_data } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    dispatch(
      adminFetchData({
        endPoint: "admin/reviews",
        state: "reviews",
        token: user.token,
      })
    );
  }, []);

  const msg = {
    icon: <FaExclamationCircle className="me-3" />,
    type: "alert-info",
    text: "No reviews found",
  };

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = filter_data?.event_reviews?.slice(
    indexOfFirstData,
    indexOfLastData
  );

  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event payoutRequests">
        <Filters event_reviews={reviews} events={events} />
      </div>

      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : !filter_data?.event_reviews?.length > 0 ? (
        <Alert {...msg} />
      ) : (
        <div className="card review">
          <div className="card-body">
            {currentData?.map((review, index) => {
              return <ReviewCard review={review} key={index} />;
            })}
          </div>
        </div>
      )}

      {filter_data?.event_reviews?.length > 10 && (
        <Pagination
          current={currentPage}
          total={filter_data?.event_reviews.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};

export default AdminReviews;
