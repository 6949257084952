import React from "react";
import html2pdf from "html2pdf.js";
import { Link, useLocation } from "react-router-dom";
import { formatDate } from "../../../../utils/CustomFunctions";
import Alert from "../../../global/alert/Alert";
import { FaExclamationCircle } from "react-icons/fa";
import { countries } from "./../../../global/Values";

const PaymentDetailsPDF = () => {
  const { state } = useLocation();
  const data = state?.data || [];

  const oncl = () => {
    const element = document.getElementById("contentToConvert");
    // html2pdf().from(element).save();
    html2pdf()
      .from(element)
      .outputPdf()
      .then(function (pdf) {
        // Create a Blob from the PDF data
        var base64 = btoa(pdf);
        const blob = base64ToBlob(base64, "application/pdf");
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");

        function base64ToBlob(base64, type = "application/octet-stream") {
          const binStr = atob(base64);
          const len = binStr.length;
          const arr = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
          }
          return new Blob([arr], { type: type });
        }
      });
  };
  const msg = {
    icon: <FaExclamationCircle className="me-3" />,
    type: "alert-info",
    text: "No tickets found",
  };

  return (
    <>
      <div className="container  mt-5 mb-5">
        <div className="row">
          <div className="col-12">
            {data?.length > 0 ? (
              <>
                <h2 className="text-center">Congratulations!</h2>
                <p className="pt-1 text-center">
                  Your payment has been confirmed.
                </p>
                <div className="card mt-5 mb-5">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover table-vcenter">
                        <thead>
                          <tr>
                            <th className="text-center">Ticket ID</th>
                            <th className="text-center">Order ID</th>
                            <th>Event</th>
                            <th>Start date</th>
                            <th>End date</th>
                            <th>Venue / Location</th>
                            <th>Organizer</th>
                            <th>Attendee</th>
                            <th>Ticket type</th>
                            <th>Qty</th>
                            <th>Payment method</th>
                            <th>Order date </th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((curElem, i) => {
                            return (
                              <tr key={i}>
                                <td className="text-center text-muted text-sm">
                                  {curElem?.event?.reference}
                                </td>
                                <td className="text-center text-muted text-sm">
                                  {curElem?.reference}
                                </td>
                                <td>{curElem?.event?.name}</td>
                                <td>
                                  {curElem?.event?.start_On &&
                                    formatDate(
                                      new Date(curElem?.event?.start_On)
                                    ).fullDate}
                                </td>
                                <td>
                                  {curElem?.event?.end_On &&
                                    formatDate(new Date(curElem?.event?.end_On))
                                      .fullDate}
                                </td>
                                <td>
                                  {curElem?.event?.event_date_online == 1 ? (
                                    "Online"
                                  ) : (
                                    <>
                                      {curElem?.event?.venue?.street_address}{" "}
                                      {curElem?.event?.venue?.postal_code}{" "}
                                      {curElem?.event?.venue?.city},{" "}
                                      {curElem?.event?.venue?.state},{" "}
                                      {countries?.map((curElem) => {
                                        if (
                                          curElem.id ==
                                          curElem?.event?.venue?.country
                                        ) {
                                          return (
                                            <React.Fragment key={curElem.id}>
                                              {curElem.name}
                                            </React.Fragment>
                                          );
                                        }
                                        return null; // or any other default value if the condition is not met
                                      })}
                                    </>
                                  )}
                                </td>
                                <td>{curElem?.event?.user?.organize_name}</td>
                                <td>
                                  {curElem?.billing_reference?.first_name}{" "}
                                  {curElem?.billing_reference?.last_name}
                                </td>
                                <td>
                                  {curElem?.event?.tickets?.[0]?.ticket_name}
                                </td>
                                <td>{curElem?.ticket_quantity}</td>
                                <td>Credit/Debit Cards</td>
                                <td>
                                  {curElem?.date &&
                                    formatDate(new Date(curElem?.date))
                                      .fullDate}
                                </td>

                                <td>
                                  <span className="d-flex align-items-center">
                                    {curElem?.payment === "completed" && (
                                      <span className="badge badge-success ">
                                        Paid
                                      </span>
                                    )}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="13" className="pt-5"></td>
                          </tr>
                          <tr className="">
                            <td colSpan="13" className="text-end pt-3 border-0">
                              <button
                                className="btn btn-dark btn-sm "
                                onClick={oncl}
                              >
                                Print Ticket
                              </button>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="" style={{ height: "50vh" }}>
                <Alert {...msg} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-none">
        <div
          className="container"
          id="contentToConvert"
          style={{ padding: "6rem 4rem 0px 4rem" }}
        >
          <div className="row border" style={{ paddingTop: "3rem" }}>
            {data?.map((curElem, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="col-6 p-0 border-end">
                    <h5
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        padding: "25px 0 25px 20px",
                        fontSize: "17px",
                      }}
                    >
                      Order - {curElem?.reference}
                    </h5>

                    <div className="p-3">
                      <h4 className="title">{curElem?.event?.name}</h4>
                      <b className="text-muted">Start date</b>
                      <br />
                      <b>
                        {curElem?.event?.start_On &&
                          formatDate(new Date(curElem?.event?.start_On))
                            .fullDate}
                      </b>
                      <br />
                      <br />
                      <b className="text-muted">End date</b>
                      <br />
                      <b>
                        {curElem?.event?.end_On &&
                          formatDate(new Date(curElem?.event?.end_On)).fullDate}
                      </b>
                      <br />
                      <br />
                      <b className="text-muted">Venue / Location</b>
                      <br />
                      <b>
                        {curElem?.event?.event_date_online == 1 ? (
                          "Online"
                        ) : (
                          <>
                            {curElem?.event?.venue?.street_address}{" "}
                            {curElem?.event?.venue?.postal_code}{" "}
                            {curElem?.event?.venue?.city},{" "}
                            {curElem?.event?.venue?.state},{" "}
                            {countries?.map((curElem) => {
                              if (
                                curElem.id == curElem?.event?.venue?.country
                              ) {
                                return (
                                  <React.Fragment key={curElem.id}>
                                    {curElem.name}
                                  </React.Fragment>
                                );
                              }
                              return null; // or any other default value if the condition is not met
                            })}
                          </>
                        )}
                      </b>
                      <br />
                      <br />
                      <b className="text-muted">Organizer</b>
                      <br />
                      <b>{curElem?.event?.user?.organize_name}</b>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <h5
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        padding: "25px 0 25px 20px",
                        fontSize: "17px",
                      }}
                    >
                      Ticket - {curElem?.event?.reference}
                    </h5>
                    <div className="p-3">
                      <h4 className="title">
                        {curElem?.billing?.first_name}{" "}
                        {curElem?.billing?.last_name}
                      </h4>

                      {curElem?.event?.tickets &&
                        curElem?.event?.tickets?.length > 0 && (
                          <>
                            <b className="text-muted">Ticket type</b>
                            <br />
                            <b>{curElem?.event?.tickets?.[0]?.ticket_name}</b>
                          </>
                        )}

                      <br />
                      <br />
                      <b className="text-muted">Ticket price</b>
                      <br />
                      <b>
                        {curElem?.event?.tickets?.[0]?.ticket_free === 1
                          ? "Free"
                          : `${curElem?.total_price}$`}
                      </b>
                      <br />
                      <br />
                      <b className="text-muted">Total Ticket</b>
                      <br />
                      <b>{curElem?.ticket_quantity}</b>
                      <br />
                      <br />
                      <b className="text-muted">Payment method</b>
                      <br />
                      <b>Credit/Debit Cards</b>
                      <br />
                      <br />
                      <b className="text-muted">Order date</b>
                      <br />
                      <b>
                        {curElem?.date &&
                          formatDate(new Date(curElem?.date)).fullDate}
                      </b>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            <hr />
            <div className="col-12">
              <div style={{ textAlign: "center" }}>
                <img
                  alt="Barcode Generator TEC-IT"
                  src="/./images/qr.png"
                  width="15%"
                />
              </div>
            </div>
          </div>

          <div className="row mb-5" style={{ marginTop: "15rem" }}>
            <div className="col text-center">
              <div style={{ textAlign: "center" }}>
                <img
                  alt="Barcode Generator TEC-IT"
                  src="/./images/barcode.png"
                  width="30%"
                />
              </div>

              <Link to="https://feevaa.com/en/signin" target="_blank">
                <h5>https://feevaa.com</h5>
              </Link>
              <h5>feevaa - Event Management And Ticket Sales Platform</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDetailsPDF;
