import React, { useEffect, useState } from "react";
import {
  BsFillCalendarPlusFill,
  BsBicycle,
  BsHeart,
  BsFillCartPlusFill,
} from "react-icons/bs";
import {
  FaCarAlt,
  FaBus,
  FaTicketAlt,
  FaRegIdCard,
  FaRegWindowClose,
  FaSyncAlt,
  FaInfoCircle,
  FaFolderMinus,
} from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { AiFillFolderAdd, AiFillClockCircle } from "react-icons/ai";
import { BiWalk } from "react-icons/bi";
import "./browsereventsidebar.scss";
import { Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Count from "../../../count/Count";
import axios from "axios";
import { toast } from "react-toastify";
import { formatDate } from "../../../../utils/CustomFunctions";
import { add_To_Cart, fetchTickets } from "../../../../store/slice/Cart";
import Alert from "../../../global/alert/Alert";
import { fetchuUserData } from "../../../../store/slice/Attendee";
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import { FcGoogle } from "react-icons/fc";

export default function BrowseEventSidebar({ event, totalTickets }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const server = process.env.REACT_APP_SERVER;
  const { user } = useSelector((state) => state.authUser);
  const { favorites, followings, loading } = useSelector(
    (state) => state.attendee
  );
  const { total_item, payment_settings, cart, tickets } = useSelector(
    (state) => state.CartDetails
  );

  const { countries } = useSelector((state) => state.AllData);
  const api = process.env.REACT_APP_SERVER_API;
  const [isPending, setPending] = useState(false);
  const [discountPrice, setDiscountPprice] = useState(false);
  const [eventExists, setEventExists] = useState(false);
  const [favorite, setfavorite] = useState({ isFavorite: false, favorite: {} });
  const [following, setfollowing] = useState({
    isFollowing: false,
    following: {},
  });
  const { start_On, end_On, name, enable_event_date, venue } = event;
  const {
    id,
    event_id,
    Price,
    quantity,
    ticket_name,
    discount,
    enable_sales_ticket,
    tickets_per_attendee,
    sale_start,
  } = event?.tickets?.[0];

  const startDate = new Date(start_On);
  const endDate = new Date(end_On);

  const tickets_left =
    totalTickets && totalTickets.length > 0
      ? parseInt(quantity) - parseInt(totalTickets?.[0]?.total_ticket)
      : quantity;

  const { month, day, year, time, fullDate } = formatDate(startDate);
  const {
    month: endMnth,
    day: endDay,
    year: endYear,
    time: endTime,
  } = formatDate(endDate);

  const [openModel, setOpenModel] = useState(false);
  const [openModel2, setOpenModel2] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentEventCart, setCurrentEventCart] = useState({});

  // add event to calender
  const event_d = {
    start: start_On,
    end: end_On,
    duration: [1, "day"],
    title: name,
    description:
      "Editing the title after the event is saved won't change the event url",
    location:
      event?.event_date_online === 1
        ? " Online"
        : `${event?.venue?.city}: ${event?.venue?.postal_code} ${event?.venue?.street_address}, ${event?.venue?.state}, ${event?.venue?.country}`,
    busy: true,
    guests: [],
  };

  const [addToCart, setAddToCart] = useState({
    user_id: user?.id || null,
    event_id,
    ticket: "",
    ticket_id: id,
    price: discountPrice,
  });
  const validateFields = () => {
    let newErrors = {};
    if (addToCart.ticket < 1) newErrors.ticket = "";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  // for add to cart
  const add_cart = async (e) => {
    e.preventDefault();
    setOpenModel(false);
    if (!validateFields()) return;

    try {
      const { data } = await axios.post(`${api}/add_cart`, addToCart);
      if (data.status === 201) {
        toast.success(data.message);

        if (user && Object.keys(user).length > 0) {
          user.role.some((role) => role.name === "organizer") &&
            navigate("/en/dashboard/organizer/cart");
          user.role.some((role) => role.name === "attendee") &&
            navigate("/en/dashboard/attendee/cart");
        } else {
          navigate("/en/guest/cart");
        }

        dispatch(
          add_To_Cart({
            user,
            ticket_qty: addToCart.ticket,
            event,
            start_On: fullDate,
            fees: payment_settings?.ticket_fee_percentage,
            tax: payment_settings?.ticket_fee,
            remaining_ticket: tickets_left,
            tickets_left,
            price: discountPrice,
          })
        );
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  // alert message
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No tickets on sale at this moment",
  };
  useEffect(() => {
    dispatch(
      fetchTickets({
        getData: "payment_fees",
        state: "payment_settings",
        token: "",
      })
    );
  }, []);

  // for chek event date greater than 2 days of ticket sale start date
  // const ticketSaleDate = new Date(sale_start.split(" ")[0]);
  // const eventDate = new Date(start_On.split(" ")[0]);
  // const ticketSaleFiveDaysLater = new Date(ticketSaleDate);
  // ticketSaleFiveDaysLater.setDate(ticketSaleFiveDaysLater.getDate() + 5);
  // const isEventAfterFiveDays = eventDate > ticketSaleFiveDaysLater;
  // Log the result

  // for check dates
  function validDate(start, end) {
    const currentDate = new Date();
    const isSart = new Date(start) <= currentDate;
    const isEnd = new Date(end) < currentDate;
    return { isSart, isEnd };
  }
  const ticketSale = validDate(
    event?.tickets?.[0]?.sale_start,
    event?.tickets?.[0]?.sale_end
  );
  // const event = validDate(modelEevnt?.start_On, modelEevnt?.end_On);

  // for add/remove to favorites - follow / unfollow
  const handleADDTOFAVORITES = async (e, id, endPoint) => {
    e.preventDefault();
    if (!user?.token) {
      navigate("/en/signin", { state: `/en/event/${event?.slug}` });
    } else {
      (endPoint === "add_favorite" || endPoint === "remove_favorite") &&
        setPending(true);
      const { data } = await axios.get(`${api}/attendee/${endPoint}/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (data.status === 200 || data.status === 201) {
        toast.success(data.message);
        if (endPoint === "add_following" || endPoint === "unfollowing") {
          dispatch(
            fetchuUserData({
              getData: "attendee/followings",
              state: "followings",
              token: user.token,
            })
          );
        } else {
          dispatch(
            fetchuUserData({
              getData: "attendee/favorites",
              state: "favorites",
              token: user.token,
            })
          );
        }
      }
    }
    try {
    } catch (error) {
      toast.error(error?.response?.data.message);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    if (user?.token) {
      const isFavorite = favorites.some((f) => f.event_id === event.id);
      const favorite = favorites.find((f) => f.event_id === event.id);

      const isFollowing = followings.some(
        (f) => f.organizer_id === event.user.id
      );

      const following = followings.find(
        (f) => f.organizer_id === event.user.id
      );

      setfavorite({ isFavorite, favorite });
      setfollowing({ isFollowing, following });
    }
  }, [user?.token, favorites, followings, event.id]);

  useEffect(() => {
    if (cart && cart.length > 0) {
      const item = cart.find((item) => item.event_id == event_id) || {};
      setCurrentEventCart(item);
    } else {
      setCurrentEventCart({});
    }
  }, [cart]);

  useEffect(() => {
    setDiscountPprice(discount ? parseInt(Price) - parseInt(discount) : Price);
  }, [event, event_id]);

  useEffect(() => {
    if (venue) {
      const apiKey = process.env.REACT_APP_MAP_API_KEY;
      const address = `${venue?.street_address}, ${venue?.city}, ${venue?.state} ${venue?.postal_code}, ${venue?.country}`;

      const encodedAddress = encodeURIComponent(address);
      const mapsUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${encodedAddress}`;

      const iframe = document.createElement("iframe");
      iframe.setAttribute("width", "100%");
      iframe.setAttribute("height", "320");
      iframe.setAttribute("frameborder", "0");
      iframe.setAttribute("style", "border:0");
      iframe.setAttribute("src", mapsUrl);

      const container = document.getElementById("BrowseEventVenueMap");

      if (!container) return;

      container.innerHTML = "";
      container.appendChild(iframe);

      return () => {
        container.innerHTML = "";
      };
    }
  }, [venue, event]);

  useEffect(() => {
    // if this event has already been added to the cart
    const eventExists = cart.some((item) => item.event_id == event.id);
    setEventExists(eventExists);
  }, [cart]);

  return (
    <>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={openModel}
        onCancel={() => setOpenModel(false)}
        className="Model"
      >
        <div className="ModelBox">
          <div className="ModelHeader">
            <FaTicketAlt className="ModelHeaderIcon" />
            <span className="ModelBoxBuyTicketText">Buy tickets</span>
          </div>

          <div className="ModelContent">
            <span className="ModelTitle text-capitalize">
              {name} -{" "}
              <span className="text-uppercase">
                {venue?.city} {venue?.state}
              </span>
            </span>

            <div className="ModelDetailsWrapper">
              <div className="ModelVenue">
                <AiFillClockCircle className="ModelIcon" />
                <span className="ModelAddress">{fullDate}</span>
              </div>
              {venue && Object.keys(venue).length > 0 && (
                <div className="ModelVenue">
                  <FaLocationDot className="ModelIcon" />
                  <span className="ModelAddress text-capitalize">
                    {venue?.city}: {venue?.postal_code} {venue?.street_address},{" "}
                    {venue?.state},{" "}
                    {countries?.map((curElem) => {
                      if (curElem.id == venue?.country) {
                        return (
                          <React.Fragment key={curElem.id}>
                            {curElem.name}
                          </React.Fragment>
                        );
                      }
                      return null; // or any other default value if the condition is not met
                    })}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="ModelBoxTicketPurchaseBox">
            <div className="ModelBoxTicketPurchaseTitleBox">
              <div className="ModelBoxTicketPurchaseTitleCartBox">
                <span className="ModelBoxTicketPurchaseTitle">
                  {ticket_name}
                </span>
              </div>
              <span className="ModelBoxTicketPurchasePrice">
                {event?.tickets?.[0]?.ticket_free == 1 ? (
                  "Free"
                ) : (
                  <span style={{ fontWeight: 600 }}>
                    Price After Discount: ${discountPrice}
                  </span>
                )}
              </span>
              {event?.tickets?.[0]?.ticket_free !== 1 && discount && (
                <span style={{ fontWeight: 600 }}>Discount: ${discount}</span>
              )}
            </div>

            <div className="ModelBoxTicketPurchaseCounterBox">
              {eventExists ? (
                <span className="text-danger">
                  This event is already in your cart.
                </span>
              ) : (
                <Count
                  per_attendee={
                    currentEventCart?.ticket_quantity
                      ? tickets_per_attendee - currentEventCart.ticket_quantity
                      : tickets_per_attendee
                  }
                  name="ticket"
                  value={addToCart.ticket}
                  setState={setAddToCart}
                  tickets_left={
                    currentEventCart?.remaining_ticket
                      ? currentEventCart.remaining_ticket
                      : tickets_left
                  }
                />
              )}

              <div className="d-flex gap-3">
                <span className="ModelBoxTicketPurchaseCounterTicketsRemaining">
                  {tickets_per_attendee} per attendee
                </span>
                <span className="ModelBoxTicketPurchaseCounterTicketsRemaining">
                  {Object.keys(currentEventCart).length > 0
                    ? currentEventCart.remaining_ticket
                    : tickets_left}{" "}
                  tickets left
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
          <button
            className="btn btn-outline-dark rounded-5 d-inline-flex align-items-center gap-2"
            onClick={() => setOpenModel(false)}
          >
            <FaRegWindowClose className="ModelBoxTicketPurchaseTitleIcon" />
            close
          </button>

          <button
            className="btn btn-dark rounded-5 d-inline-flex align-items-center gap-2"
            onClick={add_cart}
            disabled={
              (cart.length > 0 &&
                (currentEventCart?.remaining_ticket == 0 ||
                  currentEventCart?.ticket_quantity == tickets_per_attendee)) ||
              tickets_left == 0 ||
              eventExists
            }
          >
            <BsFillCartPlusFill className="ModelBoxTicketPurchaseTitleIcon" />
            Add to cart
          </button>
        </div>
      </Modal>

      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={openModel2}
        onCancel={() => setOpenModel2(false)}
        // className="Model"
        style={{ width: "520px !important" }}
      >
        <div className="ModelBox">
          <div className="ModelHeader">
            <span className="ModelBoxBuyTicketText fs-5">
              <BsFillCalendarPlusFill className="me-2" />
              Add to Calender
            </span>
          </div>
          <hr />
          <div
            className="jconfirm-content "
            id="jconfirm-box27525 add-to-calendar"
          >
            <div className="BrowseEventVenueDetailOraganizerSection text-center">
              <div className="BrowseEventVenueDetailOraganizerHeader  fw-600">
                <Link to={google(event_d)} target="_blank" className="fs-6">
                  <FcGoogle className="me-1" />
                  Goggle
                </Link>
              </div>
              <div className="BrowseEventVenueDetailOraganizerHeader  fw-600">
                <Link to={outlook(event_d)} target="_blank">
                  <img
                    src="/./images/outlook.svg"
                    width="16px"
                    className="me-1"
                    alt=""
                  />
                  Outlook
                </Link>
              </div>
              <div className="BrowseEventVenueDetailOraganizerHeader  fw-600">
                <Link
                  to={yahoo(event_d)}
                  target="_blank"
                  className="icon-yahoo"
                >
                  <img
                    src="/./images/yahoo.svg"
                    width="16px"
                    className="me-1"
                    alt=""
                  />
                  Yahoo
                </Link>
              </div>
              {/* <div className="BrowseEventVenueDetailOraganizerHeader  fw-600">
              <Link to={office365(event_d)} target="_blank">
                Office365
              </Link>
            </div> */}
              <div className="BrowseEventVenueDetailOraganizerHeader  fw-600">
                <Link to={ics(event_d)} target="_blank">
                  <img
                    src="/./images/ical.svg"
                    width="16px"
                    className="me-1"
                    alt=""
                  />
                  iCal Calendar
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
          <button
            className="btn btn-outline-secondary btn-sm rounded-5 d-inline-flex align-items-center gap-2"
            onClick={() => setOpenModel2(false)}
          >
            close
          </button>
        </div>
      </Modal>

      <div className="BrowseEventSidebarSection">
        <div className="BrowseEventsSidebarContainer">
          {/* for no sale event alter */}
          {(enable_sales_ticket === 0 ||
            enable_event_date === 0 ||
            !ticketSale.isSart ||
            ticketSale.isEnd) && <Alert {...msg} />}
          <div className="BrowseEventSidebarHeader">
            <span className="BrowseEventSidebarHEaderTitle fw-bold text-muted">
              Dates
            </span>
            <div className="BrowseEventSidebarDateBox">
              <div className="BrowseEventDateboxWrapper">
                <div className="d-flex gap-3">
                  <div className="BrowseEventDatesWrapper gap-1">
                    <span className="BrowseEventDateboxDate">{day}</span>
                    <span className="BrowseEventDateboxDate">
                      <div className="fs-6">{month}</div>
                      <div> {year}</div>
                    </span>
                  </div>
                  <div className="BrowseEventDatesWrapper gap-1">
                    <span className="BrowseEventDateboxDate">{endDay}</span>
                    <span className="BrowseEventDateboxDate">
                      <div className="fs-6">{endMnth}</div>
                      <div> {endYear}</div>
                    </span>
                  </div>
                </div>

                <span className="BrowseEventDateboxTime text-muted fw-medium">
                  {time} - {endTime}
                </span>
                <div className="BrowseEventDateboxWrappers">
                  <BsFillCalendarPlusFill className="BrowseEventDateboxCalender" />

                  <Link
                    to=""
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenModel2(true);
                    }}
                    className="BrowseEventDateboxCalenderText"
                  >
                    Add to Calender
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="BrowseEventVenueDetailsSection">
            <div className="BrowseEventVenueDetails">
              <span className="BrowseEventVenueDetailsText text-muted">
                Venue
              </span>
              {/* <div className="BrowseEventVenueDetailsBox">
                  <Link to="" className="d-flex align-items-center">
                  <BsBuilding className="BrowseEventVenueDetailsBoxIcon" />
                  <span className="BrowseEventVenueDetailsBoxText">
                    Details
                  </span>
                </Link>
                </div> */}
            </div>

            {event?.event_date_online !== 1 ? (
              <>
                <div className="BrowseEventVenueDetailContent">
                  <span className="BrowseEventVenueDetailContentTitle">
                    {venue?.name}
                  </span>
                  <span className="BrowseEventVenueDetailDesc">
                    {venue?.street_address} {venue?.city},{venue?.state}{" "}
                    {venue?.postal_code},{" "}
                    {countries?.map((curElem) => {
                      if (curElem.id == venue?.country) {
                        return (
                          <React.Fragment key={curElem.id}>
                            {curElem.name}
                          </React.Fragment>
                        );
                      }
                      return null; // or any other default value if the condition is not met
                    })}
                  </span>
                </div>

                <div className="BrowseEventVenueDetailIconsWrapper">
                  <FaCarAlt className="BrowseEventVenueDetailIcons" />
                  <BiWalk className="BrowseEventVenueDetailIcons" />
                  <FaBus className="BrowseEventVenueDetailIcons" />
                  <BsBicycle className="BrowseEventVenueDetailIcons" />
                </div>

                <div
                  className="BrowseEventVenueDetailWrapperMap"
                  id="BrowseEventVenueMap"
                ></div>
              </>
            ) : (
              <dl>
                <dd>
                  <br />
                  <h4 className="text-center">Online</h4>
                </dd>
              </dl>
            )}
          </div>
          {enable_sales_ticket === 1 &&
            enable_event_date === 1 &&
            ticketSale.isSart &&
            !ticketSale.isEnd && (
              <>
                <div className="BrowseEventVenueDetailTicketBox">
                  <span className="BrowseEventVenueDetailTicketBoxTitle">
                    Tickets
                  </span>
                  <div className="BrowseEventVenueDetailTicketBtnWrapper">
                    <h5 className="BrowseEventVenueDetailTicketBtnWrapperBoxText text-center m-0">
                      {ticket_name}
                    </h5>
                    <hr className="mt-2" />
                    <div>
                      <h6>
                        {event?.tickets?.length > 0 &&
                        event?.tickets?.[0]?.ticket_free == 1 ? (
                          "Free"
                        ) : (
                          <span>
                            Price After Discount: <b>${discountPrice}</b>
                          </span>
                        )}
                      </h6>
                      <h6>
                        {event?.tickets?.length > 0 &&
                          event?.tickets?.[0]?.ticket_free !== 1 &&
                          discount && (
                            <>
                              Discount: <b>${discount}</b>
                            </>
                          )}
                      </h6>
                    </div>
                  </div>

                  {user?.role?.[0]?.name !== "admin" && (
                    <div className="mt-3">
                      <button
                        onClick={() => setOpenModel(true)}
                        className="d-flex align-items-center justify-content-center w-100 btn btn-dark rounded-5 mb-3"
                      >
                        <FaTicketAlt className="me-1" />
                        Tickets
                      </button>

                      {user?.role?.[0]?.name !== "admin" &&
                        user?.role?.[0]?.name !== "organizer" &&
                        (favorite.isFavorite ? (
                          <button
                            className="d-flex align-items-center justify-content-center w-100 btn btn-dark rounded-5"
                            onClick={(e) =>
                              handleADDTOFAVORITES(
                                e,
                                favorite?.favorite?.id,
                                "remove_favorite"
                              )
                            }
                            type="submit"
                            disabled={isPending ? "disabled" : ""}
                          >
                            <BsHeart className="me-1" /> Remove from favorites
                          </button>
                        ) : (
                          <button
                            className="d-flex align-items-center justify-content-center w-100 btn btn-outline-dark rounded-5"
                            onClick={(e) =>
                              handleADDTOFAVORITES(e, event?.id, "add_favorite")
                            }
                            type="submit"
                            disabled={isPending ? "disabled" : ""}
                          >
                            {isPending ? (
                              <FaSyncAlt className="spinIcon" />
                            ) : (
                              <>
                                <BsHeart className="me-1" /> ADD TO FAVORITES
                              </>
                            )}
                          </button>
                        ))}
                    </div>
                  )}
                </div>
              </>
            )}
          {/* this show */}
          <div className="BrowseEventVenueDetailOraganizerSection ">
            <div className="BrowseEventVenueDetailOraganizerHeader  fw-600">
              <span className="BrowseEventVenueDetailOraganizerHeaderTitle">
                Organizer
              </span>
              <div className="BrowseEventVenueDetailOraganizerHeaderDetails">
                <FaRegIdCard className="BrowseEventVenueDetailOraganizerHeaderDetailsIcon" />
                <Link to={`/en/organizer/${event?.user?.slug}`}>Details</Link>
              </div>
            </div>
            {/*  */}
            <div
              className="card-banner mt-1"
              style={{
                height: "250px",
                backgroundImage: `${
                  event?.user?.image &&
                  `url(${server}/user/profile/${event?.user?.image})`
                } `,
              }}
            >
              <article className="overlay bottom text-center">
                <h4 className="card-title">{event?.user?.organize_name}</h4>
                {event?.user?.organizer_logo && (
                  <img
                    src={`${server}/organizer/logo/${event?.user?.organizer_logo}`}
                    className="img-100-100 d-block mx-auto mb-3 img-thumbnail"
                    alt={event?.user?.organize_name}
                  />
                )}
                {user?.role?.[0]?.name !== "admin" &&
                  user?.role?.[0]?.name !== "organizer" &&
                  (following.isFollowing ? (
                    <button
                      type="button"
                      className="d-flex align-items-center btn btn-dark btn-sm rounded-5 mx-auto"
                      onClick={(e) =>
                        handleADDTOFAVORITES(
                          e,
                          following?.following?.id,
                          "unfollowing"
                        )
                      }
                    >
                      <FaFolderMinus
                        className="me-1"
                        style={{ fontSize: "15px" }}
                      />
                      unFollow
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="d-flex align-items-center btn btn-dark btn-sm rounded-5 mx-auto"
                      onClick={(e) =>
                        handleADDTOFAVORITES(
                          e,
                          event?.user?.id,
                          "add_following"
                        )
                      }
                    >
                      <AiFillFolderAdd
                        className="me-1"
                        style={{ fontSize: "15px" }}
                      />
                      Follow
                    </button>
                  ))}
              </article>
            </div>
          </div>

          {/* <div className="BrowseEventVenueDetailOraganizerNewsletterSection">
            <div className="BrowseEventVenueDetailOraganizerNewsletterWrapper">
              <div className="BrowseEventVenueDetailOraganizerNewsletterHeader">
                <span className="BrowseEventVenueDetailOraganizerNewsletterHeaderIconWrapper">
                  <FaMailBulk className="BrowseEventVenueDetailOraganizerNewsletterHeaderIcon" />
                </span>

                <span className="BrowseEventVenueDetailOraganizerNewsletterHeaderText">
                  Subscribe to our newsletter
                </span>
              </div>

              <div className="BrowseEventVenueDetailOraganizerNewsletterEmailBox">
                <input
                  className="BrowseEventVenueDetailOraganizerNewsletterEmailBoxEmail"
                  type="email"
                  name=""
                  id=""
                  placeholder="Email Address"
                />

                <button className="BrowseEventVenueDetailOraganizerNewsletterEmailBtn">
                  Subscribe
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
