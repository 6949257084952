import React from "react";

const TabContent = ({ tab, name, val, setVal }) => {
  return (
    <div className="tab-content w-100" id="v-pills-tabContent">
      {tab.map((tab, index) => {
        return (
          <div
            className={`tab-pane fade ${
              val[name] === tab.title && "show active"
            }`}
            id={`${tab.title === name && tab.id}`}
            role="tabpanel"
            aria-labelledby={`${tab.title === name && tab.id}_tab`}
            key={index}
          >
            <input
              type="text"
              name={tab.input}
              className="form-control mt-3"
              value={val[tab.input]}
              onChange={(e) => setVal({ ...val, [tab.input]: e.target.value })}
            />
          </div>
        );
      })}
    </div>
  );
};

export default TabContent;
