import { Dropdown, Pagination, Space } from "antd";
import React, { useState } from "react";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaCartArrowDown,
  FaChartLine,
  FaEllipsisV,
  FaFileInvoice,
  FaInfoCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Alert from "../../../../global/alert/Alert";
import { formatDate } from "../../../../../utils/CustomFunctions";
import { SlCalender } from "react-icons/sl";

export const Table = ({ reports }) => {
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No reports found",
  };

  // for check dates
  function validDate(start, end) {
    const currentDate = new Date();
    const isSart = new Date(start) <= currentDate;
    const isEnd = new Date(end) < currentDate;
    return { isSart, isEnd };
  }
  // const ticketSale = validDate(
  //   modelEevnt?.tickets?.[0]?.sale_start,
  //   modelEevnt?.tickets?.[0]?.sale_end
  // );
  // const event = validDate(modelEevnt?.start_On, modelEevnt?.end_On);

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = reports?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <div className="row organizer-event">
        <div className="col-12">
          {reports && reports.length > 0 ? (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter">
                  <thead>
                    <tr>
                      <th className="text-center">Reference</th>
                      <th>Event date</th>
                      <th>Tickets sold</th>
                      <th>Net sales</th>
                      <th>Percentage cut</th>
                      <th>Status</th>
                      <th className="text-center">
                        <AiTwotoneSetting />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((report, index) => {
                      const items = [
                        {
                          label: (
                            <Link
                              to="/en/dashboard/organizer/my-events"
                              className="d-flex align-items-center"
                            >
                              <SlCalender className="me-1" />
                              Manage event
                            </Link>
                          ),
                          key: "0",
                        },
                        {
                          label: (
                            <Link
                              to={`/en/dashboard/organizer/my-events/${report?.event?.reference}`}
                              className="d-flex align-items-center"
                            >
                              <FaChartLine className="me-1" />
                              View detailed statistics
                            </Link>
                          ),
                          key: "1",
                        },
                        {
                          label: (
                            <Link
                              to={`/en/dashboard/organizer/manage-orders/event/${report?.event?.reference}`}
                              className="d-flex align-items-center"
                            >
                              <FaCartArrowDown className="me-1" />
                              Orders
                            </Link>
                          ),
                          key: "2",
                        },
                      ];
                      const ticketSale = validDate(
                        report?.tickets?.[0]?.sale_start,
                        report?.tickets?.[0]?.sale_end
                      );

                      return (
                        <tr key={index}>
                          <td className="text-center text-muted text-sm">
                            {report?.event?.reference}
                          </td>
                          <td className="text-sm">
                            {report?.event?.name}
                            <br />
                            {
                              formatDate(new Date(report?.event?.start_On))
                                .fullDate
                            }
                          </td>
                          <td>{report?.check_out_ticket_quantity}</td>
                          <td>${report?.check_out_total?.toFixed(2)} </td>
                          <td>${report?.ticketFees}</td>
                          <td>
                            {report?.event?.status === "inactive" && (
                              <span className="badge badge-warning">
                                Event is not yet published.
                              </span>
                            )}
                            {report?.event?.status === "active" &&
                              (report?.tickets?.[0]?.enable_sales_ticket ===
                                0 ||
                                report?.event?.enable_event_date === 0 ||
                                !ticketSale.isSart ||
                                ticketSale.isEnd) && (
                                <span className="badge badge-info">
                                  No events on sale
                                </span>
                              )}
                            {report?.event?.status === "active" &&
                              report?.tickets?.[0]?.enable_sales_ticket === 1 &&
                              report?.event.enable_event_date === 1 &&
                              ticketSale.isSart &&
                              !ticketSale.isEnd && (
                                <span className="badge badge-success">
                                  on sale
                                </span>
                              )}
                          </td>
                          <td className="text-center">
                            <Dropdown
                              menu={{
                                items,
                              }}
                              trigger={["click"]}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                  <FaEllipsisV />
                                </Space>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <Alert {...msg} />
          )}
        </div>
      </div>

      {reports?.length > 10 && (
        <Pagination
          current={currentPage}
          total={reports.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};
