import React, { useEffect, useState } from "react";
import { FaCircleInfo } from "react-icons/fa6";
import "./profile.scss";
import { Radio, Tooltip } from "antd";
import { useSignUp } from "../../../../../utils/CustomFunctions";
import { useSelector } from "react-redux";
import SelectBox from "../../../../global/SelectBox";
import MultiSelectBox from "../../../../global/MultiSelectBox";
import { FaSyncAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { country } from "../../../../global/Values";

export const Profile = () => {
  const server = process.env.REACT_APP_SERVER;
  const { event_categories } = useSelector((state) => state.organizer);
  const { user } = useSelector((state) => state.authUser);

  const initialState = {
    ...user,
    organize_name: user?.organize_name || "",
    about: user?.about || "",
    organizer_logo: user?.organizer_logo || "",
    email: user?.email || "",
    reviews: user?.reviews,
    followers: user?.followers,
    venues: user?.venues,
  };
  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    if (!values.organize_name.trim())
      newErrors.organize_name = "This value should not be null.";
    if (!values.about.trim())
      newErrors.about = "This value should not be null.";
    if (!values.organizer_logo)
      newErrors.organizer_logo = "This value should not be null.";
    if (!values.email) newErrors.email = "This value should not be null.";

    return newErrors;
  };

  const {
    values,
    errors,
    isPending,
    handleChange,
    handleSelectBox,
    handleMultiSelectBox,
    singleImage,
    handleSubmit,
  } = useSignUp(initialState, validateFields, `profile_update`);

  return (
    <div className="card OrganizerProfileSection">
      <div className="OrganizerProfilePreviewProfileBox">
        <FaCircleInfo className="OrganizerProfilePreviewProfileBoxIcon" />
        <span className="OrganizerProfilePreviewProfileBoxLink">
          <Link to={`/en/organizer/${values.slug}`}>Click here</Link>{" "}
          <span className="OrganizerProfilePreviewProfileBoxText">
            {" "}
            to preview your profile
          </span>
        </span>
      </div>

      {Object.keys(values).length > 0 && (
        <form className="signform mt-2 setting" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="organize_name" className="form-label">
              Organize Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              id="organize_name"
              name="organize_name"
              className={`form-control ${errors.organize_name && "is-invalid"}`}
              value={values?.organize_name || ""}
              onChange={handleChange}
            />
            {errors.organize_name && (
              <small className="text-danger d-block mt-1">
                {errors.organize_name}
              </small>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="about" className="form-label">
              About the organizer<span style={{ color: "red" }}>*</span>
            </label>
            <textarea
              id="details"
              name="about"
              rows="10"
              className={`form-control ${errors.about && "is-invalid"}`}
              onChange={handleChange}
              value={values?.about || ""}
            ></textarea>
            {errors.about && (
              <small className="text-danger d-block mt-1">{errors.about}</small>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="event_categories_id"
              className="form-label fw-600 m-0"
            >
              Categories
            </label>
            <div className="OrganizerOptionDescBox">
              <FaCircleInfo className="OrganizerOptionDescIcon" />
              <span className="OrganizerOptionDesc">
                Select the categories that represent your events types
              </span>
            </div>
            <MultiSelectBox
              name="categories"
              options={event_categories}
              val={values}
              change={handleMultiSelectBox}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="organizer_logo" className="form-label">
              Organizer logo<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="file"
              name="organizer_logo"
              id="organizer_logo"
              className={`form-control ${
                errors.organizer_logo && "is-invalid"
              }`}
              onChange={singleImage}
            />
            {errors.organizer_logo && (
              <small className="text-danger d-block mt-1">
                {errors.organizer_logo}
              </small>
            )}
            {values?.organizer_logo && (
              <div className="mt-2">
                <Tooltip title="Enlarge">
                  <Link
                    className="fancybox"
                    to={`${server}/organizer/logo/${values.organizer_logo}`}
                    target="_blank"
                  >
                    <img
                      src={`${server}/organizer/logo/${values.organizer_logo}`}
                      alt=""
                      width="20%"
                    />
                  </Link>
                </Tooltip>
              </div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="image" className="form-label m-0">
              Cover photo
            </label>
            <div className="OrganizerOptionDescBox">
              <FaCircleInfo className="OrganizerOptionDescIcon" />
              <span className="OrganizerOptionDesc">
                Optionally add a cover photo to showcase your organizer
                activities
              </span>
            </div>
            <input
              id="image"
              type="file"
              name="image"
              className="form-control"
              onChange={singleImage}
            />
            {values.image && (
              <div className="mt-2">
                <Tooltip title="Enlarge">
                  <Link
                    className="fancybox"
                    to={`${server}/user/profile/${values.image}`}
                    target="_blank"
                  >
                    <img
                      src={`${server}/user/profile/${values.image}`}
                      alt=""
                      width="20%"
                    />
                  </Link>
                </Tooltip>
              </div>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="phone_number" className="form-label">
              Country
            </label>
            <SelectBox
              name="country"
              options={country}
              val={values.country}
              change={handleSelectBox}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="form-label">
              Email<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className={`form-control ${errors.email && "is-invalid"}`}
              value={values?.email || ""}
              readOnly
            />
            {errors.email && (
              <small className="text-danger mt-1">{errors.email}</small>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="phone_number" className="form-label">
              Phone Number
            </label>
            <input
              type="text"
              name="phone_number"
              id="phone_number"
              className="form-control"
              value={values?.phone_number || ""}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="facebook" className="form-label ">
              Facebook
            </label>
            <input
              type="text"
              name="facebook"
              id="facebook"
              className="form-control"
              onChange={handleChange}
              value={values?.facebook || ""}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="twitter" className="form-label">
              Twiiter
            </label>
            <input
              type="text"
              name="twitter"
              id="twitter"
              className="form-control"
              onChange={handleChange}
              value={values?.twitter || ""}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="instagram" className="form-label">
              Instagram
            </label>
            <input
              type="text"
              name="instagram"
              id="instagram"
              className="form-control"
              onChange={handleChange}
              value={values?.instagram || ""}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="google_plus" className="form-label">
              Google Plus
            </label>
            <input
              type="text"
              name="google_plus"
              id="google_plus"
              className="form-control"
              onChange={handleChange}
              value={values?.google_plus || ""}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="linked_in" className="form-label">
              LinkedIn
            </label>
            <input
              type="text"
              name="linked_in"
              id="linked_in"
              className="form-control"
              onChange={handleChange}
              value={values?.linked_in || ""}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="youtube_url" className="form-label m-0">
              Youtube video url
            </label>
            <div className="OrganizerOptionDescBox">
              <FaCircleInfo className="OrganizerOptionDescIcon" />
              <span className="OrganizerOptionDesc">
                If you have an Youtube video that represents your activities as
                an event organizer, add it in the standard format:
                https://www.youtube.com/watch?v=FzG4uDgje3M
              </span>
            </div>
            <input
              type="text"
              name="youtube_url"
              id="youtube_url"
              className="form-control"
              onChange={handleChange}
              value={values?.youtube_url || ""}
            />
          </div>

          <div className="ProfileSettingOptionsContainer mb-4">
            <div className="ProfileSettingOptionsBox mb-2">
              <label htmlFor="attendees_show" className="form-label fw-600 m-0">
                Show venues map<span style={{ color: "red" }}>*</span>
              </label>

              <div className="OrganizerOptionDescBox p-0">
                <FaCircleInfo className="OrganizerOptionDescIcon" />
                <span className="OrganizerOptionDesc">
                  Show a map at the bottom of your organizer profile page
                  containing the venues you added
                </span>
              </div>

              <div className="ProfileSettingRadioBtns">
                <Radio
                  className="ProfileSettingRadioBtn fw-600"
                  name="venues"
                  checked={values?.venues == 1}
                  onChange={handleChange}
                  value={1}
                >
                  Show
                </Radio>
                <Radio
                  className="ProfileSettingRadioBtn fw-600"
                  name="venues"
                  checked={values?.venues == 0}
                  value={0}
                  onChange={handleChange}
                >
                  Hide
                </Radio>
              </div>
            </div>

            <div className="ProfileSettingOptionsBox mb-2">
              <label htmlFor="attendees_show" className="form-label fw-600 m-0">
                Show followers <span style={{ color: "red" }}>*</span>
              </label>

              <div className="OrganizerOptionDescBox p-0">
                <FaCircleInfo className="OrganizerOptionDescIcon" />
                <span className="OrganizerOptionDesc">
                  Show the number and list of people that follow you
                </span>
              </div>

              <div className="ProfileSettingRadioBtns">
                <Radio
                  className="ProfileSettingRadioBtn fw-600"
                  name="followers"
                  checked={values?.followers == 1}
                  onChange={handleChange}
                  value={1}
                >
                  Show
                </Radio>
                <Radio
                  className="ProfileSettingRadioBtn fw-600"
                  name="followers"
                  checked={values?.followers == 0}
                  value={0}
                  onChange={handleChange}
                >
                  Hide
                </Radio>
              </div>
            </div>

            <div className="ProfileSettingOptionsBox">
              <label htmlFor="attendees_show" className="form-label fw-600 m-0">
                Show reviews <span style={{ color: "red" }}>*</span>
              </label>

              <div className="OrganizerOptionDescBox p-0">
                <FaCircleInfo className="OrganizerOptionDescIcon" />
                <span className="OrganizerOptionDesc">
                  Show the reviews that you received for your events
                </span>
              </div>

              <div className="ProfileSettingRadioBtns">
                <Radio
                  className="ProfileSettingRadioBtn fw-600"
                  name="reviews"
                  checked={values.reviews == 1}
                  onChange={handleChange}
                  value={1}
                >
                  Show
                </Radio>
                <Radio
                  className="ProfileSettingRadioBtn fw-600"
                  name="reviews"
                  checked={values.reviews == 0}
                  value={0}
                  onChange={handleChange}
                >
                  Hide
                </Radio>
              </div>
            </div>
          </div>

          <div className="form-group mb-3">
            <button
              className="btn btn-dark rounded-5"
              type="submit"
              disabled={isPending ? "disabled" : ""}
            >
              {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
