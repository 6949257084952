import { FaSyncAlt } from "react-icons/fa";
import "./style.css";
import { Table } from "./Table";
import { useDispatch, useSelector } from "react-redux";
import { fetchTickets } from "../../../../../store/slice/Cart";
import { useEffect } from "react";

const MyTickets = () => {
  const dispatch = useDispatch();
  const { tickets, loading, error } = useSelector((state) => state.CartDetails);

  useEffect(() => {
    dispatch(
      fetchTickets({
        getData: "payment_fees",
        state: "payment_settings",
        token: "",
      })
    );
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table data={tickets} />
      )}
    </>
  );
};

export default MyTickets;
