import { Dropdown, Space } from "antd";
import { AiTwotoneSetting } from "react-icons/ai";
import { FaEllipsisV, FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";

export const Table = () => {
  const items = [
    {
      label: "Edit",
      key: "0",
    },
    {
      label: "Hide",
      key: "1",
    },
    {
      label: "Delete",
      key: "3",
    },
  ];
  return (
    <div className="row organizer-event">
      <div className="col-12">
        <div className="card">
          <div className="table-responsive">
            <table className="table table-hover table-vcenter">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Slug</th>
                  <th>Link</th>
                  <th className="text-center">
                    <AiTwotoneSetting />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Terms of service</td>
                  <td>terms-of-service</td>
                  <td className="">
                    <Link to="/#/">/en/page/terms-of-service</Link>
                  </td>
                  <td className="text-center">
                    <Dropdown
                      menu={{
                        items,
                      }}
                      trigger={["click"]}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space className="justify-content-center">
                          <FaEllipsisV />
                        </Space>
                      </a>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td>Terms of service</td>
                  <td>terms-of-service</td>
                  <td className="">
                    <Link to="/#/">/en/page/terms-of-service</Link>
                  </td>
                  <td className="text-center">
                    <Dropdown
                      menu={{
                        items,
                      }}
                      trigger={["click"]}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space className="justify-content-center">
                          <FaEllipsisV />
                        </Space>
                      </a>
                    </Dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
