import { Dropdown, Modal, Pagination, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaAt,
  FaCalendar,
  FaCartArrowDown,
  FaEllipsisV,
  FaFileAlt,
  FaImage,
  FaInfoCircle,
  FaRegWindowClose,
  FaStar,
  FaTicketAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Alert from "../../../../global/alert/Alert";
import { formatDate } from "../../../../../utils/CustomFunctions";
import { SlCalender } from "react-icons/sl";
import { useSelector } from "react-redux";

export const Table = ({ reports }) => {
  const server = process.env.REACT_APP_SERVER;
  const { countries, audiences } = useSelector((state) => state.AllData);
  const [openModel, setOpenModel] = useState(false);
  const [modelEevnt, setModelEevnt] = useState({});
  const [filteredAudience, setfilteredAudience] = useState([]);

  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No reports found",
  };
  const handleModelEvent = (e, id) => {
    e.preventDefault();
    setModelEevnt(reports.find((report) => report?.event?.id === id));
    setOpenModel(true);
  };

  function validDate(start, end) {
    const currentDate = new Date();
    const isSart = new Date(start) <= currentDate;
    const isEnd = new Date(end) < currentDate;
    return { isSart, isEnd };
  }
  const ticketSale = validDate(
    modelEevnt?.event?.tickets?.[0]?.sale_start,
    modelEevnt?.event?.tickets?.[0]?.sale_end
  );
  const event = validDate(
    modelEevnt?.event?.start_On,
    modelEevnt?.event?.end_On
  );

  // {
  //   label: (
  //     <Link
  //       to="/en/dashboard/administrator/manage-payout-requests"
  //       className="d-flex align-items-center"
  //     >
  //       <FaFileInvoice className="me-1" />
  //       Payout requests
  //     </Link>
  //   ),
  //   key: "3",
  // },

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = reports?.slice(indexOfFirstData, indexOfLastData);

  useEffect(() => {
    if (openModel) {
      // Convert string IDs to integers
      const firstArrayInt = modelEevnt?.event?.audiences?.map((idStr) =>
        parseInt(idStr)
      );
      // Filter second array based on IDs present in the first array
      const filteredArray = audiences.filter((item) =>
        firstArrayInt?.includes(item.id)
      );
      setfilteredAudience(filteredArray);
    }
  }, [modelEevnt]);

  return (
    <>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={openModel}
        onCancel={() => setOpenModel(false)}
        className="Event Model"
      >
        {openModel && (
          <>
            <div className="ModelBox">
              <div className="ModelHeader">
                <span className="ModelBoxBuyTicketText">
                  {modelEevnt?.event?.name}
                </span>
              </div>
              <div className="ModelContent">
                <div className="jconfirm-content" id="jconfirm-box19401">
                  <div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="table-responsive">
                          <table className="table table-borderless table-striped table-hover table-sm">
                            <thead>
                              <tr>
                                <th
                                  colSpan="2"
                                  className="d-flex align-items-center"
                                >
                                  <FaFileAlt className="me-1" />
                                  General information
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td width="30%">Status</td>
                                <td>
                                  {modelEevnt?.event?.status === "inactive" && (
                                    <span className="badge badge-warning">
                                      Event is not yet published.
                                    </span>
                                  )}

                                  {modelEevnt?.event?.status === "active" &&
                                    (modelEevnt?.event?.tickets?.[0]
                                      ?.enable_sales_ticket === 0 ||
                                      modelEevnt?.event?.enable_event_date ===
                                        0 ||
                                      !ticketSale.isSart ||
                                      ticketSale.isEnd) && (
                                      <span className="badge badge-info">
                                        No events on sale
                                      </span>
                                    )}
                                  {modelEevnt?.event?.status === "active" &&
                                    modelEevnt?.event?.tickets?.[0]
                                      ?.enable_sales_ticket === 1 &&
                                    modelEevnt?.event?.enable_event_date ===
                                      1 &&
                                    ticketSale.isSart &&
                                    !ticketSale.isEnd && (
                                      <span className="badge badge-success">
                                        on sale
                                      </span>
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td width="30%">Title</td>
                                <td>{modelEevnt?.event?.name}</td>
                              </tr>
                              <tr>
                                <td>Organizer</td>
                                <td>
                                  {
                                    modelEevnt?.event?.venue?.user
                                      ?.organize_name
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Reference</td>
                                <td>{modelEevnt?.event?.reference}</td>
                              </tr>
                              <tr>
                                <td>Creation date</td>
                                <td>
                                  {
                                    formatDate(
                                      new Date(modelEevnt?.event?.created_at)
                                    ).fullDate
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Update date</td>
                                <td>
                                  {
                                    formatDate(
                                      new Date(modelEevnt?.event?.updated_at)
                                    ).fullDate
                                  }
                                </td>
                              </tr>

                              {/* <tr>
                                <td>Added to favorites by</td>
                                <td>0 user(s)</td>
                              </tr> */}
                              <tr>
                                <td>Category</td>
                                <td>{modelEevnt?.event?.category?.name}</td>
                              </tr>
                              {modelEevnt?.event?.languages && (
                                <tr>
                                  <td>Languages</td>
                                  <td className="text-capitalize">
                                    {modelEevnt?.event?.languages}
                                  </td>
                                </tr>
                              )}
                              {modelEevnt?.event?.subtitles &&
                                modelEevnt?.event?.subtitles?.length > 0 && (
                                  <tr>
                                    <td>Subtitles</td>
                                    <td className="text-capitalize">
                                      {modelEevnt?.event?.subtitles?.map(
                                        (subtitle, index) => (
                                          <React.Fragment key={index}>
                                            {subtitle},{" "}
                                          </React.Fragment>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                )}
                              {modelEevnt?.event?.audiences &&
                                modelEevnt?.event?.audiences?.length > 0 && (
                                  <tr>
                                    <td>Audiences</td>
                                    <td className="text-capitalize">
                                      {filteredAudience?.map(
                                        (audience, index) => (
                                          <React.Fragment key={index}>
                                            {audience.name},{" "}
                                          </React.Fragment>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                )}
                              {modelEevnt?.event?.country && (
                                <tr>
                                  <td>Country</td>
                                  <td className="text-capitalize">
                                    {countries?.map((curElem) => {
                                      if (
                                        curElem.id == modelEevnt?.event?.country
                                      ) {
                                        return (
                                          <React.Fragment key={curElem.id}>
                                            {curElem.name}
                                          </React.Fragment>
                                        );
                                      }
                                      return null; // or any other default value if the condition is not met
                                    })}
                                  </td>
                                </tr>
                              )}
                              {modelEevnt?.event?.artists &&
                                modelEevnt?.event?.artists?.length > 0 && (
                                  <tr>
                                    <td>Artists</td>
                                    <td>
                                      {modelEevnt?.event?.artists?.map(
                                        (artist, index) => (
                                          <React.Fragment key={index}>
                                            {artist},{" "}
                                          </React.Fragment>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                )}
                              {modelEevnt?.event?.tags?.length > 0 && (
                                <tr>
                                  <td>Tags</td>
                                  <td>
                                    {modelEevnt?.event?.tags?.map(
                                      (tag, index) => (
                                        <React.Fragment key={index}>
                                          {tag},{" "}
                                        </React.Fragment>
                                      )
                                    )}
                                  </td>
                                </tr>
                              )}
                              {modelEevnt?.event?.year && (
                                <tr>
                                  <td>Year</td>
                                  <td>{modelEevnt?.event?.year}</td>
                                </tr>
                              )}
                              {modelEevnt?.event?.youtube_video_url && (
                                <tr>
                                  <td>Youtube video</td>
                                  <td>
                                    {modelEevnt?.event?.youtube_video_url}
                                  </td>
                                </tr>
                              )}

                              <tr>
                                <td>Publicly show attendees</td>
                                <td>
                                  {modelEevnt?.event?.attendees_show === 1
                                    ? "Yes"
                                    : "No"}
                                </td>
                              </tr>
                              <tr>
                                <td>Allow attendees to leave reviews</td>
                                <td>
                                  {modelEevnt?.event?.review === 1
                                    ? "Yes"
                                    : "No"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="table-responsive">
                          <table className="table table-borderless table-striped table-hover table-sm">
                            <thead>
                              <tr>
                                <th
                                  colSpan="2"
                                  className="d-flex align-items-center"
                                >
                                  <FaImage className="me-1" />
                                  Images
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td width="30%">Main image</td>
                                <td>
                                  {modelEevnt?.event?.main_event_image ? (
                                    <Tooltip title="Enlarge">
                                      <Link
                                        className="fancybox"
                                        to={`${server}/event/image/${modelEevnt?.event?.main_event_image}`}
                                        target="_blank"
                                      >
                                        <img
                                          src={`${server}/event/image/${modelEevnt?.event?.main_event_image}`}
                                          className="img-thumbnail img-50-50"
                                          width="50%"
                                        />
                                      </Link>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Enlarge">
                                      <Link
                                        className="fancybox"
                                        to="/./images/noPhoto.png"
                                        target="_blank"
                                      >
                                        <img
                                          src="/./images/noPhoto.png"
                                          className="img-thumbnail img-50-50"
                                          width="50%"
                                        />
                                      </Link>
                                    </Tooltip>
                                  )}
                                </td>
                              </tr>
                              {modelEevnt?.event?.images &&
                                modelEevnt?.event?.images?.length > 0 && (
                                  <tr>
                                    <td>Gallery</td>
                                    <td>
                                      {modelEevnt?.event?.images?.length}{" "}
                                      image(s)
                                      <div className="gallery photos-gallery d-flex">
                                        {modelEevnt?.event?.images?.map(
                                          (image) => (
                                            <figure
                                              key={image.id}
                                              style={{ width: "30%" }}
                                            >
                                              <Tooltip title="Enlarge">
                                                <Link
                                                  className="fancybox"
                                                  to={`${server}/event/gallery/${image?.image}`}
                                                  target="_blank"
                                                >
                                                  <img
                                                    src={`${server}/event/gallery/${image?.image}`}
                                                    width="100%"
                                                    className="img-thumbnail img-50-50"
                                                    style={{ height: "70px" }}
                                                  />
                                                </Link>
                                              </Tooltip>
                                            </figure>
                                          )
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="table-responsive">
                          <table className="table table-borderless table-sm">
                            <thead>
                              <tr>
                                <th>
                                  <span className="d-flex align-items-center">
                                    <FaCalendar className="me-1" />
                                    Event dates
                                  </span>
                                </th>
                                <th>
                                  <span className="d-flex align-items-center">
                                    <FaTicketAlt className="me-1" />
                                    Tickets Sale
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td width="50%">
                                  <h6>Event date 1</h6>
                                  <div className="table-responsive">
                                    <table className="table table-borderless table-striped table-hover table-sm">
                                      <tbody>
                                        <tr>
                                          <td>Start date</td>
                                          <td>
                                            {
                                              formatDate(
                                                new Date(
                                                  modelEevnt?.event?.start_On
                                                )
                                              ).fullDate
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>End date</td>
                                          <td>
                                            {
                                              formatDate(
                                                new Date(
                                                  modelEevnt?.event?.end_On
                                                )
                                              ).fullDate
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="30%">Status</td>
                                          <td>
                                            {modelEevnt?.event?.status ===
                                              "active" &&
                                              (modelEevnt?.event?.tickets?.[0]
                                                ?.enable_sales_ticket === 0 ||
                                                modelEevnt.event
                                                  ?.enable_event_date === 0 ||
                                                !ticketSale.isSart) && (
                                                <span className="badge badge-info">
                                                  No events on sale
                                                </span>
                                              )}
                                            {modelEevnt?.event?.status ===
                                              "active" &&
                                              modelEevnt?.event?.tickets?.[0]
                                                ?.enable_sales_ticket === 1 &&
                                              modelEevnt?.event
                                                ?.enable_event_date === 1 &&
                                              ticketSale.isSart &&
                                              !ticketSale.isEnd &&
                                              !event.isSart && (
                                                <span className="badge badge-success">
                                                  on sale
                                                </span>
                                              )}

                                            {modelEevnt?.event?.status ===
                                              "active" &&
                                              modelEevnt?.event?.tickets?.[0]
                                                ?.enable_sales_ticket === 1 &&
                                              modelEevnt?.event
                                                ?.enable_event_date === 1 &&
                                              ticketSale.isSart &&
                                              !ticketSale.isEnd &&
                                              event.isSart && (
                                                <span className="badge badge-info">
                                                  Event already started
                                                </span>
                                              )}

                                            {modelEevnt?.event?.status ===
                                              "active" &&
                                              modelEevnt?.event?.tickets?.[0]
                                                ?.enable_sales_ticket === 1 &&
                                              modelEevnt?.event
                                                ?.enable_event_date === 1 &&
                                              ticketSale.isSart &&
                                              ticketSale.isEnd && (
                                                <span className="badge badge-success">
                                                  No ticket on sale
                                                </span>
                                              )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Reference</td>
                                          <td>
                                            {modelEevnt?.event?.reference}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                                <td>
                                  <h6>Ticket 1</h6>
                                  <div className="table-responsive">
                                    <table className="table table-borderless table-striped table-hover table-sm">
                                      <tbody>
                                        <tr>
                                          <td>Ticket Price</td>
                                          <td>
                                            ${modelEevnt?.average_ticket_fees}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="30%">Ticket Tax</td>
                                          <td>${modelEevnt?.ticket_fees}</td>
                                        </tr>
                                        <tr>
                                          <td>Ticket percentage cut</td>
                                          <td>${modelEevnt?.percentage_cut}</td>
                                        </tr>
                                        <tr>
                                          <td>Tickets sale</td>
                                          <td>{modelEevnt?.total_ticket}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="table-responsive">
                          <table className="table table-borderless table-striped table-hover table-sm">
                            <thead>
                              <tr>
                                <th
                                  colSpan="2"
                                  className="d-flex align-items-center"
                                >
                                  <FaAt className="me-1" />
                                  Contact &amp; Social media
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {modelEevnt?.event?.external_link && (
                                <tr>
                                  <td>External link</td>
                                  <td>{modelEevnt?.event?.external_link}</td>
                                </tr>
                              )}
                              {modelEevnt?.event?.contact_email && (
                                <tr>
                                  <td>Email</td>
                                  <td>{modelEevnt?.event?.contact_email}</td>
                                </tr>
                              )}

                              {modelEevnt?.event?.phone_number && (
                                <tr>
                                  <td>Phone number</td>
                                  <td>{modelEevnt?.event?.phone_number}</td>
                                </tr>
                              )}
                              {modelEevnt?.event?.facebook && (
                                <tr>
                                  <td>Facebook</td>
                                  <td>{modelEevnt?.event?.facebook}</td>
                                </tr>
                              )}
                              {modelEevnt?.event?.twitter && (
                                <tr>
                                  <td>Twitter</td>
                                  <td>{modelEevnt?.event?.twitter}</td>
                                </tr>
                              )}
                              {modelEevnt?.event?.google_plus && (
                                <tr>
                                  <td>Google plus</td>
                                  <td>{modelEevnt?.event?.google_plus}</td>
                                </tr>
                              )}
                              {modelEevnt?.event?.instagram && (
                                <tr>
                                  <td>Instagram</td>
                                  <td>{modelEevnt?.event?.instagram}</td>
                                </tr>
                              )}
                              {modelEevnt?.event?.linkedIn && (
                                <tr>
                                  <td>Linkedin</td>
                                  <td>{modelEevnt?.event?.linkedIn}</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="table-responsive">
                          <table className="table table-borderless table-striped table-hover table-sm">
                            <thead>
                              <tr>
                                <th
                                  colSpan="2"
                                  className="d-flex align-items-center"
                                >
                                  <FaStar className="me-1" />
                                  Reviews
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td width="30%">Count</td>
                                <td>{modelEevnt?.event?.reviews.length}</td>
                              </tr>
                              <tr>
                                <td>Rating</td>
                                <td>
                                  <div className="rating-wrap">
                                    <div className="label-rating">
                                      {modelEevnt?.event?.average_rating || 0}{" "}
                                      out of 5 stars
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
              <button
                className="btn btn-outline-dark btn-sm rounded-5 d-inline-flex align-items-center gap-2"
                onClick={() => setOpenModel(false)}
              >
                <FaRegWindowClose className="ModelBoxTicketPurchaseTitleIcon" />
                close
              </button>
            </div>
          </>
        )}
      </Modal>
      <div className="row organizer-event">
        <div className="col-12">
          {reports && reports.length > 0 ? (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter">
                  <thead>
                    <tr>
                      <th className="text-center">Reference</th>
                      <th>Event date</th>
                      <th>Organizer</th>
                      <th>Tickets sold</th>
                      <th>Net sales</th>
                      <th>Ticket fees</th>
                      <th>Percentage cut</th>
                      <th>Status</th>
                      <th className="text-center">
                        <AiTwotoneSetting />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((report, index) => {
                      const items = [
                        {
                          label: (
                            <Link
                              to="/en/dashboard/administrator/manage-events"
                              className="d-flex align-items-center"
                            >
                              <SlCalender className="me-1" />
                              Manage event
                            </Link>
                          ),
                          key: "0",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              onClick={(e) =>
                                handleModelEvent(e, report.event.id)
                              }
                              className="d-flex align-items-center"
                            >
                              <FaFileAlt className="me-1" />
                              Details
                            </Link>
                          ),
                          key: "1",
                        },
                        {
                          label: (
                            <Link
                              to="/en/dashboard/administrator/manage-orders"
                              className="d-flex align-items-center"
                            >
                              <FaCartArrowDown className="me-1" />
                              Orders
                            </Link>
                          ),
                          key: "2",
                        },
                      ];
                      const ticketSale = validDate(
                        report?.event?.tickets?.[0]?.sale_start,
                        report?.event?.tickets?.[0]?.sale_end
                      );
                      const event = validDate(
                        report?.event?.start_On,
                        report?.event?.end_On
                      );

                      return (
                        <tr key={index}>
                          <td className="text-center text-muted text-sm">
                            {report?.event?.reference}
                          </td>
                          <td className="text-sm">
                            {report?.event?.name}
                            <br />
                            {
                              formatDate(new Date(report?.event?.start_On))
                                .fullDate
                            }
                          </td>
                          <td>{report?.event?.venue?.user?.organize_name}</td>
                          <td>{report?.total_ticket}</td>
                          <td>${report?.net_sale?.toFixed(2)}</td>

                          <td>${report?.ticket_fees}</td>
                          <td>${report?.percentage_cut}</td>
                          <td className="">
                            {report?.event?.status === "inactive" && (
                              <span className="badge badge-warning">
                                Event is not yet published.
                              </span>
                            )}
                            {report?.event?.status === "active" &&
                              (report?.event?.tickets?.[0]
                                ?.enable_sales_ticket === 0 ||
                                report?.event?.enable_event_date === 0 ||
                                !ticketSale.isSart ||
                                ticketSale.isEnd) && (
                                <span className="badge badge-info">
                                  No events on sale
                                </span>
                              )}
                            {report?.event?.status === "active" &&
                              report?.event?.tickets?.[0]
                                ?.enable_sales_ticket === 1 &&
                              report?.event?.enable_event_date === 1 &&
                              ticketSale.isSart &&
                              !event.isSart && (
                                <span className="badge badge-success">
                                  on sale
                                </span>
                              )}
                            {report?.event?.status === "active" &&
                              report?.event?.tickets?.[0]
                                ?.enable_sales_ticket === 1 &&
                              report?.event?.enable_event_date === 1 &&
                              ticketSale.isSart &&
                              !ticketSale.isEnd &&
                              event.isSart && (
                                <span className="badge badge-info">
                                  Event already started
                                </span>
                              )}
                          </td>
                          <td className="text-center">
                            <Dropdown
                              menu={{
                                items,
                              }}
                              trigger={["click"]}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                  <FaEllipsisV />
                                </Space>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <Alert {...msg} />
          )}
        </div>
      </div>

      {reports?.length > 10 && (
        <Pagination
          current={currentPage}
          total={reports.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};
