import React from "react";

const ManageCartQuantity = ({ quantity, setIncreaseQty, setDecreaseQty }) => {
  return (
    <div className="input-group count">
      <input
        type="text"
        className="form-control"
        // name={name}
        value={quantity}
        readOnly
      />
      <span className="input-group-btn-vertical">
        <button
          className="btn btn-dark bootstrap-touchspin-up"
          type="button"
          onClick={() => setIncreaseQty()}
        >
          +
        </button>
        <button
          className="btn btn-dark bootstrap-touchspin-down "
          type="button"
          // disabled={value < 1 && "disabled"}
          onClick={() => setDecreaseQty()}
        >
          -
        </button>
      </span>
    </div>
  );
};

export default ManageCartQuantity;
