import React, { useEffect } from "react";
import Breadcrumb from "../../components/global/Breadcrumb";
import { Layout } from "../../components/LayoutUser/Layout";
import { useDispatch, useSelector } from "react-redux";
import Blogs from "../../components/blog/Blogs";
import { filteredBlogs } from "../../store/slice/BlogFilters";
import { fetchAllData } from "../../store/slice/AllData";

const Blog = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.blogFilters);
  useEffect(() => {
    dispatch(filteredBlogs());
    window.scrollTo(0, 0);
  }, [filters]);

  useEffect(() => {
    dispatch(
      fetchAllData({
        getData: "blog_setting",
        state: "blog_setting",
      })
    );
  }, []);

  return (
    <Layout>
      <Breadcrumb page={"Blog"} />
      <Blogs />
    </Layout>
  );
};

export default Blog;
