import React from "react";
import { Layout } from "../../components/LayoutUser/Layout";
import { FaInbox } from "react-icons/fa";

export const CheckEmail = () => {
  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-sm-12">
            <div className="card box pt-3 pb-3">
              <div className="card-body pt-5 pb-5">
                <div className="col-12 col-md-6 mx-auto">
                  <p className="text-center">
                    <FaInbox
                      className="text-success"
                      style={{ fontSize: "8rem" }}
                    />
                  </p>
                  <p className="text-center">
                    An email has been sent. It contains a link you must click to
                    reset your password.
                    <br />
                    Note: You can only request a new password once within 2
                    hours.
                    <br />
                    <br />
                    If you don't get an email check your spam folder or try
                    again.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
