import React from "react";
import Breadcrumb from "../global/Breadcrumb";
import { Layout } from "../LayoutUser/Layout";
import { FaSyncAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addFilters } from "../../store/slice/Filters";

const EventCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { categories, loading } = useSelector((state) => state.AllData);
  const server = process.env.REACT_APP_SERVER;

  const func = (e, name, value) => {
    e.preventDefault();
    pathname === "/en/events"
      ? dispatch(addFilters({ name, value }))
      : navigate("/en/events", {
          state: { name, value },
        });
  };

  return (
    <Layout>
      <Breadcrumb page={"Event categories"} />
      {/* <Content /> */}
      <div class="container mt-5 mb-5">
        <div class="row">
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <FaSyncAlt className="spinIcon" />
            </div>
          ) : (
            categories &&
            categories?.length > 0 &&
            categories?.map((category) => (
              <div className="col-sm-6 col-md-4" key={category.id}>
                <Link
                  to=""
                  onClick={(e) => func(e, "category", category.id)}
                  className="zoom-wrap"
                >
                  <div className="card-banner-c mb-3">
                    <div
                      className="card-body zoom-in img-lazy-load b-loaded"
                      style={{
                        height: "250px",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundImage: `url(${
                          category?.image
                            ? `${server}/event/category/image/${category?.image}`
                            : "/images/no.png"
                        })`, // Use backticks for dynamic URL
                      }}
                    ></div>
                    <div className="text-bottom">
                      <h4 className="title">{category.name}</h4>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EventCategory;
