import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage

import userReducer from "./slice/User";
import AdminReducer from "./slice/Admin";
import OrganizerReducer from "./slice/Organizer";
import AllDataReducer from "./slice/AllData";
import CartReducer from "./slice/Cart";
import AttendeeReducer from "./slice/Attendee";
import FiltersReducer from "./slice/Filters";
import venueFiltersReducer from "./slice/venueFilters";
import blogFiltersReducer from "./slice/BlogFilters";
import OrganizerFiltersReducer from "./slice/OrganizerFilters";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  authUser: userReducer,
  admin: AdminReducer,
  organizer: OrganizerReducer,
  attendee: AttendeeReducer,
  AllData: AllDataReducer,
  CartDetails: CartReducer,
  Filters: FiltersReducer,
  venueFilters: venueFiltersReducer,
  blogFilters: blogFiltersReducer,
  organizerFilters: OrganizerFiltersReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }),
});

export const persistor = persistStore(store);
