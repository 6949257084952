import React from "react";
import { useAddEntity } from "../../../../../../utils/CustomFunctions";
import { FaSyncAlt } from "react-icons/fa";

const AddVenuesTypes = () => {
  const initialState = {
    name: "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    if (!values.name) newErrors.name = "This value should not be null.";
    return newErrors;
  };
  const { isPending, errors, newEntity, handleChange, handleSubmit } =
    useAddEntity(
      "admin",
      "venue_type",
      "venue_types",
      initialState,
      validateFields,
      "/en/dashboard/administrator/manage-venues-types"
    );

  return (
    <div className="card">
      <div className="card-body">
        <form className="signform" onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="mb-1">
              <label htmlFor="name" className="form-label fw-600">
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control"
                value={newEntity?.name}
                onChange={handleChange}
              />
            </div>
            {errors.name && (
              <small className="text-danger d-block">{errors.name}</small>
            )}
          </div>

          <div className="form-group mt-3 mb-3">
            <button
              className="btn btn-dark rounded-5"
              disabled={isPending ? "disabled" : ""}
              type="submit"
            >
              {isPending ? <FaSyncAlt className="spinIcon" /> : "save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddVenuesTypes;
