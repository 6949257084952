import { FaCircleInfo } from "react-icons/fa6";
import "../layout/style.scss";
import { Radio } from "antd";
import { useSignUp } from "../../../../../../utils/CustomFunctions";
import { FaSyncAlt } from "react-icons/fa";
import { useSelector } from "react-redux";

const Newsletter = () => {
  const { news_litter } = useSelector((state) => state.admin);

  const initialState = {
    mail_chimp: news_litter?.mail_chimp || "yes",
    mail_chimp_app_id: news_litter?.mail_chimp_app_id || "",
    mail_chimp_list_id: news_litter?.mail_chimp_list_id || "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    if (!values.mail_chimp_app_id.trim())
      newErrors.mail_chimp_app_id = "This value should not be null.";
    if (!values.mail_chimp_list_id.trim())
      newErrors.mail_chimp_list_id = "This value should not be null.";

    return newErrors;
  };

  const { values, errors, isPending, handleChange, handleSubmit } = useSignUp(
    initialState,
    validateFields,
    `admin/add_news_litter_setting`,
    "news_litter"
  );

  return (
    <>
      <div className="card box">
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form onSubmit={handleSubmit} className="signform mt-3 ">
              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Enable newsletter
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfleSettingOptionDescBox">
                    <FaCircleInfo className="ProfleSettingOptionDescIcon" />
                    <span className="ProfleSettingOptionDesc">
                      The event date stats (sales and attendance) will be
                      visible on the scanner app
                    </span>
                  </div>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="mail_chimp"
                      checked={values.mail_chimp === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Show
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="mail_chimp"
                      checked={values.mail_chimp === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      Hide
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="app_key" className="form-label fw-600">
                  Mailchimp app id
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    Go to the documentation to get help about getting an api key
                  </span>
                </div>
                <input
                  type="text"
                  name="mail_chimp_app_id"
                  id="app_key"
                  className="form-control"
                  value={values.mail_chimp_app_id}
                  onChange={handleChange}
                />
                {errors.mail_chimp_app_id && (
                  <small className="text-danger d-block mt-1">
                    {errors.mail_chimp_app_id}
                  </small>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="list_id" className="form-label fw-600">
                  Mailchimp list id
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    Go to the documentation to get help about getting a list id
                  </span>
                </div>
                <input
                  type="text"
                  name="mail_chimp_list_id"
                  id="list_id"
                  className="form-control"
                  value={values.mail_chimp_list_id}
                  onChange={handleChange}
                />
                {errors.mail_chimp_list_id && (
                  <small className="text-danger d-block mt-1">
                    {errors.mail_chimp_list_id}
                  </small>
                )}
              </div>

              <div className="form-group mt-4 mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  disabled={isPending ? "disabled" : ""}
                  type="submit"
                >
                  {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newsletter;
