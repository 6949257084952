import { FaCircleInfo } from "react-icons/fa6";
import "./style.scss";
import { Radio } from "antd";
import Tabs from "./Tabs";
import TabContent from "./TabContent";
import { useSelector } from "react-redux";
import { useSignUp } from "../../../../../../utils/CustomFunctions";
import SelectBox from "./../../../../../global/SelectBox";
import { page_slug, timezones } from "../../../../../global/Values";
import { FaSyncAlt } from "react-icons/fa";

const Layout = () => {
  const { layout_settings } = useSelector((state) => state.admin);
  const server = process.env.REACT_APP_SERVER;

  const initialState = {
    environment: layout_settings?.environment || "yes",
    debugging: layout_settings?.debugging || "enable",
    secret: layout_settings?.secret || "",
    maintenance: layout_settings?.maintenance || "enable",
    maintenance_custom: layout_settings?.maintenance_custom || "",
    date_time: layout_settings?.date_time || "",
    alternative_date_time: layout_settings?.alternative_date_time || "",
    date: layout_settings?.date || "",
    time: layout_settings?.time || "",
    languag: layout_settings?.languag || "",
    website_name: layout_settings?.website_name || "",
    website_slug: layout_settings?.website_slug || "",
    website_url: layout_settings?.website_url || "",
    website_root_url: layout_settings?.website_root_url || "",
    website: layout_settings?.website || "",
    Website_description_input: layout_settings?.Website_description_input || "",
    Description_du_site_input: layout_settings?.Description_du_site_input || "",
    Description_del_lugar_input:
      layout_settings?.Description_del_lugar_input || "",
    وصف_الموقع_input: layout_settings?.وصف_الموقع_input || "",
    Webseitenbeschreibung_input:
      layout_settings?.Webseitenbeschreibung_input || "",
    Descrição_do_site_input: layout_settings?.Descrição_do_site_input || "",

    website_2: layout_settings?.website_2 || "",
    Website_keywords_input: layout_settings?.Website_keywords_input || "",
    Mots_clés_du_site_input: layout_settings?.Description_du_site_input || "",
    Palabras_clave_del_sitio_input:
      layout_settings?.Palabras_clave_del_sitio_input || "",
    الكلمات_الرئيسية_للموقع_input:
      layout_settings?.الكلمات_الرئيسية_للموقع_input || "",
    Website_Schlüsselwörter_input:
      layout_settings?.Website_Schlüsselwörter_input || "",
    Palavras_chave_do_site_input:
      layout_settings?.Palavras_chave_do_site_input || "",

    phone: layout_settings?.phone || "",
    fax: layout_settings?.fax || "",
    address: layout_settings?.address || "",
    facebook: layout_settings?.facebook || "",
    instagram: layout_settings?.instagram || "",
    youtube: layout_settings?.youtube || "",
    twitter: layout_settings?.twitter || "",
    layout: layout_settings?.layout || "compact",
    color_code: layout_settings?.color_code || "",
    top_button: layout_settings?.top_button || "yes",
    service_page_link: layout_settings?.service_page_link || "yes",
    service_page_slug: layout_settings?.service_page_slug || "",
    privacy_policy: layout_settings?.privacy_policy || "yes",
    privacy_policy_slug: layout_settings?.privacy_policy_slug || "",
    cookie_policy_page: layout_settings?.cookie_policy_page || "yes",
    cookie_policy_page_slug: layout_settings?.cookie_policy_page_slug || "",
    cookie_policy_bar_bottom:
      layout_settings?.cookie_policy_bar_bottom || "yes",
    compliance_page: layout_settings?.compliance_page || "yes",
    compliance_page_slug: layout_settings?.compliance_page_slug || "",
    custom_css: layout_settings?.custom_css || "",
    analytics_tracking: layout_settings?.analytics_tracking || "",
    social: layout_settings?.social || "",
    favicon: layout_settings?.favicon || "",
    logo: layout_settings?.logo || "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};

    // if (!values.number) newErrors.number = "This value should not be null.";

    return newErrors;
  };

  const {
    values,
    setValues,
    errors,
    isPending,
    handleChange,
    handleSelectBox,
    singleImage,
    handleSubmit,
  } = useSignUp(
    initialState,
    validateFields,
    `admin/add_layout_settings`,
    "layout_settings"
  );

  const tab1 = [
    {
      id: "website_description_en",
      title: "Website description",
      input: "Website_description_input",
    },
    {
      id: "website_description_fr",
      title: "Description du site",
      input: "Description_du_site_input",
    },
    {
      id: "website_description_es",
      title: "Descripción del lugar",
      input: "Description_del_lugar_input",
    },
    {
      id: "website_description_ar",
      title: "وصف الموق",
      input: "وصف_الموقع_input",
    },
    {
      id: "website_description_de",
      title: "Webseitenbeschreibung",
      input: "Webseitenbeschreibung_input",
    },
    {
      id: "website_description_pt",
      title: "Descrição do site",
      input: "Descrição_do_site_input",
    },
  ];

  const tab2 = [
    {
      id: "website_keywords_en",
      title: "Website keywords",
      input: "Website_keywords_input",
    },
    {
      id: "website_keywords_fr",
      title: "Mots clés du site",
      input: "Mots_clés_du_site_input",
    },
    {
      id: "website_keywords_es",
      title: "Palabras clave del sitio",
      input: "Palabras_clave_del_sitio_input",
    },
    {
      id: "website_keywords_ar",
      title: "الكلمات الرئيسية للموقع",
      input: "الكلمات_الرئيسية_للموقع_input",
    },
    {
      id: "website_keywords_de",
      title: "Website-Schlüsselwörter",
      input: "Website_Schlüsselwörter_input",
    },
    {
      id: "website_keywords_pt",
      title: "Palavras-chave do site",
      input: "Palavras_chave_do_site_input",
    },
  ];

  const language = [
    { id: "en", name: "en" },
    { id: "fr", name: "fr" },
    { id: "es", name: "es" },
    { id: "ar", name: "ar" },
    { id: "de", name: "de" },
    { id: "pt", name: "pt" },
  ];
  return (
    <>
      <div className="card box">
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form onSubmit={handleSubmit} className="signform mt-3 ">
              <div className="ProfileSettingOptionsContainer mb-4">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    App Environment
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="ProfleSettingOptionDescBox">
                    <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                    <span className="ProfleSettingOptionDesc">
                      Development environment is used for development purposes
                      only
                    </span>
                  </div>
                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="environment"
                      checked={values.environment === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Production
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="environment"
                      checked={values.environment === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      Development
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="ProfileSettingOptionsContainer mb-4">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    App Debugging
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="ProfleSettingOptionDescBox">
                    <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                    <span className="ProfleSettingOptionDesc">
                      Enable to display stacktraces on error pages or if cache
                      files should be dynamically rebuilt on each request
                    </span>
                  </div>
                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="debugging"
                      checked={values.debugging === "enable"}
                      onChange={handleChange}
                      value="enable"
                    >
                      Enable
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="debugging"
                      checked={values.debugging === "disable"}
                      onChange={handleChange}
                      value="disable"
                    >
                      Disable
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="app_secret" className="form-label fw-600">
                  App Secret<span style={{ color: "red" }}>*</span>
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    This is a string that should be unique to your application
                    and it is commonly used to add more entropy to security
                    related operations
                  </span>
                </div>
                <input
                  type="text"
                  name="secret"
                  id="app_secret"
                  className="form-control"
                  value={values.secret}
                  onChange={handleChange}
                />
                {errors.secret && (
                  <small className="text-danger d-block mt-1">
                    {errors.secret}
                  </small>
                )}
              </div>

              <div className="ProfileSettingOptionsContainer mb-4">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Maintenance mode
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="ProfleSettingOptionDescBox">
                    <small className="ProfleSettingOptionDesc">
                      <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                      Enable maintenance mode to display a maintenance page for
                      all users but the users who are granted the
                      ROLE_ADMINISTRATOR role, if you lost your session, you can
                      edit the MAINTENANCE_MODE parameter directly in the .env
                      file
                    </small>
                  </div>
                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="maintenance"
                      checked={values.maintenance === "enable"}
                      onChange={handleChange}
                      value="enable"
                    >
                      Enable
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="maintenance"
                      checked={values.maintenance === "disable"}
                      onChange={handleChange}
                      value="disable"
                    >
                      Disable
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="maintenance_custom"
                  className="form-label fw-600"
                >
                  Maintenance mode custom message
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    This is a string that should be unique to your application
                    and it is commonly used to add more entropy to security
                    related operations
                  </span>
                </div>
                <input
                  type="text"
                  name="maintenance_custom"
                  id="maintenance_custom"
                  className="form-control"
                  value={values.maintenance_custom}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="date_and_time_format"
                  className="form-label fw-600"
                >
                  Date and time format<span style={{ color: "red" }}>*</span>
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <small className="ProfleSettingOptionDesc">
                    <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                    Project wide date and time format, follow this link for a
                    list of supported characters:
                    https://unicode-org.github.io/icu/userguide/format_parse/datetime/
                    . Please make sure to keep the double quotes " " around the
                    format string
                  </small>
                </div>
                <input
                  type="text"
                  name="date_time"
                  id="date_and_time_format"
                  className="form-control"
                  value={values.date_time}
                  onChange={handleChange}
                />
                {errors.date_time && (
                  <small className="text-danger d-block mt-1">
                    {errors.date_time}
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="alternative_date_and_time_format"
                  className="form-label fw-600"
                >
                  Alternative date and time format
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <small className="ProfleSettingOptionDesc">
                    <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                    Used in some specific cases, follow this link for a list of
                    supported characters:
                    https://www.php.net/manual/en/datetime.format.php . Please
                    make sure to keep the double quotes " " around the format
                    string
                  </small>
                </div>
                <input
                  type="text"
                  name="alternative_date_time"
                  id="alternative_date_and_time_format"
                  className="form-control"
                  value={values.alternative_date_time}
                  onChange={handleChange}
                />
                {errors.alternative_date_time && (
                  <small className="text-danger d-block mt-1">
                    {errors.alternative_date_time}
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="date_only_format" className="form-label fw-600">
                  Date only format
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <small className="ProfleSettingOptionDesc">
                    <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                    Used in some specific cases, follow this link for a list of
                    supported characters:
                    https://www.php.net/manual/en/datetime.format.php . Please
                    make sure to keep the double quotes " " around the format
                    string
                  </small>
                </div>
                <input
                  type="text"
                  name="date"
                  id="date_only_format"
                  className="form-control"
                  value={values.date}
                  onChange={handleChange}
                />
                {errors.date && (
                  <small className="text-danger d-block mt-1">
                    {errors.date}
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="Timezone" className="form-label fw-600">
                  Timezone
                  <span style={{ color: "red" }}>*</span>
                </label>
                <SelectBox
                  name="time"
                  options={timezones}
                  val={values.time}
                  change={handleSelectBox}
                />
                {errors.time && (
                  <small className="text-danger d-block mt-1">
                    {errors.time}
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="Default_language" className="form-label fw-600">
                  Default language
                  <span style={{ color: "red" }}>*</span>
                </label>
                <SelectBox
                  name="languag"
                  options={language}
                  val={values.languag}
                  change={handleSelectBox}
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="Available_language"
                  className="form-label fw-600"
                >
                  Available languages
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="en"
                  />
                  <label className="form-check-label" htmlFor="en">
                    en
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="fr"
                  />
                  <label className="form-check-label" htmlFor="fr">
                    fr
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="es"
                  />
                  <label className="form-check-label" htmlFor="es">
                    es
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="ar"
                  />
                  <label className="form-check-label" htmlFor="ar">
                    ar
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="de"
                  />
                  <label className="form-check-label" htmlFor="de">
                    de
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="pt"
                  />
                  <label className="form-check-label" htmlFor="pt">
                    pt
                  </label>
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="website_name" className="form-label fw-600">
                  Website name
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="website_name"
                  id="website_name"
                  className="form-control"
                  value={values.website_name}
                  onChange={handleChange}
                />
                {errors.website_name && (
                  <small className="text-danger d-block mt-1">
                    {errors.website_name}
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="website_url" className="form-label fw-600">
                  Website url
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <small className="ProfleSettingOptionDesc">
                    <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                    Enter the full website url
                  </small>
                </div>
                <input
                  type="text"
                  name="website_url"
                  id="website_url"
                  className="form-control"
                  value={values.website_url}
                  onChange={handleChange}
                />
                {errors.website_url && (
                  <small className="text-danger d-block mt-1">
                    {errors.website_url}
                  </small>
                )}
              </div>

              <div className="mb-5">
                <label htmlFor="website_root_url" className="form-label fw-600">
                  Website root url
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <small className="ProfleSettingOptionDesc">
                    <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                    Enter the full website url
                  </small>
                </div>
                <input
                  type="text"
                  name="website_root_url"
                  id="website_root_url"
                  className="form-control"
                  value={values.website_root_url}
                  onChange={handleChange}
                />
                {errors.website_root_url && (
                  <small className="text-danger d-block mt-1">
                    {errors.website_root_url}
                  </small>
                )}
              </div>

              <div className="mb-5">
                <Tabs
                  tab={tab1}
                  name={"website"}
                  val={values}
                  setVal={setValues}
                />
                <TabContent
                  tab={tab1}
                  name={"website"}
                  val={values}
                  setVal={setValues}
                />
              </div>

              <div className="mb-5">
                <Tabs
                  tab={tab2}
                  name={"website_2"}
                  val={values}
                  setVal={setValues}
                />
                <TabContent
                  tab={tab2}
                  name={"website_2"}
                  val={values}
                  setVal={setValues}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="phone" className="form-label fw-600">
                  Contact phone
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="form-control"
                  value={values.phone}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="fax" className="form-label fw-600">
                  Contact fax
                </label>
                <input
                  type="text"
                  name="fax"
                  id="fax"
                  className="form-control"
                  value={values.fax}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="address" className="form-label fw-600">
                  Contact address
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  className="form-control"
                  value={values.address}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="facebook" className="form-label fw-600">
                  Facebook url
                </label>
                <input
                  type="text"
                  name="facebook"
                  id="facebook"
                  className="form-control"
                  value={values.facebook}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="instagram" className="form-label fw-600">
                  Instagram url
                </label>
                <input
                  type="text"
                  name="instagram"
                  id="instagram"
                  className="form-control"
                  value={values.instagram}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="youtube" className="form-label fw-600">
                  Youtube url
                </label>
                <input
                  type="text"
                  name="youtube"
                  id="youtube"
                  className="form-control"
                  value={values.youtube}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="twitter" className="form-label fw-600">
                  Twitter url
                </label>
                <input
                  type="text"
                  name="twitter"
                  id="twitter"
                  className="form-control"
                  value={values.twitter}
                  onChange={handleChange}
                />
              </div>

              {/* <div className="ProfileSettingOptionsContainer mb-4">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Application layout
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="layout"
                      checked={values.layout === "compact"}
                      onChange={handleChange}
                      value="compact"
                    >
                      Compact
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="layout"
                      checked={values.layout === "fluid"}
                      onChange={handleChange}
                      value="fluid"
                    >
                      Fluid
                    </Radio>
                  </div>
                </div>
              </div> */}

              {/* <div className="ProfileSettingOptionsContainer mb-4">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Application theme
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="color_code"
                      checked={values.color_code === "#f67611"}
                      onChange={handleChange}
                      value="#f67611"
                    >
                      Orange
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="color_code"
                      checked={values.color_code === "#90ccf4"}
                      onChange={handleChange}
                      value="#90ccf4"
                    >
                      Light blue
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="color_code"
                      checked={values.color_code === "#3500d3"}
                      onChange={handleChange}
                      value="#3500d3"
                    >
                      Dark blue
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="color_code"
                      checked={values.color_code === "#ffbd54"}
                      onChange={handleChange}
                      value="#ffbd54"
                    >
                      Yellow
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="color_code"
                      checked={values.color_code === "#44318d"}
                      onChange={handleChange}
                      value="#44318d"
                    >
                      Purple
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="color_code"
                      checked={values.color_code === "#d83f87"}
                      onChange={handleChange}
                      value="#d83f87"
                    >
                      Pink
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="color_code"
                      checked={values.color_code === "#c3073f"}
                      onChange={handleChange}
                      value="#c3073f"
                    >
                      Red
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="color_code"
                      checked={values.color_code === "#35CE8D"}
                      onChange={handleChange}
                      value="#35CE8D"
                    >
                      Green
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="color_code"
                      checked={values.color_code === "#1a1a1d"}
                      onChange={handleChange}
                      value="#1a1a1d"
                    >
                      Dark
                    </Radio>
                  </div>
                </div>
                <div className="form-group">
                  <div
                    id="appThemeColorPreview"
                    className="shadow img-sm"
                    style={{ backgroundColor: values.color_code }}
                  ></div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={values.color_code}
                  readOnly
                />
              </div> */}

              <div className="mb-4">
                <label htmlFor="logo" className="form-label fw-600">
                  Logo
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    Please choose a 200x50 image size to ensure compatibility
                    with the website design
                  </span>
                </div>
                <input
                  type="file"
                  name="logo"
                  id="logo"
                  className="form-control"
                  onChange={singleImage}
                />
                {values.logo && (
                  <img
                    src={`${server}/layout/logo/${values.logo}`}
                    alt=""
                    className="img-thumbnail img-50-50 mt-1"
                    width="100"
                  />
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="favicon" className="form-label fw-600">
                  Favicon
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    We recommend a 48x48 image size
                  </span>
                </div>
                <input
                  type="file"
                  name="favicon"
                  id="favicon"
                  className="form-control"
                  onChange={singleImage}
                />
                {values.logo && (
                  <img
                    src={`${server}/layout/favicon/${values.favicon}`}
                    alt=""
                    className="img-thumbnail img-50-50 mt-1"
                    width="100"
                  />
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="social" className="form-label fw-600">
                  Social media share image
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    Please choose a 200x200 minimum image size as it is required
                    by Facebook
                  </span>
                </div>
                <input
                  type="file"
                  name="social"
                  id="social"
                  className="form-control"
                  onChange={singleImage}
                />
                {values.logo && (
                  <img
                    src={`${server}/layout/social/${values.social}`}
                    alt=""
                    className="img-thumbnail img-50-50 mt-1"
                    width="100"
                  />
                )}
              </div>

              <div className="ProfileSettingOptionsContainer mb-4">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Show the back to top button
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="top_button"
                      checked={values.top_button === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="top_button"
                      checked={values.top_button === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="ProfileSettingOptionsContainer mb-4">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Show the terms of service page link
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="service_page_link"
                      checked={values.service_page_link === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="service_page_link"
                      checked={values.service_page_link === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="service_page_slug"
                  className="form-label fw-600"
                >
                  Terms of service page slug
                  <span style={{ color: "red" }}>*</span>
                </label>
                <SelectBox
                  name="service_page_slug"
                  options={page_slug}
                  val={values.service_page_slug}
                  change={handleSelectBox}
                />
                {errors.service_page_slug && (
                  <small className="text-danger d-block mt-1">
                    {errors.service_page_slug}
                  </small>
                )}
              </div>

              <div className="ProfileSettingOptionsContainer mb-4">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Show the privacy policy page link
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="privacy_policy"
                      checked={values.privacy_policy === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="privacy_policy"
                      checked={values.privacy_policy === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="privacy_policy_slug"
                  className="form-label fw-600"
                >
                  Privacy policy page slug
                  <span style={{ color: "red" }}>*</span>
                </label>
                <SelectBox
                  name="privacy_policy_slug"
                  options={page_slug}
                  val={values.privacy_policy_slug}
                  change={handleSelectBox}
                />
                {errors.privacy_policy_slug && (
                  <small className="text-danger d-block mt-1">
                    {errors.privacy_policy_slug}
                  </small>
                )}
              </div>

              <div className="ProfileSettingOptionsContainer mb-4">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Show the cookie policy page link
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="cookie_policy_page"
                      checked={values.cookie_policy_page === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="cookie_policy_page"
                      checked={values.cookie_policy_page === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="cookie_policy_page_slug"
                  className="form-label fw-600"
                >
                  Cookie policy page slug
                  <span style={{ color: "red" }}>*</span>
                </label>
                <SelectBox
                  name="cookie_policy_page_slug"
                  options={page_slug}
                  val={values.cookie_policy_page_slug}
                  change={handleSelectBox}
                />
                {errors.cookie_policy_page_slug && (
                  <small className="text-danger d-block mt-1">
                    {errors.cookie_policy_page_slug}
                  </small>
                )}
              </div>

              <div className="ProfileSettingOptionsContainer mb-4">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Show the cookie policy bar at the bottom
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="cookie_policy_bar_bottom"
                      checked={values.cookie_policy_bar_bottom === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="cookie_policy_bar_bottom"
                      checked={values.cookie_policy_bar_bottom === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="ProfileSettingOptionsContainer mb-4">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Show the GDPR compliance page link
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="compliance_page"
                      checked={values.compliance_page === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="compliance_page"
                      checked={values.compliance_page === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="compliance_page_slug"
                  className="form-label fw-600"
                >
                  Show the GDPR compliance page link
                  <span style={{ color: "red" }}>*</span>
                </label>
                <SelectBox
                  name="compliance_page_slug"
                  options={page_slug}
                  val={values.compliance_page_slug}
                  change={handleSelectBox}
                />
                {errors.compliance_page_slug && (
                  <small className="text-danger d-block mt-1">
                    {errors.compliance_page_slug}
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="custom_css" className="form-label fw-600">
                  Custom css
                </label>

                <textarea
                  name="custom_css"
                  className="form-control"
                  id="custom_css"
                  cols="30"
                  rows="10"
                  value={values.custom_css}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="analytics_tracking"
                  className="form-label fw-600"
                >
                  Google analytics Tracking ID
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    e.g. UA-000000-2
                  </span>
                </div>
                <input
                  type="text"
                  name="analytics_tracking"
                  id="analytics_tracking"
                  className="form-control"
                  value={values.analytics_tracking}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group mt-4 mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  disabled={isPending ? "disabled" : ""}
                  type="submit"
                >
                  {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
