import React from "react";
import { FaSyncAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Table } from "../my-tickets/Table";

const MyWallet = () => {
  const { myWallet, loading, error } = useSelector(
    (state) => state.CartDetails
  );

  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table data={myWallet} />
      )}
    </>
  );
};

export default MyWallet;
