import { FaCircleInfo } from "react-icons/fa6";
import { Radio } from "antd";
import { useSelector } from "react-redux";
import { FaSyncAlt } from "react-icons/fa";
import { useSignUp } from "../../../../../utils/CustomFunctions";
import { useParams } from "react-router-dom";

const AddStripeCheckout = () => {
  const { stripe } = useSelector((state) => state.organizer);
  const { slug } = useParams();

  const initialState = {
    id: stripe?.id || "",
    stripe_publishable_key: stripe?.stripe_publishable_key || "",
    stripe_secret_key: stripe?.stripe_secret_key || "",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};
    if (!values.stripe_publishable_key)
      newErrors.stripe_publishable_key = "This value should not be null.";
    if (!values.stripe_secret_key)
      newErrors.stripe_secret_key = "This value should not be null.";
    return newErrors;
  };

  const { values, errors, isPending, handleChange, handleSubmit } = useSignUp(
    initialState,
    validateFields,
    `${slug ? "organizer/edit_stripe" : "organizer/add_stripe"} `,
    "stripe"
  );

  return (
    <>
      <div className="card box">
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form onSubmit={handleSubmit} className="signform mt-3 ">
              <div className="mb-4">
                <label
                  htmlFor="stripe_publishable_key"
                  className="form-label fw-600"
                >
                  Stripe publishable key<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="stripe_publishable_key"
                  id="stripe_publishable_key"
                  className="form-control "
                  value={values.stripe_publishable_key}
                  onChange={handleChange}
                />
                {errors.stripe_publishable_key && (
                  <small className="text-danger mt-1 d-block">
                    {errors.stripe_publishable_key}
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="stripe_secret_key"
                  className="form-label fw-600"
                >
                  Stripe secret key<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="stripe_secret_key"
                  id="stripe_secret_key"
                  className="form-control "
                  value={values.stripe_secret_key}
                  onChange={handleChange}
                />
                {errors.stripe_secret_key && (
                  <small className="text-danger mt-1 d-block">
                    {errors.stripe_secret_key}
                  </small>
                )}
              </div>

              <div className="form-group mt-4 mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  disabled={isPending ? "disabled" : ""}
                  type="submit"
                >
                  {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStripeCheckout;
