import React from "react";
import { Card } from "../../../../events/Card/Card";
import { useSelector } from "react-redux";
import Alert from "../../../../global/alert/Alert";
import { FaInfoCircle } from "react-icons/fa";

const MyFavorites = () => {
  const col = "col-sm-6";
  const { favorites, loading } = useSelector((state) => state.attendee);

  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No favorites found",
  };
  return (
    <div className=" p-3">
      {favorites.length > 0 ? (
        <div className="card-body">
          <div className="row">
            {favorites.map((curElem, index) => {
              return (
                <Card
                  col={col}
                  data={curElem?.events?.[0]}
                  key={index}
                  clas="event-card"
                />
              );
            })}
          </div>
        </div>
      ) : (
        <Alert {...msg} />
      )}
    </div>
  );
};

export default MyFavorites;
