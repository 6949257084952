import { Dropdown, Pagination, Space } from "antd";
import React, { useState } from "react";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaChevronRight,
  FaEllipsisV,
  FaFileAlt,
  FaFileInvoice,
  FaInfoCircle,
  FaRegUser,
  FaTicketAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Alert from "../../../../global/alert/Alert";
import { formatDate, useDelete } from "../../../../../utils/CustomFunctions";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import PaymentDetailsPDF2 from "../../pdf/PaymentDetailsPDF2";

export const Table = ({ orders }) => {
  const api = process.env.REACT_APP_SERVER_API;
  const { user } = useSelector((state) => state.authUser);

  const [printPDFData, setPrintPDFData] = useState(null);
  // this is alert maessage
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No orders found",
  };

  const { isLoading, handleDelete } = useDelete(
    "admin",
    "orders",
    "orders",
    "order_summary"
  );

  const printTckets = async (e, id) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(`${api}/organizer/print_ticket/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (data.status === 200) {
        setPrintPDFData(data.data);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = orders?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      {printPDFData && <PaymentDetailsPDF2 data={printPDFData} />}

      <div className="row organizer-event">
        <div className="col-12">
          {!orders?.length > 0 ? (
            <Alert {...msg} />
          ) : (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter">
                  <thead>
                    <tr>
                      <th className="text-center">Reference</th>
                      <th>Organizer / Event / Date / Ticket</th>
                      <th style={{ width: "13%" }}>Attendee / POS</th>
                      <th>Order date </th>
                      <th>Status</th>
                      <th className="text-center">
                        <AiTwotoneSetting />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((order) =>
                      order?.check_out?.map((check_out, i) => {
                        const items = [
                          {
                            label: (
                              <Link
                                to=""
                                className="d-flex align-items-center"
                                onClick={(e) => printTckets(e, check_out?.id)}
                              >
                                <FaTicketAlt className="me-1" />
                                Print tickets
                              </Link>
                            ),
                            key: "0",
                          },
                          {
                            label: (
                              <Link
                                to={`/en/dashboard/organizer/manage-orders/${check_out?.reference}`}
                                className="d-flex align-items-center"
                              >
                                <FaFileInvoice className="me-1" />
                                Details
                              </Link>
                            ),
                            key: "1",
                          },
                        ];
                        return (
                          <tr key={i}>
                            <td className="text-center text-muted text-sm">
                              {order?.reference}
                            </td>
                            <td className="text-sm">
                              <ul
                                className="p-0"
                                style={{ listStyle: "circle" }}
                              >
                                <li>
                                  <Link to={`/en/event/${order?.slug}`}>
                                    {order?.name}
                                  </Link>
                                  <FaChevronRight />
                                  {
                                    formatDate(new Date(order?.start_On))
                                      .fullDate
                                  }
                                  <FaChevronRight />
                                  {order?.tickets?.[0]?.ticket_name}
                                  <br />
                                  <small>
                                    <strong>
                                      {check_out?.ticket_quantity} ticket
                                    </strong>
                                  </small>
                                </li>
                              </ul>
                            </td>

                            <td>
                              <span className="avatar me-1">
                                <FaRegUser />
                              </span>
                              {check_out?.billing_reference?.first_name}{" "}
                              {check_out?.billing_reference?.last_name}
                            </td>
                            <td>
                              {formatDate(new Date(check_out?.date)).fullDate}
                            </td>
                            <td>
                              {check_out?.payment === "completed" && (
                                <span className="badge badge-success ">
                                  Paid
                                </span>
                              )}
                            </td>

                            <td className="text-center">
                              <Dropdown
                                menu={{
                                  items,
                                }}
                                trigger={["click"]}
                              >
                                <a onClick={(e) => e.preventDefault()}>
                                  <Space>
                                    <FaEllipsisV />
                                  </Space>
                                </a>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {orders?.length > 10 && (
        <Pagination
          current={currentPage}
          total={orders?.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};
