import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import {
  FaExclamationCircle,
  FaGoogle,
  FaLock,
  FaSyncAlt,
  FaUser,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedUser } from "../../../store/slice/User";
import { Layout } from "../../LayoutUser/Layout";
import { formatISODate2 } from "../../../utils/CustomFunctions";

const Signin = () => {
  const api = process.env.REACT_APP_SERVER_API;
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const server = process.env.REACT_APP_SERVER;
  const { user } = useSelector((state) => state.authUser);
  const [isPanding, setPanding] = useState(false);
  const [organizer, setOrganizer] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [rememberMe, setRememberMe] = useState(false);
  // validate fields
  const validateFields = () => {
    let newErrors = {};
    if (!organizer.email.trim() || !organizer.password.trim())
      newErrors.error = "Invalid credentials.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  // change fields data
  const handleOrganizer = (e) => {
    const { name, value } = e.target;
    setOrganizer({ ...organizer, [name]: value });
  };

  // login
  const loginOrganizer = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;
    try {
      setPanding(true);
      const { data } = await axios.post(`${api}/login`, organizer);
      if (data.status === 200) {
        // remember me
        if (rememberMe) {
          localStorage.setItem("credentials", JSON.stringify(organizer));
        } else {
          localStorage.removeItem("credentials");
        }

        const last_login = formatISODate2(new Date());
        toast.success(data.message);
        setPanding(false);
        dispatch(setLoggedUser({ ...data.data, last_login }));
        if (state) {
          navigate(state);
        } else {
          data?.data?.role.some((role) => role.name === "organizer") &&
            navigate("/en/dashboard/organizer/my-events/add");
          data?.data?.role.some((role) => role.name === "admin") &&
            navigate("/en/dashboard/administrator/");
          data?.data?.role.some((role) => role.name === "attendee") &&
            navigate("/en/dashboard/attendee/my-tickets");
        }
      }
    } catch (error) {
      setErrors({ error: error?.response?.data?.message });
      setPanding(false);
    }
  };

  // handle remember me
  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  // for check user login or not
  useEffect(() => {
    if (!state) {
      user?.role?.some((role) => role.name === "admin") &&
        navigate("/en/dashboard/administrator/");
      user?.role?.some((role) => role.name === "organizer") &&
        navigate("/en/dashboard/organizer");
      user?.role?.some((role) => role.name === "attendee") &&
        navigate("/en/dashboard/attendee/my-tickets");
    }
  }, [user]);

  // get login credentials
  useEffect(() => {
    const login = JSON.parse(localStorage.getItem("credentials"));
    const credentials = login || organizer;
    setOrganizer(credentials);
    login ? setRememberMe(true) : setRememberMe(false);
  }, []);

  return (
    <Layout>
      <div className="container signin-section mb-4">
        <div className="row">
          <div className="col-12 col-lg-4 offset-lg-4">
            <div className="col-12 mb-4">
              <h1 className="sign-title text-center">Sign in</h1>
            </div>
            <p>
              <Link
                to={`${server}/google/redirect`}
                className="btn btn-block signin"
              >
                <FaGoogle /> Sign in via Google
              </Link>
            </p>
            <p className="divider-text">
              <span>OR</span>
            </p>
            {errors.error && (
              <div className="alert alert-danger alert-icon">
                <FaExclamationCircle className="me-3" />
                {errors.error}
              </div>
            )}
            <form action="" className="signform" onSubmit={loginOrganizer}>
              <div className="input-group mb-4">
                <span className="input-group-text">
                  <FaUser />
                </span>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  autoComplete="email"
                  placeholder="Email"
                  value={organizer.email}
                  onChange={handleOrganizer}
                />
              </div>
              <div className="input-group mb-4">
                <span className="input-group-text">
                  <FaLock />
                </span>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  autoComplete="current-password"
                  placeholder="Password"
                  value={organizer.password}
                  onChange={handleOrganizer}
                />
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  name="rememberMe"
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={handleRememberMe}
                />
                <label className="form-check-label" htmlFor="rememberMe">
                  Remember Me
                </label>
              </div>
              <div className="form-group mt-3 mb-3">
                <button
                  className="btn btn-dark w-100 rounded-5"
                  type="submit"
                  disabled={isPanding ? "disabled" : ""}
                >
                  {isPanding ? <FaSyncAlt className="spinIcon" /> : " sign in"}
                </button>
              </div>

              <p className="text-center">
                <Link to="/en/resetting/request">Forgot your password ?</Link>
              </p>
              <p className="text-center">
                Not a member yet ? <Link to="/en/signup/attendee">Sign up</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Signin;
