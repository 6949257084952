import React from "react";
import { FaSyncAlt } from "react-icons/fa";
import { Table3 } from "./Table3";
import { useSelector } from "react-redux";
import Filters from "../../organizer/venues/Filters";

export const ManageVenues = () => {
  const { venues, loading } = useSelector((state) => state.admin);
  const { filter_data } = useSelector((state) => state.organizerFilters);

  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event MyScanners">
        <Filters venues={venues} />
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table3 venues={filter_data.venues} />
      )}
    </>
  );
};
