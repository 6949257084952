import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  events: [],
  venues: [],
  scanners: [],
  sales: [],
  event_reviews: [],
  payout_reports: [],
  payout_request: [],
  orders: [],
  event_categories: [],
  countries: [],
  languages: [],
  audiences: [],
  venue_types: [],
  amenities: [],
  blogs: [],
  blog_categories: [],
  users: [],
  article_categories: [],
  articles: [],
  paypal: [],
  stripe: [],
  filter_data: {
    events: [],
    venues: [],
    scanners: [],
    sales: [],
    event_reviews: [],
    payout_reports: [],
    payout_request: [],
    orders: [],
    event_categories: [],
    countries: [],
    languages: [],
    audiences: [],
    venue_types: [],
    amenities: [],
    blogs: [],
    blog_categories: [],
    users: [],
    article_categories: [],
    articles: [],
    paypal: [],
    stripe: [],
  },
  sorting_value: "",
  filters: {
    event_id: "",
    category: "",
    event_status: "all",
    venue: "",
    keyword: "",
    username: "",
    status: "all",
    event_date: "",
    reference: "",
    from_date: "",
    until_date: "",
    star: "all",
    organizer: "",
    admin_event_id: "",
    admin_event_date: "",
    featured: "all",
    payment_method: "all",
    role: "all",
    organizer_name: "",
    user_name: "",
    first_name: "",
    last_name: "",
    email: "",
    country: "",
  },
};

const OrganizerFiltersSlice = createSlice({
  name: "dashboardFilters",
  initialState,
  reducers: {
    loadFilterOrganizer(state, action) {
      const { key, data } = action.payload;
      state[key] = data;
      state.filter_data[key] = data;
    },
    addOrganizerFilters(state, action) {
      const { name, value } = action.payload;
      state.filters[name] = value;
    },
    sorting(state, action) {
      const { name, value } = action.payload;
      state[name] = value;
    },
    sorted(state, action) {
      const { filter_data, sorting_value } = state;
      const sorting = (a, b) => {
        let dateA;
        let dateB;
        if (
          action.payload === "payout_reports" ||
          action.payload === "payout_request"
        ) {
          dateA = new Date(a.event.created_at);
          dateB = new Date(b.event.created_at);
        } else if (
          action.payload === "scanners" ||
          action.payload === "sales"
        ) {
          dateA = new Date(a.created_date);
          dateB = new Date(b.created_date);
        } else if (action.payload === "orders") {
          dateA = new Date(a.id);
          dateB = new Date(b.id);
        } else {
          dateA = new Date(a.created_at);
          dateB = new Date(b.created_at);
        }
        if (sorting_value === "date-asc") {
          return dateA - dateB;
        }
        if (sorting_value === "date-desc" || sorting_value === "") {
          return dateB - dateA;
        }
      };
      if (filter_data[action.payload].length > 0) {
        const newSortData = filter_data[action.payload].sort(sorting);
        state.filter_data[action.payload] = newSortData;
      }
    },

    filteredOrganizerEvents(state) {
      let tempFilterOrganizerData = state.events;
      const { event_id, category, venue, event_status } = state.filters;
      if (event_id) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.id === event_id
        );
      }
      if (category) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.event_categories_id === category
        );
      }
      if (venue) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.venue.id === venue
        );
      }
      if (event_status !== "all") {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.status === event_status
        );
      }
      state.filter_data.events = tempFilterOrganizerData;
    },
    filteredOrganizerVenues(state) {
      let tempFilterOrganizerData = state.venues;
      const { keyword } = state.filters;
      if (keyword) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }
      state.filter_data.venues = tempFilterOrganizerData;
    },
    filteredOrganizerScanners(state) {
      let tempFilterOrganizerData = state.scanners;
      const { username, status } = state.filters;
      if (username) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.user_name.toLowerCase().includes(username.toLowerCase())
        );
      }
      if (status !== "all") {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.status === status
        );
      }
      state.filter_data.scanners = tempFilterOrganizerData;
    },
    filteredOrganizerSales(state) {
      let tempFilterOrganizerData = state.sales;
      const { username, status } = state.filters;
      if (username) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.user_name.toLowerCase().includes(username.toLowerCase())
        );
      }
      if (status !== "all") {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.status === status
        );
      }
      state.filter_data.sales = tempFilterOrganizerData;
    },
    filteredOrganizerReviews(state) {
      let tempFilterOrganizerData = state.event_reviews;
      const { keyword, event_id, star, status } = state.filters;

      if (keyword) {
        tempFilterOrganizerData = tempFilterOrganizerData.map((event) => ({
          ...event,
          reviews: event.reviews.filter((review) =>
            review.headline.toLowerCase().includes(keyword.toLowerCase())
          ),
        }));
      }

      if (event_id) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.id === event_id
        );
      }
      if (status !== "all") {
        tempFilterOrganizerData = tempFilterOrganizerData.map((event) => ({
          ...event,
          reviews: event.reviews.filter((review) => review.status === status),
        }));
      }
      if (star !== "all") {
        tempFilterOrganizerData = tempFilterOrganizerData.map((event) => ({
          ...event,
          reviews: event.reviews.filter((review) => review.rating == star),
        }));
      }
      state.filter_data.event_reviews = tempFilterOrganizerData;
    },
    filteredOrganizerReports(state) {
      let tempFilterOrganizerData = state.payout_reports;
      const { event_id, event_date, organizer } = state.filters;
      if (event_id) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.event.id === event_id
        );
      }
      if (organizer) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.event.user_id == organizer
        );
      }
      if (event_date) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) =>
            curElem.event.id + " " + curElem.event.start_On === event_date
        );
      }
      state.filter_data.payout_reports = tempFilterOrganizerData;
    },
    filteredOrganizerPayoutRequests(state) {
      let tempFilterOrganizerData = state.payout_request;
      const {
        reference,
        event_date,
        from_date,
        until_date,
        organizer,
        status,
      } = state.filters;
      if (reference) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) =>
            curElem.reference.toLowerCase() === reference.toLowerCase()
        );
      }
      if (event_date) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) =>
            curElem.event.id + " " + curElem.event.start_On === event_date
        );
      }
      if (from_date && until_date) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) => {
          const request_date = curElem.request_date.split(" ")[0];
          return request_date >= from_date && request_date <= until_date;
        });
      }
      if (organizer) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.user_id == organizer
        );
      }
      if (status !== "all") {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.status === status
        );
      }
      state.filter_data.payout_request = tempFilterOrganizerData;
    },
    filteredOrganizerOrders(state) {
      let tempFilterOrganizerData = state.orders;
      const {
        reference,
        event_id,
        event_date,
        from_date,
        until_date,
        admin_event_id,
        admin_event_date,
        organizer,
        status,
      } = state.filters;

      if (reference) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) =>
            curElem.reference.toLowerCase() === reference.toLowerCase()
        );
      }
      if (event_id) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.id === event_id
        );
      }
      if (admin_event_id) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.event.id === admin_event_id
        );
      }
      if (event_date) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.id + " " + curElem.start_On === event_date
        );
      }
      if (admin_event_date) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) =>
            curElem.event.id + " " + curElem.event.start_On === admin_event_date
        );
      }
      if (from_date && until_date) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) => {
          const start_On = curElem?.event?.start_On.split(" ")[0];
          return start_On >= from_date && start_On <= until_date;
        });
      }
      if (organizer) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.event.user.id === organizer
        );
      }
      if (status !== "all") {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.payment === status
        );
      }
      state.filter_data.orders = tempFilterOrganizerData;
    },
    filteredAdminData(state) {
      let tempFilterOrganizerData = state.event_categories;
      const { keyword, featured } = state.filters;
      if (keyword) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }
      if (featured === "yes") {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.featured !== null
        );
      }
      if (featured === "no") {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.featured === null
        );
      }

      state.filter_data.event_categories = tempFilterOrganizerData;
    },
    filteredAdminCounrty(state) {
      let tempFilterOrganizerData = state.countries;
      const { keyword } = state.filters;
      if (keyword) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }
      state.filter_data.countries = tempFilterOrganizerData;
    },
    filteredAdminLanguage(state) {
      let tempFilterOrganizerData = state.languages;
      const { keyword } = state.filters;
      if (keyword) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }
      state.filter_data.languages = tempFilterOrganizerData;
    },
    filteredAdminaAudience(state) {
      let tempFilterOrganizerData = state.audiences;
      const { keyword } = state.filters;
      if (keyword) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }
      state.filter_data.audiences = tempFilterOrganizerData;
    },
    filteredAdminaVenueType(state) {
      let tempFilterOrganizerData = state.venue_types;
      const { keyword } = state.filters;
      if (keyword) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }
      state.filter_data.venue_types = tempFilterOrganizerData;
    },
    filteredAdminAmenitie(state) {
      let tempFilterOrganizerData = state.amenities;
      const { keyword } = state.filters;
      if (keyword) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }

      state.filter_data.amenities = tempFilterOrganizerData;
    },
    filteredAdminBlog(state) {
      let tempFilterOrganizerData = state.blogs;
      const { keyword } = state.filters;
      if (keyword) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }

      state.filter_data.blogs = tempFilterOrganizerData;
    },
    filteredAdminBlogCategory(state) {
      let tempFilterOrganizerData = state.blog_categories;
      const { keyword } = state.filters;
      if (keyword) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }

      state.filter_data.blog_categories = tempFilterOrganizerData;
    },
    filteredAdminUser(state) {
      let tempFilterOrganizerData = state.users;
      const {
        role,
        organizer_name,
        user_name,
        first_name,
        last_name,
        email,
        status,
        country,
      } = state.filters;

      if (role === "attendee" && organizer_name) {
        tempFilterOrganizerData = [];
      }
      if (role !== "all" && role !== "") {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.roles.some((rol) => rol.name === role)
        );
      }

      if ((role === "all" || role === "organizer") && organizer_name) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) =>
            curElem.organize_name !== null &&
            curElem.organize_name !== undefined &&
            curElem.organize_name
              .toLowerCase()
              .includes(organizer_name.toLowerCase())
        );
      }

      if (first_name) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) =>
            curElem.first_name !== null &&
            curElem.first_name !== undefined &&
            curElem.first_name.toLowerCase().includes(first_name.toLowerCase())
        );
      }
      if (last_name) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) =>
            curElem.last_name !== null &&
            curElem.last_name !== undefined &&
            curElem.last_name.toLowerCase().includes(last_name.toLowerCase())
        );
      }
      if (email) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) =>
            curElem.email !== null &&
            curElem.email !== undefined &&
            curElem.email.toLowerCase().includes(email.toLowerCase())
        );
      }
      if (user_name) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) =>
            curElem.user_name !== null &&
            curElem.user_name !== undefined &&
            curElem.user_name.toLowerCase().includes(user_name.toLowerCase())
        );
      }
      if (status !== "all") {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem.status === status
        );
      }
      if (country) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter(
          (curElem) => curElem?.country === country
        );
      }

      state.filter_data.users = tempFilterOrganizerData;
    },
    filteredAdminARTICAL(state) {
      let tempFilterOrganizerData = state.article_categories;
      const { keyword } = state.filters;
      if (keyword) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }

      state.filter_data.article_categories = tempFilterOrganizerData;
    },
    filteredAdminARTICALCategory(state) {
      let tempFilterOrganizerData = state.articles;
      const { keyword } = state.filters;
      if (keyword) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }

      state.filter_data.articles = tempFilterOrganizerData;
    },
    filteredAdminPaypal(state) {
      let tempFilterOrganizerData = state.paypal;
      let tempFilterOrganizerData2 = state.stripe;
      const { keyword } = state.filters;

      if (keyword) {
        tempFilterOrganizerData = tempFilterOrganizerData.filter((curElem) =>
          curElem.user.organize_name
            .toLowerCase()
            .includes(keyword.toLowerCase())
        );
      }
      if (keyword) {
        tempFilterOrganizerData2 = tempFilterOrganizerData2.filter((curElem) =>
          curElem.user.organize_name
            .toLowerCase()
            .includes(keyword.toLowerCase())
        );
      }

      state.filter_data.paypal = tempFilterOrganizerData;
      state.filter_data.stripe = tempFilterOrganizerData2;
    },
    // For Clear All Filters
    clearAllFilters(state) {
      state.filters.event_id = "";
      state.filters.category = "";
      state.filters.event_status = "all";
      state.filters.venue = "";
      state.sorting_value = "";
      state.filters.keyword = "";
      state.filters.username = "";
      state.filters.status = "all";
      state.filters.event_date = "";
      state.filters.reference = "";
      state.filters.from_date = "";
      state.filters.until_date = "";
      state.filters.star = "all";
      state.filters.organizer = "";
      state.filters.admin_event_id = "";
      state.filters.admin_event_date = "";
      state.filters.featured = "all";
      state.filters.role = "all";
      state.filters.organizer_name = "";
      state.filters.user_name = "";
      state.filters.first_name = "";
      state.filters.last_name = "";
      state.filters.email = "";
    },
  },
});

export const {
  loadFilterOrganizer,
  addOrganizerFilters,
  filteredOrganizerEvents,
  filteredOrganizerVenues,
  filteredOrganizerScanners,
  filteredOrganizerSales,
  filteredOrganizerReviews,
  filteredOrganizerReports,
  filteredOrganizerPayoutRequests,
  filteredOrganizerOrders,
  filteredAdminData,
  filteredAdminCounrty,
  filteredAdminLanguage,
  filteredAdminaAudience,
  filteredAdminaVenueType,
  filteredAdminAmenitie,
  filteredAdminBlog,
  filteredAdminBlogCategory,
  filteredAdminUser,
  filteredAdminARTICALCategory,
  filteredAdminARTICAL,
  filteredAdminPaypal,
  clearAllFilters,
  sorted,
  sorting,
} = OrganizerFiltersSlice.actions;
export default OrganizerFiltersSlice.reducer;
