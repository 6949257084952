import React from "react";
import { FaSyncAlt } from "react-icons/fa";
import { Table } from "./Table";
import { useSelector } from "react-redux";
import Filters from "../../organizer/reports/Filters";

const AdminReports = () => {
  const { reports, events, organizers, loading } = useSelector(
    (state) => state.admin
  );
  const { filter_data } = useSelector((state) => state.organizerFilters);

  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event reports">
        <Filters
          payout_reports={reports}
          events={events}
          organizers={organizers}
        />
      </div>

      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table reports={filter_data.payout_reports} />
      )}
    </>
  );
};

export default AdminReports;
