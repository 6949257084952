import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import { BiHeart } from "react-icons/bi";
import { formatDate } from "../../../utils/CustomFunctions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaHeart } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { fetchuUserData } from "../../../store/slice/Attendee";
import { Tooltip } from "antd";
const api = process.env.REACT_APP_SERVER_API;

export const Card = ({ col, data, clas }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.AllData);
  const server = process.env.REACT_APP_SERVER;
  const { favorites, loading } = useSelector((state) => state.attendee);
  const { user } = useSelector((state) => state.authUser);
  const [favorite, setfavorite] = useState({ isFavorite: false, favorite: {} });
  const { name, main_event_image, start_On, category, slug, venue, tickets } =
    data;
  const startDate = new Date(start_On);
  const { month, day, fullDate } = formatDate(startDate);

  useEffect(() => {
    if (user?.token) {
      const isFavorite = favorites.some((f) => f.event_id === data.id);
      const favorite = favorites.find((f) => f.event_id === data.id);
      setfavorite({ isFavorite, favorite });
    }
  }, [user?.token, favorites, data.id]);

  // for add/remove to favorites
  const handleADDTOFAVORITES = async (e, id, endPoint) => {
    e.preventDefault();
    try {
      // setPending(true);
      const { data } = await axios.get(`${api}/attendee/${endPoint}/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (data.status === 200 || data.status === 201) {
        toast.success(data.message);
        dispatch(
          fetchuUserData({
            getData: "attendee/favorites",
            state: "favorites",
            token: user.token,
          })
        );
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  return (
    <div className={`${col} mb-4`}>
      <div className="card event-card">
        <Link to={`/en/event/${slug}`}>
          <img
            src={`${
              main_event_image
                ? `${server}/event/image/${main_event_image}`
                : "/./images/noPhoto.png"
            } `}
            className={` ${clas} card-img-top`}
            alt={`Event thumbnail for ${name}`}
          />
        </Link>
        <span className="event-category">{category?.name}</span>
        <div className="event-date text-center">
          <div className="event-month bg-dark text-light">{month}</div>
          <div className="event-day bg-white">{day}</div>
        </div>
        <div className="card-body">
          <div className="event-favorite">
            <Link to="" className="text-black-50">
              {favorite.isFavorite ? (
                <Tooltip title="Remove to favorites">
                  <FaHeart
                    className="heart"
                    onClick={(e) =>
                      handleADDTOFAVORITES(
                        e,
                        favorite?.favorite?.id,
                        "remove_favorite"
                      )
                    }
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Add to favorites">
                  <BiHeart
                    className="heart"
                    title="Add to favorites"
                    onClick={(e) =>
                      handleADDTOFAVORITES(e, data?.id, "add_favorite")
                    }
                  />
                </Tooltip>
              )}
            </Link>
          </div>
          <h5 className="card-title">
            <Link
              to={`/en/event/${slug}`}
              className="text-dark text-decoration-none"
            >
              {name?.substring(0, 30) + "...."}
            </Link>
          </h5>
          <div className="text-black-50 small mb-1">
            {name},{" "}
            {countries?.map((curElem) => {
              if (curElem.id == venue?.country) {
                return (
                  <React.Fragment key={curElem.id}>
                    {curElem.name}
                  </React.Fragment>
                );
              }
              return null; // or any other default value if the condition is not met
            })}
          </div>
          <div className="text-black-50 small">{fullDate}</div>
          <div className="price-wrap">
            <span className="price-new">
              {tickets?.[0]?.ticket_free == 1
                ? "Free"
                : `$${tickets?.[0]?.Price}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
