import { Dropdown, Modal, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaEllipsisV,
  FaFileAlt,
  FaInfoCircle,
  FaRegClock,
  FaRegUser,
  FaRegWindowClose,
  FaUserAltSlash,
  FaUserCheck,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../../utils/CustomFunctions";
import Alert from "../../../../global/alert/Alert";

export const Table2 = ({ stripe }) => {
  const [openModel, setOpenModel] = useState(false);
  const [modelUser, setModelUser] = useState({});
  // this is alert maessage
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No payout methods found",
  };
  const handleModelEvent = (e, id) => {
    e.preventDefault();
    setModelUser(stripe.find((ele) => ele.id === id));
    setOpenModel(true);
  };

  return (
    <>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={openModel}
        onCancel={() => setOpenModel(false)}
        className="Model"
      >
        <div className="ModelBox">
          <div className="ModelHeader">
            <span className="ModelBoxBuyTicketText">User information</span>
          </div>

          {modelUser && Object.keys(modelUser).length > 0 && (
            <div className="jconfirm-content" id="jconfirm-box71368">
              <div>
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-borderless table-striped table-hover table-sm">
                        <thead>
                          <tr>
                            <th colSpan="2">
                              <i className="fas fa-file-alt fa-fw text-muted"></i>{" "}
                              Account information
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td width="30%">Role</td>
                            <td>Organizer</td>
                          </tr>
                          <tr>
                            <td width="30%">Status</td>
                            <td>
                              {modelUser?.user?.status === "active" ? (
                                <span className="badge badge-success d-inline-flex align-items-center">
                                  <FaUserCheck className="me-1" />
                                  Enabled
                                </span>
                              ) : (
                                <span className="badge badge-success d-inline-flex align-items-center">
                                  <FaUserAltSlash className="me-1" />
                                  Disabled
                                </span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td width="30%">First name</td>
                            <td>{modelUser?.user?.first_name}</td>
                          </tr>
                          <tr>
                            <td width="30%">Last name</td>
                            <td>{modelUser?.user?.last_name}</td>
                          </tr>
                          <tr>
                            <td width="30%">Email</td>
                            <td>{modelUser?.user?.email}</td>
                          </tr>
                          <tr>
                            <td width="30%">Username</td>
                            <td>{modelUser?.user?.user_name}</td>
                          </tr>
                          <tr>
                            <td width="30%">Registration date</td>
                            <td>
                              {
                                formatDate(
                                  new Date(modelUser?.user?.created_at)
                                ).fullDate
                              }
                            </td>
                          </tr>
                          <tr>
                            <td width="30%">Update date</td>
                            <td>
                              {
                                formatDate(
                                  new Date(modelUser?.user?.updated_at)
                                ).fullDate
                              }
                            </td>
                          </tr>
                          {/* <tr>
                        <td width="30%">Last login</td>
                        <td>Thu 30 Nov 2023, 1:41 PM EST</td>
                      </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
          <button
            className="btn btn-outline-dark btn-sm rounded-5 d-inline-flex align-items-center gap-2"
            onClick={() => setOpenModel(false)}
          >
            <FaRegWindowClose className="ModelBoxTicketPurchaseTitleIcon" />
            close
          </button>
        </div>
      </Modal>

      <div className="row organizer-event">
        <div className="col-12">
          {stripe && stripe.length > 0 ? (
            <div className="card">
              <div className="card-header">
                <h4 className="mb-0 d-inline-flex align-items-center gap-2">
                  {/* <span className="d-inline-flex align-items-center">CXC</span>{" "} */}
                  Stripe
                </h4>
              </div>
              <div className="table-responsive">
                <table className="table table-hover table-vcenter">
                  <thead>
                    <tr>
                      <th>Organizer</th>
                      <th>Payout method </th>
                      <th>Stripe Secret Key</th>
                      <th>Stripe Publishable Key</th>
                      {/* <th className="text-center">
                      <AiTwotoneSetting />
                    </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {stripe.map((elem, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-capitalize">
                            <Tooltip title="Organizer information">
                              <Link
                                to=""
                                onClick={(e) => handleModelEvent(e, elem.id)}
                              >
                                <span className="avatar me-1">
                                  <FaRegUser />
                                </span>
                                {elem?.user?.organize_name}
                              </Link>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="Enlarge">
                              {true ? (
                                <Link className="fancybox" to="">
                                  <img
                                    src="/./images/strip.svg"
                                    className="img-thumbnail img-50-50"
                                  />
                                </Link>
                              ) : (
                                <img
                                  src="/./images/noPhoto.png"
                                  className="img-thumbnail img-50-50"
                                  alt=""
                                />
                              )}
                            </Tooltip>
                          </td>
                          <td>{elem?.stripe_secret_key}</td>
                          <td>{elem?.stripe_publishable_key}</td>

                          <td className="text-center">
                            {/* <Dropdown
                            menu={{
                              items,
                            }}
                            trigger={["click"]}
                          >
                            <a onClick={(e) => e.preventDefault()}>
                              <Space className="justify-content-center">
                                <FaEllipsisV />
                              </Space>
                            </a>
                          </Dropdown> */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <Alert {...msg} />
          )}
        </div>
      </div>
    </>
  );
};
