import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../../Organizer/Dashboard/Header/DashboardHeader";
import {
  FaCalendarDays,
  FaCartArrowDown,
  FaUsers,
  FaTicket,
} from "react-icons/fa6";
import { AiFillSetting } from "react-icons/ai";
import Card from "../../../../Organizer/Dashboard/Card/Card";
import "./dashboard.scss";
import EventsTable from "../events/Table";
import Breadcrumb from "../../../../global/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../../../store/slice/Organizer";
import { FaSyncAlt } from "react-icons/fa";
import { Table } from "./../orders/Table";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);
  const { events, orders, event_summary, order_summary, loading } = useSelector(
    (state) => state.organizer
  );
  const [limitedEvents, setlimitedEvents] = useState([]);
  const [limitedOrders, setlimitedOrders] = useState([]);

  useEffect(() => {
    dispatch(
      fetchData({
        getData: "organizer/event_summary",
        token: user.token,
        state: "event_summary",
      })
    );
    dispatch(
      fetchData({
        getData: "organizer/order_summary",
        token: user.token,
        state: "order_summary",
      })
    );
  }, []);

  useEffect(() => {
    const limitedEvent = events?.slice(0, 4);
    const limitedOrder = orders?.slice(0, 1);
    setlimitedEvents(limitedEvent);
    setlimitedOrders(limitedOrder);
  }, [events, orders]);

  return (
    <>
      <Breadcrumb page={"Dashboard"} />
      <div className="OraganizerDashboardSection mt-2">
        <div className="mb-5">
          <DashboardHeader
            icon={FaCalendarDays}
            icon2={AiFillSetting}
            text1={"Event Summary"}
            text2={"My Events"}
            link={"/en/dashboard/organizer/my-events"}
          />

          <div className="OrganizerDashboardEventsCardSection mb-4">
            <Card
              count={event_summary?.added}
              text={"Events Added"}
              icon={FaCalendarDays}
            />
            <Card
              count={event_summary?.published}
              text={"Published events"}
              icon={FaCalendarDays}
            />
            <Card
              count={event_summary?.upcoming}
              text={"Upcoming events"}
              icon={FaCalendarDays}
            />
            <Card
              count={event_summary?.dates}
              text={"Events dates"}
              icon={FaCalendarDays}
            />
          </div>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <FaSyncAlt className="spinIcon" />
            </div>
          ) : (
            <EventsTable data={limitedEvents} />
          )}
        </div>
        {/* users */}
        <div>
          <DashboardHeader
            icon={FaCartArrowDown}
            icon2={FaUsers}
            text1={"Attendees Summary"}
            text2={"Manage Attendees"}
            link={"/en/dashboard/organizer/manage-orders"}
          />

          <div className="OrganizerDashboardEventsCardSection mb-4">
            <Card
              count={order_summary?.placed}
              text={"Order Placed"}
              icon={FaCartArrowDown}
            />
            <Card
              count={order_summary?.paid}
              text={"Paid Orders"}
              icon={FaCartArrowDown}
            />
            <Card
              count={order_summary?.awaiting}
              text={"Awaiting payment"}
              icon={FaCartArrowDown}
            />
            <Card
              count={order_summary?.sold}
              text={"Tickets Sold"}
              icon={FaTicket}
            />
          </div>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <FaSyncAlt className="spinIcon" />
            </div>
          ) : (
            <Table orders={limitedOrders} />
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
