import { Dropdown, Space } from "antd";
import React, { useState } from "react";
import { AiTwotoneSetting } from "react-icons/ai";
import { FaEllipsisV } from "react-icons/fa";
import { Link } from "react-router-dom";

export const Table = () => {
  const items = [
    {
      label: "Edit",
      key: "0",
    },
  ];
  const [toggle, setToggle] = useState(false);
  return (
    <div className="row organizer-event">
      <div className="col-12">
        <div className="card">
          <div className="table-responsive">
            <table className="table table-hover table-vcenter">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Header</th>
                  <th>Elements</th>
                  <th>Status</th>
                  <th className="text-center">
                    <AiTwotoneSetting />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Header menu</td>
                  <td className="text-sm">Useful Links</td>
                  <td>FeeVaa Events</td>
                  <td>9</td>
                  <td className="text-center">
                    <Dropdown
                      menu={{
                        items,
                      }}
                      trigger={["click"]}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space className="justify-content-center">
                          <FaEllipsisV />
                        </Space>
                      </a>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td>Header menu</td>
                  <td className="text-sm">Useful Links</td>
                  <td>FeeVaa Events</td>
                  <td>9</td>
                  <td className="text-center">
                    <Dropdown
                      menu={{
                        items,
                      }}
                      trigger={["click"]}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space className="justify-content-center">
                          <FaEllipsisV />
                        </Space>
                      </a>
                    </Dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
