import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  all_venues: [],
  filter_venues: [],
  venue_comments: [],
  filter_venue_comments: [],
  sorting_value: "",
  filters: {
    keyword: "",
    venue_type: [],
    country: "",
    maxSeatedGuests: 0,
    minSeatedGuests: 0,
    maxStandingGuests: 0,
    minStandingGuests: 0,
    minMaxSeatedGuests: [0, 0],
    minMaxStandingGuests: [0, 0],
  },
};

const venueFiltersSlice = createSlice({
  name: "venueFilters",
  initialState,
  reducers: {
    loadFilterVenue(state, action) {
      const seatedGuestsNumbers = action.payload.map(
        (venue) => venue.seated_guests_number
      );
      const standingGuestsNumbers = action.payload.map(
        (venue) => venue.standing_guests_number
      );
      // Finding the max and min values
      const maxSeatedGuests = Math.max(...seatedGuestsNumbers);
      const minSeatedGuests = Math.min(...seatedGuestsNumbers);
      const maxStandingGuests = Math.max(...standingGuestsNumbers);
      const minStandingGuests = Math.min(...standingGuestsNumbers);

      state.filter_venues = action.payload;
      state.all_venues = action.payload;
      state.filters.maxSeatedGuests = maxSeatedGuests;
      state.filters.minSeatedGuests = minSeatedGuests;
      state.filters.maxStandingGuests = maxStandingGuests;
      state.filters.minStandingGuests = minStandingGuests;
      state.filters.minMaxSeatedGuests = [minSeatedGuests, maxSeatedGuests];
      state.filters.minMaxStandingGuests = [
        minStandingGuests,
        maxStandingGuests,
      ];
    },
    loadFilterVenueComments(state, action) {
      state.venue_comments = action.payload;
      state.filter_venue_comments = action.payload;
    },
    sorting(state, action) {
      const { name, value } = action.payload;
      state[name] = value;
    },
    sorted(state, action) {
      const { filter_venues, sorting_value } = state;
      const sorting = (a, b) => {
        let dateA = new Date(a.created_at);
        let dateB = new Date(b.created_at);

        if (sorting_value === "date-asc") {
          return dateA - dateB;
        }
        if (sorting_value === "date-desc" || sorting_value === "") {
          return dateB - dateA;
        }
      };
      const newSortData = filter_venues.sort(sorting);
      state.filter_venues = newSortData;
    },
    sortedv(state, action) {
      const { venue_comments, sorting_value } = state;
      const sorting = (a, b) => {
        let dateA = new Date(a.created_at);
        let dateB = new Date(b.created_at);

        if (sorting_value === "date-asc") {
          console.log(sorting_value);
          return dateA - dateB;
        }
        if (sorting_value === "date-desc" || sorting_value === "") {
          console.log(sorting_value);
          return dateB - dateA;
        }
      };
      const newSortData = venue_comments?.sort(sorting);
      state.filter_venue_comments = newSortData;
    },
    addVenueFilters(state, action) {
      const { name, value } = action.payload;
      state.filters[name] = value;
    },
    // Filters
    filteredVenues(state) {
      let tempFilterVenues = state.all_venues;
      const {
        keyword,
        venue_type,
        country,
        maxSeatedGuests,
        maxStandingGuests,
        minMaxSeatedGuests,
        minMaxStandingGuests,
      } = state.filters;
      if (keyword) {
        tempFilterVenues = tempFilterVenues.filter((curElem) =>
          curElem.name.toLowerCase().includes(keyword.toLowerCase())
        );
      }

      if (venue_type.length > 0) {
        tempFilterVenues = tempFilterVenues.filter((curElem) =>
          venue_type.includes(curElem.type.name)
        );
      }

      if (country !== "") {
        tempFilterVenues = tempFilterVenues.filter(
          (curElem) => curElem.country === country
        );
      }

      if (maxSeatedGuests !== 0) {
        tempFilterVenues = tempFilterVenues.filter(
          (curElem) =>
            curElem.seated_guests_number >= minMaxSeatedGuests[0] &&
            curElem.seated_guests_number <= minMaxSeatedGuests[1]
        );
      }
      if (maxStandingGuests !== 0) {
        tempFilterVenues = tempFilterVenues.filter(
          (curElem) =>
            curElem.standing_guests_number >= minMaxStandingGuests[0] &&
            curElem.standing_guests_number <= minMaxStandingGuests[1]
        );
      }

      state.filter_venues = tempFilterVenues;
    },
    // For Clear All Filters
    clearAllFilters(state) {
      const {
        minSeatedGuests,
        maxSeatedGuests,
        minStandingGuests,
        maxStandingGuests,
      } = state.filters;

      state.filters.keyword = "";
      state.filters.venue_type = [];
      state.filters.country = "";
      state.filters.minMaxSeatedGuests = [minSeatedGuests, maxSeatedGuests];
      state.filters.minMaxStandingGuests = [
        minStandingGuests,
        maxStandingGuests,
      ];
      state.filters.maxSeatedGuests = maxSeatedGuests;
      state.filters.minSeatedGuests = minSeatedGuests;
      state.filters.maxStandingGuests = maxStandingGuests;
      state.filters.minStandingGuests = minStandingGuests;
    },
  },
});

export const {
  loadFilterVenue,
  addVenueFilters,
  filteredVenues,
  clearAllFilters,
  loadFilterVenueComments,
  sorting,
  sorted,
  sortedv,
} = venueFiltersSlice.actions;
export default venueFiltersSlice.reducer;
