import { Dropdown, Pagination, Space } from "antd";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaEdit,
  FaEllipsisV,
  FaEye,
  FaEyeSlash,
  FaInfoCircle,
  FaTheaterMasks,
  FaTrashRestoreAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Alert from "../../../../../global/alert/Alert";
import { IconPickerItem } from "react-fa-icon-picker";
import { IoCloseSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import {
  useDelete,
  useStatusChange,
} from "../../../../../../utils/CustomFunctions";
import { useState } from "react";

export const Table2 = ({ amenities }) => {
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No amenities found",
  };

  // for state change
  const { loading, handleStatusChange } = useStatusChange(
    "admin",
    "amenities_change_status",
    "amenities",
    "amenities"
  );
  // for soft delete and parmanently delete
  const { isLoading, handleDelete } = useDelete(
    "admin",
    "amenities",
    "amenities"
  );

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = amenities?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <div className="row organizer-event">
        <div className="col-12">
          {!amenities || !amenities.length > 0 ? (
            <Alert {...msg} />
          ) : (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Venues count</th>
                      <th>Icon</th>
                      <th>Status</th>
                      <th className="text-center">
                        <AiTwotoneSetting />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((amen, i) => {
                      const items = [
                        {
                          label: (
                            <Link
                              to={`${amen.slug}/edit`}
                              className="d-flex align-items-center"
                            >
                              <FaEdit className="me-1" />
                              Edit
                            </Link>
                          ),
                          key: "0",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) => handleStatusChange(e, amen.id)}
                            >
                              {amen.status === "visible" ? (
                                <>
                                  <FaEyeSlash className="me-1" />
                                  Hide
                                </>
                              ) : (
                                <>
                                  <FaEye className="me-1" /> Show
                                </>
                              )}
                            </Link>
                          ),
                          key: "1",
                        },
                        {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(e, amen.id, "amenities_delete", "")
                              }
                            >
                              {amen?.deleted_at !== null ? (
                                <>
                                  <FaTrashRestoreAlt className="me-1" /> Restore
                                </>
                              ) : (
                                <>
                                  <IoCloseSharp className="me-1" />
                                  Delete
                                </>
                              )}
                            </Link>
                          ),
                          key: "2",
                        },
                        amen.deleted_at && {
                          label: (
                            <Link
                              to=""
                              className="d-flex align-items-center"
                              onClick={(e) =>
                                handleDelete(
                                  e,
                                  amen.id,
                                  "amenities_delete_permanent",
                                  "delete_permanent"
                                )
                              }
                            >
                              <MdDelete className="me-1" />
                              Delete permanently
                            </Link>
                          ),
                          key: "3",
                        },
                      ];
                      return (
                        <tr key={i}>
                          <td className="text-capitalize">{amen?.name}</td>
                          <td>{amen?.venus_count} </td>
                          <td>
                            {amen.icon && (
                              <IconPickerItem
                                icon={amen.icon}
                                size={24}
                                color="#000"
                              />
                            )}
                          </td>

                          <td className="">
                            <span
                              className={`badge badge-${
                                amen?.status === "visible"
                                  ? "success"
                                  : "danger"
                              } d-inline-flex align-items-center`}
                            >
                              {amen?.status === "visible" ? (
                                <>
                                  <FaEye className="me-1" /> Visible
                                </>
                              ) : (
                                <>
                                  <FaEyeSlash className="me-1" />
                                  Hidden
                                </>
                              )}
                            </span>
                            {amen?.deleted_at && (
                              <>
                                <br />
                                <br />
                                <span className="badge badge-danger d-inline-flex align-items-center">
                                  <IoCloseSharp className="me-1" />
                                  Deleted
                                </span>
                              </>
                            )}
                          </td>

                          <td className="text-center">
                            <Dropdown
                              menu={{
                                items,
                              }}
                              trigger={["click"]}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Space className="justify-content-center">
                                  <FaEllipsisV />
                                </Space>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {amenities?.length > 10 && (
        <Pagination
          current={currentPage}
          total={amenities.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};
