import React from "react";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import styled from "styled-components";

const Review = ({ rating = 0 }) => {
  rating = rating || 0;
  const ratingStar = Array.from({ length: 5 }, (elem, index) => {
    let number = index + 0.5;
    return (
      <span key={index}>
        {rating >= index + 1 ? (
          <FaStar className="icon" />
        ) : rating >= number ? (
          <FaStarHalfAlt className="icon" />
        ) : (
          <AiOutlineStar className="icon empty-icon" />
        )}
      </span>
    );
  });
  return (
    <Wrapper>
      <div className="rating-wrap">
        <div className="icon-style">
          {ratingStar}
          <div className="label-rating">{rating} out of 5 stars</div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .icon-style {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    justify-content: flex-start;

    .icon {
      font-size: 16px;
    }

    .empty-icon {
      font-size: 17px;
    }
  }
`;
export default Review;
