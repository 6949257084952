import React, { useEffect } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookShareCount,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import "./style.css";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter, FaXTwitter } from "react-icons/fa6";
import { useSelector } from "react-redux";

// const SocialShare = ({ align }) => {
//   useEffect(() => {
//     // Facebook
//     const facebookScript = document.createElement("script");
//     facebookScript.text =
//       "(function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = '//connect.facebook.net/ru_RU/sdk.js#xfbml=1&version=v2.3'; fjs.parentNode.insertBefore(js, fjs); }(document, 'script', 'facebook-jssdk'));";

//     // Google
//     // const googleScript = document.createElement("script");
//     // googleScript.src = "https://apis.google.com/js/platform.js";

//     // LinkedIn
//     const linkedInScript = document.createElement("script");
//     linkedInScript.src = "//platform.linkedin.com/in.js";

//     // Twitter
//     const twitterScript = document.createElement("script");
//     twitterScript.text =
//       "window.twttr=(function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0],t=window.twttr||{};if(d.getElementById(id))return t;js=d.createElement(s);js.id=id;js.src='https://platform.twitter.com/widgets.js';fjs.parentNode.insertBefore(js,fjs);t._e=[];t.ready=function(f){t._e.push(f);};return t;}(document,'script','twitter-wjs'));";

//     // Pinterest
//     // const pinterestScript = document.createElement("script");
//     // pinterestScript.src = "//assets.pinterest.com/js/pinit.js";

//     document.body.appendChild(facebookScript);
//     // document.body.appendChild(googleScript);
//     document.body.appendChild(linkedInScript);
//     document.body.appendChild(twitterScript);
//     // document.body.appendChild(pinterestScript);

//     // const shareButton = document.body.getElementsByClassName("._49vh._2pi7");
//     // console.log(shareButton);

//     // Cleanup on component unmount
//     return () => {
//       document.body.removeChild(facebookScript);
//       //   document.body.removeChild(googleScript);
//       document.body.removeChild(linkedInScript);
//       document.body.removeChild(twitterScript);
//       //   document.body.removeChild(pinterestScript);
//     };
//   }, []); // Empty dependency array to run only once on mount

//   // const changeShareButtonText = () => {
//   //   const shareButton = document.body.getElementsByClassName("._49vh._2pi7");
//   //   if (shareButton) {
//   //     shareButton.innerText = "Share"; // Replace with your desired text
//   //   }
//   // };

//   // // Call the function to change the text content
//   // useEffect(() => {
//   //   changeShareButtonText();
//   // }, []);

//   // useEffect(() => {
//   //   const shareButton = document.body.getElementsByClassName("._49vh._2pi7");
//   //   console.log(shareButton);
//   // }, []);

//   return (
//     <div
//       className={`col-12 col-lg-6 text-center text-lg-end sharer mt-3 jssocials d-flex align-items-center ${align} gap-2`}
//     >
//       {/* Facebook */}
//       <span
//         className="fb-share-button align-self-start"
//         data-layout="button_count"
//       >
//         Facebook
//       </span>

//       {/* Google */}
//       {/* <div
//         className="g-plus"
//         data-action="share"
//         data-annotation="bubble"
//       ></div> */}

//       {/* LinkedIn */}
//       <script type="IN/Share" data-counter="right"></script>

//       {/* Twitter */}
//       <a className="twitter-share-button" href="https://twitter.com/share">
//         Tweet
//       </a>

//       {/* Pinterest */}
//       {/* <a
//         href="//www.pinterest.com/pin/create/button/?url=http%3A%2F%2Fjs-socials.com%2Fdemos%2F&media=%26quot%3Bhttp%3A%2F%2Fgdurl.com%2Fa653%26quot%3B&description=Next%20stop%3A%20Pinterest"
//         data-pin-do="buttonPin"
//         data-pin-config="beside"
//         data-pin-color="red"
//       >
//         <img
//           src="//assets.pinterest.com/images/pidgets/pinit_fg_en_rect_red_20.png"
//           alt="Pin It"
//         />
//       </a> */}
//     </div>
//   );
// };

const SocialShare = ({ align, dynamicPart }) => {
  const { blog_setting } = useSelector((state) => state.AllData);
  const server = process.env.REACT_APP_SITE_URL;
  const sharedUrl = `${server}/en/event/${dynamicPart}`;
  const quoteText = "Check out this amazing content!";
  const facebookAppId = blog_setting?.app_id
    ? blog_setting?.app_id
    : "966242223397117";

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    sharedUrl
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    sharedUrl
  )}&text=${encodeURIComponent(quoteText)}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    sharedUrl
  )}&title=${encodeURIComponent(quoteText)}`;

  return (
    <div
      className={`col-12 col-lg-6 text-center text-lg-end sharer mt-3 jssocials d-flex align-items-center ${align} gap-2`}
    >
      <div className="social-share-f-button">
        <FacebookShareButton
          url={facebookShareUrl}
          quote={quoteText}
          appid={facebookAppId}
        >
          <FaFacebook />
          <span className="social-share-text">Share</span>
        </FacebookShareButton>
      </div>

      <div className="social-share-t-button">
        <TwitterShareButton url={twitterShareUrl}>
          <FaXTwitter />
        </TwitterShareButton>
      </div>

      <div className="social-share-l-button">
        <LinkedinShareButton url={linkedinShareUrl}>
          <FaLinkedin />
          <span className="social-share-text">Share</span>
        </LinkedinShareButton>
      </div>
    </div>
  );
};
export default SocialShare;
