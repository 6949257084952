import React from "react";
import { FaLifeRing } from "react-icons/fa";
import { Link } from "react-router-dom";

const Contactus = () => {
  return (
    <div className="row contactus">
      <div className="col-12">
        <article className="box mt-3 text-center">
          <figure className="m-0">
            <div className="aside align-self-center">
              <span className="icon-wrap icon-md round bg-dark">
                <FaLifeRing className="text-white" />
              </span>
            </div>
            <figcaption className="text-wrap">
              <h5 className="text-muted mt-4 mb-4">
                You did not find an answer to your inquiry? Let us know and we
                will be glad to give you further help
              </h5>
              <Link
                to="/en/contact"
                className="btn btn-dark justify-content-center rounded-0"
              >
                CONTACT US
              </Link>
            </figcaption>
          </figure>
        </article>
      </div>
    </div>
  );
};

export default Contactus;
