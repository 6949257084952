import { FaCircleInfo } from "react-icons/fa6";
import "../layout/style.scss";
import { Radio } from "antd";
import Count from "../../../../../count/Count";
import { useSelector } from "react-redux";
import { useSignUp } from "../../../../../../utils/CustomFunctions";
import { FaSyncAlt } from "react-icons/fa";

const Checkout = () => {
  const { time_left } = useSelector((state) => state.admin);

  const initialState = {
    time: time_left?.time || "",
    count: time_left?.count || "yes",
  };

  // validate fields
  const validateFields = (values) => {
    let newErrors = {};
    if (!values.time) newErrors.time = "This value should not be null.";
    return newErrors;
  };

  const { values, setValues, errors, isPending, handleChange, handleSubmit } =
    useSignUp(initialState, validateFields, `admin/add_time_left`, "time_left");
  return (
    <>
      <div className="card box">
        <div className="card-body">
          <div className="OrganizerProfileSection">
            <form onSubmit={handleSubmit} className="signform mt-3 ">
              <div className="mb-3">
                <label htmlFor="Facebook_app_id" className="form-label fw-600">
                  Timeleft
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div className="ProfleSettingOptionDescBox">
                  <FaCircleInfo className="ProfleSettingOptionDescIcon me-1" />
                  <span className="ProfleSettingOptionDesc">
                    Leave api key empty to disable google maps project wide
                  </span>
                </div>
                <Count
                  name="time"
                  value={values.time}
                  state="values"
                  setState={setValues}
                />
                {errors.time && (
                  <small className="text-danger d-block mt-1">
                    {errors.time}
                  </small>
                )}
              </div>

              <div className="ProfileSettingOptionsContainer">
                <div className="ProfileSettingOptionsBox">
                  <span className="ProfileSettingOptionTitle">
                    Show tickets left count on cart modal
                    <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="ProfileSettingRadioBtns">
                    <Radio
                      className="ProfileSettingRadioBtn"
                      name="count"
                      checked={values.count === "no"}
                      onChange={handleChange}
                      value="no"
                    >
                      No
                    </Radio>
                    <Radio
                      className="ProfileSettingRadioBtn "
                      name="count"
                      checked={values.count === "yes"}
                      onChange={handleChange}
                      value="yes"
                    >
                      Yes
                    </Radio>
                  </div>
                </div>
              </div>

              <div className="form-group mt-4 mb-3">
                <button
                  className="btn btn-dark rounded-5"
                  disabled={isPending ? "disabled" : ""}
                  type="submit"
                >
                  {isPending ? <FaSyncAlt className="spinIcon" /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
