import React, { useEffect, useState } from "react";
import {
  FaCalendarAlt,
  FaFolderPlus,
  FaInfoCircle,
  FaStar,
  FaSyncAlt,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Layout } from "../LayoutUser/Layout";
import Alert from "../global/alert/Alert";
import Tabs from "./Tabs";
import ReviewCard from "../reviews/ReviewCard";
import { useSelector } from "react-redux";

const Events = () => {
  const { slug } = useParams();
  const api = process.env.REACT_APP_SERVER_API;
  const server = process.env.REACT_APP_SERVER;
  const { user } = useSelector((state) => state.authUser);

  const [isPending, setPending] = useState(false);
  const [events, setEvents] = useState([]);
  const [organizer, setOrganizer] = useState({});
  const [followingCount, setFollowingCount] = useState();

  const getOrganizerEvnts = async () => {
    try {
      setPending(true);
      const { data } = await axios.get(`${api}/organizer_event/${slug}`);
      if (data.status === 200) {
        setEvents(data.data.events);
        setOrganizer(data.data?.user);
        setFollowingCount(data.data?.followingCount);
        setPending(false);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      setPending(false);
    }
  };
  useEffect(() => {
    getOrganizerEvnts();
  }, [slug]);

  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: (
      <span>
        <Link to="/en/dashboard/organizer/profile">Click here </Link> to edit
        your profile
      </span>
    ),
  };

  return (
    <>
      {isPending ? (
        <div className="d-flex align-items-center justify-content-center vh-100">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Layout>
          <section className="section-content mb-4 mt-4 bg-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {user?.role?.[0]?.name === "organizer" && <Alert {...msg} />}
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <div className="organizer-profile-sidebar text-center mt-3 mb-3">
                            {organizer?.organizer_logo ? (
                              <span
                                className="avatar organizer-profile-logo"
                                style={{
                                  background: `url(${server}/organizer/logo/${organizer?.organizer_logo})`,
                                }}
                              ></span>
                            ) : (
                              <span
                                className="avatar organizer-profile-logo"
                                style={{
                                  background: `url(/./images/no.png)`,
                                }}
                              ></span>
                            )}

                            <h4 className="text-center my-4 fw-bold">
                              {organizer?.organize_name}
                            </h4>
                          </div>
                        </div>

                        <div className="col-12 col-lg-8 bg-light">
                          {/* socail */}
                          <div className="p-4 d-flex justify-content-end text-center">
                            <ul className="list-inline mb-0">
                              <li className="list-inline-item me-4">
                                <h5 className="font-weight-bold mb-0 d-block">
                                  {events?.length}
                                </h5>
                                <small className="text-muted">
                                  <FaCalendarAlt className="me-1" /> Events
                                </small>
                              </li>
                              {organizer?.followers == 1 && (
                                <li className="list-inline-item">
                                  <h5 className="font-weight-bold mb-0 d-block">
                                    {followingCount}
                                  </h5>
                                  <small className="text-muted">
                                    <FaFolderPlus className="me-1" /> Followers
                                  </small>
                                </li>
                              )}

                              <li className="list-inline-item ms-3"></li>
                            </ul>
                          </div>
                          {/* evnts */}
                          <div className="py-4 px-4">
                            <Tabs events={events} />
                          </div>
                          {/* reviews */}
                          {organizer?.reviews == 1 && (
                            <div className="row mt-5 px-4">
                              <div className="col-12">
                                <h4 className="mb-4 d-flex align-items-center">
                                  <FaStar className="me-1" /> Reviews
                                </h4>

                                {events?.map((review, index) => {
                                  return (
                                    <ReviewCard review={review} key={index} />
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </>
  );
};

export default Events;
