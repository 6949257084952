import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./slider.scss";
import { FaLocationDot } from "react-icons/fa6";
import { FaTicketAlt } from "react-icons/fa";
import { AiFillClockCircle } from "react-icons/ai";

// Import Swiper styles and modules
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { formatDate } from "../../utils/CustomFunctions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Slider({ slidesData }) {
  const navigate = useNavigate();
  const server = process.env.REACT_APP_SERVER;
  const { home_page_setting, countries } = useSelector(
    (state) => state.AllData
  );
  const swiperRef = useRef();
  const [firstSwiper, setFirstSwiper] = useState({});

  const goEventDetail = (e, slug) => {
    e.preventDefault();
    home_page_setting?.home_page === "event"
      ? navigate(`en/event/${slug}`)
      : navigate(`en/events/${slug}`);
  };

  return (
    <>
      <Swiper
        navigation={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="Slider"
      >
        {slidesData?.map((data, ind) => {
          const startDate = new Date(data.start_On);
          const { fullDate } = formatDate(startDate);

          return (
            <SwiperSlide className="SliderSlide" key={ind}>
              <div
                className="Slide"
                ref={swiperRef}
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                controller={{ control: firstSwiper }}
                onSwiper={setFirstSwiper}
                grabCursor="true"
                style={{
                  backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 53%, rgba(0, 0, 0, 0) 100%), url(${
                    home_page_setting?.home_page === "event"
                      ? data.main_event_image
                        ? `${server}/event/image/${data.main_event_image}`
                        : "/images/no.png"
                      : data.image
                      ? `${server}/user/profile/${data.image}`
                      : "/images/no.png"
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="SliderContent">
                  <span className="SliderTitle text-capitalize">
                    {home_page_setting?.home_page === "event"
                      ? data.name
                      : data.organize_name}
                  </span>
                  {home_page_setting?.home_page === "event" && (
                    <div className="SliderDetailsWrapper">
                      <div className="SliderVenue">
                        <FaLocationDot className="SliderIcon" />
                        <span className="SliderAddress">
                          {data.name} -{" "}
                          {data?.event_date_online === 1 ? (
                            "Online"
                          ) : (
                            <>
                              {data?.venue?.city}: {data?.venue?.state},{" "}
                              {countries?.map((curElem) => {
                                if (curElem.id == data?.venue?.country) {
                                  return (
                                    <React.Fragment key={curElem.id}>
                                      {curElem.name}
                                    </React.Fragment>
                                  );
                                }
                                return null; // or any other default value if the condition is not met
                              })}
                            </>
                          )}
                        </span>
                      </div>
                      <div className="SliderVenue">
                        <AiFillClockCircle className="SliderIcon" />
                        <span className="SliderAddress">{fullDate}</span>
                      </div>
                    </div>
                  )}

                  <button
                    className="SliderBTN"
                    onClick={(e) => goEventDetail(e, data.slug)}
                  >
                    <FaTicketAlt className="SliderIcon" />
                    <span>BUY TICKETS</span>
                  </button>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
