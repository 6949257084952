import React, { useEffect } from "react";

import {
  FaInfoCircle,
  FaSearchMinus,
  FaSearchengin,
  FaSyncAlt,
} from "react-icons/fa";
import Table from "./Table";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrganizerFilters,
  clearAllFilters,
  loadFilterOrganizer,
  sorted,
  sorting,
} from "../../../../../store/slice/OrganizerFilters";
import { Radio, Tooltip } from "antd";
import SelectBox from "../../../../global/SelectBox";

export default function Users() {
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.AllData);
  const { users, loading } = useSelector((state) => state.admin);
  const {
    filters: {
      role,
      organizer_name,
      user_name,
      first_name,
      last_name,
      email,
      status,
      country,
    },
    sorting_value,
    filter_data,
  } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    dispatch(loadFilterOrganizer({ data: users, key: "users" }));
  }, [dispatch, users]);

  useEffect(() => {
    dispatch(clearAllFilters());
  }, []);
  useEffect(() => {
    dispatch(sorted("users"));
  }, [sorting_value]);

  // Search Filter
  const add_filter2 = (name, value) => {
    dispatch(addOrganizerFilters({ name, value }));
  };
  const add_filter = (e) => {
    const { name, value } = e.target;
    dispatch(addOrganizerFilters({ name, value }));
  };
  const add_filter3 = (name, value) => {
    dispatch(sorting({ name, value }));
  };
  const options = [
    { id: "date-desc", name: "Creation date (desc)" },
    { id: "date-asc", name: "Creation date (asc)" },
  ];

  const roles = [
    { id: "all", name: "All" },
    { id: "organizer", name: "Organizer" },
    { id: "attendee", name: "Attendee" },
  ];

  return (
    <>
      <div className="box shadow-none bg-gray mb-4 organizer-event payoutRequests">
        <div className="row">
          <div className="col-sm-12 col-lg-3 text-center text-lg-start mb-3 mb-lg-0 align-self-center">
            <span className="center-lg-y text-muted">
              {filter_data.users.length} result(s) found
            </span>
          </div>
          <div className="col-sm-12 col-lg-5 mb-3 mb-lg-0">
            <form>
              <div className="row align-items-center">
                <div className="col-3 text-end">
                  <label htmlFor="sort" className="">
                    Sort by{" "}
                  </label>
                </div>
                <div className="col-9">
                  <SelectBox
                    name="sorting_value"
                    options={options}
                    val={sorting_value}
                    change={add_filter3}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-sm-12 col-lg-4 text-center text-lg-end">
            <Tooltip title="Toggle display the search filters">
              <button
                type="button"
                className="btn btn-dark ms-2"
                data-bs-toggle="collapse"
                data-bs-target="#search-filters"
                aria-controls="search-filters"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <FaSearchengin />
              </button>
            </Tooltip>
          </div>
          <div
            className="col-sm-12 col-lg-12 mb-3 mb-lg-0 collapse show"
            id="search-filters"
          >
            <hr />
            <form className="signform">
              <div className="row align-items-end">
                <div className="col-12 col-sm-4 mb-4">
                  <label htmlFor="event_id" className="form-label fw-600">
                    Role
                  </label>
                  <SelectBox
                    name="role"
                    options={roles}
                    val={role}
                    change={add_filter2}
                  />
                </div>
                <div className="col-12 col-sm-4 mb-4">
                  <label htmlFor="organizer_name" className="form-label fw-600">
                    Organizer name
                  </label>
                  <input
                    type="text"
                    id="organizer_name"
                    className="form-control"
                    name="organizer_name"
                    value={organizer_name}
                    onChange={add_filter}
                  />
                </div>
                <div className="col-12 col-sm-4 mb-4">
                  <label htmlFor="user_name" className="form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    id="user_name"
                    className="form-control"
                    name="user_name"
                    value={user_name}
                    onChange={add_filter}
                  />
                </div>
                <div className="col-12 col-sm-4 mb-4">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={add_filter}
                  />
                </div>
                <div className="col-12 col-sm-4 mb-4">
                  <label htmlFor="first_name" className="form-label">
                    First name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    className="form-control"
                    name="first_name"
                    value={first_name}
                    onChange={add_filter}
                  />
                </div>
                <div className="col-12 col-sm-4 mb-4">
                  <label htmlFor="last_name" className="form-label">
                    Last name
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    className="form-control"
                    name="last_name"
                    value={last_name}
                    onChange={add_filter}
                  />
                </div>
                <div className="col-12 col-sm-4 mb-4">
                  <label htmlFor="Country" className="form-label">
                    Country
                  </label>
                  <SelectBox
                    name="country"
                    options={countries}
                    val={country}
                    change={add_filter2}
                  />
                </div>

                <div className="col-12 col-sm-4 mb-4 ProfileSettingRadioBtns">
                  <ul>
                    <li>
                      <Radio
                        className="ProfileSettingRadioBtn fw-600"
                        name="status"
                        checked={status === "all"}
                        value="all"
                        onChange={add_filter}
                      >
                        All
                      </Radio>
                    </li>
                    <li>
                      <Radio
                        className="ProfileSettingRadioBtn fw-600"
                        name="status"
                        checked={status === "active"}
                        value="active"
                        onChange={add_filter}
                      >
                        Enabled only
                      </Radio>
                    </li>
                    <li>
                      <Radio
                        className="ProfileSettingRadioBtn fw-600"
                        name="status"
                        checked={status === "inactive"}
                        value="inactive"
                        onChange={add_filter}
                      >
                        Disabled only
                      </Radio>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-sm-4 mb-4">
                  <Tooltip title="Reset">
                    <button
                      type="button"
                      className="btn btn-dark ms-2"
                      onClick={() => dispatch(clearAllFilters())}
                    >
                      <FaSearchMinus />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaSyncAlt className="spinIcon" />
        </div>
      ) : (
        <Table users={filter_data.users} />
      )}
    </>
  );
}
