import React, { useEffect } from "react";
import { Table } from "./Table";
import {
  FaSearch,
  FaSearchMinus,
  FaSearchengin,
  FaSyncAlt,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrganizerFilters,
  clearAllFilters,
  loadFilterOrganizer,
  sorted,
  sorting,
} from "../../../../../store/slice/OrganizerFilters";
import SelectBox from "../../../../global/SelectBox";
import { formatDate } from "../../../../../utils/CustomFunctions";
import { Tooltip } from "antd";

const Filters = ({ payout_reports, events, organizers = null }) => {
  const dispatch = useDispatch();
  const {
    filters: { event_id, event_date, organizer },
    sorting_value,
    filter_data,
  } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    dispatch(
      loadFilterOrganizer({ data: payout_reports, key: "payout_reports" })
    );
  }, [dispatch, payout_reports]);

  useEffect(() => {
    dispatch(clearAllFilters());
  }, []);

  useEffect(() => {
    dispatch(sorted("payout_reports"));
  }, [sorting_value]);

  const add_filter2 = (name, value) => {
    dispatch(addOrganizerFilters({ name, value }));
  };
  const add_filter3 = (name, value) => {
    dispatch(sorting({ name, value }));
  };
  // Sorting select Box options
  const options = [
    { id: "date-desc", name: "Creation date (desc)" },
    { id: "date-asc", name: "Creation date (asc)" },
  ];

  const formattedDataList = events.map((event) => ({
    id: event.id + " " + event.start_On,
    name:
      event.name + " (" + formatDate(new Date(event.start_On)).fullDate + ")",
  }));
  const organizerList = organizers?.map((organizer) => ({
    id: organizer.id,
    name: organizer.organize_name,
  }));

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-3 text-center text-lg-start mb-3 mb-lg-0 align-self-center">
        <span className="center-lg-y text-muted">
          {filter_data.payout_reports.length} result(s) found
        </span>
      </div>
      <div className="col-sm-12 col-lg-5 mb-3 mb-lg-0">
        <form>
          <div className="row align-items-center">
            <div className="col-3 text-end">
              <label htmlFor="sort" className="">
                Sort by{" "}
              </label>
            </div>
            <div className="col-9">
              <SelectBox
                name="sorting_value"
                options={options}
                val={sorting_value}
                change={add_filter3}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="col-sm-12 col-lg-4 text-center text-lg-end ">
        <Tooltip title="Toggle display the search filters">
          <button
            type="button"
            className="btn btn-dark ms-2"
            data-bs-toggle="collapse"
            data-bs-target="#search-filters"
            aria-controls="search-filters"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FaSearchengin />
          </button>
        </Tooltip>
      </div>

      {/*  */}
      <div
        id="search-filters"
        className="col-sm-12 col-lg-12 mb-3 mb-lg-0 collapse show"
      >
        <hr />
        <form>
          <div className="row align-items-end">
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="event_id" className="form-label fw-600">
                Event
              </label>
              <SelectBox
                name="event_id"
                options={events}
                val={event_id}
                change={add_filter2}
              />
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="event_date" className="form-label fw-600">
                Event Date
              </label>
              <SelectBox
                name="event_date"
                options={formattedDataList}
                val={event_date}
                change={add_filter2}
              />
            </div>
            {organizers && (
              <div className="col-12 col-sm-4 mb-4">
                <label htmlFor="organizers" className="form-label fw-600">
                  Organizers
                </label>
                <SelectBox
                  name="organizer"
                  options={organizerList}
                  val={organizer}
                  change={add_filter2}
                />
              </div>
            )}

            <div className="col-12 col-sm-4 mb-4">
              <Tooltip title="Reset">
                <button
                  type="button"
                  className="btn btn-dark ms-2"
                  onClick={() => dispatch(clearAllFilters())}
                >
                  <FaSearchMinus />
                </button>
              </Tooltip>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filters;
