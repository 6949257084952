import { Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { adminFetchData } from "../../../../../store/slice/Admin";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ApproveModel = ({ openModel, setOpenModel, id }) => {
  const dispatch = useDispatch();
  const api = process.env.REACT_APP_SERVER_API;
  const { user } = useSelector((state) => state.authUser);
  const { payout_requests } = useSelector((state) => state.admin);
  const [request, setRequest] = useState({});

  const handleSubmit = async () => {
    // e.preventDefault();
    try {
      const { data } = await axios.get(
        `${api}/admin/payout_method_request_approve/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (data.status === 200) {
        toast.success(data.message);
        setOpenModel(false);
        dispatch(
          adminFetchData({
            endPoint: "admin/payout_method_requests",
            state: "payout_requests",
            token: user.token,
          })
        );
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    openModel &&
      setRequest(payout_requests.find((request) => request?.id === id));
  }, [openModel]);

  return (
    <>
      <Modal
        // title="Vertically centered modal dialog"
        centered
        open={openModel}
        onCancel={() => setOpenModel(false)}
        className="Model"
      >
        <div className="ModelBox">
          <div className="ModelHeader">
            <span className="ModelBoxBuyTicketText">Confirmation required</span>
          </div>
          <div className="jconfirm-content" id="jconfirm-box27525">
            <div>
              You are about to approve the payout request
              <br />
              Amount: <b>{request?.net_sales}$</b>
              <br />
              Payout method: <b>{request?.payout_method}</b>
            </div>
          </div>
        </div>

        <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
          <button
            className="btn btn-outline-dark btn-sm rounded-5 d-inline-flex align-items-center gap-2"
            onClick={handleSubmit}
          >
            Confirm
          </button>
          <button
            className="btn btn-outline-secondary btn-sm rounded-5 d-inline-flex align-items-center gap-2"
            onClick={() => setOpenModel(false)}
          >
            close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ApproveModel;
