import React from "react";
import Breadcrumb from "../../components/global/Breadcrumb";
import "./style.css";
import { Layout } from "../../components/LayoutUser/Layout";
const PricingAndFees = () => {
  return (
    <Layout>
      <Breadcrumb page={"Pricing and fees"} />
      <div className="container mt-4 mb-4 pricing-and-fees">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <p>
                  At feevaa.com we try to stay price competitive with other
                  event management and ticketing sales platforms as much as
                  possible. For this reason, we charge a flat rate $3.49
                  convenience fee (added at checkout) + 5% cut fee (which
                  includes our payment processor + commission fees) which is
                  absorbed by the organizer.
                </p>
                <p>
                  Please keep these charges in mind as you structure your ticket
                  prices.{" "}
                </p>
                <p>See below for our payment processor fees:</p>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Paypal</td>
                      <td>Stripe</td>
                    </tr>
                    <tr>
                      <td>2.99% + .30c</td>
                      <td>2.90% + .30c</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PricingAndFees;
