import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const api = process.env.REACT_APP_SERVER_API;

export const fetchuUserData = createAsyncThunk(
  "attendee/fetchUserData",
  async ({ getData, token, state }) => {
    const res = await axios.get(`${api}/${getData}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: res.data.data, state };
  }
);

const initialState = {
  myRviews: [],
  favorites: [],
  followings: [],
  loading: false,
  error: false,
};
const AttendeeSlice = createSlice({
  name: "attendee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchuUserData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchuUserData.fulfilled, (state, action) => {
        const { data, state: dataState } = action.payload;
        state[dataState] = data;
        state.loading = false;
      })
      .addCase(fetchuUserData.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      });
  },
});

// export const { editData, emptyEditData } = AttendeeSlice.actions;
export default AttendeeSlice.reducer;
