import { Dropdown, Pagination, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  FaEllipsisV,
  FaFileAlt,
  FaInfoCircle,
  FaWindowClose,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../../utils/CustomFunctions";
import Alert from "../../../../global/alert/Alert";
import CancelModel from "./CancelModel";

export const Table = ({ payout_request }) => {
  const [openModel2, setOpenModel2] = useState(false);
  const [modelCancelOrder, setModelCancelOrder] = useState({
    id: "",
    reason: "",
  });
  // this is alert maessage
  const msg = {
    icon: <FaInfoCircle className="me-3" />,
    type: "alert-info",
    text: "No payout request found",
  };

  const handleOderCancel = (e, id) => {
    e.preventDefault();
    setModelCancelOrder({ ...modelCancelOrder, id });
    setOpenModel2(true);
  };

  // for pagination purposes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = payout_request?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <CancelModel
        openModel={openModel2}
        setOpenModel={setOpenModel2}
        order={modelCancelOrder}
        setorder={setModelCancelOrder}
      />
      <div className="row organizer-event">
        <div className="col-12">
          {payout_request && payout_request.length > 0 ? (
            <div className="card">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter">
                  <thead>
                    <tr>
                      <th className="text-center">Reference</th>
                      <th>Event date</th>
                      <th>Payout method </th>
                      <th>Net sales</th>
                      <th>Request date</th>
                      <th>Status</th>
                      <th className="text-center">
                        <AiTwotoneSetting />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((request, index) => {
                      const items = [
                        {
                          label: (
                            <Link
                              to={`${request?.reference}/details`}
                              className="d-flex align-items-center"
                            >
                              <FaFileAlt className="me-1" />
                              Details
                            </Link>
                          ),
                          key: "0",
                        },
                        request?.status === "pending" && {
                          label: (
                            <Link
                              to=""
                              onClick={(e) => handleOderCancel(e, request.id)}
                              className="d-flex align-items-center"
                            >
                              <FaWindowClose className="me-1" />
                              Cancel
                            </Link>
                          ),
                          key: "1",
                        },
                      ];
                      return (
                        <tr key={index}>
                          <td className="text-center text-muted text-sm">
                            {request?.reference}
                          </td>
                          <td className="text-sm">
                            {request?.event?.name}
                            <br />
                            {
                              formatDate(new Date(request?.event?.start_On))
                                .fullDate
                            }
                          </td>
                          <td>
                            {request?.payout_method === "stripe" ? (
                              <img
                                src="/./images/strip.svg"
                                className="img-thumbnail img-50-50"
                              />
                            ) : (
                              <img
                                src="/./images/paypal.svg"
                                className="img-thumbnail img-50-50"
                              />
                            )}
                          </td>
                          <td>${request?.net_sales}</td>

                          <td>
                            {
                              formatDate(new Date(request?.request_date))
                                .fullDate
                            }
                          </td>
                          <td className="">
                            {request?.status === "pending" && (
                              <span className="badge badge-warning ">
                                Pending
                              </span>
                            )}
                            {request?.status === "cancel" && (
                              <span className="badge badge-danger ">
                                Canceled
                              </span>
                            )}
                            {request?.status === "approve" && (
                              <span className="badge badge-success ">
                                Approved
                              </span>
                            )}
                          </td>
                          <td className="text-center">
                            <Dropdown
                              menu={{
                                items,
                              }}
                              trigger={["click"]}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Space className="justify-content-center">
                                  <FaEllipsisV />
                                </Space>
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <Alert {...msg} />
          )}
        </div>
      </div>

      {payout_request?.length > 10 && (
        <Pagination
          current={currentPage}
          total={payout_request.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};
