import React, { useEffect, useState } from "react";
import { FaSearch, FaSearchMinus, FaSearchengin } from "react-icons/fa";
import { Radio, Tooltip } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrganizerFilters,
  clearAllFilters,
  loadFilterOrganizer,
  sorted,
  sorting,
} from "../../../../../store/slice/OrganizerFilters";
import { formatDate } from "../../../../../utils/CustomFunctions";
import SelectBox from "./../../../../global/SelectBox";

const Filters = ({ orders, events, organizers = null }) => {
  const { slug } = useParams();
  const { pathname } = useLocation();
  const isSlug = slug || null;
  const dispatch = useDispatch();
  const [order, setOrder] = useState([]);
  const {
    filters: {
      reference,
      event_id,
      event_date,
      from_date,
      until_date,
      admin_event_id,
      admin_event_date,
      organizer,
      status,
      payment_method,
    },
    sorting_value,
    filter_data,
  } = useSelector((state) => state.organizerFilters);

  useEffect(() => {
    isSlug
      ? setOrder(
          orders.filter((r) =>
            pathname ===
            `/en/dashboard/administrator/manage-orders/event/${slug}`
              ? r?.event.reference === slug
              : r.reference === slug
          )
        )
      : setOrder(orders);
  }, [orders, slug]);

  useEffect(() => {
    dispatch(loadFilterOrganizer({ data: order, key: "orders" }));
  }, [dispatch, order]);

  useEffect(() => {
    dispatch(clearAllFilters());
  }, []);

  useEffect(() => {
    dispatch(sorted("orders"));
  }, [sorting_value]);

  const add_filter = (e) => {
    const { name, value } = e.target;
    dispatch(addOrganizerFilters({ name, value }));
  };
  const add_filter2 = (name, value) => {
    dispatch(addOrganizerFilters({ name, value }));
  };
  const add_filter3 = (name, value) => {
    dispatch(sorting({ name, value }));
  };
  // Sorting select Box options
  const options = [
    { id: "date-desc", name: "Creation date (desc)" },
    { id: "date-asc", name: "Creation date (asc)" },
  ];

  const formattedDataList = events.map((event) => ({
    id: event.id + " " + event.start_On,
    name:
      event.name + " (" + formatDate(new Date(event.start_On)).fullDate + ")",
  }));
  const organizerList = organizers?.map((organizer) => ({
    id: organizer.id,
    name: organizer.organize_name,
  }));

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-3 text-center text-lg-start mb-3 mb-lg-0 align-self-center">
        <span className="center-lg-y text-muted">
          {filter_data?.orders?.length} result(s) found
        </span>
      </div>
      <div className="col-sm-12 col-lg-5">
        <form>
          <div className="row align-items-center">
            <div className="col-3 text-end">
              <label htmlFor="sort" className="">
                Sort by{" "}
              </label>
            </div>
            <div className="col-9">
              <SelectBox
                name="sorting_value"
                options={options}
                val={sorting_value}
                change={add_filter3}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="col-sm-12 col-lg-4 text-center text-lg-end">
        <Tooltip title="Toggle display the search filters">
          <button
            type="button"
            className="btn btn-dark ms-2"
            data-bs-toggle="collapse"
            data-bs-target="#search-filters"
            aria-controls="search-filters"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FaSearchengin />
          </button>
        </Tooltip>
        {/* <Link
          to="/en/dashboard/administrator/manage-orders?excel=1"
          target="_blank"
          className="btn btn-dark me-1"
          data-toggle="tooltip"
          title=""
          data-original-title="Export current orders to an Excel file"
        >
          <FaFileExcel />
        </Link>
        <Link
          to="/en/dashboard/administrator/manage-orders?excel=1"
          target="_blank"
          className="btn btn-dark me-1"
          data-toggle="tooltip"
          title=""
          data-original-title="Export current orders to an Excel file"
        >
          <FaFileCsv />
        </Link>
        <Link
          to="/en/dashboard/administrator/manage-orders?excel=1"
          target="_blank"
          className="btn btn-dark"
          data-toggle="tooltip"
          title=""
          data-original-title="Export current orders to an Excel file"
        >
          <FaFilePdf />
        </Link> */}
      </div>
      <div
        className="col-sm-12 col-lg-12 mb-3 mb-lg-0 collapse show"
        id="search-filters"
      >
        <hr />
        <form action="" className="signform">
          <div className="row">
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="Reference" className="form-label">
                Reference
              </label>
              <input
                type="text"
                className="form-control"
                id="Reference"
                name="reference"
                value={reference}
                onChange={add_filter}
              />
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="event_id" className="form-label fw-600">
                Event
              </label>
              <SelectBox
                name={`${organizers ? "admin_event_id" : "event_id"}`}
                options={events}
                val={`${organizers ? admin_event_id : event_id}`}
                change={add_filter2}
              />
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="event_date" className="form-label fw-600">
                Event Date
              </label>
              <SelectBox
                name={`${organizers ? "admin_event_date" : "event_date"}`}
                options={formattedDataList}
                val={`${organizers ? admin_event_date : event_date}`}
                change={add_filter2}
              />
            </div>
            {organizers && (
              <div className="col-12 col-sm-4 mb-4">
                <label htmlFor="organizers" className="form-label fw-600">
                  Organizers
                </label>
                <SelectBox
                  name="organizer"
                  options={organizerList}
                  val={organizer}
                  change={add_filter2}
                />
              </div>
            )}
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="From-date" className="form-label">
                From date
              </label>
              <input
                type="date"
                className="form-control"
                id="From-date"
                name="from_date"
                value={from_date}
                onChange={add_filter}
              />
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <label htmlFor="Until-date" className="form-label">
                Until date
              </label>
              <input
                type="date"
                className="form-control"
                id="Until-date"
                name="until_date"
                value={until_date}
                onChange={add_filter}
              />
            </div>
            {organizers && (
              <>
                <div className="col-12 col-sm-4 mb-4">
                  <ul>
                    <li>
                      <Radio
                        className="ProfileSettingRadioBtn fw-600"
                        name="status"
                        checked={status === "all"}
                        value="all"
                        onChange={add_filter}
                      >
                        All
                      </Radio>
                    </li>
                    <li>
                      <Radio
                        className="ProfileSettingRadioBtn fw-600"
                        name="status"
                        checked={status === "completed"}
                        value="completed"
                        onChange={add_filter}
                      >
                        Paid
                      </Radio>
                    </li>
                    <li>
                      <Radio
                        className="ProfileSettingRadioBtn fw-600"
                        name="status"
                        checked={status === "pending"}
                        value="pending"
                        onChange={add_filter}
                      >
                        Awaiting payment
                      </Radio>
                    </li>
                    <li>
                      <Radio
                        className="ProfileSettingRadioBtn fw-600"
                        name="status"
                        checked={status === "cancel"}
                        value="cancel"
                        onChange={add_filter}
                      >
                        Canceled
                      </Radio>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-sm-4 mb-4">
                  <ul>
                    <li>
                      <Radio
                        className="ProfileSettingRadioBtn fw-600"
                        name="payment_method"
                        checked={payment_method === "all"}
                        value="all"
                        onChange={add_filter}
                      >
                        All
                      </Radio>
                    </li>
                    <li>
                      <Radio
                        className="ProfileSettingRadioBtn fw-600"
                        name="payment_method"
                        checked={payment_method === "strip"}
                        value="strip"
                        onChange={add_filter}
                      >
                        Credit/Debit Cards
                      </Radio>
                    </li>
                    {/* <li>
                    <Radio value={3}>Apple Pay</Radio>
                  </li> */}
                  </ul>
                </div>
              </>
            )}

            <div className="col-12 col-sm-4 mb-4">
              <Tooltip title="Reset">
                <button
                  type="button"
                  className="btn btn-dark ms-2"
                  onClick={() => dispatch(clearAllFilters())}
                >
                  <FaSearchMinus />
                </button>
              </Tooltip>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filters;
